import React from 'react';
import isEqual from 'lodash/isEqual';
import { KEYS } from '@/utils/constants';
import layoutOuterLeftCollapsedHOC from '@/connectHOCs/layoutOuterLeftCollapsed';
import classNames from 'classnames';
import modalStyles from './Modal.css';

let modalStack = [];
let indexOfExistingTemplate = null;
let blockingModalOpen = false;

// TODO: define priority for blocking modals in the event we have multiple blocking modals to open

// eslint-disable-next-line import/no-mutable-exports
let openModal = function (
  template,
  showCloseCross,
  disableDismiss,
  fullScreenModal,
  showTeamSwitcher,
  blockingModal
) {
  if (!blockingModalOpen) {
    // ensure that no blocking modal is open before trying to open a new modal
    let templateExists = false;
    if (blockingModal) {
      blockingModalOpen = true;
    }

    if (modalStack.length > 0) {
      modalStack.forEach((item) => {
        if (isEqual(item.template, template)) {
          templateExists = true;
          indexOfExistingTemplate = modalStack.findIndex((stackItem) =>
            isEqual(stackItem.template, template)
          );

          // if template exists and is recalled, take it to the top of the stack
          modalStack.splice(indexOfExistingTemplate, 1);
          modalStack.push(template);
        }
      });
    }

    if (!templateExists) {
      const modalObj = {
        template,
        showCloseCross,
        showTeamSwitcher,
        disableDismiss,
        fullScreenModal,
        blockingModal,
      };
      modalStack.push(modalObj);
    }
    this.setState({
      show: true,
      template,
      showCloseCross: !!showCloseCross,
      disableDismiss: !!disableDismiss,
      showTeamSwitcher: !!showTeamSwitcher,
      fullScreenModal: !!fullScreenModal,
      blockingModal: !!blockingModal,
    });
    this.modalContainer?.focus();
  }
};

// eslint-disable-next-line import/no-mutable-exports
let hideModal = function (closeAllOpenModals, disableDismiss) {
  if (!disableDismiss) {
    if (modalStack.length > 1 && !!closeAllOpenModals === false) {
      modalStack.pop();
      openModal(
        modalStack[modalStack.length - 1].template,
        modalStack[modalStack.length - 1].showCloseCross
      );
    } else {
      modalStack = [];
      if (blockingModalOpen) {
        blockingModalOpen = false;
      }
      this.setState({
        template: null,
        show: false,
      });
    }
  }
};

class Modal extends React.Component {
  constructor(props) {
    super(props);
    openModal = openModal.bind(this);
    hideModal = hideModal.bind(this);
  }

  state = {
    show: false,
    template: null,
  };

  componentDidMount() {
    document.body.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    document.body.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown = (e) => {
    const { keyCode } = e;
    const { show, disableDismiss } = this.state;

    if (keyCode === KEYS.ESCAPE && show) {
      if (!disableDismiss) {
        hideModal();
        e.stopPropagation();
      }
    }
  };

  render() {
    const {
      show,
      template,
      showCloseCross,
      disableDismiss,
      fullScreenModal,
      showTeamSwitcher,
    } = this.state;

    const { outerLeftCollapsed } = this.props;
    const pushLeftStyle = {
      marginLeft: outerLeftCollapsed ? '68px' : '56px',
    };

    if (!show) {
      return null;
    }
    return (
      <div
        className={classNames(modalStyles.overlay)}
        onClick={() => hideModal(false, disableDismiss)}
        role='button'
        tabIndex='-1'
        onKeyDown={() => {}}
        style={showTeamSwitcher ? pushLeftStyle : null}
      >
        <div
          className={classNames(modalStyles.modal_content, {
            [modalStyles.is_full_screen]: fullScreenModal,
          })}
          onClick={(event) => event.stopPropagation()}
          ref={(div) => {
            this.modalContainer = div;
          }}
          role='button'
          tabIndex='-1'
          onKeyDown={() => {}}
        >
          {showCloseCross && (
            <div
              className={modalStyles.close_button}
              onClick={() => hideModal()}
              role='button'
              tabIndex='-1'
              onKeyDown={() => {}}
            />
          )}
          {template}
        </div>
      </div>
    );
  }
}

export { openModal, hideModal };

export default layoutOuterLeftCollapsedHOC(Modal);
