import isEqual from 'lodash/isEqual';
import store from '@/store';

/**
 * subscribe - An abstract Redux Subscriber function
 * @param select {function} - A state selector function that cherry picks the store value you wish to observe.
 * @param onStoreUpdate {function|Array<function>} - A callback that gets executed
 *  whenever there is a change in the above selected value with the `newState` and `oldState` as params.
 * @returns {function} This lets you unsubscribe your Redux Subscriber.
 */
export default function subscribe(select, onStoreUpdate) {
  if (typeof select !== 'function') {
    console.error('Subscriber needs a state selector function.');
  }
  if (!onStoreUpdate) {
    console.log('Subscriber requires a callback onStoreUpdate function.');
  }
  let currentValue = null;
  return store.subscribe(() => {
    const previousValue = currentValue;
    currentValue = select(store.getState());
    if (currentValue && !isEqual(previousValue, currentValue)) {
      if (typeof onStoreUpdate === 'function') {
        onStoreUpdate(currentValue, previousValue);
      } else if (Array.isArray(onStoreUpdate)) {
        onStoreUpdate.forEach((cb) => cb(currentValue, previousValue));
      }
    }
  });
}
