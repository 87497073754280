import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import omit from 'lodash/omit';
import classNames from 'classnames';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { FormattedMessage } from 'react-intl';
import {
  subscribe as subscribeKeyboard,
  REMOVE_CHAT,
  REOPEN_CONVERSATION,
  NAVIGATE_CONVERSATION,
  SECONDARY_NAVIGATION,
} from '@/utils/KeyboardShortcuts';
import { arrayMove, scrollTo, colors, isEnterPressed } from '@/utils';
import emptyState from '@/assets/empty-active-chat-state.svg';
import { ChatsProxy, ContactProxy } from '@/Proxies';
import { markLatestMessageAsRead } from '@/utils/message';
import { ALL_CHANNEL_FEED } from '@/utils/constants';
import IntlWrapper from '@/Wrappers/IntlWrapper';
import { batch } from 'react-redux';
import styles from './ActiveChats.css';
import ChatItem from './ChatItem';
import InviteWidget from './InviteWidget';
import { Image, Button, Badge, OpenGroupCount } from '../common';
import ViewModes from './ViewModes';
import { VIEW_MODE_KEYS, PINNED, MUTED, OPEN } from './constants';
import translations from './I18N';

const HEADER_HEIGHT_OFFSET = 30;
const SCROLL_OFFSET = 5;

const defaultHeader = {
  offsetHeight: 0,
};

const container = React.createRef(null);
const pinnedRef = React.createRef(null);
const openRef = React.createRef(null);
const mutedRef = React.createRef(null);
const pinnedHeaderRef = React.createRef(null);
const openHeaderRef = React.createRef(null);
const mutedHeaderRef = React.createRef(null);

function scrollToPinned() {
  container.current.scrollTo({ top: 0, behaviour: 'smooth' });
}

function scrollToOpen() {
  scrollTo(
    container.current,
    openRef.current,
    2 * HEADER_HEIGHT_OFFSET + SCROLL_OFFSET
  );
}

function scrollToMuted() {
  scrollTo(
    container.current,
    mutedRef.current,
    3 * HEADER_HEIGHT_OFFSET + SCROLL_OFFSET
  );
}

function hasClass(el, className) {
  if (el.classList) return el.classList.contains(className);
  return !!el.className.match(new RegExp(`(\\s|^)${className}(\\s|$)`));
}

function addClass(el, className) {
  if (el.classList) el.classList.add(className);
  else if (!hasClass(el, className)) el.className += ` ${className}`;
}

function removeClass(el, className) {
  if (el.classList) el.classList.remove(className);
  else if (hasClass(el, className)) {
    const reg = new RegExp(`(\\s|^)${className}(\\s|$)`);
    el.className = el.className.replace(reg, ' ');
  }
}

const SortableChatItem = SortableElement(({ children }) => children);
const SortablePinnedChats = SortableContainer(
  ({ pinnedChats, currentConversation, ownerGuid, mode, collapsed }) => (
    <div className={styles.chats} ref={pinnedRef}>
      {pinnedChats.map((contact, index) => {
        if (contact) {
          return (
            <SortableChatItem key={`${ownerGuid}-${contact.jid}`} index={index}>
              <ChatItem
                collapsed={collapsed}
                mode={mode}
                bucket={PINNED}
                contact={contact}
                ownerGuid={ownerGuid}
                isSelected={currentConversation === contact.jid}
              />
            </SortableChatItem>
          );
        }
        return null;
      })}
    </div>
  )
);

const _chatsSubscription = ChatsProxy.subscribe('');
const _contactSubscription = ContactProxy.subscribe('');

class ActiveChats extends PureComponent {
  static defaultProps = {
    currentConversation: '',
    ownerGuid: null,
    teamUrl: '',
  };

  static propTypes = {
    currentConversation: PropTypes.string,
    ownerGuid: PropTypes.string,
    teamUrl: PropTypes.string,
  };

  state = { mode: {} };

  componentDidMount() {
    window.addEventListener('resize', this.throttledStickHeaders);
    this.unsubscribeEscape = subscribeKeyboard(
      REMOVE_CHAT,
      this.closeCurrentConversation
    );
    this.unsubscribeShiftEscape = subscribeKeyboard(
      REOPEN_CONVERSATION,
      this.reopenConversation
    );
    this.unsubscribeNavigateChats = subscribeKeyboard(
      NAVIGATE_CONVERSATION,
      this.navigateConversation
    );
    this.unsubscribeSecondaryNavigation = subscribeKeyboard(
      SECONDARY_NAVIGATION,
      this.navigateConversation
    );
    this.getViewPreferences();
    const { unreadMsgIds } = this.props;
    if (unreadMsgIds) {
      const peers = Object.keys(unreadMsgIds);
      this.openUnreadChats(peers);
    }
  }

  async getViewPreferences() {
    const viewPreferences = await ChatsProxy.getViewPreferences();
    this.setState(({ mode }) => {
      return {
        mode: {
          ...mode,
          ...viewPreferences,
        },
      };
    });
  }

  throttledStickHeaders = throttle(
    () => {
      const { collapsed } = this.props;
      if (collapsed) {
        // Manage unread and mentions on arrow icons.
      } else {
        const { current: $container } = container;
        const { current: $pinned } = pinnedRef;
        const { current: $open } = openRef;
        const { current: $muted } = mutedRef;
        let { current: $openHeader } = openHeaderRef;
        let { current: $mutedHeader } = mutedHeaderRef;
        let { current: $pinnedHeader } = pinnedHeaderRef;
        $pinnedHeader = $pinnedHeader || defaultHeader;
        $openHeader = $openHeader || defaultHeader;
        $mutedHeader = $mutedHeader || defaultHeader;
        /* Stick to the top or bottom.
          container.scrollTop - The amount container has scrolled.
          ${bucket}.offsetTop - The relative position of the bucket wrt the container
          container.offsetHeight - Visible height of the container
        */
        if ($container) {
          const containerBottom =
            $container.scrollTop + $container.offsetHeight;

          const stickPinnedToTop =
            $pinned && $container.scrollTop >= $pinned.offsetTop;
          const stickOpenToTop =
            $container.scrollTop + $pinnedHeader.offsetHeight >=
            $open.offsetTop - 15;
          const stickMutedToTop =
            $muted &&
            $container.scrollTop +
              ($pinnedHeader.offsetHeight + $openHeader.offsetHeight) >=
              $muted.offsetTop - 15;

          const stickOpenToBottom = $open && containerBottom <= $open.offsetTop;

          const stickMutedToBottom =
            $muted && containerBottom <= $muted.offsetTop;

          if ($pinnedHeader instanceof window.Element) {
            $pinnedHeader.style.top = stickPinnedToTop
              ? 0
              : `${$pinnedHeader.style.top}px`;
            if (stickPinnedToTop) {
              addClass($pinnedHeader, styles.stickyTop);
              addClass($pinnedHeader, styles.stickyBottom);
            } else {
              removeClass($pinnedHeader, styles.stickyTop);
              removeClass($pinnedHeader, styles.stickyBottom);
            }
          }
          if ($openHeader instanceof window.Element) {
            $openHeader.style.top = stickOpenToTop
              ? `${$pinnedHeader.offsetHeight}px`
              : 'unset';
            $openHeader.style.bottom = stickOpenToBottom
              ? `${$mutedHeader.offsetHeight}px`
              : `${$openHeader.style.bottom}px`;

            if (stickOpenToTop) {
              addClass($openHeader, styles.stickyTop);
            } else {
              removeClass($openHeader, styles.stickyTop);
            }

            if (stickOpenToBottom) {
              addClass($openHeader, styles.stickyBottom);
            } else {
              removeClass($openHeader, styles.stickyBottom);
            }
          }
          if ($mutedHeader && $mutedHeader instanceof window.Element) {
            $mutedHeader.style.top = stickMutedToTop
              ? `${$pinnedHeader.offsetHeight + $openHeader.offsetHeight}px`
              : `${$mutedHeader.style.top}px`;
            $mutedHeader.style.bottom = stickMutedToBottom ? 0 : 'unset';

            if (stickMutedToTop) {
              addClass($mutedHeader, styles.stickyTop);
            } else {
              removeClass($mutedHeader, styles.stickyTop);
            }

            if (stickMutedToBottom) {
              addClass($mutedHeader, styles.stickyBottom);
            } else {
              removeClass($mutedHeader, styles.stickyBottom);
            }
          }
        }
      }
    },
    400,
    { leading: true }
  );

  onModeChange = (bucket, style) => {
    this.setState(
      (state) => {
        return {
          mode: {
            ...state.mode,
            [bucket]: style,
          },
        };
      },
      () => ChatsProxy.saveViewPreferences(VIEW_MODE_KEYS[bucket], style)
    );
  };

  onSortEnd = ({ newIndex, oldIndex }) => {
    const { ownerGuid, conversationBuckets } = this.props;
    const { pinned } = conversationBuckets;
    const pins = arrayMove(pinned, oldIndex, newIndex);
    const pinsOrder = pins.map(({ jid }) => jid);
    ChatsProxy.savePinsOrder(ownerGuid, pinsOrder);
  };

  componentDidUpdate(prevProps) {
    const { openJids, ownerGuid, unreadMsgIds } = this.props;
    if (prevProps.openJids !== openJids && openJids.length > 0) {
      this.throttledStickHeaders();
      _contactSubscription.update(ownerGuid, openJids);
    }
    if (prevProps.ownerGuid !== ownerGuid) {
      _chatsSubscription.update(ownerGuid);
      const peers = Object.keys(omit(unreadMsgIds, openJids || []));
      this.openUnreadChats(peers);
    }
  }

  openUnreadChats = (peers) => {
    const { ownerGuid } = this.props;
    if (ownerGuid) {
      batch(() =>
        peers.forEach((peer) =>
          ChatsProxy.openConversation({ jid: peer, ownerGuid }, false)
        )
      );
    }
  };

  closeCurrentConversation = () => {
    const {
      currentConversation,
      ownerGuid,
      conversationBuckets: { pinned },
    } = this.props;
    if (pinned && pinned.find(({ jid }) => jid === currentConversation)) {
      return false;
    }
    return ChatsProxy.removeConversation({
      jid: currentConversation,
      ownerGuid,
    });
  };

  reopenConversation = ChatsProxy.reopenConversation;

  navigateConversation = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
      const direction =
        /up/i.test(e.code || e.key) || e.shiftKey ? 'up' : 'down';
      ChatsProxy.navigateConversation(direction);
    }
  };

  markAllRead = (bucket) => {
    const { conversationBuckets } = this.props;
    const { [bucket]: conversationsToBeMarked } = conversationBuckets;
    conversationsToBeMarked.forEach((peer) => markLatestMessageAsRead(peer));
  };

  openChannelFeed = () => {
    const { ownerGuid } = this.props;
    ChatsProxy.openChannelFeed(ownerGuid);
  };

  componentWillUnmount() {
    this.throttledStickHeaders.cancel();
    _contactSubscription.unsubscribe();
    this.unsubscribeEscape();
    this.unsubscribeShiftEscape();
    this.unsubscribeNavigateChats();
    this.unsubscribeSecondaryNavigation();
    window.removeEventListener('resize', this.throttledStickHeaders);
  }

  shouldShowInvite(memberCount, teamUrl, collapsed, isDMBlocked, currentOwner) {
    if (!currentOwner) {
      return false;
    }
    if (isDMBlocked && !currentOwner.isAdmin) {
      return false;
    }
    return teamUrl && memberCount <= 9 && !collapsed;
  }

  render() {
    const {
      ownerGuid,
      currentConversation,
      openNewChat,
      openRecent,
      teamUrl,
      collapsed,
      memberCount,
      conversationBuckets,
      bucketInfo,
      unreadChannelCount,
      isDMBlocked,
      currentOwner,
    } = this.props;

    if (!ownerGuid) return null;

    const { mode } = this.state;

    const showInviteWidget = this.shouldShowInvite(
      memberCount,
      teamUrl,
      collapsed,
      isDMBlocked,
      currentOwner
    );

    const { pinned: pinnedChats, open, muted } = conversationBuckets;

    const { open: openInfo, muted: mutedInfo, pinned: pinnedInfo } = bucketInfo;

    return (
      <div
        onScroll={this.throttledStickHeaders}
        ref={container}
        className={classNames(styles.activeChats, {
          [styles.collapsed]: collapsed,
        })}
      >
        {pinnedChats.length ? (
          <Fragment>
            <div
              onClick={scrollToPinned}
              onKeyDown={(e) => e.key === 'Enter' && scrollToPinned()}
              role='button'
              tabIndex='0'
              ref={pinnedHeaderRef}
              className={classNames(
                styles.header,
                styles.pinned,
                styles[mode.pinned]
              )}
            >
              <div className={styles.background} />
              <div className={styles.chatType}>
                <FormattedMessage id='pinned_conversation_separator_text' />
              </div>
              {pinnedInfo.count > 0 ? (
                <Badge
                  background={
                    pinnedInfo.hasMention ? colors.mention : colors.unread
                  }
                  className={styles.badge}
                >
                  {pinnedInfo.count}
                </Badge>
              ) : null}
              <ViewModes
                mode={mode.pinned}
                type={PINNED}
                onModeChange={this.onModeChange}
              />
            </div>
            <SortablePinnedChats
              collapsed={collapsed}
              pinnedChats={pinnedChats}
              ownerGuid={ownerGuid}
              mode={mode.pinned}
              currentConversation={currentConversation}
              helperClass={classNames('sortableHelper', styles.sorting)}
              lockAxis='y'
              onSortEnd={this.onSortEnd}
              distance={10}
            />
          </Fragment>
        ) : null}
        <div
          onClick={scrollToOpen}
          onKeyDown={(e) => e.key === 'Enter' && scrollToOpen()}
          role='button'
          tabIndex='0'
          ref={openHeaderRef}
          className={classNames(styles.header, styles.open, styles[mode.open])}
        >
          <div className={styles.background} />
          <div className={styles.chatType}>
            <FormattedMessage id='direct_conversation_separator_text' />
          </div>
          {openInfo.count > 0 ? (
            <Badge
              background={openInfo.hasMention ? colors.mention : colors.unread}
              className={styles.badge}
            >
              {openInfo.count}
            </Badge>
          ) : null}
          <ViewModes
            mode={mode.open}
            type={OPEN}
            onModeChange={this.onModeChange}
          />
        </div>
        <div className={styles.chats} ref={openRef}>
          {open.map((contact) => (
            <ChatItem
              collapsed={collapsed}
              key={`${ownerGuid}-${contact.jid}`}
              mode={mode.open}
              bucket={OPEN}
              contact={contact}
              ownerGuid={ownerGuid}
              isSelected={currentConversation === contact.jid}
            />
          ))}
          <div
            className={classNames(styles.emptyState, {
              [styles.center]: open.length === 0,
            })}
          >
            {!collapsed && open.length === 0 ? (
              <Fragment>
                <Image src={emptyState} alt='No open chats' />
                <div className={styles.emptyText}>
                  <FormattedMessage id='active_bucket_empty_text' />
                </div>
              </Fragment>
            ) : null}
            {collapsed ? null : (
              <div className={styles.links}>
                {open.length === 0 ? (
                  <Button
                    size='small'
                    link
                    kind='secondary'
                    onClick={openNewChat}
                  >
                    <FormattedMessage id='active_bucket_empty_start_conversation' />
                  </Button>
                ) : null}
                <Button
                  style={{ marginLeft: '10px' }}
                  size='small'
                  link
                  kind='secondary'
                  onClick={openRecent}
                >
                  {open.length === 0 ? (
                    <FormattedMessage id='active_bucket_empty_open_recent' />
                  ) : (
                    <FormattedMessage id='active_bucket_recent_button' />
                  )}
                </Button>
              </div>
            )}
          </div>
        </div>
        {showInviteWidget ? (
          <InviteWidget teamUrl={teamUrl} ownerGuid={ownerGuid} />
        ) : null}
        <div
          className={classNames(styles.chatItem, styles.channelFeed, {
            [styles.selected]: currentConversation === ALL_CHANNEL_FEED,
            [styles.hasUnread]: unreadChannelCount > 0,
          })}
          role='button'
          onClick={this.openChannelFeed}
          tabIndex='-1'
          onKeyDown={(e) => isEnterPressed(e) && this.openChannelFeed()}
        >
          <div className={styles.iconText}>
            <div className={styles.channelFeedIcon} />
            {!collapsed ? (
              <div className={styles.channelFeedText}>
                <FormattedMessage id='all_channel_feed_tab_text' />
              </div>
            ) : null}
          </div>
          {!collapsed && unreadChannelCount > 0 ? (
            <div>
              <Badge
                background={colors.mutedUnread}
                className={classNames(styles.badge, styles.headerBadge)}
              >
                {unreadChannelCount}
              </Badge>
            </div>
          ) : null}
        </div>
        {muted.length ? (
          <div
            onClick={scrollToMuted}
            onKeyDown={(e) => e.key === 'Enter' && scrollToMuted()}
            role='button'
            tabIndex='0'
            ref={mutedHeaderRef}
            className={classNames(
              styles.header,
              styles.muted,
              styles[mode.muted]
            )}
          >
            <div className={styles.background} />
            <div className={styles.chatType}>
              <FormattedMessage id='channels_separator_text' />
            </div>
            <div className={styles.headerActions}>
              {collapsed ? null : (
                <OpenGroupCount>
                  {({ openGroups, onClick }) => {
                    return (
                      <div
                        role='button'
                        onKeyDown={() => {}}
                        tabIndex='0'
                        className={styles.openGroup}
                        onClick={(e) => onClick(e, 'active-chats')}
                      >
                        <div className={styles.globe} />
                        <div className={styles.count}>{openGroups}</div>
                      </div>
                    );
                  }}
                </OpenGroupCount>
              )}
              {mutedInfo.count > 0 ? (
                <Badge
                  background={colors.mutedUnread}
                  className={classNames(styles.badge, styles.headerBadge)}
                >
                  {mutedInfo.count}
                </Badge>
              ) : null}
              <ViewModes
                mode={mode.muted}
                type={MUTED}
                onModeChange={this.onModeChange}
                markAllRead={this.markAllRead}
              />
            </div>
          </div>
        ) : null}
        <div className={styles.chats} ref={mutedRef}>
          {muted.map((contact) => (
            <ChatItem
              collapsed={collapsed}
              key={`${ownerGuid}-${contact.jid}`}
              mode={mode.muted}
              bucket={MUTED}
              contact={contact}
              ownerGuid={ownerGuid}
              isSelected={currentConversation === contact.jid}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default IntlWrapper(ActiveChats, translations);
