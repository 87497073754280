export default {
  join_group_description:
    'Veja abaixo a lista de canais p\u00fablicos na sua equipe.',
  discover_group_disc:
    'Veja abaixo a lista de canais p\u00fablicos na sua equipe.',
  start_new_channel_link_text: 'Inicie um novo canal',
  no_results_found: 'Nenhum resultado encontrado',
  no_open_groups:
    'Parece que n\u00e3o h\u00e1 Canais p\u00fablicos nos quais ingressar',
  no_open_groups_description:
    'Canais p\u00fablicos permitem que a sua equipe descubra conversas e participe delas com facilidade. Por exemplo, {example}',
  no_open_groups_description_example:
    'Equipe de marketing, Ideias e inspira\u00e7\u00e3o',
  create_a_channel: 'Inicie um novo canal',
  public_channel_guest_restriction_heading: 'Discover and Join Public Channels',
  public_channel_guest_restriction_message:
    'Oops! Guest on Flock cannot view or create public channels in this team.',
  okay: 'Okay',
};
