export default {
  upgrade_pro_private: 'Upgrade to Flock PRO for Private Channels',
  upgrade_pro_public: 'Upgrade to Flock PRO for more Public Channels',
  private_channel_pro_feature:
    'Creating a Private Channel is a Flock PRO feature.',
  upgrade_pro_or_create_public:
    'Upgrade to Flock PRO or create a Public Channel',
  upgrade_pro_or_create_private:
    'Upgrade to Flock PRO to create a Private Channel.',
  public_channel_limit_starter_plan:
    'You can create up to {publicLimit} Public Channels in the Starter plan. To create more Channels, upgrade to Flock PRO.',
  create_public_button_label: 'Create Public Channel',
};
