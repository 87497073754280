/* eslint-disable no-await-in-loop */
import bridge from '@/utils/bridge';

const internetCheckUrls: string[] = [
  'https://www.google-analytics.com/collect?v=1&t=pageview&tid=UA-89107015-1&cid=10&dp=%2Fconnection_check',
  'https://s3.eu-central-1.amazonaws.com/prod-euc1.chat.region-connectivity/region_connectivity/eu_flag_yellow_low.jpeg',
  'https://flock-apps-public.s3.amazonaws.com/region_connectivity/us_flag.jpeg',
];

type Config = {
  proteusCheckUrls: string[];
  doorCheckUrls: string[];
  doorSocketUrl: string;
  fileServerUrls: string[];
};

let config: Config = {
  proteusCheckUrls: [],
  doorCheckUrls: [],
  doorSocketUrl: '',
  fileServerUrls: [],
};

const defaultConfig: Config = {
  proteusCheckUrls: ['https://proxy.handler.talk.to/status'],
  doorCheckUrls: ['https://door.handler.talk.to/status'],
  doorSocketUrl: 'wss://door.handler.talk.to',
  fileServerUrls: ['https://fs.flock.com/filesharing/status'],
};

let isConfigFetched: boolean = false;
const TEN_SECONDS = 10 * 1000;

const getFileServerStatusUrl = async () => {
  const url = await bridge.ask('HydraServer', 'getStartupOption', [
    'fileSharingUrl',
  ]);
  return `${url}/status`;
};

const fetchConfigOptions = async () => {
  if (isConfigFetched) return;
  try {
    config.proteusCheckUrls.push(
      await bridge.ask('HydraServer', 'getStartupOption', ['proteusCheckUrl'])
    );
    config.doorCheckUrls.push(
      await bridge.ask('HydraServer', 'getStartupOption', ['doorCheckUrl'])
    );
    config.fileServerUrls.push(await getFileServerStatusUrl());
    config.doorSocketUrl = await bridge.ask('HydraServer', 'getStartupOption', [
      'boshWSServiceUrl',
    ]);
    isConfigFetched = true;
  } catch {
    config = defaultConfig;
  }
};
fetchConfigOptions();

const getUncachedUrl = (originalUrl: string): string => {
  const url = new URL(originalUrl);
  url.searchParams.append('date', `${Date.now()}${Math.random()}`);
  return url.href;
};

const checkConnection = async (
  connectionCheckUrls: string[],
  useOriginal: boolean = false
) => {
  let isSuccess = true;
  for (let i = 0; i < connectionCheckUrls.length; i += 1) {
    const url = useOriginal
      ? connectionCheckUrls[i]
      : getUncachedUrl(connectionCheckUrls[i]);

    try {
      const result = await fetch(url);
      if (result.status < 200 || result.status >= 300) {
        // HTTP response codes between 200 and 299 indicate success
        isSuccess = false;
        break;
      }
    } catch {
      isSuccess = false;
      break;
    }
  }
  return isSuccess;
};

const checkWebsocketConnection = () => {
  const ws = new WebSocket(config.doorSocketUrl);
  const promise = new Promise((resolve) => {
    ws.onopen = () => {
      ws.close();
      resolve(true);
    };
    ws.onerror = () => {
      resolve(false);
    };
    ws.onclose = () => {
      resolve(false);
    };
    setTimeout(() => {
      ws.close();
      resolve(false);
    }, TEN_SECONDS);
  });
  return promise;
};

const sleep = async (time: number) => {
  return new Promise((r) => setTimeout(r, time));
};

const networkCheck = {
  name: 'Network',
  // description: 'Checking Network availability',
  getPromise: async () => {
    await fetchConfigOptions();
    await sleep(1000);
    return window.navigator.onLine;
  },
};

const internetCheck = {
  name: 'Internet',
  // description: 'Checking Internet connectivity',
  getPromise: async () => {
    return checkConnection(internetCheckUrls);
  },
};

const proteusCheck = {
  name: 'Proteus',
  // description: 'Proteus status check',
  getPromise: async () => {
    return checkConnection(config.proteusCheckUrls);
  },
};

const websocketCheck = {
  name: 'Websocket',
  // description: 'Websocket status check',
  getPromise: async () => {
    const result = await checkWebsocketConnection();
    return result;
  },
};

const FileServerCheck = {
  name: 'FileServer',
  // description: 'File server status check',
  getPromise: async () => {
    return checkConnection(config.fileServerUrls);
  },
};

const doorCheck = {
  name: 'Door',
  // description: 'Door status check',
  getPromise: async () => {
    return checkConnection(config.doorCheckUrls, true);
  },
};

const checksArray = [
  networkCheck,
  internetCheck,
  proteusCheck,
  websocketCheck,
  FileServerCheck,
  doorCheck,
];

export default checksArray;
