import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Popover from '@/components/common/Popover';
import BuddyContactCard from './ContactCards/Buddy';
import GroupContactCard from './ContactCards/Group';
import Name from './Name';
import MemberCount from './MemberCount';
import AddMember from './AddMember';
import LastSeen from './LastSeen';
import Description from './Description';
import ActionButtons from './ActionButtons';
import css from './Header.css';

function isParentHasClass(node, className) {
  if (!node) {
    return false;
  }
  if (node.classList.contains(className)) {
    return true;
  }
  return isParentHasClass(node.parentElement, className);
}

/*
  Header which hosts all the individual feature components for a conversation header. 
  * Manages header layout.
  As of now, this component doesn't differentiate b/w 1-1 or group conv. 
  So each feature component needs to decide on it's own, what to render based on the type of conv.

  Components/Features
  > Name
  > MemberCount
  > add member widget
  > description/status
  > action buttons, this is basically actions for current conversation dispalyed on right of header.
  > Last seen, for 1-1 peer.
  > Contact Cards, this component decides which contact card to display.

*/

// Contact card popover closes whenever there's a click outside of it. But in some special cases when
// we don't want to close on outside click, we can add this class to the desired element or it's parent.
// If clicked element or it's parent has this below class contact card popover will not close.
const CHILD_POPOVER_CLASS = 'Child-of-Header';

class Header extends PureComponent {
  static propTypes = {
    peer: PropTypes.object.isRequired,
  };

  // static defaultProps = {
  //   peer: undefined,
  // };

  state = {
    showContactCard: false,
    isAddMemberActive: false,
  };

  componentDidUpdate(prevProps) {
    const { peer: { jid: prevJid } = {} } = prevProps;
    const { peer: { jid: currentJid } = {} } = this.props;
    if (prevJid !== currentJid) {
      this.setState({
        showContactCard: false,
        isAddMemberActive: false,
      });
    }
  }

  onAddMemberVisibilityChange = (inputVisible) => {
    // set showContactCard false while switching addMemberActive flag.
    // This is to make sure UI is similar as hydra's UI
    if (inputVisible) {
      this.setState({
        isAddMemberActive: true,
        showContactCard: false,
      });
    } else {
      this.setState({
        isAddMemberActive: false,
        showContactCard: false,
      });
    }
  };

  onClickOutside = (event) => {
    const { target } = event;
    if (!isParentHasClass(target, CHILD_POPOVER_CLASS)) {
      this.hideContactCard();
    }
  };

  hideContactCard = () => {
    this.setState({ showContactCard: false });
  };

  showContactCard = () => {
    this.setState(({ isAddMemberActive }) => {
      if (isAddMemberActive) {
        return null;
      }
      const {
        peer: { type, hasPostMessagePrivilege, isBot },
      } = this.props;
      if (type === 'group' && !hasPostMessagePrivilege) {
        return null;
      }
      if (isBot) {
        return null;
      }
      return { showContactCard: true };
    });
  };

  render() {
    const { peer, memberCount } = this.props;
    if (!peer) {
      return null;
    }
    const { showContactCard, isAddMemberActive } = this.state;
    const {
      type,
      hasAddMemberPrivilege,
      isAnnouncementOnly,
      isDeactivated = false,
      isDeleted = false,
    } = peer;
    return (
      <div className={css.Container}>
        <div className={css.Layout_left}>
          <div className={css.Layout_left_top}>
            <Popover
              open={showContactCard && !isAddMemberActive}
              triggerClass={`${css['d-flex']} ${css.pointer} ${css.width}`}
              position='bottom'
              popOverContent={
                type === 'group' ? (
                  <GroupContactCard
                    peer={peer}
                    closePopover={this.hideContactCard}
                    memberCount={memberCount}
                  />
                ) : (
                  <BuddyContactCard
                    peer={peer}
                    closePopover={this.hideContactCard}
                  />
                )
              }
              showArrow
              arrowStyle={{ left: '10px' }}
              arrowPosition='top'
              heightDifference={26}
              onClickOutside={this.onClickOutside}
              onClick={this.showContactCard}
            >
              <Name peer={peer} />
            </Popover>
          </div>
          <div className={css.Layout_left_bottom}>
            {!isDeactivated && !isDeleted && <LastSeen peer={peer} />}
            <MemberCount
              peer={peer}
              showContactCard={this.showContactCard}
              memberCount={memberCount}
            />
            {hasAddMemberPrivilege ? (
              <AddMember
                peer={peer}
                onInputVisibilityChange={this.onAddMemberVisibilityChange}
              />
            ) : null}
            {!isAddMemberActive && !isDeactivated && !isDeleted ? (
              <Description peer={peer} />
            ) : null}
          </div>
        </div>

        {!isAnnouncementOnly && !isDeactivated && !isDeleted ? (
          <div className={css.Layout_right}>
            <ActionButtons peer={peer} />
          </div>
        ) : null}
      </div>
    );
  }
}

export default Header;
