import React, { Component } from 'react';
import PropTypes from 'prop-types';
import currentSessionHOC from '@/connectHOCs/Sessions/currentSession';
import {
  openCreateChannelScreen,
  openInviteContactScreen,
} from '@/utils/ShellUtils';

import { getOpenGroupCountNotJoined } from '@/utils/SessionUtils';
import bridge from '@/utils/bridge';
import cssStyles from './PlusWidgetMenu.css';

const gaSourceName = 'search_plus';
const MAX_COUNT = 99;

const MenuItems = [
  {
    id: 'create_new_channel',
    label: 'Create a new channel',
    subLabel: 'For your projects or topics',
    iconClass: 'group',
    action(currentSession) {
      openCreateChannelScreen(currentSession, gaSourceName);
    },
  },
  {
    id: 'join_public_channels',
    label: 'Join public channels',
    subLabel: 'Discover shared groups',
    iconClass: 'join_channel',
    action() {
      bridge.publish('odara-group-discovery-modal', [gaSourceName]);
    },
  },
  {
    id: 'invite_contacts',
    label: 'Invite contacts',
    subLabel: 'Add members to your team',
    iconClass: 'invite',
    action() {
      openInviteContactScreen(gaSourceName);
    },
  },
];
class PlusWidgetMenu extends Component {
  static propTypes = {
    currentSession: PropTypes.object,
    onItemClick: PropTypes.func,
  };

  static defaultProps = {
    currentSession: {},
    onItemClick: () => {},
  };

  state = {
    openChannelCount: 0,
  };

  constructor(props) {
    super();
    if (props.currentSession.id) {
      getOpenGroupCountNotJoined(props.currentSession.id).then(
        this.setOpenGroupCount
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { currentSession } = this.props;
    if (prevProps.currentSession.id !== currentSession.id) {
      getOpenGroupCountNotJoined(currentSession.id).then(
        this.setOpenGroupCount
      );
    }
  }

  setOpenGroupCount = (count) => {
    this.setState({
      openChannelCount: count,
    });
  };

  onItemClick = (event, item) => {
    if (item.action) {
      const { currentSession } = this.props;
      item.action.call(null, currentSession, event);
    }
    const { onItemClick } = this.props;
    onItemClick();
  };

  render() {
    const { openChannelCount } = this.state;
    const count =
      openChannelCount > MAX_COUNT ? '(99+)' : `(${openChannelCount})`;

    const countText = openChannelCount > 0 ? count : null;
    return (
      <div className={cssStyles.Menu}>
        {MenuItems.map((item) => (
          <div
            key={item.id}
            className={cssStyles.Item}
            onClick={(e) => this.onItemClick(e, item)}
            onKeyDown={() => {}}
            role='menuitem'
            tabIndex='-1'
            data-pendo-hook={`plus-${item.id}`}
          >
            <div className={`plus-item__icon ${item.iconClass}`} />
            <div>
              <div className={cssStyles.label}>
                {item.label}{' '}
                {item.id === 'join_public_channels' ? countText : ''}
              </div>
              <div className={cssStyles['sub-label']}>{item.subLabel}</div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default currentSessionHOC(PlusWidgetMenu);
