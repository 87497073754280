export default {
  delete_channel_confirm_button: 'Sim, Sair e Excluir',
  leave_confirm_button: 'Sim, sair',
  channel_delete_confirm_message:
    'Este canal ser\u00e1 exclu\u00eddo, pois voc\u00ea \u00e9 o \u00faltimo membro. Voc\u00ea tem certeza?',
  channel_leave_warning_message:
    'Voc\u00ea ter\u00e1 que ser convidado para retornar a este canal. Voc\u00ea tem certeza?',
  channel_leave_confirm_message:
    'Voc\u00ea tem certeza de que deseja sair deste canal?',
  leave_channel_title: 'Sair do canal?',
};
