import * as React from 'react';
import { openUpgradeUrl, notifyAdminForUpgrade } from '@/utils/SessionUtils';
import UpgradeToProHook from '@/components/upgradeProHook/UpgradeToPro';
import upgradeIconSrc from '@/assets/upgrade.svg';
import css from './EditorPaywall.css';

const text = {
  admin: 'Upgrade to PRO',
  member: 'Request PRO upgrade',
};

const UPGRADE_SOURCE = 'message_paywall';
const UPGRADE_CONTEXT = 'PRIVATE_CHANNEL_POST_MESSAGE';

/**
 * Whether to show the paywall or not based on the parameters passed.
 *
 * @param {Object} param
 * @param {boolean} param.mas If the application was downloaded from Mac App Store.
 * @param {('open' | 'close')} param.groupType If the group is public ('open') or private ('close')
 * @param {boolean} param.free If current team is on a free plan.
 * @param {Array} param.exclusions An array of features that are allowed in the free plan for the current team.
 */
function isPaywallVisible({ mas, groupType, free, exclusions }) {
  return (
    !mas && groupType === 'close' && free && !exclusions.includes('CHANNELS')
  );
}

/**
 *
 * @param {{ children: {}, groupType: ('open' | 'close'), session: any }} arg
 */
function EditorPaywall({ children, groupType, session }) {
  /**
   * Either opens the admin dashboard or sends a request to the admin
   * to upgrade depending on whether the user is an admin or not.
   *
   * @param {boolean} admin If the user is an admin.
   */
  const handleUpgradeCta = (admin) => {
    if (admin) {
      openUpgradeUrl(session.id, UPGRADE_SOURCE);
    } else {
      notifyAdminForUpgrade(session.id, UPGRADE_CONTEXT);
    }
  };

  return (
    <UpgradeToProHook source='editor' session={session}>
      {({
        isMacAppStore: mas,
        isAdmin: admin,
        isFreeTeam: free,
        exclusions,
      }) => {
        if (
          isPaywallVisible({
            mas,
            groupType,
            free,
            exclusions,
          })
        ) {
          return (
            <div
              tabIndex='0'
              role='button'
              onClick={() => handleUpgradeCta(admin)}
              onKeyDown={({ key }) =>
                key === 'ENTER' && handleUpgradeCta(admin)
              }
              className={css.EditorPaywall}
            >
              <img alt='' src={upgradeIconSrc} />
              <span className={css.Highlight}>
                {text[admin ? 'admin' : 'member']}
              </span>
              <span>
                {' '}
                to start sending and receiving messages in this group
              </span>
            </div>
          );
        }

        return children;
      }}
    </UpgradeToProHook>
  );
}

export default React.memo(EditorPaywall);
