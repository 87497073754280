const defaultState = {};

export default (ActionTypes) => {
  return (state = defaultState, action) => {
    try {
      switch (action.type) {
        case ActionTypes.UPDATE_ALL_MESSAGES: {
          const { addedMessages, removedIds, ownerGuid } = action.payload;
          const messages = (state && { ...state[ownerGuid] }) || {};
          if (addedMessages)
            addedMessages.forEach((message) => {
              messages[message.message_cache_id] = message;
            });
          if (removedIds)
            removedIds.forEach((messageCacheId) => {
              delete messages[messageCacheId];
            });
          return {
            ...state,
            [ownerGuid]: messages,
          };
        }

        default:
          return state;
      }
    } catch (error) {
      console.error(
        `Error in message reducer: ${error.message || error.code || error}`,
        error
      );
    }
    return state;
  };
};
