import React, { forwardRef } from 'react';
import { RECEIPT_STATE } from '@/utils/constants';
import css from './Receipt.css';

const { SENDING, SENT, RECEIVED, READ, ERROR, FAILED } = RECEIPT_STATE;

const classNames = {
  [SENDING]: css.sendingReceipt,
  [SENT]: css.sentReceipt,
  [RECEIVED]: css.receivedReceipt,
  [READ]: css.readReceipt,
  [ERROR]: css.errorReceipt,
  [FAILED]: css.errorReceipt,
};

export default React.memo(
  forwardRef(function Receipt(
    { receiptState, className, excludeReceipts, ...props },
    ref
  ) {
    if (
      !receiptState ||
      (excludeReceipts && excludeReceipts.includes(receiptState))
    ) {
      return null;
    }

    return (
      <div
        className={`${css.receipt} ${classNames[receiptState]} ${className}`}
        ref={ref}
        {...props}
      />
    );
  })
);
