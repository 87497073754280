export default {
  block_tooltip_unpin: 'Desanclar',
  block_tooltip_pin_chat: 'Anclar esta pesta\u00f1a de chat',
  channels_separator_text: 'CANALES SILENCIADOS',
  direct_conversation_separator_text: 'ABRIR CHATS',
  pinned_conversation_separator_text: 'CHATS ANCLADOS',
  display_density_compact: 'Compacto',
  display_density_thin: 'Agradable',
  display_density_expanded: 'C\u00f3modo',
  display_density_classic: 'Cl\u00e1sico',
  read_options_mark_as_read: 'Marcar todoscomo le\u00eddos',
  select_drop_down_title_display_density: 'Mostrar densidad',
  select_drop_down_title_read: 'Leer',
  invite_contact_tooltip: 'Invitar',
  browse_channel_tooltip: 'Unirte a canales',
  create_channel_tooltip: 'Crear canales',
  search_recent_tooltip: 'Tus chats recientes',
  active_bucket_empty_text: 'No hay chats abiertos',
  active_bucket_empty_start_conversation: 'Iniciar nuevo',
  active_bucket_empty_open_recent: 'Ver recientes',
  active_bucket_recent_button: 'Chats recientes',
  all_channel_feed_tab_text: 'Mensajes recientes de todos los canales',
  contactAdder_inviteHeader_title: 'Invita a personas a tu equipo',
  contactAdder_inviteHeader_desc:
    'Potencia el uso de Flock invitando a compa\u00f1eros de trabajo, clientes, proveedores... A\u00f1ade participantes introduciendo sus correos electr\u00f3nicos.',
  contactAdder_invite_error:
    'No tienes privilegios para a\u00f1adir a miembros a este equipo.',
  invite_buttonText: 'Invitar',
  text_shareUrlWithTeam: 'O comparte esta URL con el equipo',
  text_enterEmailAddress:
    'Introducir una direcci\u00f3n de correo electr\u00f3nico',
  enter_valid_email:
    'Introduce una direcci\u00f3n de correo electr\u00f3nico v\u00e1lida',
};
