import * as React from 'react';
import cx from 'classnames';
import noop from '@/utils/noop';
import css from './ColorSwatch.css';

const BG_COLOR_VALUES = [
  'transparent',
  '#81aaf1',
  '#f99898',
  '#f7cd84',
  '#67c0c4',
  '#75cff1',
  '#6accb5',
  '#c89af0',
];

export default React.memo(function ColorSwatch({ onColorChange = noop }) {
  return (
    <div className={css.ColorSwatch}>
      {BG_COLOR_VALUES.map((value) => (
        <div className={css.ColorOption} key={value} title={value}>
          <input
            className={css.ColorOptionInput}
            name='avatar-bgc'
            id={`color-${value}`}
            type='radio'
            onChange={() => onColorChange(value)}
            defaultChecked={value === 'transparent'}
          />
          <label
            className={cx([
              css.ColorOptionCircle,
              {
                [css.ColorOptionCircleTransparent]: value === 'transparent',
              },
            ])}
            style={{
              backgroundColor: value,
            }}
            htmlFor={`color-${value}`}
          >
            {value}
          </label>
        </div>
      ))}
    </div>
  );
});
