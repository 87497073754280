import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import noop from '@/utils/noop';
import styles from './CustomList.css';

class CustomList extends Component {
  static propTypes = {
    listItemClass: PropTypes.string,
    stopPropagation: PropTypes.bool,
    className: PropTypes.string,
    onItemClick: PropTypes.func,
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
    items: PropTypes.arrayOf(PropTypes.object),
    innerRef: PropTypes.object,
    onBlur: PropTypes.func,
  };

  static defaultProps = {
    listItemClass: '',
    stopPropagation: true,
    className: '',
    component: Fragment,
    items: [],
    onItemClick: noop,
    innerRef: null,
    onBlur: noop,
  };

  getListItemHelpers() {
    const { listItemClass } = this.props;
    return {
      className: classNames(styles.suggestionItem, listItemClass),
    };
  }

  handleItemClick = (e, o) => {
    const { stopPropagation, onItemClick } = this.props;
    if (stopPropagation) e.stopPropagation();
    onItemClick(o);
  };

  render() {
    const {
      className = '',
      items,
      innerRef,
      onBlur,
      component: CustomListItem,
    } = this.props;
    return (
      <ul
        tabIndex='-1'
        ref={innerRef}
        onBlur={onBlur}
        className={classNames(styles.customList, className)}
      >
        {items.map((o, index) => (
          <li
            role='menuitem'
            tabIndex='0'
            onKeyDown={noop}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            {...this.getListItemHelpers()}
            onMouseDown={(e) => this.handleItemClick(e, o)}
          >
            <CustomListItem {...o} />
          </li>
        ))}
      </ul>
    );
  }
}

export default React.forwardRef((props, ref) => (
  <CustomList innerRef={ref} {...props} />
));
