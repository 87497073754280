import React, { useState } from 'react';
import Button from '@/components/common/Button';
import { notifyAdminForUpgrade, openUpgradeUrl } from '@/utils/SessionUtils';
import { logMedusaGenericEvent } from '@/utils/logAnalytics';

const buttonStrings = {
  upgrade: 'Upgrade to Flock PRO',
  startTrial: 'Start Flock PRO trial',
  requestUpgrade: 'Request Flock PRO Upgrade',
  requestTrial: 'Request Flock PRO Trial',
};

export default function PaywallButton({
  isAdmin,
  isEligibleForFreeProTrial,
  sessionId,
  className,
  gaSource,
  feature,
  source,
  close,
}) {
  const [requestedUpgrade, setRequestedUpgrade] = useState(false);

  function requestUpgrade() {
    logMedusaGenericEvent('paywall_clicked', {
      feature,
      source,
    });
    if (isAdmin) {
      openUpgradeUrl(sessionId, source);
    } else {
      notifyAdminForUpgrade(sessionId, gaSource);
      if (close) close();
    }
    setRequestedUpgrade(true);
  }

  return isAdmin ? (
    <Button onClick={requestUpgrade} className={className}>
      {isEligibleForFreeProTrial
        ? buttonStrings.startTrial
        : buttonStrings.upgrade}
    </Button>
  ) : (
    <Button
      disabled={requestedUpgrade}
      onClick={requestUpgrade}
      className={className}
    >
      {isEligibleForFreeProTrial
        ? buttonStrings.requestTrial
        : buttonStrings.requestUpgrade}
    </Button>
  );
}
