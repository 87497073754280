import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import IntlWrapper from '@/Wrappers/IntlWrapper';
import { Image } from '@/components/common';
import emptyFeedImg from '@/assets/empty-channel-feed.svg';
import translations from './I18N';
import styles from './AllChannelFeed.css';
import GroupMessage from './GroupMessage';

function AllChannelFeed({ ownerGuid, sortedGrouspWithMessages }) {
  // Assume that you do not need to subscribe to contacts in UnreadMessagesGroupWise,
  // because they're already in the store. Get them from redux. Also, all logic inside selector

  return (
    <div className={styles.channelFeed}>
      <div className={styles.channelFeedHeader}>
        <div className={styles.channelFeedTitle}>
          <FormattedMessage id='channel_feed_title' />
        </div>
      </div>
      {sortedGrouspWithMessages.length ? (
        <div className={styles.feedContainer}>
          {sortedGrouspWithMessages.map(({ jid, messageList, unreadCount }) => (
            <Fragment key={jid}>
              {messageList.length ? (
                <GroupMessage
                  jid={jid}
                  unreadCount={unreadCount}
                  messageList={messageList}
                  ownerGuid={ownerGuid}
                />
              ) : null}
            </Fragment>
          ))}
        </div>
      ) : (
        <div className={styles.emptyFeed}>
          <Image
            className={styles.emptyFeedImg}
            src={emptyFeedImg}
            alt='No more channel messages'
          />
          <div className={styles.emptyFeedText}>
            <FormattedMessage id='channel_feed_empty_text' />
          </div>
        </div>
      )}
    </div>
  );
}

// export default connect(mapStateToProps)(AllChannelFeed);
export default IntlWrapper(AllChannelFeed, translations);
