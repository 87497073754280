import { createSelector } from 'reselect';
import { getConversationType, DEFAULT_CONTACT_NAME } from '@/utils';
import ownerGuidSelector from './ownerGuid';

const defaultContactImageUrl = 'https://i.talk.to/avatar-default.png';

export function getDefaultContact(jid, ownerGuid) {
  return {
    jid,
    ownerGuid,
    name: jid.indexOf('@groups') > -1 ? 'Your Channel' : DEFAULT_CONTACT_NAME,
    chatName: DEFAULT_CONTACT_NAME,
    imageUrl: defaultContactImageUrl,
    canChatWith: true,
    type: getConversationType(jid),
  };
}

const defaultContacts = {};

const allContactsSelector = (state) => state.restricted.contact.contact_proxy;

export const contactsByGuidSelector = createSelector(
  allContactsSelector,
  (contacts) => (ownerGuid) => contacts[ownerGuid] || defaultContacts
);

const getOwnerGuidJid = (state, { ownerGuid, jid }) => ({ ownerGuid, jid });

export const getContact = createSelector(
  [contactsByGuidSelector, getOwnerGuidJid],
  (getContactsByOwnerGuid, { ownerGuid, jid }) => {
    const allContacts = getContactsByOwnerGuid(ownerGuid);
    if (allContacts) {
      return allContacts[jid] || getDefaultContact(jid, ownerGuid);
    }
    return getDefaultContact(jid, ownerGuid);
  }
);

export default createSelector(
  ownerGuidSelector,
  contactsByGuidSelector,
  (ownerGuid, getAllContactsByGuid) => getAllContactsByGuid(ownerGuid)
);
