import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { allSessionsSelector, currentSessionSelector } from '@/selectors';
import sessionsSubHOC from '@/subscribeHOCs/allSessions';
import bridge from '@/utils/bridge';
import { logMedusaGenericEvent } from '@/utils/logAnalytics';

import cssStyles from './MenuDropdown.css';

class SignOut extends Component {
  signOut = () => {
    const { allSessions, currentSession } = this.props;
    const teamsList = [];

    Object.values(allSessions).forEach((session) => {
      if (session.owner.email === currentSession.owner.email) {
        teamsList.push(session.sessionInfo.teamInfo.teamName);
      }
    });

    bridge.publish('odara-signout-modal', [teamsList]);
    logMedusaGenericEvent('settings_signout_clicked');
  };

  render() {
    const { currentSession } = this.props;
    if (currentSession) {
      return (
        <div
          role='menuitem'
          onKeyDown={() => {}}
          tabIndex='-1'
          className={cssStyles.dropdown_list_item}
          onClick={this.signOut}
          data-pendo-hook='menu-signout'
        >
          <div className={cssStyles.menu_item_info}>
            <div className={cssStyles.menu_item_label_red}>Sign Out</div>
            <div className={cssStyles.menu_item_sub_label}>
              {currentSession.owner.email}
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

SignOut.propTypes = {
  currentSession: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  allSessions: allSessionsSelector,
  currentSession: currentSessionSelector,
});

export default connect(mapStateToProps)(sessionsSubHOC(SignOut));
