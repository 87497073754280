import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import groupDiscoveryModalStyles from '@/components/Modal/GroupDiscoveryModal/GroupDiscoveryModalComponent.css';
import commonStyles from '@/components/common/common.css';

function GroupDiscoveryNoPublicChannels(props) {
  const { createChannel } = props;

  return (
    <div
      className={classNames(
        groupDiscoveryModalStyles.join_public_channels_modal,
        groupDiscoveryModalStyles.no_public_channels
      )}
    >
      <div className={groupDiscoveryModalStyles.modal_title}>
        <FormattedMessage id='no_open_groups' />
      </div>
      <div className={groupDiscoveryModalStyles.no_public_channels_image} />

      <div className={groupDiscoveryModalStyles.no_public_channels_description}>
        <FormattedMessage
          id='no_open_groups_description'
          values={{
            example: (
              <strong>
                <FormattedMessage id='no_open_groups_description_example' />
              </strong>
            ),
          }}
        />
      </div>

      <button
        type='button'
        className={classNames(
          commonStyles.btn,
          commonStyles.btn__action,
          commonStyles.btn_block
        )}
        onClick={createChannel}
      >
        <FormattedMessage id='create_a_channel' />
      </button>
    </div>
  );
}

export default GroupDiscoveryNoPublicChannels;
