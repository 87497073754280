import React, { useCallback } from 'react';
import Tippy from '@tippyjs/react';
import { addQueryParamtoHTTPUrls } from '@/utils';
import commonStyles from '@/components/common/common.css';
import cssStyles from './Item.css';

const Item = (props) => {
  const { menuItem, onAppClick, overflow } = props;

  const style = menuItem.icon
    ? {
        WebkitMask: `url(${addQueryParamtoHTTPUrls(
          menuItem.icon
        )}) center center/contain no-repeat`,
      }
    : {};

  const appClickHandler = useCallback(() => {
    onAppClick(menuItem);
  }, [menuItem, onAppClick]);

  if (!overflow) {
    const { labelDesc } = menuItem;
    return (
      <Tippy
        maxWidth={200}
        content={labelDesc}
        arrow
        theme='bootstrap'
        className={commonStyles.info_tooltip}
      >
        <div
          className={`${cssStyles.overLayIcon} ${cssStyles.limitedItemContainer}`}
          style={style}
          role='button'
          onClick={appClickHandler}
          tabIndex={-1}
          onKeyDown={() => {}}
        />
      </Tippy>
    );
  }

  return (
    <div
      className={`${cssStyles.moreMenuItemContainer} `}
      onClick={appClickHandler}
      role='button'
      tabIndex={-1}
      onKeyDown={() => {}}
    >
      <div className={`${cssStyles.icon}`} style={style} />
      <span className={cssStyles.menuItemLabel}>{menuItem.labelDesc}</span>
    </div>
  );
};

export default Item;
