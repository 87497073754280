import React from 'react';
import { hideModal } from '@/components/Modal/Modal';
import currentSessionSelector from '@/selectors/session';
import store from '@/store';
import { logMedusaEventWithConnectivity } from '../utils';
import checksArray from './Checks';
import { CheckObj, ResultObj } from '../Types';
import { getDescription } from './Descriptions';
import cssStyles from './TroubleShootChecks.css';

type State = {
  checks: CheckObj[];
};

type Props = {
  onCompletion: (result: ResultObj) => void;
};

class TroubleShootChecks extends React.Component<Props, State> {
  state: State = {
    checks: [],
  };

  componentDidMount() {
    this.startChecking();
  }

  startChecking = () => {
    this.addNextCheck();
  };

  postCompletion = () => {
    setTimeout(() => {
      const { onCompletion } = this.props;
      const { checks } = this.state;
      const failedTests = checks
        .filter((checkObj: CheckObj) => !checkObj.isSuccess)
        .map((checkObj: CheckObj) => checkObj.name);
      if (failedTests.length) {
        onCompletion?.({
          tests: failedTests,
          allTestPassed: false,
        });
      } else {
        onCompletion?.({
          tests: checks.map((checkObj: CheckObj) => checkObj.name),
          allTestPassed: true,
        });
      }
    }, 500);
  };

  addNextCheck = () => {
    const { checks } = this.state;
    const currentCheckIndex = checks.length;
    if (currentCheckIndex >= checksArray.length) {
      this.postCompletion();
      return;
    }
    const { name, getPromise } = checksArray[currentCheckIndex];
    const checkObj: CheckObj = {
      name,
      isSuccess: false,
      isLoading: true,
    };
    this.updateState(currentCheckIndex, checkObj, () =>
      this.settlePromise(getPromise, currentCheckIndex)
    );
  };

  settlePromise = (getPromise: Function, index: number) => {
    const promise: Promise<boolean> = getPromise();
    promise.then((isSuccess: boolean, status?: string) => {
      const updatedObj: Partial<CheckObj> = {
        isSuccess,
        status,
        isLoading: false,
      };
      this.updateState(index, updatedObj, this.addNextCheck);
    });
  };

  updateState = (
    index: number,
    updatedCheckObj: Partial<CheckObj>,
    cb?: Function
  ) => {
    const { checks } = this.state;
    const clonedChecks = [...checks];
    if (index < clonedChecks.length) {
      const currentCheckObj = clonedChecks[index];
      clonedChecks[index] = {
        ...currentCheckObj,
        ...updatedCheckObj,
      } as CheckObj;
    } else {
      clonedChecks[index] = { ...updatedCheckObj } as CheckObj;
    }
    this.setState(
      {
        checks: clonedChecks,
      },
      () => cb?.()
    );
  };

  cancel = () => {
    logMedusaEventWithConnectivity(
      'connection_troubleshoot_cancelled',
      currentSessionSelector(store.getState())
    );
    this.setState({
      checks: [],
    });
    hideModal();
  };

  render() {
    const { checks } = this.state;
    if (!checks.length) return null;
    const checkObj: CheckObj = checks[checks.length - 1];
    const { name, isSuccess } = checkObj;
    const description = getDescription(name, 'running');
    const percentage =
      ((checks.length - (isSuccess ? 0 : 1)) / checksArray.length) * 100;

    return (
      <div className={cssStyles.container}>
        <h2 className={cssStyles.title}>Running diagnosis</h2>
        <div className={cssStyles.description}>{description}</div>
        {/* {isLoading ? <Loader /> : <Loader />} */}
        <ProgressBar percentage={percentage} />
        <button
          className={cssStyles.cancel}
          type='button'
          onClick={this.cancel}
        >
          Cancel
        </button>
      </div>
    );
  }
}

function ProgressBar({ percentage }) {
  return (
    <div className={cssStyles.progressBarContainer}>
      <div className={cssStyles.progress} style={{ width: `${percentage}%` }} />
    </div>
  );
}

export default TroubleShootChecks;
