import React from "react";
import { observer } from "mobx-react-lite";
import { Header } from "./header";
import { useStore } from "../../store";

export const Floater = observer(function Floater() {
  const store = useStore();
  const style = {
    position: "absolute",
    top: store.disableSearch ? 0 : store.searchHeight - 1,
    left: 0,
    width: store.innerListWidth,
    height: store.headerHeight,
    borderTopLeftRadius: store.disableSearch ? 4 : 0,
    borderTopRightRadius: store.disableSearch ? 4 : 0,
    backgroundColor: store.colors.primaryBg,
    zIndex: 10
  };
  return <Header style={style} text={store.floaterText} />;
});
