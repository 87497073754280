import union from 'lodash/union';
import difference from 'lodash/difference';
import _isEmpty from 'lodash/isEmpty';

const defaultState = {
  session_proxy: {},
  all_session_ids: [],
  chat_state: {},
  editor_notifications: {},
  hasDisconnectedAtLeastOnce: false,
  // resourceLimitExceeded: false,
};

export default (ActionTypes) => {
  return (state = defaultState, action) => {
    try {
      switch (action.type) {
        case ActionTypes.SESSION_PROXY: {
          const { addedSessions, removedSessionIds } = action.payload;
          const sessionProxy = { ...state.session_proxy };

          if (addedSessions) {
            addedSessions.forEach((session) => {
              sessionProxy[session.id] = session;
            });
          }

          if (removedSessionIds) {
            removedSessionIds.forEach((id) => {
              delete sessionProxy[id];
            });
          }
          return {
            ...state,
            session_proxy: sessionProxy,
          };
        }
        case ActionTypes.ADD_TO_ALL_SESSIONS:
          return {
            ...state,
            all_session_ids: union(state.all_session_ids, action.payload),
          };

        case ActionTypes.REMOVE_FROM_ALL_SESSIONS:
          return {
            ...state,
            all_session_ids: difference(state.all_session_ids, action.payload),
          };

        case ActionTypes.CLEAR_ALL_SESSIONS:
          return {
            ...state,
            all_session_ids: [],
          };

        case ActionTypes.CHANGE_SESSION:
          return {
            ...state,
            currentSessionId: action.payload.sessionId,
            currentOwnerGuid: action.payload.ownerGuid,
          };

        case ActionTypes.UPDATE_CHAT_STATE:
          return {
            ...state,
            chat_state: {
              ...state.chat_state,
              [action.payload.ownerGuid]: {
                ...state.chat_state[action.payload.ownerGuid],
                [action.payload.jid]: {
                  status: action.payload.status,
                  participants: action.payload.participants,
                },
              },
            },
          };

        case ActionTypes.REMOVE_CHAT_STATE: {
          const newChatState = {
            ...state.chat_state,
            [action.payload.ownerGuid]: {
              ...state.chat_state[action.payload.ownerGuid],
            },
          };

          delete newChatState[action.payload.ownerGuid][action.payload.jid];

          if (_isEmpty(newChatState[action.payload.ownerGuid])) {
            delete newChatState[action.payload.ownerGuid];
          }

          return {
            ...state,
            chat_state: {
              ...newChatState,
            },
          };
        }

        case ActionTypes.UPDATE_DISCONNECTED_ONCE:
          return {
            ...state,
            hasDisconnectedAtLeastOnce: action.payload,
          };

        case ActionTypes.ADD_NEW_EDITOR_NOTIFICATION: {
          const notifications =
            state.editor_notifications?.[action.payload.ownerGuid]?.[
              action.payload.jid
            ] ?? [];

          return {
            ...state,
            editor_notifications: {
              ...state.editor_notifications,
              [action.payload.ownerGuid]: {
                ...state.editor_notifications[action.payload.ownerGuid],
                [action.payload.jid]: [
                  ...notifications,
                  {
                    text: action.payload.content.text,
                    status: action.payload.content.status,
                    time: action.payload.content.time,
                    type: action.payload.type ?? 'chat',
                  },
                ],
              },
            },
          };
        }

        case ActionTypes.REMOVE_OLDEST_EDITOR_NOTIFICATION: {
          const notifications =
            state.editor_notifications?.[action.payload.ownerGuid]?.[
              action.payload.jid
            ] ?? [];

          return {
            ...state,
            editor_notifications: {
              ...state.editor_notifications,
              [action.payload.ownerGuid]: {
                ...state.editor_notifications[action.payload.ownerGuid],
                [action.payload.jid]: notifications.slice(1),
              },
            },
          };
        }

        case ActionTypes.REMOVE_ALL_EDITOR_NOTIFICATIONS: {
          const notificationsByPeer =
            { ...state.editor_notifications?.[action.payload.ownerGuid] } || {};

          delete notificationsByPeer[action.payload.jid];

          return {
            ...state,
            editor_notifications: {
              ...state.editor_notifications,
              [action.payload.ownerGuid]: {
                ...notificationsByPeer,
              },
            },
          };
        }

        default:
          return state;
      }
    } catch (error) {
      console.error(
        `Error in sessions reducer: ${error.message || error.code || error}`,
        error
      );
    }
    return state;
  };
};
