/* eslint-disable camelcase */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import uniq from 'lodash/uniq';
import { Actions } from '@/actions/restricted';
import bridge from '@/utils/bridge';
import { without } from '@/utils';
import MessageList from '@/components/Conversation/MessageList';
import { BATCH_SIZE, FETCH_STATUS } from '@/utils/constants';
import {
  messageAnchorSelector,
  receiptForCurrentPeerSelector,
  countedUnreadMessagesForCurrentPeer,
} from '@/selectors';
import { getLastReadTimestamp } from '@/utils/ReceiptUtils';
import { ChatsProxy } from '@/Proxies';

const initialState = {
  messagesForPeerJid: undefined,
  paywallHit: false,
  earliestMessageSid: null,
  latestMessageSid: null,
  messageIds: [],
  fetchPastId: null,
  fetchPastStatus: FETCH_STATUS.SUCCESS,
  fetchFutureId: null,
  fetchFutureStatus: FETCH_STATUS.SUCCESS,

  hasFuture: false,
  hasPast: true,
};

const resetState = {
  ...initialState,
  canFetch: false,
};

const ANCHOR_TIME_LIMIT = 1000 * 60 * 60 * 4; // 4 hours
// const ANCHOR_TIME_LIMIT = 1000* 5; // 5 seconds

const defaultMessageAnchor = {};

class MessagesContainer extends Component {
  subs = [];

  state = initialState;

  fetchPastCounter = 0;

  fetchFutureCounter = 0;

  fetchCounter = 0;

  constructor(props) {
    super(props);
    this.subs.push(
      bridge.subscribe('/pw/messagecache/newmessage', this.onNewMessage),
      bridge.subscribe('/pw/messagecache/deletedMessage', this.onDeleteMessage)
      // bridge.subscribe('/pw/messagecache/resetMessages', this.onResetMessages)
    );
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    const {
      ownerGuid: prevOwnerGuid,
      currentPeerId: prevPeerId,
      messageAnchor: prevMessageAnchor,
    } = prevProps;
    const { ownerGuid, currentPeerId, messageAnchor } = this.props;
    // const { mcId: prevMcId, msgSid: prevMsgSid } = prevMessageAnchor;
    // const { mcId, msgSid } = messageAnchor;
    if (
      ownerGuid !== prevOwnerGuid ||
      currentPeerId !== prevPeerId ||
      prevMessageAnchor !== messageAnchor
    ) {
      // if (currentPeerId === prevPeerId) {
      //   // If the peer hasn't changed and msgSid is null, we don't need to call init.
      //   // Prevents scroll to latest message on resetting of the message anchor
      //   return;
      // }
      this.init();
    }
  }

  componentWillUnmount() {
    bridge.unsubscribeAll(this.subs);
  }

  init = () => {
    const {
      messageAnchor,
      currentPeer,
      receipt,
      // eslint-disable-next-line no-unused-vars
      unreadMessages,
      ownerGuid,
    } = this.props;
    const { mcId, msgSid, type, source, paywallHit } = messageAnchor;
    // Set has Future true only when we are fetching with around api.
    const hasFuture = !!msgSid;
    this.setState({ ...initialState, hasFuture }, async () => {
      if (type === 'UNREAD_ANCHOR' && receipt) {
        const lastRead = getLastReadTimestamp(receipt, ownerGuid);
        this.fetchAroundMessages({ sid: lastRead, source });
        return;
      }
      if (msgSid && !paywallHit) {
        if (mcId) {
          this.fetchAroundMessages({
            messageCacheId: +mcId,
            sid: msgSid,
            source,
          });
        } else {
          // Do we need this?
          const msgSids = await this.fetchAroundMessages({
            sid: msgSid,
            source,
          });
          if (msgSids.indexOf(msgSid) === -1) {
            const { jid } = currentPeer;
            // Need to handle deleted message case. We just show a toast for now.
            ChatsProxy.showDeletedToast(jid);
            ChatsProxy.unsetMessageAnchor(jid, ownerGuid);
          }
        }
        // To make sure, open chats always have latest message in store.
        this.triggerLatestMessageFetch();

        // Un comment below to open chat with unread anchor
      } else if (type === 'LATEST_MESSAGE') {
        this.fetchMorePast();
      } else if (unreadMessages.length > 0) {
        this.setMessageAnchor(currentPeer.ownerGuid, currentPeer.jid, {
          type: 'UNREAD_ANCHOR',
          distanceFromTop: 200,
          elementsEdge: 'top',
        });
      } else {
        this.fetchMorePast();
      }
    });
  };

  reset = () => {
    this.setState(resetState);
  };

  // onResetMessages = (refreshView, ownerGuid, jid) => {
  //   const { ownerGuid: currentOwnerGuid, currentPeerId } = this.props;
  //   if (currentOwnerGuid === ownerGuid && currentPeerId === jid) {
  //     console.error('RESET MESSAGE CONTAINER', refreshView, ownerGuid, jid);
  //     if (refreshView) {
  //       this.init(true);
  //     } else {
  //       this.reset();
  //     }
  //   }
  // };

  onDeleteMessage = (message) => {
    const { ownerGuid: currentOwnerGuid, currentPeerId } = this.props;
    const {
      ownerGuid,
      peer: { jid },
      message_cache_id: mcid,
      // stimestamp,
    } = message;
    if (currentOwnerGuid === ownerGuid && currentPeerId === jid) {
      this.setState((prevState) => {
        const {
          messageIds,
          // earliestMessageSid,
          // latestMessageSid,
        } = prevState;
        // if (
        //   `${stimestamp}` < earliestMessageSid ||
        //   latestMessageSid < `${stimestamp}`
        // ) {
        //   return null;
        // }
        const msgIndex = messageIds.indexOf(mcid);
        if (msgIndex !== -1) {
          return {
            messageIds: without(messageIds, msgIndex),
          };
        }
        return null;
      });
    }
  };

  onNewMessage = (message) => {
    const { ownerGuid, currentPeerId: peerId } = this.props;
    const {
      earliestMessageSid = '0',
      messageIds,
      hasFuture: currentHasFuture,
      // latestMessageTimestamp,
    } = this.state;

    if (message.type === 'GAP_MESSAGE') {
      if (message.ownerGuid === ownerGuid && message.peer.jid === peerId) {
        const { sid } = message;
        if (
          sid > earliestMessageSid &&
          messageIds.length > 0 &&
          !currentHasFuture
        ) {
          this.setMessageAnchor(ownerGuid, peerId, null);
          return;
        }
      }
    }

    const { sid, timestamp, message_cache_id, isHistoryMessage } = message;
    if ((sid || `${timestamp}`) < earliestMessageSid || isHistoryMessage) {
      return;
    }
    if (message.ownerGuid === ownerGuid && message.peer.jid === peerId) {
      this.setState((prevState, props) => {
        const { currentPeerId, ownerGuid: currentOwnerGuid } = props;
        if (currentPeerId !== peerId || currentOwnerGuid !== ownerGuid) {
          return {};
        }
        const { hasFuture, messageIds: existingMsgs } = prevState;

        if (hasFuture) {
          if (message.sentByOwner && !message.isGroupUpdateMessage) {
            // TODO: Need to unset anchor without re-rendering it.
            // this.setMessageAnchor(ownerGuid, peerId, null);
            return {
              ...initialState,
              messageIds: [message_cache_id],
              earliestMessageSid: sid,
              latestMessageSid: sid,
              messagesForPeerJid: peerId,
            };
          }

          return {};
        }

        return {
          messageIds: uniq([message_cache_id, ...existingMsgs]),
          latestMessageSid: +message.stimestamp,
          messagesForPeerJid: peerId,
        };
      });
    }
  };

  // Triggers latest message fetch in hydra.
  triggerLatestMessageFetch = () => {
    const { ownerGuid, currentPeerId: peerId } = this.props;
    bridge.ask('MessageCache', 'getMessagesBeforeTimestamp', [
      { jid: peerId, ownerGuid },
      null,
      null,
      BATCH_SIZE,
    ]);
  };

  /**
   *
   * @param {*} hardFetch = hardFetch == false is softFetch,
   *
   * on softFetch, fetch will be triggered only if last fetch is success.
   * on hardFetch, fetch will be triggered even if last fetch is not success.
   *
   * this is to avoid auto multiple retries on fail. If we want to force retry use
   * hardFetch value as true.
   */
  fetchMorePast = (hardFetch = false) => {
    const { ownerGuid, currentPeerId: peerId } = this.props;
    this.fetchPastCounter += 1;
    const transactionFetchId = this.fetchPastCounter;
    this.setState(
      (prevState) => {
        const { fetchPastStatus } = prevState;
        if (fetchPastStatus === FETCH_STATUS.FETCHING) {
          return null;
        }

        if (!hardFetch && fetchPastStatus === FETCH_STATUS.FAILED) {
          return null;
        }

        return {
          fetchPastStatus: FETCH_STATUS.FETCHING,
          fetchPastId: transactionFetchId,
        };
      },
      () => {
        const {
          fetchPastId: updatedFetchId,
          earliestMessageSid,
          messageIds,
        } = this.state;
        if (updatedFetchId !== transactionFetchId) {
          // fetch request dropped / ignored
          return;
        }
        const earliestMessageId = messageIds[messageIds.length - 1];
        const fetchMethod = `getMessagesBeforeTimestamp`;
        console.time('fetch time past');
        bridge
          .ask('MessageCache', fetchMethod, [
            { jid: peerId, ownerGuid },
            earliestMessageSid,
            earliestMessageId,
            BATCH_SIZE,
          ])
          .then((response) => {
            const {
              allMsg,
              hasMore,
              paywallHit,
              firstMessageSid,
              lastMessageSid,
            } = response;
            console.timeEnd('fetch time past');
            if (!allMsg) {
              throw new Error(response);
            }
            const canFetch = hasMore && !paywallHit;
            this.setState(
              (prevState, props) => {
                const {
                  fetchPastId,
                  messageIds: existingMsgs,
                  latestMessageSid: lmt,
                } = prevState;
                if (fetchPastId !== transactionFetchId) {
                  // fetch request reset, so fetch response dropped
                  return null;
                }
                const { currentPeerId, ownerGuid: currentOwnerGuid } = props;
                if (
                  currentPeerId !== peerId ||
                  currentOwnerGuid !== ownerGuid
                ) {
                  return null;
                }
                if (allMsg.length === 0) {
                  return {
                    paywallHit,
                    hasPast: canFetch,
                    fetchPastStatus: FETCH_STATUS.SUCCESS,
                  };
                }
                // set latest on first insert.
                const latestMessageSid =
                  existingMsgs.length === 0 ? lastMessageSid : lmt;
                return {
                  messagesForPeerJid: peerId,
                  earliestMessageId: allMsg[allMsg.length - 1],
                  earliestMessageSid: firstMessageSid,
                  latestMessageSid,
                  hasPast: canFetch,
                  paywallHit,
                  messageIds: uniq([...existingMsgs, ...allMsg]),
                  fetchPastStatus: FETCH_STATUS.SUCCESS,
                };
              },
              () => {
                const { hasPast: canFetchNow } = this.state;
                if (allMsg.length < BATCH_SIZE && canFetchNow) {
                  this.fetchMorePast();
                }
              }
            );
          })
          .catch(() => {
            this.setState((prevState) => {
              const { fetchPastId } = prevState;
              if (fetchPastId !== transactionFetchId) {
                // fetch request reset
                return null;
              }
              return {
                fetchPastStatus: FETCH_STATUS.FAILED,
                hasPast: true,
              };
            });
          });
      }
    );
  };

  /**
   *
   * @param {*} hardFetch = hardFetch == false is softFetch,
   *
   * on softFetch, fetch will be triggered only if last fetch is success.
   * on hardFetch, fetch will be triggered even if last fetch is not success.
   *
   * this is to avoid auto multiple retries on fail. If we want to force retry use
   * hardFetch value as true.
   */
  fetchMoreFuture = (hardFetch = false) => {
    const { ownerGuid, currentPeerId: peerId } = this.props;
    this.fetchFutureCounter += 1;
    const transactionFetchId = this.fetchFutureCounter;
    this.setState(
      (prevState) => {
        const { fetchFutureStatus, messageIds: msgs } = prevState;
        const latestMessageId = msgs[0];
        if (fetchFutureStatus === FETCH_STATUS.FETCHING || !latestMessageId) {
          return null;
        }

        if (!hardFetch && fetchFutureStatus === FETCH_STATUS.FAILED) {
          return null;
        }
        return {
          fetchFutureStatus: FETCH_STATUS.FETCHING,
          fetchFutureId: transactionFetchId,
        };
      },
      () => {
        const {
          fetchFutureId: updatedFetchId,
          messageIds,
          latestMessageSid,
        } = this.state;
        if (updatedFetchId !== transactionFetchId) {
          // fetch request dropped / ignored
          return;
        }
        const latestMessageId = messageIds[0];
        const fetchMethod = `getMessagesAfterTimestamp`;
        console.time('fetch time future');
        bridge
          .ask('MessageCache', fetchMethod, [
            { jid: peerId, ownerGuid },
            latestMessageSid,
            latestMessageId,
            BATCH_SIZE,
          ])
          .then((response) => {
            console.timeEnd('fetch time future');
            const {
              allMsg,
              hasMore,
              firstMessageSid,
              lastMessageSid,
            } = response;
            if (!allMsg) {
              throw new Error(response);
            }
            const canFetch = hasMore;
            this.setState(
              (prevState, props) => {
                const {
                  fetchFutureId,
                  messageIds: existingMsgs,
                  earliestMessageSid: emt,
                } = prevState;
                if (fetchFutureId !== transactionFetchId) {
                  // fetch request reset, so fetch response dropped
                  return null;
                }
                const { currentPeerId, ownerGuid: currentOwnerGuid } = props;
                if (
                  currentPeerId !== peerId ||
                  currentOwnerGuid !== ownerGuid
                ) {
                  return null;
                }
                if (allMsg.length === 0) {
                  return {
                    hasFuture: canFetch,
                    fetchFutureStatus: FETCH_STATUS.SUCCESS,
                  };
                }
                // set latest on first insert.
                const earliestMessageSid =
                  existingMsgs.length === 0 ? firstMessageSid : emt;
                return {
                  messagesForPeerJid: peerId,
                  latestMessageId: allMsg[0],
                  earliestMessageSid,
                  latestMessageSid: lastMessageSid,
                  hasFuture: canFetch,
                  messageIds: uniq([...allMsg, ...existingMsgs]),
                  fetchFutureStatus: FETCH_STATUS.SUCCESS,
                };
              },
              () => {
                const { hasFuture: canFetchNow } = this.state;
                if (allMsg.length < BATCH_SIZE && canFetchNow) {
                  this.fetchMoreFuture();
                }
              }
            );
          })
          .catch(() => {
            this.setState((prevState) => {
              const { fetchFutureId } = prevState;
              if (fetchFutureId !== transactionFetchId) {
                // fetch request reset
                return null;
              }
              return {
                fetchFutureStatus: FETCH_STATUS.FAILED,
                hasFuture: true,
              };
            });
          });
      }
    );
  };

  /**
   * @typedef MessageFetchOptions Fetch options around `messageCacheId` or `sid`
   * @property {string?} messageCacheId The message cache id of the message in concern.
   * @property {string} sid The sid of the message
   * @property {string} source The source of this request can be `reply`, `search` or null (the last known anchor case, it won't be tracked)
   */

  /**
   * Fetch messages around the message with `message_cache_id` of `messageCacheId` or `sid`. Only one of them is necessary.
   * @param {MessageFetchOptions} options The fetch options object of the message in concern.
   * @returns Promise that resolves with all the fetched `message.sid`s.
   */
  fetchAroundMessages(options) {
    const { ownerGuid, currentPeerId: peerId } = this.props;
    this.fetchCounter += 1;
    const transactionFetchId = this.fetchCounter;
    return new Promise((resolve, reject) => {
      const { source } = options;
      this.setState(
        (prevState) => {
          const { fetchPastStatus, fetchFutureStatus } = prevState;
          if (
            fetchFutureStatus === FETCH_STATUS.FETCHING ||
            fetchPastStatus === FETCH_STATUS.FETCHING
          ) {
            return null;
          }
          return {
            fetchFutureStatus: FETCH_STATUS.FETCHING,
            fetchFutureId: transactionFetchId,
            fetchPastStatus: FETCH_STATUS.FETCHING,
            fetchPastId: transactionFetchId,
          };
        },
        () => {
          const { fetchFutureId: updatedFetchId } = this.state;
          if (updatedFetchId !== transactionFetchId) {
            // fetch request dropped / ignored
            reject(new Error('Fetch request dropped/ignored.'));
            return;
          }
          console.time('fetch time around');
          /* Gets messages around `options.messageCacheId` or `options.sid` */
          ChatsProxy.getAroundMessages(options, { ownerGuid, jid: peerId })
            .then((response) => {
              console.timeEnd('fetch time around');
              const {
                allMsg,
                // hasMore,
                msgSids,
                paywallHit,
                firstMessageSid,
                lastMessageSid,
              } = response;
              if (!allMsg) {
                const error = new Error(
                  `No message cache IDs received. ${JSON.stringify(response)}`
                );
                ChatsProxy.addAnalytics({
                  messagesAvailable: false,
                  jumpSuccessful: false,
                  reason: 'other',
                  source,
                });
                reject(error);
                throw error;
              }
              resolve(msgSids);
              this.setState(
                (prevState, props) => {
                  const { fetchFutureId } = prevState;
                  if (fetchFutureId !== transactionFetchId) {
                    // fetch request reset, so fetch response dropped
                    return null;
                  }
                  const { currentPeerId, ownerGuid: currentOwnerGuid } = props;
                  if (
                    currentPeerId !== peerId ||
                    currentOwnerGuid !== ownerGuid
                  ) {
                    return null;
                  }
                  if (allMsg.length === 0) {
                    return {
                      paywallHit,
                      hasFuture: false,
                      hasPast: false,
                      fetchPastStatus: FETCH_STATUS.SUCCESS,
                      fetchFutureStatus: FETCH_STATUS.SUCCESS,
                    };
                  }
                  return {
                    messagesForPeerJid: peerId,
                    latestMessageId: allMsg[0],
                    earliestMessageSid: firstMessageSid,
                    latestMessageSid: lastMessageSid,
                    hasFuture: true,
                    hasPast: true,
                    paywallHit,
                    messageIds: allMsg,
                    fetchFutureStatus: FETCH_STATUS.SUCCESS,
                    fetchPastStatus: FETCH_STATUS.SUCCESS,
                  };
                }
                // () => {
                //   const { hasFuture: canFetchNow } = this.state;
                //   if (allMsg.length < BATCH_SIZE && canFetchNow) {
                //     this.fetchMoreFuture();
                //   }
                // }
              );
            })
            .catch(() => {
              this.setState((prevState) => {
                const { fetchFutureId } = prevState;
                if (fetchFutureId !== transactionFetchId) {
                  // fetch request reset
                  return null;
                }
                return {
                  fetchFutureStatus: FETCH_STATUS.FAILED,
                  hasFuture: true,
                  fetchPastStatus: FETCH_STATUS.FAILED,
                  hasPast: true,
                };
              });
            });
        }
      );
    });
  }

  hardFetchMorePast = () => {
    this.fetchMorePast(true);
  };

  hardFetchMoreFuture = () => {
    this.fetchMoreFuture(true);
  };

  /**
   * Sets fetch statuses to success.
   * This is required when fetch was failed for some reason,
   * but when message list gets a chance to retry again and if it
   * determines that it doesn't need to fetch more, we'll mark
   * fetch statuses as success so that on new fetch message list
   * will not have to make a hard fetch.
   *
   * Note: this will only set success if it is currently in failed state.
   */
  setFutureStatusAsSuccess = () => {
    this.setState(({ fetchFutureStatus }) => {
      if (fetchFutureStatus !== FETCH_STATUS.FAILED) {
        return null;
      }
      return {
        fetchFutureStatus: FETCH_STATUS.SUCCESS,
      };
    });
  };

  setPastStatusAsSuccess = () => {
    this.setState(({ fetchPastStatus }) => {
      if (fetchPastStatus !== FETCH_STATUS.FAILED) {
        return null;
      }
      return {
        fetchPastStatus: FETCH_STATUS.SUCCESS,
      };
    });
  };

  /**
   * Sets the message anchor for this class
   * @param {string} ownerGuid The team ownerGuid of the peer for which message anchor is to be set.
   * @param {string} jid The peer jid for which you wish to set the message anchor
   * @param {MessageAnchor} anchor The MessageAnchor object
   */
  setMessageAnchor = (ownerGuid, jid, anchor) => {
    if (!ownerGuid || !jid) {
      return;
    }
    const { updateMessageAnchor } = this.props;
    updateMessageAnchor(ownerGuid, jid, anchor);
  };

  render() {
    const {
      className,
      ownerGuid,
      currentPeer,
      currentSessionId,
      setIsMessageListAtBottom,
      onInteract,
      messageAnchor,
    } = this.props;
    const {
      messageIds,
      paywallHit,
      messagesForPeerJid,
      hasPast,
      hasFuture,
      fetchPastStatus,
      fetchFutureStatus,
    } = this.state;
    return (
      <div className={className} id='messages-container'>
        <MessageList
          setFutureStatusAsSuccess={this.setFutureStatusAsSuccess}
          setPastStatusAsSuccess={this.setPastStatusAsSuccess}
          fetchMorePast={this.fetchMorePast}
          hardFetchMorePast={this.hardFetchMorePast}
          fetchMoreFuture={this.fetchMoreFuture}
          hardFetchMoreFuture={this.hardFetchMoreFuture}
          // removeFuture={this.removeFuture}
          // removePast={this.removePast}
          pastFetchStatus={fetchPastStatus}
          futureFetchStatus={fetchFutureStatus}
          hasPast={hasPast}
          hasFuture={hasFuture}
          currentSessionId={currentSessionId}
          currentPeer={currentPeer}
          ownerGuid={ownerGuid}
          paywallHit={paywallHit}
          messageIds={messageIds}
          setIsMessageListAtBottom={setIsMessageListAtBottom}
          onInteract={onInteract}
          messagesForPeerJid={messagesForPeerJid}
          setMessageAnchor={this.setMessageAnchor}
          messageAnchor={messageAnchor}
          resetView={this.init}
        />
      </div>
    );
  }
}
function mapStateToProps(state, props) {
  const anchor = messageAnchorSelector(state, props);
  const { anchorTime } = anchor;
  let messageAnchor = anchor;
  if (anchorTime && Date.now() - anchorTime >= ANCHOR_TIME_LIMIT) {
    messageAnchor = defaultMessageAnchor;
  }
  return {
    messageAnchor,
    receipt: receiptForCurrentPeerSelector(state),
    unreadMessages: countedUnreadMessagesForCurrentPeer(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateMessageAnchor: (ownerGuid, jid, anchor) => {
      dispatch(
        Actions.update_message_anchor({
          ownerGuid,
          jid,
          anchor,
        })
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MessagesContainer);
