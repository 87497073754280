export default {
  user_status_available: 'Available',
  user_status_busy: 'Busy',
  user_status_away_from_desk: 'Away from desk',
  user_status_working_from_home: 'Working from home',
  user_status_on_leave: 'On leave',
  update: 'Update',
  updated: 'Updated',
  error_occurred: 'An error occurred. Please try again later.',
  oops: 'Oops!',
  okay: 'Okay',
};
