export const themes = {
  dark: {
    colors: {
      brand: "#0ABE51",
      unread: "#4593E2",
      mention: "#FF615B",
      pill: "#F6A623",

      "presence-online": "#0ABE51",
      "presence-away": "#F6A623",
      "presence-offline": "#E1E1E1",

      "primary-bg": "#212327",
      "secondary-bg": "#2F3136",

      "primary-text": "#AAAAAA",
      "secondary-text": "#898A8C",

      border: "#4A4C4F",

      "team-switcher-bg": "#212327",

      "dialog-primary-bg": "#2F3136",
      "dialog-secondary-bg": "#212327",

      "button-primary": "#0ABE51",
      "button-primary-active": "#008E3E",
      "button-primary-text": "#212327",

      "button-secondary": "#898A8C",
      "button-secondary-active": "#4A4C4F",
      "button-secondary-text": "#212327",

      "button-tertiary": "#FF615B",
      "button-tertiary-active": "#D74E49",
      "button-tertiary-text": "#212327"
    }
  },
  light: {
    colors: {
      brand: "#0ABE51",
      unread: "#4593E2",
      mention: "#FF615B",
      pill: "#F6A623",

      "presence-online": "#0ABE51",
      "presence-away": "#F6A623",
      "presence-offline": "#E1E1E1",

      "primary-bg": "#FFFFFF",
      "secondary-bg": "#EEEEEE",

      "primary-text": "#333333",
      "secondary-text": "#888888",

      border: "#E1E1E1",

      "team-switcher-bg": "#333333",

      "dialog-primary-bg": "#FFFFFF",
      "dialog-secondary-bg": "#EEEEEE",

      "button-primary": "#0ABE51",
      "button-primary-active": "#008E3E",
      "button-primary-text": "#FFFFFF",

      "button-secondary": "#E1E1E1",
      "button-secondary-active": "#B6B6B6",
      "button-secondary-text": "#333333",

      "button-tertiary": "#FF615B",
      "button-tertiary-active": "#D74E49",
      "button-tertiary-text": "#FFFFFF"
    }
  }
};

export const stickers = [
  {
    id: "smileys",
    name: "Smileys :)",
    order: 0,
    image: "https://mc.flock.co/stickers/smileys/icon.png",
    stickers: [
      {
        _id: "smile",
        id: "smileys_smile",
        name: "Smile",
        thumbnail: "https://mc.flock.co/stickers/smileys/smile-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/smileys/smile.png",
        categoryId: "smileys",
        categoryName: "Smileys :)"
      },
      {
        _id: "tongue-out",
        id: "smileys_tongue-out",
        name: "Sticking tongue out",
        thumbnail: "https://mc.flock.co/stickers/smileys/tongue-out-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/smileys/tongue-out.png",
        categoryId: "smileys",
        categoryName: "Smileys :)"
      },
      {
        _id: "big-grin",
        id: "smileys_big-grin",
        name: "Big grin",
        thumbnail: "https://mc.flock.co/stickers/smileys/big-grin-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/smileys/big-grin.png",
        categoryId: "smileys",
        categoryName: "Smileys :)"
      },
      {
        _id: "sleeping",
        id: "smileys_sleeping",
        name: "Sleeping",
        thumbnail: "https://mc.flock.co/stickers/smileys/sleeping-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/smileys/sleeping.png",
        categoryId: "smileys",
        categoryName: "Smileys :)"
      },
      {
        _id: "crying-profusely",
        id: "smileys_crying-profusely",
        name: "Crying profusely",
        thumbnail:
          "https://mc.flock.co/stickers/smileys/crying-profusely-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/smileys/crying-profusely.png",
        categoryId: "smileys",
        categoryName: "Smileys :)"
      },
      {
        _id: "wink",
        id: "smileys_wink",
        name: "Wink",
        thumbnail: "https://mc.flock.co/stickers/smileys/wink-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/smileys/wink.png",
        categoryId: "smileys",
        categoryName: "Smileys :)"
      },
      {
        _id: "sad",
        id: "smileys_sad",
        name: "Sad",
        thumbnail: "https://mc.flock.co/stickers/smileys/sad-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/smileys/sad.png",
        categoryId: "smileys",
        categoryName: "Smileys :)"
      },
      {
        _id: "surprised",
        id: "smileys_surprised",
        name: "Surprised",
        thumbnail: "https://mc.flock.co/stickers/smileys/surprised-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/smileys/surprised.png",
        categoryId: "smileys",
        categoryName: "Smileys :)"
      },
      {
        _id: "i-love-you",
        id: "smileys_i-love-you",
        name: "I love you",
        thumbnail: "https://mc.flock.co/stickers/smileys/i-love-you-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/smileys/i-love-you.png",
        categoryId: "smileys",
        categoryName: "Smileys :)"
      },
      {
        _id: "talk-to-the-hand",
        id: "smileys_talk-to-the-hand",
        name: "Talk to the hand",
        thumbnail:
          "https://mc.flock.co/stickers/smileys/talk-to-the-hand-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/smileys/talk-to-the-hand.png",
        categoryId: "smileys",
        categoryName: "Smileys :)"
      },
      {
        _id: "kiss",
        id: "smileys_kiss",
        name: "Kiss",
        thumbnail: "https://mc.flock.co/stickers/smileys/kiss-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/smileys/kiss.png",
        categoryId: "smileys",
        categoryName: "Smileys :)"
      },
      {
        _id: "will-you-go-out-with-me",
        id: "smileys_will-you-go-out-with-me",
        name: "Will you go out with me",
        thumbnail:
          "https://mc.flock.co/stickers/smileys/will-you-go-out-with-me-thumb.png",
        type: "image/png",
        image:
          "https://mc.flock.co/stickers/smileys/will-you-go-out-with-me.png",
        categoryId: "smileys",
        categoryName: "Smileys :)"
      },
      {
        _id: "thumbs-down",
        id: "smileys_thumbs-down",
        name: "Thumbs down",
        thumbnail: "https://mc.flock.co/stickers/smileys/thumbs-down-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/smileys/thumbs-down.png",
        categoryId: "smileys",
        categoryName: "Smileys :)"
      },
      {
        _id: "thumbs-up",
        id: "smileys_thumbs-up",
        name: "Thumbs up",
        thumbnail: "https://mc.flock.co/stickers/smileys/thumbs-up-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/smileys/thumbs-up.png",
        categoryId: "smileys",
        categoryName: "Smileys :)"
      },
      {
        _id: "tongue-tied",
        id: "smileys_tongue-tied",
        name: "Tongue tied",
        thumbnail: "https://mc.flock.co/stickers/smileys/tongue-tied-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/smileys/tongue-tied.png",
        categoryId: "smileys",
        categoryName: "Smileys :)"
      },
      {
        _id: "evil",
        id: "smileys_evil",
        name: "Evil",
        thumbnail: "https://mc.flock.co/stickers/smileys/evil-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/smileys/evil.png",
        categoryId: "smileys",
        categoryName: "Smileys :)"
      },
      {
        _id: "angel",
        id: "smileys_angel",
        name: "Angel",
        thumbnail: "https://mc.flock.co/stickers/smileys/angel-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/smileys/angel.png",
        categoryId: "smileys",
        categoryName: "Smileys :)"
      },
      {
        _id: "blush",
        id: "smileys_blush",
        name: "Blush",
        thumbnail: "https://mc.flock.co/stickers/smileys/blush-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/smileys/blush.png",
        categoryId: "smileys",
        categoryName: "Smileys :)"
      },
      {
        _id: "yawn",
        id: "smileys_yawn",
        name: "Yawn",
        thumbnail: "https://mc.flock.co/stickers/smileys/yawn-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/smileys/yawn.png",
        categoryId: "smileys",
        categoryName: "Smileys :)"
      },
      {
        _id: "bored",
        id: "smileys_bored",
        name: "Bored",
        thumbnail: "https://mc.flock.co/stickers/smileys/bored-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/smileys/bored.png",
        categoryId: "smileys",
        categoryName: "Smileys :)"
      },
      {
        _id: "angry",
        id: "smileys_angry",
        name: "Angry",
        thumbnail: "https://mc.flock.co/stickers/smileys/angry-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/smileys/angry.png",
        categoryId: "smileys",
        categoryName: "Smileys :)"
      },
      {
        _id: "crying-single-tear",
        id: "smileys_crying-single-tear",
        name: "Crying (single tear)",
        thumbnail:
          "https://mc.flock.co/stickers/smileys/crying-single-tear-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/smileys/crying-single-tear.png",
        categoryId: "smileys",
        categoryName: "Smileys :)"
      },
      {
        _id: "lol",
        id: "smileys_lol",
        name: "LOL",
        thumbnail: "https://mc.flock.co/stickers/smileys/lol-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/smileys/lol.png",
        categoryId: "smileys",
        categoryName: "Smileys :)"
      },
      {
        _id: "tears-of-happiness",
        id: "smileys_tears-of-happiness",
        name: "Tears of happiness",
        thumbnail:
          "https://mc.flock.co/stickers/smileys/tears-of-happiness-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/smileys/tears-of-happiness.png",
        categoryId: "smileys",
        categoryName: "Smileys :)"
      },
      {
        _id: "smirk",
        id: "smileys_smirk",
        name: "Smirk",
        thumbnail: "https://mc.flock.co/stickers/smileys/smirk-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/smileys/smirk.png",
        categoryId: "smileys",
        categoryName: "Smileys :)"
      },
      {
        _id: "confused",
        id: "smileys_confused",
        name: "Confused",
        thumbnail: "https://mc.flock.co/stickers/smileys/confused-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/smileys/confused.png",
        categoryId: "smileys",
        categoryName: "Smileys :)"
      },
      {
        _id: "high-five",
        id: "smileys_high-five",
        name: "High five",
        thumbnail: "https://mc.flock.co/stickers/smileys/high-five-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/smileys/high-five.png",
        categoryId: "smileys",
        categoryName: "Smileys :)"
      },
      {
        _id: "good-morning",
        id: "smileys_good-morning",
        name: "Good morning",
        thumbnail:
          "https://mc.flock.co/stickers/smileys/good-morning-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/smileys/good-morning.png",
        categoryId: "smileys",
        categoryName: "Smileys :)"
      },
      {
        _id: "irritation",
        id: "smileys_irritation",
        name: "Irritation",
        thumbnail: "https://mc.flock.co/stickers/smileys/irritation-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/smileys/irritation.png",
        categoryId: "smileys",
        categoryName: "Smileys :)"
      },
      {
        _id: "broken-hearted",
        id: "smileys_broken-hearted",
        name: "Broken hearted",
        thumbnail:
          "https://mc.flock.co/stickers/smileys/broken-hearted-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/smileys/broken-hearted.png",
        categoryId: "smileys",
        categoryName: "Smileys :)"
      },
      {
        _id: "disgust",
        id: "smileys_disgust",
        name: "Disgust",
        thumbnail: "https://mc.flock.co/stickers/smileys/disgust-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/smileys/disgust.png",
        categoryId: "smileys",
        categoryName: "Smileys :)"
      },
      {
        _id: "applaud",
        id: "smileys_applaud",
        name: "Applaud",
        thumbnail: "https://mc.flock.co/stickers/smileys/applaud-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/smileys/applaud.png",
        categoryId: "smileys",
        categoryName: "Smileys :)"
      },
      {
        _id: "hug",
        id: "smileys_hug",
        name: "Hug",
        thumbnail: "https://mc.flock.co/stickers/smileys/hug-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/smileys/hug.png",
        categoryId: "smileys",
        categoryName: "Smileys :)"
      },
      {
        _id: "sweetheart",
        id: "smileys_sweetheart",
        name: "You are my sweetheart",
        thumbnail: "https://mc.flock.co/stickers/smileys/sweetheart-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/smileys/sweetheart.png",
        categoryId: "smileys",
        categoryName: "Smileys :)"
      },
      {
        _id: "good-night",
        id: "smileys_good-night",
        name: "Good night",
        thumbnail: "https://mc.flock.co/stickers/smileys/good-night-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/smileys/good-night.png",
        categoryId: "smileys",
        categoryName: "Smileys :)"
      },
      {
        _id: "drunk",
        id: "smileys_drunk",
        name: "Drunk",
        thumbnail: "https://mc.flock.co/stickers/smileys/drunk-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/smileys/drunk.png",
        categoryId: "smileys",
        categoryName: "Smileys :)"
      },
      {
        _id: "zipped-shut",
        id: "smileys_zipped-shut",
        name: "Lips zipped shut",
        thumbnail: "https://mc.flock.co/stickers/smileys/zipped-shut-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/smileys/zipped-shut.png",
        categoryId: "smileys",
        categoryName: "Smileys :)"
      }
    ]
  },
  {
    id: "word-stickers",
    name: "Word Stickers",
    order: 1,
    image: "https://mc.flock.co/stickers/word-stickers/icon.png",
    stickers: [
      {
        _id: "congrats",
        id: "word-stickers_congrats",
        name: "Congrats",
        thumbnail:
          "https://mc.flock.co/stickers/word-stickers/congrats-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/word-stickers/congrats.png",
        categoryId: "word-stickers",
        categoryName: "Word Stickers"
      },
      {
        _id: "good-job",
        id: "word-stickers_good-job",
        name: "Good Job",
        thumbnail:
          "https://mc.flock.co/stickers/word-stickers/good-job-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/word-stickers/good-job.png",
        categoryId: "word-stickers",
        categoryName: "Word Stickers"
      },
      {
        _id: "hbd",
        id: "word-stickers_hbd",
        name: "Happy Birthday",
        thumbnail: "https://mc.flock.co/stickers/word-stickers/hbd-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/word-stickers/hbd.png",
        categoryId: "word-stickers",
        categoryName: "Word Stickers"
      },
      {
        _id: "huh",
        id: "word-stickers_huh",
        name: "Huh",
        thumbnail: "https://mc.flock.co/stickers/word-stickers/huh-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/word-stickers/huh.png",
        categoryId: "word-stickers",
        categoryName: "Word Stickers"
      },
      {
        _id: "idea",
        id: "word-stickers_idea",
        name: "Idea",
        thumbnail: "https://mc.flock.co/stickers/word-stickers/idea-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/word-stickers/idea.png",
        categoryId: "word-stickers",
        categoryName: "Word Stickers"
      },
      {
        _id: "number-one",
        id: "word-stickers_number-one",
        name: "Number One",
        thumbnail:
          "https://mc.flock.co/stickers/word-stickers/number-one-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/word-stickers/number-one.png",
        categoryId: "word-stickers",
        categoryName: "Word Stickers"
      },
      {
        _id: "omg",
        id: "word-stickers_omg",
        name: "OMG",
        thumbnail: "https://mc.flock.co/stickers/word-stickers/omg-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/word-stickers/omg.png",
        categoryId: "word-stickers",
        categoryName: "Word Stickers"
      },
      {
        _id: "ouch",
        id: "word-stickers_ouch",
        name: "Ouch",
        thumbnail: "https://mc.flock.co/stickers/word-stickers/ouch-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/word-stickers/ouch.png",
        categoryId: "word-stickers",
        categoryName: "Word Stickers"
      },
      {
        _id: "slap",
        id: "word-stickers_slap",
        name: "Slap",
        thumbnail: "https://mc.flock.co/stickers/word-stickers/slap-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/word-stickers/slap.png",
        categoryId: "word-stickers",
        categoryName: "Word Stickers"
      },
      {
        _id: "thank-you",
        id: "word-stickers_thank-you",
        name: "Thank You",
        thumbnail:
          "https://mc.flock.co/stickers/word-stickers/thank-you-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/word-stickers/thank-you.png",
        categoryId: "word-stickers",
        categoryName: "Word Stickers"
      },
      {
        _id: "woohoo",
        id: "word-stickers_woohoo",
        name: "Woohoo",
        thumbnail:
          "https://mc.flock.co/stickers/word-stickers/woohoo-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/word-stickers/woohoo.png",
        categoryId: "word-stickers",
        categoryName: "Word Stickers"
      },
      {
        _id: "wow",
        id: "word-stickers_wow",
        name: "Wow",
        thumbnail: "https://mc.flock.co/stickers/word-stickers/wow-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/word-stickers/wow.png",
        categoryId: "word-stickers",
        categoryName: "Word Stickers"
      }
    ]
  },
  {
    id: "mia",
    name: "mia",
    order: 2,
    image: "https://mc.flock.co/stickers/mia/icon.png",
    stickers: [
      {
        _id: "chill",
        id: "mia_chill",
        name: "chill",
        thumbnail: "https://mc.flock.co/stickers/mia/chill-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/mia/chill.png",
        categoryId: "mia",
        categoryName: "mia"
      },
      {
        _id: "hehe",
        id: "mia_hehe",
        name: "hehe",
        thumbnail: "https://mc.flock.co/stickers/mia/hehe-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/mia/hehe.png",
        categoryId: "mia",
        categoryName: "mia"
      },
      {
        _id: "oh-crap",
        id: "mia_oh-crap",
        name: "oh-crap",
        thumbnail: "https://mc.flock.co/stickers/mia/oh-crap-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/mia/oh-crap.png",
        categoryId: "mia",
        categoryName: "mia"
      },
      {
        _id: "really",
        id: "mia_really",
        name: "really",
        thumbnail: "https://mc.flock.co/stickers/mia/really-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/mia/really.png",
        categoryId: "mia",
        categoryName: "mia"
      },
      {
        _id: "selfie",
        id: "mia_selfie",
        name: "selfie",
        thumbnail: "https://mc.flock.co/stickers/mia/selfie-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/mia/selfie.png",
        categoryId: "mia",
        categoryName: "mia"
      },
      {
        _id: "tired",
        id: "mia_tired",
        name: "tired",
        thumbnail: "https://mc.flock.co/stickers/mia/tired-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/mia/tired.png",
        categoryId: "mia",
        categoryName: "mia"
      },
      {
        _id: "wassup",
        id: "mia_wassup",
        name: "wassup",
        thumbnail: "https://mc.flock.co/stickers/mia/wassup-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/mia/wassup.png",
        categoryId: "mia",
        categoryName: "mia"
      },
      {
        _id: "waah",
        id: "mia_waah",
        name: "waah",
        thumbnail: "https://mc.flock.co/stickers/mia/waah-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/mia/waah.png",
        categoryId: "mia",
        categoryName: "mia"
      }
    ]
  },
  {
    id: "bobby",
    name: "Bobby",
    order: 3,
    image: "https://mc.flock.co/stickers/bobby/icon.png",
    stickers: [
      {
        _id: "laugh",
        id: "bobby_laugh",
        name: "Laugh",
        thumbnail: "https://mc.flock.co/stickers/bobby/laugh-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bobby/laugh.png",
        categoryId: "bobby",
        categoryName: "Bobby"
      },
      {
        _id: "shame-on-you",
        id: "bobby_shame-on-you",
        name: "Shame on you",
        thumbnail: "https://mc.flock.co/stickers/bobby/shame-on-you-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bobby/shame-on-you.png",
        categoryId: "bobby",
        categoryName: "Bobby"
      },
      {
        _id: "confused",
        id: "bobby_confused",
        name: "Confused",
        thumbnail: "https://mc.flock.co/stickers/bobby/confused-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bobby/confused.png",
        categoryId: "bobby",
        categoryName: "Bobby"
      },
      {
        _id: "wrath",
        id: "bobby_wrath",
        name: "Wrath",
        thumbnail: "https://mc.flock.co/stickers/bobby/wrath-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bobby/wrath.png",
        categoryId: "bobby",
        categoryName: "Bobby"
      },
      {
        _id: "thumbs-up",
        id: "bobby_thumbs-up",
        name: "Thumbs up",
        thumbnail: "https://mc.flock.co/stickers/bobby/thumbs-up-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bobby/thumbs-up.png",
        categoryId: "bobby",
        categoryName: "Bobby"
      },
      {
        _id: "thumbs-down",
        id: "bobby_thumbs-down",
        name: "Thumbs down",
        thumbnail: "https://mc.flock.co/stickers/bobby/thumbs-down-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bobby/thumbs-down.png",
        categoryId: "bobby",
        categoryName: "Bobby"
      },
      {
        _id: "pat-pat",
        id: "bobby_pat-pat",
        name: "Pat pat",
        thumbnail: "https://mc.flock.co/stickers/bobby/pat-pat-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bobby/pat-pat.png",
        categoryId: "bobby",
        categoryName: "Bobby"
      },
      {
        _id: "idea",
        id: "bobby_idea",
        name: "Idea",
        thumbnail: "https://mc.flock.co/stickers/bobby/idea-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bobby/idea.png",
        categoryId: "bobby",
        categoryName: "Bobby"
      },
      {
        _id: "chilling",
        id: "bobby_chilling",
        name: "Chilling",
        thumbnail: "https://mc.flock.co/stickers/bobby/chilling-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bobby/chilling.png",
        categoryId: "bobby",
        categoryName: "Bobby"
      },
      {
        _id: "pride",
        id: "bobby_pride",
        name: "Pride",
        thumbnail: "https://mc.flock.co/stickers/bobby/pride-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bobby/pride.png",
        categoryId: "bobby",
        categoryName: "Bobby"
      },
      {
        _id: "sick",
        id: "bobby_sick",
        name: "Sick",
        thumbnail: "https://mc.flock.co/stickers/bobby/sick-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bobby/sick.png",
        categoryId: "bobby",
        categoryName: "Bobby"
      },
      {
        _id: "u-r-late",
        id: "bobby_u-r-late",
        name: "You are late",
        thumbnail: "https://mc.flock.co/stickers/bobby/u-r-late-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bobby/u-r-late.png",
        categoryId: "bobby",
        categoryName: "Bobby"
      },
      {
        _id: "victory",
        id: "bobby_victory",
        name: "Victory",
        thumbnail: "https://mc.flock.co/stickers/bobby/victory-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bobby/victory.png",
        categoryId: "bobby",
        categoryName: "Bobby"
      },
      {
        _id: "exhausted",
        id: "bobby_exhausted",
        name: "Exhausted",
        thumbnail: "https://mc.flock.co/stickers/bobby/exhausted-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bobby/exhausted.png",
        categoryId: "bobby",
        categoryName: "Bobby"
      },
      {
        _id: "heavy-eating",
        id: "bobby_heavy-eating",
        name: "Heavy Eating",
        thumbnail: "https://mc.flock.co/stickers/bobby/heavy-eating-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bobby/heavy-eating.png",
        categoryId: "bobby",
        categoryName: "Bobby"
      },
      {
        _id: "please",
        id: "bobby_please",
        name: "Please",
        thumbnail: "https://mc.flock.co/stickers/bobby/please-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bobby/please.png",
        categoryId: "bobby",
        categoryName: "Bobby"
      }
    ]
  },
  {
    id: "ooga-booga",
    name: "Ooga Booga",
    order: 4,
    image: "https://mc.flock.co/stickers/ooga-booga/icon.png",
    stickers: [
      {
        _id: "smile",
        id: "ooga-booga_smile",
        name: "Smile",
        thumbnail: "https://mc.flock.co/stickers/ooga-booga/smile-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/ooga-booga/smile.png",
        categoryId: "ooga-booga",
        categoryName: "Ooga Booga"
      },
      {
        _id: "grin",
        id: "ooga-booga_grin",
        name: "Grin",
        thumbnail: "https://mc.flock.co/stickers/ooga-booga/grin-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/ooga-booga/grin.png",
        categoryId: "ooga-booga",
        categoryName: "Ooga Booga"
      },
      {
        _id: "rofl",
        id: "ooga-booga_rofl",
        name: "ROFL",
        thumbnail: "https://mc.flock.co/stickers/ooga-booga/rofl-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/ooga-booga/rofl.png",
        categoryId: "ooga-booga",
        categoryName: "Ooga Booga"
      },
      {
        _id: "facepalm",
        id: "ooga-booga_facepalm",
        name: "Facepalm",
        thumbnail: "https://mc.flock.co/stickers/ooga-booga/facepalm-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/ooga-booga/facepalm.png",
        categoryId: "ooga-booga",
        categoryName: "Ooga Booga"
      },
      {
        _id: "teasing",
        id: "ooga-booga_teasing",
        name: "Teasing",
        thumbnail: "https://mc.flock.co/stickers/ooga-booga/teasing-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/ooga-booga/teasing.png",
        categoryId: "ooga-booga",
        categoryName: "Ooga Booga"
      },
      {
        _id: "frustrated",
        id: "ooga-booga_frustrated",
        name: "Frustrated",
        thumbnail:
          "https://mc.flock.co/stickers/ooga-booga/frustrated-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/ooga-booga/frustrated.png",
        categoryId: "ooga-booga",
        categoryName: "Ooga Booga"
      },
      {
        _id: "angry",
        id: "ooga-booga_angry",
        name: "Angry",
        thumbnail: "https://mc.flock.co/stickers/ooga-booga/angry-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/ooga-booga/angry.png",
        categoryId: "ooga-booga",
        categoryName: "Ooga Booga"
      },
      {
        _id: "talk-to-the-hand",
        id: "ooga-booga_talk-to-the-hand",
        name: "Talk to the hand",
        thumbnail:
          "https://mc.flock.co/stickers/ooga-booga/talk-to-the-hand-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/ooga-booga/talk-to-the-hand.png",
        categoryId: "ooga-booga",
        categoryName: "Ooga Booga"
      },
      {
        _id: "confused",
        id: "ooga-booga_confused",
        name: "Confused",
        thumbnail: "https://mc.flock.co/stickers/ooga-booga/confused-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/ooga-booga/confused.png",
        categoryId: "ooga-booga",
        categoryName: "Ooga Booga"
      },
      {
        _id: "swinging",
        id: "ooga-booga_swinging",
        name: "Swinging",
        thumbnail: "https://mc.flock.co/stickers/ooga-booga/swinging-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/ooga-booga/swinging.png",
        categoryId: "ooga-booga",
        categoryName: "Ooga Booga"
      },
      {
        _id: "victory",
        id: "ooga-booga_victory",
        name: "Victory",
        thumbnail: "https://mc.flock.co/stickers/ooga-booga/victory-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/ooga-booga/victory.png",
        categoryId: "ooga-booga",
        categoryName: "Ooga Booga"
      },
      {
        _id: "party",
        id: "ooga-booga_party",
        name: "Party",
        thumbnail: "https://mc.flock.co/stickers/ooga-booga/party-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/ooga-booga/party.png",
        categoryId: "ooga-booga",
        categoryName: "Ooga Booga"
      },
      {
        _id: "eating",
        id: "ooga-booga_eating",
        name: "Eating",
        thumbnail: "https://mc.flock.co/stickers/ooga-booga/eating-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/ooga-booga/eating.png",
        categoryId: "ooga-booga",
        categoryName: "Ooga Booga"
      },
      {
        _id: "flying",
        id: "ooga-booga_flying",
        name: "Flying",
        thumbnail: "https://mc.flock.co/stickers/ooga-booga/flying-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/ooga-booga/flying.png",
        categoryId: "ooga-booga",
        categoryName: "Ooga Booga"
      },
      {
        _id: "love",
        id: "ooga-booga_love",
        name: "Love",
        thumbnail: "https://mc.flock.co/stickers/ooga-booga/love-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/ooga-booga/love.png",
        categoryId: "ooga-booga",
        categoryName: "Ooga Booga"
      },
      {
        _id: "ssshh",
        id: "ooga-booga_ssshh",
        name: "Ssshh",
        thumbnail: "https://mc.flock.co/stickers/ooga-booga/ssshh-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/ooga-booga/ssshh.png",
        categoryId: "ooga-booga",
        categoryName: "Ooga Booga"
      },
      {
        _id: "busy",
        id: "ooga-booga_busy",
        name: "Busy",
        thumbnail: "https://mc.flock.co/stickers/ooga-booga/busy-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/ooga-booga/busy.png",
        categoryId: "ooga-booga",
        categoryName: "Ooga Booga"
      },
      {
        _id: "sad",
        id: "ooga-booga_sad",
        name: "Sad",
        thumbnail: "https://mc.flock.co/stickers/ooga-booga/sad-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/ooga-booga/sad.png",
        categoryId: "ooga-booga",
        categoryName: "Ooga Booga"
      },
      {
        _id: "sorry",
        id: "ooga-booga_sorry",
        name: "Sorry",
        thumbnail: "https://mc.flock.co/stickers/ooga-booga/sorry-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/ooga-booga/sorry.png",
        categoryId: "ooga-booga",
        categoryName: "Ooga Booga"
      },
      {
        _id: "sleeping",
        id: "ooga-booga_sleeping",
        name: "Sleeping",
        thumbnail: "https://mc.flock.co/stickers/ooga-booga/sleeping-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/ooga-booga/sleeping.png",
        categoryId: "ooga-booga",
        categoryName: "Ooga Booga"
      }
    ]
  },
  {
    id: "bunny-bobo",
    name: "Bunny Bobo",
    order: 5,
    image: "https://mc.flock.co/stickers/bunny-bobo/icon.png",
    stickers: [
      {
        _id: "smile",
        id: "bunny-bobo_smile",
        name: "Smile",
        thumbnail: "https://mc.flock.co/stickers/bunny-bobo/smile-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bunny-bobo/smile.png",
        categoryId: "bunny-bobo",
        categoryName: "Bunny Bobo"
      },
      {
        _id: "big-grin",
        id: "bunny-bobo_big-grin",
        name: "Big grin",
        thumbnail: "https://mc.flock.co/stickers/bunny-bobo/big-grin-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bunny-bobo/big-grin.png",
        categoryId: "bunny-bobo",
        categoryName: "Bunny Bobo"
      },
      {
        _id: "sad",
        id: "bunny-bobo_sad",
        name: "Sad",
        thumbnail: "https://mc.flock.co/stickers/bunny-bobo/sad-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bunny-bobo/sad.png",
        categoryId: "bunny-bobo",
        categoryName: "Bunny Bobo"
      },
      {
        _id: "wink",
        id: "bunny-bobo_wink",
        name: "Wink",
        thumbnail: "https://mc.flock.co/stickers/bunny-bobo/wink-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bunny-bobo/wink.png",
        categoryId: "bunny-bobo",
        categoryName: "Bunny Bobo"
      },
      {
        _id: "surprised",
        id: "bunny-bobo_surprised",
        name: "Surprised",
        thumbnail:
          "https://mc.flock.co/stickers/bunny-bobo/surprised-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bunny-bobo/surprised.png",
        categoryId: "bunny-bobo",
        categoryName: "Bunny Bobo"
      },
      {
        _id: "sticking-tongue-out",
        id: "bunny-bobo_sticking-tongue-out",
        name: "Sticking tongue out",
        thumbnail:
          "https://mc.flock.co/stickers/bunny-bobo/sticking-tongue-out-thumb.png",
        type: "image/png",
        image:
          "https://mc.flock.co/stickers/bunny-bobo/sticking-tongue-out.png",
        categoryId: "bunny-bobo",
        categoryName: "Bunny Bobo"
      },
      {
        _id: "angry",
        id: "bunny-bobo_angry",
        name: "Angry",
        thumbnail: "https://mc.flock.co/stickers/bunny-bobo/angry-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bunny-bobo/angry.png",
        categoryId: "bunny-bobo",
        categoryName: "Bunny Bobo"
      },
      {
        _id: "broken-heart",
        id: "bunny-bobo_broken-heart",
        name: "Broken heart",
        thumbnail:
          "https://mc.flock.co/stickers/bunny-bobo/broken-heart-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bunny-bobo/broken-heart.png",
        categoryId: "bunny-bobo",
        categoryName: "Bunny Bobo"
      },
      {
        _id: "kisses",
        id: "bunny-bobo_kisses",
        name: "Kisses",
        thumbnail: "https://mc.flock.co/stickers/bunny-bobo/kisses-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bunny-bobo/kisses.png",
        categoryId: "bunny-bobo",
        categoryName: "Bunny Bobo"
      },
      {
        _id: "pretty-please",
        id: "bunny-bobo_pretty-please",
        name: "Pretty please",
        thumbnail:
          "https://mc.flock.co/stickers/bunny-bobo/pretty-please-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bunny-bobo/pretty-please.png",
        categoryId: "bunny-bobo",
        categoryName: "Bunny Bobo"
      },
      {
        _id: "lol",
        id: "bunny-bobo_lol",
        name: "LOL",
        thumbnail: "https://mc.flock.co/stickers/bunny-bobo/lol-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bunny-bobo/lol.png",
        categoryId: "bunny-bobo",
        categoryName: "Bunny Bobo"
      },
      {
        _id: "flirty-with-flowers",
        id: "bunny-bobo_flirty-with-flowers",
        name: "Flirty with flowers",
        thumbnail:
          "https://mc.flock.co/stickers/bunny-bobo/flirty-with-flowers-thumb.png",
        type: "image/png",
        image:
          "https://mc.flock.co/stickers/bunny-bobo/flirty-with-flowers.png",
        categoryId: "bunny-bobo",
        categoryName: "Bunny Bobo"
      },
      {
        _id: "crying-profusely",
        id: "bunny-bobo_crying-profusely",
        name: "Crying profusely",
        thumbnail:
          "https://mc.flock.co/stickers/bunny-bobo/crying-profusely-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bunny-bobo/crying-profusely.png",
        categoryId: "bunny-bobo",
        categoryName: "Bunny Bobo"
      },
      {
        _id: "i-love-you",
        id: "bunny-bobo_i-love-you",
        name: "I love you",
        thumbnail:
          "https://mc.flock.co/stickers/bunny-bobo/i-love-you-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bunny-bobo/i-love-you.png",
        categoryId: "bunny-bobo",
        categoryName: "Bunny Bobo"
      },
      {
        _id: "rock-on",
        id: "bunny-bobo_rock-on",
        name: "Rock on",
        thumbnail: "https://mc.flock.co/stickers/bunny-bobo/rock-on-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bunny-bobo/rock-on.png",
        categoryId: "bunny-bobo",
        categoryName: "Bunny Bobo"
      },
      {
        _id: "running-scared",
        id: "bunny-bobo_running-scared",
        name: "Running Scared",
        thumbnail:
          "https://mc.flock.co/stickers/bunny-bobo/running-scared-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bunny-bobo/running-scared.png",
        categoryId: "bunny-bobo",
        categoryName: "Bunny Bobo"
      },
      {
        _id: "eating",
        id: "bunny-bobo_eating",
        name: "Eating",
        thumbnail: "https://mc.flock.co/stickers/bunny-bobo/eating-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bunny-bobo/eating.png",
        categoryId: "bunny-bobo",
        categoryName: "Bunny Bobo"
      },
      {
        _id: "i-am-watching-you",
        id: "bunny-bobo_i-am-watching-you",
        name: "I am watching you",
        thumbnail:
          "https://mc.flock.co/stickers/bunny-bobo/i-am-watching-you-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bunny-bobo/i-am-watching-you.png",
        categoryId: "bunny-bobo",
        categoryName: "Bunny Bobo"
      },
      {
        _id: "chilling-out",
        id: "bunny-bobo_chilling-out",
        name: "Chilling out",
        thumbnail:
          "https://mc.flock.co/stickers/bunny-bobo/chilling-out-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bunny-bobo/chilling-out.png",
        categoryId: "bunny-bobo",
        categoryName: "Bunny Bobo"
      },
      {
        _id: "cowboy",
        id: "bunny-bobo_cowboy",
        name: "Cowboy",
        thumbnail: "https://mc.flock.co/stickers/bunny-bobo/cowboy-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bunny-bobo/cowboy.png",
        categoryId: "bunny-bobo",
        categoryName: "Bunny Bobo"
      },
      {
        _id: "sticking-tongue-out-as-a-retort",
        id: "bunny-bobo_sticking-tongue-out-as-a-retort",
        name: "Sticking tongue out as a retort",
        thumbnail:
          "https://mc.flock.co/stickers/bunny-bobo/sticking-tongue-out-as-a-retort-thumb.png",
        type: "image/png",
        image:
          "https://mc.flock.co/stickers/bunny-bobo/sticking-tongue-out-as-a-retort.png",
        categoryId: "bunny-bobo",
        categoryName: "Bunny Bobo"
      }
    ]
  },
  {
    id: "vampire-little",
    name: "Vampire Little",
    order: 6,
    image: "https://mc.flock.co/stickers/vampire-little/icon.png",
    stickers: [
      {
        _id: "smile",
        id: "vampire-little_smile",
        name: "Smile",
        thumbnail:
          "https://mc.flock.co/stickers/vampire-little/smile-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/vampire-little/smile.png",
        categoryId: "vampire-little",
        categoryName: "Vampire Little"
      },
      {
        _id: "big-grin",
        id: "vampire-little_big-grin",
        name: "Big grin",
        thumbnail:
          "https://mc.flock.co/stickers/vampire-little/big-grin-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/vampire-little/big-grin.png",
        categoryId: "vampire-little",
        categoryName: "Vampire Little"
      },
      {
        _id: "sad",
        id: "vampire-little_sad",
        name: "Sad",
        thumbnail: "https://mc.flock.co/stickers/vampire-little/sad-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/vampire-little/sad.png",
        categoryId: "vampire-little",
        categoryName: "Vampire Little"
      },
      {
        _id: "wink",
        id: "vampire-little_wink",
        name: "Wink",
        thumbnail: "https://mc.flock.co/stickers/vampire-little/wink-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/vampire-little/wink.png",
        categoryId: "vampire-little",
        categoryName: "Vampire Little"
      },
      {
        _id: "surprised",
        id: "vampire-little_surprised",
        name: "Surprised",
        thumbnail:
          "https://mc.flock.co/stickers/vampire-little/surprised-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/vampire-little/surprised.png",
        categoryId: "vampire-little",
        categoryName: "Vampire Little"
      },
      {
        _id: "sticking-tongue-out",
        id: "vampire-little_sticking-tongue-out",
        name: "Sticking tongue out",
        thumbnail:
          "https://mc.flock.co/stickers/vampire-little/sticking-tongue-out-thumb.png",
        type: "image/png",
        image:
          "https://mc.flock.co/stickers/vampire-little/sticking-tongue-out.png",
        categoryId: "vampire-little",
        categoryName: "Vampire Little"
      },
      {
        _id: "angry",
        id: "vampire-little_angry",
        name: "Angry",
        thumbnail:
          "https://mc.flock.co/stickers/vampire-little/angry-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/vampire-little/angry.png",
        categoryId: "vampire-little",
        categoryName: "Vampire Little"
      },
      {
        _id: "broken-heart",
        id: "vampire-little_broken-heart",
        name: "Broken heart",
        thumbnail:
          "https://mc.flock.co/stickers/vampire-little/broken-heart-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/vampire-little/broken-heart.png",
        categoryId: "vampire-little",
        categoryName: "Vampire Little"
      },
      {
        _id: "kisses",
        id: "vampire-little_kisses",
        name: "Kisses",
        thumbnail:
          "https://mc.flock.co/stickers/vampire-little/kisses-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/vampire-little/kisses.png",
        categoryId: "vampire-little",
        categoryName: "Vampire Little"
      },
      {
        _id: "pretty-please",
        id: "vampire-little_pretty-please",
        name: "Pretty please",
        thumbnail:
          "https://mc.flock.co/stickers/vampire-little/pretty-please-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/vampire-little/pretty-please.png",
        categoryId: "vampire-little",
        categoryName: "Vampire Little"
      },
      {
        _id: "lol",
        id: "vampire-little_lol",
        name: "LOL/ROFL",
        thumbnail: "https://mc.flock.co/stickers/vampire-little/lol-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/vampire-little/lol.png",
        categoryId: "vampire-little",
        categoryName: "Vampire Little"
      },
      {
        _id: "will-you-go-out-with-me",
        id: "vampire-little_will-you-go-out-with-me",
        name: "Will you go out with me?",
        thumbnail:
          "https://mc.flock.co/stickers/vampire-little/will-you-go-out-with-me-thumb.png",
        type: "image/png",
        image:
          "https://mc.flock.co/stickers/vampire-little/will-you-go-out-with-me.png",
        categoryId: "vampire-little",
        categoryName: "Vampire Little"
      },
      {
        _id: "crying-profusely",
        id: "vampire-little_crying-profusely",
        name: "Crying profusely",
        thumbnail:
          "https://mc.flock.co/stickers/vampire-little/crying-profusely-thumb.png",
        type: "image/png",
        image:
          "https://mc.flock.co/stickers/vampire-little/crying-profusely.png",
        categoryId: "vampire-little",
        categoryName: "Vampire Little"
      },
      {
        _id: "sleeping",
        id: "vampire-little_sleeping",
        name: "Sleeping",
        thumbnail:
          "https://mc.flock.co/stickers/vampire-little/sleeping-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/vampire-little/sleeping.png",
        categoryId: "vampire-little",
        categoryName: "Vampire Little"
      },
      {
        _id: "hi",
        id: "vampire-little_hi",
        name: "Hi",
        thumbnail: "https://mc.flock.co/stickers/vampire-little/hi-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/vampire-little/hi.png",
        categoryId: "vampire-little",
        categoryName: "Vampire Little"
      },
      {
        _id: "sick",
        id: "vampire-little_sick",
        name: "Sick",
        thumbnail: "https://mc.flock.co/stickers/vampire-little/sick-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/vampire-little/sick.png",
        categoryId: "vampire-little",
        categoryName: "Vampire Little"
      },
      {
        _id: "good-morning",
        id: "vampire-little_good-morning",
        name: "Good Morning",
        thumbnail:
          "https://mc.flock.co/stickers/vampire-little/good-morning-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/vampire-little/good-morning.png",
        categoryId: "vampire-little",
        categoryName: "Vampire Little"
      },
      {
        _id: "drunk",
        id: "vampire-little_drunk",
        name: "Drunk",
        thumbnail:
          "https://mc.flock.co/stickers/vampire-little/drunk-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/vampire-little/drunk.png",
        categoryId: "vampire-little",
        categoryName: "Vampire Little"
      },
      {
        _id: "really",
        id: "vampire-little_really",
        name: "Really?",
        thumbnail:
          "https://mc.flock.co/stickers/vampire-little/really-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/vampire-little/really.png",
        categoryId: "vampire-little",
        categoryName: "Vampire Little"
      },
      {
        _id: "vacationing",
        id: "vampire-little_vacationing",
        name: "Vacationing",
        thumbnail:
          "https://mc.flock.co/stickers/vampire-little/vacationing-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/vampire-little/vacationing.png",
        categoryId: "vampire-little",
        categoryName: "Vampire Little"
      }
    ]
  },
  {
    id: "big-al-the-pirate",
    name: "Big Al the Pirate",
    order: 7,
    image: "https://mc.flock.co/stickers/big-al-the-pirate/icon.png",
    stickers: [
      {
        _id: "smile",
        id: "big-al-the-pirate_smile",
        name: "Smile",
        thumbnail:
          "https://mc.flock.co/stickers/big-al-the-pirate/smile-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/big-al-the-pirate/smile.png",
        categoryId: "big-al-the-pirate",
        categoryName: "Big Al the Pirate"
      },
      {
        _id: "big-grin",
        id: "big-al-the-pirate_big-grin",
        name: "Big grin",
        thumbnail:
          "https://mc.flock.co/stickers/big-al-the-pirate/big-grin-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/big-al-the-pirate/big-grin.png",
        categoryId: "big-al-the-pirate",
        categoryName: "Big Al the Pirate"
      },
      {
        _id: "sad",
        id: "big-al-the-pirate_sad",
        name: "Sad",
        thumbnail:
          "https://mc.flock.co/stickers/big-al-the-pirate/sad-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/big-al-the-pirate/sad.png",
        categoryId: "big-al-the-pirate",
        categoryName: "Big Al the Pirate"
      },
      {
        _id: "surprised",
        id: "big-al-the-pirate_surprised",
        name: "Surprised",
        thumbnail:
          "https://mc.flock.co/stickers/big-al-the-pirate/surprised-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/big-al-the-pirate/surprised.png",
        categoryId: "big-al-the-pirate",
        categoryName: "Big Al the Pirate"
      },
      {
        _id: "crying-copiously",
        id: "big-al-the-pirate_crying-copiously",
        name: "Crying (Copiously)",
        thumbnail:
          "https://mc.flock.co/stickers/big-al-the-pirate/crying-copiously-thumb.png",
        type: "image/png",
        image:
          "https://mc.flock.co/stickers/big-al-the-pirate/crying-copiously.png",
        categoryId: "big-al-the-pirate",
        categoryName: "Big Al the Pirate"
      },
      {
        _id: "sticking-tongue-out",
        id: "big-al-the-pirate_sticking-tongue-out",
        name: "Sticking tongue out",
        thumbnail:
          "https://mc.flock.co/stickers/big-al-the-pirate/sticking-tongue-out-thumb.png",
        type: "image/png",
        image:
          "https://mc.flock.co/stickers/big-al-the-pirate/sticking-tongue-out.png",
        categoryId: "big-al-the-pirate",
        categoryName: "Big Al the Pirate"
      },
      {
        _id: "angry",
        id: "big-al-the-pirate_angry",
        name: "Angry",
        thumbnail:
          "https://mc.flock.co/stickers/big-al-the-pirate/angry-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/big-al-the-pirate/angry.png",
        categoryId: "big-al-the-pirate",
        categoryName: "Big Al the Pirate"
      },
      {
        _id: "broken-hearted",
        id: "big-al-the-pirate_broken-hearted",
        name: "Broken hearted",
        thumbnail:
          "https://mc.flock.co/stickers/big-al-the-pirate/broken-hearted-thumb.png",
        type: "image/png",
        image:
          "https://mc.flock.co/stickers/big-al-the-pirate/broken-hearted.png",
        categoryId: "big-al-the-pirate",
        categoryName: "Big Al the Pirate"
      },
      {
        _id: "lol",
        id: "big-al-the-pirate_lol",
        name: "LOL",
        thumbnail:
          "https://mc.flock.co/stickers/big-al-the-pirate/lol-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/big-al-the-pirate/lol.png",
        categoryId: "big-al-the-pirate",
        categoryName: "Big Al the Pirate"
      },
      {
        _id: "will-you-go-out-with-me",
        id: "big-al-the-pirate_will-you-go-out-with-me",
        name: "Will you go out with me",
        thumbnail:
          "https://mc.flock.co/stickers/big-al-the-pirate/will-you-go-out-with-me-thumb.png",
        type: "image/png",
        image:
          "https://mc.flock.co/stickers/big-al-the-pirate/will-you-go-out-with-me.png",
        categoryId: "big-al-the-pirate",
        categoryName: "Big Al the Pirate"
      },
      {
        _id: "kisses",
        id: "big-al-the-pirate_kisses",
        name: "Kisses",
        thumbnail:
          "https://mc.flock.co/stickers/big-al-the-pirate/kisses-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/big-al-the-pirate/kisses.png",
        categoryId: "big-al-the-pirate",
        categoryName: "Big Al the Pirate"
      },
      {
        _id: "pretty-please",
        id: "big-al-the-pirate_pretty-please",
        name: "Pretty please",
        thumbnail:
          "https://mc.flock.co/stickers/big-al-the-pirate/pretty-please-thumb.png",
        type: "image/png",
        image:
          "https://mc.flock.co/stickers/big-al-the-pirate/pretty-please.png",
        categoryId: "big-al-the-pirate",
        categoryName: "Big Al the Pirate"
      },
      {
        _id: "sleeping",
        id: "big-al-the-pirate_sleeping",
        name: "Sleeping",
        thumbnail:
          "https://mc.flock.co/stickers/big-al-the-pirate/sleeping-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/big-al-the-pirate/sleeping.png",
        categoryId: "big-al-the-pirate",
        categoryName: "Big Al the Pirate"
      },
      {
        _id: "eating",
        id: "big-al-the-pirate_eating",
        name: "Eating",
        thumbnail:
          "https://mc.flock.co/stickers/big-al-the-pirate/eating-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/big-al-the-pirate/eating.png",
        categoryId: "big-al-the-pirate",
        categoryName: "Big Al the Pirate"
      },
      {
        _id: "lets-party",
        id: "big-al-the-pirate_lets-party",
        name: "Let's party",
        thumbnail:
          "https://mc.flock.co/stickers/big-al-the-pirate/lets-party-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/big-al-the-pirate/lets-party.png",
        categoryId: "big-al-the-pirate",
        categoryName: "Big Al the Pirate"
      },
      {
        _id: "ahoy",
        id: "big-al-the-pirate_ahoy",
        name: "Ahoy",
        thumbnail:
          "https://mc.flock.co/stickers/big-al-the-pirate/ahoy-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/big-al-the-pirate/ahoy.png",
        categoryId: "big-al-the-pirate",
        categoryName: "Big Al the Pirate"
      },
      {
        _id: "drunk",
        id: "big-al-the-pirate_drunk",
        name: "Drunk",
        thumbnail:
          "https://mc.flock.co/stickers/big-al-the-pirate/drunk-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/big-al-the-pirate/drunk.png",
        categoryId: "big-al-the-pirate",
        categoryName: "Big Al the Pirate"
      },
      {
        _id: "touche",
        id: "big-al-the-pirate_touche",
        name: "Touche",
        thumbnail:
          "https://mc.flock.co/stickers/big-al-the-pirate/touche-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/big-al-the-pirate/touche.png",
        categoryId: "big-al-the-pirate",
        categoryName: "Big Al the Pirate"
      },
      {
        _id: "bathing",
        id: "big-al-the-pirate_bathing",
        name: "Bathing",
        thumbnail:
          "https://mc.flock.co/stickers/big-al-the-pirate/bathing-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/big-al-the-pirate/bathing.png",
        categoryId: "big-al-the-pirate",
        categoryName: "Big Al the Pirate"
      }
    ]
  },
  {
    id: "bluba",
    name: "Bluba",
    order: 8,
    image: "https://mc.flock.co/stickers/bluba/icon.png",
    stickers: [
      {
        _id: "smile",
        id: "bluba_smile",
        name: "Smile",
        thumbnail: "https://mc.flock.co/stickers/bluba/smile-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bluba/smile.png",
        categoryId: "bluba",
        categoryName: "Bluba"
      },
      {
        _id: "sad",
        id: "bluba_sad",
        name: "Sad",
        thumbnail: "https://mc.flock.co/stickers/bluba/sad-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bluba/sad.png",
        categoryId: "bluba",
        categoryName: "Bluba"
      },
      {
        _id: "surprised",
        id: "bluba_surprised",
        name: "Surprised",
        thumbnail: "https://mc.flock.co/stickers/bluba/surprised-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bluba/surprised.png",
        categoryId: "bluba",
        categoryName: "Bluba"
      },
      {
        _id: "sticking-tongue-out",
        id: "bluba_sticking-tongue-out",
        name: "Sticking tongue out",
        thumbnail:
          "https://mc.flock.co/stickers/bluba/sticking-tongue-out-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bluba/sticking-tongue-out.png",
        categoryId: "bluba",
        categoryName: "Bluba"
      },
      {
        _id: "angry",
        id: "bluba_angry",
        name: "Angry",
        thumbnail: "https://mc.flock.co/stickers/bluba/angry-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bluba/angry.png",
        categoryId: "bluba",
        categoryName: "Bluba"
      },
      {
        _id: "broken-heart",
        id: "bluba_broken-heart",
        name: "Broken heart",
        thumbnail: "https://mc.flock.co/stickers/bluba/broken-heart-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bluba/broken-heart.png",
        categoryId: "bluba",
        categoryName: "Bluba"
      },
      {
        _id: "kisses",
        id: "bluba_kisses",
        name: "Kisses",
        thumbnail: "https://mc.flock.co/stickers/bluba/kisses-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bluba/kisses.png",
        categoryId: "bluba",
        categoryName: "Bluba"
      },
      {
        _id: "lol",
        id: "bluba_lol",
        name: "LOL/ROFL",
        thumbnail: "https://mc.flock.co/stickers/bluba/lol-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bluba/lol.png",
        categoryId: "bluba",
        categoryName: "Bluba"
      },
      {
        _id: "confused",
        id: "bluba_confused",
        name: "Confused",
        thumbnail: "https://mc.flock.co/stickers/bluba/confused-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bluba/confused.png",
        categoryId: "bluba",
        categoryName: "Bluba"
      },
      {
        _id: "super-bluba",
        id: "bluba_super-bluba",
        name: "Super-bluba",
        thumbnail: "https://mc.flock.co/stickers/bluba/super-bluba-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bluba/super-bluba.png",
        categoryId: "bluba",
        categoryName: "Bluba"
      },
      {
        _id: "angel-face",
        id: "bluba_angel-face",
        name: "Angel-face",
        thumbnail: "https://mc.flock.co/stickers/bluba/angel-face-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bluba/angel-face.png",
        categoryId: "bluba",
        categoryName: "Bluba"
      },
      {
        _id: "stealth-ninja",
        id: "bluba_stealth-ninja",
        name: "Stealth ninja",
        thumbnail: "https://mc.flock.co/stickers/bluba/stealth-ninja-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bluba/stealth-ninja.png",
        categoryId: "bluba",
        categoryName: "Bluba"
      },
      {
        _id: "sleeping",
        id: "bluba_sleeping",
        name: "Sleeping",
        thumbnail: "https://mc.flock.co/stickers/bluba/sleeping-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bluba/sleeping.png",
        categoryId: "bluba",
        categoryName: "Bluba"
      },
      {
        _id: "busy",
        id: "bluba_busy",
        name: "Busy",
        thumbnail: "https://mc.flock.co/stickers/bluba/busy-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bluba/busy.png",
        categoryId: "bluba",
        categoryName: "Bluba"
      },
      {
        _id: "drunk",
        id: "bluba_drunk",
        name: "Drunk",
        thumbnail: "https://mc.flock.co/stickers/bluba/drunk-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bluba/drunk.png",
        categoryId: "bluba",
        categoryName: "Bluba"
      },
      {
        _id: "thinking",
        id: "bluba_thinking",
        name: "Thinking",
        thumbnail: "https://mc.flock.co/stickers/bluba/thinking-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bluba/thinking.png",
        categoryId: "bluba",
        categoryName: "Bluba"
      },
      {
        _id: "peeping",
        id: "bluba_peeping",
        name: "Peeping",
        thumbnail: "https://mc.flock.co/stickers/bluba/peeping-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bluba/peeping.png",
        categoryId: "bluba",
        categoryName: "Bluba"
      },
      {
        _id: "getting-angry",
        id: "bluba_getting-angry",
        name: "Getting angry",
        thumbnail: "https://mc.flock.co/stickers/bluba/getting-angry-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bluba/getting-angry.png",
        categoryId: "bluba",
        categoryName: "Bluba"
      },
      {
        _id: "annoyed",
        id: "bluba_annoyed",
        name: "Annoyed",
        thumbnail: "https://mc.flock.co/stickers/bluba/annoyed-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bluba/annoyed.png",
        categoryId: "bluba",
        categoryName: "Bluba"
      },
      {
        _id: "puking",
        id: "bluba_puking",
        name: "Puking",
        thumbnail: "https://mc.flock.co/stickers/bluba/puking-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/bluba/puking.png",
        categoryId: "bluba",
        categoryName: "Bluba"
      }
    ]
  },
  {
    id: "berry-brown",
    name: "Berry Brown",
    order: 9,
    image: "https://mc.flock.co/stickers/berry-brown/icon.png",
    stickers: [
      {
        _id: "smile",
        id: "berry-brown_smile",
        name: "Smile",
        thumbnail: "https://mc.flock.co/stickers/berry-brown/smile-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/berry-brown/smile.png",
        categoryId: "berry-brown",
        categoryName: "Berry Brown"
      },
      {
        _id: "big-grin",
        id: "berry-brown_big-grin",
        name: "Big grin",
        thumbnail:
          "https://mc.flock.co/stickers/berry-brown/big-grin-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/berry-brown/big-grin.png",
        categoryId: "berry-brown",
        categoryName: "Berry Brown"
      },
      {
        _id: "sad",
        id: "berry-brown_sad",
        name: "Sad",
        thumbnail: "https://mc.flock.co/stickers/berry-brown/sad-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/berry-brown/sad.png",
        categoryId: "berry-brown",
        categoryName: "Berry Brown"
      },
      {
        _id: "wink",
        id: "berry-brown_wink",
        name: "Wink",
        thumbnail: "https://mc.flock.co/stickers/berry-brown/wink-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/berry-brown/wink.png",
        categoryId: "berry-brown",
        categoryName: "Berry Brown"
      },
      {
        _id: "surprised",
        id: "berry-brown_surprised",
        name: "Surprised",
        thumbnail:
          "https://mc.flock.co/stickers/berry-brown/surprised-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/berry-brown/surprised.png",
        categoryId: "berry-brown",
        categoryName: "Berry Brown"
      },
      {
        _id: "sticking-tongue-out",
        id: "berry-brown_sticking-tongue-out",
        name: "Sticking tongue out",
        thumbnail:
          "https://mc.flock.co/stickers/berry-brown/sticking-tongue-out-thumb.png",
        type: "image/png",
        image:
          "https://mc.flock.co/stickers/berry-brown/sticking-tongue-out.png",
        categoryId: "berry-brown",
        categoryName: "Berry Brown"
      },
      {
        _id: "angry",
        id: "berry-brown_angry",
        name: "Angry",
        thumbnail: "https://mc.flock.co/stickers/berry-brown/angry-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/berry-brown/angry.png",
        categoryId: "berry-brown",
        categoryName: "Berry Brown"
      },
      {
        _id: "broken-hearted",
        id: "berry-brown_broken-hearted",
        name: "Broken hearted",
        thumbnail:
          "https://mc.flock.co/stickers/berry-brown/broken-hearted-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/berry-brown/broken-hearted.png",
        categoryId: "berry-brown",
        categoryName: "Berry Brown"
      },
      {
        _id: "i-love-you",
        id: "berry-brown_i-love-you",
        name: "I love you",
        thumbnail:
          "https://mc.flock.co/stickers/berry-brown/i-love-you-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/berry-brown/i-love-you.png",
        categoryId: "berry-brown",
        categoryName: "Berry Brown"
      },
      {
        _id: "kisses-on-face",
        id: "berry-brown_kisses-on-face",
        name: "Kisses on face",
        thumbnail:
          "https://mc.flock.co/stickers/berry-brown/kisses-on-face-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/berry-brown/kisses-on-face.png",
        categoryId: "berry-brown",
        categoryName: "Berry Brown"
      },
      {
        _id: "sleeping",
        id: "berry-brown_sleeping",
        name: "Sleeping",
        thumbnail:
          "https://mc.flock.co/stickers/berry-brown/sleeping-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/berry-brown/sleeping.png",
        categoryId: "berry-brown",
        categoryName: "Berry Brown"
      },
      {
        _id: "working-out",
        id: "berry-brown_working-out",
        name: "Working out",
        thumbnail:
          "https://mc.flock.co/stickers/berry-brown/working-out-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/berry-brown/working-out.png",
        categoryId: "berry-brown",
        categoryName: "Berry Brown"
      },
      {
        _id: "reading",
        id: "berry-brown_reading",
        name: "Reading",
        thumbnail: "https://mc.flock.co/stickers/berry-brown/reading-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/berry-brown/reading.png",
        categoryId: "berry-brown",
        categoryName: "Berry Brown"
      },
      {
        _id: "flying",
        id: "berry-brown_flying",
        name: "Flying",
        thumbnail: "https://mc.flock.co/stickers/berry-brown/flying-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/berry-brown/flying.png",
        categoryId: "berry-brown",
        categoryName: "Berry Brown"
      },
      {
        _id: "photographer",
        id: "berry-brown_photographer",
        name: "Photographer",
        thumbnail:
          "https://mc.flock.co/stickers/berry-brown/photographer-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/berry-brown/photographer.png",
        categoryId: "berry-brown",
        categoryName: "Berry Brown"
      },
      {
        _id: "eating",
        id: "berry-brown_eating",
        name: "Eating",
        thumbnail: "https://mc.flock.co/stickers/berry-brown/eating-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/berry-brown/eating.png",
        categoryId: "berry-brown",
        categoryName: "Berry Brown"
      },
      {
        _id: "teeing-off",
        id: "berry-brown_teeing-off",
        name: "Teeing off",
        thumbnail:
          "https://mc.flock.co/stickers/berry-brown/teeing-off-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/berry-brown/teeing-off.png",
        categoryId: "berry-brown",
        categoryName: "Berry Brown"
      },
      {
        _id: "listening-to-music",
        id: "berry-brown_listening-to-music",
        name: "Listening to music",
        thumbnail:
          "https://mc.flock.co/stickers/berry-brown/listening-to-music-thumb.png",
        type: "image/png",
        image:
          "https://mc.flock.co/stickers/berry-brown/listening-to-music.png",
        categoryId: "berry-brown",
        categoryName: "Berry Brown"
      },
      {
        _id: "bubble-gum",
        id: "berry-brown_bubble-gum",
        name: "Bubble gum",
        thumbnail:
          "https://mc.flock.co/stickers/berry-brown/bubble-gum-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/berry-brown/bubble-gum.png",
        categoryId: "berry-brown",
        categoryName: "Berry Brown"
      },
      {
        _id: "sick",
        id: "berry-brown_sick",
        name: "Sick",
        thumbnail: "https://mc.flock.co/stickers/berry-brown/sick-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/berry-brown/sick.png",
        categoryId: "berry-brown",
        categoryName: "Berry Brown"
      },
      {
        _id: "drinking-coffee",
        id: "berry-brown_drinking-coffee",
        name: "Drinking coffee",
        thumbnail:
          "https://mc.flock.co/stickers/berry-brown/drinking-coffee-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/berry-brown/drinking-coffee.png",
        categoryId: "berry-brown",
        categoryName: "Berry Brown"
      },
      {
        _id: "looking-sideways",
        id: "berry-brown_looking-sideways",
        name: "Looking sideways",
        thumbnail:
          "https://mc.flock.co/stickers/berry-brown/looking-sideways-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/berry-brown/looking-sideways.png",
        categoryId: "berry-brown",
        categoryName: "Berry Brown"
      }
    ]
  },
  {
    id: "leroy-the-lion",
    name: "Leroy the Lion",
    order: 10,
    image: "https://mc.flock.co/stickers/leroy-the-lion/icon.png",
    stickers: [
      {
        _id: "smile",
        id: "leroy-the-lion_smile",
        name: "Smile",
        thumbnail:
          "https://mc.flock.co/stickers/leroy-the-lion/smile-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/leroy-the-lion/smile.png",
        categoryId: "leroy-the-lion",
        categoryName: "Leroy the Lion"
      },
      {
        _id: "big-grin",
        id: "leroy-the-lion_big-grin",
        name: "Big grin",
        thumbnail:
          "https://mc.flock.co/stickers/leroy-the-lion/big-grin-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/leroy-the-lion/big-grin.png",
        categoryId: "leroy-the-lion",
        categoryName: "Leroy the Lion"
      },
      {
        _id: "sad",
        id: "leroy-the-lion_sad",
        name: "Sad",
        thumbnail: "https://mc.flock.co/stickers/leroy-the-lion/sad-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/leroy-the-lion/sad.png",
        categoryId: "leroy-the-lion",
        categoryName: "Leroy the Lion"
      },
      {
        _id: "wink",
        id: "leroy-the-lion_wink",
        name: "Wink",
        thumbnail: "https://mc.flock.co/stickers/leroy-the-lion/wink-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/leroy-the-lion/wink.png",
        categoryId: "leroy-the-lion",
        categoryName: "Leroy the Lion"
      },
      {
        _id: "surprised",
        id: "leroy-the-lion_surprised",
        name: "Surprised",
        thumbnail:
          "https://mc.flock.co/stickers/leroy-the-lion/surprised-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/leroy-the-lion/surprised.png",
        categoryId: "leroy-the-lion",
        categoryName: "Leroy the Lion"
      },
      {
        _id: "crying-single-tear",
        id: "leroy-the-lion_crying-single-tear",
        name: "Crying (Single tear)",
        thumbnail:
          "https://mc.flock.co/stickers/leroy-the-lion/crying-single-tear-thumb.png",
        type: "image/png",
        image:
          "https://mc.flock.co/stickers/leroy-the-lion/crying-single-tear.png",
        categoryId: "leroy-the-lion",
        categoryName: "Leroy the Lion"
      },
      {
        _id: "crying-copiously",
        id: "leroy-the-lion_crying-copiously",
        name: "Crying (Copiously)",
        thumbnail:
          "https://mc.flock.co/stickers/leroy-the-lion/crying-copiously-thumb.png",
        type: "image/png",
        image:
          "https://mc.flock.co/stickers/leroy-the-lion/crying-copiously.png",
        categoryId: "leroy-the-lion",
        categoryName: "Leroy the Lion"
      },
      {
        _id: "confused",
        id: "leroy-the-lion_confused",
        name: "Confused",
        thumbnail:
          "https://mc.flock.co/stickers/leroy-the-lion/confused-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/leroy-the-lion/confused.png",
        categoryId: "leroy-the-lion",
        categoryName: "Leroy the Lion"
      },
      {
        _id: "sticking-tongue-out",
        id: "leroy-the-lion_sticking-tongue-out",
        name: "Sticking tongue out",
        thumbnail:
          "https://mc.flock.co/stickers/leroy-the-lion/sticking-tongue-out-thumb.png",
        type: "image/png",
        image:
          "https://mc.flock.co/stickers/leroy-the-lion/sticking-tongue-out.png",
        categoryId: "leroy-the-lion",
        categoryName: "Leroy the Lion"
      },
      {
        _id: "angry",
        id: "leroy-the-lion_angry",
        name: "Angry",
        thumbnail:
          "https://mc.flock.co/stickers/leroy-the-lion/angry-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/leroy-the-lion/angry.png",
        categoryId: "leroy-the-lion",
        categoryName: "Leroy the Lion"
      },
      {
        _id: "blush",
        id: "leroy-the-lion_blush",
        name: "Blush",
        thumbnail:
          "https://mc.flock.co/stickers/leroy-the-lion/blush-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/leroy-the-lion/blush.png",
        categoryId: "leroy-the-lion",
        categoryName: "Leroy the Lion"
      },
      {
        _id: "broken-hearted",
        id: "leroy-the-lion_broken-hearted",
        name: "Broken hearted",
        thumbnail:
          "https://mc.flock.co/stickers/leroy-the-lion/broken-hearted-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/leroy-the-lion/broken-hearted.png",
        categoryId: "leroy-the-lion",
        categoryName: "Leroy the Lion"
      },
      {
        _id: "thumbs-up",
        id: "leroy-the-lion_thumbs-up",
        name: "Thumbs up",
        thumbnail:
          "https://mc.flock.co/stickers/leroy-the-lion/thumbs-up-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/leroy-the-lion/thumbs-up.png",
        categoryId: "leroy-the-lion",
        categoryName: "Leroy the Lion"
      },
      {
        _id: "rofl",
        id: "leroy-the-lion_rofl",
        name: "ROFL",
        thumbnail: "https://mc.flock.co/stickers/leroy-the-lion/rofl-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/leroy-the-lion/rofl.png",
        categoryId: "leroy-the-lion",
        categoryName: "Leroy the Lion"
      },
      {
        _id: "i-love-you",
        id: "leroy-the-lion_i-love-you",
        name: "I love you",
        thumbnail:
          "https://mc.flock.co/stickers/leroy-the-lion/i-love-you-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/leroy-the-lion/i-love-you.png",
        categoryId: "leroy-the-lion",
        categoryName: "Leroy the Lion"
      },
      {
        _id: "will-you-go-out-with-me",
        id: "leroy-the-lion_will-you-go-out-with-me",
        name: "Will you go out with me",
        thumbnail:
          "https://mc.flock.co/stickers/leroy-the-lion/will-you-go-out-with-me-thumb.png",
        type: "image/png",
        image:
          "https://mc.flock.co/stickers/leroy-the-lion/will-you-go-out-with-me.png",
        categoryId: "leroy-the-lion",
        categoryName: "Leroy the Lion"
      },
      {
        _id: "kisses",
        id: "leroy-the-lion_kisses",
        name: "Kisses",
        thumbnail:
          "https://mc.flock.co/stickers/leroy-the-lion/kisses-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/leroy-the-lion/kisses.png",
        categoryId: "leroy-the-lion",
        categoryName: "Leroy the Lion"
      },
      {
        _id: "talk-to-the-hand",
        id: "leroy-the-lion_talk-to-the-hand",
        name: "Talk to the hand",
        thumbnail:
          "https://mc.flock.co/stickers/leroy-the-lion/talk-to-the-hand-thumb.png",
        type: "image/png",
        image:
          "https://mc.flock.co/stickers/leroy-the-lion/talk-to-the-hand.png",
        categoryId: "leroy-the-lion",
        categoryName: "Leroy the Lion"
      },
      {
        _id: "beer",
        id: "leroy-the-lion_beer",
        name: "Beer?",
        thumbnail: "https://mc.flock.co/stickers/leroy-the-lion/beer-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/leroy-the-lion/beer.png",
        categoryId: "leroy-the-lion",
        categoryName: "Leroy the Lion"
      },
      {
        _id: "studying-hard",
        id: "leroy-the-lion_studying-hard",
        name: "Studying hard",
        thumbnail:
          "https://mc.flock.co/stickers/leroy-the-lion/studying-hard-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/leroy-the-lion/studying-hard.png",
        categoryId: "leroy-the-lion",
        categoryName: "Leroy the Lion"
      }
    ]
  },
  {
    id: "milo-the-dog",
    name: "Milo the Dog",
    order: 11,
    image: "https://mc.flock.co/stickers/milo-the-dog/icon.png",
    stickers: [
      {
        _id: "smile",
        id: "milo-the-dog_smile",
        name: "Smile",
        thumbnail: "https://mc.flock.co/stickers/milo-the-dog/smile-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/milo-the-dog/smile.png",
        categoryId: "milo-the-dog",
        categoryName: "Milo the Dog"
      },
      {
        _id: "big-grin",
        id: "milo-the-dog_big-grin",
        name: "Big grin",
        thumbnail:
          "https://mc.flock.co/stickers/milo-the-dog/big-grin-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/milo-the-dog/big-grin.png",
        categoryId: "milo-the-dog",
        categoryName: "Milo the Dog"
      },
      {
        _id: "sad",
        id: "milo-the-dog_sad",
        name: "Sad",
        thumbnail: "https://mc.flock.co/stickers/milo-the-dog/sad-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/milo-the-dog/sad.png",
        categoryId: "milo-the-dog",
        categoryName: "Milo the Dog"
      },
      {
        _id: "wink",
        id: "milo-the-dog_wink",
        name: "Wink",
        thumbnail: "https://mc.flock.co/stickers/milo-the-dog/wink-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/milo-the-dog/wink.png",
        categoryId: "milo-the-dog",
        categoryName: "Milo the Dog"
      },
      {
        _id: "surprised",
        id: "milo-the-dog_surprised",
        name: "Surprised",
        thumbnail:
          "https://mc.flock.co/stickers/milo-the-dog/surprised-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/milo-the-dog/surprised.png",
        categoryId: "milo-the-dog",
        categoryName: "Milo the Dog"
      },
      {
        _id: "crying-single-tear",
        id: "milo-the-dog_crying-single-tear",
        name: "Crying (Single tear)",
        thumbnail:
          "https://mc.flock.co/stickers/milo-the-dog/crying-single-tear-thumb.png",
        type: "image/png",
        image:
          "https://mc.flock.co/stickers/milo-the-dog/crying-single-tear.png",
        categoryId: "milo-the-dog",
        categoryName: "Milo the Dog"
      },
      {
        _id: "crying-copiously",
        id: "milo-the-dog_crying-copiously",
        name: "Crying (Copiously)",
        thumbnail:
          "https://mc.flock.co/stickers/milo-the-dog/crying-copiously-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/milo-the-dog/crying-copiously.png",
        categoryId: "milo-the-dog",
        categoryName: "Milo the Dog"
      },
      {
        _id: "confused",
        id: "milo-the-dog_confused",
        name: "Confused",
        thumbnail:
          "https://mc.flock.co/stickers/milo-the-dog/confused-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/milo-the-dog/confused.png",
        categoryId: "milo-the-dog",
        categoryName: "Milo the Dog"
      },
      {
        _id: "sticking-tongue-out",
        id: "milo-the-dog_sticking-tongue-out",
        name: "Sticking tongue out",
        thumbnail:
          "https://mc.flock.co/stickers/milo-the-dog/sticking-tongue-out-thumb.png",
        type: "image/png",
        image:
          "https://mc.flock.co/stickers/milo-the-dog/sticking-tongue-out.png",
        categoryId: "milo-the-dog",
        categoryName: "Milo the Dog"
      },
      {
        _id: "angry",
        id: "milo-the-dog_angry",
        name: "Angry",
        thumbnail: "https://mc.flock.co/stickers/milo-the-dog/angry-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/milo-the-dog/angry.png",
        categoryId: "milo-the-dog",
        categoryName: "Milo the Dog"
      },
      {
        _id: "blush",
        id: "milo-the-dog_blush",
        name: "Blush",
        thumbnail: "https://mc.flock.co/stickers/milo-the-dog/blush-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/milo-the-dog/blush.png",
        categoryId: "milo-the-dog",
        categoryName: "Milo the Dog"
      },
      {
        _id: "broken-hearted",
        id: "milo-the-dog_broken-hearted",
        name: "Broken hearted",
        thumbnail:
          "https://mc.flock.co/stickers/milo-the-dog/broken-hearted-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/milo-the-dog/broken-hearted.png",
        categoryId: "milo-the-dog",
        categoryName: "Milo the Dog"
      },
      {
        _id: "thumbs-up",
        id: "milo-the-dog_thumbs-up",
        name: "Thumbs up",
        thumbnail:
          "https://mc.flock.co/stickers/milo-the-dog/thumbs-up-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/milo-the-dog/thumbs-up.png",
        categoryId: "milo-the-dog",
        categoryName: "Milo the Dog"
      },
      {
        _id: "rofl",
        id: "milo-the-dog_rofl",
        name: "ROFL",
        thumbnail: "https://mc.flock.co/stickers/milo-the-dog/rofl-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/milo-the-dog/rofl.png",
        categoryId: "milo-the-dog",
        categoryName: "Milo the Dog"
      },
      {
        _id: "i-love-you",
        id: "milo-the-dog_i-love-you",
        name: "I love you",
        thumbnail:
          "https://mc.flock.co/stickers/milo-the-dog/i-love-you-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/milo-the-dog/i-love-you.png",
        categoryId: "milo-the-dog",
        categoryName: "Milo the Dog"
      },
      {
        _id: "will-you-go-out-with-me",
        id: "milo-the-dog_will-you-go-out-with-me",
        name: "Will you go out with me",
        thumbnail:
          "https://mc.flock.co/stickers/milo-the-dog/will-you-go-out-with-me-thumb.png",
        type: "image/png",
        image:
          "https://mc.flock.co/stickers/milo-the-dog/will-you-go-out-with-me.png",
        categoryId: "milo-the-dog",
        categoryName: "Milo the Dog"
      },
      {
        _id: "sleeping",
        id: "milo-the-dog_sleeping",
        name: "Sleeping",
        thumbnail:
          "https://mc.flock.co/stickers/milo-the-dog/sleeping-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/milo-the-dog/sleeping.png",
        categoryId: "milo-the-dog",
        categoryName: "Milo the Dog"
      }
    ]
  },
  {
    id: "munster-the-monster",
    name: "Munster the Monster",
    order: 12,
    image: "https://mc.flock.co/stickers/munster-the-monster/icon.png",
    stickers: [
      {
        _id: "smile",
        id: "munster-the-monster_smile",
        name: "Smile",
        thumbnail:
          "https://mc.flock.co/stickers/munster-the-monster/smile-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/munster-the-monster/smile.png",
        categoryId: "munster-the-monster",
        categoryName: "Munster the Monster"
      },
      {
        _id: "big-grin",
        id: "munster-the-monster_big-grin",
        name: "Big grin",
        thumbnail:
          "https://mc.flock.co/stickers/munster-the-monster/big-grin-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/munster-the-monster/big-grin.png",
        categoryId: "munster-the-monster",
        categoryName: "Munster the Monster"
      },
      {
        _id: "sad",
        id: "munster-the-monster_sad",
        name: "Sad",
        thumbnail:
          "https://mc.flock.co/stickers/munster-the-monster/sad-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/munster-the-monster/sad.png",
        categoryId: "munster-the-monster",
        categoryName: "Munster the Monster"
      },
      {
        _id: "wink",
        id: "munster-the-monster_wink",
        name: "Wink",
        thumbnail:
          "https://mc.flock.co/stickers/munster-the-monster/wink-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/munster-the-monster/wink.png",
        categoryId: "munster-the-monster",
        categoryName: "Munster the Monster"
      },
      {
        _id: "surprised",
        id: "munster-the-monster_surprised",
        name: "Surprised",
        thumbnail:
          "https://mc.flock.co/stickers/munster-the-monster/surprised-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/munster-the-monster/surprised.png",
        categoryId: "munster-the-monster",
        categoryName: "Munster the Monster"
      },
      {
        _id: "crying-copiously",
        id: "munster-the-monster_crying-copiously",
        name: "Crying (Copiously)",
        thumbnail:
          "https://mc.flock.co/stickers/munster-the-monster/crying-copiously-thumb.png",
        type: "image/png",
        image:
          "https://mc.flock.co/stickers/munster-the-monster/crying-copiously.png",
        categoryId: "munster-the-monster",
        categoryName: "Munster the Monster"
      },
      {
        _id: "sticking-tongue-out",
        id: "munster-the-monster_sticking-tongue-out",
        name: "Sticking tongue out",
        thumbnail:
          "https://mc.flock.co/stickers/munster-the-monster/sticking-tongue-out-thumb.png",
        type: "image/png",
        image:
          "https://mc.flock.co/stickers/munster-the-monster/sticking-tongue-out.png",
        categoryId: "munster-the-monster",
        categoryName: "Munster the Monster"
      },
      {
        _id: "angry",
        id: "munster-the-monster_angry",
        name: "Angry",
        thumbnail:
          "https://mc.flock.co/stickers/munster-the-monster/angry-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/munster-the-monster/angry.png",
        categoryId: "munster-the-monster",
        categoryName: "Munster the Monster"
      },
      {
        _id: "broken-hearted",
        id: "munster-the-monster_broken-hearted",
        name: "Broken hearted",
        thumbnail:
          "https://mc.flock.co/stickers/munster-the-monster/broken-hearted-thumb.png",
        type: "image/png",
        image:
          "https://mc.flock.co/stickers/munster-the-monster/broken-hearted.png",
        categoryId: "munster-the-monster",
        categoryName: "Munster the Monster"
      },
      {
        _id: "lol",
        id: "munster-the-monster_lol",
        name: "LOL",
        thumbnail:
          "https://mc.flock.co/stickers/munster-the-monster/lol-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/munster-the-monster/lol.png",
        categoryId: "munster-the-monster",
        categoryName: "Munster the Monster"
      },
      {
        _id: "will-you-go-out-with-me",
        id: "munster-the-monster_will-you-go-out-with-me",
        name: "Will you go out with me",
        thumbnail:
          "https://mc.flock.co/stickers/munster-the-monster/will-you-go-out-with-me-thumb.png",
        type: "image/png",
        image:
          "https://mc.flock.co/stickers/munster-the-monster/will-you-go-out-with-me.png",
        categoryId: "munster-the-monster",
        categoryName: "Munster the Monster"
      },
      {
        _id: "kisses",
        id: "munster-the-monster_kisses",
        name: "Kisses",
        thumbnail:
          "https://mc.flock.co/stickers/munster-the-monster/kisses-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/munster-the-monster/kisses.png",
        categoryId: "munster-the-monster",
        categoryName: "Munster the Monster"
      },
      {
        _id: "pretty-please",
        id: "munster-the-monster_pretty-please",
        name: "Pretty please",
        thumbnail:
          "https://mc.flock.co/stickers/munster-the-monster/pretty-please-thumb.png",
        type: "image/png",
        image:
          "https://mc.flock.co/stickers/munster-the-monster/pretty-please.png",
        categoryId: "munster-the-monster",
        categoryName: "Munster the Monster"
      },
      {
        _id: "sleeping",
        id: "munster-the-monster_sleeping",
        name: "Sleeping",
        thumbnail:
          "https://mc.flock.co/stickers/munster-the-monster/sleeping-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/munster-the-monster/sleeping.png",
        categoryId: "munster-the-monster",
        categoryName: "Munster the Monster"
      },
      {
        _id: "working-out",
        id: "munster-the-monster_working-out",
        name: "Working out",
        thumbnail:
          "https://mc.flock.co/stickers/munster-the-monster/working-out-thumb.png",
        type: "image/png",
        image:
          "https://mc.flock.co/stickers/munster-the-monster/working-out.png",
        categoryId: "munster-the-monster",
        categoryName: "Munster the Monster"
      },
      {
        _id: "feeling-hungry",
        id: "munster-the-monster_feeling-hungry",
        name: "Feeling hungry",
        thumbnail:
          "https://mc.flock.co/stickers/munster-the-monster/feeling-hungry-thumb.png",
        type: "image/png",
        image:
          "https://mc.flock.co/stickers/munster-the-monster/feeling-hungry.png",
        categoryId: "munster-the-monster",
        categoryName: "Munster the Monster"
      },
      {
        _id: "lets-party",
        id: "munster-the-monster_lets-party",
        name: "Let's party",
        thumbnail:
          "https://mc.flock.co/stickers/munster-the-monster/lets-party-thumb.png",
        type: "image/png",
        image:
          "https://mc.flock.co/stickers/munster-the-monster/lets-party.png",
        categoryId: "munster-the-monster",
        categoryName: "Munster the Monster"
      },
      {
        _id: "cartwheels",
        id: "munster-the-monster_cartwheels",
        name: "Cartwheels",
        thumbnail:
          "https://mc.flock.co/stickers/munster-the-monster/cartwheels-thumb.png",
        type: "image/png",
        image:
          "https://mc.flock.co/stickers/munster-the-monster/cartwheels.png",
        categoryId: "munster-the-monster",
        categoryName: "Munster the Monster"
      },
      {
        _id: "running-away",
        id: "munster-the-monster_running-away",
        name: "Running away",
        thumbnail:
          "https://mc.flock.co/stickers/munster-the-monster/running-away-thumb.png",
        type: "image/png",
        image:
          "https://mc.flock.co/stickers/munster-the-monster/running-away.png",
        categoryId: "munster-the-monster",
        categoryName: "Munster the Monster"
      },
      {
        _id: "evil-laugh",
        id: "munster-the-monster_evil-laugh",
        name: "Evil laugh",
        thumbnail:
          "https://mc.flock.co/stickers/munster-the-monster/evil-laugh-thumb.png",
        type: "image/png",
        image:
          "https://mc.flock.co/stickers/munster-the-monster/evil-laugh.png",
        categoryId: "munster-the-monster",
        categoryName: "Munster the Monster"
      }
    ]
  },
  {
    id: "phish",
    name: "Phish",
    order: 13,
    image: "https://mc.flock.co/stickers/phish/icon.png",
    stickers: [
      {
        _id: "smile",
        id: "phish_smile",
        name: "Smile",
        thumbnail: "https://mc.flock.co/stickers/phish/smile-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/phish/smile.png",
        categoryId: "phish",
        categoryName: "Phish"
      },
      {
        _id: "sad",
        id: "phish_sad",
        name: "Sad",
        thumbnail: "https://mc.flock.co/stickers/phish/sad-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/phish/sad.png",
        categoryId: "phish",
        categoryName: "Phish"
      },
      {
        _id: "sticking-tongue-out",
        id: "phish_sticking-tongue-out",
        name: "Sticking tongue out",
        thumbnail:
          "https://mc.flock.co/stickers/phish/sticking-tongue-out-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/phish/sticking-tongue-out.png",
        categoryId: "phish",
        categoryName: "Phish"
      },
      {
        _id: "angry",
        id: "phish_angry",
        name: "Angry",
        thumbnail: "https://mc.flock.co/stickers/phish/angry-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/phish/angry.png",
        categoryId: "phish",
        categoryName: "Phish"
      },
      {
        _id: "broken-heart",
        id: "phish_broken-heart",
        name: "Broken heart",
        thumbnail: "https://mc.flock.co/stickers/phish/broken-heart-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/phish/broken-heart.png",
        categoryId: "phish",
        categoryName: "Phish"
      },
      {
        _id: "kisses",
        id: "phish_kisses",
        name: "Kisses",
        thumbnail: "https://mc.flock.co/stickers/phish/kisses-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/phish/kisses.png",
        categoryId: "phish",
        categoryName: "Phish"
      },
      {
        _id: "confused",
        id: "phish_confused",
        name: "Confused",
        thumbnail: "https://mc.flock.co/stickers/phish/confused-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/phish/confused.png",
        categoryId: "phish",
        categoryName: "Phish"
      },
      {
        _id: "sleeping",
        id: "phish_sleeping",
        name: "Sleeping",
        thumbnail: "https://mc.flock.co/stickers/phish/sleeping-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/phish/sleeping.png",
        categoryId: "phish",
        categoryName: "Phish"
      },
      {
        _id: "busy",
        id: "phish_busy",
        name: "Busy",
        thumbnail: "https://mc.flock.co/stickers/phish/busy-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/phish/busy.png",
        categoryId: "phish",
        categoryName: "Phish"
      },
      {
        _id: "drunk",
        id: "phish_drunk",
        name: "Drunk",
        thumbnail: "https://mc.flock.co/stickers/phish/drunk-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/phish/drunk.png",
        categoryId: "phish",
        categoryName: "Phish"
      },
      {
        _id: "eating",
        id: "phish_eating",
        name: "Eating",
        thumbnail: "https://mc.flock.co/stickers/phish/eating-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/phish/eating.png",
        categoryId: "phish",
        categoryName: "Phish"
      },
      {
        _id: "cool",
        id: "phish_cool",
        name: "Cool",
        thumbnail: "https://mc.flock.co/stickers/phish/cool-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/phish/cool.png",
        categoryId: "phish",
        categoryName: "Phish"
      },
      {
        _id: "thinking",
        id: "phish_thinking",
        name: "Thinking",
        thumbnail: "https://mc.flock.co/stickers/phish/thinking-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/phish/thinking.png",
        categoryId: "phish",
        categoryName: "Phish"
      },
      {
        _id: "like-a-boss",
        id: "phish_like-a-boss",
        name: "Like a boss",
        thumbnail: "https://mc.flock.co/stickers/phish/like-a-boss-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/phish/like-a-boss.png",
        categoryId: "phish",
        categoryName: "Phish"
      },
      {
        _id: "will-you-go-out-with-me",
        id: "phish_will-you-go-out-with-me",
        name: "Will you go out with me?",
        thumbnail:
          "https://mc.flock.co/stickers/phish/will-you-go-out-with-me-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/phish/will-you-go-out-with-me.png",
        categoryId: "phish",
        categoryName: "Phish"
      },
      {
        _id: "mischievous-laugh",
        id: "phish_mischievous-laugh",
        name: "Mischievous laugh",
        thumbnail:
          "https://mc.flock.co/stickers/phish/mischievous-laugh-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/phish/mischievous-laugh.png",
        categoryId: "phish",
        categoryName: "Phish"
      },
      {
        _id: "crying-profusely",
        id: "phish_crying-profusely",
        name: "Crying profusely",
        thumbnail:
          "https://mc.flock.co/stickers/phish/crying-profusely-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/phish/crying-profusely.png",
        categoryId: "phish",
        categoryName: "Phish"
      },
      {
        _id: "wink",
        id: "phish_wink",
        name: "Wink",
        thumbnail: "https://mc.flock.co/stickers/phish/wink-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/phish/wink.png",
        categoryId: "phish",
        categoryName: "Phish"
      },
      {
        _id: "singing",
        id: "phish_singing",
        name: "Singing",
        thumbnail: "https://mc.flock.co/stickers/phish/singing-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/phish/singing.png",
        categoryId: "phish",
        categoryName: "Phish"
      },
      {
        _id: "excited",
        id: "phish_excited",
        name: "Excited",
        thumbnail: "https://mc.flock.co/stickers/phish/excited-thumb.png",
        type: "image/png",
        image: "https://mc.flock.co/stickers/phish/excited.png",
        categoryId: "phish",
        categoryName: "Phish"
      }
    ]
  }
];
