/* global analytics */
import bridge from '@/utils/bridge';

const publish = bridge.publish.bind(bridge);

export function logMedusaGenericEvent(eventName, params) {
  publish('/log_analytics/medusa/helper/genericEvent', [eventName, params]);
}

export function logMedusaChatOpenStarted(id) {
  publish('/log_analytics/medusa/helper/chat_open_started_temp_id', [id]);
}

export function logNewContacts(count) {
  analytics.log('invite', 'invite_via_email', 'search', count);
  publish('/log_analytics/medusa/helper/invites_sent', [
    {
      source: 'search',
      invite_count: count,
    },
  ]);
}

export function logGroup(conversation, newContactsCount, tempId = '') {
  analytics.log('group', 'created', 'search');
  publish('/contact_selected', [conversation.peer.jid]);
  publish('/log_analytics/medusa/helper/chat_open_started_peer_jid_bind', [
    conversation.peer,
    tempId,
  ]);
  publish('/log_analytics/medusa/helper/genericEvent', [
    'channel_created',
    {
      template: 'adhoc',
      channel_type: 'close',
      member_count: conversation.associateCount,
      invite_count: newContactsCount,
      source: 'search',
    },
  ]);
}

export function logContact(contact, tempId = '') {
  if (contact && contact.jid) {
    publish('/contact_selected', [contact.jid]);
    publish('/log_analytics/medusa/helper/chat_open_started_peer_jid_bind', [
      contact,
      tempId,
    ]);
  }
}

export function logChatStarted(type = '') {
  analytics.log('chat_started', 'active_chat_search', type || '');
}

function logInvitesSent(inviteCount, source) {
  bridge.publish('/log_analytics/medusa/helper/invites_sent', [
    {
      source,
      invite_count: inviteCount,
    },
  ]);
}

function logToggleChannelMute(muteValue, source = '') {
  analytics.log('group', muteValue ? 'mute' : 'unmute', source);
}

function logGroupCreated(source) {
  analytics.log('group', 'created', source);
}

export { logInvitesSent, logToggleChannelMute, logGroupCreated };
