export default {
  text_inviteUserByEmail:
    'Dica: Digite uma ID de e-mail para convidar usu\u00e1rios.',
  error_ExternalContactsDisbaled:
    'Os contatos externos est\u00e3o desativados para a sua organiza\u00e7\u00e3o. Voc\u00ea s\u00f3 pode adicionar usu\u00e1rios @',
  default_value: 'Pesquisar Contatos e Canais',
  talk_to: 'Talk.to',
  startChatWithEmail: 'Digite uma id de e-mail para iniciar a conversa',
  ext_contacts_disabled:
    'Os contatos externos est\u00e3o desativados para a sua organiza\u00e7\u00e3o. Voc\u00ea s\u00f3 pode adicionar usu\u00e1rios @',
  tip_email: 'Dica: Digite uma ID de e-mail para convidar usu\u00e1rios.',
  tab_add_people: 'Pressione {button} para adicionar',
  select_to_share: 'Selecionar contatos ou canais para compartilhar',
  open_groups_separator: 'Canais p\u00fablicos',
  dept_search_separator: 'Cargo Departamento',
  other_contacts_separator: 'Contatos que n\u00e3o est\u00e3o na sua equipe',
  invited_but_not_joined: 'Convidado, mas n\u00e3o entrou',
  start_new_chat: 'Iniciar conversa',
  join_button: 'Entrar',
  recommended_contacts_separator: 'N\u00e3o est\u00e1 neste canal',
  imported_contacts_separator: 'Convide para a sua equipe',
  invite_add_button: 'Convidar e Adicionar',
  add_button: 'Adicionar',
  invite_mode: 'Convidar',
  invited_mode: 'Convite enviado',
  frequent: 'frequente',
  recent: 'recente',
  tab: 'tab',
};
