import React from 'react';

import { Radio } from '@talk-to/flock-components';
import withTheme from '@/connectHOCs/Theme';
import PanelSection from '../shared/PanelSection';

import sharedStyles from '../shared/styles.css';
import styles from './Themes.css';

const DescriptiveRadio = ({
  description,
  label,
  id,
  value,
  onChange,
  checked,
}) => {
  return (
    <div className={styles.descriptiveRadio}>
      <Radio
        label={
          <>
            <div>{label}</div>
            <div className={styles.radioDesc}>{description}</div>
          </>
        }
        id={id}
        value={value}
        onChange={onChange}
        checked={checked}
      />
    </div>
  );
};

function Themes({ currentTheme: theme, toggleTheme }) {
  return (
    <div className={sharedStyles.avPreferences}>
      <PanelSection
        title='Change the appearance of Flock on desktop'
        contentClassName={styles['panel-content']}
      >
        <DescriptiveRadio
          id='lightTheme'
          label='Light Theme'
          value='light'
          description='The classic white & green theme'
          onChange={toggleTheme}
          checked={theme === 'light'}
        />
        <DescriptiveRadio
          id='darkTheme'
          label='Dark Theme'
          value='dark'
          onChange={toggleTheme}
          description='A sleek night theme for improved focus'
          checked={theme === 'dark'}
        />
      </PanelSection>
    </div>
  );
}

export default withTheme(Themes);
