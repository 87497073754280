import React from "react";
import { observer } from "mobx-react-lite";
import { Item } from "./item";
import { Emoji } from "../common/emoji";
import { Sticker } from "../common/sticker";
import { useStore } from "../../store";

export const ListItems = observer(function ListItems({ items, style }) {
  const store = useStore();

  const Items = items.map((item) => {
    if (item.type === "emoji") {
      return (
        <Item key={item.id} id={item.id} type={item.type}>
          <Emoji
            data={item}
            width={store.emojiWidth}
            height={store.emojiHeight}
          />
        </Item>
      );
    } else if (item.type === "sticker") {
      return (
        <Item key={item.id} id={item.id} type={item.type}>
          <Sticker
            data={item}
            width={store.stickerWidth}
            height={store.stickerHeight}
          />
        </Item>
      );
    }
    return null;
  });

  const padding =
    items[0].type === "emoji"
      ? store.contentPadding
      : store.contentStickerPadding;

  return (
    <div className="list-items-root" style={style}>
      {Items}

      <style jsx>{`
        .list-items-root {
          display: flex;
          align-items: center;
        }
      `}</style>
      <style jsx>{`
        .list-items-root {
          padding: 0 ${padding}px;
        }
      `}</style>
    </div>
  );
});
