export default {
  sender_name_you: 'T\u00fa',
  message_sentByMe_header: 'Yo',
  phrase_invited: '{actor} a\u00f1adi\u00f3 a {user}',
  phrase_removed: '{actor} eliminaste a {user}',
  phrase_joined: '{user} se ha unido',
  phrase_left: '{user} ha abandonado el canal',
  public_note:
    'Este canal es p\u00fablico. Cualquier miembro del equipo puede ver y unirse a este canal.',
  private_note:
    'Este canal es privado. Solo los miembros invitados pueden leer o publicar mensajes.',
  restricted_note:
    'Este canal es p\u00fablico para grupos de usuarios determinados. Cualquier miembro de los siguientes grupos puede ver y unirse a este canal: {user_groups}',
  notify_public_channel_created:
    '{actor} cre\u00f3 un canal p\u00fablico. Cualquier miembro del equipo puede ver y unirse a este canal.',
  notify_private_channel_created:
    '{actor} cre\u00f3 un canal privado. Solo los miembros invitados pueden leer o publicar mensajes.',
  notify_restricted_channel_created:
    '{actor} cre\u00f3 un canal p\u00fablico para los siguientes grupos de usuarios: {user_groups}',
  notify_channel_purpose_set:
    '{actor} has establecido que el objetivo del canal es "{channel_purpose}"',
  notify_channel_avatar_change: '{actor} ha cambiado el avatar del canal',
  notify_add_channel_admin:
    '{actor} ha nombrado a {user} administrador del canal',
  notify_auto_add_channel_admin: '{user} ahora es administrador del canal',
  notify_remove_channel_admin:
    '{actor} ha revocado los privilegios de administrador de {user}',
  notify_channel_name_change:
    '{actor} ha cambiado el nombre del canal a "{channel_name}"',
  notify_channel_type_restricted:
    '{actor} actualiz\u00f3 los criterios de privacidad de este canal. Todos los usuarios de los siguientes grupos de usuarios pueden ver y unirse a este canal a partir de ahora: {user_groups}. Los usuarios que no pertenezcan a estos grupos de usuarios deben ser a\u00f1adidos por los administradores del canal.',
  notify_channel_type_public:
    '{actor} actualiz\u00f3 los criterios de privacidad de este canal. Cualquier miembro del equipo puede ver y unirse a este canal.',
  notify_channel_type_private:
    '{actor} cambi\u00f3 el tipo de canal a privado. Solo los miembros invitados pueden leer o publicar mensajes.',
  notify_channel_purpose_remove: '{actor} ha borrado el objetivo del canal',
  notify_channel_purpose_change:
    '{actor} ha cambiado el objetivo del canal a "{channel_purpose}"',
  notify_channel_grant_admin:
    '{actor} ha cambiado los ajustes del canal a "los administradores pueden a\u00f1adir/eliminar miembros"',
  notify_channel_grant_everyone:
    '{actor} ha cambiado los ajustes del canal a "todos pueden a\u00f1adir/eliminar miembros"',
};
