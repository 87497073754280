import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import noop from '@/utils/noop';
import cssStyles from './Popover.css';
import { POPOVER_POSITION } from './constants';

const px = (value) => `${value}px`;

// TODO: Write docs for this.
class Popover extends Component {
  static propTypes = {
    open: PropTypes.bool,
    popOverContent: PropTypes.element,
    children: PropTypes.element,
    onClick: PropTypes.func,
    onBlur: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onEscape: PropTypes.func,
    onPopoverMouseEnter: PropTypes.func,
    onPopoverMouseLeave: PropTypes.func,
    onClickOutside: PropTypes.func,
    /**
     * Whether trigger element is considered outside the popover or part of the popover
     * when a click happens outside the popover content. Use in combination with `onClickOutside`.
     */
    triggerElementExistsOutside: PropTypes.bool,
    /**
      Flag to decide, should popover rerender on window resize
    */
    rePositionOnResize: PropTypes.bool,
    position: PropTypes.string,
    heightDifference: PropTypes.number,
    widthDifference: PropTypes.number,
    popOverClass: PropTypes.string,
    triggerClass: PropTypes.string,
    focusOnRender: PropTypes.bool,
    renderOnBody: PropTypes.bool,
    showArrow: PropTypes.bool,
    arrowPosition: PropTypes.string,
    arrowStyle: PropTypes.object,
    /*
      Calculates position of the popover before rendering.
      If returned value is lodash.isEmpty() then popover will not render. 

      If fn is undefined, position prop will be used.
    */
    getPositionStyle: PropTypes.func,
  };

  static defaultProps = {
    open: false,
    popOverContent: <div />,
    children: <div />,
    onClick: noop,
    onBlur: noop,
    onMouseLeave: noop,
    onMouseEnter: noop,
    onPopoverMouseEnter: noop,
    onPopoverMouseLeave: noop,
    onEscape: noop,
    onClickOutside: noop,
    triggerElementExistsOutside: false,
    rePositionOnResize: true,
    position: 'bottom-right',
    popOverClass: '',
    triggerClass: '',
    heightDifference: 0,
    widthDifference: 0,
    focusOnRender: true,
    renderOnBody: true,

    showArrow: false,
    arrowPosition: 'top',
    arrowStyle: {},
    // Logic is based on default being noop or undefined.
    getPositionStyle: noop,
  };

  constructor() {
    super();

    this.el = React.createRef();
    this.mask = React.createRef();
    this.popover = React.createRef();
  }

  componentDidMount() {
    const { open, focusOnRender } = this.props;
    if (
      focusOnRender &&
      open &&
      !this.popover.current.contains(document.activeElement)
    ) {
      this.popover.current.focus();
    }

    this.checkAndlistenForMouseDownsOnBody();
    this.checkAndListenForWindowResize();
    this.setPopoverPosition();
  }

  componentDidUpdate() {
    const { open, focusOnRender } = this.props;
    if (
      focusOnRender &&
      open &&
      !this.popover.current.contains(document.activeElement)
    ) {
      this.popover.current.focus();
    }

    this.checkAndlistenForMouseDownsOnBody();
    this.checkAndListenForWindowResize();
    this.setPopoverPosition();
  }

  // To reduce noise, only attach the mousedown listener on body if popover
  // is in the open state. Don't create listeners if no valid handler is
  // provided.
  checkAndlistenForMouseDownsOnBody = () => {
    const { open, onClickOutside, rePositionOnResize } = this.props;

    if (open && onClickOutside !== noop) {
      document.body.removeEventListener('mousedown', this.onBodyMouseDown);
      document.body.addEventListener('mousedown', this.onBodyMouseDown);
    }

    if (rePositionOnResize) {
      window.removeEventListener('resize', this.onWindowResize);
      window.addEventListener('resize', this.onWindowResize);
    }
  };

  checkAndListenForWindowResize = () => {
    const { rePositionOnResize } = this.props;

    if (rePositionOnResize) {
      window.removeEventListener('resize', this.onWindowResize);
      window.addEventListener('resize', this.onWindowResize);
    }
  };

  componentWillUnmount() {
    document.body.removeEventListener('mousedown', this.onBodyMouseDown);
    window.removeEventListener('resize', this.onWindowResize);
  }

  onWindowResize = throttle(() => {
    this.forceUpdate();
  }, 200);

  onBodyMouseDown = (event) => {
    const { triggerElementExistsOutside, onClickOutside } = this.props;
    const { target } = event;

    let clickedOutside =
      this.popover.current && !this.popover.current.contains(target);

    if (!triggerElementExistsOutside) {
      clickedOutside =
        clickedOutside && this.el.current && !this.el.current.contains(target);
    }

    if (clickedOutside) {
      // Prevents blur from getting fired. This seems to be working
      // fine... for now.
      event.preventDefault();
      onClickOutside(event);
    }
  };

  onMouseEnter = (event) => {
    const { onMouseEnter } = this.props;
    onMouseEnter(event);
  };

  onMouseLeave = (event) => {
    const { onMouseLeave } = this.props;
    onMouseLeave(event);
  };

  onPopoverMouseLeave = (event) => {
    const { onPopoverMouseLeave } = this.props;
    onPopoverMouseLeave(event);
  };

  onPopoverMouseEnter = (event) => {
    const { onPopoverMouseEnter } = this.props;
    onPopoverMouseEnter(event);
  };

  onClick = (event) => {
    // event.preventDefault();
    const { onClick } = this.props;
    if (onClick) {
      onClick(event);
    }
  };

  onPopoverBlur = (event) => {
    const { onBlur } = this.props;

    const target = event.currentTarget;

    if (!target.contains(event.relatedTarget)) {
      onBlur(event);
    }
  };

  /**
   *
   * @param {KeyboardEvent} event
   */
  onPopoverKeyDown = (event) => {
    const { onEscape } = this.props;

    if (event.key === 'Escape' && onEscape !== noop) {
      event.stopPropagation();
      event.preventDefault();

      onEscape();
    }
  };

  getPositionStyle() {
    const {
      position,
      heightDifference,
      widthDifference,
      renderOnBody,
      getPositionStyle,
    } = this.props;

    const defaultStyles = {
      top: 'auto',
      left: 'auto',
      right: 'auto',
      bottom: 'auto',
    };

    if (getPositionStyle && getPositionStyle !== noop) {
      return {
        ...defaultStyles,
        ...getPositionStyle(
          this.el.current,
          this.popover.current,
          renderOnBody
        ),
      };
    }

    const bodyHeight = document.body.clientHeight;
    const bodyWidth = document.body.clientWidth;
    const elRects = this.el.current.getBoundingClientRect();

    const elHeight = elRects.height + heightDifference;
    const elWidth = elRects.width + widthDifference;

    let computed = null;

    switch (position) {
      case POPOVER_POSITION.TOP_LEFT: {
        if (renderOnBody) {
          computed = {
            bottom: px(bodyHeight - elRects.bottom + elHeight),
            right: px(bodyWidth - elRects.right + elWidth),
          };
        } else {
          computed = {
            bottom: px(elHeight),
            right: px(elWidth),
          };
        }
        break;
      }
      case POPOVER_POSITION.TOP_RIGHT: {
        if (renderOnBody) {
          computed = {
            bottom: px(bodyHeight - elRects.bottom + elHeight),
            left: px(elRects.left + elWidth),
          };
        } else {
          computed = {
            bottom: px(elHeight),
            left: px(elWidth),
          };
        }
        break;
      }
      case POPOVER_POSITION.BOTTOM_LEFT: {
        if (renderOnBody) {
          computed = {
            top: px(elRects.top + elHeight),
            right: px(bodyWidth - elRects.right + elWidth),
          };
        } else {
          computed = {
            top: px(heightDifference),
            right: px(elWidth),
          };
        }
        break;
      }
      case POPOVER_POSITION.BOTTOM_RIGHT: {
        if (renderOnBody) {
          computed = {
            top: px(elRects.top + elHeight),
            left: px(elRects.left + elWidth),
          };
        } else {
          computed = {
            top: px(heightDifference),
            right: px(elWidth),
          };
        }
        break;
      }
      case POPOVER_POSITION.BOTTOM:
      default: {
        if (renderOnBody) {
          computed = {
            top: px(elRects.top + elHeight),
            left: px(elRects.left),
          };
        } else {
          computed = {
            top: px(heightDifference),
            left: px(widthDifference),
          };
        }
        break;
      }
    }

    return {
      ...defaultStyles,
      ...computed,
    };
  }

  getArrowPositionClass() {
    const { arrowPosition } = this.props;

    switch (arrowPosition) {
      case 'right':
        return cssStyles.ArrowRight;
      case 'bottom':
        return cssStyles.ArrowBottom;
      case 'left':
        return cssStyles.ArrowLeft;
      case 'top':
      default:
        return cssStyles.ArrowTop;
    }
  }

  setPopoverPosition() {
    if (!this.popover.current || !this.el.current) {
      return;
    }

    const styles = this.getPositionStyle();

    Object.assign(this.popover.current.style, styles);
  }

  getPopover() {
    const { popOverContent, popOverClass, showArrow, arrowStyle } = this.props;

    const popover = (
      <div
        ref={this.popover}
        onMouseLeave={this.onPopoverMouseLeave}
        onMouseEnter={this.onPopoverMouseEnter}
        onKeyDown={this.onPopoverKeyDown}
        className={`${cssStyles.popover_body} ${popOverClass}`}
        onBlur={this.onPopoverBlur}
        tabIndex='-1'
        role='presentation'
      >
        {showArrow ? (
          <div
            className={`${cssStyles.Arrow} ${this.getArrowPositionClass()}`}
            style={arrowStyle}
          />
        ) : null}
        {popOverContent}
      </div>
    );

    return popover;
  }

  render() {
    const { children, open, triggerClass, renderOnBody } = this.props;

    return (
      <React.Fragment>
        <span
          ref={this.el}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          className={`${cssStyles.popover_trigger} ${triggerClass}`}
          onClick={this.onClick}
          onKeyUp={noop}
          role='button'
          tabIndex='-1'
        >
          {children}
          {open && !renderOnBody ? this.getPopover() : null}
        </span>
        {open && renderOnBody
          ? ReactDOM.createPortal(this.getPopover(), document.body)
          : null}
      </React.Fragment>
    );
  }
}

export default Popover;
