import { connect } from 'react-redux';
import bridge from '@/utils/bridge';
import { Actions } from '@/actions/app';

function mapStateToProps(state) {
  return {
    currentTheme: state.app.theme,
  };
}

function mapDispatchToProps(dispatch) {
  function getThemeFromHydra() {
    bridge.ask('ShellController', 'getTheme').then((theme) => {
      dispatch(Actions.set_theme(theme.name));
    });
  }
  getThemeFromHydra();
  return {
    getThemeFromHydra() {
      getThemeFromHydra();
    },
    toggleTheme() {
      bridge.tell('ShellController', 'toggleTheme');
      getThemeFromHydra();
    },
  };
}

export default function withTheme(component) {
  return connect(mapStateToProps, mapDispatchToProps)(component);
}
