import React from "react";
import { observer } from "mobx-react-lite";
import { VariableSizeList } from "react-window";
import isNumber from "lodash/isNumber";
import { Row } from "./row";
import { useStore } from "../../store";
import { reaction } from "mobx";

export const List = observer(function List() {
  const store = useStore();
  const ref = React.useRef();
  const innerRef = React.useRef();
  const scrollInitiatorRef = React.useRef('list');
  const scrolledCategoryRef = React.useRef(null);

  const itemKey = React.useCallback((index, data) => data[index].id, []);
  const itemSize = React.useCallback((index) => {
    if (!store.list.length) return 0;
    const i = store.list[index];
    if (i.type === "header") {
      return store.headerHeight;
    } else {
      const e = i.items[0];
      if (e.type === "emoji") {
        return store.itemHeight;
      } else {
        return store.itemStickerHeight;
      }
    }
  });

  const getTarget = (e) => {
    if (e.target.hasAttribute("data-picker-item")) {
      const id = e.target.getAttribute("data-picker-id");
      const type = e.target.getAttribute("data-picker-type");
      return { id, type };
    }
    return {};
  };

  const onItemsRendered = React.useCallback((e) => {
    if (scrollInitiatorRef.current === 'exteral') {
      scrollInitiatorRef.current = 'list';
      return;
    }

    let categoryId = null;
    if (
      !store.isSearching &&
      store.list.length &&
      isNumber(e.visibleStartIndex)
    ) {
      const obj = store.list[e.visibleStartIndex];
      if (obj.type === "header") {
        categoryId = obj.id;
      } else {
        categoryId = obj.items[0].categoryId;
      }
    }

    scrolledCategoryRef.current = categoryId;
    store.setCategoryId(categoryId);
    store.setItemsRendered(e);
  }, []);

  const onClick = React.useCallback(
    (e) => {
      const { id, type } = getTarget(e);
      if (id && type) {
        store.setSelection(id, type);
      }
    },
    [store.setSelection]
  );
  const onMouseOver = React.useCallback((e) => {
    const { id, type } = getTarget(e);
    if (id && type) {
      store.setHover(id, type);
    }
  });

  React.useEffect(() => {
    if (innerRef.current && innerRef.current.clientWidth > 0) {
      store.setInnerListWidth(innerRef.current.clientWidth);
    }
  });

  React.useEffect(() => {
    if (innerRef.current) {
      innerRef.current.addEventListener("mouseover", onMouseOver);
    }
    return () => {
      if (innerRef.current) {
        innerRef.current.removeEventListener("mouseover", onMouseOver);
      }
    };
  }, [innerRef.current]);

  React.useEffect(() => {
    const dispose = reaction(
      () => store.lastReset,
      () => {
        ref.current?.scrollTo(0);
      },
      {
        name: "resetListScrollOnStoreReset",
      }
    );

    return () => {
      dispose();
    };
  }, []);

  React.useEffect(() => {
    const dispose = reaction(
      () => store.categoryId,
      () => {
        if (store.categoryId === scrolledCategoryRef.current) {
          return;
        } else {
          scrollInitiatorRef.current = 'external';
        }
        
        const categoryIndex = store.list.findIndex(
          (i) => i.type === "header" && i.id === store.categoryId
        );

        if (categoryIndex > -1) {
          store.searchTerm = "";
          ref.current?.scrollToItem(categoryIndex, "start");
        }
      },
      {
        name: "setListScrollPositionOnCategoryChange",
      }
    );

    return () => {
      dispose();
    };
  });

  const style = {
    display: store.isSearching ? "none" : "block",
  };

  return (
    <div style={style} onClick={onClick}>
      <VariableSizeList
        ref={ref}
        innerRef={innerRef}
        itemCount={store.list.length}
        itemData={store.list}
        itemKey={itemKey}
        itemSize={itemSize}
        estimatedItemSize={store.estimatedItemSize}
        overscanCount={2}
        width={store.contentWidth}
        height={store.contentHeight}
        onItemsRendered={onItemsRendered}
      >
        {Row}
      </VariableSizeList>
    </div>
  );
});
