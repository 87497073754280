/* eslint-disable import/prefer-default-export, prefer-destructuring, no-prototype-builtins */
import { logEventToMedusa } from '../../FroalaUtils/FroalaEditorUtils';

const commands = {
  bold: {
    name: 'bold',
    selector: 'strong',
  },
  italic: {
    name: 'italic',
    selector: 'em',
  },
  underline: {
    name: 'underline',
    selector: 'u',
  },
  strikeThrough: {
    name: 'strikethrough',
    selector: 's',
  },
  insertLink: {
    name: 'link',
  },
  textColor: {
    name: 'fontColor',
  },
};

let isButtonClicked = false;

const _getEventObj = (
  commandName: string,
  isAdded: boolean,
  isKBShortcut: boolean
) => {
  const actionType = isAdded ? 'added' : 'removed';
  const actionBy = isKBShortcut ? 'kb' : 'click';
  return {
    category: 'text_formatting',
    action: `${actionType}_w_${actionBy}`,
    label: commandName,
  };
};

const logFormatting = (
  commandName: string,
  isAdded: boolean,
  isKBShortcut: boolean
) => {
  const eventObj = _getEventObj(commandName, isAdded, isKBShortcut);
  logEventToMedusa(eventObj);
};

export const MedusaLogger = (frolaEditor) => {
  const FE =
    frolaEditor && frolaEditor.hasOwnProperty('default')
      ? frolaEditor.default
      : frolaEditor;
  FE.MODULES.MedusaLogger = (editor) => {
    function _init() {
      editor.events.on('commands.before', (commandName: string) => {
        const commandProperties = commands[commandName];
        if (commandProperties) {
          let isAdded = true;
          if (commandProperties.selector) {
            isAdded = !editor.format.is(commandProperties.selector);
          }
          logFormatting(commandProperties.name, isAdded, !isButtonClicked);
        }
        isButtonClicked = false;
      });

      editor.events.on('commands.mousedown', () => {
        isButtonClicked = true;
      });
    }

    return {
      _init,
    };
  };
};
