/* eslint-disable camelcase */
import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import Tippy from '@tippyjs/react';
import { injectIntl } from 'react-intl';

import { receiptSelector, channelPreferencesSelector } from '@/selectors';
import bridge from '@/utils/bridge';
import ChatsProxy from '@/Proxies/ChatsProxy';
import { getReceiptState } from '@/utils/ReceiptUtils';
import { RECEIPT_STATE } from '@/utils/constants';
import Receipt from '@/components/common/Receipts';
import EmojiDecorator from '@/components/common/EmojiDecorator';
import { getUnreadCount } from '@/utils/ConversationUtils';
import defaultGroupImage from '@/assets/icon-group.svg';
import defaultContactImage from '@/assets/icon-contact.svg';
import noop from '@/utils/noop';
import commonStyles from '@/components/common/common.css';

import MessagePreview from './MessagePreview';
import styles from './ActiveChats.css';
import { MODES, BUCKETS } from './constants';
import { Image, Badge } from '../common';

const HIDE_COUNT_MODES = [MODES.COMPACT];
const defaultLatestMessage = {};

const Guest = ({ guest }) =>
  guest ? <span className={styles.guest}>(guest)</span> : null;

function getLatestVisibleMessage({ jid }, ownerGuid) {
  return bridge.ask('MessageCache', 'getLatestVisibleMessage', [
    { ownerGuid, jid },
  ]);
}

class ChatItem extends PureComponent {
  state = {
    latestMessage: undefined,
    visible: true,
  };

  setTooltipVisibility = (visible) => {
    this.setState({
      visible,
    });
  };

  openConversation = () => {
    const { contact } = this.props;
    ChatsProxy.openConversation(contact, true);
  };

  removeConversation = (e) => {
    const { contact, ownerGuid } = this.props;
    ChatsProxy.removeConversation({ ...contact, ownerGuid });
    e.stopPropagation();
  };

  togglePin = (e) => {
    const {
      contact: { jid, pinned },
      ownerGuid,
    } = this.props;
    ChatsProxy.togglePin(ownerGuid, [jid], !pinned);
    e.stopPropagation();
  };

  fetchLatestMessage = async () => {
    const { ownerGuid, contact } = this.props;
    if (ownerGuid && contact && contact.jid) {
      const latestMessage = await getLatestVisibleMessage(contact, ownerGuid);
      if (latestMessage) {
        this.setState({ latestMessage });
      } else {
        this.setState({ latestMessage: defaultLatestMessage });
      }
    }
  };

  /**
   * @typedef {Object} Message - The message object, usually obtained from Hydra.
   */

  /**
   * Sets the `latestMessage` in state on receiving new message. Sets `latestMessage` if the new message has
   * a later timestamp than the previous `latestMessage` or there's no latestMessage.
   * @param {Message} message - The message that is being received on this peer from Hydra
   */
  onMessage = (message) => {
    if (message.type === 'GAP_MESSAGE') {
      this.fetchLatestMessage();
      return;
    }

    this.setState(({ latestMessage }) => {
      if (
        message.isVisibleMessage &&
        (!latestMessage ||
          (latestMessage && latestMessage.timestamp < message.timestamp))
      ) {
        return { latestMessage: message };
      }
      return null;
    });
  };

  /**
   * Update the `latestMessage` if and only if the message cache IDs are the same.
   * @param {Message} message The updated message from Hydra
   */
  onMessageUpdate = (message) => {
    this.setState(({ latestMessage }) => {
      if (message.message_cache_id === latestMessage?.message_cache_id) {
        return { latestMessage: message };
      }
      return null;
    });
  };

  onDeleteMessage = (message) => {
    if (message.type === 'GAP_MESSAGE') {
      this.fetchLatestMessage();
      return;
    }
    this.setState(({ latestMessage }) => {
      if (message.message_cache_id === latestMessage?.message_cache_id) {
        return { latestMessage: undefined };
      }
      return null;
    }, this.fetchLatestMessage);
  };

  // TODO: remove commented code after testing.
  // Not using reset in messages with gaps.
  onResetMessage = (refreshView) => {
    if (!refreshView) {
      this.setState({ latestMessage: undefined });
    }
  };

  componentDidMount() {
    const { ownerGuid, contact } = this.props;
    /* Fetch latestMessage if available, otherwise will be handled by /newmessage */
    this.fetchLatestMessage();
    this.subscriptions = [
      bridge.subscribe(
        `/pw/messagecache/newmessage/${ownerGuid}/${contact.jid}`,
        this.onMessage
      ),
      bridge.subscribe(
        `/pw/messagecache/messageupdate/${ownerGuid}/${contact.jid}`,
        this.onMessageUpdate
      ),
      bridge.subscribe(
        `/pw/messagecache/deletedMessage/${ownerGuid}/${contact.jid}`,
        this.onDeleteMessage
      ),
      bridge.subscribe(
        `/pw/messagecache/resetMessages/${ownerGuid}/${contact.jid}`,
        this.onResetMessage
      ),
    ];
  }

  componentDidUpdate(prevProps) {
    const {
      contact: { memberCount: prevMemberCount } = {},
      channelPreferences: {
        affiliationChangeVisibility: prevAffiliationChangeVisibility,
      } = {},
    } = prevProps;
    const {
      contact: { memberCount: newMemberCount } = {},
      channelPreferences: {
        affiliationChangeVisibility: newAffiliationChangeVisibility,
      } = {},
    } = this.props;
    if (
      prevMemberCount !== newMemberCount ||
      prevAffiliationChangeVisibility !== newAffiliationChangeVisibility
    ) {
      this.fetchLatestMessage();
    }
  }

  componentWillUnmount() {
    bridge.unsubscribeAll(this.subscriptions);
  }

  render() {
    const {
      bucket,
      ownerGuid,
      contact,
      isSelected,
      mode,
      collapsed,
      intl,
    } = this.props;
    const {
      isBuddy,
      isGroup,
      chatName,
      name,
      imageUrl,
      guest,
      isBot,
      pinned,
      hasMention,
      unreadCount,
      presence,
      isDeactivated,
    } = contact;

    const { visible, latestMessage = defaultLatestMessage } = this.state;
    const { receiptObj } = this.props;

    const hasUnread = unreadCount > 0;
    const unread = unreadCount > 0 && !hasMention && bucket !== BUCKETS.MUTED;
    const mutedUnread = !hasMention && bucket === BUCKETS.MUTED;

    const {
      isInfoMessage,
      sentByOwner,
      sender: { jid: senderJid } = {},
      peer: { isBot: isPeerBot } = {},
    } = latestMessage;
    const receiptState = getReceiptState(receiptObj, latestMessage);
    const showReceipt =
      !isInfoMessage && sentByOwner && !isPeerBot && receiptState;

    const showClassicBadge = mode === MODES.CLASSIC && unreadCount > 0;
    const hideChatItem = !(chatName || name);
    return (
      <Tippy
        maxWidth={200}
        className={commonStyles.info_tooltip}
        content={
          (chatName && (
            <EmojiDecorator text={guest ? `${chatName} (guest)` : chatName} />
          )) ||
          (name && <EmojiDecorator text={name} />) ||
          'Unknown Contact'
        }
        arrow
        placement='right'
        theme='bootstrap'
        delay={[200, 0]}
        disabled={!collapsed || !visible}
      >
        <div
          onClick={this.openConversation}
          onKeyDown={noop}
          tabIndex='0'
          role='button'
          className={classNames(styles.chatItem, styles[bucket], {
            [styles.selected]: isSelected,
            [styles[mode]]: !collapsed,
            [styles.collapsedMode]: collapsed,
            [styles.hidden]: hideChatItem,
          })}
        >
          <div
            className={classNames(styles.chatAvatarContainer, {
              [styles.contactAvatar]: isBot,
              [styles.groupAvatar]: isGroup,
            })}
          >
            <Image
              className={styles.chatAvatar}
              fallbackUrl={isGroup ? defaultGroupImage : defaultContactImage}
              src={imageUrl}
            />
            {!isBot && !isGroup ? (
              <span
                className={classNames(styles.presence, styles[presence], {
                  [styles.Deactivated]: isDeactivated,
                })}
              />
            ) : null}
            {collapsed && unreadCount > 0 ? (
              <div className={styles.badgeContainer}>
                <Badge
                  className={classNames(styles.badge, {
                    [styles.mention]: hasMention,
                    [styles.unread]: unread,
                    [styles.mutedUnread]: mutedUnread,
                    [styles.collapsedBadge]: collapsed,
                  })}
                >
                  {getUnreadCount(unreadCount)}
                </Badge>
              </div>
            ) : null}
          </div>
          <div
            className={classNames(styles.chatDescription, {
              [styles.hasBadge]: unreadCount > 0 || showReceipt,
            })}
          >
            <div
              className={classNames(styles.chatName, {
                [styles.hasUnread]: hasUnread,
              })}
            >
              {(chatName && (
                <>
                  <EmojiDecorator text={chatName} />
                  <Guest guest={guest} />
                </>
              )) ||
                (name && (
                  <>
                    <EmojiDecorator text={name} />
                    <Guest guest={guest} />
                  </>
                )) ||
                'Unknown Contact'}
            </div>
            {mode !== 'compact' && senderJid ? (
              <MessagePreview
                mode={mode}
                className={styles.msgPreview}
                message={latestMessage}
                jid={senderJid}
                ownerGuid={ownerGuid}
                isBuddy={isBuddy}
                isGroup={isGroup}
              />
            ) : null}
          </div>
          <div className={styles.actions}>
            <Tippy
              maxWidth={200}
              className={commonStyles.info_tooltip}
              content={
                pinned
                  ? intl.formatMessage({ id: 'block_tooltip_unpin' })
                  : intl.formatMessage({ id: 'block_tooltip_pin_chat' })
              }
              arrow
              placement='right-end'
              theme='bootstrap'
              onShown={() => this.setTooltipVisibility(false)}
              onHidden={() => this.setTooltipVisibility(true)}
            >
              <span
                onKeyDown={noop}
                tabIndex='-1'
                role='button'
                onClick={this.togglePin}
                className={styles.pinIcon}
              />
            </Tippy>
            {pinned ? null : (
              <span
                onKeyDown={noop}
                tabIndex='-1'
                role='button'
                onClick={this.removeConversation}
                className={styles.closeIcon}
              />
            )}
          </div>
          {unreadCount > 0 && !collapsed ? (
            <Badge
              className={classNames(styles.badge, {
                [styles.mention]: hasMention,
                [styles.unread]: unread,
                [styles.mutedUnread]: mutedUnread,
              })}
            >
              {!mutedUnread || HIDE_COUNT_MODES.indexOf(mode) === -1
                ? getUnreadCount(unreadCount)
                : null}
            </Badge>
          ) : (
            showReceipt && (
              <Receipt
                receiptState={receiptState}
                className={styles.receiptStatus}
                excludeReceipts={[RECEIPT_STATE.READ]}
              />
            )
          )}
          {showClassicBadge ? (
            <Badge
              background='var(--c-unread)'
              className={styles.classicBadge}
            />
          ) : null}
        </div>
      </Tippy>
    );
  }
}

const defaultReceipt = {};

export default connect((state, { contact }) => {
  const receipts = receiptSelector(state);
  const channelPreferences = channelPreferencesSelector(state);
  if (contact && contact.jid) {
    return {
      receiptObj: receipts[contact.jid],
      channelPreferences,
    };
  }
  return defaultReceipt;
})(injectIntl(ChatItem));
