import React, { Component } from 'react';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import memoize from 'memoize-one';
import PCancelable from 'p-cancelable';
import { Image } from '@/components/common';
import { scale, getPreviewData } from '@/utils/image';
import { getSignedDownloadUrl } from '@/utils/ShellUtils';
import { openWidgetFullScreenInShell } from '@/utils/SessionUtils';
import noop from '@/utils/noop';
import cssStyles from './Message.css';

function reportError(err) {
  console.error('Error occurred while loading the image.', err);
}
class AttachmentImage extends Component {
  state = {
    src: null,
  };

  componentDidMount() {
    this.checkSource();
  }

  componentDidUpdate(prevProps) {
    const { attachment } = this.props;
    if (!isEqual(attachment, prevProps.attachment)) {
      this.request?.cancel();
      this.checkSource();
    }
  }

  componentWillUnmount() {
    this.request?.cancel();
  }

  showImagePreviewPopup = () => {
    const { message } = this.props;
    openWidgetFullScreenInShell('widgets/ImagePopup', {
      msg: message,
    });
  };

  // To fix scroll issue due to un-specified height for attachment images,
  // specially for unfurl attachments
  defaultImgStyle = {
    maxWidth: '400px',
    maxHeight: '400px',
    minWidth: '30px',
    minHeight: '30px',
  };

  // Keep height constant as we don't want our layout to shift vertically on load,
  // but horizontally is fine.
  defaultAppImgStyle = {
    height: '250px',
    width: 'auto',
  };

  getStyle = memoize(
    (previewImage, isApp) => {
      const defaultMaxSize = isApp ? 250 : 400;
      const { height, width } = previewImage;
      if (height && width) {
        const { height: calculatedHeight, width: calculatedWidth } = scale(
          {
            height,
            width,
          },
          defaultMaxSize,
          defaultMaxSize
        );
        return {
          height: `${calculatedHeight}px`,
          width: `${calculatedWidth}px`,
        };
      }
      return isApp ? this.defaultAppImgStyle : this.defaultImgStyle;
    },
    ([img, isApp], [prevImg, wasApp]) =>
      img?.height === prevImg?.height &&
      img?.width === prevImg?.width &&
      isApp === wasApp
  );

  render() {
    const {
      attachment: {
        appId,
        views: { image },
      },
      message,
    } = this.props;

    const { filename } = image;
    const previewData = getPreviewData(image);
    const { src } = this.state;
    const style = this.getStyle(previewData, !!appId);
    const isMessageReply = message.replyOf !== undefined;

    if (src) {
      return (
        <Image
          className={classNames(cssStyles.attachmentImage, {
            [cssStyles.appAttachmentImage]: !!appId,
            [cssStyles.sharedImage]: !appId,
          })}
          src={src}
          style={style}
          alt={filename}
          onError={reportError}
          onClick={appId || isMessageReply ? noop : this.showImagePreviewPopup}
        />
      );
    }
    return (
      <div
        className={classNames(cssStyles.appAttachmentImagePlaceholder)}
        style={style}
        onClick={appId || isMessageReply ? noop : this.showImagePreviewPopup}
        onKeyDown={noop}
        role='presentation'
      />
    );
  }

  checkSource() {
    const {
      currentSessionId,
      attachment: {
        views: { image },
      },
    } = this.props;

    const previewData = getPreviewData(image);
    const src = previewData.id ? null : previewData.src;
    if (src) {
      this.setState({
        src,
      });
    } else {
      const pendingRequest = new PCancelable((resolve, reject) => {
        getSignedDownloadUrl(previewData, currentSessionId)
          .then(resolve)
          .catch(reject);
      });
      this.request = pendingRequest;

      pendingRequest
        .then((signedUrl) => {
          // TODO: check if props not changed again before this response
          this.setState({
            src: signedUrl,
          });
        })
        .catch((err) => {
          if (!pendingRequest.isCanceled) {
            console.error('AttachmentImage:', err);
          }
        });
    }
  }
}

export default AttachmentImage;
