export default {
  user_status_away_from_desk: 'Longe da mesa',
  user_status_available: 'Dispon\u00edvel',
  user_status_working_from_home: 'Trabalhando de casa',
  user_status_busy: 'Ocupado',
  user_status_on_leave: 'De licen\u00e7a',
  update: 'Atualizar',
  updated: 'Atualizado',
  error_occurred: 'Ocorreu um erro. Tente de novo mais tarde.',
  oops: 'Oops!',
  okay: 'OK',
};
