/**
 * Reducers output the Redux state (models) for the app. They do not alter the
 * existing state, but generate modified state objects from the old state in
 * response to actions.
 *
 * http://redux.js.org/docs/basics/Reducers.html
 *
 * */
import { combineReducers } from 'redux';
import { ActionTypes } from '../../actions/restricted';
import _sessionsReducerGenerator from './_session';
import messageReducerGenerator from './message';
import contactReducerGenerator from './contact';
import shellReducerGenerator from './shell';
import appListReducerGenerator from './appListReducer';
import editorStateReducer from './editorState';
import receiptStateReducer from './receiptState';
import teamPrivilegesReducer from './teamPrivileges';
import messageAnchorReducer from './messageAnchor';

/*
 * Reducer aggregator
 */
export default combineReducers({
  /*
   * Populate reducers
   */
  _session: _sessionsReducerGenerator(ActionTypes),
  message: messageReducerGenerator(ActionTypes),
  contact: contactReducerGenerator(ActionTypes),
  shell: shellReducerGenerator(ActionTypes),
  dike: appListReducerGenerator(ActionTypes),
  editorState: editorStateReducer(ActionTypes),
  receiptState: receiptStateReducer(ActionTypes),
  teamPrivileges: teamPrivilegesReducer(ActionTypes),
  messageAnchor: messageAnchorReducer(ActionTypes),
});
