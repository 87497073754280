import React from "react";
import { observer } from "mobx-react-lite";
import { VariableSizeList } from "react-window";
import { Row } from "../list/row";
import { useStore } from "../../store";
import { reaction } from "mobx";

export const SearchResults = observer(function SearchResults() {
  const store = useStore();
  const ref = React.useRef();
  const innerRef = React.useRef();

  const itemKey = (index, data) => data[index].id;
  const itemSize = React.useCallback(
    (index) => {
      if (!store.searchResults.length) return 0;
      const i = store.searchResults[index];
      if (i.type === "header") {
        return store.headerHeight;
      } else {
        const e = i.items[0];
        if (e.type === "emoji") {
          return store.itemHeight;
        } else {
          return store.itemStickerHeight;
        }
      }
    },
    [store.searchResults]
  );

  const getTarget = (e) => {
    if (e.target.hasAttribute("data-picker-item")) {
      const id = e.target.getAttribute("data-picker-id");
      const type = e.target.getAttribute("data-picker-type");
      return { id, type };
    }
    return {};
  };

  const onClick = React.useCallback(
    (e) => {
      const { id, type } = getTarget(e);
      if (id && type) {
        store.setSelection(id, type);
      }
    },
    [store.setSelection]
  );
  const onMouseOver = React.useCallback((e) => {
    const { id, type } = getTarget(e);
    if (id && type) {
      store.setHover(id, type);
    }
  });

  React.useEffect(() => {
    const dispose = reaction(
      () => store.searchResults.length,
      () => {
        ref.current?.resetAfterIndex(0, false);
      },
      {
        name: "resetVariableSizeListOnSearchResultsChange",
      }
    );

    return () => {
      dispose();
    };
  }, []);

  React.useEffect(() => {
    if (innerRef.current) {
      innerRef.current.addEventListener("mouseover", onMouseOver);
    }
    return () => {
      innerRef.current.removeEventListener("mouseover", onMouseOver);
    };
  }, [innerRef.current]);

  const style = {
    display: store.isSearching ? "block" : "none",
  };

  return (
    <div style={style} onClick={onClick}>
      <VariableSizeList
        ref={ref}
        innerRef={innerRef}
        itemCount={store.searchResults.length}
        itemData={store.searchResults}
        itemKey={itemKey}
        itemSize={itemSize}
        estimatedItemSize={store.estimatedSearchItemSize}
        overscanCount={2}
        width={store.contentWidth}
        height={store.contentHeight}
      >
        {Row}
      </VariableSizeList>
    </div>
  );
});
