import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';
import bridge from '@/utils/bridge';
import { openUpgradeUrl, notifyAdminForUpgrade } from '@/utils/SessionUtils';
import css from './SpecialUpgradeHook.css';

class SpecialUpgradeHook extends Component {
  static propTypes = {
    isAdmin: PropTypes.bool,
    currentSessionId: PropTypes.string,
    teamPlan: PropTypes.object,
  };

  static defaultProps = {
    isAdmin: false,
    currentSessionId: undefined,
    teamPlan: {},
  };

  state = {
    onAdminNotifiedCss: '',
    nonAdminCtaText: 'Notify Admin',
  };

  onBuyFlockClick = (gaSource) => {
    const { currentSessionId } = this.props;
    openUpgradeUrl(currentSessionId, gaSource);
  };

  onRequestAdmin = () => {
    const { teamPlan: { type } = {}, currentSessionId } = this.props;
    notifyAdminForUpgrade(
      currentSessionId,
      type === 'HARD_PAYWALL_EXPERIMENT'
        ? 'HARD_PAYWALL_ABOVE_TEAM_MODAL'
        : 'DESKTOP_SETTINGS_MENU_REQUEST_PRO'
    ).then(() => {
      this.setState({
        nonAdminCtaText: 'Admin notified',
        onAdminNotifiedCss: css.afterNotify,
      });
    });
  };

  onWrapperClick = () => {
    const {
      teamPlan: { type, attrs: { experiment_type: experimentType } = {} },
      isAdmin,
    } = this.props;

    const SPE_TRIAL = type === 'TRIAL' && experimentType === 'spe_trial';
    if (isAdmin) {
      this.onBuyFlockClick(
        SPE_TRIAL ? 'spe_trial_above_team_name' : 'hard_paywall_above_team_name'
      );
    } else if (SPE_TRIAL) {
      bridge.publish('odara-paywall-comparison', []);
    } else {
      this.onRequestAdmin();
    }
  };

  renderCTA() {
    const {
      teamPlan: { type, attrs: { experiment_type: experimentType } = {} },
      isAdmin,
    } = this.props;
    const { onAdminNotifiedCss, nonAdminCtaText } = this.state;

    if (isAdmin) {
      return <span className={css.cta}>Buy PRO</span>;
    }
    if (type === 'TRIAL' && experimentType === 'spe_trial') {
      return <span className={css.cta}>Know more</span>;
    }
    return (
      <span className={`${css.cta} ${onAdminNotifiedCss}`}>
        {nonAdminCtaText}
      </span>
    );
  }

  renderHardPaywallHook() {
    const {
      teamPlan: { daysLeftForExpiry },
      isAdmin,
    } = this.props;

    return (
      <Tippy
        content={
          isAdmin
            ? 'Buy Flock to avoid losing access'
            : 'Notify your team admin to buy Flock'
        }
        arrow
        placement='bottom'
      >
        <div
          className={`${css.wrapper} ${css.highlighted_wrapper}`}
          onClick={this.onWrapperClick}
          onKeyDown={this.onWrapperClick}
          role='button'
          tabIndex={-1}
          data-pendo-hook='trial-days-left-tl'
        >
          <span className={css.hexagonInStar} />
          <span
            className={`${css.bold} ${css['p-h-5']} ${
              daysLeftForExpiry <= 7 ? css['c-red'] : ''
            }`}
          >
            {daysLeftForExpiry} {daysLeftForExpiry === 1 ? 'day' : 'days'}
          </span>{' '}
          left on your trial
          {this.renderCTA()}
        </div>
      </Tippy>
    );
  }

  renderSPETrialHook() {
    const {
      teamPlan: { daysLeftForExpiry },
    } = this.props;

    return (
      <Tippy
        content='Your team will be moved to the free Starter plan if you do not choose to purchase Flock PRO.'
        arrow
        placement='bottom'
      >
        <div
          className={`${css.wrapper} ${
            daysLeftForExpiry <= 10 ? css.highlighted_wrapper : ''
          }`}
          onClick={this.onWrapperClick}
          onKeyDown={this.onWrapperClick}
          role='button'
          tabIndex={-1}
        >
          <span className={css.hexagonInStar} />
          <span className={css['p-h-5']}>
            <span className={css.bold}>
              {daysLeftForExpiry} {daysLeftForExpiry === 1 ? 'day' : 'days'}
            </span>{' '}
            left on your PRO trial
          </span>
          {this.renderCTA()}
        </div>
      </Tippy>
    );
  }

  render() {
    const {
      teamPlan: { type, attrs: { experiment_type: experimentType } = {} },
    } = this.props;

    if (type === 'HARD_PAYWALL_EXPERIMENT') {
      return this.renderHardPaywallHook();
    }

    if (type === 'TRIAL' && experimentType === 'spe_trial') {
      return this.renderSPETrialHook();
    }

    return null;
  }
}

export default SpecialUpgradeHook;
