export default {
  request_phone_text:
    'Hey, can you edit your Flock profile and add your mobile number so I may call you.',
  delete_channel_confirm_button: 'Yes, leave and delete',
  leave_confirm_button: 'Yes, leave',
  channel_delete_confirm_message:
    'This channel will be deleted since you are the last member. Are you sure?',
  channel_leave_warning_message:
    'You will have to be invited to rejoin this channel. Are you sure?',
  channel_leave_confirm_message: 'Are you sure you wish to leave this channel?',
  leave_channel_title: 'Leave Channel?',
};
