/* eslint-disable no-bitwise */
import platform from '@/utils/platform';
import getContact from './getContact';

const SUPPORTED_UTILITY_CONTACTS = ['TEMP', 'SEARCH_UTILITY', 'IMPORTED'];
const defaultContactImageUrl = 'https://i.talk.to/avatar-default.png';

function generateGuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function getTempContact(text) {
  const nameAutoComplete = text.indexOf('@') > -1 ? '' : '@';
  return {
    jid: `TEMP:::${text}`,
    canChatWith: true,
    email: text,
    id: generateGuid(),
    imageUrl: `${platform.getRootPath()}client_base/images/icon-add-contact.svg`,
    isTempSearchContact: true,
    name: text,
    nameAutoComplete,
    type: 'temp_search',
  };
}

/**
 *
 * How do we handle temp or utility contacts ??
 * special contact ids will have structure as type:::param1:::param2
 *
 * How to check if a contactId is utility contact?
 * tokens = contactId.spit(':::');
 * if tokens[0] === { TEMP || SEARCH_UTILITY || maybe some other defined type },
 * then it's a utility contact id.
 */

export function isContactIdSpecial(contactId) {
  if (!contactId) {
    return false;
  }
  const tokens = contactId.split(':::');
  return SUPPORTED_UTILITY_CONTACTS.indexOf(tokens[0]) > -1;
}

function getSearchUtilityContact(text, type) {
  const tempContact = getTempContact(text);

  return {
    ...tempContact,
    jid: `SEARCH_UTILITY:::${text}:::${type}`,
    type: 'utility_search',
    name: `@${text}`,
    chatName: text,
    chatType: type,
    imageUrl: `${platform.getRootPath()}client_base/images/icon-mention.svg`,
  };
}

function getImportedContact(emailId, name) {
  return {
    jid: `IMPORTED:::${emailId}:::${name}`,
    canChatWith: true,
    chatName: name,
    name,
    email: emailId,
    imageUrl: defaultContactImageUrl,
    isImported: true,
    type: 'email',
    subscription: 'none',
  };
}
/**
 *
 * How do we handle temp or utility contacts ??
 * special contact ids will have structure as type:::param1:::param2
 *
 * How to check if a contactId is utility contact?
 * tokens = contactId.spit(':::');
 * if tokens[0] === { TEMP || SEARCH_UTILITY || maybe some other defined type },
 * then it's a utility contact id.
 */

/**
 *
 * @param {string} contactId
 *
 * @returns {object} contact
 */
export function getContactForId(contactId, ownerGuid) {
  const tokens = contactId.split(':::');
  const [type, ...params] = tokens;
  switch (type) {
    case 'TEMP':
      return getTempContact(...params);
    case 'SEARCH_UTILITY':
      return getSearchUtilityContact(...params);
    case 'IMPORTED':
      return getImportedContact(...params);
    default:
      return getContact(contactId, ownerGuid);
  }
}
