import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { ownerGuidSelector } from '@/selectors';

const allGroupsSelector = (state) => {
  return state.restricted.contact.all_groups_meta_proxy;
};

const allGroupsIdsSelector = createSelector(
  ownerGuidSelector,
  allGroupsSelector,
  (ownerGuid, groups) => groups[ownerGuid]
);

const mapStateToProps = (state) => {
  const allGroupsIds = allGroupsIdsSelector(state);
  return {
    allGroupsIds,
  };
};

export default function (Component) {
  return connect(mapStateToProps, null)(Component);
}
