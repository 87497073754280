import React from 'react';
import cssStyles from './MenuDropdown.css';

function Divider() {
  return (
    <li className={cssStyles.dropdown_list_item_divider}>
      <div className={cssStyles.menu_item_divider} />
    </li>
  );
}

export default Divider;
