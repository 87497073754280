/* eslint react/no-unused-prop-types: "off" */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ContactList from '@/connectHOCs/Contacts/ContactList';
import ownerGuidHOC from '@/connectHOCs/Sessions/currentSession/ownerGuid';
import ContactProxy from '@/Proxies/ContactProxy';
import ContactItem, {
  GroupContactItem,
  SearchUtilContact,
} from './ContactItem';
import styles from './ContactBucket.css';

const bucketIdI18NMap = {
  openChannel: 'open_groups_separator',
  federated: 'other_contacts_separator',
  invited: 'invited_but_not_joined',
  imported: 'imported_contacts_separator',
  recommendation: 'recommended_contacts_separator',
  'mention-utility': 'mention_separator',
};

class ContactBucket extends PureComponent {
  static propTypes = {
    contactIds: PropTypes.array,
    bucketId: PropTypes.string,
    activeIndex: PropTypes.number,
    showHeader: PropTypes.bool,
    onItemClick: PropTypes.func,
    customFieldsMap: PropTypes.object,
    showJoinButton: PropTypes.bool,
    showAddButton: PropTypes.bool,
    showCustomFields: PropTypes.bool,
    showDescription: PropTypes.bool,
    showGroupCreatedBy: PropTypes.bool,
  };

  static defaultProps = {
    contactIds: [],
    bucketId: '',
    activeIndex: -1,
    showHeader: false,
    onItemClick: () => {},
    customFieldsMap: undefined,
    showJoinButton: false,
    showAddButton: false,
    showCustomFields: false,
    showDescription: false,
    showGroupCreatedBy: false,
  };

  constructor(props) {
    super(props);
    const { ownerGuid, contactIds = [] } = this.props;
    this.contactProxySub = ContactProxy.subscribe(ownerGuid, contactIds);
  }

  componentDidUpdate() {
    const { ownerGuid, contactIds = [] } = this.props;
    this.contactProxySub.update(
      ownerGuid,
      contactIds.filter((c) => !c.isTempSearchContact)
    );
  }

  componentWillUnmount() {
    this.contactProxySub.unsubscribe();
  }

  // NOTE: This workaround is needed to let decorator visibility be controlled
  // by editor focus state. (See https://github.com/talk-to/odara/pull/345)
  onMouseDown = (event) => event.preventDefault();

  render() {
    const {
      bucketId,
      activeIndex,
      contacts,
      showHeader,
      customFieldsMap,
      onItemClick,
      showAddButton,
      showJoinButton,
      showDescription,
      showCustomFields,
      showGroupCreatedBy,
    } = this.props;

    return (
      <div>
        {showHeader ? (
          <div className={styles.header}>
            <FormattedMessage
              id={bucketIdI18NMap[bucketId] || 'temp'}
              defaultMessage={`${bucketId} Not handled yet`}
            />
          </div>
        ) : null}
        {contacts.map((c, i) => {
          const { type } = c;
          let Comp = ContactItem;
          switch (type) {
            case 'utility_search':
              Comp = SearchUtilContact;
              break;
            case 'group':
              Comp = GroupContactItem;
              break;
            default:
              Comp = ContactItem;
          }
          return (
            <li
              key={c.isTempSearchContact ? `temp ${c.email}` : c.jid}
              role='option'
              aria-selected={activeIndex === i}
              className={`${styles.li} ${
                activeIndex === i ? styles.active : ''
              }`}
              onClick={() => onItemClick(c.jid)}
              onMouseDown={this.onMouseDown}
              onKeyDown={() => {}}
            >
              <Comp
                contact={c}
                showJoinButton={showJoinButton}
                showAddButton={showAddButton}
                customFieldsMap={customFieldsMap}
                showCustomFields={showCustomFields}
                showDescription={showDescription}
                showCreatedBy={showGroupCreatedBy}
              />
            </li>
          );
        })}
      </div>
    );
  }
}

export default ownerGuidHOC(
  ContactList(ContactBucket, 'contactIds', 'ownerGuid')
);
