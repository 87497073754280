import React from 'react';
import { doAppAction } from '@/utils/ShellUtils';
import currentSessionHOC from '@/connectHOCs/Sessions/currentSession';
import { joinAndOpenChannel } from '@/utils/SessionUtils';
import { addQueryParamtoHTTPUrls } from '@/utils';
import MoreAttachmentActions from './MoreAttachmentActions';
import cssStyles from './AttachmentActions.css';
import LocalAttachmentActions from './LocalAttachmentActions';

const DISPLAY_LIMIT = 2;
const LOCAL_CHANNEL_MENTION_UNFURL = 'local_channel_mention_unfurl';

const getClassName = (icon, appId) => {
  if (icon) return null;
  return appId === LOCAL_CHANNEL_MENTION_UNFURL
    ? cssStyles.channelMentionIcon
    : cssStyles.defaultIcon;
};

const getButtonActions = (attachment, currentSessionId, peer, msgSid) => {
  const { appId, buttons } = attachment;
  if (!buttons) {
    return [];
  }
  return buttons.map((button) => {
    return {
      id: `app__${button.id}`,
      label: button.name,
      icon: button.icon
        ? `url(${addQueryParamtoHTTPUrls(button.icon)}) no-repeat`
        : null,
      class: getClassName(button.icon, appId),
      action: () => {
        if (appId === LOCAL_CHANNEL_MENTION_UNFURL) {
          const { sessionId, channelJID: channelGuid } = attachment;
          joinAndOpenChannel(sessionId, channelGuid);
          return;
        }
        const flockmlParams = {
          name: 'client.pressButton',
          button: 'attachmentButton',
          messageUid: msgSid || '',
          attachmentId: attachment.id,
          buttonId: button.id || null,
        };
        doAppAction(
          button.action,
          appId,
          peer,
          currentSessionId,
          flockmlParams,
          'attachmentButton'
        );
      },
      validator: () => true,
    };
  });
};

const renderLimitedAttachmentActionsItems = (
  attachmentActionsItems,
  currentSession,
  attachment,
  message,
  isPopupNeeded
) => {
  const limitedAttachmentActionsItems = isPopupNeeded
    ? attachmentActionsItems.slice(0, DISPLAY_LIMIT)
    : attachmentActionsItems.filter(() => true);
  const owner = !currentSession ? null : currentSession.owner;
  return limitedAttachmentActionsItems.map((attachmentActionItem) => {
    const iconStyle = attachmentActionItem.icon
      ? {
          WebkitMask: attachmentActionItem.icon,
          WebkitMaskSize: !attachmentActionItem.isLocal ? 'cover' : null,
        }
      : null;
    const localIconClass = attachmentActionItem.isLocal
      ? cssStyles.localIcon
      : '';
    return (
      <div
        key={attachmentActionItem.id}
        className={cssStyles.limitedItemContainer}
        onClick={() =>
          attachmentActionItem.action(
            attachment,
            currentSession.id,
            message,
            owner
          )
        }
        onKeyDown={() => {}}
        tabIndex={-1}
        role='button'
      >
        <span
          className={`${cssStyles.icon} ${attachmentActionItem.class} ${localIconClass}`}
          style={iconStyle}
        />
        <span className={cssStyles.label}>{attachmentActionItem.label}</span>
      </div>
    );
  });
};

const renderOverflowAttachmentActionsItems = (
  attachmentActionsItems,
  isPopupNeeded
) => {
  const overflowAttachmentActionsItems = isPopupNeeded
    ? attachmentActionsItems.slice(DISPLAY_LIMIT)
    : [];
  if (!overflowAttachmentActionsItems.length) {
    return null;
  }

  return <MoreAttachmentActions actionItems={overflowAttachmentActionsItems} />;
};

const AttachmentActions = (props) => {
  const { attachment, peer, message, currentSession } = props;
  const buttonActionsItems = getButtonActions(
    attachment,
    currentSession.id,
    peer,
    message.sid
  );
  const visibleLocalAttachmentActions = LocalAttachmentActions.filter(
    (AttachmentAction) => AttachmentAction.validator(attachment)
  );
  const attachmentActionsItems = [
    ...visibleLocalAttachmentActions,
    ...buttonActionsItems,
  ];
  const isPopupNeeded = attachmentActionsItems.length - DISPLAY_LIMIT > 1;

  return (
    <div className={cssStyles.flexContainer}>
      {renderLimitedAttachmentActionsItems(
        attachmentActionsItems,
        currentSession,
        attachment,
        message,
        isPopupNeeded
      )}
      {renderOverflowAttachmentActionsItems(
        attachmentActionsItems,
        isPopupNeeded
      )}
    </div>
  );
};

export default currentSessionHOC(AttachmentActions);
