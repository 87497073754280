import React, { Component } from 'react';
import noop from '@/utils/noop';
import CSSStyles from './DragAndDrop.css';

export default class DragAndDrop extends Component {
  static defaultProps = {
    handleDrop: noop,
    handleDragIn: noop,
    handleDragOut: noop,
    handleDragOver: noop,
  };

  constructor() {
    super();
    this.state = {
      dragging: false,
      cancelDrop: false,
    };
    this.closeHotCorner = React.createRef();
    this.dragCounter = 0;
  }

  setCancelDrop = (value) => {
    const { cancelDrop } = this.state;
    if (cancelDrop !== value) {
      this.setState({
        cancelDrop: value,
      });
    }
  };

  handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = 'copy';
    const { handleDragOver } = this.props;
    if (e.target === this.closeHotCorner.current) {
      this.setCancelDrop(true);
    } else {
      this.setCancelDrop(false);
    }
    handleDragOver();
  };

  handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { handleDragIn } = this.props;
    this.dragCounter += 1; // to keep track of how many elements deep our cursor is
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ dragging: true });
      handleDragIn();
    }
  };

  handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { handleDragOut } = this.props;
    this.dragCounter -= 1;
    if (this.dragCounter === 0) {
      this.setState({ dragging: false });
      handleDragOut();
    }
  };

  handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { handleDrop } = this.props;
    const { cancelDrop } = this.state;
    this.setState({ dragging: false });
    if (cancelDrop) {
      return;
    }
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleDrop(e.dataTransfer.files);
      e.dataTransfer.clearData();
      this.dragCounter = 0;
    }
  };

  render() {
    const { children } = this.props;
    const { dragging, cancelDrop } = this.state;
    const closeHotCornerHover = cancelDrop ? CSSStyles.closeHotCornerHover : '';

    return (
      <span
        onDrop={this.handleDrop}
        onDragEnter={this.handleDragIn}
        onDragOver={this.handleDragOver}
        onDragLeave={this.handleDragOut}
      >
        {dragging && (
          <div className={CSSStyles.dropFileZone}>
            <div className={CSSStyles.img} />
            <div className={CSSStyles.dropFileContent}>
              <span className={CSSStyles.Title}>Drag & Drop files here</span>
              <p className={CSSStyles.description}>
                You can drop multiple files
                <br />
                to share them on Flock
              </p>
            </div>
            <div
              className={`${CSSStyles.closeHotCorner} ${closeHotCornerHover}`}
              ref={this.closeHotCorner}
            >
              <span className={CSSStyles.btnCancelDrop}>
                <i
                  className={`${CSSStyles.iconClass} ${CSSStyles.iconCloseWhite}`}
                />
                <i
                  className={`${CSSStyles.iconClass} ${CSSStyles.iconBinWhite}`}
                />
              </span>
            </div>
          </div>
        )}
        {children}
      </span>
    );
  }
}
