import React, { PureComponent } from 'react';
import _throttle from 'lodash/throttle';
import _isEmpty from 'lodash/isEmpty';
import EditorPopoverStyleContext from '@/Contexts/EditorPopoverStyle';
import bridge from '@/utils/bridge';
import { CHAT_STATE, PEER_TYPE } from '@/utils/constants';
import currentSessionHOC from '@/connectHOCs/Sessions/currentSession';
import withCurrentPeer from '@/subscribeHOCs/currentPeer';
import withOwner from '@/subscribeHOCs/owner';
import {
  sendMessage,
  getConversationIdentity,
  editLastSentMessage,
} from '@/utils/ConversationUtils';
import { getReplyMessageContent } from '@/utils/MessageReply';
import {
  events,
  subscribe,
  unsubscribe,
  FOCUS_MESSAGE_EDITOR,
} from '@/utils/KeyboardShortcuts';
import {
  decorateFlockml,
  optimizeMessageObject,
} from '@/utils/message/flockml';
import { shortCodeToNative, nativeToShortCode } from '@/lib/emoji';
import getTranslatedString from '@/utils/getTranslatedString';
import uploadImageFromPasteEvent from '@/utils/clipboardImage';
import * as editorFocusPubSub from '@/lib/pubsub/editor-focus';
import editorStateHOC from './editorStateHOC';
import BaseEditor from './BaseEditor';
import { decoratorTokenLookUp } from './TokenUtils';
import { SlashCommandManagerHOC } from './MessageDecorator/SlashDecoratorHelper/SlashCommandManager';
import MessageReply from './MessageReply';
import {
  convertToProperFlockml,
  getFormatting,
  logFormattingToMedusa,
} from './FroalaUtils/FroalaEditorUtils';
import { attachMentions } from './FroalaUtils/MentionsUtils';
import {
  getGenericMentionsUpdateFunctions,
  isElementContentEditable,
  isCharacter,
} from './utils';
import { Peer, CurrentSession, Owner } from './Types';
import css from './ConversationEditor.css';
import i18n from './I18N';

const INACTIVE_TIMEOUT: number = 15000;
const CHAT_STATE_THROTTLE_TIME: number = 5000;
const REPLY_MESSAGE_MAX_LENGTH: number = 85;
const ALLOWED_DECORATORS: string[] = [
  'CHANNEL_MENTION',
  'BUDDY_MENTION',
  'SLASH',
  'EMOJI',
];
type EditorUtilities = {
  getHtmlContentWithMarkers?: Function;
};

type StateType = {
  htmlContent: string;
  textContent: string;
  selectionStart: number;
  selectionEnd: number;
  keepTextAreaFocused: boolean;
  messageObjectUpdates: Function[];
  contactParamUdpates: Function[];
  replyMessage: Message;
};

type Props = {
  currentPeer: Peer;
  owner: Owner;
  currentSession: CurrentSession;
  persistedEditorState: StateType;
  commandManager: { getCommandForText: Function };

  updateEditorState: (conversationId: string, newState: StateType) => void;
  onInteract: () => void;
} & typeof ConversationEditor.defaultProps;

const defaultState = {
  htmlContent: '',
  textContent: '',
  selectionStart: 0,
  selectionEnd: 0,
  keepTextAreaFocused: true,
  messageObjectUpdates: [],
  contactParamUdpates: [],
  replyMessage: null,
};

class ConversationEditor extends PureComponent<Props, Partial<StateType>> {
  static defaultProps = {
    currentPeer: undefined,
    owner: undefined,
    currentSession: undefined,
    persistedEditorState: defaultState,
    onInteract: () => {},
  };

  _throttledSendChatState: any;

  editorFocusUnsubscribe: Function;

  messageReplySubscription: Promise<any>;

  _chatStateTimeout: ReturnType<typeof setTimeout>;

  editorRef = React.createRef<HTMLDivElement>();

  editorUtilities: EditorUtilities = {};

  constructor(props: Props) {
    super(props);

    this.state = props.persistedEditorState || defaultState;

    this._throttledSendChatState = _throttle(
      this._sendChatState,
      CHAT_STATE_THROTTLE_TIME
    );

    this.editorFocusUnsubscribe = editorFocusPubSub.listen(this.setFocus);
  }

  componentDidMount() {
    subscribe(FOCUS_MESSAGE_EDITOR, this.onKeyboardShortcut, 'onKeyDown');
    this.messageReplySubscription = bridge.subscribe(
      '/onMessageReply',
      this.setReplyMessage
    );
    // This custom event will be fired only when original document keydown event has not been handled in keyboard shortcuts.
    document.addEventListener('documentKeydown', this.handleDocumentKeydown);
  }

  componentDidUpdate(prevProps) {
    if (this.isConversationChanged(prevProps)) {
      this.updateState(
        this.getConversationIdentityFromProps(prevProps),
        this.state
      );
      const { persistedEditorState } = this.props;
      this.setState(persistedEditorState);
      this.setFocus(true);
    }
  }

  componentWillUnmount() {
    unsubscribe(FOCUS_MESSAGE_EDITOR, this.onKeyboardShortcut, 'onKeyDown');
    this.editorFocusUnsubscribe();
    bridge.unsubscribeAll([this.messageReplySubscription]);
    // update editor state in redux
    this.updateState(
      this.getConversationIdentityFromProps(this.props),
      this.state
    );

    if (this._chatStateTimeout) {
      clearTimeout(this._chatStateTimeout);
    }

    this._throttledSendChatState.cancel();
    document.removeEventListener('documentKeydown', this.handleDocumentKeydown);
  }

  getPeerJidFromProps = (props) => {
    const {
      currentPeer: { jid: peerJid },
    } = props;
    return peerJid;
  };

  isConversationChanged = (prevProps) => {
    const prevPeerJid = this.getPeerJidFromProps(prevProps);
    const newPeerJid = this.getPeerJidFromProps(this.props);
    return prevPeerJid !== newPeerJid;
  };

  getConversationIdentityFromProps = (props) => {
    const { currentPeer: peer } = props;
    const conversationId = getConversationIdentity(peer);
    return conversationId;
  };

  // updates state in redux and automaticaly merges state object
  updateState = (conversationId, newstate) => {
    const { updateEditorState } = this.props;
    const htmlContentWithMarkers = this.getHtmlContentWithMarkers();
    let stateToStore = newstate;
    if (htmlContentWithMarkers) {
      stateToStore = {
        ...newstate,
        htmlContent: htmlContentWithMarkers,
      };
    }
    updateEditorState(conversationId, stateToStore);
  };

  getHtmlContentWithMarkers = () => {
    if (
      this.editorUtilities &&
      this.editorUtilities.getHtmlContentWithMarkers
    ) {
      return this.editorUtilities.getHtmlContentWithMarkers();
    }
    return null;
  };

  handleDocumentKeydown = (e: CustomEvent) => {
    const { nativeEvent } = e.detail || {};
    const { keepTextAreaFocused } = this.state;
    if (
      !keepTextAreaFocused &&
      nativeEvent &&
      !isElementContentEditable(nativeEvent) &&
      isCharacter(nativeEvent)
    ) {
      this.setFocus();
    }
  };

  setReplyMessage = (message) => {
    const { peer: { jid } = {} as Peer } = message;
    const { currentPeer: peer } = this.props;
    if (peer.jid !== jid) {
      return;
    }
    this.setState({
      replyMessage: message,
    });
    this.setFocus();
  };

  cancelReplyMessage = () => {
    this.setState({
      replyMessage: null,
    });
    this.setFocus();
  };

  onKeyboardShortcut = () => {
    this.setFocus(true);
  };

  onBlur = () => {
    this.setFocus(false);
  };

  onFocus = () => {
    this.setFocus(true);
    const { onInteract } = this.props;
    onInteract();
  };

  onKeyPress = () => {
    const { onInteract } = this.props;
    onInteract();
  };

  onClick = () => {
    const { onInteract } = this.props;
    onInteract();
  };

  setFocus = (value: boolean = true) => {
    this.setState(({ keepTextAreaFocused }) => {
      if (keepTextAreaFocused === value) {
        return null;
      }
      return {
        keepTextAreaFocused: value,
      };
    });
  };

  _getReplyMessageContent = () => {
    const { replyMessage } = this.state;
    return getReplyMessageContent(replyMessage, REPLY_MESSAGE_MAX_LENGTH);
  };

  executeSlashCommand = () => {
    const {
      commandManager: { getCommandForText },
      currentPeer: { type },
    } = this.props;
    const { textContent } = this.state;
    const commandText = textContent.split(' ')[0];
    const slashCommand = getCommandForText(commandText, type === 'group');

    if (!slashCommand) {
      this.onUpdateContent(defaultState);
      return;
    }

    const { contactParamUdpates } = this.state;

    const { currentSession, currentPeer: peer, owner } = this.props;
    const contactParams = contactParamUdpates.reduce((contacts, updateFn) => {
      const v = updateFn(contacts, textContent);
      return v;
    }, []);
    const { action } = slashCommand;

    // TODO: add try catch here, do not trust action to be always succesfull
    action(
      nativeToShortCode(textContent),
      peer,
      owner,
      currentSession.id,
      undefined,
      contactParams
    );
    this.onUpdateContent(defaultState);
  };

  sendMessage = () => {
    const {
      currentPeer: peer,
      owner,
      commandManager: { getCommandForText },
    } = this.props;
    const {
      htmlContent,
      selectionStart,
      selectionEnd,
      messageObjectUpdates,
    } = this.state;
    let { textContent } = this.state;
    const { ownerGuid } = owner || {};

    // Do not send blank messages
    if (!textContent.trim()) {
      return;
    }
    const { type: peerType } = peer;

    const decoratorToken = decoratorTokenLookUp(
      textContent,
      selectionStart,
      selectionEnd
    );
    if (decoratorToken && decoratorToken.tokenType === 'SLASH') {
      const commandText = textContent.split(' ')[0];
      const slashCommand = getCommandForText(commandText, peerType === 'group');
      if (slashCommand) {
        this.executeSlashCommand();
        return;
      }
    }

    const replyMessageContent = this._getReplyMessageContent();
    textContent = shortCodeToNative(textContent);

    let messageObj = {
      flockml: htmlContent,
      text: textContent.trim(),
      notification: textContent.trim(),
      sentByPeer: false,
      peer,
      ...replyMessageContent,
    };
    const genericMentions =
      peerType === 'group' ? getGenericMentionsUpdateFunctions(ownerGuid) : [];

    const _messageObjectUpdates = [
      attachMentions,
      ...messageObjectUpdates,
      ...genericMentions,
    ];

    messageObj = _messageObjectUpdates.reduce((_messageObj, updateFn) => {
      return updateFn(_messageObj, { owner, peer });
    }, messageObj);

    if (messageObj.flockml) {
      messageObj.flockml = convertToProperFlockml(
        decorateFlockml(messageObj.flockml)
      );
    }
    const formattingObj = getFormatting(messageObj.flockml);
    optimizeMessageObject(_isEmpty(formattingObj), messageObj);
    sendMessage(messageObj);
    this.onUpdateContent(defaultState);
    if (!_isEmpty(formattingObj)) {
      logFormattingToMedusa(formattingObj);
    }
  };

  sendSticker = (sticker) => {
    const { currentPeer: peer } = this.props;

    const message = {
      text: '',
      notification: getTranslatedString(
        'mediaTray_chatTab_notification_stickerMessage',
        i18n,
        { stickerName: sticker.name }
      ),
      sentByPeer: false,
      peer,
      messageElements: {
        bundle: {
          type: 'sticker',
          caption: sticker.name,
          title: sticker.name,
          filename: '',
          mime_type: sticker.type,
          source: sticker.image,
          thumbnail: sticker.thumbnail,
        },
      },
    };

    sendMessage(message);
  };

  onPaste = (event: Event) => {
    const { currentPeer, currentSession } = this.props;
    uploadImageFromPasteEvent(event, currentPeer, currentSession);
  };

  onUpArrow = (e: React.KeyboardEvent) => {
    const { metaKey, ctrlKey, shiftKey, altKey } = e;

    // Edit message when only up arrow is pressed. No meta key.
    if (metaKey || ctrlKey || shiftKey || altKey) {
      return true;
    }
    const { currentPeer } = this.props;
    const { textContent } = this.state;
    if (textContent.length === 0) {
      editLastSentMessage(currentPeer);
      return false;
    }
    return true;
  };

  onTextAreaEnter = (event: React.KeyboardEvent) => {
    const { metaKey, ctrlKey, shiftKey, altKey } = event;

    if (!metaKey && !ctrlKey && !shiftKey && !altKey) {
      this.sendMessage();
      this._changeChatState('');
      event.preventDefault();
      return false;
    }
    return true;
  };

  onUpdateContent = (newState: Partial<StateType>) => {
    this.setState(newState);
  };

  _changeChatState = (textContent: string) => {
    if (textContent) {
      // Send throttled 'composing' state update
      this._throttledSendChatState(CHAT_STATE.COMPOSING);
    } else {
      // Set inactive immediately
      this._throttledSendChatState.cancel();
      this._sendChatState(CHAT_STATE.INACTIVE).catch(console.error);
    }

    clearTimeout(this._chatStateTimeout);
    this._chatStateTimeout = setTimeout(() => {
      this._throttledSendChatState.cancel();
      this._sendChatState(CHAT_STATE.INACTIVE).catch(console.error);
    }, INACTIVE_TIMEOUT);
  };

  _sendChatState = async (chatState) => {
    const { currentPeer: peer } = this.props;

    const identity = getConversationIdentity(peer);

    await bridge.tell(`Conversation_${identity}`, 'sendChatState', [
      chatState,
      peer.type === PEER_TYPE.GROUP,
    ]);
  };

  placeholder = () => {
    const { currentPeer } = this.props;
    const { keepTextAreaFocused } = this.state;
    if (!currentPeer) {
      return '';
    }
    const { isMeBot = false, chatName = '' } = currentPeer;

    let text = '';
    if (isMeBot) {
      text = getTranslatedString(
        'editor_chatTab_text_messagePlaceholder_meBot',
        i18n
      );
    } else {
      text = getTranslatedString(
        'editor_chatTab_text_messagePlaceholderNamed',
        i18n,
        { peer: chatName }
      );
    }
    if (!keepTextAreaFocused) {
      text += ` (${events[FOCUS_MESSAGE_EDITOR].shortcutString})`;
    }
    return text;
  };

  popoverStyleFn = () => {
    const bodyHeight = document.body.clientHeight;

    const elRects = this.editorRef.current.getBoundingClientRect();

    // on top of the trigger, i.e text area.
    return {
      bottom: `${bodyHeight - elRects.bottom + elRects.height}px`,
      left: `${elRects.left}px`,
    };
  };

  editorContextValue = {
    getPositionStyle: this.popoverStyleFn,
    popoverTriggerClass: css.popoverTriggerClass,
  };

  registerEditorUtility = (name: string, util: Function) => {
    this.editorUtilities[name] = util;
    return () => {
      this.unregisterEditorUtility(name);
    };
  };

  unregisterEditorUtility = (name: string) => {
    if (this.editorUtilities) {
      delete this.editorUtilities[name];
    }
  };

  render() {
    const {
      textContent,
      selectionStart,
      selectionEnd,
      keepTextAreaFocused,
      messageObjectUpdates,
      contactParamUdpates,
      replyMessage,
      htmlContent,
    } = this.state;
    const { currentPeer: peer, owner, currentSession } = this.props;
    const { id: currentSessionId } = currentSession;

    if (!peer) {
      return null;
    }
    const replyMsgContainer = replyMessage ? css.replyMsgContainer : '';
    const TextAreaInReply = replyMessage ? css.TextAreaInReply : '';
    const WrapperInReply = replyMessage ? css.WrapperInReply : '';

    return (
      <div
        onKeyPress={this.onKeyPress}
        onClick={this.onClick}
        role='presentation'
        className={css.Container}
        data-pendo-hook='conversation-editor'
      >
        <div className={replyMsgContainer}>
          {replyMessage ? (
            <MessageReply
              message={replyMessage}
              cancelReplyMessage={this.cancelReplyMessage}
              MAX_COUNT={REPLY_MESSAGE_MAX_LENGTH}
              currentSessionId={currentSessionId}
            />
          ) : null}
          <div
            className={`${css.Wrapper} ${WrapperInReply}`}
            ref={this.editorRef}
          >
            <EditorPopoverStyleContext.Provider value={this.editorContextValue}>
              <BaseEditor
                owner={owner}
                peer={peer}
                currentSession={currentSession}
                htmlContent={htmlContent}
                textContent={textContent}
                selectionStart={selectionStart}
                selectionEnd={selectionEnd}
                messageObjectUpdates={messageObjectUpdates}
                contactParamUdpates={contactParamUdpates}
                keepTextAreaFocused={keepTextAreaFocused}
                placeholder={this.placeholder()}
                onInput={this._changeChatState}
                onChange={this.onUpdateContent}
                onEnter={this.onTextAreaEnter}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onUpArrow={this.onUpArrow}
                onPaste={this.onPaste}
                sendSticker={this.sendSticker}
                setFocus={this.setFocus}
                showAttachmentPicker={!replyMessage}
                allowedDecorators={ALLOWED_DECORATORS}
                textAreaClassName={TextAreaInReply}
                registerEditorUtility={this.registerEditorUtility}
              />
            </EditorPopoverStyleContext.Provider>
          </div>
        </div>
      </div>
    );
  }
}

export default currentSessionHOC(
  withOwner(
    withCurrentPeer(SlashCommandManagerHOC(editorStateHOC(ConversationEditor)))
  )
);
