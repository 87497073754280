import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import currentSessionIdHOC from '@/connectHOCs/Sessions/currentSession/id';
import AppMenu from '../components/appMenu/AppMenu';

class AppMenuContainer extends PureComponent {
  static defaultProps = {
    currentSessionId: null,
  };

  render() {
    const { currentSessionId, shellLayout } = this.props;
    if (!currentSessionId) {
      return null;
    }
    return (
      <AppMenu currentSessionId={currentSessionId} shellLayout={shellLayout} />
    );
  }
}

AppMenuContainer.propTypes = {
  currentSessionId: PropTypes.string,
  shellLayout: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    shellLayout: state.restricted.shell.layout,
  };
};

export default currentSessionIdHOC(connect(mapStateToProps)(AppMenuContainer));
