import { connect } from 'react-redux';
import currentSessionHOC from '@/connectHOCs/Sessions/currentSession';
import Avatar from '@/components/appMenu/Avatar';
import { checkMention } from '@/utils';

// TODO: this needs to subscribe to UnreadMessagesProxy.
// Currently relying on unreadmessageproxy subscription for all sessions from other component.

const mapStateToProps = (
  state,
  { currentSession, shellLayout: { outerLeft: { collapsed } = {} } = {} }
) => {
  let hasMention = false;
  const {
    message: { all_messages: allMessages, unread_messages: unreadMessages },
  } = state.restricted;

  if (collapsed) {
    const unreadConversationsList = new Set();

    if (currentSession && currentSession.owner) {
      Object.keys(unreadMessages).forEach((ownerGuid) => {
        if (ownerGuid !== currentSession.owner.ownerGuid) {
          const teamUnreadChats = unreadMessages[ownerGuid];
          const teamMessages = allMessages[ownerGuid];
          Object.values(teamUnreadChats).forEach((mCIds) =>
            mCIds.forEach((mCId) => {
              const msg = teamMessages && teamMessages[mCId];
              if (msg && !msg.muted && msg.peer) {
                hasMention = hasMention || checkMention(msg);
                unreadConversationsList.add(msg.peer.jid);
              }
            })
          );
        }
      });
    }

    return {
      collapsed,
      hasMention,
      unreadConversationCount: unreadConversationsList.size,
    };
  }
  return {
    collapsed: false,
  };
};

export default currentSessionHOC(connect(mapStateToProps)(Avatar));
