import { createSelector } from 'reselect';
import memoizeOne from 'memoize-one';
import { getSessionId } from '@/utils/SessionUtils';
import allSessionsSelector from './allSessions';
import ownerGuidSelector from './ownerGuid';

const defaultSession = {
  sessionInfo: {
    teamInfo: {
      teamUrl: '',
      memberCount: 0,
    },
  },
};

export const sessionByGuidSelector = createSelector(
  allSessionsSelector,
  (allSessions) =>
    /* Not exactly needed to add memoize here but
      added to prevent unnecessary execution of getSessionId.
    */
    memoizeOne(
      (ownerGuid) => allSessions[getSessionId(ownerGuid)] || defaultSession
    )
);

export default createSelector(
  ownerGuidSelector,
  sessionByGuidSelector,
  (currentSession, getSessionByGuid) => getSessionByGuid(currentSession)
);
