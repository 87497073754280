export default {
  editor_messageDropdown_menu_text_copy: 'Copiar',
  editor_messageDropdown_menu_text_edit: 'Editar',
  editor_messageDropdown_menu_text_retry: 'Volver a intentar',
  editor_messageDropdown_popup_text_deleteMessage:
    'Este mensaje ser\u00e1 borrado para usted y el destinatario, y ser\u00e1 borrado permanentemente de nuestros servidores.',
  editor_messageDropdown_popup_title_deleteMessage: '\u00bfBorrar mensaje?',
  editor_messageDropdown_popup_buttonText_deleteMessage_Yes: 'S\u00ed, borrar',
  editor_messageDropdown_popup_buttonText_delete: 'Borrar',
  editor_deletion_failed: 'No est\u00e1 permitida esa acci\u00f3n',
  editor_not_connected_to_internet_error:
    'Al parecer est\u00e1 fuera de l\u00ednea. Normalmente, esto se debe a que no hay conectividad o es muy d\u00e9bil.',
};
