import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';
import PlusWidget from '@/components/PlusWidget';
import Popover from '@/components/common/Popover';
import IntlWrapper from '@/Wrappers/IntlWrapper';
import currentSessionOwnerGuidHOC from '@/connectHOCs/Sessions/currentSession/ownerGuid';
import selectedContactsInSearch from '@/connectHOCs/Contacts/selectedContactsInSearch';
import layoutOuterLeftCollapsed from '@/connectHOCs/layoutOuterLeftCollapsed';
import contactSearchConfigHOC from '@/connectHOCs/ContactSearchConfig';
import {
  subscribe,
  unsubscribe,
  events,
  SEARCH,
} from '@/utils/KeyboardShortcuts';
import { KEYS } from '@/utils/constants';
import commonStyles from '@/components/common/common.css';
import bridge from '@/utils/bridge';
import translations from './I18N';
import ContactSearch from './ContactSearch';
import ContactPills from './ContactSearch/SearchInput/ContactPills';
import cssStyles from './Search.css';

class Search extends PureComponent {
  bridgeSubscriptions = [];

  static propTypes = {
    ownerGuid: PropTypes.string,
    selectedContacts: PropTypes.array,
    outerLeftCollapsed: PropTypes.bool,
    ContactSearchConfig: PropTypes.object,
  };

  static defaultProps = {
    ownerGuid: '',

    selectedContacts: [],
    outerLeftCollapsed: false,
    ContactSearchConfig: {
      open: false,
      listType: 'frequent',
      searchText: '',
    },
  };

  bridgeSubscriptions = [
    bridge.subscribe('/active-chats-open-search-clicked', (config) => {
      // Not sure why list type is named stack in hyrda.
      let listType = 'frequent';
      if (config) {
        listType = config.stack;
      }
      this.openSearch({
        listType,
      });
    }),
  ];

  componentDidMount() {
    document.body.addEventListener('keydown', this.onKeyDown, true);
    subscribe(SEARCH, this.openSearchWithoutConfig, 'onKeyDown');
  }

  componentDidUpdate(prevProps) {
    const { ownerGuid: prevOwnerGuid } = prevProps;
    const { ownerGuid } = this.props;
    if (prevOwnerGuid && prevOwnerGuid !== ownerGuid) {
      this.closeSearch();
    }
  }

  componentWillUnmount() {
    document.body.removeEventListener('keydown', this.onKeyDown, true);
    unsubscribe(SEARCH, this.openSearchWithoutConfig, 'onKeyDown');
    clearInterval(this._interval);
    bridge.unsubscribeAll(this.bridgeSubscriptions);
    this.bridgeSubscriptions = null;
  }

  onKeyDown = (e) => {
    const { keyCode } = e;
    const {
      ContactSearchConfig: { open },
    } = this.props;
    if (keyCode === KEYS.ESCAPE && open) {
      this.closeSearch();
      e.stopPropagation();
    }
  };

  onContactSearchBlur = (e) => {
    const target = e.currentTarget;
    setTimeout(() => {
      if (!target.contains(document.activeElement)) {
        this.closeSearch();
      }
    }, 0);
  };

  openSearch = (config) => {
    const { setContactSearchConfig } = this.props;
    setContactSearchConfig({
      ...config,
      open: true,
    });
  };

  openSearchWithoutConfig = () => {
    this.openSearch({
      open: true,
    });
  };

  closeSearch = () => {
    const { setContactSearchConfig } = this.props;
    setContactSearchConfig({
      open: false,
    });
  };

  toggleSearch = () => {
    const {
      ContactSearchConfig: { open },
    } = this.props;

    if (open) {
      this.closeSearch();
    } else {
      this.openSearchWithoutConfig();
    }
  };

  renderCollapsedView() {
    const { ContactSearchConfig } = this.props;

    const { open: showSearch } = ContactSearchConfig;

    const searchShortcutString = events[SEARCH].shortcutString;
    return (
      <div className={cssStyles.wrapper} id='searchController'>
        <div
          className={`${cssStyles.searchContainer} ${cssStyles.pointer} ${cssStyles.containerCollapsed}`}
        >
          <div
            className={`${cssStyles.triggerContainer} ${cssStyles.triggerContainerCollapsed}`}
          >
            <Popover
              open={showSearch}
              onClick={this.openSearchWithoutConfig}
              onBlur={this.closeSearch}
              popOverContent={this.renderContactSearch()}
              popOverClass={cssStyles.contactSearchPopOver}
              heightDifference={-30}
            >
              <Tippy
                className={`${commonStyles.info_tooltip}`}
                content={`Search or Start a new chat/channel (${searchShortcutString})`}
                arrow
                placement='right'
              >
                <div
                  className={`search__handle-search ${cssStyles.searchHandle}`}
                />
              </Tippy>
            </Popover>
          </div>
          <PlusWidget />
        </div>
      </div>
    );
  }

  renderContactSearch() {
    const {
      ownerGuid,
      outerLeftCollapsed,
      setContactSearchConfig,
      ContactSearchConfig,
      resetContactSearchConfig,
    } = this.props;
    return (
      <ContactSearch
        ContactSearchConfig={ContactSearchConfig}
        setContactSearchConfig={setContactSearchConfig}
        resetContactSearchConfig={resetContactSearchConfig}
        onBlur={outerLeftCollapsed ? () => {} : this.onContactSearchBlur}
        guid={ownerGuid}
        closeSearch={this.closeSearch}
      />
    );
  }

  renderView() {
    const {
      ownerGuid,
      selectedContacts,
      outerLeftCollapsed,
      ContactSearchConfig,
    } = this.props;

    if (ContactSearchConfig.open) {
      return null;
    }

    const searchShortcutString = events[SEARCH].shortcutString;
    return (
      <div className={cssStyles.wrapper} id='searchController'>
        <div
          className={`${cssStyles.searchContainer} ${cssStyles.pointer} ${
            outerLeftCollapsed ? cssStyles.containerCollapsed : ''
          }`}
        >
          <Tippy
            className={`${commonStyles.info_tooltip}`}
            content={`Search or Start a new chat/channel (${searchShortcutString})`}
            arrow
            placement='bottom'
          >
            <div
              tabIndex='-1'
              className={`${cssStyles.triggerContainer}`}
              onClick={() => this.toggleSearch()}
              onKeyDown={() => {}}
              role='button'
            >
              <div
                className={`search__handle-search ${cssStyles.searchHandle}`}
              />
              <div
                className={cssStyles.search_placeholder}
                data-pendo-hook='start-a-chat'
              >
                <ContactPills
                  ownerGuid={ownerGuid}
                  contactIds={selectedContacts}
                />
                <p>Start a chat ({searchShortcutString})</p>
              </div>
            </div>
          </Tippy>
          <div>
            <PlusWidget />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { outerLeftCollapsed, ContactSearchConfig } = this.props;

    if (outerLeftCollapsed) {
      return this.renderCollapsedView();
    }

    if (ContactSearchConfig.open) {
      return (
        <div className={`${cssStyles.contactSearchWrapper}`}>
          {this.renderContactSearch()}
        </div>
      );
    }

    return this.renderView();
  }
}

export default layoutOuterLeftCollapsed(
  currentSessionOwnerGuidHOC(
    IntlWrapper(
      selectedContactsInSearch(contactSearchConfigHOC(Search), 'ownerGuid'),
      translations
    )
  )
);
