import { getImageBlobFromCanvas } from '@/utils/canvas';

type GetImageFromFilePickerResolvedValue = {
  previewUrl: string;
  imageFile: Blob;
};

export class AvatarFileInputCancelledError extends Error {}

export default function getImageFromFilePicker(): Promise<
  GetImageFromFilePickerResolvedValue
> {
  return new Promise((resolve, reject) => {
    const fileInputEl = document.createElement('input');
    fileInputEl.type = 'file';
    fileInputEl.accept = '.png,.jpg,.jpeg,.gif,.bmp';

    fileInputEl.onchange = () => {
      const file = fileInputEl.files[0];

      if (!file) {
        reject(new AvatarFileInputCancelledError());
        return;
      }

      const fileReader = new FileReader();

      fileReader.onload = () => {
        const tempImg = new Image();
        tempImg.src = fileReader.result as string;

        tempImg.onload = () => {
          let cropSize = 640;
          let tempW = tempImg.width;
          let tempH = tempImg.height;
          let sx = 0;
          const sy = 0;

          if (tempW > tempH) {
            sx = (tempW - tempH) / 2;
            tempW = tempH;
          } else {
            tempH = tempW;
          }

          if (cropSize > tempW) {
            cropSize = tempW;
          }

          const canvas = document.createElement('canvas');
          canvas.width = cropSize;
          canvas.height = cropSize;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(
            tempImg,
            sx,
            sy,
            tempW,
            tempH,
            0,
            0,
            cropSize,
            cropSize
          );

          resolve({
            previewUrl: canvas.toDataURL('image/png'),
            imageFile: getImageBlobFromCanvas(canvas),
          });
        };
      }; // move it to function

      fileReader.readAsDataURL(file);
    };

    fileInputEl.click();
  });
}
