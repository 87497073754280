import React, { useEffect } from 'react';
import currentSessionSelector from '@/selectors/session';
import store from '@/store';
import { hideModal } from '@/components/Modal/Modal';
import { openSupportRequestPage } from '@/utils/ShellUtils';
import retryIcon from '@/assets/icon-retry-2.svg';
import checkedIcon from '@/assets/icon-checked.svg';
import warningIcon from '@/assets/icon-warning.svg';
import successIcon from '@/assets/checkmark.svg';
import { logMedusaEventWithConnectivity } from '../utils';
import { ResultObj } from '../Types';
import { getDescription } from '../TroubleShootChecks/Descriptions';
import cssStyles from './TroubleShootResult.css';

type Props = {
  result: ResultObj;
  checkAgain: () => void;
};

function TroubleShootResult({ result, checkAgain }: Props) {
  const { allTestPassed, tests } = result;

  useEffect(() => {
    if (allTestPassed) {
      logMedusaEventWithConnectivity(
        'connection_troubleshoot_success',
        currentSessionSelector(store.getState())
      );
    } else {
      logMedusaEventWithConnectivity(
        'connection_troubleshoot_failure',
        currentSessionSelector(store.getState()),
        {
          reason: tests.join(', '),
        }
      );
    }
  }, [allTestPassed, tests]);

  const retry = () => {
    checkAgain();
    logMedusaEventWithConnectivity(
      'connection_troubleshoot_retry',
      currentSessionSelector(store.getState())
    );
  };

  const contactSupport = () => {
    openSupportRequestPage();
    logMedusaEventWithConnectivity(
      'connection_troubleshoot_contact_support',
      currentSessionSelector(store.getState())
    );
  };

  return (
    <div className={cssStyles.container}>
      <h2 className={cssStyles.title}>Diagnosis report</h2>
      <div className={cssStyles.reportCard}>
        <div className={cssStyles.reportHeader}>
          <span>{allTestPassed ? 'No ' : ''}Problems found</span>
          <span
            onClick={retry}
            role='button'
            onKeyDown={() => {}}
            tabIndex={-1}
          >
            <img src={retryIcon} alt='retry' />
          </span>
        </div>
        <Reports result={result} />
        <div className={cssStyles.reportFooter}>
          <img src={checkedIcon} alt='checked' />
          Logs attached
        </div>
      </div>
      <div className={cssStyles.buttonWrapper}>
        <button className={cssStyles.cancel} type='button' onClick={hideModal}>
          Cancel
        </button>
        <button
          className={cssStyles.contactSupport}
          type='button'
          onClick={contactSupport}
        >
          Contact Support
        </button>
      </div>
    </div>
  );
}

function Reports({ result }: { result: ResultObj }) {
  const { tests, allTestPassed } = result;
  return (
    <div className={cssStyles.reports}>
      {tests.length !== 0 &&
        tests.map((testName) => {
          return allTestPassed ? (
            <div
              key={testName}
              className={`${cssStyles.problem} ${cssStyles.problemNotFound}`}
            >
              <img src={successIcon} alt='' />
              {getDescription(testName, 'success')}
            </div>
          ) : (
            <div key={testName} className={cssStyles.problem}>
              <img src={warningIcon} alt='' />
              {getDescription(testName, 'failed')}
            </div>
          );
        })}
    </div>
  );
}

export default TroubleShootResult;
