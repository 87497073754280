import React from 'react';
import classNames from 'classnames';
import styles from './Badge.css';

/**
 *
 * @param {object} props
 *
 * Takes in the following props:
 *
 * `children` - The text you wish to render within the badge.
 *
 * `className?` - Your className with your custom styles.
 *
 * `background?` - Your custom background color for the badge.
 */
export default function Badge({
  children = null,
  className = '',
  background = '',
  mask = '',
  ...props
}) {
  /* Badge with Content */
  if (children) {
    return (
      <div
        {...props}
        style={{
          background,
        }}
        className={classNames(styles.content, className)}
      >
        {children}
      </div>
    );
  }
  /* Badge without content */
  return (
    <div
      {...props}
      style={{
        background,
        mask,
      }}
      className={classNames(styles.noContent, className)}
    />
  );
}
