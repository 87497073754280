import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { ownerGuidSelector } from '@/selectors';

const recentContactsSelector = (state) =>
  state.restricted.contact.recent_contacts_meta_proxy;

const recentContactsIdsForOwnerGuidSelector = createSelector(
  ownerGuidSelector,
  recentContactsSelector,
  (ownerGuid, recentContacts) => recentContacts[ownerGuid]
);

const mapStateToProps = (state) => {
  const recentContactIds = recentContactsIdsForOwnerGuidSelector(state) || [];
  return {
    recentContactIds,
  };
};

export default function (Component) {
  return connect(mapStateToProps, null)(Component);
}
