/* eslint-disable import/prefer-default-export, prefer-destructuring, no-prototype-builtins */

import { USER, CHANNEL, MENTION_TYPES } from '../../constants';

export const Mentions = (froalaEditor) => {
  const FE =
    froalaEditor && froalaEditor.hasOwnProperty('default')
      ? froalaEditor.default
      : froalaEditor;

  FE.MODULES.Mentions = (editor) => {
    function _init() {
      editor.events.on('keydown', setProperFormattingForMentions, true);
      editor.events.on('contentChanged', updateMentions);
      editor.events.on('customContentChanged', updateMentions);
    }

    // Will ensure character being typed at the end of mention goes outside of mention
    function setProperFormattingForMentions(event: KeyboardEvent) {
      if (!editor.keys.isCharacter(event.keyCode)) return;
      if (editor.format.is(USER)) {
        editor.format.remove(USER);
      } else if (editor.format.is(CHANNEL)) {
        editor.format.remove(CHANNEL);
      }
    }

    function updateMentions() {
      const mentions: Element[] = Array.from(
        editor.el.querySelectorAll(MENTION_TYPES.join(','))
      );
      const alreadyMentioned = {};
      try {
        mentions.forEach((mention) => {
          const originalValue = mention.getAttribute('data-value');
          const innerText = mention.textContent;
          // Any other child element exists then remove the mention
          if (mention.firstElementChild) {
            removeMention(mention);
          }
          // if extra content is added at the end of mentions then move that extra content outside mention
          if (
            innerText.length > originalValue.length &&
            innerText.indexOf(originalValue) === 0
          ) {
            moveExtraContentAside(mention, originalValue);
          }
          // if mention has been modified then remove mention tag and convert to plain text only
          else if (
            originalValue !== innerText ||
            alreadyMentioned[originalValue]
          ) {
            removeMention(mention);
          } else {
            alreadyMentioned[originalValue] = true;
          }
        });
      } catch {
        //
      }
    }

    // fallback for "setProperFormattingForMentions" method
    function moveExtraContentAside(mention: Element, originalValue: string) {
      const innerText = mention.textContent;
      const div = document.createElement('div');
      div.innerHTML = mention.outerHTML;
      div.firstChild.textContent = originalValue;
      mention.outerHTML = `${div.innerHTML}${innerText.substr(
        originalValue.length
      )}${editor.FROALA.MARKERS}`;
      editor.selection.restore();
    }

    function removeMention(mention: Element) {
      editor.selection.save();
      mention.outerHTML = mention.innerHTML;
      editor.selection.restore();
    }
    return {
      _init,
    };
  };
};
