export default {
  user_status_available: 'Disponible',
  user_status_busy: 'Ocupado',
  user_status_away_from_desk: 'Lejos del escritorio',
  user_status_working_from_home: 'Trabajando desde casa',
  user_status_on_leave: 'De permiso',
  update: 'Actualizar',
  updated: 'Actualizado',
  error_occurred:
    'Ocurri\u00f3 un error. Por favor, int\u00e9ntelo de nuevo m\u00e1s tarde.',
  oops: 'Ups!',
  okay: 'OK',
};
