import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Tippy from '@tippyjs/react';
import memoize from 'memoize-one';

import bridge from '@/utils/bridge';
import appsListSubscriber from '@/subscribeHOCs/appList';
import { doAppAction, doTwillioAppAction } from '@/utils/ShellUtils';
import { addQueryParamtoHTTPUrls } from '@/utils';
import {
  currentSessionSelector,
  currentCallProviderSelector,
} from '@/selectors';
import commonStyles from '@/components/common/common.css';

import VideoConferenceOptions from '../VideoConferenceOptions';
import ActionButtonCss from '../ActionButtons.css';

const rootCategory = 'rightCornerApps';
const orgStandardRootCategory = 'chatTabButton';
const gaSource = 'chat_tab_button';

const getSortedAppList = memoize((appList) =>
  appList.sort(function (a, b) {
    const aProiority = a?.properties?.priorities?.rightCornerApps || 0;
    const bProiority = b?.properties?.priorities?.rightCornerApps || 0;

    return bProiority - aProiority;
  })
);

class DikeActionButtons extends PureComponent {
  static defaultProps = {
    peer: undefined,
    currentSession: undefined,
    appList: [],
    isVideoRecordingEnabled: false,
  };

  state = {
    twilioId: undefined,
    appearId: undefined,
  };

  constructor(props) {
    super(props);

    bridge
      .ask('HydraServer', 'getStartupOption', ['twilioAppId'])
      .then((id) => {
        this.twilioAppId = id;
      });
  }

  async componentDidMount() {
    const appIdPromises = [
      bridge.ask('HydraServer', 'getStartupOption', ['twilioAppId']),
      bridge.ask('HydraServer', 'getStartupOption', ['appearAppId']),
    ];

    const [twilioId, appearId] = await Promise.all(appIdPromises);

    this.setState({
      twilioId,
      appearId,
    });
  }

  getEnabledVideoAppId = () => {
    const { twilioId, appearId } = this.state;
    const { callProvider } = this.props;
    if (!twilioId || !appearId) {
      return undefined;
    }

    if (callProvider === 'twilio') {
      return twilioId;
    }
    return appearId;
  };

  onAppClick = (app) => {
    const { currentSession, peer } = this.props;
    if (app.id === this.twilioAppId) {
      doTwillioAppAction(currentSession.id, peer);
    } else {
      doAppAction(
        app[rootCategory].action,
        app,
        peer,
        currentSession.id,
        {
          name: 'client.pressButton',
          button: orgStandardRootCategory,
        },
        gaSource
      );
    }
  };

  render() {
    const {
      appList,
      isVideoRecordingEnabled,
      currentSession,
      peer,
    } = this.props;

    const enabledVideoAppId = this.getEnabledVideoAppId();

    return getSortedAppList(appList).map((app) => {
      const style = {
        WebkitMask: `url(${addQueryParamtoHTTPUrls(
          app.inClientIcon
        )}) 0px 0px / cover no-repeat`,
      };
      const tooltip = app[rootCategory].description;
      if (enabledVideoAppId === app.id) {
        return (
          <VideoConferenceOptions
            key={app.id}
            appId={app.id}
            tooltip={tooltip}
            style={style}
            className={ActionButtonCss.Item}
            currentSession={currentSession}
            peer={peer}
            isVideoRecordingEnabled={isVideoRecordingEnabled}
          />
        );
      }
      return (
        <Tippy
          maxWidth={200}
          content={tooltip}
          arrow
          key={app.id}
          className={commonStyles.info_tooltip}
        >
          <span
            className={ActionButtonCss.Item}
            style={style}
            onClick={() => this.onAppClick(app)}
            onKeyPress={() => {}}
            tabIndex={-1}
            role='button'
            data-chat-header-app-id={app.id}
          />
        </Tippy>
      );
    });
  }
}

const mapStateToProps = createStructuredSelector({
  currentSession: currentSessionSelector,
  callProvider: currentCallProviderSelector,
});

export default connect(mapStateToProps)(
  appsListSubscriber(DikeActionButtons, rootCategory)
);
