import React from 'react';
import { connect } from 'react-redux';
import bridge from '@/utils/bridge';
import { Actions } from '@/actions/app';

export default function withStickers(Component) {
  class WithStickers extends React.Component {
    async componentDidMount() {
      const { stickers, dispatch } = this.props;

      try {
        if (stickers.length === 0) {
          const {
            data: { sets: categories = [] },
          } = await bridge.ask('MediaContentTracker', 'getStickerContent');

          if (!this.cancelled && categories.length && stickers.length === 0) {
            const stickersPayload = { categories: [] };

            // eslint-disable-next-line no-restricted-syntax
            for (const [categoryIndex, category] of Object.entries(
              categories
            )) {
              const categoryItem = {
                id: category.id,
                name: category.name,
                order: categoryIndex,
                image: category['icon-url'].replace(/^https/, 'http'),
                stickers: [],
              };

              // eslint-disable-next-line no-restricted-syntax
              for (const stickerItem of category.items) {
                categoryItem.stickers.push({
                  id: `${category.id}_${stickerItem.id}`,
                  name: stickerItem.name,
                  thumbnail: stickerItem['thumb-source'],
                  type: stickerItem.type,
                  image: stickerItem.source,
                  categoryId: category.id,
                  categoryName: category.name,
                });
              }

              stickersPayload.categories.push(categoryItem);
            }

            dispatch(Actions.set_stickers(stickersPayload));
          }
        }
      } catch (error) {
        console.error('Error fetching sticker data:', error);
      }
    }

    componentWillUnmount() {
      this.cancelled = true;
    }

    render() {
      return <Component {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return {
      stickers: state.app.stickers.categories,
    };
  }

  return connect(mapStateToProps)(WithStickers);
}
