const defaultState = {
  categories: [],
};

export default (ActionTypes) => {
  return (state = defaultState, action) => {
    if (action.type === ActionTypes.SET_STICKERS) {
      return {
        categories: action.payload.categories,
      };
    }

    return state;
  };
};
