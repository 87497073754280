import { DecoratorFragments } from '../Types';

function getCommandFragments(
  textContent: string,
  selectionStart: number,
  selectionEnd: number,
  ignoreCaret?: boolean
) {
  const content = ignoreCaret ? textContent.trim() : textContent;

  const caretBeg = ignoreCaret ? content.length : selectionStart;
  const caretEnd = ignoreCaret ? content.length : selectionEnd;

  const textBeforeCursor = content.slice(0, caretBeg);
  const textAfterCursor = content.slice(caretBeg);

  const prefixMatch = /^\/[^ ]*$/.exec(textBeforeCursor);
  const prefix = ((prefixMatch && prefixMatch[0]) || '').trim();

  const suffixMatch = /^([^ \n\r]*)([^]*)/.exec(textAfterCursor);
  const suffix = (suffixMatch && suffixMatch[1]) || '';

  const textAfterSuffix = (suffixMatch && suffixMatch[2]) || '';

  return {
    caretBeg,
    caretEnd,
    textBeforeCursor,
    textAfterCursor,
    textAfterSuffix,
    prefix,
    suffix,
  };
}

function getParamFragments(
  textContent: string,
  selectionStart: number,
  selectionEnd: number,
  paramRegex: RegExp,
  ignoreCaret?: boolean
): DecoratorFragments {
  const commandFragments = getCommandFragments(
    textContent,
    selectionStart,
    selectionEnd,
    ignoreCaret
  );
  const paramPrefixMatch = paramRegex.exec(commandFragments.textBeforeCursor);

  const textBeforeParam = (paramPrefixMatch && paramPrefixMatch[1]) || '';
  const paramPrefix = (paramPrefixMatch && paramPrefixMatch[2]) || '';

  return {
    ...commandFragments,
    paramPrefix,
    textBeforeParam,
  };
}

function getMultiParamFragments(
  textContent: string,
  selectionStart: number,
  selectionEnd: number,
  ignoreCaret?: boolean
): DecoratorFragments {
  return getParamFragments(
    textContent,
    selectionStart,
    selectionEnd,
    /^([^]*)\s+(@[^ ]*)$/,
    ignoreCaret
  );
}
function getSingleParamFragments(
  textContent: string,
  selectionStart: number,
  selectionEnd: number,
  ignoreCaret?: boolean
): DecoratorFragments {
  return getParamFragments(
    textContent,
    selectionStart,
    selectionEnd,
    /^(\/[^ ]*)\s+(@[^ ]*)$/,
    ignoreCaret
  );
}
function getEmojiFragments(
  textContent: string,
  selectionStart: number,
  selectionEnd: number
): DecoratorFragments {
  return getParamFragments(
    textContent,
    selectionStart,
    selectionEnd,
    /^((?:[^]*\s)|)(:[a-zA-Z+-][^ ]*)$/,
    false
  );
}
function getChannelMentionFragments(
  textContent: string,
  selectionStart: number,
  selectionEnd: number
): DecoratorFragments {
  return getParamFragments(
    textContent,
    selectionStart,
    selectionEnd,
    /^((?:[^]*\s)|)(#[^ ]*)$/,
    false
  );
}

function getBuddyMentionsFragments(
  textContent: string,
  selectionStart: number,
  selectionEnd: number
): DecoratorFragments {
  return getParamFragments(
    textContent,
    selectionStart,
    selectionEnd,
    /^((?:[^]*\s)|)(@[^ ]*)$/
  );
}

export {
  getCommandFragments,
  getMultiParamFragments,
  getSingleParamFragments,
  getEmojiFragments,
  getChannelMentionFragments,
  getBuddyMentionsFragments,
};
