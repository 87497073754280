/**
 * Reducers output the Redux state (models) for the app. They do not alter the
 * existing state, but generate modified state objects from the old state in
 * response to actions.
 *
 * http://redux.js.org/docs/basics/Reducers.html
 *
 * */
import { combineReducers } from 'redux';
import { ActionTypes } from '../../actions/app';
import languageReducerGenerator from './language';
import themeReducerGenerator from './theme';
import ContactSearchConfigReducerGenerator from './ContactSearchConfig';
import stickersReducerGenerator from './stickers';
import emojiReducerGenerator from './emoji';

/*
 * Reducer aggregator
 */
export default combineReducers({
  /*
   * Populate reducers
   */
  preferredLanguage: languageReducerGenerator(ActionTypes),
  theme: themeReducerGenerator(ActionTypes),
  ContactSearchConfig: ContactSearchConfigReducerGenerator(ActionTypes),
  stickers: stickersReducerGenerator(ActionTypes),
  emoji: emojiReducerGenerator(ActionTypes),
});
