import { connect } from 'react-redux';
import { Actions } from '../actions/app';

function mapStateToProps(state) {
  const { ContactSearchConfig } = state.app;
  return {
    ContactSearchConfig,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setContactSearchConfig(config) {
      dispatch(Actions.set_contact_search_config(config));
    },
    resetContactSearchConfig() {
      dispatch(Actions.reset_contact_search_config());
    },
  };
}

export default function (component) {
  return connect(mapStateToProps, mapDispatchToProps)(component);
}
