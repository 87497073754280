

// requestAnimationFrame() polyfills for React 16
require('raf/polyfill');
require("regenerator-runtime/runtime");

if (typeof Promise === 'undefined') {
  // Rejection tracking prevents a common issue where React gets into an
  // inconsistent state due to an error, but it gets swallowed by a Promise,
  // and the user has no idea what causes React's erratic future behavior.
  require('promise/lib/rejection-tracking').enable();
  window.Promise = require('promise/lib/es6-extensions.js');
}

if (typeof window.ResizeObserver === 'undefined') {
  const { default: ResizeObserver } = require('resize-observer-polyfill');
  window.ResizeObserver = ResizeObserver;
}

require('intersection-observer');

// fetch() polyfill for making API calls.
require('whatwg-fetch');

// Object.assign() is commonly used with React.
// It will use the native implementation if it's present and isn't buggy.
Object.assign = require('object-assign');

const supportsElementScroll =
  'scrollTo' in Element.prototype;

if (!supportsElementScroll ) {
  require('element-scroll-polyfill');
}
