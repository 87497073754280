import pick from 'lodash/pick';
import bridge from '@/utils/bridge';
import {
  getConversationIdentity,
  sendMessage,
} from '@/utils/ConversationUtils';
import { getMsgText } from '@/utils/message';
import {
  deleteMessageModalDialog,
  showUnableToDeleteModalDialog,
} from '@/utils/GenericModalUtils';
import { checkPrivilegeToDelete } from '@/utils/teamPrivileges';
import iconCopy from '@/assets/icon-copy.svg';
import iconReply from '@/assets/icon-reply.svg';
import iconEdit2 from '@/assets/icon-edit2.svg';
import iconDelete from '@/assets/icon-delete.svg';
import iconRetry from '@/assets/icon-retry.svg';
import getTranslatedString from '@/utils/getTranslatedString';
import i18n from './I18N';

const LocalMenuItems = [
  {
    id: 'copyIcon',
    labelName: 'Copy',
    labelDesc: getTranslatedString(
      'editor_messageDropdown_menu_text_copy',
      i18n
    ),
    iconClass: '',
    icon: iconCopy,
    action: (msg) => {
      bridge.tell('HydraServer', 'copyToClipboard', [getMsgText(msg)]);
    },
    validator: (msg) => {
      return (msg.text && msg.text.trim()) || msg.flockml;
    },
    properties: {
      priorities: {
        messageAction: 550,
      },
    },
  },
  {
    id: 'replyIcon',
    labelName: 'Reply',
    labelDesc: getTranslatedString(
      'editor_messageDropdown_menu_text_reply',
      i18n
    ),
    iconClass: '',
    icon: iconReply,
    action: (msg) => {
      bridge.publish('/onMessageReply', [msg]);
    },
    validator: (msg) => {
      return msg.sid;
    },
    properties: {
      priorities: {
        messageAction: 2500,
      },
    },
  },
  {
    id: 'editIcon',
    labelName: 'Edit',
    labelDesc: getTranslatedString(
      'editor_messageDropdown_menu_text_edit',
      i18n
    ),
    iconClass: '',
    icon: iconEdit2,
    action: (msg) => {
      bridge.publish(`/editMessage/${msg.id}`, [msg]);
    },
    validator: (msg) => {
      return (
        (msg.text || msg.flockml) &&
        !msg.sentAsApp &&
        msg.sentByOwner &&
        msg.sid
      );
    },
    properties: {
      priorities: {
        messageAction: 600,
      },
    },
  },
  {
    id: 'deleteIcon',
    labelName: 'Delete',
    labelDesc: getTranslatedString(
      'editor_messageDropdown_popup_buttonText_delete',
      i18n
    ),
    iconClass: '',
    icon: iconDelete,
    action: (msg, session) => {
      if (session.state === 'ACTIVE' || msg.receiptState === 'failed') {
        const { peer } = msg;
        const conversationId = `Conversation_${getConversationIdentity(peer)}`;
        deleteMessageModalDialog(
          conversationId,
          msg,
          peer.isGroup,
          peer.isBot,
          peer.chatName
        );
      } else {
        showUnableToDeleteModalDialog('editor_not_connected_to_internet_error');
      }
    },
    validator: (msg, currentSession, currentPeer, config) => {
      if (!msg.peer.isGroup) return msg.sentByOwner;
      return checkPrivilegeToDelete(
        msg,
        currentPeer,
        currentSession.owner.isAdmin,
        config
      );
    },
    properties: {
      priorities: {
        messageAction: 500,
      },
    },
  },
  {
    id: 'retryIcon',
    labelName: 'Retry',
    labelDesc: getTranslatedString(
      'editor_messageDropdown_menu_text_retry',
      i18n
    ),
    iconClass: '',
    icon: iconRetry,
    action: (msg) => {
      bridge.tell('MessageCache', 'deleteMessageByMCId', [
        msg.message_cache_id,
      ]);
      sendMessage(
        pick(msg, [
          'text',
          'flockml',
          'peer',
          'id',
          'attachments',
          'notification',
          'replyOf',
          'forwardOf',
        ])
      );
    },
    validator: (msg) => {
      return msg.receiptState === 'failed';
    },
    properties: {
      priorities: {
        messageAction: 650,
      },
    },
  },
];

export default LocalMenuItems;
