import React, { PureComponent } from 'react';
import _isEmpty from 'lodash/isEmpty';
import { combineEvents } from '@/utils';
import noop from '@/utils/noop';
import { decoratorTokenLookUp } from '../TokenUtils/index';
import ChannelMentionDecoratorHelper from './ChannelMentionDecoratorHelper';
import BuddyMentionDecoratorHelper from './BuddyMentionDecoratorHelper';
import SlashDecoratorHelper from './SlashDecoratorHelper';
import EmojiDecorator from './EmojiDecorator';
import { Owner, Peer, DecoratorToken, InsertTextFun } from '../Types';

type Props = {
  textContent: string;
  selectionStart: number;
  selectionEnd: number;
  isEditorFocused: boolean;
  allowedDecorators: string[];
  insertText: InsertTextFun;
  owner: Owner;
  peer: Peer;
  children: any;
} & typeof MessageDecorator.defaultProps;

type State = {
  decoratorToken: DecoratorToken;
  visible: boolean;
};

class MessageDecorator extends PureComponent<Props, State> {
  static defaultProps = {
    textContent: '',
    selectionStart: undefined,
    selectionEnd: undefined,
    isEditorFocused: true,
    allowedDecorators: [],
    insertText: noop as InsertTextFun,
    owner: undefined,
    peer: undefined,
    children: null,
  };

  state = {
    decoratorToken: undefined,
    visible: true,
  } as State;

  componentDidMount() {
    this.computeDecoratorToken();
  }

  componentDidUpdate(prevProps: Props) {
    const {
      textContent: prevTextContent,
      selectionStart: prevSS,
      selectionEnd: prevSE,
    } = prevProps;
    const { textContent, selectionStart, selectionEnd } = this.props;
    if (
      textContent !== prevTextContent ||
      selectionStart !== prevSS ||
      selectionEnd !== prevSE
    ) {
      this.setState({ visible: true });
      this.computeDecoratorToken();
    }
  }

  onTextAreaEnter = () => {
    return true;
  };

  getDecoratorTokenFromText() {
    const { textContent, selectionStart, selectionEnd } = this.props;

    return decoratorTokenLookUp(textContent, selectionStart, selectionEnd);
  }

  computeDecoratorToken = () => {
    const decoratorToken = this.getDecoratorTokenFromText();
    this.setState({
      decoratorToken,
    });
  };

  renderDecoratorHelper() {
    const {
      textContent,
      peer,
      owner,
      allowedDecorators,
      insertText,
    } = this.props;
    // eslint-disable-next-line react/destructuring-assignment
    if (!this.state.visible) {
      return this.renderChildren();
    }

    const { decoratorToken } = this.state;
    const { tokenType: type } = decoratorToken;

    if (
      type === 'CHANNEL_MENTION' &&
      allowedDecorators.includes('CHANNEL_MENTION')
    ) {
      return (
        <ChannelMentionDecoratorHelper
          command={decoratorToken}
          textContent={textContent}
          insertText={insertText}
          peer={peer}
        >
          {this.renderChildren}
        </ChannelMentionDecoratorHelper>
      );
    }

    if (
      type === 'BUDDY_MENTION' &&
      allowedDecorators.includes('BUDDY_MENTION')
    ) {
      return (
        <BuddyMentionDecoratorHelper
          command={decoratorToken}
          textContent={textContent}
          insertText={insertText}
          peer={peer}
          owner={owner}
        >
          {this.renderChildren}
        </BuddyMentionDecoratorHelper>
      );
    }

    if (type === 'SLASH' && allowedDecorators.includes('SLASH')) {
      const { selectionStart, selectionEnd } = this.props;
      return (
        <SlashDecoratorHelper
          owner={owner}
          peer={peer}
          command={decoratorToken}
          selectionStart={selectionStart}
          selectionEnd={selectionEnd}
          textContent={textContent}
          insertText={insertText}
        >
          {this.renderChildren}
        </SlashDecoratorHelper>
      );
    }

    if (type === 'EMOJI' && allowedDecorators.includes('EMOJI')) {
      const { fragments } = decoratorToken;
      const { selectionStart, selectionEnd } = this.props;

      return (
        <EmojiDecorator
          fragments={fragments}
          textContent={textContent}
          selectionStart={selectionStart}
          selectionEnd={selectionEnd}
          insertText={insertText}
        >
          {this.renderChildren}
        </EmojiDecorator>
      );
    }

    return this.renderChildren();
  }

  onEscape = (event) => {
    // We have a global ESC listener which handles closing of open conversations
    // at the moment. We don't want that to be triggered when we are attempting
    // to close open decorators. Hence, we need the following.
    event.stopPropagation();

    this.setState({
      visible: false,
    });
  };

  renderChildren = (decoratorProps: any = {}) => {
    const { children } = this.props;

    if ('onEscape' in decoratorProps) {
      return children({
        ...decoratorProps,
        onEscape: combineEvents([decoratorProps.onEscape, this.onEscape]),
      });
    }

    if (!_isEmpty(decoratorProps)) {
      return children({
        ...decoratorProps,
        onEscape: this.onEscape,
      });
    }

    return children(decoratorProps);
  };

  render() {
    const { decoratorToken } = this.state;
    const { isEditorFocused } = this.props;

    return decoratorToken && isEditorFocused
      ? this.renderDecoratorHelper()
      : this.renderChildren();
  }
}

export default MessageDecorator;
