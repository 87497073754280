import { createSelector } from 'reselect';
import { maybe } from '@/utils';
import currentSessionSelector from './session';
import currentSessionIdSelector from './currentSessionId';

export default createSelector(
  currentSessionSelector,
  currentSessionIdSelector,
  (currentSession, sessionId) => {
    return {
      teamPlan: maybe(currentSession, 'sessionInfo', 'teamPlan'),
      sessionId,
      isAdmin: maybe(currentSession, 'sessionInfo', 'teamInfo', 'isAdmin'),
    };
  }
);
