import React from 'react';
import cn from 'classnames';
import contactHOC from '@/subscribeHOCs/contact';
import { sanitizeChannelName } from '@/utils/ContactUtils';
import { joinAndOpenChannel, openUrl } from '@/utils/SessionUtils';
import EmojiDecorator from '@/components/common/EmojiDecorator';
import cssStyles from './Message.css';

const styleMap = {
  color: 'color',
  'text-decoration-line': 'textDecorationLine',
};

export function getStyles(element) {
  const styles = element.getAttribute('style');
  if (styles) {
    return styles.split(';').reduce((styleObj, styleProp) => {
      const propParts = styleProp.trim().split(':');
      const prop = propParts[0] && propParts[0].trim();
      const value = propParts[1] && propParts[1].trim();
      const propName = prop && styleMap[prop];
      if (propName && value) {
        return {
          ...styleObj,
          [propName]: value,
        };
      }
      return styleObj;
    }, {});
  }
  return {};
}

export function LinkElement({ onClick, style, children, href }) {
  return (
    <a
      style={style}
      className={cssStyles.link}
      href={href}
      target='_blank'
      rel='noopener noreferrer'
      onClick={(e) => {
        e.preventDefault();
        if (href) openUrl(href);
        if (onClick) onClick();
        return false;
      }}
    >
      {children}
    </a>
  );
}

export const ChannelLink = contactHOC(function ({
  element,
  currentSessionId,
  peerType,
  contact,
}) {
  const channelId = element.getAttribute('channelId');
  return (
    <LinkElement
      onClick={() => {
        joinAndOpenChannel(currentSessionId, channelId, peerType);
      }}
      style={getStyles(element)}
    >
      {contact ? `#${sanitizeChannelName(contact.name)}` : element.textContent}
    </LinkElement>
  );
});

export const GroupMention = function ({ textContent, isMeMention }) {
  const className = cn(cssStyles.Mention, {
    [cssStyles.MeMention]: isMeMention,
  });
  return (
    <span className={className}>
      <EmojiDecorator text={textContent} />
    </span>
  );
};

export const UserMention = contactHOC(function ({ contact = {}, ownerGuid }) {
  const isMeMention = contact.jid === `${ownerGuid}@go.to`;
  const className = cn(cssStyles.Mention, {
    [cssStyles.MeMention]: isMeMention,
  });
  const { chatName } = contact;
  const mentionedChatName = `@${chatName}`;
  return (
    <span className={className}>
      <EmojiDecorator text={mentionedChatName} />
    </span>
  );
});
