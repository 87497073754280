import bridge from '@/utils/bridge';
import { getAllBuddies } from '@/utils/ContactUtils';
import store from '@/store';
import ContactProxy from '@/Proxies/ContactProxy';
import { Actions } from '@/actions/restricted';
import { getCounter } from './utilities';

class AllBuddiesProxy {
  counter = getCounter();

  contactProxySubscriptions = {};

  subscribedIds = {};

  constructor() {
    bridge.subscribe('/contact/new/buddy', this.onBuddyAdded);
    bridge.subscribe('/contact/delete/buddy', this.onBuddyDeleted);
  }

  getCurrentState = () => {
    const state = store.getState();
    const allBuddiesContacts = state.restricted.contact.all_buddies_meta_proxy;
    if (!allBuddiesContacts) {
      return {};
    }

    return allBuddiesContacts;
  };

  setAllBuddiesInStore(ownerGuid, contactIds) {
    store.dispatch(Actions.all_buddies_meta_proxy({ [ownerGuid]: contactIds }));
    this.contactProxySubscriptions[ownerGuid].update(ownerGuid, contactIds);
  }

  onBuddyAdded = (contact) => {
    const allBuddiesContacts = this.getCurrentState();
    const { ownerGuid } = contact;
    if (!allBuddiesContacts[ownerGuid]) {
      return;
    }
    const buddiesSet = new Set([...allBuddiesContacts[ownerGuid]]);
    buddiesSet.add(contact.jid);
    this.setAllBuddiesInStore(ownerGuid, Array.from(buddiesSet));
  };

  onBuddyDeleted = (contact) => {
    const allBuddiesContacts = this.getCurrentState();
    const { ownerGuid } = contact;
    if (!allBuddiesContacts[ownerGuid]) {
      return;
    }
    const buddiesSet = new Set([...allBuddiesContacts[ownerGuid]]);
    buddiesSet.delete(contact.jid);
    this.setAllBuddiesInStore(ownerGuid, Array.from(buddiesSet));
  };

  subscribe(ownerGuid) {
    const id = this.counter.next().value;

    if (!this.subscribedIds[ownerGuid]) {
      this.subscribedIds[ownerGuid] = [];
    }
    if (this.subscribedIds[ownerGuid].length === 0) {
      getAllBuddies(ownerGuid).then((contacts) => {
        const contactsSet = new Set();
        for (let i = 0; i < contacts.length; i += 1) {
          contactsSet.add(contacts[i].jid);
        }
        this.setAllBuddiesInStore(ownerGuid, Array.from(contactsSet));
      });
    }
    this.subscribedIds[ownerGuid].push(id);

    if (!this.contactProxySubscriptions[ownerGuid]) {
      this.contactProxySubscriptions[ownerGuid] = ContactProxy.subscribe(
        ownerGuid,
        []
      );
    }

    return { unsubscribe: this.unsubscribe.bind(this, ownerGuid, id) };
  }

  unsubscribe(ownerGuid, id) {
    if (!ownerGuid || !id) {
      return;
    }
    const subscriptions = this.subscribedIds[ownerGuid];
    if (subscriptions.length > 0) {
      const idx = subscriptions.indexOf(id);
      subscriptions.splice(idx, 1);
    }
    if (subscriptions.length < 1) {
      this.setAllBuddiesInStore(ownerGuid, []);
      this.contactProxySubscriptions[ownerGuid].unsubscribe();
    }
  }
}

export default new AllBuddiesProxy();
