import bridge from '@/utils/bridge';
import store from '@/store';
import { Actions } from '@/actions/restricted';
import { getDelta, getSubscriptionUpdates, getCounter } from './utilities';

// TODO: Move this to appropriate file
function getMessage(mcid) {
  return bridge.ask('MessageCache', 'getMessageByMCId', [mcid]);
}

function getMessages(ids) {
  return Promise.all(ids.map((id) => getMessage(id)));
}

function dispatchUpdate(addedMessages, removedIds, ownerGuid) {
  store.dispatch(
    Actions.update_all_messages({
      addedMessages: addedMessages.filter((c) => !!c),
      removedIds,
      ownerGuid,
    })
  );
}

async function updateState(ownerGuid, addedIds, removedIds, didChange) {
  if (didChange) {
    const addedMessages = await getMessages(addedIds);
    dispatchUpdate(
      addedMessages.filter((c) => !!c),
      removedIds,
      ownerGuid
    );
  }
}

class MessageProxy {
  // message_cache_id which are to be subscribed from hydra.
  subscribedIds = {};

  // message_cache_id per contactProxy subscription
  subSets = {};

  // ownerGuids for each subscription
  subscribedGuids = {};

  counter = getCounter();

  constructor() {
    bridge.subscribe('/pw/messagecache/messageupdate', this.onUpdateMessage);
    bridge.subscribe('/pw/messagecache/deletedMessage', this.onDeleteMessage);
  }

  getSubSets(ownerGuid) {
    if (!this.subSets[ownerGuid]) {
      this.subSets[ownerGuid] = {};
    }

    return this.subSets[ownerGuid];
  }

  setSubSets(ownerGuid, setId, value) {
    const subSet = this.getSubSets(ownerGuid);
    subSet[setId] = value;
  }

  getSubscribedIds(ownerGuid) {
    if (!this.subscribedIds[ownerGuid]) {
      this.subscribedIds[ownerGuid] = {};
    }
    return this.subscribedIds[ownerGuid];
  }

  setSubscribedIds(ownerGuid, value) {
    this.subscribedIds[ownerGuid] = value;
  }

  subscribe(ownerGuid, Ids) {
    const setId = this._subscribe(ownerGuid, Ids);
    return {
      unsubscribe: this.unsubscribe.bind(this, setId),
      update: (_ownerGuid, _Ids) => this._subscribe(_ownerGuid, _Ids, setId),
    };
  }

  _subscribe(ownerGuid, Ids, setId = `${this.counter.next().value}`) {
    if (
      this.subscribedGuids[setId] &&
      this.subscribedGuids[setId] !== ownerGuid
    ) {
      this.unsubscribe(setId);
    }
    this.subscribedGuids[setId] = ownerGuid;
    if (ownerGuid) {
      const subscribedIds = this.getSubscribedIds(ownerGuid);
      const subSets = this.getSubSets(ownerGuid);

      const delta = getDelta(subSets[setId] || [], Ids || []);
      const {
        addedIds,
        removedIds,
        subscribedIds: _subscribedIds,
        didChange,
      } = getSubscriptionUpdates(subscribedIds, delta);

      this.setSubSets(ownerGuid, setId, [...Ids]);
      this.setSubscribedIds(ownerGuid, _subscribedIds);

      updateState(ownerGuid, addedIds, removedIds, didChange);
    }
    return setId;
  }

  unsubscribe(setId) {
    if (!setId) {
      return false;
    }

    const ownerGuid = this.subscribedGuids[setId];
    if (!ownerGuid) {
      return false;
    }

    const subscribedIds = this.getSubscribedIds(ownerGuid);
    const subSets = this.getSubSets(ownerGuid);

    const delta = getDelta(subSets[setId], []);
    const {
      addedIds,
      removedIds,
      subscribedIds: _subscribedIds,
      didChange,
    } = getSubscriptionUpdates(subscribedIds, delta);

    this.setSubSets(ownerGuid, setId, false);
    this.setSubscribedIds(ownerGuid, _subscribedIds);

    updateState(ownerGuid, addedIds, removedIds, didChange);
    return true;
  }

  _hasMessage = (msg) => {
    const { ownerGuid, message_cache_id: mcId } = msg;
    if (ownerGuid) {
      const subscridedIds = this.getSubscribedIds(ownerGuid);
      if (subscridedIds[mcId]) {
        return true;
      }
    }
    return false;
  };

  onUpdateMessage = (msg) => {
    const { ownerGuid } = msg;
    if (this._hasMessage(msg)) {
      dispatchUpdate([msg], [], ownerGuid);
    }
  };

  onDeleteMessage = (msg) => {
    const { ownerGuid, message_cache_id: messageCacheId } = msg;
    if (this._hasMessage(msg)) {
      dispatchUpdate([], [messageCacheId], ownerGuid);
    }
  };
}

export default new MessageProxy();
