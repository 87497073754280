import React from 'react';
import Popover from '@/components/common/Popover';
import Container from './Container';
import CSSStyles from './MessageActions.css';

// TODO: transition of Container

class MessageActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popOverOpen: false,
      subMenuOpen: false,
    };
  }

  openPopOver = () => {
    this.setState({
      popOverOpen: true,
    });
  };

  closePopOver = () => {
    const { subMenuOpen } = this.state;
    if (!subMenuOpen) {
      this.setState({
        popOverOpen: false,
      });
    }
  };

  setSubMenuOpen = (subMenuOpen) => {
    this.setState({
      subMenuOpen,
    });
  };

  closeBothPopover = () => {
    this.setState({
      popOverOpen: false,
      subMenuOpen: false,
    });
  };

  render() {
    const { popOverOpen } = this.state;
    const { children } = this.props;
    const { message } = this.props;
    const popOverContent = (
      <Container
        setSubMenuOpen={this.setSubMenuOpen}
        closeBothPopover={this.closeBothPopover}
        message={message}
      />
    );

    return (
      <Popover
        popOverContent={popOverContent}
        open={popOverOpen}
        onMouseEnter={this.openPopOver}
        onMouseLeave={this.closePopOver}
        renderOnBody={false}
        position='top-right'
        popOverClass={CSSStyles.popOverClass}
        heightDifference={0}
        widthDifference={-115}
        focusOnRender={false}
      >
        {children}
      </Popover>
    );
  }
}

export default MessageActions;
