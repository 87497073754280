import React, { useRef, useEffect, useCallback } from 'react';
import isEqual from 'lodash/isEqual';
import { justDate, niceString } from '@/utils/datetime';
import {
  canUseAsUnreadMarker,
  isMessageUnreadByMe,
} from '@/utils/ReceiptUtils';
import { logMedusaGenericEvent } from '@/utils/logAnalytics';
import SameDateMessages from './SameDateMessages';
import UnreadMarker from './UnreadMarker';
import cssStyles from './MessageList.css';

const MEDUSA_REPORT_INTERVAL = 10 * 60 * 1000; // 10 Minutes

type Props = {
  currentSession: Session;
  currentPeer: Contact;
  ownerGuid: string;
  receiptObj: any;
  messages: Message[];
  messagesForPeerJid: string;
  onMessageIntersection: () => void;
  hasFuture: boolean;
  showUnreadMarker: boolean;
};

const MessageRenderer = function MessageRenderer({
  currentSession,
  currentPeer,
  ownerGuid,
  receiptObj,
  messages,
  messagesForPeerJid,
  onMessageIntersection,
  showUnreadMarker,
}: Props) {
  const marker = useRef(undefined);
  const lastReportTime = useRef(0);
  const currentPeerJid = currentPeer?.jid;

  /**
   * Unread marker rendering process:
   *
   * For chat with unread messages, render marker render above the first unread message.
   * For chats with no unread msgs, ignore.
   *
   * But if a user sends a message, then we shouldn't render at all. i.e we set unread
   * marker as -1.
   *
   * i.e
   * -1 => Marker will not render anymore.
   *
   * undefined => look for an unread marker.
   */
  useEffect(() => {
    marker.current = undefined;
  }, [currentPeerJid]);

  const getKey = (dateGroup) => {
    return `${dateGroup.date} ${dateGroup.isUnreadGroup}`;
  };

  const reportIfMultipleDateGroupsWithSameKey = useCallback(
    (dateGroups = []) => {
      const timePassedSinceLastReport = Date.now() - lastReportTime.current;
      // This is running on every render so have to restrict it.
      if (timePassedSinceLastReport <= MEDUSA_REPORT_INTERVAL) return;
      const allKeys = dateGroups.map((groupObj) => getKey(groupObj));
      const uniqueKeys = Array.from(new Set(allKeys));
      if (allKeys.length !== uniqueKeys.length) {
        const allMsgs = dateGroups.reduce(
          (msgs, group) => [...msgs, ...group.msgs],
          []
        );
        const allMsgsTime = allMsgs.map((msg) => msg.stimestamp);
        console.error(
          'duplicate keys found in message renderer',
          JSON.stringify({ allKeys, allMsgsTime })
        );
        logMedusaGenericEvent('duplicate_keys_web', { allKeys, allMsgsTime });
        lastReportTime.current = Date.now();
      }
    },
    []
  );

  const getRenderObject = useCallback(() => {
    let currentDateCollection;
    const renderObject = [];
    const currentTime = Date.now();

    messages.forEach((msg, index, allMessages) => {
      const msgTime = msg.stimestamp || currentTime;
      const date = justDate(Number(msgTime));

      // For testing
      // if(msg && msg.sid === '1641807689357-fqf-o102') {
      //   date = justDate(Number(msgTime) + 2 * 24 * 60 * 60 * 1000);
      // }

      if (!showUnreadMarker) {
        marker.current = { sid: -1 };
      }

      if (!marker.current) {
        const firstMessage = allMessages[0];
        const isFirstMessageUnRead = firstMessage
          ? isMessageUnreadByMe(receiptObj, firstMessage)
          : false;
        const prevMsg = allMessages[index - 1];
        const isPrevMsgUsable = prevMsg
          ? canUseAsUnreadMarker(receiptObj, prevMsg)
          : false;
        const isCurrentMsgUsable = canUseAsUnreadMarker(receiptObj, msg);

        // First message of the list must be read for unread marker for the logic to work.
        if (
          !isFirstMessageUnRead &&
          prevMsg &&
          !isPrevMsgUsable &&
          isCurrentMsgUsable
        ) {
          marker.current = msg;
        }
      }

      if (marker.current?.sid === msg.sid) {
        currentDateCollection = {
          date,
          msgs: [],
          firstUnreadGroup: true,
          isUnreadGroup: true,
        };
        renderObject.push(currentDateCollection);
      }

      if (
        !currentDateCollection ||
        !isEqual(date, currentDateCollection.date)
      ) {
        currentDateCollection = {
          date,
          msgs: [],
          isUnreadGroup: marker.current
            ? marker.current.timestamp < msg.timestamp
            : false,
        };
        renderObject.push(currentDateCollection);
      }
      currentDateCollection.msgs.push(msg);
    });
    reportIfMultipleDateGroupsWithSameKey(renderObject);
    return renderObject;
  }, [
    messages,
    receiptObj,
    showUnreadMarker,
    reportIfMultipleDateGroupsWithSameKey,
  ]);

  if (messagesForPeerJid !== currentPeer.jid) {
    return null;
  }
  return getRenderObject().map((dateGroup, index, allItems) => {
    const isLastDateGroup = index === allItems.length - 1;
    return (
      // Make sure the key here is same for subsequent renders, so that messages under the group
      // are not re-created.
      <React.Fragment key={getKey(dateGroup)}>
        {dateGroup.firstUnreadGroup ? <UnreadMarker /> : null}
        <div className={cssStyles.dateGroup}>
          <div className={cssStyles.dateWrap}>
            <div className={cssStyles.date}>{niceString(dateGroup.date)}</div>
          </div>
          <SameDateMessages
            msgs={dateGroup.msgs}
            currentSession={currentSession}
            currentPeer={currentPeer}
            ownerGuid={ownerGuid}
            receiptObj={receiptObj}
            isLastDateGroup={isLastDateGroup}
            onMessageIntersection={onMessageIntersection}
          />
        </div>
      </React.Fragment>
    );
  });
};

export default MessageRenderer;
