import React from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../store";
import IconArrow from "../../assets/icon-arrow.svg";

export const Indicator = observer(function Indicator({ position, onClick }) {
  const store = useStore();
  const onLocalClick = React.useCallback(() => onClick(position), []);

  if (!store.tabIndicators[position]) {
    return null;
  }
  return (
    <div className="indicator-root" onClick={onLocalClick}>
      <div className="arrow">
        <IconArrow className="eup-arrow-icon" />
      </div>

      <style jsx>{`
        .indicator-root {
          position: absolute;
          left: 0;
          z-index: 100;
          overflow: hidden;
          cursor: pointer;
        }
        .arrow {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
        }
      `}</style>
      <style jsx>{`
        .indicator-root {
          top: ${position === "top" ? "0px" : "auto"};
          bottom: ${position === "top" ? "auto" : "0px"};
          width: ${store.tabsWidth - 1}px;
          height: ${store.tabIndicatorHeight}px;
          background-color: ${store.colors.primaryBg};
          border-top-left-radius: ${position === "top" ? "4px" : "0px"};
          border-bottom-left-radius: ${position === "bottom" ? "4px" : "0px"};
          box-shadow: ${position === "top"
            ? "0 4px 2px -2px rgba(0, 0, 0, 0.15)"
            : "0 -4px 2px -2px rgba(0, 0, 0, 0.15)"};
        }
        .arrow {
          transform: ${position === "top" ? "rotate(180deg)" : "none"};
        }
      `}</style>

      <style jsx global>{`
        .eup-arrow-icon {
          fill: ${store.colors.secondaryText};
        }
      `}</style>
    </div>
  );
});
