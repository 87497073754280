import {
  getCommandFragments,
  getBuddyMentionsFragments,
  getEmojiFragments,
  getChannelMentionFragments,
} from './textFragments';
import { DecoratorToken } from '../Types';

/**
 *
 * @param {string} textContent
 * @param {integer} selectionStart
 * @param {integer} selectionEnd
 *
 * Types of tokens to look for
 *   * Slash token
 *   * Emoji token
 *   * Channel Mention token
 *   * Buddy Mention token
 *
 * @returns {object || null}  ; null if no condition satisfies
 *  return object = {
 *                      tokenType: SLASH || EMOJI || CHANNEL_MENTION || BUDDY_MENTION
 *                      paramPrefix: paramPrefixObject from fragments utility
 *                  }
 */
function decoratorTokenLookUp(
  textContent: string,
  selectionStart: number,
  selectionEnd: number
): DecoratorToken {
  if (textContent.indexOf('/') === 0) {
    const textFragments = getCommandFragments(
      textContent,
      selectionStart,
      selectionEnd
    );
    return {
      tokenType: 'SLASH',
      fragments: textFragments,
    };
  }

  const emojiFragments = getEmojiFragments(
    textContent,
    selectionStart,
    selectionEnd
  );
  if (emojiFragments.paramPrefix) {
    return {
      tokenType: 'EMOJI',
      fragments: emojiFragments,
    };
  }

  const channelMentionFragments = getChannelMentionFragments(
    textContent,
    selectionStart,
    selectionEnd
  );

  if (channelMentionFragments.paramPrefix) {
    return {
      tokenType: 'CHANNEL_MENTION',
      fragments: channelMentionFragments,
    };
  }

  const buddyMentionsFragments = getBuddyMentionsFragments(
    textContent,
    selectionStart,
    selectionEnd
  );
  if (buddyMentionsFragments.paramPrefix) {
    return {
      tokenType: 'BUDDY_MENTION',
      fragments: buddyMentionsFragments,
    };
  }

  return undefined;
}

export default decoratorTokenLookUp;
