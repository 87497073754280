import bridge from '@/utils/bridge';
import { getDeviceInfo } from '@/utils/user-agent';
import { getFileShareId } from '@/utils/upload';
import { getUserTokenFromSession } from '@/utils/auth';

function getFileSharingUrl() {
  return bridge.ask('HydraServer', 'getStartupOption', ['fileSharingUrl']);
}

const defaultHooks = {
  onload(successCallback, errorCallback) {
    return (event) => {
      if (event.target.status !== 200) {
        errorCallback();
      } else {
        successCallback(event.target.responseText);
      }
    };
  },
  onerror(callback) {
    return () => callback(false);
  },
  onprogress(callback) {
    return (event) => {
      if (event.lengthComputable) {
        callback({ uploaded: event.loaded, total: event.total });
      } else {
        callback(event);
      }
    };
  },
  onabort(callback) {
    return () => callback(false);
  },
};

async function uploadFile(
  file,
  sessionId,
  ownerGuid,
  peerJid,
  msgCid,
  callbacks,
  forceFail = false
) {
  // eslint-disable-next-line no-undef
  const xhr = new XMLHttpRequest();
  const formData = new FormData();
  formData.append('file', file);
  const token = `${await getUserTokenFromSession(sessionId)}${
    forceFail ? 'yellow' : ''
  }`;
  const guid = ownerGuid.split('@')[0];
  const uuid = getFileShareId(peerJid);
  const fileShareUrl = await getFileSharingUrl();
  const deviceInfo = await getDeviceInfo();
  xhr.upload.onprogress = defaultHooks.onprogress(callbacks.onprogress);
  xhr.onload = defaultHooks.onload(callbacks.onload, callbacks.onerror);
  xhr.onerror = defaultHooks.onerror(callbacks.onerror);
  xhr.onabort = defaultHooks.onabort(callbacks.onabort);
  xhr.open(
    'POST',
    `${fileShareUrl}/uploadPrivate?token=${token}&uuid=${uuid}&guid=${guid}&peer=${peerJid}&cid=${msgCid}`
  );
  xhr.setRequestHeader('X-UA-Info', deviceInfo);
  xhr.send(formData);

  return xhr;
}

// eslint-disable-next-line import/prefer-default-export
export { uploadFile };
