import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tippy from '@tippyjs/react';
import { Image } from '@/components/common';
import { events, SEARCH_MESSAGES } from '@/utils/KeyboardShortcuts';
import { addQueryParamtoHTTPUrls } from '@/utils';
import commonStyles from '@/components/common/common.css';
import cssStyles from './AppElement.css';
import badgeStyles from './Badge.css';

class AppElement extends PureComponent {
  static propTypes = {
    appInfo: PropTypes.object.isRequired,
    category: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    isSelected: PropTypes.bool,
    hasBadge: PropTypes.bool,
  };

  static defaultProps = {
    isSelected: false,
    hasBadge: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      loadedIconUrl: null,
    };
  }
  // checking if icon url of mask changed

  componentDidUpdate({ appInfo: { icon: prevIcon } }) {
    const {
      appInfo: { icon: newIcon },
    } = this.props;

    if (newIcon !== prevIcon) {
      this.setState({
        loadedIconUrl: null,
      });
    }
  }
  // loading icon url before applying mask

  getDummyImg = (icon) => {
    const displayDummyImg = 'none';
    const iconUrl = addQueryParamtoHTTPUrls(icon, 'CORS', '1');
    const dummyImg = (
      <Image
        src={iconUrl}
        alt={iconUrl}
        onLoad={(loadedUrl) => this.setState({ loadedIconUrl: loadedUrl })}
        crossOrigin='Anonymous'
      />
    );

    return <div style={{ display: displayDummyImg }}> {dummyImg} </div>;
  };

  onAppClick = () => {
    console.log('App clicked');
    const { appInfo, onClick } = this.props;
    onClick(appInfo);
  };

  render() {
    const { appInfo, category, isSelected, hasBadge } = this.props;
    const { icon, name, description } = appInfo;
    const label = appInfo[category].label || name;
    let details = appInfo[category].description || description;
    if (name === 'Search') {
      details = `${details} (${events[SEARCH_MESSAGES].shortcutString})`;
    }
    const { loadedIconUrl } = this.state;
    const iconUrl = addQueryParamtoHTTPUrls(icon, 'CORS', '0');
    const style = {
      WebkitMask: `url(${
        !loadedIconUrl ? iconUrl : loadedIconUrl
      }) 0px 0px / cover no-repeat`,
    };

    const { id, properties: { backgroundColor = '#0abe51' } = {} } =
      appInfo || {};
    return (
      <Tippy
        maxWidth={200}
        content={details}
        arrow
        theme='bootstrap'
        placement='left'
        className={commonStyles.info_tooltip}
      >
        <div
          style={
            isSelected
              ? {
                  backgroundColor,
                }
              : {}
          }
          role='button'
          id={id}
          tabIndex={0}
          onKeyDown={() => {}}
          className={classNames(cssStyles.appElement, {
            [cssStyles.selected]: isSelected,
            [badgeStyles.badge]: hasBadge,
            'badge-element': hasBadge,
          })}
          onClick={this.onAppClick}
          data-app-list-id={id}
        >
          <div className={cssStyles.icon} style={style} />
          <div className={cssStyles.appName}>{label}</div>
          {!loadedIconUrl ? this.getDummyImg(iconUrl) : null}
        </div>
      </Tippy>
    );
  }
}

export default AppElement;
