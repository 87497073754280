import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import bridge from '@/utils/bridge';
import ChatsProxy from '@/Proxies/ChatsProxy';
import currentSessionHOC from '@/connectHOCs/Sessions/currentSession';
import withTeamInfo from '@/connectHOCs/Sessions/currentSession/teamInfo';
import { getFullContactImageUrl } from '@/utils/image';
import { requestPhoneNumber } from '@/utils/ConversationUtils';
import { getCustomFieldValuePair } from '@/utils/ContactUtils';
import IntlWrapper from '@/Wrappers/IntlWrapper';
import { isCurrentConversationPinnedSelector } from '@/selectors';
import { openUrl } from '@/utils/SessionUtils';
import noop from '@/utils/noop';
import { Deactivated as DeactivatedBadge } from '@/components/common/badges';
import TextMessageDecorator from '@/components/Conversation/Message/TextMessageDecorator';
import translations from './I18N';
import css from './Buddy.css';

class Buddy extends Component {
  static defaultProps = {
    peer: undefined,
    currentSession: undefined,
    isPinned: false,
    closePopover: () => {},
  };

  onRequestNumber = () => {
    const { peer } = this.props;
    requestPhoneNumber(peer);
  };

  onAvatarClick = () => {
    const {
      peer: { imageUrl },
      closePopover,
    } = this.props;

    bridge.tell('Shell', 'showImagePopup', [
      {
        src: getFullContactImageUrl(imageUrl),
      },
    ]);
    closePopover();
  };

  onTogglePin = () => {
    const {
      peer: { jid, ownerGuid },
      isPinned,
    } = this.props;
    ChatsProxy.togglePin(ownerGuid, jid, !isPinned);
  };

  onEmailClick = () => {
    const {
      peer: { email },
    } = this.props;
    openUrl(`mailto:${email}`, false);
  };

  render() {
    const { peer, currentSession, teamInfo, isPinned } = this.props;

    const { isEmailAddressMasked, isPhoneMasked } = teamInfo;

    if (!peer || !currentSession) {
      return null;
    }
    const {
      imageUrl,
      name,
      guest,
      userStatus,
      mobile,
      email,
      isDeactivated,
      isDeleted,
    } = peer;

    const customFields = getCustomFieldValuePair(peer, currentSession);
    const pinnedClass = isPinned ? css.isPinned : '';
    const phoneNumberFieldVisible =
      (isDeactivated || isDeleted ? !!mobile : true) && !isPhoneMasked;

    return (
      <div className={css.Wrapper}>
        <div className={css.Top}>
          <div className={css.Profile}>
            {/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */}
            <img
              src={imageUrl}
              className={css.Profile_Img}
              alt='Profile'
              onClick={this.onAvatarClick}
              onKeyDown={noop}
              tabIndex={-1}
              role='button'
            />
            <div className={css.Profile_Details}>
              <div className={css.Name}>
                <span className={css._Name}>{name}</span>
                {guest ? <span className={css.Guest}>(guest)</span> : null}
              </div>
              {isDeactivated && <DeactivatedBadge variant='list-item' />}
              {!isDeactivated && (
                <div className={css.Status}>
                  {userStatus ||
                    (peer.invited ? (
                      <FormattedMessage id='contactCard_default_status' />
                    ) : (
                      ''
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={css.Middle}>
          <div
            className={css.Pin}
            onClick={this.onTogglePin}
            onKeyPress={noop}
            role='button'
            tabIndex={-1}
          >
            <div className={`${css.Icon} ${pinnedClass}`} />
            {isPinned ? (
              <div className={css.Text}>Unpin this chat</div>
            ) : (
              <div className={css.Text}>Pin this chat</div>
            )}
          </div>
        </div>
        {(customFields.length === 0 && isEmailAddressMasked && isPhoneMasked) ||
        !email ? null : (
          <div className={css.Bottom}>
            {!isEmailAddressMasked ? (
              <div className={css.Email}>
                <div className={css.Icon} />
                <div
                  className={css.Text}
                  onClick={this.onEmailClick}
                  onKeyPress={noop}
                  role='button'
                  tabIndex={-1}
                >
                  {email}
                </div>
              </div>
            ) : null}
            {phoneNumberFieldVisible ? (
              <div className={css.PhoneNumber}>
                <div className={css.Icon} />
                <div className={mobile ? css.Text : css.Request}>
                  {mobile || (
                    <span
                      onClick={this.onRequestNumber}
                      onKeyPress={noop}
                      role='button'
                      tabIndex={-1}
                    >
                      <FormattedMessage id='editor_button_text_placeholderRequestNumber' />
                    </span>
                  )}
                </div>
              </div>
            ) : null}
            {customFields.length > 0 ? (
              <div className={css.CustomFields}>
                <div className={css.Icon} />
                <div className={css.FieldsWrapper}>
                  {customFields.map(function (field) {
                    return (
                      <div className={css.Row}>
                        <span className={css.FieldName}>{field.name}</span>
                        <span className={css.FieldValue}>
                          {TextMessageDecorator(
                            field.value,
                            undefined,
                            undefined,
                            ['bold', 'italics', 'emoji']
                          )}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const isPinned = isCurrentConversationPinnedSelector(state);

  return {
    isPinned,
  };
};

export default withTeamInfo(
  currentSessionHOC(connect(mapStateToProps)(IntlWrapper(Buddy, translations)))
);
