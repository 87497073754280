export default {
  recent_contacts: 'CONTATOS RECENTES',
  recent_groups: 'Canais recentes',
  create_group: 'Novo Canal',
  invite_contact: 'Convidar',
  invite_contact_button: 'Convidar',
  group_tab_search_placeholder: 'Pesquisar canais',
  all_contact_tab_search_placeholder: 'Pesquisar Contatos',
  your_groups: 'Seus canais',
  all_contacts: 'Todos os Contatos',
  join_group_button: 'Entrar em um canal',
  create_group_button: 'Criar canal',
  no_result_found: 'Nenhum resultado encontrado',
  join_group_description: 'Descubra e participe de canais na sua equipe',
  create_group_description: 'Criar um novo canal',
  invite_contact_description: 'Convide membros para a sua equipe',
  invite_via_email: 'Convidar por e-mail',
};
