import React, { PureComponent } from 'react';
import DikeActionButtons from './DikeActionButtons';
import AddMemberButton from './AddMemberButton';
import NotificationPreferences from './NotificationPreferences';
import css from './ActionButtons.css';

class ActionButtons extends PureComponent {
  static defaultProps = {
    peer: undefined,
  };

  showDikeActions() {
    const {
      peer: { type },
    } = this.props;

    if (type === 'buddy') {
      return true;
    }
    if (type === 'group') {
      const {
        peer: { hasPostMessagePrivilege },
      } = this.props;
      return !!hasPostMessagePrivilege;
    }
    return false;
  }

  showNotificationPreference() {
    const {
      peer: { type },
    } = this.props;
    if (type !== 'group') {
      return false;
    }
    const {
      peer: { hasMuteGroupPrivilege },
    } = this.props;

    return !!hasMuteGroupPrivilege;
  }

  render() {
    const { peer } = this.props;
    if (!peer) {
      return null;
    }
    return (
      <div className={css.Wrapper}>
        {this.showDikeActions(peer) ? <DikeActionButtons peer={peer} /> : null}
        {peer.isBuddy ? <AddMemberButton peer={peer} /> : null}
        {this.showNotificationPreference() ? (
          <NotificationPreferences peer={peer} />
        ) : null}
      </div>
    );
  }
}

export default ActionButtons;
