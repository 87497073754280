import React, { PureComponent } from 'react';
import Tippy from '@tippyjs/react';
import currentSessionHOC from '@/connectHOCs/Sessions/currentSession';
import appsListSubscriber from '@/subscribeHOCs/appList';
import { doAppAction } from '@/utils/ShellUtils';
import { addQueryParamtoHTTPUrls } from '@/utils';

const rootCategory = 'mailcastApp';
const orgStandardRootCategory = 'chatTabButton';
const gaSource = 'chat_tab_button';

class DikeApps extends PureComponent {
  static defaultProps = {
    peer: undefined,
    currentSession: undefined,
    className: '',
    appList: [],
  };

  onAppClick = (app) => {
    const { currentSession, peer } = this.props;

    doAppAction(
      app[rootCategory].action,
      app,
      peer,
      currentSession.id,
      {
        name: 'client.pressButton',
        button: orgStandardRootCategory,
      },
      gaSource
    );
  };

  render() {
    const { className, appList } = this.props;

    return appList.map((app) => {
      const style = {
        WebkitMask: `url(${addQueryParamtoHTTPUrls(
          app.inClientIcon
        )}) 0px 0px / cover no-repeat`,
      };
      const tooltip = app[rootCategory].description;

      return (
        <Tippy maxWidth={200} content={tooltip} arrow key={app.id}>
          <div
            className={className}
            style={style}
            onClick={() => this.onAppClick(app)}
            onKeyPress={() => {}}
            tabIndex={-1}
            role='button'
          />
        </Tippy>
      );
    });
  }
}

export default currentSessionHOC(appsListSubscriber(DikeApps, rootCategory));
