import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import bridge from '@/utils/bridge';
import cssStyles from './MenuDropdown.css';

class EditStatus extends Component {
  constructor(props) {
    super(props);
    this.editStatus = this.editStatus.bind(this);
  }

  editStatus() {
    const { session } = this.props;
    bridge.publish('odara-edit-status-modal', [session]);
  }

  render() {
    return (
      <div
        role='menuitem'
        onKeyDown={() => {}}
        tabIndex='-1'
        className={classNames(cssStyles.dropdown_list_item)}
        onClick={this.editStatus}
        data-pendo-hook='edit-status'
      >
        <div className={cssStyles.menu_item_info}>
          <div className={classNames(cssStyles.menu_item_label)}>
            <FormattedMessage id='edit_status' />
          </div>
        </div>
      </div>
    );
  }
}

EditStatus.propTypes = {
  session: PropTypes.object.isRequired,
};

export default EditStatus;
