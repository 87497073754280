import React from "react";

export const Sticker = React.memo(function Sticker({ data, width, height }) {
  return (
    <span className="sticker-root">
      <span className="sticker" />

      <style jsx>{`
        .sticker-root {
          display: flex;
          justify-content: center;
          align-items: center;
          pointer-events: none;
        }
        .sticker {
          width: 100%;
          height: 100%;
        }
      `}</style>
      <style jsx>{`
        .sticker-root {
          width: ${width}px;
          height: ${height}px;
        }
        .sticker {
          background: url(${data.thumbnail}) center center / cover no-repeat;
        }
      `}</style>
    </span>
  );
});
