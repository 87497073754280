import React from "react";
import { observer } from "mobx-react-lite";
import { DetailPlaceholder } from "./detailPlaceholder";
import { Emoji } from "../common/emoji";
import { Sticker } from "../common/sticker";
import { useStore } from "../../store";

export const Detail = observer(function Detail() {
  const store = useStore();
  const ref = React.useRef();
  const onMouseOver = React.useCallback(() => {
    store.setHover();
  });

  React.useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener("mouseover", onMouseOver);
    }
    return () => {
      if (ref.current) {
        ref.current.removeEventListener("mouseover", onMouseOver);
      }
    };
  }, [ref.current]);

  let Content;
  if (store.hoverItemType === "emoji") {
    Content = (
      <React.Fragment>
        <div className="eup-detail-item">
          <Emoji
            data={store.hoverItem}
            width={store.detailEmojiWidth}
            height={store.detailEmojiHeight}
          />
        </div>
        <div className="eup-detail-data">
          <div className="eup-detail-label">{store.hoverItem.name}</div>
          <div className="eup-detail-sublabel">
            {store.hoverItem.shortcodes.map(i => (
              <span key={i} className="eup-detail-tag">
                {i}
              </span>
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  } else if (store.hoverItemType === "sticker") {
    Content = (
      <React.Fragment>
        <div className="eup-detail-item">
          <Sticker
            data={store.hoverItem}
            width={store.detailStickerWidth}
            height={store.detailStickerHeight}
          />
        </div>
        <div className="eup-detail-data">
          <div className="eup-detail-label">{store.hoverItem.name}</div>
        </div>
      </React.Fragment>
    );
  } else {
    Content = <DetailPlaceholder />;
  }

  const padding =
    store.hoverItemType === "emoji" ? store.padding : store.paddingSticker;
  const itemWidth =
    store.hoverItemType === "emoji"
      ? store.detailEmojiWidth + store.padding
      : store.detailStickerWidth + store.paddingSticker;

  if (store.disableDetail) {
    return null;
  }
  return (
    <div ref={ref} className="detail-root">
      {Content}

      <style jsx>{`
        .detail-root {
          display: flex;
          align-items: center;
        }
      `}</style>
      <style jsx>{`
        .detail-root {
          width: ${store.contentWidth}px;
          height: ${store.detailHeight}px;
          padding: 0 ${padding}px;
          border-top: 1px solid ${store.colors.border};
        }
      `}</style>

      <style jsx global>{`
        .eup-detail-item {
          display: flex;
          align-items: center;
          height: 100%;
        }
        .eup-detail-data {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          height: 100%;
          overflow: hidden;
        }
        .eup-detail-data > *:empty {
          display: none;
        }
        .eup-detail-label {
          font-size: 11px;
          font-weight: 600;
          text-transform: uppercase;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        .eup-detail-sublabel {
          display: flex;
          align-items: center;
          height: 22px;
          margin-top: 2px;
          font-size: 13px;
        }
        .eup-detail-tag {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 1px 4px 3px;
          border-radius: 4px;
          white-space: nowrap;
        }
        .eup-detail-tag + .eup-detail-tag {
          margin-left: 4px;
        }
      `}</style>
      <style jsx global>{`
        .eup-detail-item {
          width: ${itemWidth}px;
        }
        .eup-detail-data {
          width: ${store.contentWidth - itemWidth}px;
          padding: ${padding / 3}px 0;
        }
        .eup-detail-label {
          color: ${store.colors.secondaryText};
        }
        .eup-detail-sublabel {
          color: ${store.colors.primaryText};
        }
        .eup-detail-tag {
          background-color: ${store.colors.secondaryBg};
        }
      `}</style>
    </div>
  );
});
