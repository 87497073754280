import React, { PureComponent } from 'react';
import { KEYS } from '@/utils/constants';
import css from './Input.css';

class Input extends PureComponent {
  static defaultProps = {
    text: '',
    placeholder: '',
    onChange: () => {},
    onBlur: () => {},
    onFocus: () => {},
    onEnter: () => {},
    onUpArrow: () => {},
    onDownArrow: () => {},
    onSpace: () => {},
    onTab: () => {},
    onEscape: () => {},
    className: '',
    autoFocus: true,
  };

  element = React.createRef();

  componentDidMount() {
    const { autoFocus } = this.props;
    if (autoFocus) {
      setTimeout(() => {
        this.element.current.focus();
      }, 0);
    }
    this.element.current?.addEventListener('keydown', this.onNativeKeyDown);
  }

  componentWillUnmount() {
    this.element.current?.removeEventListener('keydown', this.onNativeKeyDown);
  }

  componentDidUpdate(prevProps) {
    const { autoFocus } = this.props;
    if (autoFocus) {
      if (prevProps?.autoFocus !== autoFocus) {
        this.element.current.focus();
      }
    }
  }

  focus = () => {
    this.element.current.focus();
  };

  blur = () => {
    this.element.current.blur();
  };

  onBlur = (e) => {
    const { onBlur } = this.props;
    onBlur(e);
  };

  onFocus = (e) => {
    const { onFocus } = this.props;
    onFocus(e);
  };

  onChange = (e) => {
    const { onChange } = this.props;
    onChange(e.target.value);
  };

  onNativeKeyDown = (event) => {
    const { onNativeEsc, onNativeEnter } = this.props;
    const { keyCode } = event;

    switch (keyCode) {
      case KEYS.ESCAPE:
        onNativeEsc(event);
        break;

      case KEYS.ENTER:
        onNativeEnter(event);
        break;

      default:
    }
  };

  onKeyDown = (event) => {
    const {
      onEnter,
      onUpArrow,
      onDownArrow,
      onSpace,
      onTab,
      onEscape,
      preventTabOut,
    } = this.props;
    const { keyCode } = event;
    switch (keyCode) {
      case KEYS.ENTER:
        event.preventDefault();
        onEnter(event);
        break;
      case KEYS.UP_ARROW:
        onUpArrow(event);
        break;
      case KEYS.DOWN_ARROW:
        onDownArrow(event);
        break;
      case KEYS.SPACE:
        onSpace(event);
        break;
      case KEYS.TAB:
        if (preventTabOut) {
          event.preventDefault();
        }
        onTab(event);
        break;
      case KEYS.ESCAPE:
        onEscape(event);
        break;
      default:
    }
  };

  render() {
    const { text, placeholder, className } = this.props;

    const computedClassName = `${css.Input} ${className}`;

    return (
      <input
        className={computedClassName}
        ref={this.element}
        type='text'
        value={text}
        onChange={this.onChange}
        onKeyDown={this.onKeyDown}
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        placeholder={placeholder}
      />
    );
  }
}

export default Input;
