import queryString from 'query-string';
import snakeCase from 'lodash/snakeCase';
import difference from 'lodash/difference';
import memoize from 'memoize-one';
import bridge from './bridge';

/**
 *
 * @type {{POST: ((p1?:*, p2?:*)), PUT: ((p1?:*, p2?:*)), DELETE: ((p1?:*)), GET: ((p1?:*))}}
 * @private
 */
const _requestAPI = {
  POST: (url, payload) =>
    fetch(url, { method: 'POST', body: JSON.stringify(payload) }),
  PUT: (url, payload) =>
    fetch(url, { method: 'PUT', body: JSON.stringify(payload) }),
  DELETE: (url) => fetch(url, { method: 'DELETE' }),
  GET: (url) => fetch(url),
};

/**
 * Creates a function that calls the callback `cb` after increasing periods until number of retries exceeds `maxTrials`
 * @param {Function} cb - The function to be retried
 * @param {Number} maxTrials - Number of max retries allowed
 */
export function exponentialBackoff(cb, maxTrials) {
  const MAX_TRIALS = maxTrials || 20;
  let trials = 0;
  return () => {
    if (trials >= MAX_TRIALS) {
      return false;
    }
    // Callback will execute in 2 * trials seconds
    setTimeout(cb, 2 * trials * 1000);
    trials += 1;
    return true;
  };
}

/**
 * upperSnakeCase
 * Convert a space seperated string to an upper snake cased string
 * e.g. "cat and dog" -> "CAT_AND_DOG"
 */
export function upperSnakeCase(string) {
  return snakeCase(string).toUpperCase();
}

/**
 * !!! USE 'string/camelCase' FROM LODASH INSTEAD !!!
 * https://lodash.com/docs/4.17.4#camelCase
 *
 * camelCase
 * Convert an underscore or space sepertated sting to a camel-cased string
 * e.g. "eat RHUBARB_pie" -> "eatRhubarbPie"
 *
 */
// export function camelCase (string) {
//     let words = string.toLowerCase().split(/_| /);
//     return words.reduce((word, next) =>
//     word + next[0].toUpperCase() + next.slice(1));
// }

/**
 * !!! USE 'object/get' FROM LODASH INSTEAD !!!
 * https://lodash.com/docs/4.17.4#get
 *
 * maybe
 * Returns nested key within an object. If not found returns null
 * e.g. maybe(abc.ba.ba.c) -> "eatRhubarbPie"/undefined
 *
 */
// export function maybe () {
//     if (arguments.length < 2) {
//         throw "you're doing it wrong";
//     }
//     let obj = _(arguments).first();
//     return _(arguments).chain().tail().reduce(function(o, prop){
//         if (!o) {
//             return o;
//         }
//         let checkForMethod = /(.*)\(\)$/.exec(prop);
//         return checkForMethod ? o[checkForMethod[1]]() : o[prop];
//     }, obj).value();
// }

/**
 * getQueryParams
 * Returns query params object
 * e.g. "?q=123&b=321" -> {q: 123, b:321}
 *
 */
export function getQueryParams() {
  const qParams = {
    ...queryString.parse(window.location.search),
    ...queryString.parse(window.location.hash),
  };

  return qParams;
}

/**
 * getQueryParam
 * Returns specified query param value
 * e.g. getQueryParams(q) => 123
 *
 */
export function getQueryParam(param) {
  return getQueryParams()[param];
}

/**
 * isValidEmail
 * Returns bool if given arg is a valid email
 * e.g. isValidEmail("sahil@sahil.com") => true
 * e.g. isValidEmail("sahil") => false
 * @param email
 */
export function isValidEmail(email) {
  // eslint-disable-next-line
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

/**
 * getEmailInitial
 * Returns the string before @ in a string
 * e.g. getEmailInitial('sahiln@flock.com') => sahiln
 * e.g. isValidEmail("sahil") => false
 * @param email
 */
export function getEmailInitial(email) {
  return email.replace(/@.*$/, '');
}

/**
 * getEnvironment
 * Returns current project environment
 * e.g. getEnvironment() => <prod, preprod>
 *
 */
export function getEnvironment() {
  const qParams = getQueryParams();

  if (qParams.config) {
    return qParams.config;
  }

  if (window.location.hostname.indexOf('staging') > -1) {
    return 'preprod';
  }

  return 'prod';
}

/**
 * parseToJsonPromise
 * Returns JSON result for the fetch response
 * e.g. parseToJsonPromise(data) => { ...values }
 *
 */
function parseToJsonPromise(data) {
  return new Promise((res) => {
    data.json().then((object) => {
      let response = {
        statusCode: data.status,
      };

      if (Array.isArray(object)) {
        response = {
          data: object,
          ...response,
        };
      } else {
        response = {
          ...object,
          ...response,
        };
      }

      return res(response);
    });
  });
}

/**
 * stringifyQueryParams
 * Returns stringified query params to be consumed by the URIs
 * e.g. stringifyQueryParams({ ...value }) => "?key=value&..."
 *
 */
export function stringifyQueryParams(queryParams = {}) {
  return Object.keys(queryParams)
    .map((k) => `${k}=${queryParams[k]}`)
    .join('&');
}

/**
 * constructUrl
 * Returns constructed url stitched with endpoint uri and stringified query params.
 * e.g. constructUrl("https://blahblah.com", "myname", { ...values }) => "https://blahblah.com/myname?key=value&..."
 *
 */
export function constructUrl(endpoint, uri, queryParams) {
  const query = stringifyQueryParams(queryParams);
  return `${endpoint}/${uri}?${query || ''}`;
}

/**
 * requestApi:: Handler to request APIs given an endpoint, method and data
 * @param endpoint:: Target endpoint to which the request is made
 * @param uri:: Method on the server to be called
 * @param method:: HTTP method to be called with, <GET, POST>
 * @param data:: payload along with query params
 * @returns {Promise.<T>}
 */
export function requestApi(endpoint, uri, method, data) {
  return _requestAPI[method](
    constructUrl(endpoint, uri, data.queryParams),
    data.body
  )
    .then(parseToJsonPromise)
    .catch((error) => {
      throw error;
    });
}

/**
 * timeoutPromise: returns a self destructive Promise after a certain timeout
 * @param timeout
 * @param err
 * @param promise
 * @returns {Promise}
 */
export function timeoutPromise(timeout, promise) {
  return new Promise(function (resolve, reject) {
    promise.then(resolve, reject);
    setTimeout(reject, timeout);
  });
}

export const arrayMoveMutate = (array, from, to) => {
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
};

export const arrayMove = (array, from, to) => {
  array = array.slice();
  arrayMoveMutate(array, from, to);
  return array;
};
export const AppType = (function () {
  const platformIsDesktop = !!(
    window.process &&
    window.process.versions &&
    (window.process.versions.electron || window.process.versions.nw)
  );

  return platformIsDesktop ? 'desktop' : 'browser';
})();

export const OS = (function () {
  const userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.indexOf('mac') > -1) {
    return 'mac';
  }
  if (userAgent.indexOf('win') > -1) {
    return 'win';
  }
  return 'linux';
})();

export const OsAppType = (function () {
  return `${OS}-${AppType}`;
})();

export const getLocalMidnightTimestamp = function () {
  const now = new Date();
  now.setHours(24);
  now.setMinutes(0);
  now.setSeconds(0);

  return now;
};

export const combineEvents = function (functionsArray) {
  return function (...args) {
    return functionsArray.every((fn) => !fn || fn(...args));
  };
};
const MENTIONS = 'mentions';
export function checkMention(msg) {
  if (!msg || !msg.messageElements || !msg.messageElements[MENTIONS])
    return false;
  return msg.messageElements[MENTIONS].some((mention) => {
    return mention.mentionsMe;
  });
}

export const extractGuid = memoize((sessionId) => {
  return sessionId && sessionId.split('_')[1].split('@')[0];
});

export function jidFromGuid(guid, group) {
  return group ? `${guid}@groups.go.to` : `${guid}@go.to`;
}

export function guidFromJid(jid) {
  return jid.split('@')[0];
}

export function maybe(obj, ...keys) {
  if (keys.length === 0) {
    throw new Error('Must provide keys');
  }

  return keys.reduce(function (o, prop) {
    if (!o) return o;
    const checkForMethod = /(.*)\(\)$/.exec(prop);
    return checkForMethod ? o[checkForMethod[1]]() : o[prop];
  }, obj);
}

export function isGroup(jid) {
  return /group/i.test(jid);
}

export function getConversationType(jid) {
  return isGroup(jid) ? 'group' : 'buddy';
}

/**
 *
 * @param {HTMLElement|Document} container element which needs to be scrolled. Default value is the `document` global.
 * @param {HTMLElement} elem The element to be scrolled into view
 * @param {Number} offset The offset to adjust the visible element in scroll.
 */
export function scrollTo(container = document, elem, offset = 0) {
  if (container && elem) {
    container.scrollTo({
      top: elem.offsetTop - offset,
      behaviour: 'smooth',
    });
  }
}

export function getResolvedPromise(time = 0) {
  return new Promise((res) => setTimeout(res, time));
}
/**
  Adds dummy query param to urls. 
  In case we want to burst cache, we can change query param's value and
  it will be good enough to tell browser to fetch fresh results. 
 */
export function addQueryParamtoHTTPUrls(src) {
  if (src.indexOf('data:image/') !== 0) {
    const separator = src.indexOf('?') > -1 ? '&' : '?';
    return `${src}${separator}CORS=1`;
  }
  return src;
}

export function escapedForRegex(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export const profilerCallBack = function (
  id,
  phase,
  actualDuration,
  baseDuration,
  startTime,
  commitTime
  /* interaction */
) {
  console.log('REACT_PERF_TIME:::', id, phase, commitTime - startTime);
};

export { default as colors } from './colors';

export const ENTER = 'Enter';

export function isEnterPressed(e) {
  return (e.key || e.code) === ENTER || e.which === 13;
}

export function PromisifyBridgeSubscribes(
  resolveSubscribe,
  rejectSubscribe,
  timeout
) {
  const subs = [];
  const promise = new Promise((resolve, reject) => {
    subs.push(bridge.subscribe(resolveSubscribe, resolve));
    subs.push(bridge.subscribe(rejectSubscribe, reject));
    if (timeout > 0) {
      setTimeout(reject, timeout);
    }
  });

  promise.finally(() => {
    bridge.unsubscribeAll(subs);
  });

  return promise;
}

export function without(arr, index) {
  // return new array without element at index
  // clones the array if index not in range
  const i = !(index >= 0) ? arr.length : index;
  return [...arr.slice(0, i), ...arr.slice(i + 1, arr.length)];
}

export function getMemoizedArray(initialValue = []) {
  let arr = initialValue;

  return (newArr) => {
    const addedElements = difference(newArr, arr);
    const removedElements = difference(arr, newArr);

    // Uncomment this to check if memoizing arrays gives any benefit
    // return newArr;
    if (addedElements.length === 0 && removedElements.length === 0) {
      return arr;
    }
    arr = newArr;
    return arr;
  };
}

export function getMemoizedObject(initialValue = {}) {
  let obj = initialValue;

  return (newObj) => {
    const objKeys = Object.keys(obj);
    const newobjKeys = Object.keys(newObj);
    const addedElems = [];
    newobjKeys.forEach((key) => {
      if (newObj[key] !== obj[key]) {
        addedElems.push(newObj[key]);
      }
    });
    const removedElems = [];
    objKeys.forEach((key) => {
      if (newObj[key] === undefined) {
        removedElems.push(obj[key]);
      }
    });
    // Uncomment this to check if memoizing objects gives any benefit
    // return newObj;

    if (addedElems.length === 0 && removedElems.length === 0) {
      return obj;
    }

    obj = newObj;
    return obj;
  };
}

export const DEFAULT_CONTACT_NAME = 'Unknown';

export const getMemberCount = (memberCount, maskChannelmemberCountAfter) => {
  if (
    !maskChannelmemberCountAfter ||
    memberCount <= maskChannelmemberCountAfter
  ) {
    return memberCount;
  }
  return `${maskChannelmemberCountAfter}+`;
};

export function highlightMessage(messageEl, transition, highlight) {
  return new Promise((resolve) => {
    messageEl.classList.remove(transition);
    messageEl.classList.add(highlight);
    setTimeout(() => {
      messageEl.classList.add(transition);
    }, 100);
    setTimeout(() => {
      messageEl.classList.remove(highlight);
      resolve('highlighted');
    }, 4000);
    setTimeout(() => {
      messageEl.classList.remove(transition);
    }, 5000);
  });
}

/**
 *
 * @param {string| number} value
 * Checks if a given value is a valid timestamp or not.
 * @returns {boolean}
 */
export function isValidTimestamp(value) {
  const integerValue = parseInt(value, 10);
  const d = new Date(integerValue);

  if (!d) {
    return false;
  }

  if (d.getTime() !== integerValue) {
    return false;
  }

  return true;
}

/**
 *
 * @param {string} sid
 *
 * Checks if a given string is a valid sid or not.
 * @returns {boolean}
 */
export function isValidSid(sid) {
  // Sid is ${stimestamp}-${3-char-string}-${4-char-alphanumeric}
  const sidParts = sid.split('-');

  // If sid doesn't have 3 parts it's not valid.
  if (sidParts.length < 3) {
    return false;
  }

  const part1 = sidParts[0];
  if (!isValidTimestamp(part1)) {
    return false;
  }

  const part2 = sidParts[1];
  if (part2.length !== 3) {
    return false;
  }

  const part3 = sidParts[2];
  if (part3.length !== 4) {
    return false;
  }

  return true;
}
