import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import ListElem from '@/components/common/ListElem';
import Tippy from '@tippyjs/react';
import { isEnterPressed } from '@/utils';
import noop from '@/utils/noop';
import commonStyles from '@/components/common/common.css';

const List = React.forwardRef(
  (
    {
      dropdownClass,
      hide,
      label,
      labelClass,
      options,
      onItemClick,
      isSelected,
      selectedClass,
      itemClass,
    },
    listRef
  ) => (
    <div className={dropdownClass} ref={listRef} onBlur={hide} tabIndex='-1'>
      {label ? <div className={labelClass}>{label}</div> : null}
      <ListElem
        options={options}
        onClick={onItemClick}
        isSelected={isSelected}
        selectedClass={selectedClass}
        itemClass={itemClass}
        hide={hide}
      />
    </div>
  )
);

/**
 *
 * Takes in a label and a list of options to render a dropdown with a list of options.
 * Component should get the following props:
 * `options`: `[{ text: 'Some display text for item', ...rest}, ...]` - List of options to display
 * `onClick`: onClick handler for what should happen onClick of item.
 * `children`: The element that will be clicked to show the dropdown.
 * `hideOnClick`: When true, dropdown will close when blurred, default is true
 * `dropdownClass`: For styles, you may add your own class and style the dropdown accordingly.
 * `selectedClass`: Applied to the option that is selected, pass down your class and style it in your component.
 * `itemClass`: Applied to every list item, pass down your class and style it in your component.
 * `label`: The label text (can be JSX as well).
 *  `labelClass`: For styling the label, pass down your label class and style it in your component.
 *  Look at the implementation in [ActiveChats](../../ActiveChats/ActiveChats.js)
 * @returns JSX component
 */
export default function DropdownList({
  onClick,
  children,
  onToggle = noop,
  dropdownClass,
  label,
  labelClass,
  options,
  isSelected,
  selectedClass,
  itemClass,
}) {
  const listRef = useRef(null);
  const [on, setVisible] = useState(false);

  const toggle = useCallback(
    (e) => {
      setVisible(!on);
      onToggle(!on);
      e.stopPropagation();
    },
    [on, onToggle]
  );

  const hide = useCallback(() => setVisible(false), [setVisible]);

  const onEnterPress = useCallback(
    (e) => {
      e.preventDefault();
      if (isEnterPressed(e)) {
        toggle();
      }
    },
    [toggle]
  );

  const onShow = useCallback(() => {
    listRef.current?.focus();
  }, []);

  const onItemClick = useCallback(
    (e, o) => {
      onClick(e, o);
      hide();
    },
    [onClick, hide]
  );

  return (
    <Tippy
      className={commonStyles.tooltip}
      visible={on}
      key={on ? 'true' : 'false'}
      arrow={false}
      interactive
      interactiveBorder={0}
      placement='bottom-start'
      appendTo={document.body}
      onShown={onShow}
      content={
        <List
          hide={hide}
          label={label}
          ref={listRef}
          dropdownClass={dropdownClass}
          labelClass={labelClass}
          options={options}
          onItemClick={onItemClick}
          isSelected={isSelected}
          selectedClass={selectedClass}
          itemClass={itemClass}
        />
      }
    >
      <span
        role='button'
        onKeyDown={onEnterPress}
        tabIndex='-1'
        onClick={toggle}
      >
        {children}
      </span>
    </Tippy>
  );
}

DropdownList.defaultProps = {
  label: '',
  labelClass: '',
  onClick: () => {},
  dropdownClass: '',
  selectedClass: '',
  itemClass: '',
  isSelected: () => {},
  onToggle: () => {},
};

DropdownList.propTypes = {
  label: PropTypes.string,
  labelClass: PropTypes.string,
  options: PropTypes.array.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.object.isRequired,
  dropdownClass: PropTypes.string,
  selectedClass: PropTypes.string,
  itemClass: PropTypes.string,
  isSelected: PropTypes.func,
  onToggle: PropTypes.func,
};
