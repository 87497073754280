import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import IntlWrapper from '@/Wrappers/IntlWrapper';
import Image from '@/components/common/Image';
import { Deactivated as DeactivatedBadge } from '@/components/common/badges';
import defaultContactImage from '@/assets/icon-contact.svg';
import { getLoaderWithFallbackUrl } from '@/components/common/utils';
import guestStyles from '@/components/ActiveChats/ActiveChats.css';
import presenceStyles from '@/components/common/common.css';
import { PRESENCE } from '../../utils/constants';
import cssStyles from './Contacts.css';
import UserContext from './UserContext';
import translations from './I18n';

const presenceClassMap = {};
presenceClassMap[PRESENCE.UNKNOWN] = 'presence-avatar--unknown';
presenceClassMap[PRESENCE.ONLINE] = 'presence-avatar--online';
presenceClassMap[PRESENCE.AWAY] = 'presence-avatar--away';
presenceClassMap[PRESENCE.OFFLINE] = 'presence-avatar--offline';

const Guest = ({ isGuest }) =>
  isGuest ? (
    <span className={guestStyles.guest}>
      <FormattedMessage id='guest_badge' />
    </span>
  ) : null;

function Buddy({ contact, changeConversation }) {
  let classes = null;

  if (contact.isDeactivated) {
    classes = cssStyles.Deactivated;
  } else {
    const presenceClass = `presence--${contact.presence}` || PRESENCE.UNKNOWN;
    classes = presenceStyles[presenceClass];
  }

  return (
    <div
      role='menuitem'
      tabIndex={0}
      onKeyDown={() => {}}
      onClick={() => {
        changeConversation(contact);
      }}
      className={cssStyles.contact_container}
    >
      <div className={cssStyles.contact_info}>
        <div
          className={classNames(cssStyles.contact, {
            [cssStyles.Deactivated]: contact.isDeactivated,
          })}
        >
          <div className={cssStyles.roster_image_container}>
            <Image
              alt={contact.name}
              className={cssStyles.roster_image}
              src={contact.imageUrl}
              fallbackUrl={defaultContactImage}
              Loader={getLoaderWithFallbackUrl(defaultContactImage)}
            />
            <div
              className={classNames(
                classes,
                presenceStyles.presence_base,
                cssStyles.presence
              )}
            >
              {' '}
            </div>
          </div>
          <span className={cssStyles.roster_label}>
            {contact.name}
            <Guest isGuest={contact.guest} />
          </span>
          {contact.isDeactivated && <DeactivatedBadge variant='list-item' />}
        </div>
        <div className={cssStyles.actions}>
          <UserContext
            userInfo={contact}
            changeConversation={changeConversation}
          />
        </div>
      </div>
    </div>
  );
}

Buddy.propTypes = {
  contact: PropTypes.object.isRequired,
  changeConversation: PropTypes.func.isRequired,
};

export default IntlWrapper(Buddy, translations);
