import React, { useCallback } from 'react';
import classNames from 'classnames';
import MessageListActions from '@/Contexts/MessageListActions';
import { Image } from '@/components/common';
import AttachmentImage from '@/components/Conversation/Message/AttachmentImage';
import FlockML from '@/components/Conversation/Message/FlockML';
import AttachmentActions from '@/components/Conversation/Message/AttachmentAction/AttachmentActions';
import EmojiDecorator from '@/components/common/EmojiDecorator';
import { getOwnerGuidFromSessionId } from '@/utils/SessionUtils';
import noop from '@/utils/noop';
import cssStyles from './Message.css';
import AttachmentWidget from './AttachmentWidget';
import AudioPlayer from './AudioPlayer';
import FileAttachment from './FileAttachment';

function getTintDetails(attachment) {
  const {
    views = {},
    title,
    subtitle,
    description,
    appId,
    color = 'green',
  } = attachment;
  const { flockml, widget, html, image, sticker } = views;
  const hasTint =
    !sticker &&
    color !== 'none' &&
    (title ||
      subtitle ||
      description ||
      flockml ||
      widget ||
      html ||
      (appId && image));
  return {
    color,
    hasTint,
  };
}

function Sticker({ src, name }) {
  return (
    <Image src={src} title={name} alt={name} className={cssStyles.sticker} />
  );
}

const defaultDownloads = [];

function AttachmentView(props) {
  const { attachment, currentSessionId, peerType, message } = props;
  const { views, downloads = defaultDownloads, appId } = attachment;
  if (views) {
    if (views.widget || views.html) {
      return <AttachmentWidget {...props} isInline={!!views.html} />;
    }
    if (views.flockml) {
      return (
        <FlockML
          appId={appId}
          flockml={views.flockml}
          currentSessionId={currentSessionId}
          peerType={peerType}
          ownerGuid={getOwnerGuidFromSessionId(currentSessionId)}
        />
      );
    }
    if (views.image) {
      const { filename } = views.image;
      if (
        !filename ||
        !['psd', 'tiff', 'tif'].some((e) => filename.endsWith(e))
      ) {
        return (
          <AttachmentImage
            attachment={attachment}
            currentSessionId={currentSessionId}
            message={message}
          />
        );
      }
    }
    if (views.sticker) {
      const { src, name } = views.sticker;
      return <Sticker src={src} name={name} />;
    }
  }
  return downloads.map((download) =>
    download.mime && download.mime.startsWith('audio') ? (
      <AudioPlayer
        key={download.filename}
        download={download}
        currentSessionId={currentSessionId}
      />
    ) : (
      <FileAttachment
        key={download.filename}
        download={download}
        currentSessionId={currentSessionId}
      />
    )
  );
}

function AttachmentDetails(props) {
  const { attachment } = props;
  const { hasTint, color: tintColor } = getTintDetails(attachment);
  const attachmentClassName = classNames(cssStyles.attachmentRoot, {
    [cssStyles.hasTint]: hasTint,
  });

  const { views: { sticker } = {} } = attachment;
  const { description, title, subtitle } = !sticker ? attachment : {};
  const attachmentRootStyle = {
    borderColor: tintColor,
  };
  return (
    <div className={attachmentClassName} style={attachmentRootStyle}>
      {title ? (
        <div className={cssStyles.title}>
          <EmojiDecorator text={title} />
        </div>
      ) : null}
      {subtitle ? (
        <div className={cssStyles.subtitle}>
          <EmojiDecorator text={subtitle} />
        </div>
      ) : null}
      {description ? (
        <div className={cssStyles.description}>
          <EmojiDecorator text={description} />
        </div>
      ) : null}
      <AttachmentView {...props} />
    </div>
  );
}

function ReplyAttachment({ scrollToMessageBySid, ...props }) {
  const {
    message: { replyOf },
  } = props;

  const onClick = useCallback(() => {
    scrollToMessageBySid(replyOf, false, true, 'reply');
  }, [scrollToMessageBySid, replyOf]);

  return (
    <div
      className={cssStyles.replyAttachment}
      onClick={onClick}
      onKeyDown={noop}
      role='button'
      tabIndex={0}
    >
      <AttachmentDetails {...props} />
    </div>
  );
}

export default class Attachment extends React.Component {
  render() {
    const { attachment, message, currentPeer } = this.props;
    return (
      <>
        {message.replyOf ? (
          <MessageListActions.Consumer>
            {({ scrollToMessageBySid }) => (
              <ReplyAttachment
                scrollToMessageBySid={scrollToMessageBySid}
                {...this.props}
              />
            )}
          </MessageListActions.Consumer>
        ) : (
          <AttachmentDetails {...this.props} />
        )}
        <AttachmentActions
          attachment={attachment}
          peer={currentPeer}
          message={message}
        />
      </>
    );
  }
}
