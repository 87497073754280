import { createActions } from 'redux-actions';

export const OPEN_SEARCH_APP = 'OPEN_SEARCH_APP';
export const CLOSE_SEARCH_APP = 'CLOSE_SEARCH_APP';
export const RESET_SEARCH_APP_OPTIONS = 'RESET_SEARCH_APP_OPTIONS';

export const ActionNames = {
  OPEN_SEARCH_APP,
  CLOSE_SEARCH_APP,
  RESET_SEARCH_APP_OPTIONS,
};

interface OpenSearchAction {
  type: typeof OPEN_SEARCH_APP;
  payload: any;
}

interface CloseSearchAction {
  type: typeof CLOSE_SEARCH_APP;
  payload: any;
}

interface ResetSearchOptionsAction {
  type: typeof RESET_SEARCH_APP_OPTIONS;
  payload: any;
}

export type ActionTypes =
  | OpenSearchAction
  | CloseSearchAction
  | ResetSearchOptionsAction;

export const actions = createActions({
  OPEN_SEARCH_APP: (options) => options,
  CLOSE_SEARCH_APP: () => {},
  RESET_SEARCH_APP_OPTIONS: () => {},
} as Record<ActionTypes['type'], any>);
