import ptBr from './pt-br';
import enGB from './en-GB';
import enUS from './en-US';
import es from './es';
import esMX from './es-MX';
import fr from './fr';
import it from './it';
import id from './id';
import tr from './tr';
import zhHansCN from './zh-Hans-CN';

export default {
  'pt-br': ptBr,
  es,
  'en-GB': enGB,
  'en-US': enUS,
  'es-MX': esMX,
  fr,
  it,
  id,
  tr,
  'zh-Hans-CN': zhHansCN,
};
