import React from 'react';
import yesIcon from '@/assets/icon-yes.svg';
import Button from '@/components/common/Button';
import { notifyAdminForUpgrade } from '@/utils/SessionUtils';
import { logMedusaGenericEvent } from '@/utils/logAnalytics';
import styles from './PaywallComparison.css';

const gaSource = 'PAYWALL_COMPARISON';

export default function PaywallComparison({
  teamPlan: { daysLeftForExpiry } = {},
  sessionId,
  hidePaywallComparison,
}) {
  function requestUpgrade() {
    notifyAdminForUpgrade(sessionId, 'PAYWALL_COMPARISON');
    logMedusaGenericEvent('upgrade_clicked', {
      gaSource,
    });
    hidePaywallComparison();
  }
  return (
    <div className={styles['onboarding-plan-comparison']}>
      <h1>
        {daysLeftForExpiry} {daysLeftForExpiry === 1 ? 'day' : 'days'} remaining
        on your PRO trial
      </h1>
      <table>
        <colgroup>
          <col className={styles['features-col']} />
          <col className={styles['pro-col']} />
          <col className={styles['free-col']} />
        </colgroup>

        <thead>
          <tr>
            <th>Features</th>
            <th>PRO plan</th>
            <th>Starter Plan</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>Unlimited messages</td>
            <td>
              <img src={yesIcon} alt='Yes' />
            </td>
            <td>
              <img src={yesIcon} alt='Yes' />
            </td>
          </tr>
          <tr>
            <td>Admin Control</td>
            <td>Advanced</td>
            <td>Basic</td>
          </tr>
          <tr>
            <td>Search for messages</td>
            <td>All messages</td>
            <td>Last 10K messages</td>
          </tr>
          <tr>
            <td>Public Channels</td>
            <td>Unlimited</td>
            <td>10</td>
          </tr>
          <tr>
            <td>Private Channels</td>
            <td>
              <img src={yesIcon} alt='Yes' />
            </td>
            <td />
          </tr>
          <tr>
            <td>Group video calls</td>
            <td>
              <img src={yesIcon} alt='Yes' />
            </td>
            <td />
          </tr>
          <tr>
            <td>Guest members</td>
            <td>
              <img src={yesIcon} alt='Yes' />
            </td>
            <td />
          </tr>
          <tr>
            <td>Shared to-dos</td>
            <td>
              <img src={yesIcon} alt='Yes' />
            </td>
            <td />
          </tr>
        </tbody>
      </table>

      <Button onClick={requestUpgrade}>Request Admin to upgrade to PRO</Button>

      <p className={styles['onboarding-plan-comparison-footer-text']}>
        Your team will be moved to the free Starter plan if you do not choose to
        purchase Flock PRO.
      </p>
    </div>
  );
}
