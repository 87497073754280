import React from 'react';
import PropTypes from 'prop-types';
import { SortableContainer } from 'react-sortable-hoc';

/**
 * Wraps a sortable list
 *
 * @param {object} props
 * @param {children} props.children
 */
const SortableList = ({ children, className, onScroll }) => (
  <div className={className} onScroll={onScroll}>
    {children}
  </div>
);

SortableList.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  className: PropTypes.string,
};

SortableList.defaultProps = {
  className: '',
};

export default SortableContainer(SortableList);
