import React, { Component } from 'react';
import Tippy from '@tippyjs/react';

import { CustomList } from '@/components/common';
import { doTwillioAppAction } from '@/utils/ShellUtils';
import AVConferenceAppsComponent from '@/components/Conversation/Header/ActionButtons/VideoConferenceOptions/AVConferenceAppsComponent';
import styles from './VideoConferenceOptions.css';

const VIDEO = 'video';
const AUDIO = 'audio';

const MODES = [AUDIO, VIDEO];

function generateCallOptions(mode) {
  return [
    {
      enableRecording: true,
      title: `Record the ${mode} call`,
      desc: `Records ${
        mode === 'video' ? `all media, audio` : 'audio'
      } and screen share`,
      startWithoutVideo: mode !== 'video',
    },
    {
      enableRecording: false,
      title: 'Continue without recording',
      desc: 'Does not record anything',
      startWithoutVideo: mode !== 'video',
    },
  ];
}
const callOptions = {
  [VIDEO]: generateCallOptions('video'),
  [AUDIO]: generateCallOptions('audio'),
};

function CallOptionItem({ title, desc }) {
  return (
    <>
      <div className={styles.title}>{title}</div>
      <div className={styles.desc}>{desc}</div>
    </>
  );
}

export default class VideoConferenceOptions extends Component {
  state = {
    dropdown: {
      [VIDEO]: false,
      [AUDIO]: false,
    },
  };

  onCallOptionClick = ({ enableRecording, startWithoutVideo }, mode) => {
    console.log(mode);
    const { currentSession, peer } = this.props;
    doTwillioAppAction(
      currentSession.id,
      peer,
      null,
      enableRecording,
      startWithoutVideo
    );
    this.hide(mode);
  };

  hide = (mode) => {
    this.setState(({ dropdown }) => ({
      dropdown: {
        ...dropdown,
        [mode]: false,
      },
    }));
  };

  toggle = (e, mode) => {
    e.stopPropagation();
    this.setState(({ dropdown }) => ({
      dropdown: {
        ...dropdown,
        [mode]: !dropdown[mode],
      },
    }));
  };

  render() {
    const {
      className,
      tooltip,
      style,
      appId,
      isVideoRecordingEnabled,
    } = this.props;
    const { dropdown } = this.state;
    return (
      <>
        {MODES.map((mode) => {
          if (isVideoRecordingEnabled) {
            return (
              <Tippy
                visible={dropdown[mode]}
                onClickOutside={() => this.hide(mode)}
                arrow={false}
                interactive
                interactiveBorder={0}
                placement='bottom-end'
                appendTo={document.body}
                render={(attrs) =>
                  dropdown[mode] ? (
                    <div {...attrs}>
                      <CustomList
                        items={callOptions[mode]}
                        className={styles.videoOptions}
                        listItemClass={styles.videoOptionItem}
                        component={CallOptionItem}
                        onItemClick={(o) => this.onCallOptionClick(o, mode)}
                      />
                    </div>
                  ) : null
                }
              >
                <div>
                  <AVConferenceAppsComponent
                    mode={mode}
                    className={className}
                    tooltip={tooltip}
                    appId={appId}
                    isVideoRecordingEnabled={isVideoRecordingEnabled}
                    style={style}
                    callOptionClick={this.onCallOptionClick}
                    toggleMenu={this.toggle}
                  />
                </div>
              </Tippy>
            );
          }
          return (
            <AVConferenceAppsComponent
              mode={mode}
              className={className}
              tooltip={tooltip}
              appId={appId}
              isVideoRecordingEnabled={isVideoRecordingEnabled}
              style={style}
              callOptionClick={this.onCallOptionClick}
              toggleMenu={this.toggle}
            />
          );
        })}
      </>
    );
  }
}
