import React from "react";
import { observer } from "mobx-react-lite";
import { FixedSizeList } from "react-window";
import { TabItem } from "./tabItem";
import { useStore } from "../../store";
import { Indicator } from "./indicator";
import { reaction } from "mobx";

export const Tabs = observer(function Tabs() {
  const store = useStore();
  const ref = React.useRef();
  const listRef = React.useRef();
  const listOuterRef = React.useRef();

  const onItemsRendered = React.useCallback(store.setTabsItemsRendered, []);
  const onIndicatorClick = React.useCallback(
    (position) => {
      if (listRef.current) {
        if (position === "top") {
          listRef.current.scrollToItem(0);
        } else if (position === "bottom") {
          listRef.current.scrollToItem(store.tabList.length - 1);
        }
      }
    },
    []
  );
  const onClick = React.useCallback((e) => {
    if (e.target.hasAttribute("data-picker-tab-item")) {
      const id = e.target.getAttribute("data-picker-tab-item-id");
      if (id) {
        store.setCategoryId(id);
      }
    }
  }, []);

  // If indicators are shown, this will offset the tab item that's currently
  // scrolled into by the height in pixels of the indicator so that the tab
  // item is fully visible.
  const onListScroll = React.useCallback(
    ({ scrollDirection, scrollUpdateWasRequested }) => {
      if (listRef.current && listOuterRef.current && scrollUpdateWasRequested) {
        const tabIndicatorHeight = store.tabIndicatorHeight;

        if (scrollDirection === "forward" && store.tabIndicators.bottom) {
          listOuterRef.current.scrollBy({ top: tabIndicatorHeight });
        } else if (scrollDirection === "backward" && store.tabIndicators.top) {
          listOuterRef.current.scrollBy({ top: -tabIndicatorHeight });
        }
      }
    },
    []
  );

  React.useEffect(() => {
    const dispose = reaction(
      () => store.lastReset,
      () => {
        listRef.current?.scrollTo(0);
      },
      {
        name: "resetTabsListScrollOnStoreReset",
      }
    );

    return () => {
      dispose();
    };
  }, []);

  // Scroll to the currently active tab element if it's not visible
  // when the active category changes as a result of user scrolling
  // the list of emojis.
  React.useEffect(() => {
    const dispose = reaction(
      () => [store.activeCategory, store.tabList],
      () => {
        const activeTabIndex = store.tabList.findIndex(
          (tab) => tab.id === store.activeCategory
        );
        listRef.current?.scrollToItem(activeTabIndex);
      },
      {
        name: "syncTabsScrollPositionWithEmojiList",
      }
    );

    return () => {
      dispose();
    };
  }, []);

  if (store.disableTabs) {
    return null;
  }

  return (
    <React.Fragment>
      <Indicator position="top" onClick={onIndicatorClick} />
      <div ref={ref} className="tabs-root" onClick={onClick}>
        <FixedSizeList
          ref={listRef}
          outerRef={listOuterRef}
          className="eup-tabs-list"
          width={store.tabsWidth}
          height={store.height}
          itemSize={store.tabItemHeight}
          itemCount={store.tabList.length}
          onScroll={onListScroll}
          onItemsRendered={onItemsRendered}
        >
          {TabItem}
        </FixedSizeList>
      </div>
      <Indicator position="bottom" onClick={onIndicatorClick} />

      <style jsx>{`
        .tabs-root {
          border-right: 1px solid ${store.colors.border};
        }
      `}</style>
      <style jsx global>{`
        .eup-tabs-list::-webkit-scrollbar {
          width: 0px;
          background: transparent;
        }
      `}</style>
    </React.Fragment>
  );
});

export default Tabs;
