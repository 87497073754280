import { ActionTypes, ActionNames } from '@/actions/search';
import _isEqual from 'lodash/isEqual';

const defaultState = {
  open: false,
  options: null,
};

const {
  OPEN_SEARCH_APP,
  CLOSE_SEARCH_APP,
  RESET_SEARCH_APP_OPTIONS,
} = ActionNames;

type SearchStoreState = typeof defaultState;

export default function searchReducer(
  state = defaultState,
  action: ActionTypes
): SearchStoreState {
  if (action.type === OPEN_SEARCH_APP) {
    return {
      ...state,
      open: true,
      options: _isEqual(action.payload, state.options)
        ? state.options
        : action.payload,
    };
  }

  if (action.type === CLOSE_SEARCH_APP) {
    return {
      ...state,
      open: false,
    };
  }

  if (action.type === RESET_SEARCH_APP_OPTIONS) {
    return {
      ...state,
      options: null,
    };
  }

  return state;
}
