import React, { PureComponent } from 'react';
import currentSessionOwnerGuidHOC from '@/connectHOCs/Sessions/currentSession/ownerGuid';
import getBucketedList from '@/utils/BucketedList';
import { searchContacts } from '@/utils/ContactUtils';
import noop from '@/utils/noop';
import ContactSearch from '../../ContactSearch';
import { addChannelMentionInText } from '../../utils';
import { CHANNEL } from '../../constants';
import { Peer, Command, InsertTextFun } from '../../Types';
import css from './ChannelMentionCommandHelper.css';

type Props = {
  textContent: string;
  peer: Peer;
  command: Command;
  ownerGuid: string;
  insertText: InsertTextFun;
  children: any;
} & typeof ChannelMentionCommandHelper.defaultProps;

type State = {
  contacts: Contact[];
  bucketIndices: any;
};

class ChannelMentionCommandHelper extends PureComponent<Props, State> {
  // TODO: Should this component compute command or accept as a prop?
  static defaultProps = {
    textContent: '',
    peer: undefined as Peer,
    command: undefined as Command,
    children: null,
    insertText: noop as InsertTextFun,
  };

  state = {
    contacts: [],
    bucketIndices: [],
  };

  constructor(props: Props) {
    super(props);

    this.updateSearchResults(props);
  }

  componentDidUpdate(prevProps: Props) {
    const { command: { fragments: prevFragments = {} } = {} } = prevProps;

    const { command: { fragments = {} } = {} } = this.props;

    if (fragments.paramPrefix !== prevFragments.paramPrefix) {
      this.updateSearchResults();
    }
  }

  onContactSelect = (contact: Contact) => {
    const { command, insertText, textContent } = this.props;
    const { fragments } = command;

    const {
      textToBeAdded,
      startPosition,
      endPosition,
    } = addChannelMentionInText(contact, fragments, textContent);
    insertText(
      textToBeAdded,
      startPosition,
      endPosition,
      undefined,
      undefined,
      { insertType: CHANNEL, contact }
    );
  };

  async updateSearchResults(props?: Props) {
    const { command, peer, ownerGuid } = props || this.props;

    const { fragments } = command;

    const queryString = fragments.paramPrefix.slice(1);
    const searchParams = {
      start: 0,
      count: 6,
      filters: {
        type: ['group'],
        groupType: ['open'],
      },
      excludedJids: [peer.jid],
      sortMatches: true,
    };
    const buckets = await searchContacts(ownerGuid, queryString, searchParams);
    const { contacts, bucketIndices } = getBucketedList(buckets, []);
    this.setState({
      contacts,
      bucketIndices,
    });
  }

  render() {
    const { children } = this.props;
    const { contacts, bucketIndices } = this.state;

    return (
      // <div>
      <ContactSearch
        className={css.List}
        contacts={contacts}
        bucketIndices={bucketIndices}
        onContactSelect={this.onContactSelect}
        selectOnSpace={false}
        selectOnTab
        headerString='Public Channels'
      >
        {children}
      </ContactSearch>
      //  </div>
    );
  }
}

export default currentSessionOwnerGuidHOC(ChannelMentionCommandHelper);
