/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { compose } from 'redux';
import EditorPopoverStyleContext from '@/Contexts/EditorPopoverStyle';
import Popover from '@/components/common/Popover';
import { withRecentEmojis, withEmojiPrefs } from '@/lib/emoji';
import noop from '@/utils/noop';
import EmojiPopoverList from './EmojiPopoverList';
import { EMOJI } from '../../constants';
import { DecoratorFragments, Emoji, InsertTextFun } from '../../Types';
import css from './EmojiDecorator.css';

const REGEX_WHITESPACE = /\s/;
type AddToRecentsFn = (emoji: Emoji) => void;

type Props = {
  textContent: string;
  selectionStart: number;
  selectionEnd: number;
  fragments: DecoratorFragments;
  insertText: InsertTextFun;
  addToRecents: AddToRecentsFn;
  recents: any;
  skinTone: any;
  children: any;
} & typeof EmojiDecorator.defaultProps;

type State = {
  emojiPopoverListeners: any;
  open: boolean;
};

class EmojiDecorator extends React.PureComponent<Props, State> {
  static defaultProps = {
    textContent: '',
    selectionStart: 0,
    selectionEnd: 0,
    fragments: null as DecoratorFragments,
    addToRecents: noop as AddToRecentsFn,
  };

  state = {
    emojiPopoverListeners: {},
    open: true,
  };

  componentDidUpdate({
    textContent: tC,
    selectionStart: sS,
    selectionStart: sE,
  }) {
    const { textContent, selectionStart, selectionEnd } = this.props;

    if (textContent !== tC || selectionEnd !== sE || selectionStart !== sS) {
      this.setState({
        open: true,
      });
    }
  }

  onEmojiSelect = (emoji: Emoji) => {
    const { fragments, addToRecents, insertText } = this.props;
    const nativeEmoji: string = emoji.native;

    const {
      textToBeAdded,
      startPosition,
      endPosition,
    } = this.updateMessageContents(nativeEmoji, fragments);

    addToRecents(emoji);
    insertText(
      textToBeAdded,
      startPosition,
      endPosition,
      undefined,
      undefined,
      { insertType: EMOJI }
    );
    // onSelect(textContent, caretPosition, caretPosition);
  };

  updateMessageContents = (
    nativeEmoji: string,
    { textBeforeParam, textAfterSuffix }: Partial<DecoratorFragments>
  ) => {
    const { textContent } = this.props;
    let paddingRight = '';

    if (!REGEX_WHITESPACE.test(textAfterSuffix[0])) {
      paddingRight = ' ';
    }

    const startPosition = textBeforeParam.length;
    const endPosition =
      startPosition +
      textContent.length -
      textBeforeParam.length -
      textAfterSuffix.length;
    // const shortCodeEndPos = shortCodeBeginPos + shortCode.length + 1;
    const textToBeAdded = `${nativeEmoji}${paddingRight}`;

    return {
      textToBeAdded,
      startPosition,
      endPosition,
    };
  };

  registerEmojiPopoverListeners = (listeners) => {
    this.setState({
      emojiPopoverListeners: listeners,
    });
  };

  onEditorUpArrow = (event: React.KeyboardEvent) => {
    event.preventDefault();

    const { onUpArrow } = this.state.emojiPopoverListeners as any;

    return onUpArrow?.() ?? true;
  };

  onEditorDownArrow = (event: React.KeyboardEvent) => {
    event.preventDefault();

    const { onDownArrow } = this.state.emojiPopoverListeners as any;

    return onDownArrow?.() ?? true;
  };

  onEditorEnter = (event: React.KeyboardEvent | React.MouseEvent) => {
    const { onEnter } = this.state.emojiPopoverListeners as any;

    return onEnter?.(event) ?? true;
  };

  render() {
    const { fragments, children, recents, skinTone } = this.props;

    return (
      <EditorPopoverStyleContext.Consumer>
        {({ getPositionStyle, popoverTriggerClass }) => (
          <Popover
            open={this.state.open}
            popOverContent={
              <EmojiPopoverList
                className={css.EmojiPopoverList}
                searchValue={fragments.paramPrefix}
                recents={recents}
                skinTone={skinTone}
                onSelect={this.onEmojiSelect}
                registerListeners={this.registerEmojiPopoverListeners}
              />
            }
            focusOnRender={false}
            getPositionStyle={getPositionStyle}
            triggerClass={popoverTriggerClass}
          >
            {children({
              onUpArrow: this.onEditorUpArrow,
              onDownArrow: this.onEditorDownArrow,
              onEnter: this.onEditorEnter,
              onTab: this.onEditorEnter,
            })}
          </Popover>
        )}
      </EditorPopoverStyleContext.Consumer>
    );
  }
}

export default compose<any>(withEmojiPrefs, withRecentEmojis)(EmojiDecorator);
