import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import memoize from 'memoize-one';
import { createSelector } from 'reselect';
import { leaveTeam } from '@/utils/SessionUtils';
import { addsToCount } from '@/utils/ReceiptUtils';
import { checkMention } from '@/utils';
import ContactList from '@/connectHOCs/Contacts/ContactList';
import UnreadMessagesProxy from '@/Proxies/UnreadMessagesProxy';
import TeamButton from '@/components/teamSwitcher/teamButton/TeamButton';
import { allUnreadMessagesSelector, allMessagesSelector } from '@/selectors';
import ContactProxy from '@/Proxies/ContactProxy';

class TeamButtonContainer extends Component {
  constructor(props) {
    super(props);
    const { ownerGuid, unreadPeerJids } = props;
    this.unreadMessagesSubscription = UnreadMessagesProxy.subscribe(ownerGuid);
    this.subscribeToContactProxy(unreadPeerJids);
  }

  componentWillUnmount() {
    this.unreadMessagesSubscription.unsubscribe();
    if (this.contactProxySubscription) {
      this.contactProxySubscription.unsubscribe();
    }
  }

  componentDidUpdate() {
    const { unreadPeerJids } = this.props;
    this.subscribeToContactProxy(unreadPeerJids);
  }

  onLeaveTeamClick = (sessionId) => {
    leaveTeam(sessionId);
  };

  subscribeToContactProxy = memoize((unreadPeerJids) => {
    const { ownerGuid } = this.props;
    this.contactProxySubscription = ContactProxy.subscribe(
      ownerGuid,
      unreadPeerJids
    );
  });

  categorizeUnreadMessages = memoize(
    (allMessages, unreadPeerContacts, unreadMessageIds) => {
      const unreadPeerSet = new Set();
      const unreadMutedPeerSet = new Set();
      const unreadPeerContactsMap = new Map();
      unreadPeerContacts.forEach((contact) =>
        unreadPeerContactsMap.set(contact.jid, contact)
      );

      let hasMention = false;

      Object.values(unreadMessageIds).forEach((peerMsgs) => {
        let peerHasMention = false;
        peerMsgs.forEach((messageCacheId) => {
          const msg = allMessages[messageCacheId];
          if (msg && addsToCount(msg)) {
            if (!peerHasMention) {
              peerHasMention = checkMention(msg);
            }
            hasMention = hasMention || peerHasMention;
            const peerJid = msg.peer.jid;
            const contact = unreadPeerContactsMap.get(peerJid);
            if (contact && !contact.isDeleted) {
              if (peerHasMention || !contact.isMuted) {
                unreadPeerSet.add(msg.peer.jid);
              } else {
                unreadMutedPeerSet.add(msg.peer.jid);
              }
            }
          }
        });
      });

      return {
        hasMention,
        unreadPeerSet,
        unreadMutedPeerSet,
      };
    }
  );

  render() {
    const {
      allMessages,
      contacts,
      unreadMessageIds,
      ...allPropsOtherThenSubProxy
    } = this.props;

    const unreadSetsData = this.categorizeUnreadMessages(
      allMessages,
      contacts,
      unreadMessageIds
    );

    return (
      <TeamButton
        {...allPropsOtherThenSubProxy}
        hasMention={unreadSetsData.hasMention}
        unreadConversationCount={unreadSetsData.unreadPeerSet.size}
        onLeaveTeamClick={this.onLeaveTeamClick}
        mutedUnreadChatCount={unreadSetsData.unreadMutedPeerSet.size}
      />
    );
  }
}

TeamButtonContainer.propTypes = {
  index: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  ownerGuid: PropTypes.string.isRequired,
  unreadMessageIds: PropTypes.object,
  allMessages: PropTypes.object,
};

TeamButtonContainer.defaultProps = {
  unreadMessageIds: {},
  allMessages: {},
};

const allUnreadWithOwnerGuid = (state, { ownerGuid }) => {
  const allUnreadMessages = allUnreadMessagesSelector(state);
  return allUnreadMessages[ownerGuid];
};

const allMessagesWithOwnerGuid = (state, { ownerGuid }) => {
  const allMessages = allMessagesSelector(state);
  return allMessages[ownerGuid];
};

const getContactIdsFromMessageIds = (unreadMessageIds) => {
  return unreadMessageIds ? Object.keys(unreadMessageIds) : [];
};

const makeGetMessages = () => {
  return createSelector(
    allUnreadWithOwnerGuid,
    allMessagesWithOwnerGuid,
    (unreadMessageIds, allMessages) => {
      const unreadPeerJids = getContactIdsFromMessageIds(unreadMessageIds);
      return {
        unreadMessageIds,
        allMessages,
        unreadPeerJids,
      };
    }
  );
};

const makeMapStateToProps = () => {
  const mapStateToProps = (state, props) => {
    const getMessagesSelector = makeGetMessages();
    return getMessagesSelector(state, props);
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps())(
  ContactList(TeamButtonContainer, 'unreadPeerJids', 'ownerGuid')
);
