export default {
  fileSharing_chatTab_modal_message_fileCountExceedMessage:
    '\u00a1Vaya! No puede subir m\u00e1s de 10 archivos a la vez.',
  fileSharing_chatTab_modal_title_fileLimitExceeded:
    'Se excedi\u00f3 el l\u00edmite del archivo',
  fileSharing_chatTab_modal_message_fileLimitExceed:
    '\u00a1Vaya! El tama\u00f1o de su archivo no debe exceder de {fileUploadLimit} MB',
  fileSharing_chatTab_modal_buttonText_cancelFileUpload:
    'Cancelar la subida del archivo',
  fileSharing_chatTab_modal_buttonText_completeFileUpload:
    'Completar la subida del archivo',
  fileSharing_chatTab_modal_title_fileCountExceeded: 'Demasiados archivos',
  fileSharing_chatTab_modal_message_cancelFileUpload:
    '\u00bfEst\u00e1 seguro de que quiere cancelar la subida?',
  fileSharing_chatTab_modal_title_abortTitle: 'Abortar la subida del archivo',
  fileSharing_chatTab_hover_toolTip: 'Compartir un archivo',
  buttonText_ok: 'OK',
  fileSharing_chatTab_modal_message_invalidFileType:
    'No se pudo procesar la subida del archivo. Por favor, revise lo siguiente: ',
  fileSharing_error_check_folders_cannot_be_uploaded:
    'No se pueden cargar carpetas que no est\u00e9n comprimidas en formato .zip.',
  fileSharing_error_check_connected_to_internet:
    'Aseg\u00farese de que Flock est\u00e9 conectado a internet.',
  fileSharing_chatTab_modal_failureMessage_someFilesFailed:
    'No se enviaron {numFiles} archivos por un problema. Por favor, revise su conexi\u00f3n a Internet e int\u00e9ntelo de nuevo.',
  fileSharing_chatTab_modal_fileShareFailed_title:
    'Fall\u00f3 la transferencia del archivo',
  fileSharing_chatTab_modal_failureMessage_allFilesFailed:
    'No se envi\u00f3 tu archivo por un problema. Por favor, revise su conexi\u00f3n a Internet e int\u00e9ntelo de nuevo.',
  fileSharing_chatTab_modal_message_fileShareDisabledByAdmin:
    'Se cancel\u00f3 el permiso para compartir archivos. Si es necesario, p\u00f3ngase en contacto con su administrador de Flock.',
  fileSharing_chatTab_modal_progressMessage_percentageUploaded:
    '$percentUploaded$% de $totalFileSize$',
  fileSharing_chatTab_modal_filename: 'Nombre del archivo',
  fileSharing_chatTab_modal_title_fileShareDisabledByAdmin:
    'Hay restricci\u00f3n para compartir archivos',
  fileSharing_chatTab_modal_title_uploadImage: 'Subir imagen',
  fileSharing_chatTab_notification_sentFile: 'envi\u00f3 un archivo $filename$',
  fileSharing_chatTab_notification_sentImage:
    'envi\u00f3 una imagen $filename$',
  fileSharing_chatTab_uploadingText_fileUploading: 'Subiendo',
  fileSharing_chatTab_chatTab_multipleFileUploadProgressString:
    '{countDone} de {totalCount} archivos',
  fileSharing_chatTab_chatTab_files: 'archivos',

  chatTab_dropdown_groupMemberList_loadingMemberText:
    'Recuperando los miembros...',
  chatTab_dropdown_groupMemberList_MultipleMembers: '{member_count} miembros',
  chatTab_inputBox_placeholderText_inviteNewMemberToGroup: 'Invitar al canal',
  chatTab_dropdown_groupMemberList_removeMember: 'Eliminar',
  chatTab_dropdown_groupMemberList_SingleMember: '{member_count} miembros',
  text_addNewMemberToGroup: 'Agregar',
  remove_member_ok_text: 'S\u00ed, eliminar',
  remove_member_cancel_text: 'No',
  remove_member_confirm_dialog_text:
    '\u00bfEst\u00e1 seguro de que quiere eliminar a {member_name} de este canal?',
  group_name_placeholder: 'Establecer el nombre del canal',
  group_purpose_placeholder: 'Establecer el prop\u00f3sito del canal',
  title_remove_contact: '\u00bfEliminar contacto?',

  ATTENTION: '\u00a1Atenci\u00f3n!',
  OOPS: '\u00a1Ups!',
  PERMISSION_DETAILS_MESSAGE:
    '\u00a1Un invitado en Flock no puede realizar esta acci\u00f3n!',
  INVITE_PERMISSION_MESSAGE:
    'No tiene permiso para agregar invitados a este equipo. Por favor, p\u00f3ngase en contacto con el administrador de su equipo.',
  PERMISSION_RESTRICTED: 'Permiso restringido',
  LIMITED_PERMISSIONS:
    'Un invitado en Flock solo puede acceder a conversaciones y aplicaciones selectas.',
  PUBLIC_CHANNELS_MESSAGE:
    '\u00a1Ups! Un invitado en Flock no puede ver o crear canales p\u00fablicos en este equipo.',
  ADD_GUEST_TITLE: '\u00bfAgregar un invitado a este canal?',
  ADD_GUEST_DESCRIPTION:
    'Tendr\u00e1n acceso a todos los mensajes y archivos compartidos en el canal.',

  cancel: 'Cancelar',

  chatTab_popup_slashCommand_text_leaveGroupHint: 'abandonar canal',
  chatTab_popup_slashCommand_text_leaveGroupCommand: 'abandonar',
  chatTab_popup_slashCommand_text_cloneGroupHint: 'clonar el canal actual',
  chatTab_popup_text_error_cloneGroupDialog:
    'Lo sentimos, por el momento, no se pueden clonar canales mayores de {count} miembros.',
  chatTab_popup_slashCommand_text_cloneGroupCommand: 'clonar',
  add_popup: 'Agregar',
  add_user_to_channel:
    '\u00bfEst\u00e1 seguro de que quiere agregar a {name} a este canal?',
  title_add_contact: '\u00bfAgregar contacto?',

  editor_messageDropdown_menu_text_copy: 'Copiar',
  editor_messageDropdown_menu_text_edit: 'Editar',
  editor_messageDropdown_menu_text_retry: 'Volver a intentar',
  editor_messageDropdown_popup_text_deleteMessage:
    'Este mensaje ser\u00e1 borrado para usted y el destinatario, y ser\u00e1 borrado permanentemente de nuestros servidores.',
  editor_messageDropdown_popup_title_deleteMessage: '\u00bfBorrar mensaje?',
  editor_messageDropdown_popup_buttonText_deleteMessage_Yes: 'S\u00ed, borrar',
  editor_messageDropdown_popup_buttonText_delete: 'Borrar',
  editor_deletion_failed: 'No est\u00e1 permitida esa acci\u00f3n',
  editor_not_connected_to_internet_error:
    'Al parecer est\u00e1 fuera de l\u00ednea. Normalmente, esto se debe a que no hay conectividad o es muy d\u00e9bil.',
};
