import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tippy from '@tippyjs/react';
import { openUrl } from '@/utils/SessionUtils';
import cssStyles from './MenuDropdown.css';
import UpgradeToProHook from '../upgradeProHook/UpgradeToPro';
import commonStyles from '../common/common.css';

class TeamInfo extends Component {
  constructor(props) {
    super(props);
    this.openUrl = this.openUrl.bind(this);
    // this.openUpgradeUrl = this.openUpgradeUrl.bind(this);
  }

  openUrl() {
    const { session } = this.props;
    const teamUrl = `${session.sessionInfo.teamInfo.teamUrl}.flock.com`;
    openUrl(teamUrl);
  }

  render() {
    const { session, shellLayout } = this.props;
    const { collapsed } = shellLayout.outerLeft;
    const { teamName } = session.sessionInfo.teamInfo;

    const isOddTeam = session.sessionInfo.teamInfo.teamId % 2 !== 0;
    return (
      <div className={cssStyles.dropdown_list_item}>
        <div
          role='menuitem'
          onKeyDown={() => {}}
          tabIndex='-1'
          className={cssStyles.menu_item_info}
          onClick={this.openUrl}
        >
          <div className={cssStyles.menu_item_sub_label}>{teamName}</div>
        </div>

        <UpgradeToProHook session={session} source='menu_header'>
          {({
            isPaidTeam,
            showTrialProHook,
            showUpgradeProHook,
            isSpecialTeam,
            isHardPaywallTrial,
            onClick,
            isMacAppStore,
          }) => {
            if (
              collapsed &&
              session.owner.isAdmin &&
              !isMacAppStore &&
              (isOddTeam || isSpecialTeam)
            ) {
              if (showUpgradeProHook) {
                let tooltip;
                if (isHardPaywallTrial) {
                  tooltip = 'Buy Flock to avoid losing access';
                } else {
                  tooltip = 'Upgrade your team to the PRO plan';
                }
                return (
                  <Tippy
                    className={commonStyles.info_tooltip}
                    content={tooltip}
                    arrow
                    placement='bottom'
                  >
                    <div
                      role='menuitem'
                      onKeyDown={() => {}}
                      tabIndex='-1'
                      className={classNames(
                        cssStyles.menu_header_upgrade_hook,
                        cssStyles.menu_item_icon
                      )}
                      onClick={onClick}
                    >
                      Upgrade to <span>Pro</span>
                    </div>
                  </Tippy>
                );
              }
              if (showTrialProHook) {
                return (
                  <Tippy
                    className={commonStyles.info_tooltip}
                    content='Start a free PRO trial for your team'
                    arrow
                    placement='bottom'
                    delay={[0, 500000]}
                  >
                    <div
                      role='menuitem'
                      onKeyDown={() => {}}
                      tabIndex='-1'
                      className={classNames(
                        cssStyles.menu_header_upgrade_hook,
                        cssStyles.menu_item_icon
                      )}
                      onClick={onClick}
                    >
                      Try <span>Pro</span> for free
                    </div>
                  </Tippy>
                );
              }
            }
            if (isPaidTeam) {
              return (
                <div
                  className={classNames(
                    cssStyles.menu_header_upgrade_hook,
                    cssStyles.menu_item_icon
                  )}
                >
                  <span>Pro</span>
                </div>
              );
            }
            return null;
          }}
        </UpgradeToProHook>
      </div>
    );
  }
}

TeamInfo.propTypes = {
  session: PropTypes.object.isRequired,
  shellLayout: PropTypes.object.isRequired,
};

export default TeamInfo;
