/* eslint-disable import/prefer-default-export, prefer-destructuring, no-prototype-builtins */

/* 
  We want to override bindTooltip method so that it always places tooltip above the element. So here first we calling original module
  and then overriding default bind function with our custom function.
*/

export const Tooltip = (froalaEditor) => {
  const FE =
    froalaEditor && froalaEditor.hasOwnProperty('default')
      ? froalaEditor.default
      : froalaEditor;

  const originalModule = FE.MODULES.tooltip;
  FE.MODULES.tooltip = (editor) => {
    const returnObj = originalModule(editor);
    const originalBindMethod = returnObj.bind;
    const customBindMethod = (arg1, arg2) => {
      originalBindMethod(arg1, arg2, true);
    };
    return {
      ...returnObj,
      bind: customBindMethod,
    };
  };
};
