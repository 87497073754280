import React from 'react';

const COLORS = {
  green: '#0abe51',
  white: '#fff',
};

/**
  Loader with green and white strokes.
*/

export default function Loader({
  color,
  width = '30px',
  height = '30px',
  style = {},
}) {
  const strokeColor = COLORS[color] ? COLORS[color] : COLORS.green;

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 100 100'
      preserveAspectRatio='xMidYMid'
      className='uil-ring-alt'
      style={style}
    >
      <rect x='0' y='0' width='100' height='100' fill='none' className='bk' />
      <circle
        cx='50'
        cy='50'
        r='40'
        stroke='#d7d7d7'
        fill='none'
        strokeWidth='3'
        strokeLinecap='round'
      />
      <circle
        cx='50'
        cy='50'
        r='40'
        stroke={strokeColor}
        fill='none'
        strokeWidth='4'
        strokeLinecap='round'
        className='loader-circler'
      >
        <animate
          attributeName='stroke-dashoffset'
          dur='2s'
          repeatCount='indefinite'
          from='0'
          to='-502'
        />
        <animate
          attributeName='stroke-dasharray'
          dur='2s'
          repeatCount='indefinite'
          values='150.6 100.4;1 250;150.6 100.4'
        />
      </circle>
    </svg>
  );
}
