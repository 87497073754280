import { batch } from 'react-redux';
import bridge from '@/utils/bridge';
import store from '@/store';
import { Actions } from '@/actions/restricted';
import {
  allMessagesByGuidSelector,
  allUnreadMessagesByGuidSelector,
  receiptStateByGuidSelector,
} from '@/selectors';
import { guidFromJid } from '@/utils';
import { isMessageUnreadByMe } from '@/utils/ReceiptUtils';

class ReceiptProxy {
  subscriptions = [];

  constructor() {
    bridge.ask('MessageCache', 'getAllReceiptState').then((allReceipts) => {
      batch(() => {
        Object.keys(allReceipts).forEach((ownerJid) => {
          const sessionReceipts = allReceipts[ownerJid];
          Object.keys(sessionReceipts).forEach((peerJid) => {
            const receiptState = sessionReceipts[peerJid];
            this.handleUpdate(ownerJid, peerJid, receiptState);
          });
        });
      });
    });
    this.subscriptions.push(
      bridge.subscribe(
        '/odara/receipts/update/',
        (ownerJid, peerJid, updatedState) => {
          batch(() => {
            this.handleUpdate(ownerJid, peerJid, updatedState);
          });
        }
      )
    );
  }

  handleUpdate(ownerJid, peerJid, updatedState) {
    const readMessagesCacheIds = this.getReadMessagesCacheIds(
      ownerJid,
      peerJid,
      updatedState
    );
    this.dispatchUpdate(ownerJid, peerJid, updatedState, readMessagesCacheIds);
  }

  getReadMessagesCacheIds = (ownerJid, peerJid, updatedState) => {
    const ownerGuid = guidFromJid(ownerJid);
    const state = store.getState();
    const allMessages = allMessagesByGuidSelector(state)(ownerGuid);
    const unreadMessages = allUnreadMessagesByGuidSelector(state)(ownerGuid);
    const unreadMessagesCacheIdsForPeer = unreadMessages[peerJid];

    const readMessagesCacheIds = [];
    if (!unreadMessagesCacheIdsForPeer) {
      return readMessagesCacheIds;
    }
    unreadMessagesCacheIdsForPeer.forEach((mCId) => {
      const msg = allMessages[mCId];
      if (msg) {
        const unread = this._isMessageUnreadByMe(msg, updatedState);
        if (!unread) {
          readMessagesCacheIds.push(mCId);
        }
      }
    });
    return readMessagesCacheIds;
  };

  dispatchUpdate = (ownerJid, peerJid, updatedState, readMessagesCacheIds) => {
    store.dispatch(
      Actions.update_receipt_state({
        ownerJid,
        peerJid,
        receiptObj: updatedState,
      })
    );
    store.dispatch(
      Actions.mark_unread_messages_as_read({
        ownerGuid: guidFromJid(ownerJid),
        peerJid,
        readMessagesCacheIds,
      })
    );
  };

  _isMessageUnreadByMe = (msg, receiptObj) => {
    if (receiptObj) {
      return isMessageUnreadByMe(receiptObj, msg);
    }
    const {
      ownerGuid,
      peer: { jid: peerJid },
    } = msg;
    const receiptState = receiptStateByGuidSelector(store.getState())(
      ownerGuid
    );
    return isMessageUnreadByMe(receiptState[peerJid], msg);
  };

  unsubscribe() {
    bridge.unsubscribeAll(this.subscriptions);
  }
}

export default new ReceiptProxy();
