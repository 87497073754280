export function getImage(src, isCrossOrigin) {
  return new Promise((resolve, reject) => {
    if (src) {
      const img = new Image();
      if (isCrossOrigin) {
        img.crossOrigin = 'anonymous';
      }
      img.onload = function onLoad() {
        resolve(this);
      };
      img.onerror = function onError(error) {
        reject(error);
      };

      img.src = src;
    }
  });
}

export function getFullContactImageUrl(url) {
  // defination copied from https://github.com/talk-to/hydra/blob/master/src/client_base/util/image.js#L61
  // update src to get the full image .. its of format i.talk.to/guid-full?r=cache-invalidate\
  let first = url.split('?')[0];
  const second = url.split('?')[1];
  first = `${first}-full`;
  const params = [first, second];
  return params.join('?');
}

export const mimeExtMap = {
  'image/jpeg': '.jpg',
  'image/jpg': '.jpg',
  'image/pjpeg': '.jpg',
  'image/svg+xml': '.svg',
  'image/tiff': '.tif',
  'image/png': '.png',
  'image/gif': '.gif',
  'image/bmp': '.bmp',
};

export function getPreviewData(image) {
  // check for thumbnail id as a workaround for issue in history messages
  // where thumbnail object was created without id
  const { thumbnail, original } = image;
  if (thumbnail && (thumbnail.id || !original || !original.id)) {
    return thumbnail;
  }
  return original;
}

export function scale(dimensions, maxWidth, maxHeight) {
  const height = Number(dimensions.height);
  const width = Number(dimensions.width);
  const scaleFactor = Math.min(
    1,
    height / maxHeight >= width / maxWidth
      ? maxHeight / height
      : maxWidth / width
  );
  return {
    height: height ? Math.ceil(height * scaleFactor) : maxHeight,
    width: width ? Math.ceil(width * scaleFactor) : maxWidth,
  };
}

export const fetchDimensions = async (imageSrc) => {
  try {
    const image = await getImage(imageSrc);
    return {
      width: image.width,
      height: image.height,
    };
  } catch (e) {
    console.log('error in image upload', e);
    return {};
  }
};

export const fetchImageDimensionsFromFile = (file) => {
  const fileReader = new FileReader();
  const promise = new Promise((resolve) => {
    fileReader.onload = (e) => {
      const {
        target: { result: imgSrc },
      } = e;
      fetchDimensions(imgSrc).then((dimensions) => {
        resolve(dimensions);
      });
    };
    fileReader.onerror = () => {
      resolve({});
    };
  });
  fileReader.readAsDataURL(file);
  return promise;
};
