/* eslint-disable import/prefer-default-export, prefer-destructuring, no-prototype-builtins */

export const CustomShortcuts = (froalaEditor) => {
  const FE =
    froalaEditor && froalaEditor.hasOwnProperty('default')
      ? froalaEditor.default
      : froalaEditor;

  FE.MODULES.CustomShortcuts = (editor) => {
    function _init() {
      const originalCursorEnter = editor.cursor.enter;
      editor.cursor.enter = () => {
        originalCursorEnter(true); // always use BR when creating new line
      };
      editor.customShortcutMap = {};
      editor.registerShortcut = registerShortcut;
      editor.events.on('keydown', onkeydown, true);
    }

    const registerShortcut = (
      keyCode: number,
      shiftKey: boolean,
      altKey: boolean,
      cb: Function,
      metaKey: boolean = true
    ) => {
      const shortcutKey = getShortcutKey(keyCode, shiftKey, altKey, metaKey);
      editor.customShortcutMap[shortcutKey] = cb;
    };

    const getShortcutKey = (
      keyCode: number,
      shiftKey: boolean,
      altKey: boolean,
      metaKey: boolean = true
    ) => {
      const key = `${metaKey ? '$' : ''}${shiftKey ? '^' : ''}${
        altKey ? '@' : ''
      }${keyCode}`;
      return key;
    };

    const onkeydown = (event) => {
      const { which, shiftKey, altKey } = event;
      const metaKey = editor.helpers.isMac() ? event.metaKey : event.ctrlKey;
      const shortcutKey = getShortcutKey(which, shiftKey, altKey, metaKey);
      if (shortcutKey && editor.customShortcutMap[shortcutKey]) {
        const cb = editor.customShortcutMap[shortcutKey];
        const returnValue = cb(event);
        if (returnValue === false) return false;
      }
      if (which === editor.FROALA.KEYCODE.DELETE && shiftKey) {
        if (!editor.selection.isCollapsed()) {
          event.preventDefault();
          document.execCommand('cut');
        }
        return false;
      }
      return true;
    };
    return {
      _init,
    };
  };
};
