import React from "react";
import ReactDOM from "react-dom";
import { default as categoryList } from "emoji-data/data/categories.json";
import { App } from "./components/app";
import { StoreProvider } from "./store";
import { Convertor } from "../convertor";
import { version } from "../../package.json";

export const initialProps = {
  convertor: null,
  disableDetail: false,
  disableSearch: false,
  disableTabs: false,
  onEmoji: () => {},
  onSticker: () => {},
  onSkin: () => {},
  recents: [],
  skin: 1,
  stickers: [],
  theme: { name: "", colors: {} }
};

export class Picker {
  version = version;

  constructor(props = {}) {
    this.props = { ...initialProps, ...props };
    if (props.convertor && props.convertor.version === this.version) {
      this.convertor = props.convertor;
    } else {
      this.convertor = new Convertor();
    }
  }

  placeAt(container) {
    if (!container) {
      throw new Error("Emoji picker needs a container when rendering.");
    }

    if (container.nodeType && container.nodeType === Node.ELEMENT_NODE) {
      this.container = container;
      const emojis = this._getEmojiList();
      ReactDOM.render(
        <StoreProvider>
          <App
            {...this.props}
            convertor={this.convertor}
            emojis={emojis}
            register={this._register}
            stickers={this.props.stickers}
          />
        </StoreProvider>,
        container
      );
    } else {
      throw new Error("Emoji picker needs a container when rendering.");
    }
  }

  destroy() {
    if (this.container) {
      ReactDOM.unmountComponentAtNode(this.container);
    }
  }

  reset() {
    if (!this.picker) return;
    this.picker.reset();
  }

  setRecents(recents) {
    if (!this.picker) return;
    this.picker.setRecents(recents);
  }

  setSkin(skin) {
    if (!this.picker) return;
    this.picker.setSkin(skin);
  }

  setTheme(theme) {
    if (!this.picker) return;
    this.picker.setTheme(theme);
  }

  setVendor(vendor) {
    if (!this.picker) return;
    this.picker.setVendor(vendor);
  }

  _getEmojiList() {
    if (!this._emojisList) {
      this._emojisList = categoryList.map(category => {
        return {
          ...category,
          emojis: this.convertor.listBy(category.id)
        };
      });
    }
    return this._emojisList;
  }

  _register = picker => {
    this.picker = picker;
  };
}
