import { logMedusaGenericEvent } from '../logAnalytics';

function convertToSeconds(time) {
  return (time / 1000).toFixed(2);
}
/**
 * The `PerformanceLogger` utility lets us measure durations between two points in time using start() and end().
 */
class PerformanceLogger {
  markers = {};

  /**
   * Add the start marker.
   * @param {string} eventName This will be used to report durations in Medusa.
   * @param {string} id The identifier for the event. The marker name needs to be unique across the application.
   */
  start(eventName, id) {
    if (this.markers[eventName]?.[id]) {
      // This marker exists. Which means, we don't mark again.
      console.warn(
        `Marker for eventName: ${eventName} and id: ${id} already exists`
      );
    } else {
      if (!this.markers[eventName]) {
        this.markers[eventName] = {};
      }
      this.markers[eventName][id] = id;
      performance.mark(`${eventName}-start`);
    }
  }

  /**
   * Adds the end marker and logs event to console and Medusa.
   * @param {string} eventName The same eventName that was used to add the start marker
   * @param {string} id The same id that was used to add the start marker
   */
  end(eventName, id) {
    if (this.markers[eventName]?.[id]) {
      const endMarker = `${eventName}-end`;
      performance.mark(endMarker);

      const startMarker = `${eventName}-start`;
      const measureName = `measure-${eventName}`;
      performance.measure(measureName, startMarker, endMarker);

      const performanceMeasures = performance.getEntriesByName(measureName);

      const { startTime, duration } = performanceMeasures[0];
      logMedusaGenericEvent(eventName, {
        id,
        startTime: convertToSeconds(startTime),
        duration: convertToSeconds(duration),
      });
      console.log(`${eventName}: ${convertToSeconds(duration)}s`);
      performance.clearMarks(startMarker);
      performance.clearMarks(endMarker);
      performance.clearMeasures(measureName);
      delete this.markers[eventName][id];
      if (Object.keys(this.markers[eventName]).length > 0) {
        console.error(
          `Certain perf events for ${eventName} had started but have not yet ended`,
          this.markers[eventName]
        );
        // Clearing the markers to avoid bloat in case of issue.
        delete this.markers[eventName];
      }
    } else {
      console.error(
        `No event found with eventName: ${eventName} and id: ${id}. Are you sure you sent the correct eventName and id?`
      );
    }
  }
}

export default new PerformanceLogger();
