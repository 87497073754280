import { connect } from 'react-redux';
import memoize from 'memoize-one';
import bridge from '@/utils/bridge';
import { GENERAL_STORE as SIDEBAR_STATE_STORE } from '@/utils/storeNames';
import DikeProxy from '@/Proxies/DikeProxy';
import { currentSessionIdSelector, ownerGuidSelector } from '@/selectors';
import Sidebar from '../components/Sidebar';

const IS_CLOSED = 'isclosedapp';
const SIDEBAR_ARGS = 'sidebar_arguments';

const getSidebarState = (sessionId) => {
  return bridge
    .ask('Storage', 'get', [
      SIDEBAR_STATE_STORE,
      `sidebarStateStore_${IS_CLOSED}_${sessionId}`,
    ])
    .then((closed = 'true') => {
      const isClosed = closed && JSON.parse(closed);
      if (!isClosed) {
        return bridge
          .ask('Storage', 'get', [
            SIDEBAR_STATE_STORE,
            `sidebarStateStore_${SIDEBAR_ARGS}_${sessionId}`,
          ])
          .then((app) => {
            console.log('appInfo', app);
            return JSON.parse(app);
          });
      }
      return false;
    });
};

/* Whenever a session is updated */
async function onCurrentSessionChange(currentSessionId) {
  /**
   * Get the order of apps from IndexedDB.
   * Reasons this is in props of Sidebar instead of state:
   * 1. Best place to make API calls is actions
   * 2. We need to get the order of apps every time session is updated, which is this method
   */
  if (currentSessionId) {
    const orderedAppIds = await DikeProxy.getAppsOrder(currentSessionId);
    const ownerGuid = currentSessionId.match(/session_(.*)@go.to/)[1];
    DikeProxy.saveAppsOrder(orderedAppIds, ownerGuid);
  }
}

/**
 * Save the apps after drag and drop into the indexedDB and redux store
 * @param {string} sessionId The current sessionId of team
 * @param {*} orderedAppIds The latest order of the apps after drag and drop
 */
function saveAppsOrder(sessionId, orderedAppIds) {
  const ownerGuid = sessionId.match(/session_(.*)@go.to/)[1];
  DikeProxy.saveAppsOrder(orderedAppIds, ownerGuid);
}

function setSidebarState(sessionId, isClosed, app) {
  bridge.tell('Storage', 'set', [
    SIDEBAR_STATE_STORE,
    `sidebarStateStore_${IS_CLOSED}_${sessionId}`,
    JSON.stringify(isClosed),
  ]);
  if (!isClosed) {
    bridge.tell('Storage', 'set', [
      SIDEBAR_STATE_STORE,
      `sidebarStateStore_${SIDEBAR_ARGS}_${sessionId}`,
      JSON.stringify(app),
    ]);
  }
}

/**
 * Dispatch action creators to props of component
 * @param {function} dispatch
 * Always implement the following action creators:
 * init - listen to subscribers, some setup etc.
 */
const mapDispatchToProps = () => ({
  onCurrentSessionChange,
  getSidebarState,
  saveAppsOrder,
  setSidebarState,
});

const memoizedBadges = memoize((badges) => badges.map((b) => b.appId));

const defaultOrder = [];
const defaultBadges = [];
const defaultDikeStore = {};

const mapStateToProps = (state) => {
  const sessionId = currentSessionIdSelector(state);
  const ownerGuid = ownerGuidSelector(state);
  const {
    dike: {
      [ownerGuid]: {
        badges = defaultBadges,
        appsOrder = defaultOrder,
      } = defaultDikeStore,
    },
  } = state.restricted;
  return {
    currentSessionId: sessionId,
    badges: memoizedBadges(badges),
    appsOrder,
    // filtered appList will come in props from appListHOC
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
