import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { getOpenGroupCount, openJoinPublicChannel } from '@/utils/ShellUtils';
import bridge from '@/utils/bridge';

function onClick(e, source) {
  e.stopPropagation();
  openJoinPublicChannel(source);
}

function useFetchCount() {
  const [openGroups, setOpenGroupCount] = useState(null);
  useEffect(() => {
    async function fetchCount() {
      const response = await getOpenGroupCount();
      if (response) {
        setOpenGroupCount(response < 100 ? response : '99+');
      }
    }
    const debouncedFetch = debounce(fetchCount, 10000);
    const subscriptions = [
      bridge.subscribe('/contact/new/group', debouncedFetch),
      bridge.subscribe('/contact/update/group', debouncedFetch),
      bridge.subscribe('/contact/delete/group', debouncedFetch),
    ];
    fetchCount();
    return () => {
      bridge.unsubscribeAll(subscriptions);
      debouncedFetch.cancel();
    };
  }, []);
  return openGroups;
}

export default function OpenGroupCount({ children }) {
  const openGroups = useFetchCount();
  return children({ openGroups, onClick });
}
