import React from 'react';
import Tippy from '@tippyjs/react';
import { openRequestFeatureLink, openSupportLink } from '@/utils/ShellUtils';
import commonStyles from '@/components/common/common.css';
import { RequestAFeatureIcon, HelpIcon } from './Icons';
import css from './SupportHooks.css';

function SupportHooks() {
  return (
    <div className={css.Wrapper}>
      <Tippy
        maxWidth={200}
        content='Request a feature'
        arrow
        theme='bootstrap'
        placement='right'
        className={commonStyles.info_tooltip}
      >
        <div
          className={`${css.RequestAFeature} ${css.Item}`}
          onClick={openRequestFeatureLink}
          onKeyPress={() => {}}
          tabIndex={0}
          role='button'
          data-pendo-hook='request-feature-bl'
        >
          <RequestAFeatureIcon bulbClassName={css.bulb} />
          Request
        </div>
      </Tippy>
      <Tippy
        maxWidth={200}
        content='Help'
        arrow
        theme='bootstrap'
        placement='right'
        className={commonStyles.info_tooltip}
      >
        <div
          className={`${css.Help} ${css.Item}`}
          onClick={openSupportLink}
          onKeyPress={() => {}}
          tabIndex={0}
          role='button'
          data-pendo-hook='help-bl'
        >
          <HelpIcon borderClassName={css.Helpborder} />
          Help
        </div>
      </Tippy>
    </div>
  );
}

export default SupportHooks;
