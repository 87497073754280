import { connect } from 'react-redux';
import { createSelector } from 'reselect';

const defaultBuddyIds = [];

const allBuddiesSelectorFactory = (ownerGuidProp) => {
  return createSelector(
    (state) => state.restricted.contact.all_buddies_meta_proxy,
    (state, props) => props[ownerGuidProp],
    (allBuddies, ownerGuid) => ({
      allBuddiesIds: allBuddies[ownerGuid] || defaultBuddyIds,
    })
  );
};

export default function (Component, ownerGuidProp = 'ownerGuid') {
  return connect(allBuddiesSelectorFactory(ownerGuidProp))(Component);
}
