import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import bridge from '@/utils/bridge';
import chatStateProxy from '@/Proxies/ChatStateProxy';
import withCurrentSession from '@/connectHOCs/Sessions/currentSession';
import { CHAT_STATE } from '@/utils/constants';

import { ownerGuidSelector } from '@/selectors';
import FileUploadProgress from './FileUploadProgress';
import TypingIndicator from './TypingIndicator';
import NotificationIndicator from './NotificationIndicator';
import DndIndicator from './DndIndicator';

import css from './EditorIndicators.css';

function subscribeToLastSeen(peer) {
  if (!peer.isBot && !peer.isGroup) {
    bridge.tell('LastSeenAt', 'subscribeLastSeen', [peer.jid, peer.ownerGuid]);
  }
}

function unsubscribeLastSeen() {
  bridge.tell('LastSeenAt', 'unsubscribeLastSeen');
}

class EditorIndicators extends React.PureComponent {
  static propTypes = {
    peer: PropTypes.object.isRequired,
    chatState: PropTypes.object,
    contacts: PropTypes.object,
  };

  static defaultProps = {
    chatState: null,
    contacts: {},
  };

  subs = [];

  state = {
    fileUploadStatus: 'SUCCESS',
    muted: false,
    mutedDurationInMinutes: 0,
  };

  constructor(props) {
    super(props);

    const { peer } = this.props;

    chatStateProxy
      .subscribe(peer)
      .then((subscription) => {
        this._chatStateProxySub = subscription;
      })
      .catch((error) => {
        console.error(
          'EditorIndicators.ctor: Error subscribing for chat states:',
          error
        );
      });

    subscribeToLastSeen(peer);
  }

  updateFileProgressState = ({ peerJid, status }) => {
    const { peer } = this.props;

    if (peer.jid !== peerJid) {
      return;
    }

    this.setState({
      fileUploadStatus: status || 'SUCCESS',
    });
  };

  updateLastSeenState = (jid, lastSeen) => {
    const { peer } = this.props;
    const { mute: muted, mutedTime: mutedDurationInMinutes } = lastSeen;

    if (peer.jid === jid) {
      this.setState({
        muted,
        mutedDurationInMinutes:
          mutedDurationInMinutes > 0 ? mutedDurationInMinutes : 0,
      });
    }
  };

  mapParticipants = (participantIds, contacts) => {
    return participantIds.map((id) => contacts[id]).filter(Boolean);
  };

  componentDidMount() {
    this.subs.push(
      bridge.subscribe(
        'MultiFileUpload/Progress',
        this.updateFileProgressState
      ),
      bridge.subscribe('lastSeen/update', this.updateLastSeenState)
    );
  }

  async componentDidUpdate(prevProps) {
    const { peer: newPeer } = this.props;
    const { peer: oldPeer } = prevProps;

    if (oldPeer.jid !== newPeer.jid) {
      this._chatStateProxySub.unsubscribe();

      this._chatStateProxySub = await chatStateProxy.subscribe(newPeer);

      this.setState({
        muted: false,
        mutedDurationInMinutes: 0,
      });

      unsubscribeLastSeen();
      subscribeToLastSeen(newPeer);
    }
  }

  getComponentToRender() {
    const { peer, chatState, contacts } = this.props;
    const { fileUploadStatus, muted, mutedDurationInMinutes } = this.state;

    if (fileUploadStatus === 'UPLOADING') {
      return (
        <FileUploadProgress peerJid={peer.jid} className={css.ProgressBar} />
      );
    }

    if (!peer.isBot && !peer.isGroup && muted) {
      return (
        <DndIndicator
          fullName={peer.name}
          mutedDurationInMinutes={mutedDurationInMinutes}
        />
      );
    }

    if (chatState?.status && chatState.status !== CHAT_STATE.INACTIVE) {
      return (
        <TypingIndicator
          status={chatState.status}
          participants={
            peer.type === 'group'
              ? this.mapParticipants(chatState.participants, contacts)
              : [peer]
          }
        />
      );
    }

    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  getFloatingComponentsToRender() {
    const { peer } = this.props;

    return <NotificationIndicator peer={peer} />;
  }

  render() {
    return (
      <div className={css.EditorIndicators}>
        {this.getFloatingComponentsToRender()}
        <div className={css.FixedIndicatorsContainer}>
          {this.getComponentToRender()}
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    bridge.unsubscribeAll(this.subs);
    // eslint-disable-next-line no-unused-expressions
    this._chatStateProxySub?.unsubscribe();
    unsubscribeLastSeen();
  }
}

function mapStateToProps(state, props) {
  const {
    restricted: { _session: session, contact },
  } = state;
  const { peer } = props;
  const ownerGuid = ownerGuidSelector(state);

  return {
    chatState:
      session && peer
        ? _get(session, ['chat_state', ownerGuid, peer.jid])
        : null,
    contacts: contact.contact_proxy[ownerGuid],
  };
}

export default compose(
  withCurrentSession,
  connect(mapStateToProps)
)(EditorIndicators);
