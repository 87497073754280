import extend from 'lodash/extend';

const backstop = {};
export default {
  generateClaimToken(appid) {
    return `${Math.random()}_${appid}`;
  },
  _getPeerOwnerContextProps(peer, owner) {
    const obj = {};
    if (peer) {
      obj.chatName = peer.name;
      obj.chat = `${peer.type === 'group' ? 'g' : 'u'}:${
        peer.jid.split('@')[0]
      }`;
    }
    obj.userName = owner.name;
    // attach locale here as it is a common point for both flockevent and sendToAppService
    obj.locale = 'en';
    return obj;
  },
  _genContextFlockEventProps(peer, owner) {
    const obj = this._getPeerOwnerContextProps(peer, owner);
    obj.userId = `u:${owner.jid.split('@')[0]}`;
    return obj;
  },
  /**
   *
   * @param url {String}
   * @param hash {String}
   * @returns {String}
   */
  setHashInUrl: function setHashInUrl(cUrl, hash) {
    // existing hash will get replaced
    let url = cUrl;
    let a = document.createElement('a');
    a.href = url;
    a.hash = hash;
    url = a.href;
    a = null;
    return url;
  },
  // while using platformApp's utillity methods it is imp to ensure to have correct data in claim token
  // store. i.e if call is done from conversation and iframe listener is in shell, then shell's
  // pApp.js claimTokenStore should have the relevant data
  /**
   *
   * @param {String} url The base URL of the app that you're going to open
   * @param {String} eventToken The Flock eventToken
   * @param {Object} peer The peer object to provide the context of a conversation
   * @param {Object} owner The current user who is opening the app
   * @param {String} placement Sidebar or Modal
   * @param {String|Object} customFlockEventParams Not sure what this is
   * @param {Object} context
   * @param {*} claimToken
   * @param {*} override
   * @param {*} hash
   */
  addContextParametersToUrl(
    url,
    eventToken,
    peer,
    owner,
    placement,
    customFlockEventParams,
    context,
    claimToken,
    override,
    hash
  ) {
    if (!context) {
      // if context is false do not add ANYTHING to url
      return url;
    }
    const obj = {
      flockClient: 'desktop',
      flockEventToken: eventToken,
      flockWidgetType: placement,
      flockClaimToken: claimToken,
    };
    if (override) {
      obj.flockEvent = JSON.stringify(customFlockEventParams);
    } else {
      const flockEventObj = extend(
        {},
        customFlockEventParams,
        this._genContextFlockEventProps(peer, owner)
      );
      obj.flockEvent = JSON.stringify(flockEventObj);
    }
    if (window.CURRENT_THEME) {
      obj.flockTheme = JSON.stringify(window.CURRENT_THEME);
    }
    const qString = this.objectToQuery(obj);
    // the check against false string would hopefully not be required in future after move to
    // json responses for door.
    /* eslint-disable no-param-reassign */
    if (!hash || hash === 'false') {
      url += url.indexOf('?') === -1 ? '?' : '&';
      url += qString;
    } else {
      url = this.setHashInUrl(url, qString);
    }
    /* eslint-enable no-param-reassign */
    return url;
  },
  addContextParametersToDataset: (
    element,
    eventToken,
    peer,
    owner,
    customFlockEventParams,
    claimToken
  ) => {
    element.dataset.flockClient = 'desktop';
    if (window.CURRENT_THEME) {
      element.dataset.flockTheme = JSON.stringify(window.CURRENT_THEME);
      if (element.ownerDocument) {
        const doc = element.ownerDocument;
        const { head } = doc;
        const { colors } = window.CURRENT_THEME;

        try {
          const style = doc.createElement('style');
          style.type = 'text/css';
          style.id = 'theme';
          const cssStyles = `body { color:${colors[`primary-text`]}; }
                    .light { color:${colors[`secondary-text`]}; }
                    a, a:link, a:visited, a:hover { color:${colors.unread}; }`;
          style.appendChild(document.createTextNode(cssStyles));
          head.appendChild(style);
        } catch (e) {
          console.error('inline html css theme', e);
        }
      }
    }
    if (eventToken) {
      element.dataset.flockEventToken = eventToken;
    }
    if (claimToken) {
      element.dataset.flockClaimToken = claimToken;
    }
    if (customFlockEventParams) {
      // const flockEventObj = extend({}, customFlockEventParams, this._genContextFlockEventProps(peer, owner));
      element.dataset.flockEvent = JSON.stringify(customFlockEventParams);
    }
  },

  objectToQuery(/* Object */ map) {
    const enc = encodeURIComponent;
    const pairs = [];
    Object.keys(map).forEach((name) => {
      const value = map[name];
      if (value !== backstop[name]) {
        const assign = `${enc(name)}=`;
        pairs.push(assign + enc(value));
      }
    });
    return pairs.join('&'); // String
  },
};
