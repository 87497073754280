import React, { PureComponent } from 'react';
import Tippy from '@tippyjs/react';
import currentSessionHOC from '@/connectHOCs/Sessions/currentSession';
import { createGroupAndOpen } from '@/utils/ConversationUtils';
import bridge from '@/utils/bridge';
import css from './AddMemberButton.css';
import ActionButtonCss from '../ActionButtons.css';

class AddMemberButton extends PureComponent {
  static defaultProps = {
    peer: undefined,
    currentSession: undefined,
  };

  tooltipText = 'Add others to this conversation';

  onClick = () => {
    const { peer, currentSession } = this.props;
    bridge.publish('/log_analytics/medusa/helper/chat_open_started', [
      peer.jid,
    ]);
    createGroupAndOpen([peer], currentSession.id, 'chat_add_member');
  };

  render() {
    return (
      <Tippy maxWidth={200} content={this.tooltipText} arrow>
        <span
          className={`${ActionButtonCss.Item} ${css.AddMemberMask}`}
          onClick={this.onClick}
          onKeyPress={() => {}}
          tabIndex={-1}
          role='button'
          data-pendo-hook='header-add-to-conversation'
        />
      </Tippy>
    );
  }
}

export default currentSessionHOC(AddMemberButton);
