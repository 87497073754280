import bridge from '@/utils/bridge';
import ContactProxy from '@/Proxies/ContactProxy';
import { CHAT_STATE } from '@/utils/constants';
import store from '@/store';
import { Actions } from '@/actions/restricted';
import { getConversationIdentity } from '@/utils/ConversationUtils';

class ChatStateProxy {
  // Keep tracks of count of subscriptions to a given peer's chat state.
  _numSubscribers = {};

  // Keeps track of the actual subscription handle returned by bridge.subscribe.
  // for each peer.
  _chatStateSubscriptions = {};

  // Keeps track of subscriptions to contact proxy that are used to fetch the details
  // of the correspinding IDs we receive.
  _contactProxySubscriptions = {};

  /**
   * Subscribes for chat state updates.
   *
   * @param {*} peer
   */
  async subscribe(peer) {
    await this._addSubscription(peer);

    return {
      unsubscribe: this.unsubscribe.bind(this, peer),
    };
  }

  /**
   * Keeps track of subscriptions for a given session and a conversation.
   * Updates `_numSubscribers` and `_chatStateSubscriptions` with the current
   * count and the handles returned from `bridge.subscribe()`.
   *
   * We only subscribe once for given parameters and update the store while they
   * are in use.
   *
   * @param {*} peer
   */
  async _addSubscription(peer) {
    const key = this._getKey(peer);

    if (this._numSubscribers[key]) {
      this._numSubscribers[key] += 1;
      return;
    }

    this._numSubscribers[key] = 0;

    const subscription = await bridge.subscribe(
      `/hydra/chat_states/${peer.ownerGuid}/${peer.jid}`,
      this._updateChatState.bind(this, peer)
    );

    this._chatStateSubscriptions[key] = subscription;
    this._numSubscribers[key] += 1;

    this._updateChatState(peer);
  }

  /**
   * Gets a key to keep track of subscriptions.
   *
   * Format for a key is `<session_id>/<peer_jid>`.
   *
   * @param {*} peer
   *
   * @returns string
   */
  // eslint-disable-next-line class-methods-use-this
  _getKey(peer) {
    return `${peer.ownerGuid}/${peer.jid}`;
  }

  /**
   * Handle to `bridge.subscribe` that's triggered whenever
   * chat state changes for a given conversation. The updated
   * state is persisted to the store.
   *
   * @param {*} peer
   */
  async _updateChatState(peer) {
    const key = this._getKey(peer);

    const jids = await bridge.ask(
      `Conversation_${getConversationIdentity(peer)}`,
      'getComposingJids'
    );

    if (this._contactProxySubscriptions[key]) {
      this._contactProxySubscriptions[key].unsubscribe();
    }

    this._contactProxySubscriptions[key] = ContactProxy.subscribe(
      peer.ownerGuid,
      jids
    );

    if (jids.length > 0) {
      this._updateStore(peer, CHAT_STATE.COMPOSING, jids);
    } else {
      this._updateStore(peer);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  _updateStore(peer, status = CHAT_STATE.INACTIVE, participants = []) {
    store.dispatch(
      Actions.update_chat_state({
        ownerGuid: peer.ownerGuid,
        jid: peer.jid,
        status,
        participants,
      })
    );
  }

  // eslint-disable-next-line class-methods-use-this
  _removeFromStore({ ownerGuid, jid }) {
    store.dispatch(Actions.remove_chat_state({ ownerGuid, jid }));
  }

  /**
   * Decrements the count and removes the handler if nothing is
   * using the subscription (i.e, count === 0). Also cleans up
   * the keys from the store.
   *
   * @param {*} peer
   */
  unsubscribe(peer) {
    const key = this._getKey(peer);

    if (!this._numSubscribers[key]) {
      return;
    }

    if (this._numSubscribers[key] > 0) {
      this._numSubscribers[key] -= 1;
    }

    if (this._numSubscribers[key] <= 0) {
      bridge.unsubscribe(this._chatStateSubscriptions[key]);
      this._contactProxySubscriptions[key].unsubscribe();

      this._removeFromStore(peer);
      this._numSubscribers[key] = 0;
      delete this._numSubscribers[key];
      delete this._chatStateSubscriptions[key];
      delete this._contactProxySubscriptions[key];
    }
  }
}

export default new ChatStateProxy();
