/**
 * This module exports a pub-sub system for us to control the focus of
 * `<ConversationEditor>`. Currently, we publish only in `<EditMessageComposer>`
 * where if the composer is closed, we need to set the focus to the main
 * conversation editor.
 *
 * NOTE: There are multiple scenarios where while/after performing on action,
 * the editor focus must be restored. This is hard to do by other idiomatic
 * means and an overkill probably would be to lift the focus state upto redux.
 * We need to see if we can manage this in a nice, documented and sensible
 * manner so that we can have control of our state and keep it clean when it
 * comes to the code.
 */

import bridge from '@/utils/bridge';

export function focus() {
  bridge.publish('editor/focus');
}

export function listen(fn: () => void) {
  const subscription = bridge.subscribe('editor/focus', fn);

  return () => {
    bridge.unsubscribeAll([subscription]);
  };
}
