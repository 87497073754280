import React, { useState, useEffect } from 'react';
import {
  getImageUrlFromReplyMessage,
  getDescriptionOfReplyMessage,
} from '@/utils/MessageReply';
import { getSignedDownloadUrl } from '@/utils/ShellUtils';
import EmojiDecorator from '@/components/common/EmojiDecorator';
import { Noop } from '../Types';
import CSSStyles from './MessageReply.css';

type Props = {
  message: Message;
  cancelReplyMessage: Noop;
  MAX_COUNT: number;
  currentSessionId: string;
};

const MessageReply = (props: Props) => {
  const { message, cancelReplyMessage, MAX_COUNT, currentSessionId } = props;
  const {
    sender: { name: senderName },
  } = message;
  const [signedUrlOfThumbnail, setSignedUrlOfThumbnail] = useState<
    string | null
  >(null);

  useEffect(() => {
    setSignedUrlOfThumbnail(null);
    const img = getImageUrlFromReplyMessage(message);
    if (img) {
      getSignedDownloadUrl(img, currentSessionId, false).then(
        (signedUrl: string) => {
          setSignedUrlOfThumbnail(signedUrl);
        }
      );
    }
  }, [message, currentSessionId]);

  function onErrorOfImgThumbnail() {
    setSignedUrlOfThumbnail(null);
  }

  function getLimitedText() {
    const text = getDescriptionOfReplyMessage(message, MAX_COUNT);
    return <EmojiDecorator text={text} />;
  }

  return (
    <div className={CSSStyles.wrapper}>
      <div className={CSSStyles.details}>
        <div className={`${CSSStyles.messageSenderName} ${CSSStyles.message}`}>
          <EmojiDecorator text={senderName} />
        </div>
        <div className={`${CSSStyles.messageText} ${CSSStyles.message}`}>
          {getLimitedText()}
        </div>
      </div>
      <div className={CSSStyles.flexContainer}>
        {signedUrlOfThumbnail ? (
          <img
            className={CSSStyles.messageThumbnail}
            src={signedUrlOfThumbnail}
            alt='null'
            width='60'
            height='30'
            onError={onErrorOfImgThumbnail}
          />
        ) : null}
        <div
          className={CSSStyles.cancelIcon}
          onClick={cancelReplyMessage}
          onKeyDown={() => {}}
          tabIndex={-1}
          role='button'
        />
      </div>
    </div>
  );
};

export default MessageReply;
