export default {
  recent_contacts: 'Recent Direct Messages',
  recent_groups: 'Recent Channels',
  create_group: 'New Channel',
  invite_contact: 'Invite',
  invite_contact_button: 'Invite',
  group_tab_search_placeholder: 'Search for Channels',
  all_contact_tab_search_placeholder: 'Search for Contacts',
  your_groups: 'Your Channels',
  all_contacts: 'All Contacts',
  join_group_button: 'Join Channels',
  create_group_button: 'Create Channels',
  no_result_found: 'No result found',
  join_group_description: 'Discover and join channels in your team',
  create_group_description: 'Create a new channel',
  invite_contact_description: 'Invite members to your team',
  guest_badge: '(guest)',
};
