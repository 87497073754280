import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { allSessionIdsSelector } from '@/selectors';
import SessionProxy from '@/Proxies/SessionProxy';
import AllSessionsProxy from '@/Proxies/AllSessionsProxy';

const mapStateToProps = (state) => ({
  allSessionIds: allSessionIdsSelector(state),
});
export default function (ContainedComponent) {
  class AllSessionsSubHOC extends Component {
    static propTypes = {
      allSessionIds: PropTypes.array.isRequired,
    };

    componentDidMount() {
      const { allSessionIds = [] } = this.props;
      this.sessionProxySubscription = SessionProxy.subscribe(allSessionIds);
      this.allSessionProxySubscription = AllSessionsProxy.subscribe();
    }

    componentDidUpdate() {
      const { allSessionIds = [] } = this.props;
      this.sessionProxySubscription.update(allSessionIds);
    }

    componentWillUnmount() {
      this.sessionProxySubscription.unsubscribe();
      this.sessionProxySubscription = null;

      this.allSessionProxySubscription.unsubscribe();
      this.allSessionProxySubscription = null;
    }

    render() {
      return <ContainedComponent {...this.props} />;
    }
  }

  return connect(mapStateToProps)(AllSessionsSubHOC);
}
