import React from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import Tippy from '@tippyjs/react';
import { followCursor } from 'tippy.js';
import Attachment from '@/components/Conversation/Message/Attachment';
import MessageActions from '@/components/Conversation/MessageActions';
import { editedDateString, formatDate } from '@/utils/datetime';
import EditMessageComposer from '@/components/ConversationEditor/EditMessageComposer';
import Receipt from '@/components/common/Receipts';
import commonStyles from '@/components/common/common.css';
import ReadingState from '@/components/common/ReadingState';
import { maybe } from '@/utils';
import bridge from '@/utils/bridge';
import { MESSAGE_READING_STATE, RECEIPT_STATE } from '@/utils/constants';
import { getToolTipText } from '@/utils/ReceiptUtils';
import MessageText from './MessageText';
import GetAppButton from './GetAppButton';
import cssStyles from './Message.css';

const { READ: READ_RECEIPT, SENDING, FAILED } = RECEIPT_STATE;
const { READ } = MESSAGE_READING_STATE;

const toolTipProps = {
  trigger: 'mouseenter',
  maxWidth: 200,
  placement: 'bottom',
  arrow: false,
  followCursor: 'initial',
  animation: 'fade',
  delay: [1000, 0],
  plugins: [followCursor],
};

function getToolTip(type, state) {
  return (
    <div className={cssStyles.receiptToolTip}>
      {getToolTipText(type, state)}
    </div>
  );
}

class ChatMessage extends React.PureComponent {
  state = {
    appStoreUrlList: [],
  };

  messageElement = React.createRef();

  observer = null;

  async componentDidMount() {
    this.fetchAppSuggestions();
    const { onMessageIntersection } = this.props;

    if (!this.messageElement.current) {
      return;
    }

    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => onMessageIntersection(entry));
      },
      {
        root: null,
      }
    );

    this.observer.observe(this.messageElement.current);
  }

  componentDidUpdate({ message: prevMessage }) {
    const { message } = this.props;
    if (maybe(message, 'text') !== maybe(prevMessage, 'text')) {
      this.fetchAppSuggestions();
    }
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.unobserve(this.messageElement.current);
    }
  }

  async fetchAppSuggestions() {
    const { message, currentSessionId } = this.props;
    const res = await bridge.ask(
      'AppSuggestionsController',
      'getAppsForMessage',
      [message, currentSessionId]
    );
    const { appStoreUrlList } = this.state;
    if (res.apps) {
      this.setState({
        appStoreUrlList: res.apps,
        appsProperties: {
          storeKey: res.storeKey,
          type: res.type,
          prop: res.prop,
        },
      });
    } else if (appStoreUrlList.length) {
      this.setState({
        appStoreUrlList: [],
      });
    }
  }

  render() {
    const {
      message,
      currentSession,
      currentPeer,
      currentSessionId,
      isLastMessage,
      showEditState,
      setEditStateToFalse,
      receiptState,
      readingState,
    } = this.props;

    const { appStoreUrlList, appsProperties } = this.state;

    const { peer: { isBot: isPeerBot } = {} } = message || {};
    const showReceipt =
      message.sentByOwner &&
      ([SENDING, FAILED].includes(receiptState) ||
        (!isPeerBot && (isLastMessage || receiptState !== READ_RECEIPT)));
    const showReadingState =
      message.sentByPeer &&
      readingState &&
      (isLastMessage || readingState !== READ);
    const showEditedLabel = !!message.editedOn;

    return (
      <>
        <div
          ref={this.messageElement}
          dir='auto'
          className={`${cssStyles.messageRoot} chat-message`}
          key={message.message_cache_id}
          data-msgroot-id={message.message_cache_id}
          data-msgroot-sid={message.sid}
        >
          {showEditState ? (
            <EditMessageComposer
              message={message}
              onCancelEditMessage={setEditStateToFalse}
            />
          ) : (
            <MessageActions message={message} key={message.id}>
              <>
                <MessageText
                  message={message}
                  currentSessionId={currentSessionId}
                />
                {showEditedLabel && (
                  <span className={cssStyles.editedLabel}>
                    <Tippy
                      className={commonStyles.info_tooltip}
                      content={editedDateString(new Date(message.editedOn))}
                    >
                      <span>
                        (<FormattedMessage id='message_area_edited_label' />)
                      </span>
                    </Tippy>
                  </span>
                )}
                {message.attachments
                  ? message.attachments.map((attachment) => (
                      <Attachment
                        message={message}
                        messageId={message.id}
                        messageSid={message.sid}
                        attachment={attachment}
                        currentPeer={currentPeer}
                        currentSessionId={currentSessionId}
                        currentSessionOwner={currentSession.owner}
                        key={`${attachment.appId}_${attachment.id}`}
                      />
                    ))
                  : null}
                <span
                  // adding no-select class will ignore this element while copying message text
                  className={cn('no-select', cssStyles.messageTime, {
                    [cssStyles.LastMessageTime]: isLastMessage,
                  })}
                >
                  {formatDate(new Date(message.sentTime), 'h:mm a')}
                </span>
                {showReceipt && (
                  <Tippy
                    content={getToolTip('receipt', receiptState)}
                    {...toolTipProps}
                    className={cssStyles.overrideTippyClass}
                  >
                    <Receipt
                      receiptState={receiptState}
                      className={cn(cssStyles.receiptState, {
                        [cssStyles.lastMessageReceipt]: isLastMessage,
                      })}
                    />
                  </Tippy>
                )}
                {showReadingState && (
                  <Tippy
                    content={getToolTip('readingState', readingState)}
                    {...toolTipProps}
                    className={cssStyles.overrideTippyClass}
                  >
                    <ReadingState
                      readingState={readingState}
                      className={cssStyles.dot}
                    />
                  </Tippy>
                )}
              </>
            </MessageActions>
          )}
        </div>
        {appStoreUrlList.length ? (
          <GetAppButton
            appStoreUrlList={appStoreUrlList}
            appsProperties={appsProperties}
            sessionId={currentSessionId}
          />
        ) : null}
      </>
    );
  }
}

export default ChatMessage;
