import { connect } from 'react-redux';

function mapStateToProps(state) {
  const {
    restricted: {
      _session: { currentOwnerGuid: ownerGuid },
    },
  } = state;
  return { ownerGuid };
}

// export default function (component: any) {
export default connect(mapStateToProps);
// }
