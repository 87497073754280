import React, { Component } from 'react';
import classNames from 'classnames';

import getTranslatedString from '@/utils/getTranslatedString';
import prettyFileSize from '@/utils/prettyFileSize';
import { maybe } from '@/utils';
import bridge from '@/utils/bridge';
import noop from '@/utils/noop';
import { sendMessage } from '@/utils/ConversationUtils';
import { getSignedDownloadUrl } from '@/utils/ShellUtils';
import ContactSearch from '@/components/ConversationEditor/ContactSearch/ContactSearch';
import Input from '@/components/Conversation/Header/AddMember/Input';
import ContactPills from '@/components/Search/ContactSearch/SearchInput/ContactPills/ContactPills';
import { Button } from '@/components/common';
import { getContactForId } from '@/Models/Contact';
import { hideModal } from '@/components/Modal/Modal';
import currentSessionIdHOC from '@/connectHOCs/Sessions/currentSession/id';
import PopoverStyleContext from '@/Contexts/EditorPopoverStyle';

import currentSessionHOC from '@/connectHOCs/Sessions/currentSession';

import modalStyles from '@/components/Modal/Modal.css';
import isEmpty from 'lodash/isEmpty';
import extend from 'lodash/extend';
import styles from './ForwardMessage.css';
import translations from './I18n';

type Props = {
  currentSession: {
    owner: {
      ownerGuid: string;
    };
  };
  currentSessionId: string;
  attachment: Attachment;
  message: MessageData;
  title: string;
};

type State = {
  fileName?: string;
  fileSize?: string;
  fileTypeClassname?: string;
  searchTerm?: string;
  selectedContactIds?: Array<string>;
  hasFocus?: boolean;
  imgSrc?: string;
};

type Attachment = {
  title: string;
  appId: string;
  url: string;
  icon: string;
  views: any;
  downloads: any;
};

type MessageData = {
  forwardOf: boolean;
  sid: string;
  notification: any;
  text: string;
};

type Data = {
  icon?: string;
  title?: string;
  fileName?: string;
  size?: number;
  imgObject?: any;
};

class ForwardMessage extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      fileName: null,
      fileSize: null,
      fileTypeClassname: null,
      searchTerm: '',
      selectedContactIds: [],
      hasFocus: false,
      imgSrc: '',
    };
  }

  inputRef = React.createRef<Input>();

  fileNameProperties = (fileName: string) => {
    const extMatch = fileName && /\.([^.]+)$/.exec(fileName);
    const extension = extMatch && extMatch[1].toLowerCase();
    return {
      extension,
      className: extension ? `fileType_${extension}` : '',
    };
  };

  setLinkData = (url: string) => {
    this.setState({
      fileName: url,
      fileTypeClassname: 'link',
    });
  };

  setAppData = (data: Data) => {
    const { title } = data;
    this.setState({
      fileName: title,
      imgSrc: data.icon,
    });
  };

  setFileDetails = (data: Data) => {
    const { fileName, size } = data;
    const { currentSessionId } = this.props;
    const fileSize = prettyFileSize(size);
    const fileTypeClassname = this.fileNameProperties(fileName).className;
    if (!isEmpty(data.imgObject)) {
      getSignedDownloadUrl(data.imgObject, currentSessionId, false).then(
        (src) => {
          this.setState({
            fileName,
            fileSize,
            imgSrc: src,
          });
        }
      );
    } else {
      this.setState({
        fileName,
        fileSize,
        fileTypeClassname,
      });
    }
  };

  getForwardContentDetails = () => {
    const { attachment, currentSessionId } = this.props;
    const content = attachment;

    if (content.url) {
      this.setLinkData(content.url);
    } else if (content.appId && !isEmpty(content.title)) {
      const data = {
        title: content.title,
        appId: content.appId,
        icon: undefined,
      };
      bridge
        .ask('DikeController', 'getAppById', [currentSessionId, data.appId])
        .then((app: { inClientIcon: any }) => {
          if (app) {
            data.icon = app.inClientIcon;
          }
          this.setAppData(data);
        });
    } else if (
      maybe(content, 'views', 'image') ||
      maybe(content, 'downloads', 0)
    ) {
      const imageData = maybe(content, 'views', 'image') || {};
      const original = imageData.original || {};
      const thumbnail = imageData.thumbnail || {};
      const downloads = maybe(content, 'downloads', 0) || {};
      const imgObj = !isEmpty(thumbnail) ? thumbnail : original;

      this.setFileDetails({
        fileName: downloads.filename || imageData.filename,
        size: downloads.size || original.size || thumbnail.size,
        imgObject: imgObj,
      });
    }
  };

  focusInput = (val: boolean) => {
    this.setState({
      hasFocus: val,
    });
    if (val) {
      this.inputRef.current.focus();
    }
  };

  onSearchTermChange = (term: string) => {
    this.setState({
      searchTerm: term,
    });
  };

  onInputBlur = () => {
    this.focusInput(false);
  };

  onInputFocus = () => {
    this.focusInput(true);
  };

  onInputEscape = (e) => {
    const { searchTerm, selectedContactIds } = this.state;
    if (searchTerm !== '' || selectedContactIds.length !== 0) {
      this.setState({ searchTerm: '' });
      this.inputRef.current.blur();
      // Prevent escape from closing conversation
      e.stopPropagation();
    }
  };

  onInputEnter = (e) => {
    const { searchTerm, selectedContactIds } = this.state;
    if (searchTerm === '' && selectedContactIds.length !== 0) {
      this.executeForwardMessage();
    }
  };

  onContactSelect = (contact: { jid: string }) => {
    const { selectedContactIds } = this.state;

    const selectedCIds = [...selectedContactIds];

    if (!selectedCIds.includes(contact.jid)) {
      selectedCIds.push(contact.jid);

      this.setState(
        {
          selectedContactIds: selectedCIds,
          searchTerm: '',
        },
        () => {
          this.inputRef.current.focus();
        }
      );
    }
  };

  renderAttachmentDetails = () => {
    const { fileTypeClassname, fileName, fileSize, imgSrc } = this.state;

    const className = `${styles.fileType_default} ${styles[fileTypeClassname]}`;
    return (
      <div className={styles.file_container}>
        <div className={styles.attachment_icon_wrap}>
          {!imgSrc && <div className={className} />}
          {imgSrc && <img src={imgSrc} alt='' />}
        </div>
        <div className={styles.attachment_details}>
          <div className={styles.file_name}>{fileName}</div>
          {fileSize && <div className={styles.file_size}>{fileSize}</div>}
        </div>
      </div>
    );
  };

  getPopoverPositionStyle = (trigger, popover) => {
    const popoverHeight = popover.getBoundingClientRect().height;
    const input = this.inputRef.current;
    const bodyHeight = document.body.clientHeight;
    const elRects = input && input.element.current.getBoundingClientRect();

    // If bottom has space, show popover below the input
    if (bodyHeight - elRects.bottom > popoverHeight) {
      return {
        // the extra '1' is to compensate for the border width
        top: `${elRects.top + elRects.height + 1}px`,
        left: `${elRects.left}px`,
      };
    }

    // If top has space, show popover above the input
    if (elRects.top > popoverHeight) {
      return {
        // the extra '1' is to compensate for the border width
        bottom: `${bodyHeight - elRects.bottom + elRects.height + 1}px`,
        left: `${elRects.left}px`,
      };
    }

    // otherwise position it to the right of the input
    // so as not to cover the input while typing
    // the extra '1' is to compensate for the border width
    return {
      top: `${elRects.top - popoverHeight / 2}px`,
      left: `${elRects.left + elRects.width + 1}px`,
    };
  };

  contextValue = {
    getPositionStyle: this.getPopoverPositionStyle,
  };

  renderSearchInput = () => {
    const { searchTerm, selectedContactIds, hasFocus } = this.state;

    const showSearchDropdown =
      (searchTerm !== '' || selectedContactIds.length === 0) && hasFocus;

    return (
      <PopoverStyleContext.Provider value={this.contextValue}>
        <ContactSearch
          popoverOpen={showSearchDropdown}
          searchValue={searchTerm}
          searchParams={{
            filters: {
              type: ['buddy', 'email', 'group'],
              isDeactivated: [false, undefined],
            },
            count: 4,
            includeImported: false,
            excludedJids: selectedContactIds,
          }}
          onContactSelect={this.onContactSelect}
          selectOnTab
          focusOnRender={false}
          heightDifference={5}
          widthDifference={20}
          popOverClass={styles.contact_search_dropdown}
          showCustomFields
          showGroupTypeHeader={false}
        >
          {(props) => {
            return (
              <Input
                onBlur={this.onInputBlur}
                onFocus={this.onInputFocus}
                text={searchTerm}
                onChange={this.onSearchTermChange}
                placeholder={getTranslatedString(
                  'select_to_share',
                  translations,
                  null
                )}
                onNativeEsc={this.onInputEscape}
                onNativeEnter={this.onInputEnter}
                preventTabOut={showSearchDropdown}
                className={styles.contact_select_input}
                ref={this.inputRef}
                autoFocus
                {...props}
              />
            );
          }}
        </ContactSearch>
      </PopoverStyleContext.Provider>
    );
  };

  removePill = (id: string) => {
    console.error(id);
    const { selectedContactIds } = this.state;
    const newCIds = [...selectedContactIds];

    const idIndex = newCIds.indexOf(id);
    newCIds.splice(idIndex, 1);

    this.setState({
      selectedContactIds: newCIds,
    });
  };

  executeForwardMessage = () => {
    const { message, attachment, currentSession } = this.props;
    const { selectedContactIds } = this.state;
    const { ownerGuid } = currentSession.owner;

    if (selectedContactIds.length > 0) {
      selectedContactIds.forEach((contactJid) => {
        const peer = getContactForId(contactJid, ownerGuid);
        const messageData = {
          messageElements: {},
          forwardOf: message.forwardOf || message.sid,
          notification: '',
          attachments: [],
          text: '',
        };

        if (attachment) {
          messageData.attachments = [attachment];
          if (attachment.url) {
            extend(messageData, {
              text: attachment.url,
            });
          } else if (
            !attachment.appId &&
            ((attachment.views && attachment.views.image) ||
              (attachment.downloads && attachment.downloads.length))
          ) {
            extend(messageData, {
              text: message.text,
            });
          }
        }
        if (!messageData.text) {
          messageData.notification = message.notification;
        }
        extend(messageData, {
          peer,
        });
        sendMessage(messageData);
      });
      hideModal();
    }
  };

  componentDidMount() {
    this.getForwardContentDetails();
    this.focusInput(true);
  }

  render() {
    const { title, currentSession } = this.props;
    const { selectedContactIds, hasFocus } = this.state;

    return (
      <div className={styles.forward_message_modal}>
        <div className={modalStyles.modal_title}>{title}</div>

        {this.renderAttachmentDetails()}

        <div
          className={classNames(styles.input_and_pills_container, {
            [styles.focused]: hasFocus,
          })}
          onClick={() => {
            this.focusInput(true);
          }}
          onKeyDown={noop}
          role='presentation'
        >
          {this.renderSearchInput()}

          <ContactPills
            ownerGuid={currentSession?.owner.ownerGuid}
            contactIds={selectedContactIds}
            showRemovePill
            removePill={this.removePill}
            className={styles.selected_contact_pills}
          />
        </div>
        <div className={styles.buttons_container}>
          <Button type='button' kind='secondary' onClick={hideModal}>
            {getTranslatedString('cancel', translations, null)}
          </Button>
          <Button
            type='button'
            kind='primary'
            onClick={this.executeForwardMessage}
          >
            {getTranslatedString('forward', translations, null)}
          </Button>
        </div>
      </div>
    );
  }
}

export default currentSessionHOC(currentSessionIdHOC(ForwardMessage));
