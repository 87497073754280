import bridge from '@/utils/bridge';
import ChatsProxy from '@/Proxies/ChatsProxy';
import {
  showInviteGuestToConversationConfirmation,
  showRemoveFromConversationConfirmation,
  cloneChannelConfirmModalDialog,
} from '@/utils/GenericModalUtils';
import { openWidgetPopupInShell } from '@/utils/SessionUtils';
import getTranslatedString from '@/utils/getTranslatedString';
import { logGroupCreated } from '@/utils/logAnalytics';
import { BATCH_SIZE } from '@/utils/constants';
import { PromisifyBridgeSubscribes } from '@/utils';
import i18n from './I18N';

export function fetchAffiliates(group) {
  bridge.tell('GroupParticipantManager', 'fetchAffiliates', [group]);
}

export function stopFetchAffiliates(group) {
  bridge.tell('GroupParticipantManager', 'stopFetching', [group]);
}

export function getConversationIdentity(peer) {
  if (!peer) {
    return null;
  }
  if (peer.type === 'group') {
    return `session_${peer.ownerGuid}@go.to/${peer.jid}`;
  }
  if (peer.type === 'buddy') {
    return `${peer.ownerGuid}@go.to--${peer.jid}`;
  }
  return null;
}

export function sendMessage(messageData) {
  return bridge.tell('ConversationHandler', 'createOutgoingMessage', [
    messageData,
  ]);
}

export function getNewMessageClientId() {
  return bridge.ask('ConversationHandler', 'getNewMessageClientId', []);
}

export function inviteContactsToConversation(contacts, peer) {
  if (!contacts.length) {
    return;
  }
  if (peer.type !== 'group') {
    return;
  }
  const nonGuestContacts = contacts.filter((c) => !c.guest);
  const nonGuestJids = nonGuestContacts.map((c) => c.jid);
  const jids = contacts.map((c) => c.jid);

  if (nonGuestJids && nonGuestJids.length < jids.length) {
    showInviteGuestToConversationConfirmation(
      ['GroupInfoManager', 'addMembers', [peer, jids]],
      ['GroupInfoManager', 'addMembers', [peer, nonGuestJids]]
    );
  } else {
    bridge.tell('GroupInfoManager', 'addMembers', [peer, jids]);
  }
}

export function createGroupAndOpen(contacts, currentSessionId, source) {
  const invitees = contacts.map((contact) => {
    return {
      jid: contact.jid,
      email: contact.email,
    };
  });

  bridge
    .ask(`Session_${currentSessionId}`, 'createGroupOnServer', [
      null,
      invitees,
      null,
      'close',
    ])
    .then((conversation) => {
      if (!conversation || conversation instanceof Error) return;

      if (conversation && conversation.peer) {
        ChatsProxy.openConversation(conversation.peer, true);
        bridge.publish('/log_analytics/medusa/helper/genericEvent', [
          'channel_created',
          {
            template: 'adhoc',
            channel_type: 'close',
            member_count: conversation.associateCount,
            invite_count: 0,
            source: '1on1_add_member',
          },
        ]);
        logGroupCreated(source);
      }
    });
}

export function updateNotificationPreference(sessionId, peerJid, preference) {
  bridge.tell(`Session_${sessionId}`, 'updateNotificationPreference', [
    peerJid,
    preference,
  ]);
}

export function requestPhoneNumber(peer) {
  const data = {
    text: getTranslatedString('request_phone_text', i18n),
    sentByPeer: false,
    peer,
  };
  sendMessage(data);
}

export const leaveChannelWarnings = {
  GROUP_WILL_BE_DELETED: {
    message: 'channel_delete_confirm_message',
    okButtonLabel: 'delete_channel_confirm_button',
    okButtonClass: 'btn--red',
  },
  MEMBERS_ONLY: {
    message: 'channel_leave_warning_message',
    okButtonLabel: 'leave_confirm_button',
    okButtonClass: 'btn--red',
  },
  PUBLIC_CHANNEL_LEAVE: {
    message: 'channel_leave_confirm_message',
    okButtonLabel: 'leave_confirm_button',
    okButtonClass: 'btn--red',
  },
};

export function confirmAndLeaveChannel(peer) {
  const conversationIdentity = getConversationIdentity(peer);
  const { memberCount, groupType } = peer;
  const isLastMember = memberCount === 1 || memberCount === '1';

  let warning;
  if (isLastMember) {
    warning = leaveChannelWarnings.GROUP_WILL_BE_DELETED;
  } else if (groupType === 'open') {
    warning = leaveChannelWarnings.PUBLIC_CHANNEL_LEAVE;
  } else {
    warning = leaveChannelWarnings.MEMBERS_ONLY;
  }
  const { okButtonLabel, okButtonClass, message } = warning;

  openWidgetPopupInShell('widgets/ConfirmDialog', {
    okText: getTranslatedString(okButtonLabel, i18n),
    okButtonClass,
    confirmSubText: getTranslatedString(message, i18n),
    confirmText: getTranslatedString('leave_channel_title', i18n),
    containerClass: 'leave-dialog confirm-dialog',
    uncloseable: true,
    publish: `${conversationIdentity}/leaveGroup`,
    publishCancel: `${conversationIdentity}/leaveGroupCancel`,
    dimensions: {
      height: 'auto',
      width: 'auto',
    },
  });

  PromisifyBridgeSubscribes(
    `${conversationIdentity}/leaveGroup`,
    `${conversationIdentity}/leaveGroupCancel`
  ).then(() => {
    // conversation should close after update received from hydra
    // otherwise it should remain open (eg. when leave fails due to connectivity issues)
    bridge.ask('GroupInfoManager', 'leaveGroup', [peer]).then((response) => {
      if (response.responseType === 'success') {
        ChatsProxy.removeConversation(peer);
      }
    });
  });
}

export function confirmAndCloneChannel(peer) {
  if (peer.type !== 'group') {
    return;
  }
  const { memberCount } = peer;
  const conversationIdentity = getConversationIdentity(peer);
  cloneChannelConfirmModalDialog(conversationIdentity, memberCount);
}

export function updateAffiliationOfAssociate(peer, associate, affiliation) {
  const { jid: affiliateJid } = associate;

  const bridgeTellParams = [
    `GroupInfoManager`,
    'updateMembersAffiliation',
    [peer, [affiliateJid], affiliation],
  ];
  if (affiliation === 'none') {
    showRemoveFromConversationConfirmation(associate.name, bridgeTellParams);
  } else {
    bridge.tell(...bridgeTellParams);
  }
}

export function updateGroupName(peer, name) {
  bridge.tell('GroupInfoManager', 'updateGroupProfile', [peer, { name }]);
}

export function updateGroupDescription(peer, description) {
  bridge.tell('GroupInfoManager', 'updateGroupProfile', [
    peer,
    { description },
  ]);
}

export function getUnreadCount(unreadCount, maxCount = BATCH_SIZE) {
  return unreadCount >= maxCount ? `${maxCount}+` : unreadCount;
}

export async function editLastSentMessage(peer) {
  const message = await bridge.ask(
    'MessageCache',
    'getLatestEditableMessageSentByOwner',
    [peer]
  );
  bridge.publish('/editMessage', [message]);
}
