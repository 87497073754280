export default {
  'English-US': {
    englishName: 'English-US',
    localName: 'English-US',
    code: 'en-US',
    isAllowed: true,
    codeSimilarTo: 'en-GB',
  },
  'English-UK': {
    englishName: 'English-UK',
    localName: 'English-UK',
    code: 'en-GB',
    isAllowed: true,
    codeSimilarTo: 'en-US',
  },
  Spanish: {
    englishName: 'Spanish',
    localName: 'Español',
    code: 'es',
    isAllowed: true,
  },
  'Spanish-MX': {
    englishName: 'Spanish-MX',
    localName: 'Español México',
    code: 'es-MX',
    isAllowed: true,
  },

  'Brazilian Portuguese': {
    englishName: 'Brazilian Portuguese',
    localName: 'Português Brasileiro',
    isAllowed: true,
    code: 'pt-br',
  },
};
