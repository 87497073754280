import * as React from 'react';
import { Icon } from '@talk-to/flock-components';
import { cxBind } from '@/utils/cx';

import styles from './EditableCard.css';

const cx = cxBind(styles);

type Props = {
  className?: string;
  noPadding?: boolean;
  editing: boolean;
  editContent?: () => React.ReactNode;
  onEditIconClick?: () => void;
  children: React.ReactNode;
};

export default function EditableCard(props: Props) {
  const {
    noPadding = false,
    editing,
    editContent,
    className,
    children,
    onEditIconClick,
  } = props;

  const handleClick = () => !editing && onEditIconClick?.();

  return (
    <div
      role='button'
      tabIndex={-1}
      className={cx(
        'card',
        { 'no-padding': noPadding, pointer: !editing },
        className
      )}
      onKeyUp={handleClick}
      onClick={handleClick}
    >
      {!editing && (
        <div className={cx('edit-icon')}>
          <Icon name='edit' />
        </div>
      )}
      {editing ? editContent() : children}
    </div>
  );
}
