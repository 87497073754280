/* global analytics */
import bridge from '@/utils/bridge';
import { isContactIdSpecial } from '@/Models/Contact';
import { logMedusaGenericEvent, logInvitesSent } from '@/utils/logAnalytics';
import {
  showRestrictedAction,
  showRestrictedActionForLite,
} from '@/utils/GenericModalUtils';
import { inviteContacts } from '@/utils/SessionUtils';
import { inviteContactsToConversation } from '@/utils/ConversationUtils';
import getTranslatedString from '@/utils/getTranslatedString';
import { getContactsFromText } from '../../../../utils';
import { getMultiParamFragments } from '../../../../TokenUtils';
import I18N from './I18N';

const command = {
  name: getTranslatedString('command_add_name', I18N),
  hint: getTranslatedString('command_add_hint', I18N),
  params: {
    hint: getTranslatedString('command_add_param_hint', I18N),
    type: ['buddy', 'email'],
    excludeDeactivated: true,
    contactTypesFilter: ['contact'],
    check(/* peer */) {
      return true;
    },
    getParamsInfo(textContent, selectionStart, selectionEnd) {
      return getMultiParamFragments(textContent, selectionStart, selectionEnd);
    },
  },

  shouldIgnore(isDMBlocked) {
    return !!isDMBlocked;
  },

  action(
    textContent,
    peer,
    owner,
    sessionId
    /* _conversation  , contactParams */
  ) {
    getContactsFromText(textContent, owner).then((contacts) => {
      if (peer.type === 'buddy') {
        if (owner.guest) {
          showRestrictedAction();
          return true;
        }
        if (!owner.isLicensed) {
          showRestrictedActionForLite(sessionId, 'CREATE_CHANNEL');
          return true;
        }

        contacts.unshift(peer);

        const inviteCount = contacts.filter(function (contact) {
          return contact.jid === undefined;
        }).length;
        analytics.log('group', 'creation_initiated', 'slash_command');

        bridge
          .ask(`Session_${sessionId}`, 'createGroupOnServer', [
            null,
            contacts,
            null,
            'close',
          ])
          .then((conversation) => {
            if (conversation && conversation.peer) {
              analytics.log('channel', 'created', 'slash_command');
              logMedusaGenericEvent('channel_created', {
                template: 'adhoc',
                channel_type: 'close',
                member_count: conversation.associateCount,
                invite_count: inviteCount,
                source: 'slash_command_add',
              });
            } else {
              analytics.log('channel', 'creation-failed', 'slash_command');
            }
          });
      } else {
        if (!peer.hasAddMemberPrivilege) {
          bridge.ask('InAppNotificationStore', 'addNewNotification', [
            peer.jid,
            'You do not have privileges to do this action',
            'error',
            5000,
          ]);
          return false;
        }
        const tempContacts = contacts.filter(
          (contact) => contact.isTempSearchContact
        );
        const tempContactsEmails = tempContacts.map((c) => c.email);
        const existingContacts = contacts.filter(
          (c) => !isContactIdSpecial(c.jid)
        );

        if (tempContacts.length) {
          inviteContacts(tempContactsEmails, owner.ownerGuid, true).then(
            function (params) {
              analytics.log(
                'invite',
                'invite_via_email',
                'slash_command',
                tempContactsEmails.length
              );
              logInvitesSent(tempContactsEmails.length, 'slash_command');

              const invitedContacts = (params && params.summary.profiles) || [];
              invitedContacts.forEach((c) => {
                c.jid = `${c.guid}@go.to`;
              });
              inviteContactsToConversation(
                [...existingContacts, ...invitedContacts],
                peer
              );
            }
          );
        } else {
          inviteContactsToConversation(contacts, peer);
        }
      }
      return true;
    });
  },
};

export default command;
