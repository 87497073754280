import React, { PureComponent } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { stringifyCustomFields } from '@/components/Search/searchUtils';
import { Deactivated as DeactivatedBadge } from '@/components/common/badges';
import presenceStyles from '@/components/common/common.css';
import cssStyles from './ContactItem.css';

class ContactItem extends PureComponent {
  static propTypes = {
    contact: PropTypes.object,
    customFieldsMap: PropTypes.object,
    showAddButton: PropTypes.bool,
    showCustomFields: PropTypes.bool,
  };

  static defaultProps = {
    contact: undefined,
    customFieldsMap: undefined,
    showAddButton: false,
    showCustomFields: false,
  };

  getContactCustomFields() {
    const { contact, customFieldsMap, showCustomFields } = this.props;

    if (!showCustomFields) {
      return null;
    }

    const text = stringifyCustomFields(contact.customFields, customFieldsMap);

    if (!text) {
      return null;
    }

    return (
      <div className={cssStyles.contactDesc} title={text}>
        {text}
      </div>
    );
  }

  getContactSubText() {
    const { contact, intl } = this.props;
    let text = contact.email;

    if (contact.type === 'temp_search') {
      text = intl.formatMessage({ id: 'temp_search_channel' });
    }

    if (!text) {
      return null;
    }

    return <div className={cssStyles.contactDesc}>{text}</div>;
  }

  getAddButton() {
    const { showAddButton } = this.props;
    if (!showAddButton) {
      return null;
    }
    return (
      <button type='button' className={cssStyles.addButton}>
        Add
      </button>
    );
  }

  getInviteButton() {
    const {
      contact: { isImported },
    } = this.props;
    if (!isImported) {
      return null;
    }

    return (
      <button type='button' className={cssStyles.inviteButton}>
        Invite
      </button>
    );
  }

  getProfileImage() {
    const { contact } = this.props;
    const {
      imageUrl,
      isImported,
      type,
      name,
      presence,
      jid,
      isDeactivated = false,
    } = contact;

    const showPresence = type === 'buddy' && jid[0] !== 'B';

    if (isImported) {
      return null;
    }
    return (
      <div className={`${cssStyles.relative} ${cssStyles.thumbnaildimensions}`}>
        <img
          className={cx(cssStyles.thumbnail, {
            [cssStyles.Deactivated]: isDeactivated,
          })}
          src={imageUrl}
          alt={name}
        />
        {showPresence && !isDeactivated && (
          <div
            className={`${presenceStyles.presence_base} ${
              presenceStyles[`presence--${presence}`]
            } ${cssStyles.presence} `}
          />
        )}
      </div>
    );
  }

  render() {
    const { contact } = this.props;

    if (!contact) {
      return null;
    }
    const {
      nameAutoComplete,
      name,
      guest: isGuest,
      isDeactivated = false,
    } = contact;

    return (
      <div className={cssStyles.flex}>
        {this.getProfileImage()}
        <div className={cssStyles.contactDetails}>
          <div className={cssStyles.contactNameContainer}>
            <span
              className={cx(cssStyles.contactName, {
                [cssStyles.Deactivated]: isDeactivated,
              })}
            >
              {name}
              {nameAutoComplete ? (
                <span className={cssStyles.contactNameAutoComplete}>
                  {nameAutoComplete}
                </span>
              ) : null}
              {isGuest ? (
                <span className={cssStyles.contactGuestText}> (guest)</span>
              ) : null}
            </span>
            {isDeactivated && <DeactivatedBadge variant='list-item' />}
          </div>
          {this.getContactCustomFields()}
          {this.getContactSubText()}
        </div>
        {this.getAddButton()}
        {this.getInviteButton()}
      </div>
    );
  }
}

export default injectIntl(ContactItem);
