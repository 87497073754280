import React, { PureComponent } from 'react';
import throttle from 'lodash/throttle';
import {
  fetchAffiliates,
  stopFetchAffiliates,
} from '@/utils/ConversationUtils';
import { markLatestMessageAsRead } from '@/utils/message';
import ErrorBoundary from '@/components/widgets/ErrorBoundary';
import { Button, DragAndDrop } from '@/components/common';
import CurrentPeerHOC from '@/subscribeHOCs/currentPeer';
import CurrentSession from '@/connectHOCs/Sessions/currentSession';
import { uploadAndSendFileMessage } from '@/utils/MultiFileUpload';
import MessagesContainer from '@/containers/MessagesContainer';
import { joinPublicChannel } from '@/utils/ContactUtils';
import { maybe, getMemberCount } from '@/utils';
import EditorIndicators from './EditorIndicators';
import EditorPaywall from './EditorPaywall';
import DeactivatedUserGuard from './DeactivatedUserGuard';
import Header from './Header';
import Editor from '../ConversationEditor';
import cssStyles from './Conversation.css';

const MESSAGE_READ_TIMEOUT = 1500;

class Conversation extends PureComponent {
  subscription = undefined;

  state = {
    isMessageListAtBottom: false,
  };

  componentDidMount() {
    this.setTimeoutForReadReceipt();
    const { currentPeer } = this.props;

    if (currentPeer && currentPeer.type === 'group') {
      fetchAffiliates(currentPeer);
    }
  }

  componentDidUpdate(prevProps) {
    const { currentPeer: prevPeer = {} } = prevProps;
    const { currentPeer = {} } = this.props;
    if (
      currentPeer.jid !== prevPeer.jid ||
      currentPeer.ownerGuid !== prevPeer.ownerGuid
    ) {
      this.setTimeoutForReadReceipt();
      if (currentPeer && currentPeer.type === 'group') {
        fetchAffiliates(currentPeer);
      } else {
        stopFetchAffiliates(prevPeer);
      }
      // set isMessageListAtBottom to false, so that previos bottom value
      // does not affect sending read reciepts.
      this.setState({
        isMessageListAtBottom: false,
      });
    }
  }

  componentWillUnmount() {
    if (this.messageReadTimer) {
      window.clearTimeout(this.messageReadTimer);
    }
    const { currentPeer } = this.props;
    if (currentPeer && currentPeer.type === 'group') {
      stopFetchAffiliates(currentPeer);
    }
  }

  setIsMessageListAtBottom = (isMessageListAtBottom) => {
    const { isMessageListAtBottom: value } = this.state;
    if (isMessageListAtBottom !== value) {
      this.setState({
        isMessageListAtBottom,
      });
    }
  };

  setTimeoutForReadReceipt() {
    const { currentPeer } = this.props;
    if (this.messageReadTimer) {
      window.clearTimeout(this.messageReadTimer);
    }
    this.messageReadTimer = setTimeout(() => {
      const { isMessageListAtBottom } = this.state;
      if (isMessageListAtBottom) {
        markLatestMessageAsRead(currentPeer);
      }
    }, MESSAGE_READ_TIMEOUT);
  }

  onEditorInteract = throttle(
    () => {
      // using setState callback to get latest committed state as of this method invocation
      this.setState((state, props) => {
        const { currentPeer } = props;
        const { isMessageListAtBottom } = state;
        if (isMessageListAtBottom) {
          markLatestMessageAsRead(currentPeer);
        }
        return null;
      });
    },
    1000,
    { trailing: false }
  );

  handleFileDrop = (files) => {
    const { currentPeer, currentSession } = this.props;
    uploadAndSendFileMessage(files, currentPeer, currentSession);
  };

  joinChannel = () => {
    const { currentPeer } = this.props;
    return joinPublicChannel(currentPeer);
  };

  getMemberCount = () => {
    const { currentSession, currentPeer: { memberCount } = {} } = this.props;
    const maskChannelMemberCountAfter = maybe(
      currentSession,
      'sessionInfo',
      'teamInfo',
      'channelPreferences',
      'maskChannelMemberCountAfter'
    );
    return getMemberCount(memberCount, maskChannelMemberCountAfter);
  };

  render() {
    const { currentPeer, currentPeerId, currentSession } = this.props;

    if (!currentPeerId || !currentPeer) {
      return null;
    }
    const {
      isAnnouncementOnly,
      hasPostMessagePrivilege,
      isGroup,
      isDeactivated,
      isDeleted,
      groupType,
      isJoined,
    } = currentPeer;

    const showJoinPublicChannel = groupType === 'open' && !isJoined;
    const showAnnounceOnly = isGroup && isAnnouncementOnly;
    const showReadOnly = isGroup && !hasPostMessagePrivilege;
    const viewOnly = isDeleted;
    const showEditor = !showAnnounceOnly && !showReadOnly && !viewOnly;
    const memberCount = this.getMemberCount();

    return (
      // eslint-disable-next-line react/style-prop-object
      <DragAndDrop handleDrop={this.handleFileDrop}>
        <div className={cssStyles.container}>
          <ErrorBoundary>
            <Header peer={currentPeer} memberCount={memberCount} />
          </ErrorBoundary>
          {showJoinPublicChannel ? (
            <div className={cssStyles.joinChannel}>
              <div className={cssStyles.joinChannelContent}>
                <div className={cssStyles.joinChannelText}>
                  You are not a member of this channel yet.
                </div>
                <Button onClick={this.joinChannel}>Join Channel</Button>
              </div>
            </div>
          ) : (
            <>
              <ErrorBoundary>
                <MessagesContainer
                  {...this.props}
                  onInteract={this.onEditorInteract}
                  setIsMessageListAtBottom={this.setIsMessageListAtBottom}
                  className={cssStyles.messagesContainer}
                />
              </ErrorBoundary>
              <div className={cssStyles.editorContainer}>
                <ErrorBoundary>
                  <EditorIndicators peer={currentPeer} />
                  {showEditor ? (
                    <EditorPaywall
                      session={currentSession}
                      groupType={currentPeer.groupType}
                    >
                      <DeactivatedUserGuard
                        deactivated={isDeactivated}
                        group={isGroup}
                      >
                        <Editor onInteract={this.onEditorInteract} />
                      </DeactivatedUserGuard>
                    </EditorPaywall>
                  ) : null}
                  {showAnnounceOnly ? (
                    <div className={cssStyles.AnnounceOnly}>
                      <span className={cssStyles.highlight}>Note: </span>
                      This is an announcement channel for the entire team and
                      only admins can post messages in this channel
                    </div>
                  ) : null}
                  {showReadOnly ? (
                    <div className={cssStyles.ReadOnly}>
                      <span className={cssStyles.highlight}>Note: </span>
                      This is a read only channel. You do not have privileges to
                      post messages to this channel
                    </div>
                  ) : null}
                  {viewOnly ? (
                    <div className={cssStyles.ReadOnly}>
                      <span className={cssStyles.highlight}>Note: </span>
                      This is a view only conversation. You can not post
                      messages to this conversation.
                    </div>
                  ) : null}
                </ErrorBoundary>
              </div>
            </>
          )}
        </div>
      </DragAndDrop>
    );
  }
}

export default CurrentSession(CurrentPeerHOC(Conversation));
