/* eslint jsx-a11y/label-has-associated-control: "off" */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cssStyles from './SearchTextField.css';

class SearchTextField extends PureComponent {
  static propTypes = {
    searchText: PropTypes.string,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    searchText: '',
    onChange: () => {},
  };

  constructor(...params) {
    super(...params);

    this.onChange = this.onChange.bind(this);
    this.input = React.createRef();
  }

  componentDidMount() {
    this.input.current.focus();
  }

  componentDidUpdate() {
    this.input.current.focus();
  }

  onChange(e) {
    const { onChange } = this.props;
    if (typeof onChange === 'function') {
      onChange(e.target.value);
    }
  }

  render() {
    const { searchText } = this.props;
    return (
      <div className={cssStyles.wrapper}>
        {searchText ? null : (
          <label htmlFor='SearchTextField' className={cssStyles.label}>
            <FormattedMessage
              id='tab_add_people'
              values={{
                button: (
                  <span className={cssStyles.tab}>
                    <FormattedMessage id='tab' />
                  </span>
                ),
              }}
            />
          </label>
        )}

        <input
          ref={this.input}
          className={cssStyles.input}
          id='SearchTextField'
          type='text'
          autoComplete='off'
          autoCorrect='off'
          spellCheck='false'
          dir='auto'
          value={searchText}
          onChange={this.onChange}
          onBlur={this.onBlur}
        />
      </div>
    );
  }
}

SearchTextField.propTypes = {
  searchText: PropTypes.string,
  onChange: PropTypes.func,
};

export default SearchTextField;
