import { confirmAndCloneChannel } from '@/utils/ConversationUtils';
import { showMemberLimitOverflowModalDialog } from '@/utils/GenericModalUtils';
import getTranslatedString from '@/utils/getTranslatedString';
import I18N from './I18N';

const command = {
  name: getTranslatedString('command_clone_name', I18N),
  hint: getTranslatedString('command_clone_hint', I18N),
  shouldIgnore(isDMBlocked, isCurrentOwnerAdmin) {
    if (isDMBlocked && !isCurrentOwnerAdmin) {
      return true;
    }
    return false;
  },
  action(textContent, peer /* owner, sessionId, conversation */) {
    const allowedCount = 100;
    const { memberCount } = peer;
    if (memberCount > allowedCount) {
      showMemberLimitOverflowModalDialog(allowedCount);
    } else {
      confirmAndCloneChannel(peer);
    }
  },
};
export default command;
