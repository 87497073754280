/* eslint-disable import/prefer-default-export */

export function getDataUrlForFile(file) {
  return new Promise((resolve) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.readAsDataURL(file);
  });
}

export async function getBlobFromDataUrl(ImageURL, filename) {
  const response = await fetch(ImageURL);
  const blob = await response.blob();
  blob.name = filename;
  return blob;
}
