import React from "react";

export const Sidebar = ({ children }) => {
  return (
    <div className="sidebar-root">
      {children}

      <style jsx>{`
        .sidebar-root {
          position: relative;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          overflow: hidden;
        }
      `}</style>
    </div>
  );
};
