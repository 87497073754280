import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector, createSelector } from 'reselect';
import DikeProxy from '@/Proxies/DikeProxy';
import { maybe } from '@/utils';
import { appListSelector, ownerGuidSelector } from '@/selectors';

// type Props = {
//   currentOwnerGuid: string;
//   currentSession: any;
//   appList: any;
// }

const appListSelectorFactory = (rootCategory) => {
  return createSelector(appListSelector, (appList) =>
    appList.filter((app) =>
      Object.prototype.hasOwnProperty.call(app, rootCategory)
    )
  );
};

export default function (ContainedComponent, rootCategory) {
  const mapStateToProps = createStructuredSelector({
    currentOwnerGuid: ownerGuidSelector,
    appList: appListSelectorFactory(rootCategory),
  });

  class AppListSubHOC extends Component {
    /* Do we even need this? 
      TODO: Use subscriber on redux to subscribe on currentOwnerGuid inside dike proxy itself 
    */
    componentDidMount() {
      const { currentOwnerGuid } = this.props;
      this.dikeProxySubscription = DikeProxy.subscribe(currentOwnerGuid);
    }

    componentDidUpdate() {
      const { currentOwnerGuid } = this.props;
      this.dikeProxySubscription.update(currentOwnerGuid);
    }

    componentWillUnmount() {
      this.dikeProxySubscription.unsubscribe();
      this.dikeProxySubscription = null;
    }

    render() {
      const { currentSession } = this.props;
      const videoRecordingEnabled = maybe(
        currentSession,
        'sessionInfo',
        'teamInfo',
        'isVideoRecordingEnabled'
      );
      return (
        <ContainedComponent
          {...this.props}
          isVideoRecordingEnabled={videoRecordingEnabled}
        />
      );
    }
  }

  return connect(mapStateToProps, null)(AppListSubHOC);
}
