import { createActions } from 'redux-actions';

export const OPEN_PREFERENCES = 'OPEN_PREFERENCES';
export const CLOSE_PREFERENCES = 'CLOSE_PREFERENCES';

export const ActionNames = {
  OPEN_PREFERENCES,
  CLOSE_PREFERENCES,
};

interface OpenSearchAction {
  type: typeof OPEN_PREFERENCES;
  payload: any;
}

interface CloseSearchAction {
  type: typeof CLOSE_PREFERENCES;
  payload: any;
}

export type ActionTypes = OpenSearchAction | CloseSearchAction;

export const actions = createActions({
  OPEN_PREFERENCES: () => {},
  CLOSE_PREFERENCES: () => {},
} as Record<ActionTypes['type'], any>);
