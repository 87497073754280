import Froala from 'froala-editor';

export const BOLD_REGEX: RegExp = /(?:\s|^)(\*)([^*:\s][^*]*[^*:\s]|[^*:\s])(\*)$/g;
export const ITALIC_REGEX: RegExp = /(?:\s|^)(_)([^_:\s][^_]*[^_:\s]|[^_:\s])(_)$/g;
export const STRIKETHROUGH_REGEX: RegExp = /(?:\s|^)(~~)([^~:\s][^~]*[^~:\s]|[^~:\s])(~~)$/g;
export const EDITOR_TYPES = {
  CLASSIC: 'classic',
  BALLOON: 'balloon',
  TEMPORARY_BALLOON: 'temporary balloon',
};

export const USER = 'user';
export const CHANNEL = 'channel';
export const EMOJI = 'emoji';
export const GENERIC_MENTIONS = ['all', 'online'];
export const MENTION_TYPES = [USER, CHANNEL];

export const COLOR_CONFIG = {
  colors: [
    '#c0392c',
    '#e74c3c',
    '#d35500',
    '#e67e23',
    '#f39c12',
    '#f1c40e',
    '#17a185',
    '#19bc9c',
    '#27ae60',
    '#2ecd72',
    '#2980b9',
    '#3598dc',
    '#8e44ad',
    '#9b59b6',
    'REMOVE',
  ].map((s) => s.toUpperCase()),
};

const stylePropsForEmojis = [
  'background-image',
  'background-size',
  'background-position',
  'background-repeat',
];

const allowedStyleProps = [
  'font-weight',
  'font-style',
  'text-decoration',
  ...stylePropsForEmojis,
];

export const BASE_CONFIG = {
  attribution: false,
  autofocus: false,
  colorsStep: 6,
  colorsHEXInput: false,
  colorsText: COLOR_CONFIG.colors,
  colorsButtons: [],
  // key:
  //   'bMA6aA6A5B3F2E2D2xROKLJKYHROLDXDRH1e1YYGRe1Bg1G3I3A2C6D6A3B2E4E2H3==',
  // height: '100%',
  charCounterCount: false,
  // useClasses: false,
  htmlAllowedTags: [
    'a',
    'b',
    'br',
    'col',
    'colgroup',
    'dd',
    'del',
    'div',
    'dl',
    'dt',
    'em',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'i',
    'img',
    'label',
    // 'li',
    'link',
    // 'ol',
    'p',
    's',
    'span',
    'strike',
    'strong',
    'tbody',
    'td',
    'tfoot',
    'th',
    'thead',
    'tr',
    'u',
    // 'ul',
    'user',
    'channel',
  ],
  htmlAllowedStyleProps: ['color', ...allowedStyleProps],
  htmlAllowedAttrs: [
    ...Froala.DEFAULTS.htmlAllowedAttrs,
    'userId',
    'channelId',
    'group',
  ],
  htmlExecuteScripts: false,
  // heightMax: this.props?.isEditMessage ? 250 : 300,
  heightMin: 50,
  linkAlwaysBlank: true,
  linkAlwaysNoFollow: false,
  linkNoOpener: false,
  linkNoReferrer: false,
  linkText: true,
  linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
  linkAutoPrefix: 'https://',
  linkList: [],
  linkInsertButtons: [],
  linkConvertEmailAddress: true,
  pasteAllowedStyleProps: allowedStyleProps,
  pasteDeniedAttrs: ['class', 'id', 'data', 'width', 'height'],
  pasteDeniedTags: ['style', 'script', 'user', 'channel'],
  pluginsEnabled: [
    'colors',
    'customClipboard',
    'emoticons',
    'entities',
    'inlineClass',
    'inlineStyle',
    'lineHeight',
    'link',
    'paragraphFormat',
    'paragraphStyle',
    'save',
    'specialCharacters',
    'table',
    'url',
    'wordPaste',
  ],
  // placeholderText: this.props?.placeholder || 'Message...',
  quickInsertEnabled: false,
  // toolbarInline: true,
  toolbarButtons: [
    'bold',
    'italic',
    'underline',
    'strikeThrough',
    'textColor',
    'insertLink',
  ],
  toolbarBottom: true,
  // toolbarContainer: `#toolbar${this.props?.isEditMessage ? '-edit' : ''}`,
  tabSpaces: 4,
  saveInterval: 0,
  wordPasteModal: false,
  wordPasteKeepFormatting: true,
  zIndex: 25,
};

export const unsupportedTagReplacements = [
  {
    tagName: 'h1',
    replacement: 'p',
  },
  {
    tagName: 'h2',
    replacement: 'p',
  },
  {
    tagName: 'h3',
    replacement: 'p',
  },
  {
    tagName: 'h4',
    replacement: 'p',
  },
  {
    tagName: 'h5',
    replacement: 'p',
  },
  {
    tagName: 'tr',
    replacement: 'p',
  },
  {
    tagName: 'col',
    replacement: 'span',
  },
  {
    tagName: 'colgroup',
    replacement: 'span',
  },
  {
    tagName: 'li',
    replacement: 'span',
  },
  {
    tagName: 'tbody',
    replacement: 'span',
  },
  {
    tagName: 'td',
    replacement: 'span',
  },
  {
    tagName: 'tfoot',
    replacement: 'span',
  },
  {
    tagName: 'th',
    replacement: 'span',
  },
  {
    tagName: 'thead',
    replacement: 'span',
  },
  {
    tagName: 'img',
    replacement: 'span',
  },
];

export const FROALA_KEYCODES = Froala.KEYCODE;

export const ALLOWED_CHARACTERS_KEYCODES = new Set([
  FROALA_KEYCODES.SPACE,
  FROALA_KEYCODES.QUESTION_MARK,
  FROALA_KEYCODES.NUM_PLUS,
  FROALA_KEYCODES.NUM_MINUS,
  FROALA_KEYCODES.NUM_PERIOD,
  FROALA_KEYCODES.NUM_DIVISION,
  FROALA_KEYCODES.SEMICOLON,
  FROALA_KEYCODES.FF_SEMICOLON,
  FROALA_KEYCODES.DASH,
  FROALA_KEYCODES.EQUALS,
  FROALA_KEYCODES.FF_EQUALS,
  FROALA_KEYCODES.COMMA,
  FROALA_KEYCODES.PERIOD,
  FROALA_KEYCODES.SLASH,
  FROALA_KEYCODES.APOSTROPHE,
  FROALA_KEYCODES.SINGLE_QUOTE,
  FROALA_KEYCODES.BACKSLASH,
  FROALA_KEYCODES.OPEN_SQUARE_BRACKET,
  FROALA_KEYCODES.CLOSE_SQUARE_BRACKET,
]);

export const htmlAllowedEmptyTags = ['br', 'img'];

export const ICONS_SVGS = {
  bold: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="6 6 20 20">
  <g fill="none" fill-rule="evenodd">
      <rect width="20" height="20" rx="4"/>
      <path fill="#888" fill-rule="nonzero" d="M11.2 11.2c0-.442.358-.8.8-.8h4.8c1.767 0 3.2 1.433 3.2 3.2 0 .823-.31 1.574-.822 2.14.858.573 1.422 1.55 1.422 2.66 0 1.767-1.433 3.2-3.2 3.2H12c-.442 0-.8-.358-.8-.8zm6.2 5.6h-4.6V20h4.6c.884 0 1.6-.716 1.6-1.6 0-.884-.716-1.6-1.6-1.6zm-.6-4.8h-4v3.2h4c.884 0 1.6-.716 1.6-1.6 0-.884-.716-1.6-1.6-1.6z"/>
  </g>
</svg>
`,
  italic: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="6 6 20 20">
<g fill="none" fill-rule="evenodd">
    <rect width="20" height="20" rx="4"/>
    <path fill="#888" fill-rule="nonzero" d="M20.337 10.3c.543 0 .984.44.984.984 0 .501-.376.92-.875.978l-.11.006h-1.675l-2.8 7.463h1.528c.51 0 .93.388.98.884l.005.1c0 .502-.376.921-.876.979l-.109.006h-5.305c-.543 0-.984-.44-.984-.984 0-.501.376-.92.875-.978l.11-.006h1.673l2.8-7.465-1.526.001c-.51 0-.93-.387-.98-.883l-.005-.1c0-.502.376-.921.876-.979l.109-.006z"/>
</g>
</svg>
`,
  underline: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="7 6 18 18">
<g fill="none" fill-rule="evenodd">
    <rect width="20" height="20" rx="4"/>
    <path fill="#888" fill-rule="nonzero" d="M20.504 20c.463 0 .838.358.838.8 0 .41-.324.748-.74.795l-.098.005h-8.466c-.463 0-.838-.358-.838-.8 0-.41.323-.748.74-.795l.098-.005h8.466zm-1.058-9.6c.43 0 .783.323.832.74l.005.098v3.704c0 2.216-1.796 4.012-4.012 4.012-2.156 0-3.915-1.7-4.009-3.834l-.004-.178v-3.704c0-.463.375-.838.838-.838.43 0 .784.323.832.74l.006.098v3.704c0 1.29 1.046 2.337 2.337 2.337 1.243 0 2.259-.97 2.333-2.195l.004-.142v-3.704c0-.463.375-.838.838-.838z"/>
</g>
</svg>
`,
  strikethrough: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="6.5 6 19 19">
<g fill="none" fill-rule="evenodd">
    <rect width="20" height="20" rx="4"/>
    <path fill="#888" fill-rule="nonzero" d="M16.155 10.32c1.2 0 2.163.297 2.882.891.7.565 1.116 1.376 1.244 2.416l.023.223.017.209-.08.086h-1.266l-.08-.068-.023-.166c-.106-.748-.38-1.302-.825-1.682-.438-.374-1.074-.561-1.921-.561-.794 0-1.404.133-1.845.4-.46.267-.68.666-.68 1.236 0 .474.26.838.82 1.138.223.106.638.255 1.19.426l.19.06 1.003.295.197.07h3.789c.39 0 .707.316.707.707 0 .355-.263.653-.617.701l-.09.006h-.927l.09.099c.281.338.465.727.548 1.165l.03.19.017.195.005.18c0 .976-.42 1.774-1.24 2.352-.763.529-1.763.792-2.997.792-1.25 0-2.258-.293-2.998-.875-.794-.633-1.26-1.566-1.392-2.78l-.022-.242-.014-.207.08-.085h1.255l.08.071.018.173c.095.913.401 1.574.92 2.002.477.384 1.166.58 2.058.58.848 0 1.55-.168 2.08-.494.483-.318.722-.73.722-1.243 0-.606-.302-1.074-.928-1.426-.139-.08-.357-.178-.641-.287l-.43-.16h-5.437c-.39 0-.707-.317-.707-.707 0-.355.263-.654.617-.701l.09-.006 1.479-.001-.08-.06c-.461-.371-.748-.839-.854-1.394l-.026-.17-.016-.172-.004-.163c0-1.002.416-1.772 1.243-2.296.709-.478 1.63-.717 2.746-.717z"/>
</g>
</svg>
`,
  fontColor: `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="7.5 6 18 18">
  <g fill="none" fill-rule="evenodd">
      <rect width="32" height="32" rx="4"/>
      <path fill="#888" fill-rule="nonzero" d="M20 20.8c.442 0 .8.358.8.8 0 .442-.358.8-.8.8h-8c-.442 0-.8-.358-.8-.8 0-.442.358-.8.8-.8h8zm-3.11-10.4l3.13 8.8h-1.69l-.744-2.25h-3.238l-.756 2.25H12l3.122-8.8h1.769zm-.865 1.677h-.104l-1.207 3.629h2.506l-1.195-3.629z"/>
  </g>
</svg>

`,
  link: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="6 6 18 18">
<g fill="none" fill-rule="evenodd">
    <rect width="20" height="20" rx="4"/>
    <path fill="#888" fill-rule="nonzero" d="M15.083 13.838l.178.008c.903.064 1.733.518 2.276 1.243.238.318.173.77-.146 1.008-.294.22-.7.18-.949-.077l-.059-.069c-.292-.39-.739-.634-1.225-.67-.388-.027-.771.08-1.084.3l-.114.088-.11.1-1.431 1.432c-.637.66-.628 1.707.02 2.355.577.577 1.48.652 2.132.204l.112-.084.102-.09.821-.822c.281-.28.737-.28 1.018 0 .23.23.274.582.119.861l-.053.082-.066.076-.83.83c-1.224 1.182-3.17 1.165-4.373-.039-1.117-1.117-1.215-2.88-.258-4.118l.11-.135.119-.13 1.44-1.44c.553-.553 1.292-.876 2.069-.91l.182-.003zm5.496-2.412c1.117 1.117 1.215 2.88.258 4.118l-.11.135-.119.13-1.44 1.44c-.64.64-1.526.97-2.429.905-.903-.064-1.733-.518-2.276-1.243-.238-.318-.173-.77.146-1.008.294-.22.7-.18.949.077l.059.069c.292.39.739.634 1.225.67.388.027.771-.08 1.084-.3l.114-.088.11-.1 1.431-1.432c.637-.66.628-1.707-.02-2.355-.578-.577-1.48-.652-2.133-.203l-.112.084-.103.091-.825.821c-.282.28-.738.279-1.019-.003-.23-.23-.272-.582-.116-.861l.053-.081.066-.076.833-.828c1.225-1.183 3.17-1.166 4.374.038z"/>
</g>
</svg>
`,
};
