import bridge from '@/utils/bridge';
import getTranslatedString from '@/utils/getTranslatedString';
import I18N from './I18N';

const command = {
  name: getTranslatedString('command_shortcuts_name', I18N),
  hint: getTranslatedString('command_shortcuts_hint', I18N),
  shouldIgnore() {
    return false;
  },
  action(/* textContent, peer, owner, sessionId , conversation */) {
    bridge.tell('Shell', 'showKeyboardShortcuts');
  },
};

export default command;
