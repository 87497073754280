import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import Tippy from '@tippyjs/react';
import AllBuddiesProxy from '@/Proxies/AllBuddiesProxy';
import allBuddies from '@/connectHOCs/Contacts/allBuddies';
import ContactList from '@/connectHOCs/Contacts/ContactList';
import withTeamInfo from '@/connectHOCs/Sessions/currentSession/teamInfo';
import withCurrentOwner from '@/connectHOCs/Sessions/currentSession/owner';
import { openInviteContactScreen } from '@/utils/ShellUtils';
import noop from '@/utils/noop';
import ListContainer from './ListContainer';
import Buddy from '../components/roster/Buddy';
import cssStyles from '../components/roster/Contacts.css';
import EmptyContactsContainer from '../components/roster/EmptyContactsState';
import commonStyles from '../components/common/common.css';

const LIMIT = 20;

class AllContactsContainer extends React.Component {
  allBuddiesProxySubscription = undefined;

  static defaultProps = {
    guid: null,
    contacts: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      currentPrefix: '',
    };

    if (props.guid) {
      this.allBuddiesProxySubscription = AllBuddiesProxy.subscribe(props.guid);
    }
  }

  componentDidUpdate(prevProps) {
    const { guid } = this.props;
    const { guid: prevGuid } = prevProps;
    if (guid !== prevGuid) {
      if (this.allBuddiesProxySubscription) {
        this.allBuddiesProxySubscription.unsubscribe();
      }
      this.allBuddiesProxySubscription = AllBuddiesProxy.subscribe(guid);
    }
  }

  componentWillUnmount() {
    if (this.allBuddiesProxySubscription) {
      this.allBuddiesProxySubscription.unsubscribe();
    }
  }

  onInviteClick = () => {
    openInviteContactScreen('sidebar_plus');
  };

  onCurrentPrefixChange = (event) => {
    this.setState({ currentPrefix: event.target.value });
  };

  render() {
    const { intl, owner, teamInfo } = this.props;
    let { contacts: allContacts } = this.props;
    const { currentPrefix } = this.state;

    const { isDMBlocked } = teamInfo;
    const { isAdmin: isOwnerAdmin } = owner;

    const prefix = currentPrefix;
    // ensure that the contacts are matching the prefix of the search term mentioned
    allContacts = allContacts.filter((contact) => {
      if (isDMBlocked && !isOwnerAdmin && !contact.isAdmin) {
        return false;
      }
      return contact.name.toLowerCase().indexOf(prefix.toLowerCase()) === 0;
    });

    allContacts.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    return (
      <div>
        <div className={cssStyles.roster_search_container}>
          <input
            className={classNames(cssStyles.input, cssStyles.roster_search)}
            type='text'
            placeholder={intl.formatMessage({
              id: 'all_contact_tab_search_placeholder',
            })}
            value={currentPrefix}
            onChange={this.onCurrentPrefixChange}
          />
          {isDMBlocked && !isOwnerAdmin ? null : (
            <Tippy
              className={commonStyles.info_tooltip}
              content='Invite contact'
              arrow
              placement='bottom'
            >
              <div
                role='button'
                onKeyDown={noop}
                tabIndex={0}
                className={cssStyles.plus_icon}
                onClick={this.onInviteClick}
              >
                <div />
              </div>
            </Tippy>
          )}
        </div>

        {allContacts.length > 0 && (
          <ListContainer
            items={allContacts}
            ContactComponent={Buddy}
            defaultLimit={LIMIT}
          />
        )}
        {allContacts.length === 0 && currentPrefix !== '' && (
          <div className={cssStyles.no_results_found}> No result found</div>
        )}

        {allContacts.length === 0 && <EmptyContactsContainer />}
      </div>
    );
  }
}

AllContactsContainer.propTypes = {
  guid: PropTypes.string,
  intl: PropTypes.object.isRequired,
  contacts: PropTypes.array,
  teamInfo: PropTypes.object.isRequired,
  owner: PropTypes.object.isRequired,
};

export default withCurrentOwner(
  withTeamInfo(
    allBuddies(
      ContactList(injectIntl(AllContactsContainer), 'allBuddiesIds', 'guid'),
      'guid'
    )
  )
);
