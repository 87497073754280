export default {
  recent_contacts: 'Mensajes directos recientes',
  recent_groups: 'Canales recientes',
  create_group: 'Nuevo canal',
  invite_contact: 'Invitar',
  invite_contact_button: 'Invitar',
  group_tab_search_placeholder: 'Buscar canales',
  all_contact_tab_search_placeholder: 'Buscar contactos',
  your_groups: 'Sus canales',
  all_contacts: 'Todos los contactos',
  join_group_button: '\u00danase a los canales',
  create_group_button: 'Crear canal',
  no_result_found: 'No se encontraron resultados',
  join_group_description: 'Descubra y \u00fanase a canales en su equipo',
  create_group_description: 'Cree un nuevo canal',
  invite_contact_description: 'Invite miembros a su equipo',
  invite_via_email: 'Invitar v\u00eda email',
};
