import React from 'react';
import { connect } from 'react-redux';
import AllChannelFeedContainer from '@/containers/AllChannelFeedContainer';
import { currentConversationSelector } from '@/selectors';
import { ALL_CHANNEL_FEED } from '@/utils/constants';
import Conversation from './Conversation';

function ConversationHandler(props) {
  const { currentConversation } = props;
  if (currentConversation === ALL_CHANNEL_FEED) {
    return <AllChannelFeedContainer />;
  }
  return <Conversation />;
}

const mapStateToProps = (state) => {
  const currentConversation = currentConversationSelector(state);
  return {
    currentConversation,
  };
};

export default connect(mapStateToProps)(ConversationHandler);
