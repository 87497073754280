import { ALL_CHANNEL_FEED } from '@/utils/constants';

const defaultState = {
  contact_proxy: {},
  current_conversation: {},
  conversation_associates: {},
  all_buddies_meta_proxy: {},
  all_groups_meta_proxy: {},
  recent_contacts_meta_proxy: {},
  top_recent_contacts_meta_proxy: {},
  bucketed_search_contacts_meta_proxy: {},
  selected_contacts_in_search: {},
};

export default (ActionTypes) => {
  return (state = defaultState, action) => {
    try {
      switch (action.type) {
        case ActionTypes.OPEN_CHANNEL_FEED: {
          const { ownerGuid } = action.payload;
          return {
            ...state,
            current_conversation: {
              ...state.current_conversation,
              [ownerGuid]: ALL_CHANNEL_FEED,
            },
          };
        }

        case ActionTypes.START_CHAT: {
          const { ownerGuid, bringToFront, jid } = action.payload;
          if (bringToFront) {
            return {
              ...state,
              current_conversation: {
                ...state.current_conversation,
                [ownerGuid]: jid,
              },
            };
          }
          return state;
        }

        case ActionTypes.CONTACT_PROXY: {
          const { addedContacts, removedIds, ownerGuid } = action.payload;
          const contacts =
            (state.contact_proxy && { ...state.contact_proxy[ownerGuid] }) ||
            {};
          if (addedContacts)
            addedContacts.forEach((contact) => {
              contacts[contact.jid] = contact;
            });
          if (removedIds)
            removedIds.forEach((id) => {
              delete contacts[id];
            });
          return {
            ...state,
            contact_proxy: { ...state.contact_proxy, [ownerGuid]: contacts },
          };
        }
        case ActionTypes.UPDATE_CONVERSATION_ASSOCIATES: {
          /*
            associates should be an array. 
          */
          const { ownerGuid, peerJid, associates } = action.payload;
          if (!state.conversation_associates[ownerGuid]) {
            state.conversation_associates[ownerGuid] = { [peerJid]: {} };
          }
          if (!state.conversation_associates[ownerGuid][peerJid]) {
            state.conversation_associates[ownerGuid][peerJid] = {};
          }

          return {
            ...state,
            conversation_associates: {
              ...state.conversation_associates,
              [ownerGuid]: {
                ...state.conversation_associates[ownerGuid],
                [peerJid]: associates,
              },
            },
          };
        }
        case ActionTypes.ALL_BUDDIES_META_PROXY:
          return {
            ...state,
            all_buddies_meta_proxy: {
              ...state.all_buddies_meta_proxy,
              ...action.payload,
            },
          };
        case ActionTypes.ALL_GROUPS_META_PROXY:
          return {
            ...state,
            all_groups_meta_proxy: {
              ...state.all_groups_meta_proxy,
              ...action.payload,
            },
          };
        case ActionTypes.RECENT_CONTACTS_META_PROXY:
          return {
            ...state,
            recent_contacts_meta_proxy: {
              ...state.recent_contacts_meta_proxy,
              ...action.payload,
            },
          };
        case ActionTypes.SELECTED_CONTACTS_IN_SEARCH:
          return {
            ...state,
            selected_contacts_in_search: action.payload,
          };
        default:
          return state;
      }
    } catch (error) {
      console.error(
        `Error in contact reducer: ${error.message || error.code || error}`,
        error
      );
    }
    return state;
  };
};
