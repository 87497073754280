import React, { useCallback, useEffect, useRef, useState } from 'react';
import Popover from '@/components/common/Popover';
import noop from '@/utils/noop';
import Item from '../Item';
import CssStyles from './MoreOptions.css';

const PopOverContent = React.memo(({ menuItems, onAppClick }) => {
  return (
    <div>
      {menuItems.map((item) => (
        <Item key={item.id} menuItem={item} onAppClick={onAppClick} overflow />
      ))}
    </div>
  );
});

const MoreOptions = React.memo((props) => {
  const [open, setOpen] = useState(false);
  const [popoverPosition, setPopoverPosition] = useState('top-right');
  const {
    MenuItems,
    setSubMenuOpen,
    closeBothPopover,
    onAppClick,
    containerRef,
  } = props;
  const arrowRef = useRef(null);

  const onclick = useCallback(
    function () {
      setOpen(!open);
      setSubMenuOpen(!open);
    },
    [setOpen, setSubMenuOpen, open]
  );

  const onClickOutside = useCallback(
    (event) => {
      const { toElement } = event;
      const containerNode = containerRef.current;

      if (
        arrowRef.current === toElement ||
        arrowRef.current.contains(toElement)
      ) {
        setOpen(false);
        setSubMenuOpen(false);
      } else if (!containerNode.contains(toElement)) {
        closeBothPopover();
      }
    },
    [closeBothPopover, containerRef, setSubMenuOpen]
  );

  useEffect(() => {
    const rect = arrowRef.current.getBoundingClientRect();
    const HEIGHT_OF_ITEM = 40;
    const contentHeight = MenuItems.length * HEIGHT_OF_ITEM;

    if (contentHeight >= rect.top) {
      setPopoverPosition('bottom-right');
    }
  }, [MenuItems]);

  return (
    <Popover
      position={popoverPosition}
      popOverContent={
        <PopOverContent menuItems={MenuItems} onAppClick={onAppClick} />
      }
      open={open}
      heightDifference={5}
      widthDifference={-100}
      onClickOutside={onClickOutside}
      triggerClass={CssStyles.MoreOptionsTrigger}
      popOverClass={CssStyles.popOverClass}
    >
      <div
        className={CssStyles.circleWrapper}
        role='button'
        onClick={onclick}
        tabIndex={-1}
        onKeyDown={noop}
        ref={arrowRef}
      >
        <div className={CssStyles.upperArrow} />
      </div>
    </Popover>
  );
});

export default MoreOptions;
