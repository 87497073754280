import React, { useState, useEffect, useCallback, useRef } from 'react';
import { exponentialBackoff } from '@/utils/index';

const MAX_TRIES = 3;
const hiddenStyles = {
  display: 'none',
};

/**
 * @typedef {Object} ImageProps - The props to the image component.
 * @property {string} src - Source URL of our image.
 * @property {string} [alt] - Alt attribute
 * @property {function} [onLoad] - Callback executed when Image loads
 * @property {boolean?} [autoHeight] - Whether image needs to take height automatically.
 */

/**
 * The Image Component.
 * @param {ImageProps} props - Props for the Image component
 */
function Image({
  src,
  alt = '',
  Loader = ({ style, className }) => (
    <div style={style} className={className} />
  ),
  loaderProps,
  onError,
  onLoad,
  autoHeight,
  fallbackUrl,
  showAltText = true,
  ...rest
}) {
  const srcRef = useRef(src);
  const errorHandlerRef = useRef();
  const [isLoaded, setLoaded] = useState(false);

  const onNetworkChange = useCallback(() => {
    const separator = src.includes('?') ? '&' : '?';
    if (!isLoaded && srcRef.current) {
      srcRef.current.src = `${src}${separator}t=${Date.now()}`;
    }
  }, [isLoaded, src]);

  useEffect(() => {
    window.addEventListener('online', onNetworkChange);
    return () => {
      window.removeEventListener('online', onNetworkChange);
    };
  });

  const onImageError = useCallback(
    (e) => {
      onError?.(e);
      if (!errorHandlerRef.current) {
        errorHandlerRef.current = exponentialBackoff(
          onNetworkChange,
          MAX_TRIES
        );
      }
      const keepTrying = errorHandlerRef.current();
      if (!keepTrying) {
        if (fallbackUrl) {
          srcRef.current.src = fallbackUrl;
        }
      }
    },
    [fallbackUrl, onError, onNetworkChange]
  );

  useEffect(() => {
    setLoaded(false);
    errorHandlerRef.current = null;
  }, [src]);

  const onImageLoad = useCallback(
    (e) => {
      errorHandlerRef.current = null;
      setLoaded(true);
      if (autoHeight) {
        // need to set a height initially so that the image occupies required area until it is rendered
        // autoHeight will be set when img could have dynamic width due to max-width
        srcRef.current.style.height = null;
      }
      if (onLoad) {
        onLoad(e);
      }
    },
    [autoHeight, onLoad]
  );

  if (src) {
    return (
      <>
        <img
          {...rest}
          src={src}
          alt={alt}
          ref={srcRef}
          onLoad={onImageLoad}
          onError={onImageError}
          style={!isLoaded ? hiddenStyles : rest.style || null}
        />
        {!isLoaded ? (
          <Loader
            style={rest.style || null}
            className={rest.className}
            {...loaderProps}
          />
        ) : null}
      </>
    );
  }

  if (showAltText) {
    return <span>{alt}</span>;
  }

  return null;
}

export default React.memo(Image);
