import difference from 'lodash/difference';
import union from 'lodash/union';
import memoize from 'memoize-one';
import store from '@/store';
import { Actions } from '@/actions/restricted';
import bridge from '@/utils/bridge';
import {
  SORTABLE_STORE,
  CONVERSATION_STORE,
  PENDING_PINS_STORE,
  VIEW_PREFERENCES_STORE,
} from '@/utils/storeNames';
import connectionState, { CONNECTION_STATES } from '@/utils/ConnectionState';
import {
  ownerGuidSelector,
  currentConversationSelector,
  ownerObjSelector,
  conversationBucketsSelector,
  pinsOrderByGuidSelector,
  conversationBucketsByGuidSelector,
  currentConversationByGuidSelector,
  sessionByGuidSelector,
} from '@/selectors';

import getConversation from '@/Models/getContact';
import storeSubscribe from '@/subscribe';
import { ALL_CHANNEL_FEED } from '@/utils/constants';
import {
  VIEW_MODE_KEYS,
  MODES,
  PINNED,
  OPEN,
  MUTED,
} from '@/components/ActiveChats/constants';
import { maybe, isGroup } from '@/utils';
import { markLatestMessageAsRead } from '@/utils/message';
import { logMedusaGenericEvent } from '@/utils/logAnalytics';
import PerformanceLogger, {
  OPEN_CONVERSATION,
} from '@/utils/PerformanceLogger';
import { getContacts } from './ContactProxy';
import { getCounter } from './utilities';

const decodeJid = (jid) => jid.split(/\/|--/)[1];
const defaultPinsOrder = [];

const pinnedChats = {};
const fetchedPinsOnce = {};
/**
 * Chats should not start persisting until they have been fetched from IDB and stored into the store.
 */
const beginPersistingChats = {};
const CONNECTED = 'connected';
const lastOpenedKey = 'lastOpenJid';

const DEFAULT_MODES = {
  [PINNED]: MODES.COMFORTABLE,
  [OPEN]: MODES.COMFORTABLE,
  [MUTED]: MODES.COMPACT,
};

/* Because memoizeOne has a cache size of one, we need a factory method. */
function memoizedConcatFactory() {
  /* Calling this method returns with a memoized function based on ownerGuid */
  /* This probably calls for functional programming */
  return memoize((ownerGuid) =>
    memoize(({ pinned, open, muted }) =>
      pinned.concat(open, { jid: ALL_CHANNEL_FEED, ownerGuid }, muted)
    )
  );
}

const memoizedConcatByOwnerGuid = memoizedConcatFactory();

function getAllConversations(ownerGuid) {
  const storeState = store.getState();
  const getConversationBucketsByGuid = conversationBucketsByGuidSelector(
    storeState
  );
  const conversationBuckets = getConversationBucketsByGuid(ownerGuid);
  return memoizedConcatByOwnerGuid(ownerGuid)(conversationBuckets);
}

function getConversationIndex(jid, ownerGuid) {
  const allConversations = getAllConversations(ownerGuid);
  return allConversations.findIndex(({ jid: peerjid }) => peerjid === jid);
}

function getPinnedChatsOrder(ownerGuid) {
  const getPinsOrderByGuid = pinsOrderByGuidSelector(store.getState());
  return getPinsOrderByGuid(ownerGuid);
}

/**
 * The Proxy for everything related to Active Chats.
 * This class is responsible for communication with redux store, with Hebe and storing stuff in the IndexedDB.
 */
class ChatsProxy {
  counter = getCounter();

  removedContacts = {};

  constructor() {
    this.subscriptions = [
      bridge.subscribe('/openConversation', this.openConversation),
      bridge.subscribe(
        '/hydra/pin_notification',
        (ownerGuid, { addedPins, removedPins }) => {
          this.syncPinsWithStore(ownerGuid, { addedPins, removedPins });
          this.setPinsIntoDb(ownerGuid);
        }
      ),
      bridge.subscribe('/conversation', this.onConversationUpdate),
      bridge.subscribe('/contact/delete/group', this.removeConversation),
      bridge.subscribe('/contact/delete/buddy', this.removeConversation),
    ];
    connectionState.subscribe(this.onConnectionChange);
    storeSubscribe(
      currentConversationSelector,
      this.onCurrentConversationChange
    );
  }

  onConnectionChange = async (isConnected, status) => {
    if (CONNECTION_STATES.CONNECTED === status) {
      await this.processOfflinePins();
    }
    this.fetchPins(isConnected, status);
  };

  onConversationUpdate = (conv, action) => {
    if (action !== 'onKicked') {
      return;
    }

    const { peer } = conv;
    if (!peer) {
      return;
    }

    this.removeConversation(peer);
  };

  onCurrentConversationChange = (openConversation, prevOpenConversation) => {
    const ownerGuid = ownerGuidSelector(store.getState());
    if (ownerGuid && prevOpenConversation !== openConversation) {
      bridge.tell('Storage', 'set', [
        CONVERSATION_STORE,
        `${ownerGuid}_${lastOpenedKey}`,
        openConversation,
      ]);
    }
  };

  saveViewPreferences(bucketKey, bucketValue) {
    bridge.tell('Storage', 'set', [
      VIEW_PREFERENCES_STORE,
      bucketKey,
      bucketValue,
    ]);
  }

  async getViewPreferences() {
    const preferences = await bridge.ask('Storage', 'getAll', [
      'view_preference',
    ]);
    return Object.entries(VIEW_MODE_KEYS)
      .map(([bucket, storeKey]) => ({
        [bucket]: preferences[storeKey] || DEFAULT_MODES[bucket],
      }))
      .reduce(
        (prefs, currentPref) => ({
          ...prefs,
          ...currentPref,
        }),
        {}
      );
  }

  unsubscribe = () => {
    bridge.unsubscribeAll(this.subscriptions);
    connectionState.unsubscribe(this.onConnectionChange);
  };

  syncPinsWithStore = (
    ownerGuid,
    { addedPins, removedPins },
    updateLastFetch = false
  ) => {
    if (addedPins && addedPins.length) {
      if (updateLastFetch) {
        pinnedChats[ownerGuid] = {
          conversations: addedPins,
          lastFetched: Date.now(),
        };
      }
      store.dispatch(
        Actions.add_pinned_chats({
          conversations: addedPins,
          ownerGuid,
        })
      );
    }
    if (removedPins && removedPins.length) {
      store.dispatch(
        Actions.remove_pinned_chats({
          conversations: removedPins,
          ownerGuid,
        })
      );
    }
  };

  /**
   * Persists the pinned chats order into the IndexedDb.
   * @param {string} ownerGuid The ownerGuid of the user
   * @param {Array<string>?} pinsOrder The order of pins to be saved into IndexedDb. Default value is the pins order from store.
   */
  setPinsIntoDb = (ownerGuid, pinsOrder) => {
    let pinsOrderFromStore = pinsOrder;
    if (!pinsOrder) {
      pinsOrderFromStore = getPinnedChatsOrder(ownerGuid);
    }
    const pinsOrderStr = pinsOrderFromStore
      .map((jid) => {
        if (isGroup(jid)) {
          return `session_${ownerGuid}@go.to/${jid}`;
        }
        return `${ownerGuid}@go.to--${jid}`;
      })
      .join('|');
    bridge.tell('Storage', 'set', [
      SORTABLE_STORE,
      `bucketPinned_session_${ownerGuid}@go.to`,
      pinsOrderStr,
    ]);
    this.persistActiveChats(ownerGuid);
  };

  /**
   * Gets the pinned chats order from IndexedDb.
   * @param {string} ownerGuid The owner guid of the team for which pinned chats order is needed
   */
  getPinsFromDb = async (ownerGuid) => {
    const response = await bridge.ask('Storage', 'get', [
      SORTABLE_STORE,
      `bucketPinned_session_${ownerGuid}@go.to`,
    ]);
    if (response && response.split) {
      const order = response.split('|').map(decodeJid);
      store.dispatch(
        Actions.pins_order_changed({
          order,
          ownerGuid,
        })
      );
      return order;
    }
    return defaultPinsOrder;
  };

  fetchPins = async (isConnected = true, status = connectionState.status) => {
    const ownerGuid = ownerGuidSelector(store.getState());
    if (isConnected) {
      fetchedPinsOnce[ownerGuid] = true;
      const response = await bridge.ask('HebeAPI', 'getPins', [
        ownerGuid,
      ]);
      /**
       * The getPins api of hebe uses xhr fetch in hydra, which returns two types of values on failure,
       * 1. undefined
       * 2. Error string
       * 
       * We should not move forward if we do not have a proper response from the server, mark fetchedPinsOnce
       * for the ownerGuid as false and return. 
       * 
       */
      if(!response){
        fetchedPinsOnce[ownerGuid] = false;
        return;
      }
      const { peers: pinsOnServer } = response;

      if(!pinsOnServer){
        fetchedPinsOnce[ownerGuid] = false;
        return;
      }
      const pinsOnLocal = await this.getPinsFromDb(ownerGuid);

      if (pinsOnLocal?.length > 0) {
        /* Pins that need to be removed from pinned chats from saved chats in conversation view restorer. */
        const removedPins = difference(pinsOnLocal, pinsOnServer);
        /* New pins should be displayed first. */
        const newPins = difference(pinsOnServer, pinsOnLocal);
        /* Newest pins should be on top, therefore we reverse the order obtained from server. */
        const pinnedChatJids = union(newPins.reverse(), pinsOnLocal);
        this.initPinnedChats(ownerGuid, pinnedChatJids);
        this.syncPinsWithStore(
          ownerGuid,
          {
            addedPins: pinnedChatJids,
            removedPins,
          },
          true
        );
        this.setPinsIntoDb(ownerGuid);
      } else {
        const meChatIndex = pinsOnServer.findIndex((jid) =>
          /Botguidformechat/gi.test(jid)
        );
        let addedPins;
        const meChatId =
          meChatIndex > -1 ? pinsOnServer.splice(meChatIndex, 1) : null;
        if (meChatId) {
          addedPins = [...pinsOnServer.reverse(), ...meChatId];
        } else {
          addedPins = pinsOnServer.reverse();
        }
        this.initPinnedChats(ownerGuid, addedPins);
        this.syncPinsWithStore(ownerGuid, { addedPins, removedPins: [] }, true);
        this.setPinsIntoDb(ownerGuid);
      }
    } else if (status === CONNECTION_STATES.DISCONNECTED) {
      fetchedPinsOnce[ownerGuid] = false;
    }
  };

  initPinnedChats(ownerGuid, conversations) {
    store.dispatch(
      Actions.init_pins({
        ownerGuid,
        conversations,
      })
    );
  }

  navigateConversation = (direction) => {
    const state = store.getState();
    /* This will always happen on the current ownerGuid */
    const ownerGuid = ownerGuidSelector(state);
    const currentJid = currentConversationSelector(state);
    const isCurrentJid = ({ jid }) => currentJid === jid;
    const conversationBuckets = conversationBucketsSelector(state);
    const allConversations = memoizedConcatByOwnerGuid(ownerGuid)(
      conversationBuckets
    );
    const currentIndex = allConversations.findIndex(isCurrentJid);
    let index = -1;
    if (/up/i.test(direction)) {
      // Navigate up
      if (currentIndex === 0) {
        index = allConversations.length - 1;
      } else {
        index = currentIndex - 1;
      }
    } else if (/down/i.test(direction)) {
      // Navigate down
      if (currentIndex === allConversations.length - 1) {
        index = 0;
      } else {
        index = currentIndex + 1;
      }
    }
    if (index !== -1) this.openConversation(allConversations[index], true);
  };

  reopenConversation = () => {
    const ownerGuid = ownerGuidSelector(store.getState());
    const removedContacts = this.removedContacts[ownerGuid];
    if (removedContacts && removedContacts.length) {
      this.openConversation(removedContacts.pop(), true);
    }
  };

  /**
   * @typedef {Object} MessageAnchor - A message anchor object to open conversation from a particular messageSid
   * @property {string} [mcId] - The message cache id
   * @property {string} msgSid - The unique messageSid which is also a timestamp
   * @property {string} distanceFromBottom - The distance of the message to render from the bottom
   * @property {string} timestamp - The timestamp of the message
   * @property {string?} source - The source due to which this message anchor was set. Can be `reply`, `search` or null
   */

  /**
   * ContactInfo - Pseudo contact like object
   * @typedef {Object} ContactInfo
   * @property {string} ownerGuid
   * @property {string} jid
   * @property {boolean} [pinned]
   * @property {string} [msgSid] - The message sid to open the conversation from
   */

  /**
   * Marks messages read for the closed conversation.
   * adds to removedContacts list, to re-open conv.
   * Removes conversation from the active chat list,
   * Starts a new conversation if any avialable next in list.
   * @param {ContactInfo} contactInfo should contain jid, ownerGuid and pinned prop
   * of the peer/conv to be removed.
   */
  removeConversation = async (contactInfo) => {
    const { ownerGuid, jid } = contactInfo;
    const storeState = store.getState();
    const getConversationBucketsByGuid = conversationBucketsByGuidSelector(
      storeState
    );
    const { pinned } = getConversationBucketsByGuid(ownerGuid);
    const isPinned = !!pinned.find(({ jid: contactJid }) => contactJid === jid);
    if (isPinned) {
      // Possible only if the channel is empty
      this.openNextConversation(contactInfo);
      /* Unpin and then remove */
      await this.togglePin(ownerGuid, jid, false);
      store.dispatch(Actions.remove_chat(contactInfo));
    } else {
      const conversation = getConversation(jid, ownerGuid);
      await markLatestMessageAsRead(conversation);
      this.openNextConversation(contactInfo);
      if (ownerGuid === ownerGuidSelector(storeState)) {
        if (!this.removedContacts[ownerGuid]) {
          this.removedContacts[ownerGuid] = [];
        }
        const removedContacts = this.removedContacts[ownerGuid];
        // TODO: should be pushed iff group wasn't deleted and user is part of the group.
        removedContacts.push(contactInfo);
      }
      store.dispatch(Actions.remove_chat(contactInfo));
    }
    this.persistActiveChats(ownerGuid);
  };

  /**
   * Find and open the next in line conversation in the Active Chats list.
   * @param {ContactInfo} prevContactInfo The contact info that is being closed
   */
  openNextConversation(prevContactInfo) {
    const { jid: prevContactJid, ownerGuid } = prevContactInfo;
    const storeState = store.getState();
    const currentOwnerGuid = ownerGuidSelector(storeState);
    /* Current Conversation for ownerGuid */
    const getCurrentConversationByGuid = currentConversationByGuidSelector(
      storeState
    );
    const currentConversationJid = getCurrentConversationByGuid(ownerGuid);
    const convIndex = getConversationIndex(currentConversationJid, ownerGuid);
    const allConversations = getAllConversations(ownerGuid);
    if (prevContactJid === currentConversationJid) {
      const conversationToOpen =
        allConversations[convIndex + 1] || allConversations[convIndex - 1];
      if (conversationToOpen) {
        /* Open the conversation with bringToFront only if current guid 
        matches that of the conversation that was closed */
        this.openConversation(
          conversationToOpen,
          currentOwnerGuid === ownerGuid
        );
      } else {
        // There are no channels left to open
        this.openConversation(
          { jid: ALL_CHANNEL_FEED, ownerGuid },
          currentOwnerGuid === ownerGuid
        );
      }
    }
  }

  getConversation = (jid, ownerGuid) => {
    const contact = getConversation(jid, ownerGuid);
    if (contact) {
      return Promise.resolve(contact);
    }
    return getContacts([jid], ownerGuid).then(([conversation]) => conversation);
  };

  /**
   * Fetches around messages from Hydra for given peer as `contactInfo` around the fetch `options`.
   * @param {FetchOptions} options - Contains `messageCacheId` and `sid` of the message. Should have at least one of the two.
   * @param {ContactInfo} contactInfo - Contains `ownerGuid` and `jid` of the peer for which we are fetching messages.
   * @returns {Promise} A promise that resolves to message sids and cacheIds of the fetched messages.
   */
  getAroundMessages = (options, contactInfo) => {
    const { messageCacheId, sid } = options;
    const { ownerGuid, jid } = contactInfo;
    const fetchMethod = `getMessagesAroundTimestamp`;
    return bridge.ask('MessageCache', fetchMethod, [
      { jid, ownerGuid },
      sid,
      messageCacheId,
      20,
      20,
    ]);
  };

  showDeletedToast = (jid) => {
    return bridge.ask('InAppNotificationStore', 'addNewNotification', [
      jid,
      'Sorry, the message you are trying to access is no longer available.',
      'warn',
      5000,
    ]);
  };

  /**
   * @typedef AnchorOptions
   * @property {string} msgSid The message sid of the anchor message
   * @property {string} jid - The jid of the chat to which message anchor is being set
   * @property {string} ownerGuid - The team guid for which message anchor is being set
   * @property {boolean?} paywallHit - Whether the msgSid has hit the paywall or not
   * @property {boolean?} highlight - Whether to highlight the rendered message. By default, we highlight the message.
   */

  /**
   * Checks for `msgSid` and sets the message anchor to the provided `msgSid`, `jid` and `ownerGuid`
   * @param {AnchorOptions} anchorOptions The options object containing information about the new message achor
   * @param {string} source - Whether source was 'search' or 'reply'
   */
  setMessageAnchor = (
    { msgSid, jid, ownerGuid, paywallHit, highlight = true },
    source = 'reply'
  ) => {
    if (msgSid) {
      const anchor = {
        msgSid,
        highlight,
        distanceFromTop: paywallHit ? 200 : 100,
        paywallHit,
        elementsEdge: 'top',
        source,
      };
      // Set the anchor before changing the conversation
      store.dispatch(
        Actions.update_message_anchor({
          ownerGuid,
          jid,
          anchor,
        })
      );
    }
  };

  /**
   * Unsets the message anchor for a `jid` and `ownerGuid`
   * @param {string} jid Contact for which you want to unset the message anchor
   * @param {string} ownerGuid Contact's ownerGuid
   */
  unsetMessageAnchor = (jid, ownerGuid) => {
    store.dispatch(
      Actions.update_message_anchor({
        ownerGuid,
        jid,
        anchor: null,
      })
    );
  };

  /**
   * @typedef MedusaParams
   * @property {boolean} messagesAvailable - Whether the message was already available (the within-25 message) or not.
   * @property {boolean?} jumpSuccessful - Whether the original message was displayed eventually
   * @property {string?} reason - The reason for message not showing up - Can be `deletion`, `paywall`, `network`, `other`
   * @property {string} source - Source of the jump trigger. Can be `reply` or `search`
   */

  /**
   * Uses the current active team and adds the given parameters to medusa.
   * Currently, we do not have a flow where we can "Jump to Message" across teams, so we can use the current session.
   * @param {MedusaParams} medusaParams - The medusa params that are needed for analytics.
   */
  addAnalytics = ({ messagesAvailable, jumpSuccessful, reason, source }) => {
    logMedusaGenericEvent('jump_to_message', {
      source,
      message_already_available: messagesAvailable,
      original_message_displayed: jumpSuccessful,
      failure_reason: reason,
    });
  };

  /**
   * Persist Active Chats from the store into the IndexedDb
   * @param {String} ownerGuid The ownerGuid for which we want to persist Active Chats
   */
  persistActiveChats = (ownerGuid) => {
    /**
     * If chats haven't been stored into the store, don't persist into the IDB.
     */
    if (beginPersistingChats[ownerGuid]) {
      const allConversations = getAllConversations(ownerGuid);
      bridge.tell('Storage', 'set', [
        CONVERSATION_STORE,
        ownerGuid,
        Object.values(allConversations),
      ]);
    }
  };

  /**
   * Opens a conversation and initialises conversation class at hydra end.
   * Can open a conversation for an inactive team as well.
   * @param {ContactInfo} contactInfo: The contact info object should contain jid, anchor and ownerGuid
   * @param {boolean} shouldOpen - Whether to open the chat window or not
   */
  openConversation = async (contactInfo, shouldOpen) => {
    if (shouldOpen === true) {
      PerformanceLogger.start(OPEN_CONVERSATION, contactInfo.jid);
    }
    const { ownerGuid, jid, bringToFront = false } = contactInfo;
    console.time(`conversation ${jid} opened`);

    this.setMessageAnchor(contactInfo, 'search');

    const state = store.getState();
    const getSessionFromGuid = sessionByGuidSelector(state);
    const session = getSessionFromGuid(ownerGuid);
    // TODO: Get owner for contact's session
    const owner = ownerObjSelector(state);
    if (jid === ALL_CHANNEL_FEED) {
      this.openChannelFeed(session.owner.ownerGuid);
    } else {
      const teamInfo = maybe(session, 'sessionInfo', 'teamInfo');
      const contact =
        (await this.getConversation(jid, ownerGuid)) || contactInfo;
      if (
        teamInfo.isDMBlocked &&
        !owner.isAdmin &&
        !contact.isAdmin &&
        contact.isBuddy &&
        !contact.isBot
      ) {
        bridge.tell('Shell', 'showDMBlockedError');
        return;
      }
      if (session && jid) {
        bridge.tell(`Session_${session.id}`, 'getConversation', [jid, true]);
      }
      const pinned = getPinnedChatsOrder(ownerGuid).includes(jid);
      store.dispatch(
        Actions.start_chat({
          pinned,
          ...contactInfo,
          bringToFront: shouldOpen === true || bringToFront,
        })
      );
      this.persistActiveChats(ownerGuid);
    }
  };

  openChannelFeed = (ownerGuid) =>
    store.dispatch(
      Actions.open_channel_feed({
        ownerGuid,
      })
    );

  /**
   * Processes offline Pins by getting them from the pending_pin_chats store
   */
  processOfflinePins = async () => {
    const ownerGuid = ownerGuidSelector(store.getState());
    const offlinePinnedItems = await bridge.ask('Storage', 'getAll', [
      PENDING_PINS_STORE,
      ownerGuid,
    ]);
    console.log('offlinePinnedItems', offlinePinnedItems);
    const pinnedPeers = [];
    const unpinnedPeers = [];
    Object.entries(offlinePinnedItems).forEach(([jid, isPinned]) => {
      if (isPinned) {
        pinnedPeers.push(jid);
      } else {
        unpinnedPeers.push(jid);
      }
    });
    const pendingTasks = [];
    if (pinnedPeers.length > 0) {
      pendingTasks.push(this.togglePin(ownerGuid, pinnedPeers, true));
    }
    if (unpinnedPeers.length > 0) {
      pendingTasks.push(this.togglePin(ownerGuid, unpinnedPeers, false));
    }
    return Promise.all(pendingTasks);
  };

  /**
   * Toggles the Chat Item's `pinned` status
   * @param {String} ownerGuid - The `ownerGuid` of the team for which the pinned chat item is being toggled.
   * @param {String|Array<String>} jid - The `jid` of the contact which needs to be toggled. Can be an array of jids as well.
   * @param {Boolean} pin - The new `pin` status of the chat item.
   */
  togglePin(ownerGuid, jid, pin) {
    const peers = typeof jid === 'string' ? [jid] : jid;
    peers.forEach((peer) =>
      bridge.tell('Storage', 'setImmediately', [PENDING_PINS_STORE, peer, pin])
    );
    this.togglePinInStore(ownerGuid, peers, pin);
    this.setPinsIntoDb(ownerGuid);
    if (connectionState.status === CONNECTED) {
      return bridge
        .ask('HebeAPI', 'togglePin', [ownerGuid, peers, pin])
        .then((response) => {
          console.log('togglePin:: response', response);
          if (response && !response.isError) {
            peers.forEach((peer) =>
              bridge.tell('Storage', 'removeImmediately', [
                PENDING_PINS_STORE,
                peer,
              ])
            );
          }
        });
    }
    return Promise.resolve('will be done with internet');
  }

  savePinsOrder = (ownerGuid, pinsOrder) => {
    store.dispatch(
      Actions.pins_order_changed({
        order: pinsOrder,
        ownerGuid,
      })
    );
    this.setPinsIntoDb(ownerGuid, pinsOrder);
  };

  /**
   * Synchronously toggles the status of the pin in the Redux store.
   * @param {String} ownerGuid
   * @param {String|Array<String>} peers - Collection of jids or a single jid
   * @param {Boolean} pin - New status of the pin in redux store
   */
  togglePinInStore = (ownerGuid, peers, pin) => {
    if (pin) {
      store.dispatch(
        Actions.add_pinned_chats({
          conversations: peers,
          ownerGuid,
        })
      );
    } else {
      store.dispatch(
        Actions.remove_pinned_chats({
          conversations: peers,
          ownerGuid,
        })
      );
    }
  };

  subscribe(ownerGuid) {
    const setId = this._subscribe(ownerGuid);
    // TODO: fetch all conversations with unread messages for this team from hydra
    return {
      update: (updatedOwnerGuid) => this._subscribe(updatedOwnerGuid, setId),
    };
  }

  async _subscribe(ownerGuid, setId = `${this.counter.next().value}`) {
    if (ownerGuid) {
      /* For faster loading of pins from local */
      const pinsOrder = await this.getPinsFromDb(ownerGuid);
      this.syncPinsWithStore(ownerGuid, { addedPins: pinsOrder }, false);
      const conversationToRestore = await bridge.ask('Storage', 'get', [
        CONVERSATION_STORE,
        `${ownerGuid}_${lastOpenedKey}`,
      ]);
      /* Get conversations from IndexedDB */
      const conversations =
        (await bridge.ask('Storage', 'get', [CONVERSATION_STORE, ownerGuid])) ||
        [];

      store.dispatch(
        Actions.init_chats({
          conversations,
          ownerGuid,
        })
      );
      /**
       * We can now start persisting chats since we have fetched them from IDB into the store.
       */
      beginPersistingChats[ownerGuid] = true;
      if (conversationToRestore === ALL_CHANNEL_FEED) {
        this.openChannelFeed(ownerGuid);
      } else if (conversationToRestore) {
        this.openConversation({ jid: conversationToRestore, ownerGuid }, true);
      } else if (conversations.length) {
        this.openConversation({ ...conversations[0], ownerGuid }, true);
      }
      if (
        !fetchedPinsOnce[ownerGuid] ||
        !pinnedChats[ownerGuid] ||
        (pinnedChats[ownerGuid] &&
          pinnedChats[ownerGuid].lastFetched - new Date().getTime() >
            60 * 30 * 1000)
      ) {
        this.fetchPins();
      }
      return setId;
    }
    return null;
  }
}

export default new ChatsProxy();
