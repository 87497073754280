import debounce from 'lodash/debounce';
import pull from 'lodash/pull';
import bridge from './bridge';

export const CONNECTION_STATES = {
  CONNECTED: 'connected',
  DISCONNECTED: 'disconnected',
  CONNECTING: 'connecting',
};

/**
 * Abstraction to handle change in Hydra's connection status.
 * Maintains `status` and `changeHandlers`.
 */
class ConnectionState {
  /** The `status` of Hydra for current connection state. */
  status = 'connected';

  /** List of callbacks that will be invoked whenever there's a change in Hydra's connection state  */
  changeHandlers = [];

  constructor() {
    bridge.subscribe('/connectionState/changed', this.onConnectionStateChange);
  }

  /**
   * @callback subscriptionCallback
   * @param {boolean} isSessionConnected
   * @param {string} status - Can be either `connected`, `disconnected` etc.
   */

  /**
   * Subscribe to a connection state change.
   * @param {...subscriptionCallback} cb - The list of callbacks that subscribe on to the Hydra Network State.
   */
  subscribe = (...cb) => {
    this.changeHandlers.push(...cb);
  };

  /**
   * Unsubscribe to connection state change. Should be called in case callback needs to be removed.
   * @param ...cb The list of callbacks.
   */
  unsubscribe = (...cb) => {
    this.changeHandlers = pull(this.changeHandlers, ...cb);
  };

  onConnectionStateChange = debounce((isSessionConnected, status) => {
    console.log(
      'onConnectionStateChange isSessionConnected',
      isSessionConnected,
      'status',
      status
    );
    this.status = status;
    this.changeHandlers.forEach((cb) => cb && cb(isSessionConnected, status));
  }, 200);
}
export default new ConnectionState();
