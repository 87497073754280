import React, { PureComponent } from 'react';
import ContactList from '@/connectHOCs/Contacts/ContactList';
import ContactProxy from '@/Proxies/ContactProxy';
import cssStyles from './ContactPills.css';

type Props = typeof ContactPills.defaultProps & {
  removePill: (id: string) => void;
};

class ContactPills extends PureComponent<Props> {
  contactProxySubscription = undefined;

  static defaultProps = {
    contactIds: [],
    ownerGuid: '',
    contacts: [],
    className: '',
    showRemovePill: false,
  };

  constructor(props) {
    super(props);
    const { ownerGuid, contactIds } = props;
    this.contactProxySubscription = ContactProxy.subscribe(
      ownerGuid,
      contactIds
    );
  }

  componentDidUpdate() {
    const { ownerGuid, contactIds } = this.props;
    this.contactProxySubscription.update(ownerGuid, contactIds);
  }

  componentWillUnmount() {
    if (this.contactProxySubscription) {
      this.contactProxySubscription.unsubscribe();
    }
  }

  render() {
    const {
      contacts: selectedContacts,
      showRemovePill,
      removePill,
      className,
    } = this.props;

    const computedClassName = `${cssStyles.pills} ${className}`;

    if (selectedContacts.length <= 0) {
      return null;
    }
    return (
      <div className={computedClassName}>
        {selectedContacts.map((c) => (
          <div key={c.id}>
            {c.name}{' '}
            {showRemovePill && (
              <span
                onClick={() => removePill(c.jid)}
                role='button'
                onKeyDown={() => {}}
                tabIndex={0}
              >
                &times;
              </span>
            )}
          </div>
        ))}
      </div>
    );
  }
}

// FIXME: Need to type these HOCs correctly and undo this hack 😬
export default (ContactList(
  ContactPills,
  'contactIds',
  'ownerGuid'
) as unknown) as typeof ContactPills;
