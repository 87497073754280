import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { currentSessionSelector } from '@/selectors';

const mapStateToProps = createStructuredSelector({
  currentSession: currentSessionSelector,
});
// export default function (component) {
export default connect(mapStateToProps);
// }
