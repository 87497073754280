/* eslint-disable no-use-before-define */

// Hydra's version: https://github.com/talk-to/hydra/blob/master/src/client_base/util/HyperLinkUrlEnvelope.js

// excluding punctuation chars from end url match - . , ; ! : ( )
const _urlRegex =
  // eslint-disable-next-line no-control-regex , no-useless-escape
  /^([^]*?)((?:\b(?:https?|ftp|file):\/\/+|www\.)(?:[\w#@%\/\\\$\(\)~\?\+\-=&\{\}\[\]\'\*\.,;!:]|[^\x00-\x7F])*(?:[\w#@%\/\\\$~\?\+=&\{\}\[\]\'\*]|[^\x00-\x7F]))([^]*)$/i;

const _getMatchParts = function (text) {
  const match = _urlRegex.exec(text);
  if (!match) return false;

  const pre = match[1];
  let url = match[2];
  let post = match[3];

  // check if url was surrounded by brackets
  const preLastChar = pre[pre.length - 1];
  const urlLastChar = url[url.length - 1];
  if (
    (preLastChar === '(' && urlLastChar === ')') ||
    (preLastChar === '[' && urlLastChar === ']')
  ) {
    post = urlLastChar + post;
    url = url.slice(0, -1);
  }
  return {
    pre,
    url,
    post,
  };
};

const getOuterHTML = function (elem) {
  return elem.outerHTML || new XMLSerializer().serializeToString(elem);
};

const _createAnchorNode = function (url) {
  const elem = document.createElement('a');
  elem.href = url;
  elem.textContent = url;
  return _convertAnchorNode(elem);
};

const _convertAnchorNode = function (anchor) {
  return anchor.cloneNode(true);
};

const _convertOtherNodes = function (elem) {
  const clonedNode = elem.cloneNode(false);
  elem.childNodes.forEach(function (n) {
    clonedNode.appendChild(hyperLinkURLsInElement(n));
  });
  return clonedNode;
};

const _createReplacementsForTextNode = function (elem) {
  const parts = _getMatchParts(elem.data);
  return parts
    ? [
        document.createTextNode(parts.pre),
        _createAnchorNode(parts.url),
        hyperLinkURLsInElement(document.createTextNode(parts.post)),
      ]
    : [elem.cloneNode(true)];
};

const _convertTextNode = function (elem) {
  const node = document.createDocumentFragment();
  const replacementNodes = _createReplacementsForTextNode(elem);
  replacementNodes.forEach(function (n) {
    node.appendChild(n);
  });

  return node;
};

const hyperLinkURLsInElement = function (elem) {
  const nodeName = elem.nodeName.toLowerCase();
  switch (nodeName) {
    case 'a':
      return _convertAnchorNode(elem);
    case '#text':
      return _convertTextNode(elem);
    default:
      return _convertOtherNodes(elem);
  }
};

const hyperLinkURLs = function (text) {
  const textNode = document.createTextNode(text);
  const nl = hyperLinkURLsInElement(textNode).childNodes;
  let result = '';
  for (let i = 0; i < nl.length; i += 1) {
    const nodeName = nl[i].nodeName.toLowerCase();
    result += nodeName === '#text' ? nl[i].data : getOuterHTML(nl[i]);
  }
  return result;
};

const hyperLinksInText = function (text) {
  const hyperLinks = [];
  let done = false;
  while (!done) {
    done = true;
    const parts = _getMatchParts(text);
    if (parts.url) {
      hyperLinks.push(parts.url);
      text = parts.post;
      done = false;
    }
  }
  return hyperLinks;
};

const hyperLinkUrlsInMarkup = function (text) {
  // TODO: need to handle case when there are two or more urls of same domain and includes base url
  // e.g link[0] = www.google.com & link[1] = www.google.com?id=123
  const links = hyperLinksInText(text);
  links.sort((a, b) => a.length - b.length);
  links.forEach(function (link) {
    text = text.replace(
      new RegExp(_escapeRegExp(link), 'g'),
      `<a href='${link}'>${link}</a>`
    );
  });
  return text;
};

function _escapeRegExp(string) {
  return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export {
  hyperLinkURLsInElement,
  hyperLinkURLs,
  hyperLinkUrlsInMarkup,
  hyperLinksInText,
};
