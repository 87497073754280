import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { currentSessionIdSelector, allSessionsSelector } from '@/selectors';
import TeamSwitcher from '../components/teamSwitcher/TeamSwitcher';

const mapStateToProps = createStructuredSelector({
  currentSessionId: currentSessionIdSelector,
  sessions: allSessionsSelector,
});

export default connect(mapStateToProps)(TeamSwitcher);
