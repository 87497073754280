export default {
  text_inviteUserByEmail: 'Tip: Enter an Email ID to invite users.',
  error_ExternalContactsDisbaled:
    'External contacts are disabled for your organisation. You can only add users @',
  default_value: 'Search Contacts and Channels',
  talk_to: 'Talk.to',
  startChatWithEmail: 'Enter an email id to start chat',
  ext_contacts_disabled:
    'External contacts are disabled for your organisation. You can only add users @',
  tip_email: 'Tip: Enter an Email ID to invite users.',
  tab_add_people: 'Press {button} to add more people',
  open_groups_separator: 'Public Channels',
  dept_search_separator: 'Job Title Department',
  other_contacts_separator: 'Contacts not in your team',
  invited_but_not_joined: 'Invited but not joined',
  start_new_chat: 'Start a chat',
  join_button: 'Join',
  recommended_contacts_separator: 'Not in this channel',
  add_button: 'Add',
  invite_mode: 'Invite',
  invited_mode: 'Invite Sent',
  frequent: 'frequent',
  recent: 'recent',
  tab: 'tab',
  temp_search_channel: 'Type an email id to add member',
  channel_member_count: '{count} Members',
};
