import React from 'react';
import { Image } from '@/components/common';
import paywall from '@/assets/paywall.svg';
import styles from './PaywallContent.css';

export default function PaywallContent({ title, description, children }) {
  return (
    <div className={styles.PaywallContent}>
      <Image src={paywall} alt='Upgrade to Flock PRO' />
      <h2>{title}</h2>
      <div className={styles.description}>{description}</div>
      {children}
    </div>
  );
}
