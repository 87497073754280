import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import memoize from 'memoize-one';
import groupDiscoveryModalStyles from '@/components/Modal/GroupDiscoveryModal/GroupDiscoveryModalComponent.css';
import commonStyles from '@/components/common/common.css';
import { getMemberCount } from '@/utils';

class GroupDiscoveryPublicChannelsExist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: '',
    };
  }

  getFilteredList = memoize((searchTerm, publicChannelsList) => {
    return publicChannelsList.filter(
      (group) =>
        group.chatName.toLowerCase().indexOf(searchTerm.toLowerCase()) === 0 ||
        group.description.toLowerCase().indexOf(searchTerm.toLowerCase()) === 0
    );
  });

  renderPublicChannelsList = () => {
    const { searchTerm } = this.state;

    const {
      showButtonLoadingSpinner,
      currentSelectedChannel,
      maskChannelMemberCountAfter,
    } = this.props;

    const { allPublicChannels, joinChannel } = this.props;

    const allPublicChannelsList = this.getFilteredList(
      searchTerm,
      allPublicChannels
    );

    allPublicChannelsList.sort((a, b) => {
      return b.memberCount - a.memberCount;
    });

    return (
      <div className={groupDiscoveryModalStyles.public_channels_list}>
        {allPublicChannelsList.length > 0 && (
          <div>
            {allPublicChannelsList.map((channel) => (
              <div
                key={channel.id}
                className={groupDiscoveryModalStyles.channel_container}
              >
                <div className={groupDiscoveryModalStyles.channel_image}>
                  <img src={channel.imageUrl} alt='Channel dp' />
                </div>
                <div className={groupDiscoveryModalStyles.channel_details}>
                  <div className={groupDiscoveryModalStyles.channel_name}>
                    {channel.chatName}
                  </div>
                  <div
                    className={groupDiscoveryModalStyles.channel_member_count}
                  >
                    {getMemberCount(
                      channel.memberCount,
                      maskChannelMemberCountAfter
                    )}{' '}
                    Members
                  </div>
                  <div
                    className={groupDiscoveryModalStyles.channel_description}
                  >
                    {channel.description}
                  </div>
                  {channel.creatorName && (
                    <div className={groupDiscoveryModalStyles.channel_creator}>
                      Created by {channel.creatorName}
                    </div>
                  )}
                </div>
                <button
                  type='button'
                  className={classNames(
                    commonStyles.btn,
                    commonStyles.btn__line_green,
                    {
                      [groupDiscoveryModalStyles.btn_loading_green]:
                        showButtonLoadingSpinner &&
                        currentSelectedChannel === channel.jid,
                    }
                  )}
                  onClick={() => joinChannel(channel)}
                >
                  {!channel.isJoined && <span>Join</span>}
                  {channel.isJoined && <span>Open</span>}
                </button>
              </div>
            ))}
          </div>
        )}

        {allPublicChannelsList.length === 0 && searchTerm !== '' && (
          <div className={groupDiscoveryModalStyles.no_results}>
            <FormattedMessage id='no_results_found' />
          </div>
        )}
        {allPublicChannelsList.length === 0 && searchTerm === '' && (
          <div className={groupDiscoveryModalStyles.channels_loading_spinner} />
        )}
      </div>
    );
  };

  onSearch = (event) => {
    this.setState({
      searchTerm: event.target.value,
    });
  };

  render() {
    const { createChannel } = this.props;
    return (
      <div className={groupDiscoveryModalStyles.join_public_channels_modal}>
        <div className={groupDiscoveryModalStyles.modal_title}>
          <FormattedMessage id='join_group_description' />
        </div>
        <div className={groupDiscoveryModalStyles.modal_subtitle}>
          <FormattedMessage id='discover_group_disc' />
        </div>

        <div className={commonStyles.input_wrapper}>
          <input
            type='text'
            placeholder='Search Channels'
            onChange={this.onSearch}
            className={classNames(
              commonStyles.input_box,
              groupDiscoveryModalStyles.search_channels_input
            )}
            /* eslint-disable-next-line jsx-a11y/no-autofocus */
            autoFocus
          />
        </div>

        {this.renderPublicChannelsList()}

        <div className={groupDiscoveryModalStyles.new_channel}>
          <span
            onClick={createChannel}
            onKeyDown={createChannel}
            role='button'
            tabIndex='-1'
          >
            <FormattedMessage id='start_new_channel_link_text' />
          </span>
        </div>
      </div>
    );
  }
}

export default GroupDiscoveryPublicChannelsExist;
