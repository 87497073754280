export default {
  sender_name_you: 'You',
  message_sentByMe_header: 'Me',
  guest: 'guest',
  phrase_invited: '{actor} added {user}',
  phrase_removed: '{actor} removed {user}',
  phrase_joined: '{user} joined',
  phrase_left: '{user} left',
  notify_channel_created:
    '{actor} created a {public_private_channel_type} channel',
  notify_channel_created_with_name:
    '{actor} created a {public_private_channel_type} channel "{channel_name}"',
  notify_channel_purpose_set:
    '{actor} set the channel purpose to "{channel_purpose}"',
  notify_channel_avatar_change: '{actor} changed the channel avatar',
  notify_add_channel_admin: '{actor} made {user} a channel admin',
  notify_remove_channel_admin:
    '{actor} revoked channel admin rights from {user}',
  notify_channel_name_change:
    '{actor} changed the channel name to "{channel_name}"',
  notify_channel_type_change:
    '{actor} changed the channel type to {public_private_channel_type}',
  notify_channel_purpose_remove: '{actor} cleared the channel purpose',
  notify_channel_purpose_change:
    '{actor} changed the channel purpose to "{channel_purpose}"',
  notify_channel_grant_admin:
    '{actor} changed the channel setting to "admins can add/remove members"',
  notify_channel_grant_everyone:
    '{actor} changed the channel setting to "everyone can add/remove members"',
};
