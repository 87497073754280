export default {
  contact_support: 'Contactar con el servicio de asistencia',
  keyboard_shortcuts: 'Atajos del teclado',
  audio_alerts: 'Alertas en audio',
  popup_notifications: 'Notificaciones emergentes',
  video_ringer: 'Video call ringer',
  quit_flock: 'Salir de Flock',
  report_bug: 'Informar sobre un error',
  set_status: 'Establece un estado...',
  manage_apps: 'Administra tus aplicaciones',
  notification_preferences: 'Preferencias de notificaciones',
  add_team: 'A\u00f1adir nuevo equipo',
  language_settings: 'Ajustes de idioma',
  language_text: 'Para todas las comunicaciones se usa el idioma seleccionado',
  language_change_note:
    'Nota: El cambio de idioma se reflejar\u00e1 en tu web y los clientes de escritorio.',
  invite: '+ Invitar',
  invite_description: 'Invitar miembros a tu equipo',
  browse_apps: 'Buscar aplicaciones',
  manage_team: 'Administrar tu equipo',
  stop_notifications: 'Dejar de recibir notificaciones',
};
