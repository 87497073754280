import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import currentOwner from '@/subscribeHOCs/owner';
import currentSessionIdHOC from '@/connectHOCs/Sessions/currentSession/id';
import { updateNotificationPreference } from '@/utils/ConversationUtils';
import IntlWrapper from '@/Wrappers/IntlWrapper';
import css from './PreferencesPopover.css';
import translations from './I18N';

const Preferences = [
  {
    id: 'ALL_MESSAGES',
    Title: () => (
      <p className={css.Option_Detail_Title}>
        <FormattedMessage id='channel_notifications_preferences_heading__notify_me_for' />{' '}
        <b>
          <FormattedMessage id='channel_notifications_preferences_heading__all_messages' />
        </b>
      </p>
    ),
    Description: () => null,
  },
  {
    id: 'MENTIONS',
    Title: () => (
      <p className={css.Option_Detail_Title}>
        <FormattedMessage id='channel_notifications_preferences_heading__notify_me_for' />{' '}
        <b>
          <FormattedMessage id='channel_notifications_preferences_heading__mentions_only' />
        </b>
      </p>
    ),
    Description: ({ name }) => (
      <p className={css.Option_Detail_Description}>
        <FormattedMessage id='channel_notifications_preferences_heading__notifications_for' />{' '}
        <b>
          <FormattedMessage
            id='channel_notifications_preferences_heading__mentions_desc'
            values={{ user_name: name }}
          />
        </b>
      </p>
    ),
  },
  {
    id: 'DIRECT_MENTION',
    Title: () => (
      <p className={css.Option_Detail_Title}>
        <FormattedMessage id='channel_notifications_preferences_heading__notify_me_for' />{' '}
        <b>
          <FormattedMessage id='channel_notifications_preferences_heading__direct_mention' />
        </b>
        {/* Notify me for <b>direct mentions only</b> */}
      </p>
    ),
    Description: ({ name }) => (
      <p className={css.Option_Detail_Description}>
        <FormattedMessage id='channel_notifications_preferences_heading__notifications_for' />{' '}
        <b>
          <FormattedMessage
            id='channel_notifications_preferences_heading__direct_mention_desc'
            values={{ user_name: name }}
          />
        </b>
        {/* You&#39;ll get notifications for <b>@{name}</b> and nothing else */}
      </p>
    ),
  },
];

class PreferencesPopover extends PureComponent {
  static defaultProps = {
    peer: undefined,
    owner: undefined,
    currentSessionId: undefined,
  };

  // Local preference state, in case server takes time to update the peer object.
  state = {
    preference: undefined,
  };

  constructor(props) {
    super(props);

    const { peer: { notifyOn } = {} } = props;

    this.state = {
      preference: notifyOn,
    };
  }

  componentDidUpdate(prevProps) {
    const { peer: { notifyOn } = {} } = this.props;
    const { peer: { notifyOn: prevNotifyOn } = {} } = prevProps;
    if (prevNotifyOn !== notifyOn) {
      this.setState({
        preference: notifyOn,
      });
    }
  }

  onOptionClick = (preference) => {
    console.log('CONV/HEADER/Preference', preference);
    this.setState({
      preference,
    });
    const { currentSessionId, peer } = this.props;
    // NOTE: This component doesn't handles failure case for the below function.
    updateNotificationPreference(currentSessionId, peer.jid, preference);
  };

  render() {
    const { peer, owner } = this.props;
    if (!peer) {
      return null;
    }
    const { chatName } = owner;
    const { preference } = this.state;

    return (
      <div className={css.Wrapper}>
        <div className={css.Title}>
          <FormattedMessage id='channel_notifications_preferences__heading' />
        </div>
        <div className={css.Options_Wrapper}>
          {Preferences.map((Preference) => (
            <div
              key={Preference.id}
              className={css.Option}
              // onClick={() => this.onOptionClick(Preference.id)}
              // onKeyPress={() => this.onOptionClick(Preference.id)}
              role='button'
              tabIndex={-1}
            >
              <input
                type='radio'
                className={css.Option_Radio}
                id={Preference.id}
                name='notificationPreference'
                onChange={() => this.onOptionClick(Preference.id)}
                checked={preference === Preference.id}
              />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label className={css.Option_Detail} htmlFor={Preference.id}>
                <Preference.Title />
                <Preference.Description name={chatName} />
              </label>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default IntlWrapper(
  currentOwner(currentSessionIdHOC(PreferencesPopover)),
  translations
);
