export default {
  new_chat_shortcut_helper: 'Nueva conversaci\u00f3n',
  keyboard_shortcuts: 'Atajos del teclado',
  search_conversations: 'Buscar conversaciones',
  close_current_chat_tab:
    'Cerrar la pesta\u00f1a de la conversaci\u00f3n actual',
  move_focus_to_editor: 'Dirigirse al editor de mensajes',
  cycle_through_chats_down:
    'Recorrer las pesta\u00f1as de las conversaciones abiertas (hacia abajo)',
  open_recent_contacts: 'Chats recientes y directorio',
  toggle_sidebar: 'Cambiar barra lateral derecha',
  reopen_last_tab:
    'Reabrir \u00faltima pesta\u00f1a de conversaci\u00f3n cerrada',
  cycle_through_chats_up:
    'Recorrer las pesta\u00f1as de las conversaciones abiertas (hacia arriba)',
  show_this_list: 'Mostrar esta lista',
};
