import React, { Fragment } from 'react';
import { compose } from 'redux';
import { DEFAULT_CONTACT_NAME } from '@/utils';
import GroupUpdateMessage from '@/components/Conversation/InfoMessageGroup/GroupUpdateMessage';
import EmojiDecorator from '@/components/common/EmojiDecorator';
import attachmentIcon from '@/assets/icon-attachment.svg';
import ContactHOC from '@/subscribeHOCs/contact';
import { Image } from '../common';
import styles from './ActiveChats.css';
import { MODES } from './constants';

function isFile(attachments) {
  return (
    attachments &&
    attachments.length > 0 &&
    attachments.some(({ downloads }) => downloads && downloads.length > 0)
  );
}

function MessagePreview({
  contact,
  mode,
  className,
  message,
  isBuddy,
  isGroup,
}) {
  if (message) {
    let senderName = '';
    const {
      notification,
      sender,
      text,
      sentAsApp,
      attachments,
      sentByOwner,
    } = message;
    const hasFileAttachment = isFile(attachments);
    if (sentAsApp) {
      senderName = sentAsApp.name;
    } else if (isGroup && sentByOwner) {
      senderName = 'Me';
    } else {
      senderName =
        sender?.chatName === DEFAULT_CONTACT_NAME
          ? contact.chatName
          : sender?.chatName;
    }
    const msgText = notification || text;
    return (
      <div className={className}>
        {hasFileAttachment ? (
          <Image
            className={styles.attachmentIcon}
            src={attachmentIcon}
            alt='Attachment Icon'
          />
        ) : null}{' '}
        {message.isInfoMessage ? (
          <GroupUpdateMessage
            CustomTag={Fragment}
            msg={message}
            onlyLast
            translateOwner
          />
        ) : (
          <EmojiDecorator
            text={`${mode === MODES.CLASSIC ? '' : ' - '}${
              isBuddy ? '' : ` ${senderName} :`
            } ${msgText}`}
          />
        )}
      </div>
    );
  }
  return null;
}

export default compose(React.memo, ContactHOC)(MessagePreview);
