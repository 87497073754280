import React, { Component } from 'react';
import bridge from '@/utils/bridge';
import currentSessionIdHOC from '@/connectHOCs/Sessions/currentSession/id';
import { getFullContactImageUrl } from '@/utils/image';
import { logMedusaGenericEvent } from '@/utils/logAnalytics';
import noop from '@/utils/noop';
import css from './Avatar.css';

class Avatar extends Component {
  static defaultProps = {
    peer: undefined,
    currentSessionId: undefined,
    allowEdit: false,
    onClick: noop,
  };

  onAvatarEditClick = (e) => {
    e.stopPropagation();
    bridge.publish('odara-channel-avatar-editor-modal').then(() => {
      logMedusaGenericEvent('channel_avatar_change_initiated');
    });
  };

  onAvatarClick = () => {
    const {
      peer: { imageUrl },
      onClick,
    } = this.props;

    bridge.tell('Shell', 'showImagePopup', [
      {
        src: getFullContactImageUrl(imageUrl),
      },
    ]);
    onClick();
  };

  render() {
    const { peer, className, allowEdit } = this.props;
    const { imageUrl, chatName } = peer;

    return (
      <div
        className={`${className} ${css.Avatar}`}
        onClick={this.onAvatarClick}
        onKeyDown={noop}
        tabIndex={-1}
        role='button'
      >
        <img className={css.AvatarImg} src={imageUrl} alt={chatName} />
        {allowEdit ? (
          <div
            className={css.AvatarEdit}
            onClick={this.onAvatarEditClick}
            onKeyDown={noop}
            role='button'
            tabIndex={-1}
            data-pendo-hook='avatar-edit'
          />
        ) : null}
      </div>
    );
  }
}

export default currentSessionIdHOC(Avatar);
