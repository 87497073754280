import React from 'react';
import MessageLoader from './MessageLoader';

type Props = {
  canFetch: boolean;
  paywallHit: boolean;
  fetchStatus: fetchStatus;
  fetchMore: (force: boolean) => void;
  currentSession: Session;
};

const ChatFooter = function ({
  canFetch,
  fetchStatus,
  fetchMore,
  currentSession,
}: Props) {
  if (canFetch) {
    return (
      <MessageLoader
        fetchStatus={fetchStatus}
        fetchMore={fetchMore}
        paywallHit={false}
        currentSession={currentSession}
      />
    );
  }

  return null;
};

export default ChatFooter;
