import store from '@/store';
import { formatDate } from '@/utils/datetime';
import { maybe } from '@/utils';
import flockmlParser from '@/utils/flockML';
import { allContactsSelector } from '@/selectors';
import getGroupUpdateMessageString from './channelUpdate';

/**
 Returns message text used only for copying message text.
 */
function flockMLtoText(flockml) {
  try {
    return flockmlParser(flockml.replace(/<br/g, '\n<br')).textContent;
  } catch (e) {
    console.error('error in copy', e);
  }
  return '';
}

export function getMsgText(message) {
  const { text, flockml, notification, attachments } = message;
  if (text) {
    return text;
  }
  if (flockml) {
    const flockmlText = flockMLtoText(flockml);
    if (flockmlText) {
      return flockmlText;
    }
  }
  if (notification) {
    return notification;
  }
  const attachment = attachments?.[0];
  if (attachment) {
    if (attachment.views?.flockml) {
      try {
        const flockmlText = flockMLtoText(attachment.views?.flockml);
        if (flockmlText) {
          return flockmlText;
        }
      } catch (e) {
        console.error('error in copy', e);
      }
    }
    return (
      attachment.title ||
      attachment.description ||
      attachment.views?.image?.original?.filename ||
      attachment.downloads?.[0]?.filename
    );
  }
  return '';
}

/**
 Returns formatted messages if selection has message elements.

 Format: ${msgDate} ${msgTime} : message text
 */
export function getTextToCopyFromSelection(selection, messageList) {
  const { rangeCount } = selection;

  const contents = document.createDocumentFragment();
  for (
    let rangeCountIndex = 0;
    rangeCountIndex < rangeCount;
    rangeCountIndex += 1
  ) {
    contents.appendChild(selection.getRangeAt(rangeCountIndex).cloneContents());
  }

  let textToCopy = '';
  if (!contents.querySelector('.chat-message')) {
    // simple text selection - replace <br> with new lines
    contents.querySelectorAll('br').forEach(function (e) {
      e.parentNode.replaceChild(contents.ownerDocument.createTextNode('\n'), e);
    });
    // remove elements which we don't want to be in the copied text
    contents.querySelectorAll('.no-select').forEach(function (e) {
      e.remove();
    });
    textToCopy = contents.textContent.trim();
  } else {
    const msgNodes = contents.querySelectorAll('.chat-message');
    const messages = [];
    msgNodes.forEach((node) => {
      const mcId = node.dataset.msgrootId;
      const message = messageList.find(
        (m) => m.message_cache_id === window.parseInt(mcId)
      );
      messages.push(message);
    });
    const allContacts = allContactsSelector(store.getState());
    textToCopy = messages.reduce(
      function (memo, message) {
        if (!message) {
          return memo;
        }
        const msgDate = formatDate(
          new Date(window.parseInt(message.timestamp)),
          'dd/MM/yyyy'
        );
        const msgTime = formatDate(
          new Date(window.parseInt(message.timestamp)),
          'h:mm a'
        );

        if (message.isInfoMessage) {
          memo.textToCopy += `${
            memo.textToCopy ? '\n' : ''
          }[${msgDate} ${msgTime}] ${getGroupUpdateMessageString(
            message,
            false,
            false,
            allContacts
          )}\n`;
        } else {
          memo.textToCopy += `${
            memo.textToCopy ? '\n' : ''
          }[${msgDate} ${msgTime}] `;

          memo.textToCopy +=
            maybe(message, 'sentAsApp', 'name') || message.sender.name;

          memo.textToCopy += `: ${getMsgText(message)}\n`;
        }
        memo.lastSenderJid = message.sender.jid;
        memo.lastDate = msgDate;
        memo.lastTime = msgTime;
        return memo;
      },
      {
        textToCopy: '',
        lastSenderJid: null,
        lastDate: null,
        lastTime: null,
      }
    ).textToCopy;
  }
  return textToCopy;
}

export { default as markLatestMessageAsRead } from './markAsRead';
