import * as React from 'react';
import { Checkbox, RadioGroup, Button, Icon } from '@talk-to/flock-components';
import withCurrentSessionId from '@/connectHOCs/Sessions/currentSession/id';
import { openEmailPreferences } from '@/utils/SessionUtils';
import { logMedusaGenericEvent } from '@/utils/logAnalytics';
import PanelSection from '../shared/PanelSection';
import NotificationWarning from './NotificationWarning';

import sharedStyles from '../shared/styles.css';
import styles from './Notifications.css';
import useNotificationPreferences from './useNotificationPreferences';
import useSoundPreferences from './useSoundPreferences';

function Notifications({ currentSessionId }) {
  const {
    preferences,
    toggleAudioAlerts,
    toggleVideoRinger,
    togglePopupNotifications,
    togglePeersOnlineNotifications,
  } = useNotificationPreferences(currentSessionId);

  const {
    sounds,
    selectedSound,
    changeSelectedSound,
    previewSound,
  } = useSoundPreferences();

  const onSoundChange = React.useCallback(
    async (id) => {
      await changeSelectedSound(id);
      previewSound(id);
      logMedusaGenericEvent('flock:notification_sound_played', {
        id,
      });
    },
    [changeSelectedSound, previewSound]
  );

  const mappedSounds = React.useMemo(
    () =>
      Object.keys(sounds).map((id) => ({
        id,
        label: sounds[id].name,
      })),
    [sounds]
  );

  const showNotificationWarning = preferences.toastersEnabled;

  return (
    <div className={sharedStyles.avPreferences}>
      <PanelSection
        title='New incoming messages'
        contentClassName={styles['panel-content']}
        panelSectionClassName={styles['notification-panel']}
      >
        <Checkbox
          fullWidth
          id='popup-notifications'
          onChange={() => {
            togglePopupNotifications(!preferences.toastersEnabled);
            logMedusaGenericEvent('preferences_popup_notifications_toggled', {
              state: !preferences.toastersEnabled,
            });
          }}
          checked={preferences.toastersEnabled}
          label='Popup notifications'
        />
        {showNotificationWarning ? <NotificationWarning /> : null}
        <div className={styles['alert-sounds']}>
          <Checkbox
            id='notification-sound'
            onChange={() => {
              toggleAudioAlerts(!preferences.audioEnabled);
              logMedusaGenericEvent('flock:notif_toggled', {
                state: !preferences.audioEnabled,
              });
            }}
            checked={preferences.audioEnabled}
            label='Notification sound'
          />
          <RadioGroup
            disabled={!preferences.audioEnabled}
            className={styles['radio-group']}
            items={mappedSounds}
            selected={selectedSound}
            onChange={onSoundChange}
            maxPerLine={2}
          />
        </div>
      </PanelSection>
      <PanelSection
        title='Other notifications'
        contentClassName={styles['panel-content']}
      >
        <Checkbox
          fullWidth
          id='ringer'
          onChange={() => {
            // FIXME: The name `videoRingerDisabled` is a bit confusing to work
            // with since it associates with a negative tone. Change it when we
            // can to something better.
            toggleVideoRinger(!preferences.videoRingerDisabled);
            logMedusaGenericEvent('preferences_video_call_ringer_toggled', {
              state: preferences.videoRingerDisabled,
            });
          }}
          checked={!preferences.videoRingerDisabled}
          label='Audio/Video conference ringer'
        />
        <Checkbox
          fullWidth
          id='co-worker-online'
          onChange={() => {
            togglePeersOnlineNotifications(!preferences.userOnlineNotification);
            logMedusaGenericEvent(
              'preferences_user_online_notification_toggled',
              {
                state: !preferences.userOnlineNotification,
              }
            );
          }}
          checked={preferences.userOnlineNotification}
          label='Co-worker is online notification'
        />
      </PanelSection>
      <PanelSection title='Email preferences' noPadding>
        <div className={styles['email-preferences']}>
          <Button
            type='link'
            variant='outline-primary'
            rightIcon={<Icon name='external' />}
            onClick={() => {
              openEmailPreferences(currentSessionId);
              logMedusaGenericEvent('preferences_manage_email_preferences');
            }}
          >
            Manage email preferences
          </Button>
        </div>
      </PanelSection>
    </div>
  );
}

export default withCurrentSessionId(Notifications);
