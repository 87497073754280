import React from "react";
import { Header } from "./header";
import { ListItems } from "./listItems";

export const Row = React.memo(function Row({ data, index, style }) {
  const { type, ...obj } = data[index];
  if (type === "header") {
    return <Header text={obj.name} style={style} />;
  } else {
    return <ListItems items={obj.items} style={style} />;
  }
});
