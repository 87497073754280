import bridge from '@/utils/bridge';

export default async function updateUserProfile(
  data: {
    firstName: string;
    lastName: string;
    mobile: string;
  },
  guid: string
) {
  const updatedProfile = await bridge.ask('ZeusAPI', 'updateProfile', [
    data,
    guid,
  ]);

  if (updatedProfile.isError) {
    const err: any = new Error();
    err.status = updatedProfile.status;

    throw err;
  }

  await bridge.ask('TeamSessionRegistry', 'updateOwnerByGuid', [
    guid,
    updatedProfile,
  ]);
}
