const CONSTANTS = {
  NS: {
    STANZA_NS: 'urn:ietf:params:xml:ns:xmpp-stanzas',
  },
  mode: {
    IM: '1',
  },
  messageElements: {
    PLAIN_MESSAGE: '',
    BUNDLE: 'bundle',
    ACTION_BUTTON_MESSAGE: 'actionButton',
    CONTEXT: 'context',
    MENTIONS: 'mentions',
  },

  PRESENCE: {
    UNKNOWN: 'unknown',
    ONLINE: 'online',
    AWAY: 'away',
    OFFLINE: 'offline',
    SERVER_RES_ONLINE: 'ONLINE',
    SERVER_RES_AWAY: 'AWAY',
    UNREACHABLE: 'unreachable',
  },

  CONTACT_TYPE: {
    TANGO: 'TANGO',
    SAMS: 'SAMS',
    INDIE: 'INDIE',
    GROUPY: 'GROUPY',
    TEMP: 'TEMP',
  },

  SUBSCRIPTION: {
    NONE: 'none',
    FROM: 'from',
    TO: 'to',
    BOTH: 'both',
    ASK: 'subscribe',
  },

  CHAT_STATE: {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    COMPOSING: 'COMPOSING',
  },

  PEER_TYPE: {
    GROUP: 'group',
    BUDDY: 'buddy',
  },

  RECEIPT_STATE: {
    SENDING: 'sending',
    SENT: 'sent',
    RECEIVED: 'received',
    READ: 'read',
    ERROR: 'error',
    FAILED: 'failed',
  },

  MESSAGE_READING_STATE: {
    UNREAD: 'unread',
    READING: 'reading',
    READ: 'read',
  },
  MESSAGE_READING_TIME: 6 * 1000, // IN MILI SECONDS
  MAX_NOTIFICATION_LENGTH: 100,
};

export const {
  PRESENCE,
  SUBSCRIPTION,
  CHAT_STATE,
  PEER_TYPE,
  RECEIPT_STATE,
  MESSAGE_READING_STATE,
  MESSAGE_READING_TIME,
  MAX_NOTIFICATION_LENGTH,
} = CONSTANTS;
export const KEYS = {
  TAB: 9,
  BACKSPACE: 8,
  DOWN_ARROW: 40,
  UP_ARROW: 38,
  LEFT_ARROW: 37,
  RIGHT_ARROW: 39,
  ESCAPE: 27,
  ENTER: 13,
  CHAR_K: 75,
  CHAR_N: 78,
  CHAR_D: 68,
  CHAR_E: 69,
  CHAR_F: 70,
  CHAR_O: 79,
  SPACE: 32,
  FORWARD_SLASH: 191,

  KEY_1: 49,
  KEY_2: 50,
  KEY_3: 51,
  KEY_4: 52,
  KEY_5: 53,
  KEY_6: 54,
  KEY_7: 55,
  KEY_8: 56,
  KEY_9: 57,

  NUM_1: 97,
  NUM_2: 98,
  NUM_3: 99,
  NUM_4: 100,
  NUM_5: 101,
  NUM_6: 102,
  NUM_7: 103,
  NUM_8: 104,
  NUM_9: 105,
};

export const ALL_CHANNEL_FEED = 'all_channel_feed';

export const BATCH_SIZE = 25;

export const FETCH_STATUS = {
  FETCHING: 'fetching',
  SUCCESS: 'success',
  FAILED: 'error',
};

export const PINNED = 'pinned';
export const OPEN = 'open';
export const MUTED = 'muted';

export const NONE = 'none';
export const MEMBER = 'member';

/**
 * Enum for affiliation of the current user with a particular group contact.
 * @enum {string}
 */
export const AFFILIATIONS = {
  NONE,
  MEMBER,
};
