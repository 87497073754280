import React from 'react';
import PropTypes from 'prop-types';
import ChatsProxy from '@/Proxies/ChatsProxy';
import noop from '@/utils/noop';

import cssStyles from '../components/roster/Contacts.css';

const PAGINATION_LIMIT = 200;

class ListContainer extends React.Component {
  static defaultProps = {
    ContactComponent: () => null,
    items: [],
    defaultLimit: 10,
  };

  constructor(props) {
    super(props);
    this.state = {
      limit: props.defaultLimit,
    };
  }

  getText(length) {
    const { limit } = this.state;
    const { defaultLimit } = this.props;
    // allContacts is the count of contacts that are matching the prefix of the search entered
    if (length <= defaultLimit) {
      return '';
    }
    if (limit === -1) {
      return 'View Less';
    }
    return 'View More';
  }

  modifyPageLimit = () => {
    let newState;
    const { items } = this.props;
    const { defaultLimit } = this.props;
    this.setState(({ limit }) => {
      if (limit === -1) {
        newState = {
          limit: defaultLimit,
        };
      } else if (limit + PAGINATION_LIMIT > items.length) {
        newState = {
          limit: -1,
        };
      } else {
        newState = {
          limit: limit + PAGINATION_LIMIT,
        };
      }
      return newState;
    });
  };

  changeConversation = (contact) => {
    ChatsProxy.openConversation(contact, true);
  };

  render() {
    const { items, ContactComponent } = this.props;
    const { limit } = this.state;
    const text = this.getText(items.length);
    const itemsToShow = limit > 0 ? items.slice(0, limit) : items;
    return (
      <div>
        {itemsToShow.map((item) => {
          return (
            <ContactComponent
              key={item.jid}
              contact={item}
              changeConversation={this.changeConversation}
            />
          );
        })}
        {/**
         * this is used to determine the behaviour when user clicks on View More/View Less
         * length = total length of contacts that can be shown
         * limitValue = default limit for the list that we show initially
         * if earlier length + 200 < length, then we can paginate and show the next 200 contacts
         * if earlier length + 200 > length, then we have shown all contacts, so now we show the option of View Less
         * if user clicks on View Less, then we show the default limit of contacts
         */}
        <div
          role='button'
          tabIndex={0}
          onKeyDown={noop}
          className={cssStyles.view_more}
          onClick={this.modifyPageLimit}
        >
          {text}
        </div>
      </div>
    );
  }
}

ListContainer.propTypes = {
  ContactComponent: PropTypes.func,
  items: PropTypes.array,
  defaultLimit: PropTypes.number,
};

export default ListContainer;
