import React, { Component } from 'react';
import Tippy from '@tippyjs/react/headless';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import noop from '@/utils/noop';
import currentSessionTeamInfoSelector from '@/selectors/currentSessionTeamInfo';
import { toggleMute } from '@/utils/SessionUtils';
import { confirmAndLeaveChannel } from '@/utils/ConversationUtils';
import Image from '@/components/common/Image';
import { getLoaderWithFallbackUrl } from '@/components/common/utils';
import defaultGroupImage from '@/assets/icon-group.svg';
import { maybe, getMemberCount } from '@/utils';
import cssStyles from './Contacts.css';

class GroupContext extends Component {
  state = {
    on: false,
  };

  static propTypes = {
    channelInfo: PropTypes.object,
  };

  static defaultProps = {
    channelInfo: null,
  };

  toggleMute = () => {
    const { channelInfo } = this.props;
    toggleMute(channelInfo);
  };

  leaveChannel = () => {
    const { channelInfo } = this.props;
    confirmAndLeaveChannel(channelInfo);
  };

  toggle = (e) => {
    e.stopPropagation();
    this.setState(({ on }) => ({ on: !on }));
  };

  hide = () => {
    this.setState({ on: false });
  };

  stopPropagation = (e) => e.stopPropagation();

  render() {
    const { channelInfo, maskChannelmemberCountAfter } = this.props;
    const { on } = this.state;
    if (!channelInfo) {
      return null;
    }
    const {
      name,
      isMuted,
      imageUrl,
      memberCount,
      hasMuteGroupPrivilege,
      hasLeaveGroupPrivilege,
    } = channelInfo;
    return (
      <Tippy
        visible={on}
        onClickOutside={this.hide}
        arrow={false}
        interactive
        interactiveBorder={0}
        placement='bottom-end'
        appendTo={document.body}
        offset={[-10, 10]}
        render={(attrs) => (
          <div
            role='menuitem'
            onKeyDown={noop}
            className={cssStyles.context_dropdown}
            tabIndex='-1'
            onClick={this.stopPropagation}
            {...attrs}
          >
            <Image
              src={imageUrl}
              alt={`${name} channel's thumbnail`}
              fallbackUrl={defaultGroupImage}
              Loader={getLoaderWithFallbackUrl(defaultGroupImage)}
            />
            <div className={cssStyles.channelName}>{name}</div>
            <div className={cssStyles.memberCount}>
              {getMemberCount(memberCount, maskChannelmemberCountAfter)} Members
            </div>
            {hasMuteGroupPrivilege && (
              <div
                role='menuitem'
                onKeyDown={noop}
                tabIndex={0}
                onClick={this.toggleMute}
                className={classNames(cssStyles.button)}
              >
                {isMuted ? 'Unmute' : 'Mute'} Channel
              </div>
            )}
            {hasLeaveGroupPrivilege && (
              <div
                role='menuitem'
                onKeyDown={noop}
                tabIndex={0}
                onClick={this.leaveChannel}
                className={classNames(cssStyles.button, cssStyles.red)}
              >
                Leave Channel
              </div>
            )}
          </div>
        )}
      >
        <div
          onClick={this.toggle}
          role='button'
          onKeyDown={noop}
          tabIndex='-1'
          className={cssStyles.context_menu_target}
        />
      </Tippy>
    );
  }
}

const mapStateToProps = createSelector(
  currentSessionTeamInfoSelector,
  (teamInfo) => {
    return {
      maskChannelmemberCountAfter: maybe(
        teamInfo,
        'channelPreferences',
        'maskChannelMemberCountAfter'
      ),
    };
  }
);

export default connect(mapStateToProps)(GroupContext);
