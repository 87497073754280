import { createSelector } from 'reselect';
import ownerGuidSelector from './ownerGuid';
import currentConversationsSelector from './allCurrentConversations';

const defaultOpenConversation = '';

export const currentConversationByGuidSelector = createSelector(
  currentConversationsSelector,
  (currentConversations) => (ownerGuid) =>
    currentConversations[ownerGuid] || defaultOpenConversation
);

export default createSelector(
  ownerGuidSelector,
  currentConversationByGuidSelector,
  (ownerGuid, getCurrentConversationByGuid) =>
    getCurrentConversationByGuid(ownerGuid)
);
