import debounce from 'lodash/debounce';
import { maybe } from '@/utils';
import store from '@/store';
import platform from '@/utils/platform';
import Subscriber from './subscriber';

let lastVisitorId = null;
function updateIframeOptions(options) {
  [...document.querySelectorAll('iframe.appframe')].forEach((frame) => {
    try {
      if (frame.contentWindow.updatePendoOptions) {
        frame.contentWindow.updatePendoOptions(options);
      }
    } catch (e) {
      // do nothing
    }
  });
}

class TeamSubscriber extends Subscriber {
  pendoInitialized = false;

  polling = debounce(() => {
    this.notify(this.selectState(store.getState()));
  }, 60 * 60 * 1000);

  selectState = ({
    restricted: {
      _session: { session_proxy: sessionProxy, currentSessionId: sessionId },
    },
  }) => {
    if (sessionProxy && sessionId && sessionProxy[sessionId]) {
      const {
        sessionInfo: {
          name: { firstName: firstname, lastName: lastname },
          email,
          teamInfo: {
            teamId,
            teamName,
            createdOn,
            isHardPaywall,
            teamPreferences,
          },
          teamPlan,
        },
        owner: { ownerGuid },
      } = sessionProxy[sessionId];

      return {
        teamId,
        ownerGuid,
        firstname,
        lastname,
        email,
        teamName,
        createdOn,
        isHardPaywall,
        creatorEmail: maybe(teamPreferences, 'creator', 'value'),
        teamPlan,
      };
    }
    return null;
  };

  notify = async ({
    teamId,
    ownerGuid,
    firstname,
    lastname,
    email,
    teamName,
    createdOn,
    isHardPaywall,
    creatorEmail,
    teamPlan,
  }) => {
    this.polling();

    if (teamId && ownerGuid && window.pendo) {
      const { uaInfoParams } = await platform.getAll();
      const options = {
        visitor: {
          id: ownerGuid,
          firstname,
          lastname,
          email,
          IsTeamCreator: creatorEmail === email,
          LatestWebVersion: uaInfoParams['app-version'],
          LatestClientType: uaInfoParams['device-type'],
          LatestOS: uaInfoParams.os,
        },
        account: {
          id: teamId,
          accountname: teamName,
          CreatedTS: createdOn,
          IsHardPaywall: isHardPaywall,
          Experiment: maybe(teamPlan, 'attrs', 'experiment_type'),
          IsPROTrial_Client: maybe(teamPlan, 'type') === 'TRIAL',
        },
      };
      if (!lastVisitorId) {
        console.log('Pendo Agent::initialize', options);
        window.pendo.initialize(options);
      } else if (lastVisitorId !== ownerGuid) {
        console.log('Pendo Agent::identify', options);
        window.pendo.identify(options);
      } else {
        console.log('Pendo Agent::updateOptions', options);
        window.pendo.updateOptions(options);
        if (window.pendo.loadGuides && !window.isPendoGuideShown()) {
          window.pendo.loadGuides();
        }
      }
      lastVisitorId = ownerGuid;
      updateIframeOptions(options);
    }
  };
}

export default new TeamSubscriber('team');
