import { connect } from 'react-redux';
import { Actions } from '@/actions/restricted';
import { getConversationIdentity } from '@/utils/ConversationUtils';

const getConversationIdentityFromProps = (props) => {
  const { currentPeer } = props;
  const conversationId = getConversationIdentity(currentPeer);
  return conversationId;
};

function mapStateToProps(state, ownProps) {
  const conversationId = getConversationIdentityFromProps(ownProps);
  const {
    restricted: {
      editorState: { [conversationId]: currentState },
    },
  } = state;

  return currentState ? { persistedEditorState: currentState } : {};
}

function mapDispatchToProps(dispatch) {
  return {
    updateEditorState: (conversationId, newState) => {
      dispatch(
        // @ts-ignore
        Actions.update_editor_state({
          conversationId,
          editorState: newState,
        })
      );
    },
  };
}

export default function (component) {
  return connect(mapStateToProps, mapDispatchToProps)(component);
}
