import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Formik, Form, FieldArray, Field } from 'formik';
import * as Yup from 'yup';
import { TextInput, Button, CustomList } from '@/components/common';
import bridge from '@/utils/bridge';
import { getBucketedSearchContacts } from '@/utils/ContactUtils';
import { getContactForId } from '@/Models/Contact';
import ContactItem from '@/components/common/ContactList/ContactBucket/ContactItem';
import Tippy from '@tippyjs/react';
import noop from '@/utils/noop';
import styles from './InviteWidget.css';

const GA_SOURCE = 'sidebar_empty_state_email';
const delimitter = ':::';

const INITIAL_STATE = {
  suggestions: {},
  copied: false,
};

function CustomListItem({ jid }) {
  const contact = getContactForId(jid);
  return <ContactItem contact={contact} />;
}

class InviteWidget extends PureComponent {
  static propTypes = {
    ownerGuid: PropTypes.string.isRequired,
    teamUrl: PropTypes.string.isRequired,
  };

  state = INITIAL_STATE;

  constructor(props) {
    super(props);
    const { intl } = props;
    this.errorMessage = intl.formatMessage({ id: 'enter_valid_email' });
    this.inviteValidationSchema = Yup.object().shape({
      emails: Yup.array()
        .of(Yup.string().email(this.errorMessage))
        .required(this.errorMessage)
        .min(1, this.errorMessage),
    });
  }

  fetchSuggestions(key, queryString) {
    const { ownerGuid } = this.props;
    getBucketedSearchContacts(ownerGuid, queryString, {
      queryString,
      filters: {
        type: [],
      },
      includeImported: true,
      includeFreeDomains: false,
      count: 2,
    }).then((results) => {
      const allSuggestions = Object.values(results)
        .reduce((all, { contacts }) => all.concat(contacts), [])
        .map((jid) => {
          return {
            jid,
          };
        });
      this.setState(({ suggestions }) => {
        return {
          suggestions: {
            ...suggestions,
            [key]: allSuggestions,
          },
        };
      });
    });
  }

  inviteContacts = ({ emails }, actions) => {
    const { ownerGuid } = this.props;
    const inviteEmails = emails.filter((email) => email !== '');
    if (inviteEmails.length > 0) {
      bridge
        .ask('ZeusAPI', 'invite', [inviteEmails, ownerGuid])
        .then(() => actions.setSubmitting(false));
    } else {
      actions.setSubmitting(false);
      actions.setFieldError('emails.0', this.errorMessage);
    }
    bridge.publish('/log_analytics/medusa/helper/invites_sent', [
      {
        source: GA_SOURCE,
        invite_count: emails.length,
      },
    ]);
  };

  copyTeamUrl = () => {
    const { teamUrl } = this.props;
    this.setState({ copied: true });
    return navigator.clipboard.writeText(`https://${teamUrl}.flock.com`);
  };

  hideToolTip = (instance) => {
    setTimeout(instance.hide, 1000);
  };

  resetSuggestions = () => this.setState(INITIAL_STATE);

  renderForm = ({ isSubmitting, values, setFieldValue, setFieldError }) => {
    const { intl, teamUrl } = this.props;
    const { suggestions, copied } = this.state;
    return (
      <Form>
        <FieldArray
          name='emails'
          validateOnChange={false}
          render={({ push }) => (
            <>
              {values.emails.map((email, index) => {
                const name = `emails.${index}`;
                const suggestedContacts = suggestions[name];
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={index} className={styles.email}>
                    <Field
                      type='email'
                      name={name}
                      placeholder={intl.formatMessage({
                        id: 'text_enterEmailAddress',
                      })}
                      component={TextInput}
                      autoComplete='off'
                      style={{
                        padding: '1px 0.5em',
                        fontSize: '0.875em',
                        color: 'var(--c-primary-text)',
                      }}
                      onChange={(e) => {
                        e.persist();
                        const { value: queryString } = e.target;
                        if (index === 0) {
                          if (
                            queryString.length === 1 &&
                            values.emails.length === 1
                          ) {
                            push('');
                          }
                        }
                        if (queryString.length === 0) {
                          this.resetSuggestions();
                        } else {
                          this.fetchSuggestions(name, queryString);
                        }
                        setFieldError(name, '');
                        setFieldValue(name, queryString, false);
                      }}
                    />
                    {suggestedContacts && suggestedContacts.length > 0 ? (
                      <CustomList
                        onItemClick={({ jid }) => {
                          const [, contact] = jid.split(delimitter);
                          setFieldValue(name, contact, true);
                          this.resetSuggestions();
                        }}
                        className={styles.suggestions}
                        listItemClass={styles.inviteItem}
                        items={suggestedContacts}
                        component={CustomListItem}
                      />
                    ) : null}
                  </div>
                );
              })}
            </>
          )}
        />
        {values.emails.length > 1 ? (
          <Button size='small' fullWidth type='submit' disabled={isSubmitting}>
            Invite
          </Button>
        ) : null}

        <div className={styles.shareUrl}>
          Or share your team URL
          <div className={styles.shareContainer}>
            <Button
              onClick={this.copyTeamUrl}
              kind='link primary'
              className={styles.teamUrl}
            >
              {teamUrl}.flock.com
            </Button>
            <Tippy content='Copied' visible={copied} onShow={this.hideToolTip}>
              <span
                role='button'
                onKeyDown={noop}
                tabIndex='0'
                onClick={this.copyTeamUrl}
                className={styles.copyIcon}
              />
            </Tippy>
          </div>
        </div>
      </Form>
    );
  };

  render() {
    return (
      <div className={styles.inviteBlurb}>
        <div className={styles.innerInviteBlurb}>
          <header className={styles.inviteHeader}>
            <FormattedMessage id='contactAdder_inviteHeader_title' />
          </header>
          <div className={styles.inviteImage} />
          <Formik
            initialValues={{ emails: [''] }}
            onSubmit={this.inviteContacts}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={this.inviteValidationSchema}
          >
            {this.renderForm}
          </Formik>
        </div>
      </div>
    );
  }
}

export default injectIntl(InviteWidget);
