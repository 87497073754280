import { jidFromGuid } from '@/utils';
import { RECEIPT_STATE, MESSAGE_READING_STATE } from './constants';

const tooltipText = {
  receipt: {
    [RECEIPT_STATE.SENDING]: 'Sending',
    [RECEIPT_STATE.SENT]: 'Delivered to Server',
    [RECEIPT_STATE.RECEIVED]: 'Delivered to device',
    [RECEIPT_STATE.READ]: 'Message Read',
    [RECEIPT_STATE.ERROR]: 'Error',
  },
  readingState: {
    [MESSAGE_READING_STATE.UNREAD]: 'Message received',
    [MESSAGE_READING_STATE.READING]: 'Reading',
    [MESSAGE_READING_STATE.READ]: 'Read by you',
  },
};

export function getReceiptState(receiptObj, message) {
  if (message && message.receiptState === RECEIPT_STATE.FAILED) {
    return RECEIPT_STATE.FAILED;
  }

  if (message && !message.sid) {
    return RECEIPT_STATE.SENDING;
  }

  if (!receiptObj || !message || !message.peer || !message.peer.jid) {
    return null;
  }

  if (receiptObj.isGroup) {
    return null;
  }

  const {
    peer: { jid: peerJid },
    sid,
  } = message;
  const { [peerJid]: { ld, lr } = {} } = receiptObj;
  const stimestamp = sid.split('-')[0];

  if (stimestamp <= lr) {
    return RECEIPT_STATE.READ;
  }
  if (stimestamp <= ld) {
    return RECEIPT_STATE.RECEIVED;
  }
  return RECEIPT_STATE.SENT;
}

export function getLastReadTimestamp(peerReceiptObj, ownerGuid) {
  if (!peerReceiptObj) {
    return 0;
  }
  const ownerJid = jidFromGuid(ownerGuid);
  const { [ownerJid]: { lr } = {} } = peerReceiptObj;
  return lr;
}

function _getMessageReadingState(lr, message) {
  const { stimestamp } = message;
  if (stimestamp <= lr) {
    return MESSAGE_READING_STATE.READ;
  }

  return MESSAGE_READING_STATE.UNREAD;
}

function _isMessageUnreadByMe(lr, message) {
  return (
    !message.sentByOwner &&
    _getMessageReadingState(lr, message) !== MESSAGE_READING_STATE.READ
  );
}

export function getMessageReadingState(receiptObj, message) {
  if (!receiptObj || !message) {
    return null;
  }
  if (message.sentByOwner) {
    return null;
  }

  const { ownerGuid } = message;
  const lr = getLastReadTimestamp(receiptObj, ownerGuid);
  return _getMessageReadingState(lr, message);
}

export function getFirstUnreadMessage(messages, receiptObj, ownerGuid) {
  const lr = getLastReadTimestamp(receiptObj, ownerGuid);
  return messages.find((msg) => _isMessageUnreadByMe(lr, msg));
}

export function addsToCount(message) {
  const { sentByOwner, isVisibleMessage, isInfoMessage } = message;
  return !sentByOwner && isVisibleMessage && !isInfoMessage;
}

export function isMessageUnreadByMe(receiptObj, message) {
  const { ownerGuid } = message;
  const lr = getLastReadTimestamp(receiptObj, ownerGuid);
  return _isMessageUnreadByMe(lr, message);
}

export function canUseAsUnreadMarker(receiptObj, message) {
  return (
    !message.isGroupUpdateMessage && isMessageUnreadByMe(receiptObj, message)
  );
}

export function isSidUnread(receiptObj, sid, ownerGuid) {
  const lastRead = getLastReadTimestamp(receiptObj, ownerGuid);
  const stimestamp = sid.split('-')[0];

  return lastRead < stimestamp;
}

export function getToolTipText(type, state) {
  if (type === 'receipt' || type === 'readingState') {
    return tooltipText[type][state];
  }
  return '';
}
