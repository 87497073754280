import React, { useCallback, useState, useRef } from 'react';
import { compose } from 'redux';
import { Picker } from 'emoji-util';
import { FormattedMessage } from 'react-intl';
import Tippy from '@tippyjs/react';
import Popover from '@/components/common/Popover';
import Icon from '@/components/Icon';
import noop from '@/utils/noop';
import withTheme from '@/connectHOCs/Theme';
import withStickers from '@/connectHOCs/withStickers';
import { withRecentEmojis, withEmojiPrefs } from '@/lib/emoji';
import IntlWrapper from '@/Wrappers/IntlWrapper';

import commonCss from '@/components/common/common.css';
import css from './EmojiButton.css';
import translations from './i18n';
import type { Emoji, Sticker } from './types';

const EmojiPicker = withStickers(function EmojiPicker(
  props: React.ComponentPropsWithoutRef<typeof Picker>
) {
  return <Picker {...props} />;
});

type EmojiButtonProps = {
  onEmoji: (emoji: Emoji) => void;
  onSticker: (sticker: Sticker) => void;
  onClose: () => void;
  currentTheme: 'light' | 'dark';
  recents: any[];
  addToRecents: (emoji: Emoji) => void;
  skinTone: number;
  changeSkinTone: () => void;
};

const EmojiButton = React.memo(function EmojiButton({
  onEmoji = noop,
  onSticker = noop,
  onClose = noop,
  currentTheme = 'light',
  recents = [],
  addToRecents = noop,
  skinTone = 1,
  changeSkinTone = noop,
}: EmojiButtonProps) {
  const [open, setOpen] = useState(false);
  const storeResetRef = useRef(null);

  const togglePicker = useCallback(() => {
    setOpen((value) => !value);
  }, []);

  const closePicker = useCallback(() => {
    setOpen(false);
    storeResetRef.current?.();
    onClose();
  }, [onClose]);

  const register = useCallback(({ reset }) => {
    storeResetRef.current = reset;
  }, []);

  const getEmojiWithSkinTone = useCallback(
    (emoji) => {
      if (skinTone > 1 && emoji.hasSkins) {
        return emoji.skins[skinTone - 2];
      }
      return emoji;
    },
    [skinTone]
  );

  const onEmojiSelected = useCallback(
    (emoji) => {
      onEmoji(getEmojiWithSkinTone(emoji));
      closePicker();
      addToRecents(emoji);
    },
    [onEmoji, closePicker, addToRecents, getEmojiWithSkinTone]
  );

  const onStickerSelected = useCallback(
    (sticker) => {
      onSticker(sticker);
      closePicker();
    },
    [onSticker, closePicker]
  );

  return (
    <Popover
      triggerClass={css.PopoverTrigger}
      open={open}
      widthDifference={-30}
      heightDifference={10}
      position='top-left'
      popOverContent={
        <EmojiPicker
          recents={recents}
          skin={skinTone}
          onEmoji={onEmojiSelected}
          onSticker={onStickerSelected}
          onSkin={changeSkinTone}
          theme={currentTheme}
          register={register}
          storeId='editor'
        />
      }
      popOverClass={css.PopoverContainer}
      onClickOutside={closePicker}
      onEscape={closePicker}
      renderOnBody
    >
      <Tippy
        className={commonCss.info_tooltip}
        content={<FormattedMessage id='emoji_picker_button_tooltip' />}
        arrow
        placement='top'
      >
        <button
          className={`${commonCss.btn__reset} ${css.emojiButton}`}
          onClick={togglePicker}
          type='button'
        >
          <span>
            <Icon className={css.SmileyIcon} name='smiley' size='32px' />
          </span>
        </button>
      </Tippy>
    </Popover>
  );
});

export default compose<any>(
  withEmojiPrefs,
  withRecentEmojis,
  withTheme
)(IntlWrapper(EmojiButton, translations));
