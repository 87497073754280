export default {
  GET_THE_APP: 'Consigue la aplicaci\u00f3n',
  MAKE_FLOCK_BETTER_APPS: 'Haz que Flock sea incluso mejor con aplicaciones',
  messageArea_chatTab_text_historyButton:
    'Este es el inicio de tu conversación con {name}',
  groupPurpose_blank_value: 'Configura el objetivo de tu canal aqu\u00ed',
  me_tab_user_status:
    'Guarda mensajes r\u00e1pidos, archivos o enlaces para verlos luego. Haz un seguimiento de tus tareas pendientes o crea notas, \u00a1sin preocuparte!',
  me_tab_your_space: 'Tu espacio personal',
  visible_to_me_text: 'Solo t\u00fa puedes ver esto',
  me_text: 'Yo',
  history_fetch_error: 'Algo ha fallado',
  retry: 'Reintentar',
  pay_wall_user:
    'Aqu\u00ed hay m\u00e1s mensajes, pero superan el l\u00edmite de tu plan gratuito. Solicita a tu administrador que actualice tu equipo para desbloquear todos los mensajes anteriores.',
  pay_wall_admin:
    'Aqu\u00ed hay m\u00e1s mensajes, pero superan el l\u00edmite de tu plan gratuito. Actualiza tu equipo para desbloquear todos los mensajes anteriores.',
};
