import React, { useCallback } from 'react';
import { FETCH_STATUS } from '@/utils/constants';
import Loader from '@/components/common/Icons/Loader';
import HistoryFetchErrorMessage from './HistoryFetchErrorMessage';
import Paywall from './Paywall';
import cssStyles from './MessageList.css';

type Props = {
  fetchStatus: fetchStatus;
  fetchMore: (force: boolean) => void;
  currentSession: Session;
  paywallHit: boolean;
};

const MessageLoader = React.memo(function MessageLoader({
  fetchStatus,
  fetchMore,
  currentSession,
  paywallHit,
}: Props) {
  const resetFetchImmediate = useCallback(() => {
    fetchMore(true);
  }, [fetchMore]);
  return (
    <div className={cssStyles.loaderContainer}>
      {paywallHit && fetchStatus === FETCH_STATUS.SUCCESS ? (
        <Paywall currentSession={currentSession} />
      ) : null}
      {fetchStatus === FETCH_STATUS.FAILED ? (
        <HistoryFetchErrorMessage retry={resetFetchImmediate} />
      ) : null}
      {fetchStatus === FETCH_STATUS.FETCHING ? <Loader color='green' /> : null}
    </div>
  );
});

export default MessageLoader;
