export default {
  sender_name_you: 'Usted',
  message_sentByMe_header: 'Yo',
  phrase_invited: '{actor} agreg\u00f3 a {user}',
  phrase_removed: '{actor} elimin\u00f3 a {user}',
  phrase_joined: '{user} se uni\u00f3',
  phrase_left: '{user} se fue',
  public_note:
    'Este canal es p\u00fablico. Cualquier miembro del equipo puede ver este canal y unirse.',
  private_note:
    'Este canal es privado. S\u00f3lo los miembros invitados pueden leer y publicar mensajes aqu\u00ed.',
  restricted_note:
    'Este canal es p\u00fablico para grupos espec\u00edficos de usuarios. Cualquiera en los siguientes grupos puede ver este canal y unirse: {user_groups}',
  notify_public_channel_created:
    '{actor} cre\u00f3 un canal p\u00fablico. Cualquier miembro del equipo puede ver este canal y unirse.',
  notify_private_channel_created:
    '{actor} cre\u00f3 un canal privado. S\u00f3lo los miembros invitados pueden leer y publicar mensajes aqu\u00ed.',
  notify_restricted_channel_created:
    '{actor} cre\u00f3 un canal p\u00fablico para los siguientes grupos de usuarios: {user_groups}',
  notify_channel_purpose_set:
    '{actor} estableci\u00f3 el prop\u00f3sito del canal "{channel_purpose}"',
  notify_channel_avatar_change: '{actor} cambi\u00f3 el avatar del canal',
  notify_add_channel_admin: '{actor} hizo administrador del canal a {user}',
  notify_auto_add_channel_admin: '{user} ahora es administrador de canal',
  notify_remove_channel_admin:
    '{actor} revoc\u00f3 los derechos de administrador del canal de {user}',
  notify_channel_name_change:
    '{actor} cambi\u00f3 el nombre del canal a "{channel_name}"',
  notify_channel_type_restricted:
    '{actor} actualiz\u00f3 los criterios de privacidad de este canal. Todos los usuarios de los siguientes grupos de usuarios pueden ver este canal y unirse a partir de este momento: {user_groups}. Cualquier usuario ajeno a estos grupos deber\u00e1 ser agregado por el administrador del canal.',
  notify_channel_type_public:
    '{actor} actualiz\u00f3 los criterios de privacidad de este canal. Todos los miembros del equipo pueden ver este canal y unirse.',
  notify_channel_type_private:
    '{actor} cambi\u00f3 el tipo de canal a privado. S\u00f3lo los miembros invitados pueden leer y publicar mensajes aqu\u00ed.',
  notify_channel_purpose_remove:
    '{actor} borr\u00f3 el prop\u00f3sito del canal',
  notify_channel_purpose_change:
    '{actor} cambi\u00f3 el prop\u00f3sito del canal a "{channel_purpose}"',
  notify_channel_grant_admin:
    '{actor} cambi\u00f3 la configuraci\u00f3n del canal a "los administradores pueden agregar/eliminar miembros"',
  notify_channel_grant_everyone:
    '{actor} cambi\u00f3 la configuraci\u00f3n del canal a "todos pueden agregar/eliminar miembros"',
};
