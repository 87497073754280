import React, { Component } from 'react';
import currentSessionHOC from '@/connectHOCs/Sessions/currentSession';
import BlockingModal from '@/components/common/BlockingModal';
import { logMedusaGenericEvent } from '@/utils/logAnalytics';
import TrialExpiredAdmin from './TrialExpiredAdmin';
import TrialExpiredNonAdmin from './TrialExpiredNonAdmin';

class HardPaywallModal extends Component {
  static defaultProps = {
    currentSession: undefined,
  };

  getStateFromProps() {
    const { currentSession } = this.props;

    const state = {
      shouldBlock: false,
      isAdmin: false,
    };

    if (!currentSession) {
      return state;
    }

    state.isAdmin = currentSession.sessionInfo.isAdmin;

    const { teamInfo, teamPlan } = currentSession.sessionInfo;
    if (!teamPlan) {
      return state;
    }

    const { type } = teamPlan;
    const { isHardPaywall } = teamInfo;

    return {
      ...state,
      shouldBlock: type === 'FREE' && isHardPaywall,
    };
  }

  logMedusaEvent() {
    const { currentSession } = this.props;
    if (!currentSession) {
      return;
    }

    logMedusaGenericEvent('hard_paywall_modal_viewed');
  }

  render() {
    const { isAdmin, shouldBlock } = this.getStateFromProps();
    const { currentSession } = this.props;

    if (!shouldBlock || !currentSession || currentSession.state !== 'ACTIVE') {
      return null;
    }
    this.logMedusaEvent();
    return (
      <BlockingModal>
        {isAdmin ? (
          <TrialExpiredAdmin sessionId={currentSession.id} />
        ) : (
          <TrialExpiredNonAdmin />
        )}
      </BlockingModal>
    );
  }
}

export default currentSessionHOC(HardPaywallModal);
