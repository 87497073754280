import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import memoizeOne from 'memoize-one';
import classNames from 'classnames';
import { createStructuredSelector } from 'reselect';
import currentSessionHOC from '@/connectHOCs/Sessions/currentSession';
import {
  allSessionIdsSelector,
  hasDisconnectedAtLeastOnceSelector,
} from '@/selectors';
import Button from '@/components/common/Button';
import bridge from '@/utils/bridge';
import layoutOuterLeftCollapsedHOC from '@/connectHOCs/layoutOuterLeftCollapsed';
import IntlWrapper from '@/Wrappers/IntlWrapper';
import styles from './IPWhitelistedModal.css';
import translations from './I18n';

type Props = {
  currentSession: Session;
  outerLeftCollapsed: boolean;
  allSessionIds: Object;
  hasDisconnectedAtLeastOnce: boolean;
};

type State = {
  processing: boolean;
  modalOpen: boolean;
};

type Session = {
  state: string;
  sessionInfo: {
    teamInfo: {
      guid: string;
      teamName: string;
      isIPWhitelistingEnabled: boolean;
    };
  };
};

class IPWhitelistedModal extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      processing: false,
      modalOpen: false,
    };
  }

  componentDidUpdate() {
    this.checkIPWhitelistingRestriction();
  }

  checkIPWhitelistingRestriction = () => {
    const { currentSession, hasDisconnectedAtLeastOnce } = this.props;
    const sessionState = currentSession.state;
    const { sessionInfo } = currentSession;
    const { isIPWhitelistingEnabled } = sessionInfo.teamInfo;
    const { modalOpen } = this.state;

    if (
      sessionState !== 'ACTIVE' &&
      isIPWhitelistingEnabled &&
      hasDisconnectedAtLeastOnce
    ) {
      /* eslint-disable-next-line no-lonely-if */
      if (!modalOpen) {
        this.setState({
          processing: false,
          modalOpen: true,
        });
      }
    } else {
      /* eslint-disable-next-line no-lonely-if */
      if (modalOpen) {
        this.setState({
          processing: false,
          modalOpen: false,
        });
      }
    }
  };

  setProcessingOff = () => {
    this.setState({
      processing: false,
      modalOpen: true,
    });
  };

  tryAgain() {
    this.setState(
      {
        processing: true,
        modalOpen: true,
      },
      () => {
        bridge.publish('retry-connection', ['unauthorized_ip']);
        setTimeout(() => {
          this.setProcessingOff();
        }, 15000);
      }
    );
  }

  memoizedCheckTeamSwitcher = memoizeOne(
    (outerLeftCollapsed: boolean, allSessionIds: object) => {
      const pushLeftStyle = {
        left: outerLeftCollapsed ? '68px' : '0',
        width: outerLeftCollapsed ? 'calc(100vw - 68px' : 'calc(100vw - 56px)',
      };

      const showTeamSwitchText = Object.keys(allSessionIds).length > 1;

      return { pushLeftStyle, showTeamSwitchText };
    }
  );

  render() {
    const { allSessionIds, currentSession, outerLeftCollapsed } = this.props;
    const { processing, modalOpen } = this.state;
    const {
      teamInfo: { teamName },
    } = currentSession.sessionInfo;

    const {
      pushLeftStyle,
      showTeamSwitchText,
    } = this.memoizedCheckTeamSwitcher(outerLeftCollapsed, allSessionIds);

    if (modalOpen) {
      return (
        <div
          className={styles.ip_whitelisting_error_modal}
          style={pushLeftStyle}
        >
          <div className={styles.content_wrapper}>
            <div className={styles.heading}>
              <FormattedMessage
                id='heading'
                values={{
                  teamName: <strong>{teamName}</strong>,
                }}
              />
            </div>
            <div className={styles.subheading}>
              <FormattedMessage id='subheading' />
            </div>

            {/* @ts-ignore */}
            <Button
              onClick={() => {
                this.tryAgain();
              }}
              className={classNames({
                [styles.saving]: processing,
              })}
              disabled={processing}
            >
              <FormattedMessage id='try_again' />
            </Button>

            {showTeamSwitchText && (
              <div className={styles.subtext}>
                <FormattedMessage
                  id='subtext'
                  values={{
                    switch_teams: (
                      <strong>
                        {' '}
                        <FormattedMessage id='switch_teams' />{' '}
                      </strong>
                    ),
                  }}
                />
              </div>
            )}
          </div>
        </div>
      );
      /* eslint-disable-next-line no-else-return */
    } else {
      return null;
    }
  }
}

const mapStateToProps = createStructuredSelector({
  allSessionIds: allSessionIdsSelector,
  hasDisconnectedAtLeastOnce: hasDisconnectedAtLeastOnceSelector,
});

export default connect(mapStateToProps)(
  IntlWrapper(
    currentSessionHOC(layoutOuterLeftCollapsedHOC(IPWhitelistedModal)),
    translations
  )
);
