const defaultState = {};

export default (ActionTypes) => {
  return (state = defaultState, action) => {
    try {
      switch (action.type) {
        case ActionTypes.UPDATE_TEAM_PRIVILEGES: {
          const { ownerGuid, config } = action.payload;
          return {
            ...state,
            [ownerGuid]: config,
          };
        }
        default:
          return state;
      }
    } catch (error) {
      console.error(
        `error in team privileges reducer : ${error.message}`,
        error
      );
    }
    return state;
  };
};
