import React from 'react';
import { FormattedMessage } from 'react-intl';
import IntlWrapper from '@/Wrappers/IntlWrapper';
import cssStyles from '../MessageList.css';
import translations from '../../nls';

const supportLabels = {
  flock_support_description:
    'Leave a message to directly chat with our support agents',
  flock_support: 'Flock Support',
};

type Props = {
  currentPeer: BuddyContact;
  support: boolean;
};

function SupportOrMeChatHeader(props: Props) {
  const { currentPeer, support } = props;
  const { imageUrl } = currentPeer;
  return (
    <div className={cssStyles.headerMessageGroupOrBot}>
      <div className={cssStyles.headerMessageHeader}>
        <div className={cssStyles.headerMessageHeaderAvatar}>
          <div className={cssStyles.headerMessageAvatarContainer}>
            <img src={imageUrl} alt='not found' />
          </div>
        </div>
        <div className={cssStyles.headerMessageHeaderName}>
          {support ? (
            supportLabels.flock_support
          ) : (
            <FormattedMessage id='me_tab_your_space' />
          )}
        </div>
      </div>
      <div className={cssStyles.headerMessageContentBot}>
        <span>
          {support ? (
            supportLabels.flock_support_description
          ) : (
            <FormattedMessage id='me_tab_user_status' />
          )}
        </span>
        {support ? (
          <strong>
            <div className={cssStyles.beginning}>
              <FormattedMessage
                id='messageArea_chatTab_text_historyButton'
                values={{ name: currentPeer.name }}
              />
            </div>
          </strong>
        ) : null}
      </div>
    </div>
  );
}

export default IntlWrapper(SupportOrMeChatHeader, translations);
