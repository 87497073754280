import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import classNames from 'classnames';
import modalStyles from '@/components/Modal/Modal.css';
import commonStyles from '@/components/common/common.css';
import IntlWrapper from '@/Wrappers/IntlWrapper';
import { signout } from '@/utils/ShellUtils';
import currentSessionHOC from '@/connectHOCs/Sessions/currentSession';
import { hideModal, openModal } from '@/components/Modal/Modal';
import GenericInformationModalComponent from '@/components/Modal/GenericInformationModal/GenericInformationModalComponent';
import styles from './SignOutModalComponent.css';
import translations from './I18n';

class SignOutModalComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showButtonLoadingSpinner: false,
    };
  }

  showErrorModal = () => {
    const { intl } = this.props;
    openModal(
      <GenericInformationModalComponent
        title={intl.formatMessage({ id: 'oops' })}
      >
        <div className={modalStyles.modal_subtitle}>
          {intl.formatMessage({ id: 'error_occurred' })}
        </div>
        <div className={commonStyles.btn_container}>
          <button
            type='button'
            className={classNames(commonStyles.btn, commonStyles.btn__action)}
            onClick={hideModal}
          >
            {intl.formatMessage({ id: 'okay' })}
          </button>
        </div>
      </GenericInformationModalComponent>,
      true,
      false
    );
  };

  signOutUser = () => {
    const { currentSession } = this.props;
    const { email } = currentSession.owner;
    this.setState(
      {
        showButtonLoadingSpinner: true,
      },
      () => {
        signout(email).then((res) => {
          if (!res.isError) {
            this.setState({
              showButtonLoadingSpinner: false,
            });
            hideModal();
          } else {
            console.log(`Sign out failed. ${res.responseText}`);
            this.setState({
              showButtonLoadingSpinner: false,
            });
            hideModal();
            this.showErrorModal();
          }
        });
      }
    );
  };

  render() {
    const { teamsList } = this.props;
    const { showButtonLoadingSpinner } = this.state;
    return (
      <div className={styles.signout_modal}>
        <div className={modalStyles.modal_title}>
          <FormattedMessage id='title_signout' />
        </div>
        <div>
          <div className={styles.content_heading}>
            <FormattedMessage id='confirm_signout' />
          </div>
          <ul>
            {teamsList.map((team) => (
              <li key={team}>{team}</li>
            ))}
          </ul>

          <div className={commonStyles.btn_container}>
            <button
              type='button'
              className={classNames(commonStyles.btn, commonStyles.btn__cancel)}
              onClick={hideModal}
            >
              Cancel
            </button>
            <button
              type='button'
              className={classNames(commonStyles.btn, commonStyles.btn__red, {
                [styles.button_loading]: showButtonLoadingSpinner,
              })}
              onClick={this.signOutUser}
            >
              Sign Out
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default currentSessionHOC(
  IntlWrapper(injectIntl(SignOutModalComponent), translations)
);
