import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import IntlWrapper from '@/Wrappers/IntlWrapper';
import translations from './i18n';
import css from './DeactivatedUserGuard.css';

function DeactivatedUserGuard({ deactivated, group, children }) {
  if (!group && deactivated) {
    return (
      <div className={css.Container}>
        <span className={css.Highlight}>
          <FormattedMessage id='conversation.deactivatedUserGuard.highlight' />:
        </span>
        &nbsp;
        <span>
          <FormattedMessage id='conversation.deactivatedUserGuard.message' />
        </span>
      </div>
    );
  }

  return children;
}

export default IntlWrapper(DeactivatedUserGuard, translations);
