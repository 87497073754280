import { OsAppType, OS } from '@/utils';
import * as eventsDefinition from './eventsDefinition';
import { CTRL, META, ALT, SHIFT } from './keyDefinitions';

const modifierKeysMap = {
  win: {
    [CTRL]: 'Ctrl + ',
    [META]: 'Windows + ',
    [ALT]: 'Alt + ',
    [SHIFT]: 'Shift + ',
  },
  mac: {
    [CTRL]: 'Ctrl',
    [META]: '⌘',
    [ALT]: '⌥',
    [SHIFT]: 'Shift',
  },
  linux: {
    [CTRL]: 'Ctrl + ',
    [META]: '',
    [ALT]: 'Alt + ',
    [SHIFT]: 'Shift + ',
  },
};

// Returns string for the shortcut;
// Depends on OS and event:
// TODO: We still need to figure out how to gen string for all Cases.
// This works for modifiers with a keyCode.
function getShortcutString(event) {
  const { modifiers, keyCodeForEventString } = event;
  if (!keyCodeForEventString) {
    return '';
  }
  let shortcutString = '';
  modifiers.forEach((mod) => {
    if (!modifierKeysMap[OS][mod]) {
      return;
    }
    shortcutString += `${modifierKeysMap[OS][mod]}`;
  });
  shortcutString += String.fromCharCode(keyCodeForEventString);

  return shortcutString;
}

function allEvents() {
  const events = {};
  Object.values(eventsDefinition).forEach((event) => {
    if (event[OsAppType]) {
      events[event.id] = {
        id: event.id,
        description: event.description,
        shortcutString: getShortcutString(event[OsAppType]),
        _props: event[OsAppType],
      };
    }
  });
  return events;
}

export default allEvents();
