export default {
  editor_messageDropdown_menu_text_copy: 'Copiar',
  editor_messageDropdown_menu_text_edit: 'Editar',
  editor_messageDropdown_menu_text_retry: 'Tentar novamente',
  editor_messageDropdown_popup_text_deleteMessage:
    'Esta mensagem ser\u00e1 exclu\u00edda para voc\u00ea e para o destinat\u00e1rio e ser\u00e1 exclu\u00edda permanentemente dos nossos servidores.',
  editor_messageDropdown_popup_title_deleteMessage: 'Excluir mensagem?',
  editor_messageDropdown_popup_buttonText_deleteMessage_Yes: 'Sim, excluir',
  editor_messageDropdown_popup_buttonText_delete: 'Excluir',
  editor_deletion_failed: 'A\u00e7\u00e3o n\u00e3o permitida',
  editor_not_connected_to_internet_error:
    'Parece que voc\u00ea foi desconectado. Isto geralmente acontece devido a uma conex\u00e3o de internet ruim ou falta de conex\u00e3o com a internet.',
};
