import { maybe } from '@/utils';
import i18n from './I18N';

const phrases = {
  invited: 'phrase_invited',
  removed: 'phrase_removed',
  joined: 'phrase_joined',
  left: 'phrase_left',
  add_admin: 'notify_add_channel_admin',
  auto_admin: 'notify_auto_add_channel_admin',
  auto_admin_self: 'notify_auto_add_channel_admin_self',
  remove_admin: 'notify_remove_channel_admin',
};

export function getLocalString(stringId, values) {
  const text = i18n['en-US'][stringId];
  if (!values) {
    return text || stringId;
  }
  return text.replace(/{([^}]+)}/g, (match, key) => {
    return values[key] || key;
  });
}

function getLocaleMessage(stringId, values) {
  const text = i18n['en-US'][stringId];
  if (!values) {
    return text || stringId;
  }
  return text.split(/({[^}]+})/).map((token) => {
    if (token) {
      if (token.startsWith('{') && token.endsWith('}')) {
        const key = token.slice(1, -1);
        return values[key] || token;
      }
      return token;
    }
    return null;
  });
}

function getUseGroups(criteriaList = {}) {
  Object.values(criteriaList)
    .map((c) => c.name)
    .join(', ');
}

function getChannelUpdateMessage(
  actor,
  channelType,
  isRestricted,
  restrictionCriteria = [],
  criteriaList
) {
  if (isRestricted || restrictionCriteria.length > 0) {
    return getLocaleMessage('notify_channel_type_restricted', {
      actor,
      user_groups: getUseGroups(criteriaList),
    });
  }
  if (channelType === 'close') {
    return getLocaleMessage('notify_channel_type_private', {
      actor,
    });
  }
  return getLocaleMessage('notify_channel_type_public', {
    actor,
  });
}

function replaceContact(args, jidKey) {
  const jid = args[jidKey];
  if (args.translateOwner && jid === args.ownerJid) {
    return getLocalString('sender_name_you');
  }
  return args.getContact({
    jid,
    ownerGuid: args.ownerGuid,
  });
}

function getGroupSettingsUpdateMessages(args) {
  const messageLines = [];
  const actor = replaceContact(args, 'actorJid');
  const channelType = maybe(args, 'config', 'type');
  const channelName = maybe(args, 'profile', 'name');
  const channelPurpose = maybe(args, 'profile', 'description');
  const isRestricted = maybe(args, 'config', 'restricted');
  const restrictionCriteria = maybe(args, 'config', 'restrictionCriteria');
  const criteriaList = maybe(args, 'criteriaList');
  const addAffiliate = maybe(
    args,
    'config',
    'privileges',
    'groupAffiliate',
    'addAffiliate'
  );
  const removeAffiliate = maybe(
    args,
    'config',
    'privileges',
    'groupAffiliate',
    'removeAffiliate'
  );

  if (args.groupCreated && channelType) {
    if (
      args.groupJid &&
      (args.groupJid.indexOf('lobby') !== -1 ||
        args.groupJid.indexOf('announcements') !== -1)
    ) {
      messageLines.push(getLocaleMessage('notify_team_hub_announcement'));
    } else if (isRestricted) {
      messageLines.push(
        getLocaleMessage('notify_restricted_channel_created', {
          actor,
          user_groups: getUseGroups(criteriaList),
        })
      );
    } else {
      messageLines.push(
        getLocaleMessage(
          channelType === 'close'
            ? 'notify_private_channel_created'
            : 'notify_public_channel_created',
          {
            actor,
          }
        )
      );
    }
  } else {
    if (addAffiliate && addAffiliate === removeAffiliate) {
      if (addAffiliate === 'moderator') {
        messageLines.push(
          getLocaleMessage('notify_channel_grant_admin', {
            actor,
          })
        );
      } else if (addAffiliate === 'member') {
        messageLines.push(
          getLocaleMessage('notify_channel_grant_everyone', {
            actor,
          })
        );
      }
    }

    if (channelType || isRestricted || restrictionCriteria) {
      messageLines.push(
        getChannelUpdateMessage(
          actor,
          channelType,
          isRestricted,
          restrictionCriteria,
          criteriaList
        )
      );
    }

    if (channelName) {
      messageLines.push(
        getLocaleMessage('notify_channel_name_change', {
          actor,
          channel_name: channelName || '',
        })
      );
    }

    if (maybe(args, 'profile', 'avatar')) {
      messageLines.push(
        getLocaleMessage('notify_channel_avatar_change', {
          actor,
        })
      );
    }

    if (channelPurpose === '') {
      messageLines.push(
        getLocaleMessage('notify_channel_purpose_remove', {
          actor,
        })
      );
    } else if (channelPurpose) {
      messageLines.push(
        getLocaleMessage('notify_channel_purpose_change', {
          actor,
          channel_purpose: channelPurpose,
        })
      );
    }
  }
  return messageLines;
}

function affiationChangeMessage(args) {
  let action = 'invited';
  const isActorSameAsUser =
    args.subjectJid === args.actorJid || args.groupJid === args.actorJid;
  const actor = replaceContact(args, 'actorJid');
  const user = replaceContact(args, 'subjectJid');
  if (
    args.newAffiliation === 'moderator' &&
    args.prevAffiliation === 'member'
  ) {
    if (isActorSameAsUser) {
      action =
        args.subjectJid === args.ownerJid ? 'auto_admin_self' : 'auto_admin';
    } else {
      action = 'add_admin';
    }
  } else if (
    args.newAffiliation === 'member' &&
    args.prevAffiliation === 'moderator'
  ) {
    action = 'remove_admin';
  } else if (
    args.newAffiliation === 'member' &&
    args.prevAffiliation === 'none'
  ) {
    action = isActorSameAsUser ? 'joined' : 'invited';
  } else if (args.newAffiliation === 'none') {
    action = isActorSameAsUser ? 'left' : 'removed';
  }
  return getLocaleMessage(phrases[action], {
    user,
    actor,
  });
}

function getPrivacyNote(msg) {
  const channelType =
    maybe(msg, 'config', 'type') || maybe(msg, 'peer', 'groupType');
  const criteriaList =
    maybe(msg, 'criteriaList') || maybe(msg, 'peer', 'criteriaList');
  const isRestricted =
    maybe(msg, 'config', 'restricted') || maybe(msg, 'peer', 'restricted');

  if (
    msg.sender &&
    msg.sender.jid &&
    (msg.sender.jid.indexOf('announcements') !== -1 ||
      msg.sender.jid.indexOf('lobby') !== -1)
  ) {
    return getLocaleMessage('team_hub_note');
  }
  if (isRestricted) {
    return getLocaleMessage('restricted_note', {
      user_groups: getUseGroups(criteriaList),
    });
  }
  if (channelType === 'close') {
    return getLocaleMessage('private_note');
  }
  return getLocaleMessage('public_note');
}

function getGroupUpdateDisplayMessage(
  msg,
  translateOwner,
  onlyLast,
  getContact
) {
  const actorJid = msg.actor;
  const ownerJid = `${msg.ownerGuid}@go.to`;
  const groupCreated = msg.groupVersion === 1;
  let showPrivacyNote = false;
  const messageLines = [];

  messageLines.push(
    ...getGroupSettingsUpdateMessages({
      translateOwner,
      getContact,
      groupCreated,
      profile: msg.profile,
      config: msg.config,
      criteriaList: msg.criteriaList || msg.peer.criteriaList,
      groupJid: msg.peer.jid,
      actorJid,
      ownerGuid: msg.ownerGuid,
      ownerJid,
    })
  );

  if (msg.members && msg.members.length) {
    const membersChange =
      onlyLast && msg.members.length > 1
        ? [msg.members[msg.members.length - 1]]
        : msg.members;
    messageLines.push(
      ...membersChange.map((member) => {
        const { newAffiliation } = member;
        const prevAffiliation = member.oldAffiliation;
        if (groupCreated) {
          if (actorJid === member.jid) {
            return '';
          }
        } else if (prevAffiliation === 'none' && member.jid === ownerJid) {
          showPrivacyNote = true;
        }
        if (newAffiliation !== prevAffiliation) {
          return affiationChangeMessage({
            translateOwner,
            getContact,
            groupJid: msg.peer.jid,
            actorJid,
            ownerGuid: msg.ownerGuid,
            ownerJid,
            subjectJid: member.jid,
            newAffiliation,
            prevAffiliation,
          });
        }
        return [];
      })
    );
  }
  if (showPrivacyNote) {
    messageLines.push(getPrivacyNote(msg));
  }
  return messageLines.filter((i) => i);
}

function getAffiliationDisplayMessage(msg, translateOwner, getContact) {
  const { muc, ownerGuid } = msg;
  const prevAffiliation = muc['old-affiliation'];
  const newAffiliation = muc.affiliation;
  const ownerJid = `${msg.ownerGuid}@go.to`;
  const actorJid = muc.actor;
  const subjectJid = muc.jid;
  return [
    affiationChangeMessage({
      translateOwner,
      getContact,
      groupJid: msg.peer.jid,
      actorJid,
      ownerJid,
      ownerGuid,
      subjectJid,
      newAffiliation,
      prevAffiliation,
    }),
  ];
}

export function getGroupUpdateMessageParts(
  msg,
  translateOwner,
  onlyLast,
  getContact
) {
  if (msg.isGroupUpdateMessage) {
    return getGroupUpdateDisplayMessage(
      msg,
      translateOwner,
      onlyLast,
      getContact
    );
  }
  if (msg.muc) {
    return getAffiliationDisplayMessage(msg, translateOwner, getContact);
  }
  return [];
}

export default function getGroupUpdateMessageString(
  msg,
  translateOwner,
  onlyLast,
  allContacts
) {
  function getContact({ jid }) {
    const { [jid]: { chatName = 'Unknown member', guest } = {} } = allContacts;
    return `${chatName}${guest ? ` (${getLocalString('guest')})` : ''}`;
  }
  return getGroupUpdateMessageParts(msg, translateOwner, onlyLast, getContact)
    .map((messageLineArr) => {
      if (messageLineArr && messageLineArr.join) {
        return messageLineArr.join('');
      }
      return messageLineArr;
    })
    .join('\n');
}
