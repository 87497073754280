import React from 'react';
import { injectIntl } from 'react-intl';
import modalStyles from '@/components/Modal/Modal.css';
import IntlWrapper from '@/Wrappers/IntlWrapper';
import styles from './GenericInformationModalComponent.css';

function GenericInformationModalComponent(props) {
  const { children, title } = props;

  return (
    <div className={styles.generic_information_wrapper}>
      <div className={modalStyles.modal_title}>{title}</div>
      {children}
    </div>
  );
}

export default IntlWrapper(injectIntl(GenericInformationModalComponent));
