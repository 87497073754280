import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import bridge from '@/utils/bridge';
import { getUploadProgress, abortUpload } from '@/utils/MultiFileUpload';
import { showAbortFileUploadDialog } from '@/utils/GenericModalUtils';
import platform from '@/utils/platform';
import IntlWrapper from '@/Wrappers/IntlWrapper';
import css from './FileUploadProgress.css';
import translations from './I18N';

const defaultState = {
  showUploader: false,
  status: 'SUCCESS',
  uploadedCount: 2,
  uploadedSize: 20,
  totalSize: 100,
  filesCount: 4,
};

class FileUploadProgress extends PureComponent {
  static defaultProps = {
    peerJid: undefined,
    className: '',
  };

  state = defaultState;

  bridgeSubscription;

  abortCallBackSub;

  constructor() {
    super();
    bridge
      .subscribe('MultiFileUpload/Progress', this.onProgressChange)
      .then((subscription) => {
        this.bridgeSubscription = subscription;
      });
    // const progress = getUploadProgress();
    // this.onProgressChange(progress);
  }

  componentDidUpdate(prevProps) {
    const { peerJid } = this.props;
    const { peerJid: prevJid } = prevProps;
    if (prevJid !== peerJid) {
      const progress = getUploadProgress(peerJid);
      this.onProgressChange(progress);
    }
  }

  componentWillUnmount() {
    if (this.bridgeSubscription) {
      bridge.unsubscribe(this.bridgeSubscription);
    }
  }

  abort = () => {
    const { peerJid } = this.props;

    showAbortFileUploadDialog(peerJid, 'FileUploadAbort');
    bridge.subscribe('FileUploadAbort', this.makeAbortCall).then((sub) => {
      this.abortCallbackSub = sub;
    });
  };

  makeAbortCall(peerJid) {
    abortUpload(peerJid);
    if (!this.abortCallBackSub) {
      return;
    }
    bridge.unsubscribe(this.abortCallBackSub);
    this.abortCallBackSub = undefined;
  }

  onProgressChange = (progress) => {
    const { peerJid } = this.props;
    if (!progress || progress.peerJid !== peerJid) {
      this.setState({
        showUploader: false,
      });
      return;
    }

    if (progress.status !== 'UPLOADING') {
      this.setState(defaultState);
      return;
    }

    this.setState({
      showUploader: true,
      status: 'UPLOADING',
      uploadedCount: progress.uploadedCount,
      filesCount: progress.filesCount,
      uploadedSize: progress.uploadedSize,
      totalSize: progress.totalSize,
    });
  };

  render() {
    const {
      showUploader,
      status,
      uploadedCount,
      filesCount,
      uploadedSize,
      totalSize,
    } = this.state;

    const { className } = this.props;

    if (!showUploader || status !== 'UPLOADING') {
      return null;
    }
    const progressWidth = `${(uploadedSize * 100) / totalSize}%`;
    return (
      <div className={`${className} ${css.Wrapper}`}>
        <span>
          <FormattedMessage
            id='uploading_x_of_y_files'
            values={{
              currentUploading: uploadedCount + 1,
              filesCount,
            }}
          />
        </span>
        <div className={css.ProgressBar}>
          <div style={{ width: progressWidth }} />
        </div>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <img
          onClick={this.abort}
          alt='Abort file upload'
          // eslint-disable-next-line prefer-template
          src={platform.getRootPath() + 'client_base/images/icon-close2.svg'}
          className={css.CloseButton}
          tabIndex={-1}
          onKeyDown={() => {}}
        />
      </div>
    );
  }
}

export default IntlWrapper(FileUploadProgress, translations);
