export default {
  join_group_description: 'Discover and join channels in your team',
  discover_group_disc: 'Below is the list of public channels in your team.',
  start_new_channel_link_text: 'Start a New Channel',
  no_results_found: 'No results found',
  no_open_groups: 'Looks like there are no Public Channels to join',
  no_open_groups_description:
    'Public channels allow your team to discover and join conversations with ease. e.g. {example}',
  no_open_groups_description_example: 'Marketing Team, Ideas & Inspiration',
  create_a_channel: 'Create a Channel',
  public_channel_guest_restriction_heading: 'Discover and Join Public Channels',
  public_channel_guest_restriction_message:
    'Oops! Guest on Flock cannot view or create public channels in this team.',
  okay: 'Okay',
};
