/* To add a new event, just export a const string with the keyboard configuration */
import { KEYS } from '@/utils/constants';
import { CTRL, META, SHIFT, ALT } from './keyDefinitions';
import {
  SEARCH,
  DIRECTORY,
  SEARCH_MESSAGES,
  FOCUS_MESSAGE_EDITOR,
  KEYBOARD_SHORTCUTS,
  SWITCH_TEAM,
  REMOVE_CHAT,
  REOPEN_CONVERSATION,
  NAVIGATE_CONVERSATION,
  OPEN_FILE_PICKER,
  SECONDARY_NAVIGATION,
} from './eventIds';

const {
  KEY_1,
  KEY_2,
  KEY_3,
  KEY_4,
  KEY_5,
  KEY_6,
  KEY_7,
  KEY_8,
  KEY_9,

  NUM_1,
  NUM_2,
  NUM_3,
  NUM_4,
  NUM_5,
  NUM_6,
  NUM_7,
  NUM_8,
  NUM_9,

  ESCAPE,
} = KEYS;

export const switchTeamShortcut = {
  id: SWITCH_TEAM,
  description: 'Switch teams',
  'win-desktop': {
    modifiers: [[CTRL]],
    keyCodeForEventString: KEY_1,
    allowedKeyCodes: [
      KEY_1,
      KEY_2,
      KEY_3,
      KEY_4,
      KEY_5,
      KEY_6,
      KEY_7,
      KEY_8,
      KEY_9,
      NUM_1,
      NUM_2,
      NUM_3,
      NUM_4,
      NUM_5,
      NUM_6,
      NUM_7,
      NUM_8,
      NUM_9,
    ],
  },
  'mac-desktop': {
    modifiers: [[META]],
    keyCodeForEventString: KEY_1,
    allowedKeyCodes: [
      KEY_1,
      KEY_2,
      KEY_3,
      KEY_4,
      KEY_5,
      KEY_6,
      KEY_7,
      KEY_8,
      KEY_9,
      NUM_1,
      NUM_2,
      NUM_3,
      NUM_4,
      NUM_5,
      NUM_6,
      NUM_7,
      NUM_8,
      NUM_9,
    ],
  },
  'linux-desktop': {
    modifiers: [[CTRL]],
    keyCodeForEventString: KEY_1,
    allowedKeyCodes: [
      KEY_1,
      KEY_2,
      KEY_3,
      KEY_4,
      KEY_5,
      KEY_6,
      KEY_7,
      KEY_8,
      KEY_9,
      NUM_1,
      NUM_2,
      NUM_3,
      NUM_4,
      NUM_5,
      NUM_6,
      NUM_7,
      NUM_8,
      NUM_9,
    ],
  },
};

export const searchShortcut = {
  id: SEARCH,
  description: 'Start a search',
  'win-browser': {
    modifiers: [[CTRL]],
    keyCodeForEventString: KEYS.CHAR_K,
    allowedKeyCodes: [KEYS.CHAR_K, KEYS.CHAR_N],
  },
  'win-desktop': {
    modifiers: [[CTRL]],
    keyCodeForEventString: KEYS.CHAR_N,
    allowedKeyCodes: [KEYS.CHAR_K, KEYS.CHAR_N],
  },
  'mac-browser': {
    modifiers: [[META]],
    keyCodeForEventString: KEYS.CHAR_K,
    allowedKeyCodes: [KEYS.CHAR_K, KEYS.CHAR_N],
  },
  'mac-desktop': {
    modifiers: [[META]],
    keyCodeForEventString: KEYS.CHAR_N,
    allowedKeyCodes: [KEYS.CHAR_K, KEYS.CHAR_N],
  },
  'linux-desktop': {
    modifiers: [[CTRL]],
    keyCodeForEventString: KEYS.CHAR_N,
    allowedKeyCodes: [KEYS.CHAR_K, KEYS.CHAR_N],
  },
  'linux-browser': {
    modifiers: [[CTRL]],
    keyCodeForEventString: KEYS.CHAR_K,
    allowedKeyCodes: [KEYS.CHAR_K, KEYS.CHAR_N],
  },
};

export const directoryShortcut = {
  id: DIRECTORY,
  description: 'Team Directory',
  'win-browser': {
    modifiers: [[CTRL]],
    keyCodeForEventString: KEYS.CHAR_D,
    allowedKeyCodes: [KEYS.CHAR_D],
  },
  'win-desktop': {
    modifiers: [[CTRL]],
    keyCodeForEventString: KEYS.CHAR_D,
    allowedKeyCodes: [KEYS.CHAR_D],
  },
  'mac-browser': {
    modifiers: [[META]],
    keyCodeForEventString: KEYS.CHAR_D,
    allowedKeyCodes: [KEYS.CHAR_D],
  },
  'mac-desktop': {
    modifiers: [[META]],
    keyCodeForEventString: KEYS.CHAR_D,
    allowedKeyCodes: [KEYS.CHAR_D],
  },
  'linux-desktop': {
    modifiers: [[CTRL]],
    keyCodeForEventString: KEYS.CHAR_D,
    allowedKeyCodes: [KEYS.CHAR_D],
  },
  'linux-browser': {
    modifiers: [[CTRL]],
    keyCodeForEventString: KEYS.CHAR_D,
    allowedKeyCodes: [KEYS.CHAR_D],
  },
};

export const keyboardShortcutsModal = {
  id: KEYBOARD_SHORTCUTS,
  description: 'Keyboard Shortcuts Modal',
  'win-browser': {
    modifiers: [[CTRL]],
    keyCodeForEventString: KEYS.FORWARD_SLASH,
    allowedKeyCodes: [KEYS.FORWARD_SLASH],
  },
  'win-desktop': {
    modifiers: [[CTRL]],
    keyCodeForEventString: KEYS.FORWARD_SLASH,
    allowedKeyCodes: [KEYS.FORWARD_SLASH],
  },
  'mac-browser': {
    modifiers: [[META]],
    keyCodeForEventString: KEYS.FORWARD_SLASH,
    allowedKeyCodes: [KEYS.FORWARD_SLASH],
  },
  'mac-desktop': {
    modifiers: [[META]],
    keyCodeForEventString: KEYS.FORWARD_SLASH,
    allowedKeyCodes: [KEYS.FORWARD_SLASH],
  },
  'linux-desktop': {
    modifiers: [[CTRL]],
    keyCodeForEventString: KEYS.FORWARD_SLASH,
    allowedKeyCodes: [KEYS.FORWARD_SLASH],
  },
  'linux-browser': {
    modifiers: [[CTRL]],
    keyCodeForEventString: KEYS.FORWARD_SLASH,
    allowedKeyCodes: [KEYS.FORWARD_SLASH],
  },
};

export const searchMessagesShortcut = {
  id: SEARCH_MESSAGES,
  description: 'Search messages',
  'win-browser': {
    modifiers: [[CTRL]],
    keyCodeForEventString: KEYS.CHAR_F,
    allowedKeyCodes: [KEYS.CHAR_F],
  },
  'win-desktop': {
    modifiers: [[CTRL]],
    keyCodeForEventString: KEYS.CHAR_F,
    allowedKeyCodes: [KEYS.CHAR_F],
  },
  'mac-browser': {
    modifiers: [[META]],
    keyCodeForEventString: KEYS.CHAR_F,
    allowedKeyCodes: [KEYS.CHAR_F],
  },
  'mac-desktop': {
    modifiers: [[META]],
    keyCodeForEventString: KEYS.CHAR_F,
    allowedKeyCodes: [KEYS.CHAR_F],
  },
  'linux-desktop': {
    modifiers: [[CTRL]],
    keyCodeForEventString: KEYS.CHAR_F,
    allowedKeyCodes: [KEYS.CHAR_F],
  },
  'linux-browser': {
    modifiers: [[CTRL]],
    keyCodeForEventString: KEYS.CHAR_F,
    allowedKeyCodes: [KEYS.CHAR_F],
  },
};

export const removeChatShortcut = {
  id: REMOVE_CHAT,
  description: 'On pressing escape, close current chat.',
  'win-browser': {
    modifiers: [[]],
    keyCodeForEventString: ESCAPE,
    allowedKeyCodes: [ESCAPE],
  },
  'win-desktop': {
    modifiers: [[]],
    keyCodeForEventString: ESCAPE,
    allowedKeyCodes: [ESCAPE],
  },
  'mac-browser': {
    modifiers: [[]],
    keyCodeForEventString: ESCAPE,
    allowedKeyCodes: [ESCAPE],
  },
  'mac-desktop': {
    modifiers: [[]],
    keyCodeForEventString: ESCAPE,
    allowedKeyCodes: [ESCAPE],
  },
  'linux-desktop': {
    modifiers: [[]],
    keyCodeForEventString: ESCAPE,
    allowedKeyCodes: [ESCAPE],
  },
  'linux-browser': {
    modifiers: [[]],
    keyCodeForEventString: ESCAPE,
    allowedKeyCodes: [ESCAPE],
  },
};

export const editorFocusShortcut = {
  id: FOCUS_MESSAGE_EDITOR,
  description: 'Move focus to message editor',
  'win-browser': {
    modifiers: [[CTRL]],
    keyCodeForEventString: KEYS.CHAR_E,
    allowedKeyCodes: [KEYS.CHAR_E],
  },
  'win-desktop': {
    modifiers: [[CTRL]],
    keyCodeForEventString: KEYS.CHAR_E,
    allowedKeyCodes: [KEYS.CHAR_E],
  },
  'mac-browser': {
    modifiers: [[META]],
    keyCodeForEventString: KEYS.CHAR_E,
    allowedKeyCodes: [KEYS.CHAR_E],
  },
  'mac-desktop': {
    modifiers: [[META]],
    keyCodeForEventString: KEYS.CHAR_E,
    allowedKeyCodes: [KEYS.CHAR_E],
  },
  'linux-desktop': {
    modifiers: [[CTRL]],
    keyCodeForEventString: KEYS.CHAR_E,
    allowedKeyCodes: [KEYS.CHAR_E],
  },
  'linux-browser': {
    modifiers: [[CTRL]],
    keyCodeForEventString: KEYS.CHAR_E,
    allowedKeyCodes: [KEYS.CHAR_E],
  },
};

export const reopenConversationShortcut = {
  id: REOPEN_CONVERSATION,
  description: 'Reopen the previous conversation',
  'win-browser': {
    modifiers: [[SHIFT]],
    keyCodeForEventString: KEYS.ESCAPE,
    allowedKeyCodes: [KEYS.ESCAPE],
  },
  'win-desktop': {
    modifiers: [[SHIFT]],
    keyCodeForEventString: KEYS.ESCAPE,
    allowedKeyCodes: [KEYS.ESCAPE],
  },
  'mac-browser': {
    modifiers: [[SHIFT]],
    keyCodeForEventString: KEYS.ESCAPE,
    allowedKeyCodes: [KEYS.ESCAPE],
  },
  'mac-desktop': {
    modifiers: [[SHIFT]],
    keyCodeForEventString: KEYS.ESCAPE,
    allowedKeyCodes: [KEYS.ESCAPE],
  },
  'linux-desktop': {
    modifiers: [[SHIFT]],
    keyCodeForEventString: KEYS.ESCAPE,
    allowedKeyCodes: [KEYS.ESCAPE],
  },
  'linux-browser': {
    modifiers: [[SHIFT]],
    keyCodeForEventString: KEYS.ESCAPE,
    allowedKeyCodes: [KEYS.ESCAPE],
  },
};

export const navigateConversationsShortcut = {
  id: NAVIGATE_CONVERSATION,
  description: 'Navigate the Active Chats',
  'win-browser': {
    modifiers: [[ALT]],
    keyCodeForEventString: KEYS.UP_ARROW,
    allowedKeyCodes: [KEYS.UP_ARROW, KEYS.DOWN_ARROW],
  },
  'win-desktop': {
    modifiers: [[ALT]],
    keyCodeForEventString: KEYS.UP_ARROW,
    allowedKeyCodes: [KEYS.UP_ARROW, KEYS.DOWN_ARROW],
  },
  'mac-browser': {
    modifiers: [[ALT], [META]],
    keyCodeForEventString: KEYS.UP_ARROW,
    allowedKeyCodes: [KEYS.UP_ARROW, KEYS.DOWN_ARROW],
  },
  'mac-desktop': {
    modifiers: [[ALT], [META]],
    keyCodeForEventString: KEYS.UP_ARROW,
    allowedKeyCodes: [KEYS.UP_ARROW, KEYS.DOWN_ARROW],
  },
  'linux-desktop': {
    modifiers: [[ALT]],
    keyCodeForEventString: KEYS.UP_ARROW,
    allowedKeyCodes: [KEYS.UP_ARROW, KEYS.DOWN_ARROW],
  },
  'linux-browser': {
    modifiers: [[ALT]],
    keyCodeForEventString: KEYS.UP_ARROW,
    allowedKeyCodes: [KEYS.UP_ARROW, KEYS.DOWN_ARROW],
  },
};

export const openFilePickerShortcut = {
  id: OPEN_FILE_PICKER,
  description: 'open file picker',
  'win-browser': {
    modifiers: [[CTRL]],
    keyCodeForEventString: KEYS.CHAR_O,
    allowedKeyCodes: [KEYS.CHAR_O],
  },
  'win-desktop': {
    modifiers: [[CTRL]],
    keyCodeForEventString: KEYS.CHAR_O,
    allowedKeyCodes: [KEYS.CHAR_O],
  },
  'mac-browser': {
    modifiers: [[META]],
    keyCodeForEventString: KEYS.CHAR_O,
    allowedKeyCodes: [KEYS.CHAR_O],
  },
  'mac-desktop': {
    modifiers: [[META]],
    keyCodeForEventString: KEYS.CHAR_O,
    allowedKeyCodes: [KEYS.CHAR_O],
  },
  'linux-desktop': {
    modifiers: [[CTRL]],
    keyCodeForEventString: KEYS.CHAR_O,
    allowedKeyCodes: [KEYS.CHAR_O],
  },
  'linux-browser': {
    modifiers: [[CTRL]],
    keyCodeForEventString: KEYS.CHAR_O,
    allowedKeyCodes: [KEYS.CHAR_O],
  },
};

export const secondaryNavigationShortcut = {
  id: SECONDARY_NAVIGATION,
  description: 'Navigate the Active Chats',
  'win-browser': {
    modifiers: [[CTRL], [SHIFT, CTRL]],
    keyCodeForEventString: KEYS.TAB,
    allowedKeyCodes: [KEYS.TAB],
  },
  'win-desktop': {
    modifiers: [[CTRL], [SHIFT, CTRL]],
    keyCodeForEventString: KEYS.TAB,
    allowedKeyCodes: [KEYS.TAB],
  },
  'mac-browser': {
    modifiers: [[ALT], [SHIFT, ALT]],
    keyCodeForEventString: KEYS.TAB,
    allowedKeyCodes: [KEYS.TAB],
  },
  'mac-desktop': {
    modifiers: [[ALT], [SHIFT, ALT]],
    keyCodeForEventString: KEYS.TAB,
    allowedKeyCodes: [KEYS.TAB],
  },
  'linux-desktop': {
    modifiers: [[CTRL], [SHIFT, CTRL]],
    keyCodeForEventString: KEYS.TAB,
    allowedKeyCodes: [KEYS.TAB],
  },
  'linux-browser': {
    modifiers: [[CTRL], [SHIFT, CTRL]],
    keyCodeForEventString: KEYS.TAB,
    allowedKeyCodes: [KEYS.TAB],
  },
};
