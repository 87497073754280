import { createSelector } from 'reselect';
import ownerGuidSelector from './ownerGuid';

const defaultAppList = [];
const defaultDikeStore = {};

export default createSelector(
  ownerGuidSelector,
  (state) => state.restricted.dike,
  (ownerGuid, dikeStore = defaultDikeStore) =>
    (dikeStore[ownerGuid] || defaultDikeStore).appList || defaultAppList
);
