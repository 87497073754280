const pickAndAssignProps = function (source, destination, propList) {
  // _(propList).each(function (prop) {
  //     destination[prop] = source[prop];
  // });
  propList.forEach((prop) => {
    destination[prop] = source[prop];
  });
  return destination;
};

const msgToPlatformMessage = function (msg) {
  const m = {
    uid: msg.sid,
  };
  const from = `u:${msg.sender.jid.split('@')[0]}`;
  let to;
  if (msg.peer.type === 'group') {
    to = `g:${msg.peer.jid.split('@')[0]}`;
  } else if (msg.sentByOwner) {
    to = `u:${msg.peer.jid.split('@')[0]}`;
  } else {
    to = `u:${msg.ownerGuid}`;
  }
  m.from = from;
  m.to = to;
  pickAndAssignProps(msg, m, [
    'timestamp',
    'flockml',
    'appId',
    'mentions',
    'attachments',
    'notification',
    'text',
  ]);
  return m;
};

export default msgToPlatformMessage;
