import bridge from '@/utils/bridge';
import { isContactIdSpecial, getContactForId } from '@/Models/Contact';
import ChatsProxy from '@/Proxies/ChatsProxy';
import strip from './Emoji';
import { getCustomFieldsMapFromSession } from './SessionUtils';
import {
  logNewContacts,
  logContact,
  logGroup,
  logMedusaGenericEvent,
  logMedusaChatOpenStarted,
  logChatStarted,
} from './logAnalytics';

export function getTopRecentContacts(ownerGuid) {
  const params = {
    count: 20,
    type: ['buddy', 'email', 'group'],
    ownerGuid,
  };
  return bridge
    .ask('ContactStore', 'getTopRecent', [params])
    .then((response) => {
      const jids = response.result.map((c) => c.jid);
      return [{ id: 'topRecent', contacts: jids }];
    });
}

export function normalizeBucketedSearchResponse(response) {
  const buckets = [];
  if (!response) {
    return buckets;
  }
  const _buckets = Object.keys(response);
  for (let i = 0; i < _buckets.length; i += 1) {
    if (_buckets[i] === 'imported') {
      buckets.push({
        id: _buckets[i],
        contacts: response[_buckets[i]].map(
          (c) => `IMPORTED:::${c.email}:::${c.name}`
        ),
      });
    } else {
      buckets.push({
        id: _buckets[i],
        contacts: response[_buckets[i]].map((c) => c.jid),
      });
    }
  }
  return buckets;
}

const defaultSearchParams = {
  start: 0,
  sortMatches: true,
  includeImported: true,
  includeFreeDomains: true,
  microFormatNeeded: true,
  filters: { type: ['buddy', 'email', 'group'] },
  count: 20,
};

export function getBucketedChannelMembers(
  group,
  queryString = '',
  searchParams = {}
) {
  const { ownerGuid } = group;
  const params = {
    ...defaultSearchParams,
    ...searchParams,
    ownerGuid,
    queryString,
  };
  return bridge
    .ask('GroupParticipantManager', 'search', [group, params])
    .then((response) => {
      const normalizedResponse = normalizeBucketedSearchResponse(
        response.buckets
      );
      return normalizedResponse;
    });
}

export function getBucketedSearchContacts(
  ownerGuid,
  queryString = '',
  searchParams = {}
) {
  const params = {
    ...defaultSearchParams,
    ...searchParams,
    ownerGuid,
    queryString,
  };
  return bridge
    .ask('ContactStore', 'getBucketedSearch', [params])
    .then((response) => {
      const normalizedResponse = normalizeBucketedSearchResponse(
        response.buckets
      );
      return normalizedResponse;
    });
}

export function searchContacts(ownerGuid, queryString, searchParams = {}) {
  const params = {
    queryString,
    start: 0,
    count: 20,
    microFormatNeeded: true,
    ownerGuid,
    ...searchParams,
  };

  return bridge.ask('ContactStore', 'search', [params]).then((response) => {
    const normalizedResponse = normalizeBucketedSearchResponse(response);
    return normalizedResponse;
  });
}

export async function getContact(jid, ownerGuid) {
  if (isContactIdSpecial(jid)) {
    // Inside an array as getContactsBy returns an array of contacts.
    return [getContactForId(jid, ownerGuid)];
  }
  return bridge.ask('ContactStore', 'getContactsBy', ['jid', jid, ownerGuid]);
}

export function openConversation(
  contact,
  ownerGuid,
  type,
  queryLength,
  bringToFront = true
) {
  const { jid } = contact;
  logChatStarted(type);
  ChatsProxy.openConversation(contact, bringToFront);

  bridge
    .ask('MagicList', 'getMagicListLength', [ownerGuid])
    .then((magicListLength) => {
      bridge.publish('/log_analytics/medusa/helper/genericEvent', [
        'search_chat_initiated',
        {
          is_in_magic_list: contact.hebeWeight > 0,
          is_first_time: !!contact.hasSearchedBefore,
          chat: jid.split('@')[0],
          is_channel: !!contact.isGroup,
          search_query_length: queryLength,
          magic_list_count: magicListLength,
        },
      ]);
      if (!contact.hasSearchedBefore)
        bridge.tell('ContactStore', 'updateContactFast', [
          contact,
          { hasSearchedBefore: true },
        ]);
    });
}

export function joinPublicChannel(
  contact,
  bringToFront = false,
  queryLength = 0
) {
  return bridge
    .ask('GroupInfoManager', 'joinGroup', [contact])
    .then((response) => {
      if (response.responseType === 'success') {
        openConversation(
          contact,
          contact.ownerGuid,
          'group',
          queryLength,
          bringToFront
        );
      }
      return response;
    });
}

export function contactInvite(contact, currentSession, tempId) {
  bridge
    .ask('ZeusAPI', 'invite', [
      [contact.email],
      currentSession.sessionInfo.teamInfo.guid,
    ])
    .then((params) => {
      const conversation = params.summary.profiles[0];

      if (conversation) {
        logNewContacts(1);
        logContact(contact, tempId);
      }
    });
}

export function createGroupAndOpen(contacts, currentSessionId, tempId) {
  let newContacts = 0;

  const invitees = contacts.map((contact) => {
    if (!contact.jid) {
      newContacts += 1;
    }

    return {
      jid: contact.type === 'temp_search' ? '' : contact.jid,
      email: contact.email,
    };
  });

  bridge
    .ask(`Session_${currentSessionId}`, 'createGroupOnServer', [
      null,
      invitees,
      null,
      'close',
    ])
    .then((conversation) => {
      if (!conversation || conversation instanceof Error) return;

      if (newContacts) {
        logNewContacts(1);
      }

      if (conversation && conversation.peer) {
        logGroup(conversation, newContacts, tempId);
        openConversation(
          conversation.peer,
          conversation.peer.ownerGuid,
          'group'
        );
      } else {
        logContact(contacts[contacts.length - 1], tempId);
      }
    });
}

export function addContactInMagicList(contact) {
  bridge.tell('MagicList', 'addSelectedContactInList', [contact]);
}

export async function selectContact(
  jid,
  _selectedContacts,
  searchText,
  guid,
  currentSession,
  currentSessionId
) {
  const selectedContacts = [..._selectedContacts];
  const _tempId = `temp_id_${new Date().getTime()}`;
  logMedusaChatOpenStarted(_tempId);

  if (jid) {
    selectedContacts.push(jid);
  }

  if (selectedContacts.length > 1) {
    const selectedContactsPromise = selectedContacts.map((c) =>
      getContact(c, guid)
    );
    Promise.all(selectedContactsPromise).then((result) => {
      const contacts = result.map((c) => c && c[0]);
      createGroupAndOpen(contacts, currentSessionId, _tempId);
    });
  } else {
    [jid] = selectedContacts;

    const _contact = await getContact(jid, guid);
    const contact = _contact && _contact[0];

    if (!contact) {
      return;
    }

    addContactInMagicList(contact);

    if (contact.isGroup && contact.groupType === 'open' && !contact.isJoined) {
      joinPublicChannel(contact, true, searchText.length);
      logMedusaGenericEvent('channel_joined', {
        channel_jid: contact.jid,
        source: 'search',
      });
    } else if (contact.type === 'temp_search' || contact.isImported) {
      contactInvite(contact, currentSession, _tempId);
    } else {
      openConversation(contact, guid, 'buddy', searchText.length);
    }
    logContact(contact, _tempId);
  }
}

export function getRecentContacts(ownerGuid) {
  const promises = [];
  promises.push(bridge.ask('RecencyTracker', 'getRecentContacts', [ownerGuid]));
  promises.push(bridge.ask('RecencyTracker', 'getRecentGroups', [ownerGuid]));
  return Promise.all(promises).then((values) => {
    return [...values[0], ...values[1]];
  });
}

export function getAllBuddies(ownerGuid) {
  return bridge.ask('ContactStore', 'fetchBuddiesByGuid', [ownerGuid]);
}

export function getAllGroups(ownerGuid) {
  return bridge.ask('ContactStore', 'fetchGroupsByOwnerGuid', [ownerGuid]);
}

export function sanitizeChannelName(channelName) {
  return strip(channelName).replace(/\s/g, '') || 'emoji';
}

export function getCustomFieldValuePair(contact, session) {
  const sessionCustomFields = getCustomFieldsMapFromSession(session);
  const contactCustomFields = contact.customFields;
  if (!contactCustomFields || !sessionCustomFields) {
    return [];
  }

  return contactCustomFields
    .map((field) => {
      if (!sessionCustomFields[field.id]) {
        return null;
      }
      return {
        name: sessionCustomFields[field.id].name,
        priority: sessionCustomFields[field.id].priority,
        value: field.values[0],
      };
    })
    .filter((field) => !!field);
}
