import { createSelector } from 'reselect';
import { jidFromGuid } from '@/utils';
import ownerGuidSelector from './ownerGuid';
import currentPeerSelector from './currentPeer';

const defaultReceipt = {};

export const receiptStateByGuidSelector = createSelector(
  (state) => state.restricted.receiptState,
  (receiptState) => (ownerGuid) => {
    const ownerJid = jidFromGuid(ownerGuid);
    const { [ownerJid]: receipts = defaultReceipt } = receiptState || {};
    return receipts;
  }
);

export const receiptForCurrentPeerSelector = createSelector(
  receiptStateByGuidSelector,
  ownerGuidSelector,
  currentPeerSelector,
  (receiptStateByGuid, ownerGuid, currentPeer) =>
    receiptStateByGuid(ownerGuid)?.[currentPeer?.jid] || {}
);

export default createSelector(
  receiptStateByGuidSelector,
  ownerGuidSelector,
  (receiptStateByGuid, ownerGuid) => receiptStateByGuid(ownerGuid)
);
