import React from 'react';
import PropTypes from 'prop-types';
import { SortableElement } from 'react-sortable-hoc';

/**
 * Sortable list item
 *
 * @param {object} props
 * @param {children} props.children
 */
const SortableItem = ({ children, className }) => (
  <div className={className}>{children}</div>
);

SortableItem.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
};

SortableItem.defaultProps = {
  className: '',
};

export default SortableElement(SortableItem);
