import React, { useCallback } from 'react';
import cx from 'classnames';
import ChatsProxy from '@/Proxies/ChatsProxy';
import contactHOC from '@/subscribeHOCs/contact';
import EmojiDecorator from '@/components/common/EmojiDecorator';
import { openWidgetFullScreenInShell } from '@/utils/SessionUtils';
import { Deactivated as DeactivatedBadge } from '@/components/common/badges';
import { getFullContactImageUrl } from '@/utils/image';
import getTranslatedString from '@/utils/getTranslatedString';
import noop from '@/utils/noop';
import i18N from '../../nls';
import cssStyles from './Sender.css';

const imgClickHandler = (src) => {
  openWidgetFullScreenInShell('widgets/ImagePopup', {
    src: getFullContactImageUrl(src),
  });
};

type SenderViewProps = {
  contact?: Contact;
  onClick: (c: Contact) => void;
  sentAsApp: any;
  visibleToMe: string[];
  jid: string;
  isBuddyChat: boolean;
};

function SenderView({
  contact,
  onClick,
  sentAsApp = {},
  visibleToMe,
  jid = '',
  isBuddyChat,
}: SenderViewProps) {
  const {
    name: contactName = '',
    imageUrl: contactImageUrl,
    isDeactivated = false,
  } = contact || {};
  const { name: appName, profileImage: appImageUrl } = sentAsApp;

  const name =
    appName ||
    (contact.isOwner && isBuddyChat
      ? getTranslatedString('me_text', i18N)
      : contactName);
  const imageUrl = appImageUrl || contactImageUrl;

  const onNameClick = useCallback(() => {
    onClick(contact);
  }, [onClick, contact]);

  const onImageClick = useCallback(() => {
    imgClickHandler(imageUrl);
  }, [imageUrl]);

  return (
    <div
      role='menuitem'
      tabIndex={0}
      onKeyDown={noop}
      className={cx(cssStyles.sender, {
        [cssStyles.deactivated]: isDeactivated,
      })}
    >
      <span onClick={onImageClick} onKeyDown={noop} tabIndex={-1} role='button'>
        <img alt='' src={imageUrl} className={cssStyles.image} />
      </span>
      <span className={cssStyles.titleSection}>
        <span
          className={cx(cssStyles.name, {
            [cssStyles.hasClickAction]: onClick !== noop,
          })}
          onClick={onNameClick}
          role='button'
          tabIndex={-1}
          onKeyDown={noop}
        >
          <EmojiDecorator text={name} />
        </span>
        {isDeactivated && <DeactivatedBadge {...{ marginLeft: '5px' }} />}
      </span>

      {appName ||
      appImageUrl ||
      (jid[0] === 'B' && jid !== 'Blrrpp1s4ru9s4ss@go.to') ? (
        <span className={cssStyles.app}>App</span>
      ) : null}
      {visibleToMe && (
        <span className={cssStyles.visibleToMeWrapper}>
          <span className={cssStyles.visibleToMeIcon} />
          <span className={cssStyles.visibleToMeText}>
            {getTranslatedString('visible_to_me_text', i18N)}
          </span>
        </span>
      )}
    </div>
  );
}

const Sender = (contactHOC(SenderView) as unknown) as React.ComponentType<
  Omit<SenderViewProps, 'contact'> & { ownerGuid: string; jid: string }
>;

function changeConversation(contact) {
  const { ownerGuid, jid } = contact;
  const ownerJid = `${ownerGuid}@go.to`;
  if (ownerJid !== jid) {
    ChatsProxy.openConversation(contact, true);
  }
}

type SenderProps = {
  message: Message;
  ownerGuid: string;
  isBuddyChat: boolean;
};

export default React.memo(function ({
  message,
  ownerGuid,
  isBuddyChat,
}: SenderProps) {
  const {
    sender: { jid },
    sentAsApp,
    visibleTo,
  } = message;
  const { name: appName, profileImage } = sentAsApp || {};
  const ownerJid = `${ownerGuid}@go.to`;
  if (appName && profileImage) {
    return (
      <SenderView
        jid={jid}
        sentAsApp={sentAsApp}
        onClick={noop}
        visibleToMe={visibleTo}
        isBuddyChat={isBuddyChat}
      />
    );
  }
  return (
    <Sender
      ownerGuid={ownerGuid}
      jid={jid}
      sentAsApp={sentAsApp}
      onClick={
        isBuddyChat || appName || ownerJid === jid ? noop : changeConversation
      }
      visibleToMe={visibleTo}
      isBuddyChat={isBuddyChat}
    />
  );
});
