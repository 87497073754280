export default {
  editor_messageDropdown_menu_text_copy: 'Copy',
  editor_messageDropdown_menu_text_edit: 'Edit',
  editor_messageDropdown_menu_text_retry: 'Retry',
  editor_messageDropdown_popup_text_deleteMessage:
    'This message will be deleted for everyone in this conversation.',
  editor_messageDropdown_popup_text_deleteMessage_buddy:
    'This message will be deleted for both {peerName} and you.',
  editor_messageDropdown_popup_title_deleteMessage: 'Delete message?',
  editor_messageDropdown_popup_buttonText_deleteMessage_Yes: 'Yes, delete',
  editor_messageDropdown_popup_buttonText_delete: 'Delete',
  editor_deletion_failed: 'Action disallowed',
  editor_not_connected_to_internet_error:
    'You seem to have gone offline. This is usually due to poor/no internet connectivity.',
};
