/* eslint-disable camelcase */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import memoizeOne from 'memoize-one';
import throttle from 'lodash/throttle';
import isEmpty from 'lodash/isEmpty';
import { getTextToCopyFromSelection } from '@/utils/message';
import connectionState from '@/utils/ConnectionState';
import MessageListActions from '@/Contexts/MessageListActions';
import InfoMessageProps from '@/Contexts/InfoMessageProps';
import MessageProxy from '@/Proxies/MessageProxy';
import Loader from '@/components/common/Icons/Loader';
import { FETCH_STATUS } from '@/utils/constants';
import { isInfoMessageVisible } from '@/utils/message/infoMessageUtils';
import { getMemoizedArray, highlightMessage, isValidSid } from '@/utils';
import {
  isSidUnread,
  isMessageUnreadByMe,
  canUseAsUnreadMarker,
} from '@/utils/ReceiptUtils';
import {
  currentSessionSelector,
  allCurrentMessagesSelector,
  receiptForCurrentPeerSelector,
  countedUnreadMessagesForCurrentPeer,
  currentPeerSelector,
  channelPreferencesSelector,
} from '@/selectors';
import PerformanceLogger, {
  OPEN_CONVERSATION,
} from '@/utils/PerformanceLogger';
import { ChatsProxy } from '@/Proxies';
import ChatHeader from './ChatHeader';
import ChatFooter from './ChatFooter';
import MessageRenderer from './MessageRenderer';
import messageStyles from '../Message/Message.css';
import cssStyles from './MessageList.css';
import ScrollButton from './ScrollButton';

const BOTTOM_BUFFER_PX = 60;
const SCROLL_BUFFER = 5;

function sortingMessages(msg1 = {}, msg2 = {}) {
  // all undelivered message placed below received/acked msgs
  const { timestamp: timestamp1, stimestamp: serverTimestamp1 } = msg1;
  const { timestamp: timestamp2, stimestamp: serverTimestamp2 } = msg2;
  if (serverTimestamp1) {
    if (serverTimestamp2) {
      return serverTimestamp1 - serverTimestamp2;
    }
    return -1;
  }
  if (serverTimestamp2) {
    return 1;
  }
  return timestamp1 - timestamp2;
}

/**
 * Checks whether the message was sent within the last 10 seconds. Scroll to bottom iff this is true.
 * @param {number} time The message sent time in milliseconds.
 */
function within10SecondsOf(time) {
  return Date.now() - time < 10 * 1000;
}

const defaultState = {
  showScrollButton: false,
  shouldScrollToUnread: false,
  isAnchorSet: false,
  shouldShowUnreadMarker: true,
};

class MessageList extends PureComponent {
  static defaultProps = {
    onInteract: () => {},
    setIsMessageListAtBottom: () => {},
    messageAnchor: undefined,

    hasFuture: false,
    fetchMoreFuture: () => {},
    futureFetchStatus: undefined,
    hasPast: false,
    fetchMorePast: () => {},
    pastFetchStatus: undefined,

    paywallHit: undefined,
    messagesForPeerJid: undefined,
    messages: [],
    messageIds: [],
    currentSessionId: undefined,
    currentPeer: undefined,
    ownerGuid: undefined,
  };

  mutationConfig = { attributes: true, childList: true, subtree: true };

  state = defaultState;

  // not kept in state as async updating value is not desired
  atBottom = false;

  // current visible messages to the user
  visibleMessagesCache = new Set();

  // current scroll anchor properties
  scrollAnchorProps = {
    el: null,
    offsetTop: 0,
    offsetHeight: 0,
    scrollWrapperHeight: 0,
  };

  constructor(props) {
    super(props);
    const { ownerGuid, messageIds } = this.props;
    this.messageProxySubscriptions = MessageProxy.subscribe(
      ownerGuid,
      messageIds
    );
    this.scrollRef = React.createRef();
    this.containerRef = React.createRef();
    this.messageListRef = React.createRef();
    window.msgList = this;
  }

  scrollWrapperResizeObserver = new ResizeObserver(() => {
    const { current: scrollWrapper } = this.scrollRef;

    if (this.atBottom && scrollWrapper) {
      // Push element to bottom
      scrollWrapper.scrollTop =
        scrollWrapper.scrollHeight - scrollWrapper.clientHeight;
    }
  });

  // messageListResizeObserver = new ResizeObserver(() => {
  //   this.doScrollCorrection();
  // });

  rafCorrectionTimer;

  usingMutationObserver = true;

  messageListResizeObserver = new MutationObserver(() => {
    const { current: scrollWrapper } = this.scrollRef;
    if (this._scrollHeight !== scrollWrapper.scrollHeight) {
      this.doScrollCorrection();
      this._scrollHeight = scrollWrapper.scrollHeight;
      const currentScrollTop = scrollWrapper.scrollTop;
      if (this.rafCorrectionTimer) {
        cancelAnimationFrame(this.rafCorrectionTimer);
        this.rafCorrectionTimer = undefined;
      }
      this.rafCorrectionTimer = requestAnimationFrame(() => {
        // In some cases, the computed scrolltop value from the correction is not
        // same just before applying the change.  This causes some unwanted shifts in scroll.
        // We correct this by applying the same value as while computing.
        scrollWrapper.scrollTop = currentScrollTop;
      });
    }
  });

  /**
   *
   * @param {*} offsetTop
   * @param {*} offsetHeight
   * @param {*} edge
   *
   * returns offset of the edge passed.
   */
  getElementsEdgeOffset = function (offsetTop, offsetHeight, edge = 'top') {
    // const { offsetTop, offsetHeight } = element;
    let elOffset = offsetTop;

    switch (edge) {
      case 'bottom':
        elOffset = offsetTop + offsetHeight;
        break;
      case 'middle':
        elOffset = offsetTop + offsetHeight / 2;
        break;
      case 'top':
      default:
    }

    return elOffset;
  };

  /**
   *
   * @param {*} el
   * @param {*} distanceFromBottom
   * @param {*} options
   *
   * options = {
   *  elementsEdge, 'top' | 'bottom',
   *  distanceFromTop: number,
   *  distanceFromBottom: number,
   * }
   *
   */
  setScrollPosition(el, options) {
    const {
      elementsEdge = 'bottom',
      distanceFromTop,
      distanceFromBottom,
    } = options;
    const { current: scrollWrapper } = this.scrollRef;
    const { offsetHeight, offsetTop } = el;
    if (!scrollWrapper.contains(el)) {
      return;
    }
    const { scrollHeight, clientHeight } = scrollWrapper;
    const {
      hasPast,
      hasFuture,
      pastFetchStatus,
      futureFetchStatus,
    } = this.props;

    const _distanceFromTop =
      distanceFromTop !== undefined
        ? distanceFromTop
        : clientHeight - (distanceFromBottom || 0);

    const elementOffset = this.getElementsEdgeOffset(
      offsetTop,
      offsetHeight,
      elementsEdge
    );

    const scrollTopValue = elementOffset - _distanceFromTop;
    const maxScrollTop = scrollHeight - clientHeight;

    /**
     * In cases when we are not able to position element in the desired position,
     * we wait for new content to load and try again.
     *
     * If there's no content to load we set isAnchorSet to true, assuming it's set.
     *
     */
    if (
      (scrollTopValue < 0 && !hasPast) ||
      pastFetchStatus === FETCH_STATUS.FAILED
    ) {
      // ScrollTopValue being -ve implies, we need more content at the top to position accurately.
      this.setState({
        isAnchorSet: true,
      });
    }
    if (
      (scrollTopValue > maxScrollTop && !hasFuture) ||
      futureFetchStatus === FETCH_STATUS.FAILED
    ) {
      // ScrollTopValue exceeding max value implies, ee need more content at the bottom to position accurately.
      this.setState({
        isAnchorSet: true,
      });
    }

    if (scrollTopValue >= 0 && scrollTopValue <= maxScrollTop) {
      this.setState({
        isAnchorSet: true,
      });
    }

    scrollWrapper.scrollTop = elementOffset - _distanceFromTop;

    this.setScrollAnchor(
      el,
      offsetTop,
      offsetHeight,
      scrollWrapper.scrollHeight,
      elementsEdge
    );
  }

  doScrollCorrection = () => {
    const { current: scrollWrapper } = this.scrollRef;

    const {
      el,
      offsetTop: prevOffsetTop,
      offsetHeight: prevOffsetHeight,
      elementsEdge,
    } = this.getScrollAnchor();

    if (!el || !scrollWrapper?.contains(el)) {
      return;
    }

    const { offsetTop, offsetHeight } = el;

    const maxScrollTop =
      scrollWrapper.scrollHeight - scrollWrapper.clientHeight;
    const currentScrollTop = scrollWrapper.scrollTop;
    // const offsetTopDiff = offsetTop - prevOffsetTop;
    const offsetEdge = this.getElementsEdgeOffset(
      offsetTop,
      offsetHeight,
      elementsEdge
    );
    const prevOffsetEdge = this.getElementsEdgeOffset(
      prevOffsetTop,
      prevOffsetHeight,
      elementsEdge
    );
    const diff = offsetEdge - prevOffsetEdge;

    // const diff = offsetEdgeDiff;
    if (diff !== 0) {
      let correction = diff;
      if (
        Math.abs(maxScrollTop - currentScrollTop) < SCROLL_BUFFER &&
        diff < 0
      ) {
        // even if diff is +ve scrollTop will not change as it is already at it's max value
        correction = 0;
      }
      scrollWrapper.scrollTop += correction;
    }

    this.setScrollAnchor(
      el,
      el.offsetTop,
      el.offsetHeight,
      scrollWrapper.scrollHeight,
      elementsEdge
    );

    // if (offsetBottom !== prevOffsetBottom) {
    // scrollWrapper.scrollTop =
    //   scrollWrapper.scrollTop + offsetTop - prevOffsetTop;
    // do nothing
    // }
  };

  /**
   * We are not using state or props here to determine the result,
   * but the current rendered content in the messages List.
   * @returns {Element} last visible message element.
   */
  getLastVisibleMessage = function () {
    let visibleEl;
    const { current: scrollWrapper } = this.scrollRef;
    if (!scrollWrapper) {
      return visibleEl;
    }
    const { scrollTop, clientHeight } = scrollWrapper;
    const renderedMessages = document.querySelectorAll(`[data-msgroot-id]`);

    for (let i = renderedMessages.length - 1; i >= 0; i -= 1) {
      const el = renderedMessages[i];
      if (!el) {
        // eslint-disable-next-line no-continue
        continue;
      }
      const { offsetTop } = el;
      if (scrollTop + clientHeight > offsetTop) {
        visibleEl = el;
        break;
      }
    }

    return visibleEl;
  };

  modifyScrollAnchor = () => {
    const { current: scrollWrapper } = this.scrollRef;

    if (scrollWrapper && this.visibleMessagesCache.size > 0) {
      const selector = [...this.visibleMessagesCache]
        .map((msgId) => `[data-msgroot-id='${msgId}']`)
        .join(',');
      const visibleMessages = scrollWrapper.querySelectorAll(selector);
      const messageEl = visibleMessages[visibleMessages.length - 1];

      if (messageEl) {
        this.setScrollAnchor(
          messageEl,
          messageEl.offsetTop,
          messageEl.offsetHeight,
          scrollWrapper.scrollHeight,
          'bottom'
        );
      }
    }
  };

  onMessageIntersection = (entry) => {
    const { target, intersectionRatio } = entry;
    const msgId =
      target.getAttribute('data-msgroot-id') ||
      target.getAttribute('data-infogroup-id');
    if (msgId) {
      if (intersectionRatio > 0) {
        this.visibleMessagesCache.add(msgId);
      } else {
        delete this.visibleMessagesCache.delete(msgId);
      }
      this.modifyScrollAnchor();
    }
    // Note: can't call fetchmore here as the current fetchMore is based on
    // height of the available content, and if messages are too long intersection
    // observer will not be called on scroll.
  };

  setScrollAnchor(
    el,
    offsetTop,
    offsetHeight,
    scrollWrapperHeight,
    elementsEdge = 'bottom'
  ) {
    this.scrollAnchorProps = {
      el,
      offsetTop,
      offsetHeight,
      elementsEdge,
      scrollHeight: scrollWrapperHeight,
    };
  }

  getScrollAnchor() {
    return this.scrollAnchorProps || {};
  }

  /**
   *
   * @param {*} mcId
   * @param {*} distanceFromTop
   * @param {*} msgSid
   *
   * applies message anchor to the list, i.e sets scroll to appropriate
   * position in a way that passed message is visible in the correct position.
   */
  applyMessageAnchor(anchor) {
    const {
      mcId,
      msgSid,
      distanceFromBottom,
      distanceFromTop,
      elementsEdge,
      type,
    } = anchor;
    const { current: scrollWrapper } = this.scrollRef;
    if (!scrollWrapper) {
      return;
    }

    if (type === 'LATEST_MESSAGE') {
      this.scrollToLastRenderedMessage();
      return;
    }

    let el;
    if (type === 'UNREAD_ANCHOR') {
      const unreadMessage = this.getFirstUnreadMessage(true);

      if (unreadMessage === 'IN-CONCLUSIVE') {
        return;
      }

      if (unreadMessage) {
        el = this.getMessageEl({ mcId: unreadMessage.message_cache_id });
      }
    } else if (mcId || msgSid) {
      el = this.getMessageEl({ mcId, msgSid });
    }

    if (!el) {
      /**
       * If element to anchor doesn't exist, and also there's no scroll anchor
       * scroll to last message and set that message as scroll anchor.
       *
       * This is to make sure, we always have a scroll anchor present.
       * Although this may not be the desired result, it will keep our
       * message list sane.
       */
      const { el: anchoredEl } = this.getScrollAnchor();
      if (!anchoredEl || !scrollWrapper.contains(anchoredEl)) {
        this.scrollToLastRenderedMessage();
      }
      return;
    }
    this.setScrollPosition(el, {
      elementsEdge,
      distanceFromBottom,
      distanceFromTop,
    });
  }

  /**
   * Finds the last visible message, and returns with it's distance from top.
   * mcid, and sid.
   *
   * @returns {MessageAnchor}
   *  {
   *    mcId, : mcId of the anchored element
   *    msgSid, : sid of the anchored element
   *    distanceFromBottom : distance of the anchored element from bottom of the container
   *  }
   */
  getMessageAnchor() {
    const el = this.getLastVisibleMessage();
    const { current: scrollWrapper } = this.scrollRef;

    if (!el || !scrollWrapper) {
      return null;
    }
    const { offsetTop, offsetHeight } = el;
    const mcId = el.getAttribute('data-msgroot-id');
    const msgSid = el.getAttribute('data-msgroot-sid');
    const { scrollTop, clientHeight } = scrollWrapper;
    const distanceFromBottom =
      scrollTop + clientHeight - offsetTop - offsetHeight;

    return {
      mcId,
      msgSid,
      distanceFromBottom,
      elementsEdge: 'bottom',
      anchorTime: Date.now(),
    };
  }

  fetchMoreRaf;

  fetchMore = (hardFetch = false) => {
    if (this.fetchMoreRaf) {
      cancelAnimationFrame(this.fetchMoreRaf);
    }
    this.fetchMoreRaf = requestAnimationFrame(() => {
      this.fetchMoreRaf = undefined;
      const multiplier = 1;
      const { current: scrollWrapper } = this.scrollRef;
      const { scrollHeight, clientHeight, scrollTop } = scrollWrapper;
      const scrollBottom = scrollHeight - clientHeight - scrollTop;

      const {
        hasPast,
        hasFuture,
        // removeFuture,
        // removePast,
        fetchMorePast,
        hardFetchMorePast,
        fetchMoreFuture,
        hardFetchMoreFuture,
        setFutureStatusAsSuccess,
        setPastStatusAsSuccess,
      } = this.props;

      if (scrollBottom < multiplier * clientHeight && hasFuture) {
        if (hardFetch) {
          hardFetchMoreFuture();
        } else {
          fetchMoreFuture();
        }
      } else if (hardFetch) {
        setFutureStatusAsSuccess();
      }
      if (scrollBottom > 2 * multiplier * clientHeight) {
        // removeFuture();
      }

      if (scrollTop < multiplier * clientHeight && hasPast) {
        if (hardFetch) {
          hardFetchMorePast();
        } else {
          fetchMorePast();
        }
      } else if (hardFetch) {
        setPastStatusAsSuccess();
      }

      if (scrollTop > 2 * multiplier * clientHeight) {
        // removePast();
      }
    });
  };

  /* 
    persists message anchor.
    Call this function on peer change and willUnmount.
  */
  persistMessageAnchor = () => {
    const { setMessageAnchor, messagesForPeerJid, ownerGuid } = this.props;
    const { current: scrollWrapper } = this.scrollRef;

    if (
      Math.abs(
        scrollWrapper.scrollHeight -
          scrollWrapper.clientHeight -
          scrollWrapper.scrollTop
      ) < BOTTOM_BUFFER_PX
    ) {
      setMessageAnchor(ownerGuid, messagesForPeerJid, null);
      return;
    }
    const msgAnchor = this.getMessageAnchor();
    setMessageAnchor(ownerGuid, messagesForPeerJid, msgAnchor);
  };

  componentDidMount() {
    connectionState.subscribe(this.resetFetch);
    // this.sendScrollPosition();

    const messageList = this.messageListRef.current;
    const scrollWrapper = this.scrollRef.current;
    if (scrollWrapper) {
      this.scrollWrapperResizeObserver.observe(scrollWrapper);
      if (this.usingMutationObserver) {
        this.messageListResizeObserver.observe(
          messageList,
          this.mutationConfig
        );
      } else {
        this.messageListResizeObserver.observe(messageList);
      }
    }
  }

  getSnapshotBeforeUpdate(prevProps) {
    const { currentPeer: prevPeer } = prevProps;
    const { currentPeer } = this.props;
    // Set message anchor before peer update is rendered,
    // as we remove messages on peer change.
    if (prevPeer.jid !== currentPeer.jid) {
      this.persistMessageAnchor();
    }

    // TODO: check if scroll anchor is being removed or not.
    // if(prevMessages.length !== messages.length){
    //   const { el }
    // }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      ownerGuid,
      messageIds,
      messages,
      currentPeer,
      currentSession,
      unreadMessages,
      messageAnchor,
      hasPast,
      hasFuture,
      pastFetchStatus,
      futureFetchStatus,
      setIsMessageListAtBottom,
    } = this.props;
    const {
      messages: prevMessages,
      messageIds: prevMessageIds,
      currentPeer: prevPeer,
      currentSession: prevSession,
      unreadMessages: prevUnreadMessages,
      messageAnchor: prevMessageAnchor,
      hasPast: prevHasPast,
      hasFuture: prevHasFuture,
      pastFetchStatus: prevPastFetchStatus,
      futureFetchStatus: prevFutureFetchStatus,
    } = prevProps;

    const { isAnchorSet: prevIsAnchorSet } = prevState;

    const { isAnchorSet } = this.state;

    if (!prevIsAnchorSet && isAnchorSet) {
      // Now we have the anchored message and we can highlight it.
      const { msgSid, highlight, source } = messageAnchor;
      const messageEl = this.getMessageEl({ msgSid });
      if (highlight) {
        if (messageEl) {
          highlightMessage(
            messageEl,
            messageStyles.highlightMsgOut,
            messageStyles.highlightMsg
          );
          ChatsProxy.addAnalytics({
            messagesAvailable: false,
            jumpSuccessful: true,
            source,
          });
        } else if (source) {
          ChatsProxy.addAnalytics({
            messagesAvailable: false,
            jumpSuccessful: false,
            reason: 'other',
            source,
          });
        }
      }
    }

    if (prevMessageIds !== messageIds) {
      this.messageProxySubscriptions.update(ownerGuid, messageIds);
    }

    const scrollWrapper = this.scrollRef.current;
    const messageList = this.messageListRef.current;

    const isScrollRefRendered = currentPeer && currentSession;
    const wasScrollRefRendered = prevPeer && prevSession;

    if (!wasScrollRefRendered && isScrollRefRendered) {
      this.scrollWrapperResizeObserver.observe(scrollWrapper);
      this.messageListResizeObserver.observe(messageList, this.mutationConfig);
    }

    if (!isScrollRefRendered && wasScrollRefRendered) {
      this.scrollWrapperResizeObserver.disconnect();
      this.messageListResizeObserver.disconnect();
    }

    if (
      prevPeer?.jid !== currentPeer.jid ||
      prevMessageAnchor !== messageAnchor
    ) {
      // set default/initial state.
      this.setState(defaultState);
      this.scrollAnchorProps = {};
      this.visibleMessagesCache.clear();
      this.atBottom = false;
      setIsMessageListAtBottom(false);
    }

    // On receiving first set of messages
    if (prevMessages.length === 0 && messages.length > 0) {
      if (!isEmpty(messageAnchor)) {
        // this.atBottom = false;
        this.applyMessageAnchor(messageAnchor);
      } else {
        this.scrollToBottom();
        this.setState({
          isAnchorSet: true,
        });
      }
      PerformanceLogger.end(OPEN_CONVERSATION, currentPeer.jid);
    }

    // On receiving subsequent set of messages
    if (
      (messages.length > 0 &&
        prevMessages.length < messages.length &&
        prevMessages.length !== 0) ||
      (prevHasPast && !hasPast) ||
      (prevHasFuture && !hasFuture) ||
      (prevPastFetchStatus !== FETCH_STATUS.FAILED &&
        pastFetchStatus === FETCH_STATUS.FAILED) ||
      (prevFutureFetchStatus !== FETCH_STATUS.FAILED &&
        futureFetchStatus === FETCH_STATUS.FAILED)
    ) {
      if (!isAnchorSet) {
        if (!isEmpty(messageAnchor)) {
          this.applyMessageAnchor(messageAnchor);
        } else {
          this.setState({
            isAnchorSet: true,
          });
        }
      }
    }

    if (messages.length - prevMessages.length === 1 && isAnchorSet) {
      // When bottom part updates
      const newMessage = messages[messages.length - 1];
      /**
       * If there's a new message and it's sent by the current user, scroll to bottom.
       * or if view is already at bottom.
       */
      if (
        newMessage &&
        newMessage.sentByOwner &&
        !newMessage.isGroupUpdateMessage &&
        // Some world class jugaad
        within10SecondsOf(newMessage.sentTime)
      ) {
        // Push element to bottom
        this.scrollToBottom();
        this.setState({
          shouldShowUnreadMarker: false,
        });
      }

      if (this.atBottom) {
        this.scrollToBottom();
      }
    }

    if (prevMessageIds.length < messageIds.length && hasPast) {
      this.fetchMore();
    }

    if (
      messages.length !== prevMessages.length ||
      unreadMessages.length !== prevUnreadMessages.length
    ) {
      this.setScrollButtonState();
    }
  }

  getMessageBySid = (sid) => {
    const { messages } = this.props;
    return messages.find((msg) => msg.sid === sid);
  };

  scrollToUnreadMessage = () => {
    // const { current: scrollWrapper } = this.scrollRef;
    const { unreadMessages, setMessageAnchor, currentPeer } = this.props;
    const firstUnreadMessage = unreadMessages.length
      ? unreadMessages[0]
      : undefined;

    if (!firstUnreadMessage) {
      return this.scrollToBottom();
    }

    // const { message_cache_id: mcid } = firstUnreadMessage;
    // const el = scrollWrapper.querySelector(`[data-msgroot-id='${mcid}']`);
    // if (el) {
    //   return this.setScrollPosition(el, 50);
    // }

    return setMessageAnchor(currentPeer.ownerGuid, currentPeer.jid, {
      type: 'UNREAD_ANCHOR',
      distanceFromBottom: 50,
    });
  };

  /**
   * Sets the new message anchor to the sid passed. We make sure it is a non-deleted message before we jump.
   * @param {string} msgSid The `message.sid` to which we are jumping
   * @param {boolean} paywallHit Whether to show the paywall or not
   * @param {string} source - The jump trigger, can either be `reply` or `search`
   */
  setMessageAnchorInStore = (msgSid, paywallHit = false, source = 'reply') => {
    const {
      currentPeer: { jid, ownerGuid },
    } = this.props;
    // The setMessageAnchorInStore is always called by scrollToMessageBySid, which is called on replied message click
    ChatsProxy.setMessageAnchor({ msgSid, jid, ownerGuid, paywallHit }, source);
    // Now time to schedule a highlight inside a ChatMessage's componentDidUpdate
  };

  scrollToMessageBySid = async (
    sid,
    position = 'contain',
    shouldHighlight,
    source
  ) => {
    const message = this.getMessageBySid(sid);
    if (message) {
      return this.scrollToMessageByCacheId(
        message.message_cache_id,
        position,
        shouldHighlight,
        false,
        source
      );
    }
    const {
      currentPeer: { jid, ownerGuid },
    } = this.props;
    /* In case it's not a local message, we first get the message, check if it's not deleted and then set the message anchor */
    const response = await ChatsProxy.getAroundMessages(
      { sid },
      { jid, ownerGuid }
    );
    if (response.msgSids.indexOf(sid) > -1) {
      this.setMessageAnchorInStore(sid, false, source);
    } else {
      if (response.paywallHit) {
        ChatsProxy.addAnalytics({
          messagesAvailable: false,
          jumpSuccessful: false,
          reason: 'paywall',
          source,
        });
        const { current: scrollWrapper } = this.scrollRef;
        scrollWrapper.scrollTop = 0;
        return false;
      }
      ChatsProxy.addAnalytics({
        messagesAvailable: false,
        jumpSuccessful: false,
        reason: 'deletion',
        source,
      });
      ChatsProxy.showDeletedToast(jid);
    }
    return false;
  };

  getFirstUnreadMessage = (ignoreGroupUpdate = false) => {
    const { messages, receiptObj } = this.props;
    if (!messages.length) {
      return null;
    }

    // canUseAsUnreadMarker checks for isGroupUpdateMessage flag and for unread.
    const checkMethod = ignoreGroupUpdate
      ? canUseAsUnreadMarker
      : isMessageUnreadByMe;
    const firstMessage = messages[0];
    const isFirstUnread = isMessageUnreadByMe(receiptObj, firstMessage);

    // If first message in the list is unread then we won't be able to find actual first unread message.
    // There could be more unread messages before this.
    if (isFirstUnread) {
      return 'IN-CONCLUSIVE';
    }

    for (let i = 0; i < messages.length; i += 1) {
      const msg = messages[i];
      const prevMessage = messages[i - 1];

      const isUnread = checkMethod(receiptObj, msg);
      const isPrevUnread = prevMessage
        ? checkMethod(receiptObj, prevMessage)
        : false;
      if (prevMessage && isUnread && !isPrevUnread) {
        return msg;
      }
    }

    return null;
  };

  /**
   * Returns the message element with the given mcId or msgSid
   * @param {MessageAnchor} messageAnchor - The message anchor object should contain at least the mcId or msgSid
   */
  getMessageEl = ({ mcId, msgSid }) => {
    if (mcId) {
      return this.scrollRef.current.querySelector(
        `[data-msgroot-id='${mcId}']`
      );
    }
    return this.scrollRef.current?.querySelector(
      `[data-msgroot-sid='${msgSid}']`
    );
  };

  /**
   *
   * Scrolls the view to the message passed. If the message is already in the view,
   * no changes are applied. If message is partially in the view, we make sure to bring
   * top part of the message in the visible are.
   *
   *
   * @param {*} mcId , message's cache_id
   * @param {*} position , incase we need to position, position here.
   * @param {*} shouldHighlight , should highlight after positioning
   * @param {*} force , force position
   * @param {*} source , source of execution
   * @returns
   */
  scrollToMessageByCacheId = (
    mcId,
    position = 'bottom',
    shouldHighlight = true,
    force = false,
    source
  ) => {
    const messageEl = this.getMessageEl({ mcId });
    if (!messageEl) {
      /**
       * Local messages were available, but couldn't scroll because element wasn't found. Probably should not happen.
       */
      ChatsProxy.addAnalytics({
        messagesAvailable: true,
        jumpSuccessful: false,
        reason: 'other',
        source,
      });
      return false;
    }
    const { offsetTop, offsetHeight } = messageEl;
    const { current: scrollWrapper } = this.scrollRef;
    const { scrollTop, clientHeight } = scrollWrapper;
    const isTopVisible = offsetTop - scrollTop > 0;
    const isBottomVisible =
      offsetTop + offsetHeight - scrollTop - clientHeight <= SCROLL_BUFFER;
    let shouldPosition = true;

    if (!force) {
      if (isTopVisible && isBottomVisible) {
        /**
         * If the whole element is visible, anchor the element
         * to bottom, and no scrolling is required.
         */

        const distanceFromTop = offsetTop - scrollTop + offsetHeight;
        this.setScrollPosition(messageEl, {
          elementsEdge: 'bottom',
          distanceFromTop,
        });
        shouldPosition = false;
      }

      if (shouldPosition && isTopVisible) {
        // If only top is visible, anchor top and leave.
        const distanceFromTop = offsetTop - scrollTop;
        this.setScrollPosition(messageEl, {
          elementsEdge: 'top',
          distanceFromTop,
        });
        shouldPosition = false;
      }

      // make top visible if only bottom is visible
      if (shouldPosition && isBottomVisible) {
        this.setScrollPosition(messageEl, {
          elementsEdge: 'top',
          distanceFromTop: BOTTOM_BUFFER_PX,
        });
        shouldPosition = false;
      }
    }

    if (shouldPosition && position === 'contain') {
      this.setScrollPosition(messageEl, {
        elementsEdge: 'top',
        distanceFromTop: scrollWrapper.clientHeight / 2,
      });
    }

    if (shouldPosition && position === 'top') {
      this.setScrollPosition(messageEl, {
        elementsEdge: 'top',
        distanceFromTop: BOTTOM_BUFFER_PX,
      });
    }

    if (shouldPosition && position === 'bottom') {
      this.setScrollPosition(messageEl, {
        elementsEdge: 'bottom',
        distanceFromBottom: BOTTOM_BUFFER_PX,
      });
    }

    if (shouldHighlight) {
      highlightMessage(
        messageEl,
        messageStyles.highlightMsgOut,
        messageStyles.highlightMsg
      );
      // Add analytics on successful jump
      ChatsProxy.addAnalytics({
        messagesAvailable: true,
        jumpSuccessful: true,
        // `search` can only be a source if the jump trigger comes through ChatsProxy.openConversation.
        source,
      });
    }
    return true;
  };

  messageListActionsContext = {
    scrollToMessageBySid: this.scrollToMessageBySid,
  };

  getInfoMessageProps = () => {
    const { peer, channelPreferences } = this.props;
    if (
      !this.infoMessageProps ||
      this.infoMessageProps.peer !== peer ||
      this.infoMessageProps !== channelPreferences
    ) {
      this.infoMessageProps = {
        peer,
        channelPreferences,
      };
    }
    return this.infoMessageProps;
  };

  /**
   * Scrolls to last rendered message in the dom.
   * This can be used as a fallback when we can't find messages to anchor.
   */
  scrollToLastRenderedMessage = () => {
    const renderedMessages = document.querySelectorAll(`[data-msgroot-id]`);
    const lastRenderedMessage = renderedMessages[renderedMessages.length - 1];

    if (lastRenderedMessage) {
      const mcid = lastRenderedMessage.getAttribute('data-msgroot-id');
      this.scrollToMessageByCacheId(
        mcid,
        'bottom',
        false,
        true,
        'scroll-to-last-function'
      );
    }
  };

  scrollToBottom = () => {
    const {
      messages,
      setIsMessageListAtBottom,
      hasFuture,
      setMessageAnchor,
      messagesForPeerJid,
      ownerGuid,
    } = this.props;

    if (hasFuture) {
      setMessageAnchor(ownerGuid, messagesForPeerJid, {
        type: 'LATEST_MESSAGE',
      });
      return;
    }

    if (!messages.length) {
      return;
    }

    const lastMessage = messages[messages.length - 1];
    const { message_cache_id: mcid } = lastMessage;
    const messageEl = this.scrollRef.current.querySelector(
      `[data-msgroot-id='${mcid}']`
    );
    // const { current: scrollWrapper } = this.scrollRef;
    // this.scrollRef.current.scrollTop = scrollWrapper.scrollHeight;
    this.atBottom = true;
    setIsMessageListAtBottom(this.atBottom);
    if (messageEl) {
      this.setScrollPosition(messageEl, {
        elementsEdge: 'bottom',
        distanceFromBottom: 0,
      });
    }
  };

  setScrollButtonState = () => {
    this.setState(
      ({
        showScrollButton: prevShowScroll,
        shouldScrollToUnread: prevUnreadScroll,
      }) => {
        const { unreadMessages, receiptObj, ownerGuid } = this.props;
        const { current: scrollWrapper } = this.scrollRef;
        let shouldScrollToUnread = false;
        let showScrollButton = false;
        if (scrollWrapper && !this.atBottom) {
          showScrollButton = true;
          const lastVisibleMessage = this.getLastVisibleMessage();
          if (lastVisibleMessage) {
            const lastVisibleMessageSid = lastVisibleMessage.getAttribute(
              `data-msgroot-sid`
            );

            // If lastVisible message doesn't have a valid sid,
            // set button to jump to bottom as a fallback.
            if (isValidSid(lastVisibleMessageSid)) {
              const isLastVisibleMessageUnread = isSidUnread(
                receiptObj,
                lastVisibleMessageSid,
                ownerGuid
              );

              // Show scroll only when all unread messages are at bottom of the container view.
              if (!isLastVisibleMessageUnread && unreadMessages.length) {
                shouldScrollToUnread = true;
              }
            }
          }
        }
        if (
          showScrollButton !== prevShowScroll ||
          shouldScrollToUnread !== prevUnreadScroll
        ) {
          return {
            showScrollButton,
            shouldScrollToUnread,
          };
        }
        return null;
      }
    );
  };

  sendScrollPosition = throttle(
    () => {
      const { setIsMessageListAtBottom, hasFuture } = this.props;
      const { current: scrollWrapper } = this.scrollRef;
      if (!scrollWrapper) {
        return;
      }
      this.atBottom =
        scrollWrapper.scrollHeight -
          (scrollWrapper.scrollTop + scrollWrapper.clientHeight) <
          BOTTOM_BUFFER_PX && !hasFuture;
      setIsMessageListAtBottom(this.atBottom);
      this.setScrollButtonState();
    },
    300,
    { leading: false }
  );

  resetFetch = throttle(() => {
    // for automatic retries, limit once per minute
    const { pastFetchStatus, futureFetchStatus } = this.props;
    if (
      pastFetchStatus === FETCH_STATUS.FAILED ||
      futureFetchStatus === FETCH_STATUS.FAILED
    ) {
      // Fetch only if last status was failed.
      this.fetchMore(true);
    }
  }, 60000);

  shouldShowLoader = memoizeOne(
    (isAnchorSet, futureFetchStatus, pastFetchStatus) => {
      if (isAnchorSet) {
        return false;
      }

      if (
        futureFetchStatus !== FETCH_STATUS.FETCHING &&
        pastFetchStatus !== FETCH_STATUS.FETCHING
      ) {
        return false;
      }

      return true;
    }
  );

  onScroll = () => {
    this.sendScrollPosition();
    this.fetchMore();
  };

  onFocus = () => {
    const { onInteract } = this.props;
    onInteract();
  };

  onClick = () => {
    const { onInteract } = this.props;
    onInteract();
  };

  onCopy = (e) => {
    const { nativeEvent } = e;
    const { messages } = this.props;
    const textToCopy = getTextToCopyFromSelection(
      window.getSelection(),
      messages
    );
    if (textToCopy) {
      nativeEvent.clipboardData.setData('text/plain', textToCopy);
      e.preventDefault();
    }
  };

  onMouseOver = () => {
    if (document.hasFocus()) {
      const { onInteract } = this.props;
      onInteract();
    }
  };

  render() {
    const {
      messages,
      currentSession,
      currentPeer,
      ownerGuid,
      receiptObj,

      messagesForPeerJid,
      onInteract,

      hasPast,
      pastFetchStatus,
      hardFetchMorePast,

      hasFuture,
      futureFetchStatus,
      hardFetchMoreFuture,
      paywallHit,
    } = this.props;

    const {
      showScrollButton,
      shouldScrollToUnread,
      isAnchorSet,
      shouldShowUnreadMarker,
    } = this.state;

    // TODO: may be remove these conditions, and let this component always have a scrollRef
    if (!currentPeer || !currentSession) {
      return null;
    }

    const { jid: currentPeerId } = currentPeer;
    return (
      <>
        <div
          ref={this.scrollRef}
          className={cssStyles.messageListContainer}
          onScroll={this.onScroll}
          onFocus={this.onFocus}
          onClick={this.onClick}
          onCopy={this.onCopy}
          onMouseOver={this.onMouseOver}
          role='presentation'
        >
          <div ref={this.messageListRef} className={cssStyles.messageList}>
            <ChatHeader
              canFetch={hasPast}
              fetchStatus={pastFetchStatus}
              fetchMore={hardFetchMorePast}
              paywallHit={paywallHit}
              currentSession={currentSession}
              currentPeer={currentPeer}
            />
            <MessageListActions.Provider value={this.messageListActionsContext}>
              <InfoMessageProps.Provider value={this.getInfoMessageProps()}>
                <MessageRenderer
                  currentSession={currentSession}
                  currentPeer={currentPeer}
                  ownerGuid={ownerGuid}
                  receiptObj={receiptObj}
                  messages={messages}
                  messagesForPeerJid={messagesForPeerJid}
                  onMessageIntersection={this.onMessageIntersection}
                  showUnreadMarker={shouldShowUnreadMarker}
                />
              </InfoMessageProps.Provider>
            </MessageListActions.Provider>
          </div>
          <ChatFooter
            canFetch={hasFuture}
            paywallHit={false}
            fetchStatus={futureFetchStatus}
            fetchMore={hardFetchMoreFuture}
            currentSession={currentSession}
          />
        </div>
        {this.shouldShowLoader(
          isAnchorSet,
          futureFetchStatus,
          pastFetchStatus
        ) ? (
          <div className={cssStyles.overlay}>
            <Loader color='green' />
          </div>
        ) : null}
        {showScrollButton ? (
          <ScrollButton
            shouldScrollToUnread={shouldScrollToUnread}
            ownerGuid={ownerGuid}
            currentPeerId={currentPeerId}
            onInteract={onInteract}
            scrollToUnreadMessage={this.scrollToUnreadMessage}
            scrollToBottom={this.scrollToBottom}
          />
        ) : null}
      </>
    );
  }

  componentWillUnmount() {
    this.persistMessageAnchor();
    connectionState.unsubscribe(this.resetFetch);
    this.messageProxySubscriptions.unsubscribe();

    if (this.messageListResizeObserver) {
      this.messageListResizeObserver.disconnect();
    }

    if (this.scrollWrapperResizeObserver) {
      this.scrollWrapperResizeObserver.disconnect();
    }
  }
}

const propsSelector = (
  _,
  { messageIds, currentSessionId, currentPeer: { jid: peerJid } = {} }
) => ({
  messageIds,
  currentSessionId,
  peerJid,
});

const memoizedArray = getMemoizedArray([]);
const unreadMessagesMemoizedarray = getMemoizedArray([]);

const isMessageVisible = (m, channelPreferences, peer) => {
  if (m.isGroupUpdateMessage) {
    return isInfoMessageVisible(m, channelPreferences, peer);
  }
  return m.isVisibleMessage;
};

const mapStateToProps = createSelector(
  [
    propsSelector,
    allCurrentMessagesSelector,
    currentSessionSelector,
    receiptForCurrentPeerSelector,
    countedUnreadMessagesForCurrentPeer,
    currentPeerSelector,
    channelPreferencesSelector,
  ],
  (
    { messageIds, currentSessionId },
    allMessages,
    currentSession,
    receiptObj,
    unreadMessages,
    peer,
    channelPreferences
  ) => {
    return {
      currentSessionId,
      currentSession,
      unreadMessages: unreadMessagesMemoizedarray(
        unreadMessages.sort(sortingMessages)
      ),
      messages: memoizedArray(
        messageIds
          .map((mCId) => allMessages[mCId] || { message_cache_id: mCId })
          .filter((m) => isMessageVisible(m, channelPreferences, peer))
          .sort(sortingMessages)
      ),
      receiptObj,
      peer,
      channelPreferences,
    };
  }
);

export default connect(mapStateToProps)(MessageList);
