import {
  openWidgetPopupInShell,
  openWidgetFullScreenInShell,
} from '../SessionUtils';
import getTranslatedString from '../getTranslatedString';
import i18n from './I18N';

// TODO: It would be nice if I can get a promise returned, instead of bridge subscriptions.
/**
  In cases where components are expecting input from user actions inside modals, 
  user has to pass a bridge publish call, and subscribe to that publish call. 

  It would be nice, if it's a promise instead of bridge calls.
 */

// From conversationUtils, not sure how to get the currentLanguage.
function cloneChannelConfirmModalDialog(conversationIdentity, memberCount) {
  openWidgetPopupInShell('widgets/ConfirmDialog', {
    okText: getTranslatedString('yes_clone', i18n),
    okButtonClass: 'btn--action',
    confirmSubText: getTranslatedString('WARNING-GROUP_CLONE_MESSAGE', i18n, {
      member_count: memberCount,
    }),
    containerClass: 'clone-dialog confirm-dialog',
    uncloseable: true,
    confirmText: getTranslatedString('TITLE-CLONE_CHANNEL', i18n),
    hideClose: true,
    tell: [`Conversation_${conversationIdentity}`, 'cloneGroup'],
    dimensions: {
      height: 'auto',
      width: 'auto',
    },
  });
}

function showMemberLimitOverflowModalDialog(allowedCount) {
  openWidgetPopupInShell('widgets/ConfirmDialog', {
    okText: getTranslatedString('buttonText_ok', i18n),
    confirmSubText: getTranslatedString(
      'chatTab_popup_text_error_cloneGroupDialog',
      i18n,
      { count: allowedCount }
    ),
    containerClass: 'confirm-dialog clone-error',
    hideCancel: true,
    hideTitle: true,
    dimensions: {
      height: 'auto',
      width: 'auto',
    },
  });
}

function showUnableToDeleteModalDialog(
  confirmSubText = 'editor_not_connected_to_internet_error'
) {
  openWidgetPopupInShell('widgets/ConfirmDialog', {
    okText: getTranslatedString('buttonText_ok', i18n),
    okButtonClass: 'btn--action',
    hideCancel: true,
    confirmText: getTranslatedString('editor_deletion_failed', i18n),
    confirmSubText: getTranslatedString(confirmSubText, i18n),
    containerClass: 'delete-message-dialog confirm-dialog',
    dimensions: {
      height: 'auto',
      width: 'auto',
    },
  });
}

function deleteMessageModalDialog(
  conversationId,
  msg,
  peerIsGroup,
  peerIsBot,
  peerName
) {
  openWidgetPopupInShell('widgets/ConfirmDialog', {
    okText: getTranslatedString(
      'editor_messageDropdown_popup_buttonText_deleteMessage_Yes',
      i18n
    ),
    okButtonClass: 'btn--red',
    cancelText: getTranslatedString('cancel', i18n),
    confirmText: getTranslatedString(
      'editor_messageDropdown_popup_title_deleteMessage',
      i18n
    ),
    confirmSubText:
      peerIsGroup || peerIsBot
        ? getTranslatedString(
            'editor_messageDropdown_popup_text_deleteMessage',
            i18n
          )
        : getTranslatedString(
            'editor_messageDropdown_popup_text_deleteMessage_buddy',
            i18n,
            { peerName }
          ),
    containerClass: 'delete-message-dialog confirm-dialog',
    tell: [conversationId, 'deleteMessages', [[msg], peerIsGroup]],
    dimensions: {
      height: 'auto',
      width: 'auto',
    },
  });
}

function showGenericModalDialog(confirmText, confirmSubText) {
  openWidgetPopupInShell('widgets/ConfirmDialog', {
    okText: getTranslatedString('buttonText_ok', i18n),
    hideCancel: true,
    confirmSubText,
    confirmText,
    containerClass: 'confirm-dialog',
    dimensions: {
      height: 'auto',
      width: 'auto',
    },
  });
}

function showRestrictedAction() {
  showGenericModalDialog(
    getTranslatedString('OOPS', i18n),
    getTranslatedString('PERMISSION_DETAILS_MESSAGE', i18n)
  );
}

function showCantJoinGroupError() {
  showGenericModalDialog(
    getTranslatedString('OOPS', i18n),
    getTranslatedString('MEMBERSHIP_PRIVATE', i18n)
  );
}

function showRestrictedActionForLite(sessionId, context) {
  openWidgetPopupInShell('widgets/ConfirmDialog', {
    okText: getTranslatedString('buttonText_ok', i18n),
    cancelText: getTranslatedString(
      'LITE_PERMISSION_REQUEST_ACCESS_BUTTON',
      i18n
    ),
    confirmSubText: getTranslatedString(
      'LITE_PERMISSION_DETAILS_MESSAGE',
      i18n
    ),
    confirmText: getTranslatedString('LITE_PERMISSION_TITLE_MESSAGE', i18n),
    containerClass: 'confirm-dialog',
    cancelTell: [
      `Session_${sessionId}`,
      'requestAccessForLiteUsers',
      [context],
    ],
    dimensions: {
      height: 'auto',
      width: 'auto',
    },
  });
}

function showFileSizeLimitExceededError(fileUploadLimit) {
  openWidgetPopupInShell('widgets/ConfirmDialog', {
    okText: getTranslatedString('buttonText_ok', i18n),
    okButtonClass: 'btn--action',
    confirmText: getTranslatedString(
      'fileSharing_chatTab_modal_title_fileLimitExceeded',
      i18n
    ),
    confirmSubText: getTranslatedString(
      'fileSharing_chatTab_modal_message_fileLimitExceed',
      i18n,
      { fileUploadLimit }
    ),
    containerClass: 'file-upload-error-dialog confirm-dialog',
    uncloseable: false,
    hideCancel: true,
    dimensions: {
      height: 'auto',
      width: 'auto',
    },
  });
}

function showFileCountLimitExceededError(maxFileCount) {
  openWidgetPopupInShell('widgets/ConfirmDialog', {
    okText: getTranslatedString('buttonText_ok', i18n),
    okButtonClass: 'btn--action',
    confirmText: getTranslatedString(
      'fileSharing_chatTab_modal_title_fileCountExceeded',
      i18n
    ),
    confirmSubText: getTranslatedString(
      'fileSharing_chatTab_modal_message_fileCountExceedMessage',
      i18n,
      { maxFileCount }
    ),
    containerClass: 'file-upload-error-dialog confirm-dialog',
    uncloseable: false,
    hideCancel: true,
    dimensions: {
      height: 'auto',
      width: 'auto',
    },
  });
}

function showFileTransferFailError() {
  openWidgetPopupInShell('widgets/ConfirmDialog', {
    okText: getTranslatedString('buttonText_ok', i18n),
    okButtonClass: 'btn--action',
    confirmText: getTranslatedString(
      'fileSharing_chatTab_modal_fileShareFailed_title',
      i18n
    ),
    confirmSubText: getTranslatedString(
      'fileSharing_chatTab_modal_message_invalidFileType',
      i18n
    ),
    containerClass: 'file-upload-error-dialog confirm-dialog',
    list: [
      getTranslatedString(
        'fileSharing_error_check_folders_cannot_be_uploaded',
        i18n
      ),
      getTranslatedString(
        'fileSharing_error_check_connected_to_internet',
        i18n
      ),
    ],
    uncloseable: false,
    hideCancel: true,
    dimensions: {
      height: 'auto',
      width: 'auto',
    },
  });
}

function showFileSharingDisabledError() {
  openWidgetPopupInShell('widgets/ConfirmDialog', {
    okText: getTranslatedString('buttonText_ok', i18n),
    okButtonClass: 'btn--action',
    confirmText: getTranslatedString(
      'fileSharing_chatTab_modal_title_fileShareDisabledByAdmin',
      i18n
    ),
    confirmSubText: getTranslatedString(
      'fileSharing_chatTab_modal_message_fileShareDisabledByAdmin',
      i18n
    ),
    containerClass: 'file-upload-error-dialog confirm-dialog',
    list: [],
    uncloseable: false,
    hideCancel: true,
    dimensions: {
      height: 'auto',
      width: 'auto',
    },
  });
}

function showNetworkError() {
  openWidgetPopupInShell('widgets/ConfirmDialog', {
    okText: getTranslatedString('buttonText_ok', i18n),
    okButtonClass: 'btn--action',
    confirmText: getTranslatedString(
      'fileSharing_error_title_not_connected_to_internet',
      i18n
    ),
    confirmSubText: getTranslatedString(
      'fileSharing_error_not_connected_to_internet',
      i18n
    ),
    containerClass: 'file-upload-error-dialog confirm-dialog',
    list: [],
    uncloseable: false,
    hideCancel: true,
    dimensions: {
      height: 'auto',
      width: 'auto',
    },
  });
}

function showPartialFileUploadError(failedCount, totalCount) {
  openWidgetPopupInShell('widgets/ConfirmDialog', {
    okText: getTranslatedString('buttonText_ok', i18n),
    okButtonClass: 'btn--action',
    confirmText: getTranslatedString(
      'fileSharing_chatTab_modal_fileShareFailed_title',
      i18n
    ),
    confirmSubText:
      failedCount === totalCount
        ? getTranslatedString(
            'fileSharing_chatTab_modal_failureMessage_allFilesFailed',
            i18n
          )
        : getTranslatedString(
            'fileSharing_chatTab_modal_failureMessage_someFilesFailed',
            i18n,
            { numFiles: failedCount }
          ),
    containerClass: 'file-upload-error-dialog confirm-dialog',
    list: [],
    uncloseable: false,
    hideCancel: true,
    dimensions: {
      height: 'auto',
      width: 'auto',
    },
  });
}

function showCannotDownloadError(title, errorMessage, okLabel, list) {
  openWidgetPopupInShell('widgets/ConfirmDialog', {
    okText: okLabel,
    okButtonClass: 'btn--action',
    confirmText: title,
    confirmSubText: errorMessage,
    subTextItems: list,
    containerClass: 'file-download-error-dialog confirm-dialog',
    uncloseable: false,
    hideCancel: true,
    dimensions: {
      height: 'auto',
      width: 'auto',
    },
  });
}

function showAbortFileUploadDialog(peerJid, onCancelPublishEvent) {
  openWidgetPopupInShell('widgets/AbortUploadDialog', {
    containerClass: 'abort-upload-dialog confirm-dialog',
    confirmText: getTranslatedString(
      'fileSharing_chatTab_modal_title_abortTitle',
      i18n
    ),
    confirmSubText: getTranslatedString(
      'fileSharing_chatTab_modal_message_cancelFileUpload',
      i18n
    ),
    okText: getTranslatedString(
      'fileSharing_chatTab_modal_buttonText_cancelFileUpload',
      i18n
    ),
    cancelText: getTranslatedString(
      'fileSharing_chatTab_modal_buttonText_completeFileUpload',
      i18n
    ),
    okButtonClass: 'btn--red',
    publish: onCancelPublishEvent,
    publishArgs: [peerJid],
    dimensions: {
      height: 'auto',
      width: 'auto',
    },
  });
}

function showInviteGuestToConversationConfirmation(
  confirmTell = '',
  cancelTell = ''
) {
  openWidgetPopupInShell('widgets/ConfirmDialog', {
    okText: getTranslatedString('text_addNewMemberToGroup', i18n),
    cancelText: getTranslatedString('cancel', i18n),
    confirmSubText: getTranslatedString('ADD_GUEST_DESCRIPTION', i18n),
    confirmText: getTranslatedString('ADD_GUEST_TITLE', i18n),
    containerClass: 'confirm-dialog',
    tell: confirmTell,
    cancelTell,
    dimensions: {
      height: 'auto',
      width: 'auto',
    },
  });
}

function showRemoveFromConversationConfirmation(memberName, confirmTell) {
  openWidgetPopupInShell('widgets/ConfirmDialog', {
    okText: getTranslatedString('remove_member_ok_text', i18n),
    cancelText: getTranslatedString('cancel', i18n),
    okButtonClass: 'btn--red',
    confirmSubText: getTranslatedString(
      'remove_member_confirm_dialog_text',
      i18n,
      { member_name: memberName }
    ),
    confirmText: getTranslatedString('title_remove_contact', i18n),
    containerClass: 'remove-dialog confirm-dialog',
    uncloseable: true,
    tell: confirmTell,
    dimensions: {
      height: 'auto',
      width: 'auto',
    },
  });
}

function openGroupSettingsModal(peer, session) {
  const { type } = peer;
  if (type !== 'group') {
    return;
  }

  const {
    jid,
    groupType,
    chatName,
    description,
    addAffiliate,
    removeAffiliate,
    hasProfileChangePrivilege,
    hasConfigChangePrivilege,
    privileges,
  } = peer;

  const params = {
    groupJid: jid,
    groupType,
    chatName,
    description,
    addAffiliate,
    removeAffiliate,
    hasProfileChangePrivilege,
    hasConfigChangePrivilege,
    privileges,
    _session: session,
    urlPath: 'channel-edit',
    __gaType: 'channel-edit',
    webAppParams: {
      groupGuid: jid.split('@')[0],
    },
  };

  openWidgetFullScreenInShell('widgets/WebifiedIFrameContainer', params);
}

export {
  showGenericModalDialog,
  showRestrictedAction,
  showCantJoinGroupError,
  showRestrictedActionForLite,
  showAbortFileUploadDialog,
  showInviteGuestToConversationConfirmation,
  openGroupSettingsModal,
  showRemoveFromConversationConfirmation,
  showCannotDownloadError,
  deleteMessageModalDialog,
  showUnableToDeleteModalDialog,
  showMemberLimitOverflowModalDialog,
  cloneChannelConfirmModalDialog,
  showFileSizeLimitExceededError,
  showFileCountLimitExceededError,
  showFileTransferFailError,
  showFileSharingDisabledError,
  showNetworkError,
  showPartialFileUploadError,
};
