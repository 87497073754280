import React from 'react';
import getTranslatedString from '@/utils/getTranslatedString';
import i18n from '../nls';
import cssStyles from './MessageList.css';

type props = {
  retry: () => void;
};

const HistoryFetchErrorMessage = React.memo(({ retry }: props) => {
  return (
    <div className={cssStyles.history_error_warning_container}>
      <div className={cssStyles.history_error_warning_msg}>
        <span>{getTranslatedString('history_fetch_error', i18n)}</span>
        <span
          role='button'
          onKeyDown={() => {}}
          tabIndex={0}
          onClick={retry}
          className={cssStyles.retry_hook}
        >
          {getTranslatedString('retry', i18n)}
        </span>
      </div>
    </div>
  );
});

export default HistoryFetchErrorMessage;
