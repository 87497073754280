import React, { Component } from 'react';
import classNames from 'classnames';
import Tippy from '@tippyjs/react';
import Popover from '@/components/common/Popover';
import commonStyles from '@/components/common/common.css';
import PlusWidgetMenu from './PlusWidgetMenu';
import cssStyles from './plusWidget.css';

export default class PlusWidget extends Component {
  state = {
    popoverState: {
      open: false,
    },
  };

  onClick = () => {
    const {
      popoverState: { open },
    } = this.state;
    this.setState({
      popoverState: {
        open: !open,
      },
    });
  };

  onBlur = () => {
    this.setState({
      popoverState: {
        open: false,
      },
    });
  };

  render() {
    const {
      popoverState: { open },
    } = this.state;
    return (
      <div
        id='plus-widget'
        className={cssStyles.search__plus}
        data-pendo-hook='plus-button'
      >
        <Popover
          open={open}
          onClick={this.onClick}
          onBlur={this.onBlur}
          popOverContent={<PlusWidgetMenu onItemClick={this.onClick} />}
          heightDifference={-20}
        >
          <Tippy
            className={classNames(commonStyles.info_tooltip, cssStyles.tooltip)}
            content='Create a channel or invite members into your team'
            arrow
            placement='right'
          >
            <div className={cssStyles.plus_icon} />
          </Tippy>
        </Popover>
      </div>
    );
  }
}
