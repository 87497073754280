import { useState, useEffect, useCallback } from 'react';

import {
  getNotificationPreferences,
  setNotificationPreferences,
  subscribeNotificationPreferences,
  unsubscribeNotificationPreferences,
  setUserOnlineNotification,
  getUserOnlineNotification,
} from '@/utils/SessionUtils';

type Preferences = {
  audioEnabled?: boolean;
  videoRingerDisabled?: boolean;
  toastersEnabled?: boolean;
  userOnlineNotification?: boolean;
};

export default function useNotificationPreferences(sessionId: string) {
  const [preferences, setPreferences] = useState<Preferences>({});

  useEffect(() => {
    async function fetchAndUpdatePreferences() {
      const userOnlineNotification = await getUserOnlineNotification(sessionId);
      const newPreferences = await getNotificationPreferences(sessionId);

      setPreferences({ ...newPreferences, userOnlineNotification });
    }

    fetchAndUpdatePreferences();

    subscribeNotificationPreferences(sessionId, () => {
      fetchAndUpdatePreferences();
    });

    return () => {
      // TODO: This function prolly should be changed to allow multilpe sources of subscription!
      unsubscribeNotificationPreferences();
    };
  }, [sessionId]);

  const changePreferences = useCallback(
    (type: string, value: any) => {
      setNotificationPreferences(sessionId, type, value);
    },
    [sessionId]
  );

  const toggleAudioAlerts = useCallback(
    (state) => {
      setNotificationPreferences(sessionId, 'audio', state);
    },
    [sessionId]
  );

  const toggleVideoRinger = useCallback(
    (state) => {
      setNotificationPreferences(sessionId, 'videoRingerDisabled', state);
    },
    [sessionId]
  );

  const togglePopupNotifications = useCallback(
    (state) => {
      setNotificationPreferences(sessionId, 'toasters', state);
    },
    [sessionId]
  );

  const togglePeersOnlineNotifications = useCallback(
    (state) => {
      setUserOnlineNotification(sessionId, state);
    },
    [sessionId]
  );

  return {
    preferences,
    changePreferences,
    toggleAudioAlerts,
    toggleVideoRinger,
    togglePopupNotifications,
    togglePeersOnlineNotifications,
  };
}
