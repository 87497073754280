import React from 'react';
import bridge from '@/utils/bridge';
import { openModal, hideModal } from '@/components/Modal/Modal';
import { subscribe, unsubscribe } from '@/utils/KeyboardShortcuts';
import storeSubscribe from '@/subscribe';
import KeyboardShortcutsModalComponent from '@/components/Modal/KeyboardShortcutsModal/KeyboardShortcutsModalComponent';
import {
  VideoPaywallContainer,
  ChannelPaywallContainer,
  PaywallComparisonContainer,
} from '@/containers';
import EditStatusModalComponent from '@/components/Modal/EditStatusModalComponent/EditStatusModalComponent';
import GroupDiscoveryModalComponent from '@/components/Modal/GroupDiscoveryModal/GroupDiscoveryModalComponent';
import SignOutModalComponent from '@/components/Modal/SignOutModal/SignOutModalComponent';
import AboutFlockModalComponent from '@/components/Modal/AboutFlockModal/AboutFlockModalComponent';
import { publishGenericEvent } from '@/utils/SessionUtils';
import DuplicateInstanceModalComponent from '@/components/Modal/DuplicateInstanceModal/DuplicateInstanceModalComponent';
import GDPRModalComponent from '@/components/Modal/GDPRModal/GDPRModalComponent';
import ChannelAvatarEditor from '@/components/Modal/ChannelAvatarEditor';
import ForwardMessage from '@/components/Modal/ForwardMessageModal/ForwardMessage';
import ResourceLimitExceededModal from '@/components/Modal/ResourceLimitExceededModal/ResourceLimitExceededModal';
import resourceLimitExceededSelector from '@/selectors/resourceLimitExceededSelector';
import ConnectionTroubleShooterModal from '@/components/Modal/ConnectionTroubleShooterModal/ConnectionTroubleShooterModal';
import store from '@/store';

/**
 * Opens the Keyboard Shortcuts Modal
 */
export function openKeyboardShortcutsModal() {
  openModal(<KeyboardShortcutsModalComponent />, true);
}

/**
 * Opens the Paywall Comparision Modal
 */
export function openPaywallComparison() {
  openModal(
    <PaywallComparisonContainer hidePaywallComparison={hideModal} />,
    true
  );
}

/**
 * Opens the Video Paywall Modal (Soft Paywall Experiment)
 */
export function openVideoPaywall() {
  openModal(<VideoPaywallContainer hideVideoPaywall={hideModal} />, true);
}

/**
 * Opens the Channel Paywall Container
 * @param {Object} channelPaywallProps Will usually open up from a Hydra event with props that are passed to the component.
 */
export function openChannelPaywall(channelPaywallProps) {
  openModal(
    <ChannelPaywallContainer
      {...channelPaywallProps}
      hideChannelPaywall={hideModal}
    />,
    true
  );
}

/**
 * Opens the Edit Status Modal
 */
export function openEditStatusModal(session) {
  openModal(<EditStatusModalComponent session={session} />, true);
}

/**
 * Opens the Group Discovery Modal
 */
export function openGroupDiscoveryModal(gaSourceName) {
  const payload = {
    source: gaSourceName,
  };
  publishGenericEvent('join_channels_screen', payload);
  openModal(<GroupDiscoveryModalComponent />, true);
}

/**
 * Opens the Sign Out Modal
 */
export function openSignOutModal(teamsList) {
  openModal(<SignOutModalComponent teamsList={teamsList} />, true, false);
}

/**
 * Opens the About Flock Modal
 */
export function openAboutFlockModal(version) {
  openModal(<AboutFlockModalComponent version={version} />, true);
}

/**
 * Opens the Duplicate Instance Modal
 */
export function openDuplicateInstanceModal() {
  openModal(<DuplicateInstanceModalComponent />, false, true);
}

/**
 * Opens the GDPR Agreement Modal
 */
export function openGDPRModal() {
  openModal(<GDPRModalComponent />, false, true);
}

/**
 * Opens the channel avatar editor modal
 */
export function openChannelAvatarEditorModal() {
  openModal(<ChannelAvatarEditor onClose={hideModal} />, false, false);
}

/**
 * Opens the Resource Limit Exceeded modal
 */
export function openResourceLimitExceededModal() {
  openModal(
    <ResourceLimitExceededModal onClose={hideModal} />,
    false,
    true,
    false,
    true,
    true
  );
}

export function openConnectionTroubleShooterModal(close, source) {
  if (!close) {
    openModal(<ConnectionTroubleShooterModal source={source} />, false);
  }
}

/**
 * Opens the forward message modal
 */
export function openForwardMessageModal(params) {
  openModal(
    <ForwardMessage
      cancel={hideModal}
      title={params.modalTitle}
      message={params.message}
      attachment={params.attachment}
    />,
    false,
    false
  );
}

/**
 * Centralized subscriptions for all Modals.
 * Modals which are to be opened using keyboard shortcuts or hydra events can be added here.
 */
class ModalSubscriptions {
  subscriptions = [];

  constructor() {
    // subscribe to Keyboard shortcuts
    subscribe('keyboard-shortcuts', openKeyboardShortcutsModal, 'onKeyDown');

    // Other Modal Subscriptions
    this.subscriptions = [
      bridge.subscribe('odara-paywall-comparison', openPaywallComparison),
      bridge.subscribe('odara-video-paywall', openVideoPaywall),
      bridge.subscribe('odara-channel-paywall', openChannelPaywall),
      bridge.subscribe('odara-edit-status-modal', openEditStatusModal),
      bridge.subscribe('odara-group-discovery-modal', openGroupDiscoveryModal),
      bridge.subscribe('odara-signout-modal', openSignOutModal),
      bridge.subscribe('odara-about-flock-modal', openAboutFlockModal),
      bridge.subscribe(
        'odara-channel-avatar-editor-modal',
        openChannelAvatarEditorModal
      ),
      bridge.subscribe('odara-gdpr-modal', openGDPRModal),
      bridge.subscribe('odara-forward-message-modal', openForwardMessageModal),
      bridge.subscribe(
        'odara-duplicate-instance-modal',
        openDuplicateInstanceModal
      ),
      bridge.subscribe(
        'odara-keyboard-shortcuts-modal',
        openKeyboardShortcutsModal
      ),
      bridge.subscribe('/hydra/session/currentSessionUpdated', () => {
        const showResourceLimitExceededModal = resourceLimitExceededSelector(
          store.getState()
        );

        if (showResourceLimitExceededModal) {
          openResourceLimitExceededModal();
        } else {
          hideModal();
        }
      }),
      bridge.subscribe(
        'odara-connection-troubleShooter-modal',
        openConnectionTroubleShooterModal
      ),
    ];

    storeSubscribe(resourceLimitExceededSelector, (param) => {
      if (param.hasResourceLimitExceeded === true) {
        openResourceLimitExceededModal();
      }
    });
  }

  /**
   * Unsubscribes all `this.subscriptions` and keyboard subscriptions.
   * Please do not forget to add your unsubscribers.
   */
  unsubscribe() {
    bridge.unsubscribeAll(this.subscriptions);
    unsubscribe(
      'keyboard-shortcuts',
      this.openKeyboardShortcutsModal,
      'onKeyDown'
    );
  }
}

export default new ModalSubscriptions();
