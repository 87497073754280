/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import memoize from 'memoize-one';
import ContactSearch from '@/components/ConversationEditor/ContactSearch';
import { inviteContactsToConversation } from '@/utils/ConversationUtils';
import { inviteContacts } from '@/utils/SessionUtils';
import IntlWrapper from '@/Wrappers/IntlWrapper';
import { AssociatesProxy } from '@/Proxies';
import currentAssociatesConnectHOC from '@/connectHOCs/Conversations/currentConversation/associates';
import Input from './Input';
import css from './AddMember.css';
import translations from './I18N';

class AddMember extends PureComponent {
  static defaultProps = {
    peer: undefined,
    onInputVisibilityChange: () => {},
  };

  state = {
    searching: false,
    searchTerm: '',
  };

  componentDidUpdate(prevProps, prevState) {
    const { searching: prevSearching } = prevState;
    const { searching } = this.state;
    const { peer: { jid: prevJid } = {} } = prevProps;
    const { peer, peer: { jid: currentJid } = {} } = this.props;

    if (searching !== prevSearching) {
      const { onInputVisibilityChange } = this.props;
      onInputVisibilityChange(searching);
    }

    if (prevJid !== currentJid) {
      this.setState({
        searching: false,
        searchTerm: '',
      });
      this._associatesSubscriber.unsubscribe();
      this._associatesSubscriber = AssociatesProxy.subscribe(peer);
    }
  }

  componentDidMount = () => {
    const { peer } = this.props;
    this._associatesSubscriber = AssociatesProxy.subscribe(peer);
  };

  componentWillUnmount() {
    const { onInputVisibilityChange } = this.props;
    onInputVisibilityChange(false);

    this._associatesSubscriber.unsubscribe();
  }

  onMouseDown = (e) => {
    const { searching } = this.state;
    e.stopPropagation();
    e.preventDefault();

    if (!searching) {
      this.setState({
        searching: true,
      });
    }
  };

  onSearchTermChange = (term) => {
    this.setState({
      searchTerm: term,
    });
  };

  onInputBlur = () => {
    setTimeout(() => {
      this.setState({ searching: false, searchTerm: '' });
    }, 100);
  };

  onInputEscape = (e) => {
    // Prevent escape from closing conversation
    e.stopPropagation();
    this.setState({ searching: false, searchTerm: '' });
  };

  onContactSelect = (contact) => {
    setTimeout(() => {
      this.setState({ searching: true, searchTerm: '' });
    }, 100);
    if (!contact) {
      return;
    }
    const { type } = contact;
    const { peer } = this.props;

    if (type === 'temp_search' || type === 'email') {
      const { email } = contact;
      inviteContacts([email], peer.ownerGuid, true).then((params) => {
        const c = params.summary.profiles[0];
        if (c) {
          c.jid = `${c.guid}@go.to`;
          inviteContactsToConversation([c], peer);
        }
      });
      return;
    }
    inviteContactsToConversation([contact], peer);
  };

  getSearchParams = memoize((associates) => {
    const existingChannelMembersJids = [];

    associates.forEach((associate) =>
      existingChannelMembersJids.push(associate.jid)
    );
    return {
      filters: { type: ['buddy', 'email'], isDeactivated: false },
      count: 4,
      excludedJids: existingChannelMembersJids,
    };
  });

  renderSearchInput() {
    const { searchTerm } = this.state;
    const { associates } = this.props;
    const searchParams = this.getSearchParams(associates);

    return (
      <ContactSearch
        includeTempContact
        searchValue={searchTerm}
        searchParams={searchParams}
        position='bottom'
        onContactSelect={this.onContactSelect}
        focusOnRender={false}
        heightDifference={5}
        widthDifference={20}
        popoverOpen={searchTerm !== ''}
      >
        {(props) => {
          return (
            <Input
              onBlur={this.onInputBlur}
              text={searchTerm}
              onChange={this.onSearchTermChange}
              placeholder='Invite'
              onEscape={this.onInputEscape}
              {...props}
            />
          );
        }}
      </ContactSearch>
    );
  }

  render() {
    const { peer } = this.props;
    if (!peer) {
      return null;
    }
    const { hasAddMemberPrivilege, type } = peer;
    if (!hasAddMemberPrivilege || type !== 'group') {
      return null;
    }

    const { searching } = this.state;

    return (
      <div
        data-pendo-hook='header-add-member'
        className={css.Wrapper}
        onMouseDown={this.onMouseDown}
      >
        {searching ? (
          this.renderSearchInput()
        ) : (
          <span className={css.addButton}>
            + <FormattedMessage id='add_popup' />
          </span>
        )}
      </div>
    );
  }
}

export default IntlWrapper(
  currentAssociatesConnectHOC(AddMember),
  translations
);
