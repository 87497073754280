/* eslint-disable react/destructuring-assignment */
import React, { PureComponent } from 'react';
import Froala from 'froala-editor';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min';
import 'froala-editor/js/plugins/link.min';
import { KEYS } from '@/utils/constants';
import noop from '@/utils/noop';
import { sanitizeText } from '../FlockmlUtils';
import {
  getEditorData,
  isHtmlContainsMarker,
  getFormatedContent,
} from '../FroalaUtils/FroalaEditorUtils';
import { replaceTag } from '../FroalaUtils/Utils';
import {
  saveSelection,
  getHtmlContentWithMarkers,
} from '../FroalaUtils/SelectionUtils';
import {
  FroalaEditorType,
  NoopEventHandler,
  Noop,
  BooleanNoopEventHandler,
} from '../Types';
import { BASE_CONFIG, EDITOR_TYPES } from '../constants';
import { CustomClipboard } from './Plugins/Clipboard';
import { replaceIcons } from './Plugins/Icons';
import { setupUIForLink } from './Plugins/Link';
import { CustomShortcuts } from './Plugins/Shortcuts';
import { MedusaLogger } from './Plugins/MedusaLogger';
import { Tooltip } from './Plugins/Tooltip';
import { Mentions } from './Plugins/Mentions';
import './Froala.css';

const { CLASSIC, TEMPORARY_BALLOON } = EDITOR_TYPES;

type Props = {
  htmlContent: string;
  textContent: string;
  selectionStart: number;
  selectionEnd: number;
  keepFocus: boolean;
  editorType: string;
  isEditMessage: boolean;
  onEnter: BooleanNoopEventHandler;
  onUpArrow: BooleanNoopEventHandler;
  onDownArrow: BooleanNoopEventHandler;
  onSpace: BooleanNoopEventHandler;
  onTab: BooleanNoopEventHandler;
  onEscape: BooleanNoopEventHandler;
  onFocus: Noop;
  onBlur: Noop;
  onDestroy: Noop;
  getToolbarRef: () => HTMLElement | null;
  className: string;
  placeholder: string;

  onInit?: Function;
  onError?: Function;
} & typeof FroalaEditor.defaultProps;

class FroalaEditor extends PureComponent<Props> {
  static defaultProps = {
    htmlContent: '',
    textContent: '',
    selectionStart: undefined,
    selectionEnd: undefined,
    keepFocus: true,
    editorType: CLASSIC,
    isEditMessage: false,
    onEnter: (() => true) as BooleanNoopEventHandler,
    onUpArrow: (() => true) as BooleanNoopEventHandler,
    onDownArrow: (() => true) as NoopEventHandler,
    onSpace: (() => true) as NoopEventHandler,
    onTab: (() => true) as NoopEventHandler,
    onEscape: (() => true) as NoopEventHandler,
    onFocus: noop as Noop,
    onBlur: noop as Noop,
    onDestroy: noop as Noop,
    getToolbarRef: noop as () => HTMLElement | null,
    className: '',
    placeholder: '',
  };

  constructor(props) {
    super(props);
    replaceIcons(Froala);
    CustomClipboard(Froala);
    CustomShortcuts(Froala);
    MedusaLogger(Froala);
    Tooltip(Froala);
    Mentions(Froala);
  }

  domContainer: HTMLElement | null = null;

  editor: FroalaEditorType | null = null;

  tooltipContainer: HTMLElement | null = null;

  htmlContentWithMarkers: string = ''; // store html with marker whenever recreating editor so that we can restore selection

  config = {
    key:
      'Ja2A4wA1C1E1E2C4B3I4oCd2ZSb1XHi1Cb2a1KIWCWMJHXCLSwG1G1B2D2B1D7E5E1F4F4==',
    ...BASE_CONFIG,
    enter: Froala.ENTER_P,
    heightMax: this.props?.isEditMessage ? 250 : 300,
    immediateReactModelUpdate: true,
    placeholderText: sanitizeText(this.props?.placeholder || 'Message...'),
    toolbarContainer: `#toolbar${this.props?.isEditMessage ? '-edit' : ''}`, // TODO: give proper id to toolbar container if want to use editor at more than two places
    events: {
      initialized: () => {
        // setTimeout(() => {
        const { onInit } = this.props;
        this.editor.FROALA = Froala;
        if (onInit) {
          onInit(this.editor);
        }
        this.postCreate();
        this.setupEventListeners();
        this.setInitialData();
        this.setupUI();
        if (!this.editor.opts.toolbarInline) {
          this.setupToolbar();
        }
        // }, 0);
      },
    },
  };

  getConfig = () => {
    const { placeholder } = this.props;
    return {
      ...this.config,
      placeholderText: sanitizeText(placeholder),
    };
  };

  setupEditor = (editorType: string) => {
    const { isEditMessage, getToolbarRef } = this.props;
    const toolbarRef: HTMLElement = getToolbarRef();
    if (isEditMessage && editorType === TEMPORARY_BALLOON) {
      this._initializeEditor(this.domContainer, false);
      toolbarRef.parentElement.style.visibility = 'hidden';
    } else if (editorType === CLASSIC) {
      toolbarRef.parentElement.style.visibility = 'visible';
      this._initializeEditor(this.domContainer, true);
    } else {
      toolbarRef.parentElement.style.visibility = 'visible';
      this._initializeEditor(toolbarRef, false);
    }
  };

  _initializeEditor(domContainer: HTMLElement, isClassicEditor: boolean) {
    let inlineEditorConfig = {};
    if (!isClassicEditor) {
      inlineEditorConfig = {
        toolbarInline: true,
        toolbarContainer: undefined,
      };
    }

    this.setupToolbar();
    if (!domContainer.firstChild) {
      const editorContainer = document.createElement('div');
      domContainer.appendChild(editorContainer);
    }
    this.editor = new Froala(domContainer.firstChild, {
      ...this.getConfig(),
      ...inlineEditorConfig,
    });
  }

  onModelChange = (custom: boolean = false) => {
    const event = custom ? 'customContentChanged' : 'contentChanged';
    this.editor.events.trigger(event, [], false);
    this.saveSelectionRange();
    // this.setPlaceholder();
  };

  /* we save selection on blur and on conversation switch so that when we come back we can restore previous selection.
    Problem here is some browsers selection range gets detached before blur event gets fired. So actual range gets missing while saving selection.
    To handle that on every content change we store selection range in editor object. So whenever we are saving selection we have correct selection range.
  */
  saveSelectionRange = () => {
    const selectionRange: Range = this.editor.selection.ranges(0);
    this.editor.selectionRange?.detach();
    if (selectionRange.cloneRange) {
      this.editor.selectionRange = selectionRange.cloneRange();
    }
  };

  setupToolbar = () => {
    const { getToolbarRef } = this.props;
    const toolbarRef = getToolbarRef();
    const childsToBeRemoved: Element[] = [];
    // remove all other childs other than current editor's toolbar
    for (let i = 0; i < toolbarRef.children.length; i += 1) {
      const child: Element = toolbarRef.children[i];
      if (!this.editor || this.editor.$tb.get(0) !== child) {
        childsToBeRemoved.push(child);
      }
    }
    childsToBeRemoved.forEach((child) => child.remove());
  };

  setInitialData = () => {
    this.setEditorData();
  };

  setEditorData = () => {
    const { htmlContent, textContent } = this.props;
    const html = this.htmlContentWithMarkers || htmlContent;
    this.htmlContentWithMarkers = '';
    let content = '';
    if (html && textContent) {
      content = html;
    } else if (textContent) {
      content = getFormatedContent('', textContent);
      content = `<p>${content}</p>`;
    }
    // flockml will be present in edit message. froala doesn't consider flockml as wrapper tag and it will add more paragraph tags. which will create more new lines. so always provide wrapper tag to froala.
    content = replaceTag(content, 'flockml', 'p');

    this.editor.html.set(content);
    if (content && !isHtmlContainsMarker(content, this.editor)) {
      // if html doesn't have selection marker then set selection at the end
      this.editor.selection.setAtEnd(this.editor.el, true);
      // this.editor.selection.restore();
    }
    this.editor.selection.restore();
    this.focus();
    this.scrollToTheSelection();
    this.onModelChange();
    this.editor.undo.saveStep();
  };

  setupEventListeners = () => {
    this.editor.events.on(
      'keydown',
      (e) => {
        this.onModelChange(true); // don't need to tell froala to check for content update
        const stopPropagation = this.onKeyDown(e);
        if (stopPropagation) {
          return false;
        }
        return true;
      },
      true
    );

    this.editor.events.on('keyup', () => {
      this.onModelChange(); // immediate update of content
    });

    this.editor.events.on(
      'focus',
      () => {
        this.editor.selection.restore();
        const { onFocus } = this.props;
        onFocus();
      },
      true
    );

    this.editor.events.on('blur', () => {
      const { onBlur } = this.props;
      onBlur();
      saveSelection(this.editor);
    });

    this.editor.events.on('click', () => {
      setTimeout(() => {
        this.onModelChange(); // it has to be async so that it doesn't interfere with click on markers
      });
    });

    this.editor.events.on('popups.show.link.insert', () => {
      setTimeout(() => {
        setupUIForLink(this.editor);
      });
    });

    this.editor.events.on('popups.show.textColor.picker', () => {
      if (this.editor.opts.toolbarInline) {
        const popup = this.editor.popups.get('textColor.picker');
        this.makeElementFullyVisible(popup);
      }
    });

    this.editor.events.on('popups.show.link.edit', () => {
      // reposition link edit popup properly. If editor's mode is classic then editor itself will manage repositioning else we need to do it.
      const wrapper = this.editor.$wp[0];
      if (
        this.editor.opts.toolbarInline &&
        wrapper &&
        wrapper.scrollHeight > wrapper.clientHeight
      ) {
        this.setVisibilityOfElement(this.editor.popups.get('link.edit'), 20);
      }
      return true;
    });

    this.editor.events.on('toolbar.show', () => {
      if (this.editor.opts.toolbarInline && this.editor.core.isEmpty()) {
        return false;
      }
      if (this.editor.opts.toolbarInline) {
        setTimeout(() => {
          this.setVisibilityOfElement(this.editor.$tb, 10);
          this.makeElementFullyVisible(this.editor.$tb);
        });
      }
      return true;
    });

    this.editor.events.on('url.linked', (link: HTMLAnchorElement) => {
      if (link) {
        let hrefValue = link.attributes.getNamedItem('href').value;
        hrefValue = hrefValue.replace(/^\/\//g, 'https://');
        link.attributes.getNamedItem('href').value = hrefValue;
        link.target = '_blank';
      }
    });

    this.editor.events.on('contentChanged', () => {
      // hides to toolbar in inline mode if it open and selection is collapsed.
      setTimeout(() => {
        if (
          this.editor.opts.toolbarInline &&
          this.editor.selection.isCollapsed() &&
          this.editor.$tb &&
          this.editor.$tb.isVisible()
        ) {
          this.editor.toolbar.hide();
        }
      });
    });
  };

  setVisibilityOfElement = ($element, allowedDifference: number = 0) => {
    // hide the jquery element if it goes out of editor wrapper
    if ($element && this.editor.$wp) {
      const elementTop: number = $element.offset()?.top;
      const elementBottom: number = elementTop + $element.outerHeight(true);
      const wrapperTop: number = this.editor.$wp.offset().top;
      const editorHeight: number = this.editor.$wp.outerHeight();
      const differenceFromBottom =
        elementBottom - wrapperTop + allowedDifference;
      const differenceFromTop = elementTop - wrapperTop - allowedDifference;
      if (differenceFromBottom < 0 || editorHeight < differenceFromTop) {
        $element.addClass('fr-hidden');
      } else {
        $element.removeClass('fr-hidden');
      }
    }
  };

  makeElementFullyVisible = ($element) => {
    const elementTop: number = $element.offset()?.top;
    const elementHeight = $element.outerHeight(true);
    const elementBottom: number = elementTop + elementHeight;
    const { clientHeight } = document.body;
    if (!$element.hasClass('fr-hidden') && elementBottom > clientHeight) {
      const selection = this.editor.position.getBoundingRect();
      const { top } = selection || {};
      const newTop = top - elementHeight;
      if (newTop && newTop > 0) {
        $element.css({
          top: newTop,
        });
      }
    }
  };

  setupUI = () => {
    this.propagateEventsManually();
    // dummy span is used to force editor to create tooltip element.
    const dummySpan = document.createElement('span');
    dummySpan.dataset.title = 'temp';
    this.editor.tooltip.to(this.editor.$(dummySpan), true);
    this.editor.tooltip.hide();
    /* issue here is default tooltip don't have arrow in it. so if we add arrow using css pseudo element then it goes below to tooltip 
      since tooltip position is fixed by froala it overlaps with actual button. 
      To overcome this need to add extra tooltip container which will contain tooltip in it and will be positioned -10px from above 
      so actual tooltip will be positioned -10px from body which will give enough space for arrow to render without overlapping with actual content. */
    this.tooltipContainer = document.createElement('div');
    this.tooltipContainer.classList.add('fr-tooltip-container');
    this.editor.$tooltip[0].parentNode.appendChild(this.tooltipContainer);
    this.tooltipContainer.appendChild(this.editor.$tooltip[0]);
  };

  propagateEventsManually = () => {
    // this is necessary as froala stops propagation of mousedown event on toolbar.
    const { editorType, getToolbarRef } = this.props;
    const toolbar = getToolbarRef();
    if (editorType === CLASSIC) {
      this.editor.$tb[0].addEventListener(this.editor._mousedown, (e) => {
        toolbar.dispatchEvent(new MouseEvent(e.type, e));
      });
    }
  };

  postCreate = () => {
    this.focus();
    const { onFocus } = this.props;
    onFocus();
  };

  focus = () => {
    try {
      if (!this.editor) return;
      if (this.editor?.el?.focus) {
        this.editor.el.focus();
      }
      // in firefox after focusing programmatically editor gets the focus but selection is not in the editor. so make sure selection is in editor after focusing.
      if (
        this.editor?.selection?.inEditor &&
        !this.editor.selection.inEditor()
      ) {
        this.setProperSelection();
      }
    } catch {
      // fail silently.
    }
  };

  setProperSelection = () => {
    try {
      // set selection at the end
      if (this.editor.el?.lastChild?.lastChild.nodeName === 'BR') {
        this.editor.selection.setBefore(this.editor.el?.lastChild?.lastChild);
      } else {
        this.editor.selection.setAtEnd(this.editor.el, true);
      }
      this.editor.selection.restore();
    } catch {
      // fail silently
    }
  };

  scrollToTheSelection = () => {
    // Make selection start marker visible and use that to find correct scroll position
    this.editor?.selection?.save();
    try {
      const marker = this.editor.$el.find('.fr-marker[data-type="true"]')[0];
      if (marker) {
        marker.style.display = 'inline-block';
        const top =
          marker.getBoundingClientRect().top -
          this.editor.el.getBoundingClientRect().top -
          20;
        this.editor.$wp.scrollTop(top);
      }
    } catch {
      // fail silently without breaking the flow
    }
    this.editor?.selection?.restore();
    // this.editor.keys.positionCaret();
  };
  // -------- componentDidUpdate to make react state consistent with froala editor state ---------------------------

  /**
   * Makes the React component's state consistent with the froala editor state.
   * This was earlier being done inside shouldComponentUpdate which prevents from making this a PureComponent.
   * (shouldComponentUpdate is not the right place to make these changes, componentDidUpdate is however the right method for this.)
   * Making FroalaEditor a PureComponent helps us reduce renders and improves performance.
   * @param prevProps
   */

  componentDidUpdate(prevProps: Props) {
    const { editorType, placeholder, keepFocus: newKeepFocus } = this.props;
    const { keepFocus: prevKeepFocus } = prevProps;
    if (this.editor && this._shouldUpdateContent(prevProps)) {
      this.setEditorData();
    }
    if (this.editor && newKeepFocus !== prevKeepFocus && newKeepFocus) {
      this.focus();
    }
    if (this.editor && this._hasPlaceholderChanged(prevProps)) {
      this.setPlaceholder(placeholder);
    }
    if (prevProps.editorType !== editorType) {
      this.recreateEditor();
    }
  }

  _shouldUpdateContent = (prevProps: Props) => {
    const { htmlContent } = prevProps;
    const {
      htmlContent: newHtmlContent,
      textContent,
      selectionStart,
      selectionEnd,
    } = this.props;

    if (htmlContent === newHtmlContent) {
      return false;
    }

    // We should not change data if the editor's content is equal to the `#data` property.
    const {
      htmlContent: editorHtmlContent,
      textContent: editorTextContent,
      selectionStart: editorSelectionStart,
      selectionEnd: editorSelectionEnd,
    } = getEditorData(this.editor);
    if (
      editorHtmlContent === newHtmlContent &&
      textContent === editorTextContent &&
      selectionStart === editorSelectionStart &&
      selectionEnd === editorSelectionEnd
    ) {
      return false;
    }

    return true;
  };

  _hasPlaceholderChanged = (prevProps: Props) => {
    const { placeholder: newPlaceholder } = this.props;
    const { placeholder: prevPlaceholder } = prevProps;
    const domPlaceholder = this.editor.opts.placeholderText;
    return (
      newPlaceholder !== prevPlaceholder ||
      sanitizeText(domPlaceholder) !== sanitizeText(newPlaceholder)
    );
  };

  setPlaceholder = (newPlaceholder?: string) => {
    const { placeholder } = this.props;
    const _placeholder = newPlaceholder || placeholder;
    this.editor.opts.placeholderText = sanitizeText(_placeholder);
    this.editor.placeholder?.refresh();
  };

  recreateEditor = () => {
    this.htmlContentWithMarkers = getHtmlContentWithMarkers(this.editor);
    this._destroyEditor(() => {
      const { editorType } = this.props;
      this.setupEditor(editorType);
    });
  };

  onKeyDown = (domEvent): boolean => {
    const {
      onEnter,
      onUpArrow,
      onDownArrow,
      onSpace,
      onTab,
      onEscape,
    } = this.props;
    const { keyCode } = domEvent;
    const DELETE_KEY = this.editor.FROALA.KEYCODE.DELETE;
    let stopPropagation = false;
    switch (keyCode) {
      case KEYS.ENTER:
        if (!onEnter(domEvent)) {
          stopPropagation = true; // don't let froala handle event
          domEvent.preventDefault();
          domEvent.stopPropagation();
        }
        break;
      case KEYS.UP_ARROW:
        if (!onUpArrow(domEvent)) {
          stopPropagation = true;
          domEvent.preventDefault();
        }
        break;
      case KEYS.DOWN_ARROW:
        stopPropagation = !onDownArrow(domEvent);
        break;
      case KEYS.SPACE:
        stopPropagation = !onSpace(domEvent);
        break;
      case KEYS.TAB:
        domEvent.preventDefault();
        stopPropagation = !onTab(domEvent);
        break;
      case KEYS.ESCAPE:
        domEvent.preventDefault();
        stopPropagation = !onEscape(domEvent);
        break;
      case KEYS.BACKSPACE:
        this.handleBackSpace(domEvent);
        break;
      case DELETE_KEY:
        this.handleBackSpace(domEvent);
        break;
      default:
    }
    return stopPropagation;
  };

  // don't let browser handler event if selection is at start and collapsed.
  handleBackSpace = (event: KeyboardEvent) => {
    const { selectionStart, selectionEnd } = this.props;
    if (selectionStart === selectionEnd && selectionStart === 0) {
      event.preventDefault();
    }
  };

  // Destroy the editor before unmouting the component.
  componentWillUnmount() {
    this._destroyEditor();
  }

  cleanup = () => {
    if (this.tooltipContainer) {
      this.tooltipContainer.remove();
    }
  };

  _destroyEditor(cb?: Function) {
    const { onDestroy } = this.props;
    onDestroy?.();
    if (this.editor) {
      this.editor.destroy();
      this.cleanup();
      this.editor = null;
      // setTimeout(() => {
      if (cb) {
        cb();
      }
      // }, 0);
    } else {
      cb?.();
    }
  }

  render() {
    const { editorType, className, isEditMessage } = this.props;
    const display: string =
      isEditMessage || editorType === CLASSIC ? 'block' : 'none';
    return (
      <div
        ref={(n) => {
          this.domContainer = n;
        }}
        className={className}
        style={{ display }}
      />
    );
  }
}

export default FroalaEditor;
