import UAParser from "ua-parser-js";

export function escapeRegex(str) {
  return str.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

export function getEnvVendor() {
  const os = new UAParser().getOS();
  const isMac = os.name === "Mac OS";
  const isIOS = os.name === "iOS";
  if (isMac || isIOS) {
    return "apple";
  } else {
    return "google";
  }
}

export function getUrlRegex() {
  return new RegExp("(?:$|\\b)(?:[a-z]+:\\/\\/|www\\.)(?:[^\\s]+)", "gi");
}

export async function preloadImage(src) {
  const img = document.createElement("img");
  return new Promise((reject, resolve) => {
    img.onload = () => resolve();
    img.onerror = (err) => reject(err);
    img.src = src;
  });
}

export function noop() {}
