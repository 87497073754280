import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Icon } from '@talk-to/flock-components';

import { openAdminPanel } from '@/utils/ShellUtils';
import { logMedusaGenericEvent } from '@/utils/logAnalytics';

import cssStyles from './MenuDropdown.css';

class ManageTeam extends React.PureComponent {
  render() {
    return (
      <div
        role='menuitem'
        tabIndex='-1'
        onKeyDown={() => {}}
        className={cssStyles.dropdown_list_item}
        onClick={() => {
          openAdminPanel();
          logMedusaGenericEvent('settings_team_administration_clicked');
        }}
        data-pendo-hook='menu-manage-team'
      >
        <div
          className={classNames(
            cssStyles.menu_item_info,
            cssStyles['menu-item-external']
          )}
        >
          <FormattedMessage id='manage_team' />
          <Icon
            className={cssStyles['external-icon']}
            name='external'
            size='1em'
          />
        </div>
      </div>
    );
  }
}

export default ManageTeam;
