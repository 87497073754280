import store from '@/store';
import supportedLanguages from '@/utils/supportedLanguages';

class LanguageProxy {
  getLanguageCode() {
    const state = store.getState();
    const language = state.app.preferredLanguage;

    return supportedLanguages[language].code;
  }
}

export default new LanguageProxy();
