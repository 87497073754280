import React, { Component } from 'react';
import Button from '@/components/common/Button';
import NotifyAdminCTA from '@/components/common/NotifyAdminCTA';
import { publishGenericEvent } from '@/utils/SessionUtils';
import css from './TrialExpiredNonAdmin.css';

class TrialExpiredNonAdmin extends Component {
  state = {
    ctaType: 'primary',
    ctaDisabled: false,
  };

  onAdminNotify = () => {
    this.setState({
      ctaType: 'secondary',
      ctaDisabled: true,
    });
  };

  onContactUsClick = () => {
    publishGenericEvent('contact_support_by_email', {
      source: 'hard_paywall_modal',
    });
  };

  render() {
    const { ctaType, ctaDisabled } = this.state;

    return (
      <React.Fragment>
        <div className={css.Wrapper}>
          <div className={css.title}>Trial Expired!</div>
          <div className={css.message}>
            <div>Your team’s free trial has ended. </div>
            <div>
              In order to continue using Flock, please request your team admin
              to buy Flock.
            </div>
          </div>
          <div className={css.cta}>
            <Button type={ctaType} disabled={ctaDisabled}>
              <NotifyAdminCTA
                onAdminNotify={this.onAdminNotify}
                context='HARD_PAYWALL_MODAL'
              />
            </Button>
          </div>
        </div>
        <div className={css.supportLink}>
          Get back to Flocking - Contact us at &nbsp;
          <a href='mailto:help@flock.com' onClick={this.onContactUsClick}>
            help@flock.com
          </a>
        </div>
      </React.Fragment>
    );
  }
}

export default TrialExpiredNonAdmin;
