import { replaceNewLineByBr } from '../FlockmlUtils';

// resource: https://www.oreilly.com/library/view/regular-expressions-cookbook/9780596802837/ch08s02.html
export const replaceTag = (
  str: string,
  tagToBeReplaced: string,
  newTag: string,
  ignoreAttributes?: boolean
): string => {
  const regexString: string = `<(/?)${tagToBeReplaced}\\b((?:[^>"']|"[^"]*"|'[^']*')*)>`;
  const regex: RegExp = new RegExp(regexString, 'g');
  if (ignoreAttributes) {
    return str.replace(regex, `<$1${newTag}>`);
  }
  return str.replace(regex, `<$1${newTag}$2>`);
};

export const clearInvisibleSpaces = (text: string) => {
  return text.replace(/\u200B/g, '');
};

export const textToHtmlConverter = (text: string): string => {
  const span = document.createElement('span');
  span.textContent = text;
  return replaceNewLineByBr(span.innerHTML);
};

export const removeUnnecessaryBr = (html: string): string => {
  let _html = html;
  _html = _html.replace(/<\/br[^>]*>/g, '');
  return _html.replace(/^<\/?br[^>]*>/g, '');
};

export const replaceSpecialEntities = (href: string): string => {
  return href.replace(/<|>/g, ''); // needed for vaid XML
};

export const isHtmlEmpty = (html: string): boolean => {
  if (!html) return true;
  const div = document.createElement('div');
  div.innerHTML = html;
  return !div.innerText;
};
