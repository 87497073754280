import { batch } from 'react-redux';
/**
 * @params: oldIds: array of ids
         newIds: array if ids

@return value:
    a delta object of ids with a value either -1,0 or 1
    Value representation:
    1: id present in newIds but not in oldIds
    -1: id not present in newIds, but present in oldIds
    0: id present both in newIds and oldIds 

Example: 
let oldsids = [1,2,3,4];
let newIds = [3,4,5,6];

delta = getDelta(oldIds, newIds);
//delta will be
    { 1: -1, 2: -1, 3: 0, 4: 0, 5: 1, 6: 1 }
 */

function getDelta(oldIds, newIds) {
  const diff = {};
  oldIds.forEach((id) => {
    diff[id] = -1;
  });
  newIds.forEach((id) => {
    if (diff[id] === undefined) {
      diff[id] = 0;
    }
    if (diff[id] !== 1) {
      diff[id] += 1;
    }
  });
  return diff;
}

/*
@params:
        * subscribedIds, an object with ids as key and it's value as number of subscriptions
        * delta, delta object returned from getDelta function.

@return value:
        an object with 
        {
            addedIds, //array of ids needs to be added
            removedIds, //array of ids need to be removed
            subscribedIds, //modified subscribedIds object
            didChange, // it's essentially (addedIds.length > 0 || removedIds.length > 0)
        }

Example: 
_subscribedIds = {1: 1, 2:1, 3:1, 4:1};
delta = { 1: -1, 2: -1, 3: 0, 4: 0, 5: 1, 6: 1 }

const { addedIds, removedIds, subscribedIds, didChange } = getSubscriptionupdates(_subscribedIds, delta);
Returned values:
    addedIds = [5,6];
    removedIds = [1,2];
    subscribedIds = {1: 0, 2:0, 3:1, 4:1, 5:1, 6:1};
    didChange = true
*/
/**
 *
 * @param {an object with ids as key and it's value as number of subscriptions} _subscribedIds
 * @param {delta object returned from getDelta function.} delta
 *
 * @returns
 */
function getSubscriptionUpdates(subscribedIds, delta) {
  const diffKeys = Object.keys(delta);
  // const subscribedIds = { ..._subscribedIds };
  const addedIds = [];
  const removedIds = [];
  let didChange = false;

  for (let i = 0; i < diffKeys.length; i += 1) {
    const id = diffKeys[i];
    const alreadySubscribed = !!subscribedIds[id];
    if (!subscribedIds[id]) {
      subscribedIds[id] = 0;
    }
    subscribedIds[id] += delta[id];
    if (subscribedIds[id] > 0 && !alreadySubscribed) {
      addedIds.push(id);
      didChange = true;
    }
    if (subscribedIds[id] < 1) {
      removedIds.push(id);
      delete subscribedIds[id];
      didChange = true;
    }
  }

  return {
    addedIds,
    removedIds,
    subscribedIds,
    didChange,
  };
}

/**
 * @returns {iterator}  Iterator to infinity
 */
function* getCounter() {
  let count = 1;
  while (true) {
    yield count;
    count += 1;
  }
}

/** 
  * @params 
      * store dispatch fn, 
      * label, label for logging and debugging purpose
      * batchCount, count of tasks in one batch. One batch gets executed at a time.
      * limit, Number of tasks in queue when allowed to run normally, when the limit crosses, 
              tasks are executed on requestIdleCallback. 

  * @returns {function} custom store dispatch which queues dispatches
                        actions in requestIdleCallback when queue length
                        crosses certain limit. 

  */

function getQueuedDispatch(
  dispatch,
  label = 'Unnamed task',
  batchCount = 100,
  limit = 10
) {
  const TaskQueue = [];

  const getMarker = () => {
    let count = 0;
    let rate = count;
    let startTime;

    return {
      getRate: () => rate,
      setRate: () => {
        if (!startTime) {
          startTime = performance.now();
        }
        const currentTime = performance.now();
        if (currentTime - startTime > 1000) {
          rate = count;
          count = 0;
          startTime = performance.now();
        } else {
          count += 1;
        }
      },
    };
  };
  const calledMarker = getMarker();
  const performedMarker = getMarker();

  const flushQueue = () => {
    if (!TaskQueue.length) {
      return;
    }
    calledMarker.setRate();
    const work = () => {
      if (!TaskQueue.length) {
        return;
      }
      const len = TaskQueue.length;

      const startTime = performance.now();
      batch(() => {
        let count = 0;
        while (TaskQueue.length > 0 && count < batchCount) {
          dispatch(TaskQueue.shift());
          performedMarker.setRate();
          count += 1;
        }
      });

      const timeTaken = performance.now() - startTime;

      const executedTasks = len > batchCount ? batchCount : len;

      console.debug(`${label} :: Flushing task queue:
                              calling Rate ~= ${calledMarker.getRate()} per second
                              executing rate ~= ${performedMarker.getRate()} per second
                              Tasks executed: ${executedTasks}
                              Time taken:     ${timeTaken}
                              Tasks remaining: ${
                                TaskQueue.length
                              } at ${performance.now()}`);

      if (TaskQueue.length > 0) {
        flushQueue();
      }
    };

    if (TaskQueue.length > limit) {
      // Safari doesn't support requestIdleCallback yet.
      if ('requestIdleCallback' in window) {
        window.requestIdleCallback(work);
      } else {
        work();
      }
    } else {
      work();
    }
  };

  return (action) => {
    TaskQueue.push(action);
    window.requestAnimationFrame(flushQueue);
    // store.dispatch(action);
  };
}

export { getDelta, getSubscriptionUpdates, getCounter, getQueuedDispatch };
