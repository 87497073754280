import React from 'react';
import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import InfoMessageGroup from '../InfoMessageGroup';
import cssStyles from './MessageList.css';
import MessageSender from './Sender';
import Message from '../Message';

function shouldGroupTogether(
  msg1: Message | undefined,
  msg2: Message | undefined
) {
  if (!msg1 || !msg2) {
    return false;
  }

  const {
    sender: { jid: jid1 },
    sentAsApp: sentAsApp1,
    visibleTo: visibleTo1,
    isInfoMessage: isInfoMessage2,
  } = msg1;
  const {
    sender: { jid: jid2 },
    sentAsApp: sentAsApp2,
    visibleTo: visibleTo2,
    isInfoMessage: isInfoMessage1,
  } = msg2;

  if (isInfoMessage1 || isInfoMessage2) {
    return false;
  }

  // Do not group messages with restricted visibleTo.
  if (visibleTo1 || visibleTo2) {
    return false;
  }

  const { name: name1, profileImage: profileImage1 } = sentAsApp1 || {};
  const { name: name2, profileImage: profileImage2 } = sentAsApp2 || {};

  if (name1 || name2 || profileImage1 || profileImage2) {
    return isEqual(sentAsApp1, sentAsApp2);
  }
  return jid1 === jid2;
}

type Props = {
  msgs: Message[];
  currentSession: Session;
  currentPeer: Contact;
  ownerGuid: string;
  receiptObj: anyObject;
  isLastDateGroup: boolean;
  onMessageIntersection: () => void;
};

export default function SameDateMessages({
  msgs,
  currentSession,
  currentPeer,
  ownerGuid,
  receiptObj,
  isLastDateGroup,
  onMessageIntersection,
}: Props) {
  const renderResponse = [];
  let prevMessage = null;
  let infoMessagesBuffer = [];
  let firstSenderDone = false;
  msgs.forEach((message, index, allItems) => {
    const isLastMessage = isLastDateGroup && index === allItems.length - 1;
    const { isInfoMessage } = message;
    if (!isInfoMessage) {
      if (infoMessagesBuffer.length) {
        renderResponse.push(
          <InfoMessageGroup
            // msgs={infoMessagesBuffer}
            // key={infoMessagesBuffer[0].message_cache_id}
            // firstInDate={!firstSenderDone}
            // onMessageIntersection={onMessageIntersection}
            // TS needs to be able to infer InfoMessageGroup's excessive props.
            {...{
              msgs: infoMessagesBuffer,
              key: infoMessagesBuffer[0].message_cache_id,
              firstInDate: !firstSenderDone,
              onMessageIntersection,
            }}
          />
        );
        infoMessagesBuffer = [];
      }
      if (!shouldGroupTogether(message, prevMessage)) {
        renderResponse.push(
          <div
            className={cn(cssStyles.senderGroupRoot, {
              [cssStyles.firstInDate]: index === 0,
            })}
            key={`sender_${message.message_cache_id}`}
          >
            <MessageSender
              message={message}
              ownerGuid={ownerGuid}
              isBuddyChat={currentPeer.isBuddy}
            />
          </div>
        );
        firstSenderDone = true;
      }
      renderResponse.push(
        <Message
          message={message}
          currentSession={currentSession}
          currentSessionId={currentSession.id}
          currentPeer={currentPeer}
          ownerGuid={ownerGuid}
          receiptObj={receiptObj}
          isLastMessage={isLastMessage}
          key={message.message_cache_id}
          onMessageIntersection={onMessageIntersection}
        />
      );
    } else {
      infoMessagesBuffer.push(message);
    }
    prevMessage = message;
  });
  if (infoMessagesBuffer.length) {
    renderResponse.push(
      <InfoMessageGroup
        // msgs={infoMessagesBuffer}
        // key={infoMessagesBuffer[0].message_cache_id}
        // firstInDate={!firstSenderDone}
        // onMessageIntersection={onMessageIntersection}
        // TS needs to be able to infer InfoMessageGroup's excessive props.
        {...{
          msgs: infoMessagesBuffer,
          key: infoMessagesBuffer[0].message_cache_id,
          firstInDate: !firstSenderDone,
          onMessageIntersection,
        }}
      />
    );
  }
  return <>{renderResponse}</>;
}
