const constants = {
  TEAM_MEMBER: {
    none: NaN,
    admin: 2,
    member: 1,
  },
  GROUP_AFFILIATE: {
    MODERATOR: 'moderator',
    none: NaN,
    moderator: 2,
    member: 1,
  },
};

function affiliationScore(affiliation) {
  return constants.GROUP_AFFILIATE[affiliation];
}

function teamRoleScore(teamRole) {
  return constants.TEAM_MEMBER[teamRole];
}

function checkChannelLevelPrivilege(deletePostType, group, teamRole) {
  const channelPrivilege = group.privileges[deletePostType];
  return (
    teamRole === 'admin' ||
    affiliationScore(group.affiliation) >= affiliationScore(channelPrivilege)
  );
}

function checkTeamLevelPrivilege(config, deletePostType, group, teamRole) {
  const configObj = config[group.groupType];

  const teamMemberPrivilege = configObj.privileges.teamMember[deletePostType];
  const groupAffiliatePrivilege =
    configObj.privileges.groupAffiliate[deletePostType];
  const overridePrivileges = configObj.overridePrivileges.includes(
    deletePostType
  );
  if (teamRoleScore(teamRole) >= teamRoleScore(teamMemberPrivilege)) {
    return true;
  }

  if (
    affiliationScore(group.affiliation) >=
    affiliationScore(groupAffiliatePrivilege)
  ) {
    if (!overridePrivileges) {
      return checkChannelLevelPrivilege(deletePostType, group, teamRole);
    }
    return true;
  }

  return false;
}

function checkPrivilegeToDelete(msg, currentPeer, isAdmin, config) {
  const teamRole = isAdmin ? 'admin' : 'member';
  const deletePostType = msg.sentByOwner
    ? 'deleteSelfPost'
    : 'deleteOthersPost';

  if (!config || !config[currentPeer.groupType]) {
    return checkChannelLevelPrivilege(deletePostType, currentPeer, teamRole);
  }
  return checkTeamLevelPrivilege(config, deletePostType, currentPeer, teamRole);
}

export { constants, checkPrivilegeToDelete };
