import union from 'lodash/union';
import difference from 'lodash/difference';

const defaultState = {};

export default (ActionTypes) => {
  return (state = defaultState, action) => {
    switch (action.type) {
      case ActionTypes.PINS_ORDER_CHANGED: {
        const { order, ownerGuid } = action.payload;
        return {
          ...state,
          [ownerGuid]: order,
        };
      }

      case ActionTypes.ADD_PINNED_CHATS: {
        const { ownerGuid, conversations } = action.payload;
        let pinsOrder = state[ownerGuid];

        // When there are new pins (from server or local), add them to the top.
        if (difference(conversations, state[ownerGuid]).length !== 0) {
          pinsOrder = union(conversations, pinsOrder);
        }
        return {
          ...state,
          [ownerGuid]: pinsOrder,
        };
      }

      case ActionTypes.REMOVE_PINNED_CHATS: {
        const { ownerGuid, conversations } = action.payload;

        const pinsOrder = state[ownerGuid].filter(
          (jid) => conversations.indexOf(jid) < 0
        );
        return {
          ...state,
          [ownerGuid]: pinsOrder,
        };
      }
      default:
        return state;
    }
  };
};
