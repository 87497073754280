/* eslint-disable import/prefer-default-export */
const descriptions = {
  Network: {
    running: 'Checking machine’s network access...',
    success: 'Network accessibility on your machine',
    failed: 'Network accessibility on your machine failed',
  },
  Internet: {
    running: 'Checking internet connection...',
    success: 'Internet connection ',
    failed: 'Internet connection failed',
  },
  Proteus: {
    running: 'Checking if http server is reachable or not...',
    success: "Flock's server accessibility",
    failed: "Flock's server accessibility",
  },
  Websocket: {
    running: 'Checking if websocket server is reachable or not...',
    success: 'Websocket connection',
    failed: 'Websocket connection error',
  },
  FileServer: {
    running: 'Checking if file sharing server is reachable or not...',
    success: 'File sharing server accessibility',
    failed: 'File sharing server accessibility',
  },
  Door: {
    running: 'Checking other connections...',
    success: 'Other connection',
    failed: 'Other connection error',
  },
};

const getDescription = (name: string, type: string) => {
  const des = descriptions?.[name]?.[type];
  return des || '';
};

export { getDescription };
