import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector, createSelector } from 'reselect';
import { ownerGuidSelector, ownerObjSelector } from '@/selectors';
import ContactProxy from '@/Proxies/ContactProxy';

const ownerJidSelector = createSelector(
  ownerGuidSelector,
  (guid) => `${guid}@go.to`
);

const mapStateToProps = createStructuredSelector({
  ownerJid: ownerJidSelector,
  owner: ownerObjSelector,
  ownerGuid: ownerGuidSelector,
});

function subscriber(Child) {
  class OwnerSub extends PureComponent {
    constructor(props) {
      super(props);

      const { ownerJid, ownerGuid } = props;
      this.subscription = ContactProxy.subscribe(ownerGuid, [ownerJid]);
    }

    componentDidUpdate() {
      const { ownerJid, ownerGuid } = this.props;
      this.subscription.update(ownerGuid, [ownerJid]);
    }

    componentWillUnmount() {
      this.subscription.unsubscribe();
    }

    render() {
      const { owner } = this.props;

      return <Child {...this.props} owner={owner} />;
    }
  }

  return connect(mapStateToProps)(OwnerSub);
}

export default subscriber;
