import React from 'react';
import classNames from 'classnames';
import offline from '@/assets/no-internet-state.svg';
import FlockButton from '@/components/common/Button';
import styles from './Offline.css';

export default function Offline({ onRetryClick, show }) {
  return (
    <div
      className={classNames(styles.offline, {
        [styles.show]: show,
      })}
    >
      <img src={offline} alt='No Internet' />
      <div className={styles.emptyStateText}>
        You are not connected to the Internet
      </div>
      <FlockButton onClick={onRetryClick}>Retry</FlockButton>
    </div>
  );
}
