import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Actions } from '@/actions/app';
import { emojiStore } from '../storage';
import converter from './converter';

const RECENT_EMOJI_DISPLAY_COUNT = 50;
const RECENT_EMOJI_MAX_COUNT = 100;
const emojiCache = {};

export default function withRecentEmojis(Component) {
  function WithRecentEmojis(props) {
    const dispatch = useDispatch();

    const recents = useSelector((state) => state.app.emoji.recents);

    const recentEmojis = useMemo(
      () =>
        recents.slice(0, RECENT_EMOJI_DISPLAY_COUNT).map(({ native }) => {
          if (native in emojiCache) {
            return emojiCache[native];
          }

          emojiCache[native] = converter.getEmojiByNative(native);
          return emojiCache[native];
        }),
      [recents]
    );

    const addToRecents = useCallback(
      (emoji) => {
        const match = recents.find((item) => item.native === emoji.native);

        if (match) {
          match.count += 1;
        } else {
          recents.unshift({ native: emoji.native, count: 1 });
        }

        const newRecents = recents
          .slice(0, RECENT_EMOJI_MAX_COUNT)
          .sort(
            ({ count: prevCount }, { count: nextCount }) =>
              nextCount - prevCount
          );

        dispatch(Actions.set_recent_emojis(newRecents));
        emojiStore.set('recents', newRecents);
      },
      [dispatch, recents]
    );

    return (
      <Component
        recents={recentEmojis}
        addToRecents={addToRecents}
        {...props}
      />
    );
  }

  return WithRecentEmojis;
}
