import React, { Component } from 'react';
import TextMessageDecorator from '@/components/Conversation/Message/TextMessageDecorator';
import css from './Description.css';

class Description extends Component {
  static defaultProps = {
    peer: undefined,
  };

  render() {
    const { peer } = this.props;
    if (!peer) {
      return null;
    }

    const text = peer.type === 'buddy' ? peer.userStatus : peer.description;
    if (!text || peer.isBot) {
      return null;
    }
    return (
      <span className={css.Wrapper}>
        <span className={css.dot_separator} />
        <span className={css.Description}>
          {TextMessageDecorator(text, undefined, undefined, [
            'bold',
            'italics',
          ])}
        </span>
      </span>
    );
  }
}

export default Description;
