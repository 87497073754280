export default {
  delete_channel_confirm_button: 'S\u00ed, salir y eliminar',
  leave_confirm_button: 'S\u00ed, salir',
  channel_delete_confirm_message:
    'Se eliminar\u00e1 este canal porque eres el \u00faltimo miembro. \u00bfEst\u00e1s seguro?',
  channel_leave_warning_message:
    'Tendr\u00e1n que invitarte a que te vuelvas a unir al canal. \u00bfEst\u00e1s seguro?',
  channel_leave_confirm_message:
    '\u00bfEst\u00e1s seguro de que quieres abandonar este canal?',
  leave_channel_title: '\u00bfSalir del canal?',
};
