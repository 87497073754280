export default {
  block_tooltip_unpin: 'Unpin',
  block_tooltip_pin_chat: 'Pin this chat',
  channels_separator_text: 'MUTED CHANNELS',
  direct_conversation_separator_text: 'OPEN CHATS AND CHANNELS',
  pinned_conversation_separator_text: 'PINNED CHATS AND CHANNELS',
  display_density_compact: 'Compact',
  display_density_thin: 'Cosy',
  display_density_expanded: 'Comfortable',
  display_density_classic: 'Classic',
  read_options_mark_as_read: 'Mark All Read',
  select_drop_down_title_display_density: 'Display Density',
  select_drop_down_title_read: 'Read',
  invite_contact_tooltip: 'Invite',
  browse_channel_tooltip: 'Join Channels',
  create_channel_tooltip: 'Create Channels',
  search_recent_tooltip: 'Your recent chats',
  active_bucket_empty_text: 'No open chats',
  active_bucket_empty_start_conversation: 'Start New',
  active_bucket_empty_open_recent: 'View Recents',
  active_bucket_recent_button: 'Recent Chats',
  all_channel_feed_tab_text: 'All Channel Feed',
  contactAdder_inviteHeader_title: 'Invite team members',
  contactAdder_inviteHeader_desc:
    'Flock works best when your team is here too. Add members by entering their email ID.',
  contactAdder_invite_error:
    'You do not have privileges to invite members to this team.',
  invite_buttonText: 'Invite',
  text_shareUrlWithTeam: 'Or share your team URL',
  text_enterEmailAddress: 'Enter an email ID',
  enter_valid_email: 'Enter a valid email address',
};
