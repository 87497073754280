import React, { Component } from 'react';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import commonStyles from '@/components/common/common.css';
import { setUserStatus } from '@/utils/SessionUtils';
import IntlWrapper from '@/Wrappers/IntlWrapper';
import { hideModal, openModal } from '@/components/Modal/Modal';
import GenericInformationModalComponent from '@/components/Modal/GenericInformationModal/GenericInformationModalComponent';
import { logMedusaGenericEvent } from '@/utils/logAnalytics';
import translations from './I18n';

import editModalStyles from './EditStatusModalComponent.css';
import modalStyles from '../Modal.css';

class EditStatusModalComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prefilledStatus: '',
      customStatus: '',
      checkCustomStatusRadio: false,
      showUpdateButton: true,
      showButtonLoadingSpinner: false,
      showUpdateActions: false,
    };

    const AVAILABLE = props.intl.formatMessage({ id: 'user_status_available' });
    const BUSY = props.intl.formatMessage({ id: 'user_status_busy' });
    const AWAY_FROM_DESK = props.intl.formatMessage({
      id: 'user_status_away_from_desk',
    });
    const WORKING_FROM_HOME = props.intl.formatMessage({
      id: 'user_status_working_from_home',
    });
    const ON_LEAVE = props.intl.formatMessage({ id: 'user_status_on_leave' });

    this.defaultStatusItems = [
      AVAILABLE,
      BUSY,
      AWAY_FROM_DESK,
      WORKING_FROM_HOME,
      ON_LEAVE,
    ];

    this.logStatusKeys = {
      [AVAILABLE]: 'available',
      [BUSY]: 'busy',
      [AWAY_FROM_DESK]: 'afd',
      [WORKING_FROM_HOME]: 'wfh',
      [ON_LEAVE]: 'leave',
    };
  }

  componentDidMount() {
    const { session } = this.props;

    if (this.defaultStatusItems.includes(session.sessionInfo.userStatus)) {
      this.setState({
        prefilledStatus: session.sessionInfo.userStatus,
      });
    } else {
      this.setState({
        customStatus: session.sessionInfo.userStatus,
        checkCustomStatusRadio: true,
        showUpdateButton: true,
      });
    }
  }

  selectCustomStatusRadio = () => {
    this.setState({
      prefilledStatus: '',
      checkCustomStatusRadio: true,
      showUpdateButton: true,
      showUpdateActions: true,
    });
  };

  selectPrefilledStatus = (status) => {
    this.setState({
      prefilledStatus: status,
      checkCustomStatusRadio: false,
      showUpdateButton: false,
      showUpdateActions: false,
    });

    this.updateUserStatus(status);

    logMedusaGenericEvent('status_updated', {
      status: this.logStatusKeys[status],
    });
  };

  setCustomStatusInState = (e) => {
    this.setState({
      customStatus: e.target.value,
    });
  };

  callUpdateUserStatus = () => {
    const { customStatus } = this.state;
    this.updateUserStatus(customStatus);
    logMedusaGenericEvent('status_updated', { status: customStatus });
  };

  enterKeyPressed = (event) => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      this.callUpdateUserStatus();
    }
  };

  showErrorModal = () => {
    const { intl } = this.props;
    openModal(
      <GenericInformationModalComponent
        title={intl.formatMessage({ id: 'oops' })}
      >
        <div className={modalStyles.modal_subtitle}>
          {intl.formatMessage({ id: 'error_occurred' })}
        </div>
        <div className={commonStyles.btn_container}>
          <button
            type='button'
            className={classNames(commonStyles.btn, commonStyles.btn__action)}
            onClick={hideModal}
          >
            {intl.formatMessage({ id: 'okay' })}
          </button>
        </div>
      </GenericInformationModalComponent>,
      true,
      false
    );
  };

  updateUserStatus = (status) => {
    const { session } = this.props;
    this.setState(
      {
        showButtonLoadingSpinner: true,
      },
      () => {
        setUserStatus(status, session.id).then((res) => {
          if (res && res.message !== 'Error') {
            this.setState(
              {
                showButtonLoadingSpinner: false,
                showUpdateButton: false,
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    showUpdateButton: true,
                  });
                }, 2000);
              }
            );
          } else {
            // hideModal();
            this.showErrorModal();
          }
        });
      }
    );
  };

  renderDefaultStatuses = () => {
    const {
      prefilledStatus,
      customStatus,
      checkCustomStatusRadio,
      showUpdateButton,
      showUpdateActions,
      showButtonLoadingSpinner,
    } = this.state;

    const { intl } = this.props;

    const updateButtonClassNames = classNames('btn btn--action', {
      [editModalStyles.button_loading]: showButtonLoadingSpinner,
    });

    return (
      <div className={editModalStyles.set_status}>
        <label htmlFor='custom'>
          <input
            type='radio'
            name='status'
            checked={checkCustomStatusRadio}
            onChange={this.selectCustomStatusRadio}
          />
          <input
            type='text'
            defaultValue={customStatus}
            placeholder='Enter a custom status'
            className={classNames(
              commonStyles.input_box,
              editModalStyles.custom_status_input
            )}
            onFocus={this.selectCustomStatusRadio}
            onInput={this.setCustomStatusInState}
            onKeyPress={this.enterKeyPressed}
          />
          {showUpdateActions && showUpdateButton && (
            <button
              type='button'
              className={updateButtonClassNames}
              onClick={this.callUpdateUserStatus}
            >
              <span> {intl.formatMessage({ id: 'update' })} </span>
            </button>
          )}
          {showUpdateActions && !showUpdateButton && (
            <span className={editModalStyles.status_updated}>
              {' '}
              {intl.formatMessage({ id: 'updated' })}{' '}
            </span>
          )}
        </label>

        {this.defaultStatusItems.map((status, index) => (
          <label htmlFor={index} key={status}>
            <input
              type='radio'
              id={index}
              name='status'
              checked={status === prefilledStatus}
              onChange={() => this.selectPrefilledStatus(status)}
              value={status}
            />
            <span>{status}</span>
          </label>
        ))}
      </div>
    );
  };

  render() {
    return (
      <div className={editModalStyles.edit_status_modal}>
        <div className={modalStyles.modal_title}>Edit Status</div>
        <div className={modalStyles.modal_subtitle} />

        {this.renderDefaultStatuses()}
      </div>
    );
  }
}

export default IntlWrapper(injectIntl(EditStatusModalComponent), translations);
