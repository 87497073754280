import React from 'react';
import Button from '@/components/common/Button';
import { openUpgradeUrl, publishGenericEvent } from '@/utils/SessionUtils';
import css from './TrialExpiredAdmin.css';

function TrialExpiredAdmin({ sessionId }) {
  const onBuyNowCLick = () => {
    openUpgradeUrl(sessionId, 'hard_paywall_modal');
  };

  const onContactUsClick = () => {
    publishGenericEvent('contact_support_by_email', {
      source: 'hard_paywall_modal',
    });
  };

  return (
    <React.Fragment>
      <div className={css.Wrapper}>
        <div className={css.title}>Trial Expired!</div>
        <div className={css.message}>
          <div>Your team’s free trial has ended.</div>
          <div>In order to continue using Flock, please make a payment.</div>
        </div>
        <div className={css.cta}>
          <Button onClick={onBuyNowCLick}>Buy Flock</Button>
        </div>
      </div>
      <div className={css.supportLink}>
        Get back to Flocking - Contact us at &nbsp;
        <a href='mailto:help@flock.com' onClick={onContactUsClick}>
          help@flock.com
        </a>
      </div>
    </React.Fragment>
  );
}

export default TrialExpiredAdmin;
