import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import store from '@/store';
import { actions as preferencesActions } from '@/actions/preferences';
import platform from '@/utils/platform';
import Theme from '@/connectHOCs/Theme';
import {
  getCurrentAppVersion,
  subscribeNotificationPreferences,
  unsubscribeNotificationPreferences,
  getUserOnlineNotification,
  getNotificationPreferences,
  getUserInfo,
  publishGenericEvent,
  setCurrentSession,
} from '@/utils/SessionUtils';
import {
  openSupportLink,
  showAddNewTeam,
  showReferSomeoneModal,
  exit,
} from '@/utils/ShellUtils';

import currentSessionHOC from '@/connectHOCs/Sessions/currentSession';
import bridge from '@/utils/bridge';
import noop from '@/utils/noop';
import { Icon } from '@talk-to/flock-components';
import { logMedusaGenericEvent } from '@/utils/logAnalytics';
import TeamInfo from './TeamInfo';
import Divider from './Divider';
import DoNotDisturb from './DoNotDisturb';
import EditStatus from './EditStatus';
import ManageApps from './ManageApps';
import ManageTeam from './ManageTeam';
import SignOut from './SignOut';
import AppMenuTeamSwitcher from '../teamSwitcher/AppMenuTeamSwitcher';

import cssStyles from './MenuDropdown.css';

class MenuDropdown extends Component {
  static propTypes = {
    currentSession: PropTypes.object.isRequired,
    currentSessionId: PropTypes.string.isRequired,
    shellLayout: PropTypes.object,
    onBlur: PropTypes.func,
    currentTheme: PropTypes.string,
    toggleTheme: PropTypes.func,
  };

  static defaultProps = {
    shellLayout: false,
    onBlur: () => {},
    currentTheme: 'light',
    toggleTheme: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      notificationPreferences: {
        userOnlineNotification: false,
      },
      appVersion: '0.0.0.0',
      showReferLink: false,
    };

    this.dropdownRef = React.createRef();
  }

  componentDidMount() {
    const { currentSessionId, currentSession } = this.props;
    this.dropdownRef.current.focus();
    platform.isDesktopClient().then((isDesktopClient) => {
      this.setState({ showQuit: isDesktopClient });
    });
    getCurrentAppVersion().then((response) => {
      this.setState({ appVersion: response });
    });
    this.getSessionValues(currentSessionId);
    this.showReferLinkInMenu(currentSession);
    subscribeNotificationPreferences(currentSessionId, (type, value) => {
      this.setState(({ notificationPreferences }) => ({
        notificationPreferences: {
          ...notificationPreferences,
          [type]: value,
        },
      }));
    });
  }

  componentWillUnmount() {
    unsubscribeNotificationPreferences();
  }

  getSessionValues(sessionId) {
    getUserOnlineNotification(sessionId).then((response) => {
      this.setState(({ notificationPreferences }) => ({
        notificationPreferences: {
          ...notificationPreferences,
          userOnlineNotification: response,
        },
      }));
    });
    getNotificationPreferences(sessionId).then((response) => {
      this.setState(({ notificationPreferences }) => ({
        notificationPreferences: {
          ...notificationPreferences,
          ...response,
        },
      }));
    });
  }

  openKeyboardShortcutsModal = () => {
    bridge.publish('odara-keyboard-shortcuts-modal');
    logMedusaGenericEvent('settings_keyboard_shortcuts_clicked');
  };

  showReferLinkInMenu = (session) => {
    const { guid } = session.sessionInfo.teamInfo;
    const token = session.sessionInfo.metaToken;
    const ONE_DAY = 24 * 60 * 60 * 1000;

    getUserInfo(token, guid).then((res) => {
      const createdOn = res.createdOn || 0;
      if (Date.now() - createdOn > 60 * ONE_DAY) {
        const payload = {
          ownerGuid: guid,
          isReferralPresent: true,
        };
        publishGenericEvent('referral_shown_in_menu', payload);
        this.setState({
          showReferLink: true,
        });
      }
    });
  };

  openAboutFlockModal = (version) => {
    bridge.publish('odara-about-flock-modal', [version]);
    logMedusaGenericEvent('settings_version_clicked');
  };

  onBlur = (event) => {
    const target = event.currentTarget;
    setTimeout(() => {
      if (target.contains(document.activeElement)) {
        return;
      }
      const { onBlur } = this.props;
      if (onBlur) {
        onBlur();
      }
    }, 0);
  };

  toggle = () => {
    const { toggleTheme } = this.props;
    toggleTheme();
  };

  openConnectionTroubleShooter = () => {
    bridge.publish('odara-connection-troubleShooter-modal', [
      false,
      'APP_MENU',
    ]);
  };

  openPreferencesModal = () => {
    store.dispatch(preferencesActions.openPreferences());
    logMedusaGenericEvent('preferences_clicked');
  };

  render() {
    const {
      notificationPreferences,
      appVersion,
      showReferLink,
      showQuit,
    } = this.state;
    const { shellLayout, currentTheme: themeName, currentSession } = this.props;

    if (!currentSession) return null;

    const currentSessionId = currentSession.id;

    const { collapsed } = shellLayout.outerLeft;
    const currentVersion =
      appVersion !== '##APP_VERSION##' ? appVersion : ': Dev Mode';
    const isDarkTheme = themeName === 'dark';
    const dropdownListClassName = classNames(cssStyles.dropdown_list, {
      [cssStyles.dropdown_list_collapsed]: collapsed,
      [cssStyles.dropdown_list_dark]: isDarkTheme,
    });
    const listItemClassName = classNames(cssStyles.dropdown_list_item);
    const currentSessionInfo = currentSession.sessionInfo;

    return (
      <div
        className={dropdownListClassName}
        ref={this.dropdownRef}
        onBlur={this.onBlur}
        tabIndex={-1}
      >
        <TeamInfo session={currentSession} shellLayout={shellLayout} />
        {collapsed && (
          <div
            role='menuitem'
            onKeyDown={() => {}}
            tabIndex='-1'
            onClick={(e) => e.stopPropagation()}
          >
            <AppMenuTeamSwitcher
              currentSessionId={currentSessionId}
              isHorizontal
              onTeamSwitch={setCurrentSession}
              isMenuTeamSwitcher
            />
          </div>
        )}
        <Divider />
        <EditStatus session={currentSession} />
        <Divider />
        <DoNotDisturb
          sessionId={currentSessionId}
          notificationPreferences={notificationPreferences}
        />
        <Divider />
        <div
          role='button'
          onClick={this.openPreferencesModal}
          onKeyDown={noop}
          tabIndex='-1'
          className={cssStyles.dropdown_list_item}
        >
          Preferences
        </div>
        <ManageApps sessionId={currentSessionId} />
        {currentSession.owner.isAdmin && (
          <>
            <Divider />
            <ManageTeam />
          </>
        )}
        <div
          role='menuitem'
          onKeyDown={() => {}}
          tabIndex='-1'
          className={listItemClassName}
          onClick={() => {
            showAddNewTeam(
              currentSession.accountEmail,
              currentSessionInfo.teamInfo.teamId
            );
            logMedusaGenericEvent('settings_add_new_team_clicked');
          }}
          data-pendo-hook='menu-add-team'
        >
          <FormattedMessage id='add_team' />
        </div>
        <Divider />
        <div
          role='menuitem'
          onKeyDown={() => {}}
          tabIndex='-1'
          className={classNames(
            listItemClassName,
            cssStyles['menu-item-external']
          )}
          onClick={() => {
            openSupportLink();
            logMedusaGenericEvent('settings_help_feedback_clicked');
          }}
        >
          <FormattedMessage id='contact_support' />
          <Icon
            className={cssStyles['external-icon']}
            name='external'
            size='1em'
          />
        </div>
        <div
          role='menuitem'
          onKeyDown={() => {}}
          tabIndex='-1'
          className={listItemClassName}
          onClick={this.openConnectionTroubleShooter}
        >
          <FormattedMessage id='connection_troubleshooter' />
        </div>
        <div
          role='menuitem'
          onKeyDown={() => {}}
          tabIndex='-1'
          className={listItemClassName}
          onClick={this.openKeyboardShortcutsModal}
        >
          <FormattedMessage id='keyboard_shortcuts' />
        </div>
        {showReferLink && (
          <div
            role='menuitem'
            onKeyDown={() => {}}
            tabIndex='-1'
            className={listItemClassName}
            onClick={() => {
              showReferSomeoneModal(currentSession);
              logMedusaGenericEvent('settings_refer_clicked');
            }}
          >
            <FormattedMessage id='refer_someone' />
          </div>
        )}
        <Divider />
        {showQuit && (
          <div
            role='menuitem'
            onKeyDown={() => {}}
            tabIndex='-1'
            className={listItemClassName}
            onClick={() => {
              exit();
              logMedusaGenericEvent('settings_quit_clicked');
            }}
          >
            <FormattedMessage id='quit_flock' />
          </div>
        )}
        <div
          role='menuitem'
          onKeyDown={() => {}}
          tabIndex='-1'
          className={listItemClassName}
          onClick={() => this.openAboutFlockModal(currentVersion)}
        >
          Version {currentVersion}
        </div>
        <SignOut />
      </div>
    );
  }
}

export default currentSessionHOC(Theme(MenuDropdown));
