import { ActionTypes, ActionNames } from '@/actions/preferences';

const defaultState = {
  open: false,
  options: null,
};

const { OPEN_PREFERENCES, CLOSE_PREFERENCES } = ActionNames;

type SearchStoreState = typeof defaultState;

export default function searchReducer(
  state = defaultState,
  action: ActionTypes
): SearchStoreState {
  if (action.type === OPEN_PREFERENCES) {
    return {
      ...state,
      open: true,
    };
  }

  if (action.type === CLOSE_PREFERENCES) {
    return {
      ...state,
      open: false,
    };
  }

  return state;
}
