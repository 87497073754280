import React, { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import bridge from '@/utils/bridge';
import { openGroupSettingsModal } from '@/utils/GenericModalUtils';
import { openAppStore } from '@/utils/SessionUtils';
import IntlWrapper from '@/Wrappers/IntlWrapper';
import { appListSelector } from '@/selectors';
import { doAppAction } from '@/utils/ShellUtils';
import noop from '@/utils/noop';
import GroupAvatar from '@/components/Conversation/Header/ContactCards/Group/Avatar';
import cssStyles from '../MessageList.css';
import translations from '../../nls';

function useApp(appList, appName) {
  const [App, setApp] = useState(false);

  useEffect(() => {
    bridge.ask('HydraServer', 'getStartupOption', [appName]).then((appId) => {
      const app = appList.find((a) => a.id === appId);
      if (app) {
        setApp(app);
      } else {
        setApp(null);
      }
    });
  }, [appList, appName]);

  return App;
}

async function _handleActionButton(app, currentSessionId, currentPeer) {
  const flockEventParams = {
    name: 'client.pressButton',
    button: 'appLauncherButton',
  };
  doAppAction(
    app.appLauncherButton.action,
    app,
    currentPeer,
    currentSessionId,
    flockEventParams,
    'header'
  );
}

type Props = {
  appList: any[];
  currentSession: Session;
  currentPeer: GroupContact;
};

function GroupChatHeader(props: Props) {
  const { appList, currentSession, currentPeer } = props;
  const currentSessionId = currentSession?.id;
  const reminderApp = useApp(appList, 'reminderAppId');
  const todoApp = useApp(appList, 'todoAppId');

  const _handleSetChannelPurposeButton = useCallback(() => {
    openGroupSettingsModal(currentPeer, currentSession);
  }, [currentPeer, currentSession]);

  const _handleReminderAction = useCallback(() => {
    _handleActionButton(reminderApp, currentSessionId, currentPeer);
  }, [reminderApp, currentSessionId, currentPeer]);

  const _handleTodoAction = useCallback(() => {
    _handleActionButton(todoApp, currentSessionId, currentPeer);
  }, [todoApp, currentSessionId, currentPeer]);

  const _handleConnectButton = useCallback(() => {
    openAppStore(currentSessionId, 'header-message-channels', '');
  }, [currentSessionId]);

  const {
    hasPostMessagePrivilege,
    hasConfigChangePrivilege,
    description,
  } = currentPeer;
  return (
    <div className={cssStyles.headerMessageDOM}>
      <div className={cssStyles.headerMessageGroupOrBot}>
        <div className={cssStyles.headerMessageHeader}>
          <div className={cssStyles.headerMessageHeaderAvatar}>
            <div className={cssStyles.headerMessageAvatarContainer}>
              <GroupAvatar
                // peer={currentPeer}
                // className={cssStyles.headerMessageAvatarContainerImage}
                // allowEdit={hasConfigChangePrivilege}
                {...{
                  peer: currentPeer,
                  className: cssStyles.headerMessageAvatarContainerImage,
                  allowEdit: hasConfigChangePrivilege,
                }}
              />
            </div>
          </div>
          <div className={cssStyles.headerMessageHeaderName}>
            {currentPeer.name}
          </div>
          {description ? (
            <div className={cssStyles.headerMessagePurpose}>
              <span>{description}</span>
              {hasConfigChangePrivilege ? (
                <span
                  className={cssStyles.headerMessageEditPurpose}
                  onClick={_handleSetChannelPurposeButton}
                  onKeyPress={noop}
                  role='button'
                  tabIndex={0}
                />
              ) : null}
            </div>
          ) : null}
          {!description && hasConfigChangePrivilege ? (
            <button
              type='button'
              className={cssStyles.headerMessageSetChannelPurpose}
              onClick={_handleSetChannelPurposeButton}
            >
              <FormattedMessage id='groupPurpose_blank_value' />
            </button>
          ) : null}
        </div>

        {hasPostMessagePrivilege && (
          <div className={cssStyles.headerMessageFooter}>
            {todoApp ? (
              <button
                className={cssStyles.defaultActionButton}
                type='button'
                onClick={_handleTodoAction}
              >
                <span
                  className={classNames(
                    cssStyles.defaultActionButtonText,
                    cssStyles.add_todo_btn
                  )}
                >
                  <FormattedMessage id='group_chat_header_add_todo' />
                </span>
              </button>
            ) : null}
            {reminderApp ? (
              <button
                className={cssStyles.defaultActionButton}
                type='button'
                onClick={_handleReminderAction}
              >
                <span
                  className={classNames(
                    cssStyles.defaultActionButtonText,
                    cssStyles.set_reminder_btn
                  )}
                >
                  <FormattedMessage id='group_chat_header_set_reminder' />
                </span>
              </button>
            ) : null}
            <button
              type='button'
              className={cssStyles.defaultActionButton}
              onClick={_handleConnectButton}
            >
              <span
                className={classNames(
                  cssStyles.defaultActionButtonText,
                  cssStyles.connect_an_app_btn
                )}
              >
                <FormattedMessage id='group_chat_header_connect_app' />
              </span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  appList: appListSelector,
});

export default connect(mapStateToProps)(
  IntlWrapper<Props>(GroupChatHeader, translations)
);
