/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import isEqual from 'lodash/isEqual';
import { getSignedDownloadUrl } from '@/utils/ShellUtils';
import { exponentialBackoff } from '@/utils';

export default class AudioPlayer extends React.Component {
  state = {
    src: '',
  };

  fetching = false;

  componentDidMount() {
    this.getUrlFromShell();
    this.initRetryFunction();
  }

  initRetryFunction() {
    this.retryTimer = exponentialBackoff(this.getUrlFromShell, 15);
  }

  componentDidUpdate(prevProps) {
    const { download } = this.props;
    if (!isEqual(prevProps.download, download)) {
      this.initRetryFunction();
      if (!this.fetching) {
        this.getUrlFromShell();
      }
    }
  }

  componentWillUnmount() {
    this.retryTimer = null;
  }

  getUrlFromShell = () => {
    const { download, currentSessionId } = this.props;
    this.fetching = true;
    getSignedDownloadUrl(download, currentSessionId).then((src) => {
      // TODO: this promise should be cancelled when component unmounts
      const { download: currentDownloadProp } = this.props;
      if (!isEqual(currentDownloadProp, download) || src instanceof Error) {
        if (this.retryTimer) {
          this.retryTimer();
        }
      } else {
        this.setState({ src });
        this.fetching = false;
      }
    });
  };

  render() {
    const { src } = this.state;
    // TODO add medusa events on interaction with the audio element
    return (
      <div className='audioMessage'>
        <audio
          className='AudioElement'
          controls={1}
          controlsList='nodownload'
          src={src}
        />
      </div>
    );
  }
}
