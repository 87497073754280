import * as React from 'react';
import { cxBind } from '@/utils/cx';
import { Avatar, Button } from '@talk-to/flock-components';
import { logMedusaGenericEvent } from '@/utils/logAnalytics';

import EditableCard from '../../shared/EditableCard/EditableCard';

import styles from './ProfileCard.css';
import EditView from './EditView';
import updateUserProfile from './updateUserProfile';
import updateProfileImage from './updateProfileImage';

const cx = cxBind(styles);

type Props = {
  guid: string;
  firstName: string;
  lastName: string;
  profile?: {
    name: string;
    imageUrl: string;
    mobile?: {
      countryCode?: string;
      mobile?: string;
    };
  };
  sessionId: string;
};

export type ProfileForm = {
  meta: {
    initialized: boolean;
  };
  firstName: string;
  lastName: string;
  countryCode: string;
  tel: string;
  imageFile: Blob | null;
};

export default function ProfileCard(props: Props) {
  const [isProfileEditing, setIsProfileEditing] = React.useState(false);
  const [isUpdating, setIsUpdating] = React.useState(false);
  const [fieldsValid, setFieldsValid] = React.useState(true);
  const formRef = React.useRef<ProfileForm | null>(null);
  const { profile, firstName, lastName, guid, sessionId } = props;

  const onSave = React.useCallback(
    (data: ProfileForm) => {
      async function update() {
        setIsUpdating(true);
        const changes = {} as Record<string, boolean>;

        try {
          const promises = [];

          if (formRef.current.imageFile) {
            changes.avatar_updated = true;
            promises.push(
              updateProfileImage(formRef.current.imageFile, sessionId, guid)
            );
          }

          if (data.firstName !== firstName) {
            changes.fname_updated = true;
          }

          if (data.lastName !== lastName) {
            changes.lname_updated = true;
          }

          if (data.tel !== profile.mobile?.mobile) {
            changes.mobile_updated = true;
          }

          promises.push(
            updateUserProfile(
              {
                firstName: data.firstName,
                lastName: data.lastName,
                mobile: data.tel ? data.countryCode + data.tel : '',
              },
              guid
            )
          );

          await Promise.all(promises);

          if (Object.keys(changes).length) {
            logMedusaGenericEvent(
              'preferences_profile_personal_details_updated',
              changes
            );
          }

          setIsProfileEditing(false);
        } catch (error) {
          console.error(error);
        } finally {
          setIsUpdating(false);
        }
      }

      update();
    },
    [sessionId, firstName, lastName, profile, guid]
  );

  if (!profile) {
    return null;
  }

  const { name, imageUrl, mobile: { countryCode, mobile } = {} } = profile;

  return (
    <div>
      <EditableCard
        editing={isProfileEditing}
        editContent={() => (
          <EditView
            profile={profile}
            firstName={firstName}
            lastName={lastName}
            name={name}
            imageUrl={imageUrl}
            onChange={(form) => {
              formRef.current = form;
            }}
            onValidation={(valid) => setFieldsValid(valid)}
          />
        )}
        onEditIconClick={() => {
          setIsProfileEditing(true);
          logMedusaGenericEvent('preferences_profile_personal_details_clicked');
        }}
      >
        <div className={cx('profile-wrapper')}>
          <Avatar fullName={name} size='small' src={imageUrl} />
          <div className={cx('profile-details')}>
            <div className={cx('fullname')}>{name}</div>
            <div className={cx('phone')}>
              {countryCode ? `${countryCode}-${mobile}` : 'Add your number'}
            </div>
          </div>
        </div>
      </EditableCard>
      {isProfileEditing && (
        <div className={cx('buttons')}>
          <Button
            variant='primary'
            wider
            onClick={() => onSave(formRef.current)}
            disabled={isUpdating || !fieldsValid}
          >
            Save
          </Button>
          <Button
            variant='secondary'
            wider
            onClick={() => setIsProfileEditing(false)}
            disabled={isUpdating}
          >
            Cancel
          </Button>
        </div>
      )}
    </div>
  );
}
