import flockmlParser from '@/utils/flockML';
import {
  getBuddyMentionHtml,
  getChannelMentionHtml,
  addMentionsToMessageObject,
} from '../FlockmlUtils';
import { isBuddyAlreadyMentioned } from '../utils';
import { GENERIC_MENTIONS, USER } from '../constants';
import { MentionObj } from '../Types';

export const attachMentions = (messageObj) => {
  const { flockml, messageElements: { mentions = [] } = {} } = messageObj;
  if (!flockml) return messageObj;

  const viewDocument = flockmlParser(flockml);
  const buddyMentions = Array.from(viewDocument.getElementsByTagName(USER));
  buddyMentions.forEach((mention) => {
    const contactObj = getContactObjFromMention(mention);
    if (
      !isBuddyAlreadyMentioned(mentions, contactObj.chatType, contactObj.jid)
    ) {
      mentions.push(createMentionObj(contactObj));
    }
  });
  addMentionsToMessageObject(messageObj, mentions);
  return messageObj;
};

const createMentionObj = (contactObj) => {
  const { chatName, chatType, jid } = contactObj || {};
  const mentionObj = {
    name: chatName,
  } as MentionObj;
  if (chatType) {
    mentionObj.type = chatType;
  }
  if (GENERIC_MENTIONS.indexOf(chatType) === -1) {
    mentionObj.userJid = jid;
  }
  return mentionObj;
};

const getContactObjFromMention = (mentionEl: Element) => {
  const chatType = mentionEl.getAttribute('data-chatType');
  const jid = mentionEl.getAttribute('data-jid');
  const chatName = mentionEl.getAttribute('data-chatName');
  return { chatType, jid, chatName };
};

export const addBuddyMentions = (
  flockml: string,
  buddyContacts: Contact[]
): string => {
  if (!buddyContacts.length) return flockml;
  const viewDocument = flockmlParser(flockml);
  buddyContacts.forEach((contact: Contact) => {
    const mentionSelector = getBuddyMentionSelector(contact);
    const mention = viewDocument.querySelector(mentionSelector);
    attachNecessaryPropsToMention(mention, contact);
  });
  return viewDocument.innerHTML;
};

export const addChannelMentions = (
  flockml: string,
  channelContacts: Contact[]
): string => {
  if (!channelContacts.length) return flockml;
  const viewDocument = flockmlParser(flockml);
  channelContacts.forEach((contact: Contact) => {
    const mentionSelector = getChannelMentionSelector(contact);
    const mention = viewDocument.querySelector(mentionSelector);
    attachNecessaryPropsToMention(mention, contact);
  });
  return viewDocument.innerHTML;
};

const getBuddyMentionSelector = (contact: Contact): string => {
  const { jid, chatType } = contact || {};
  const guid = jid && jid.split('@')[0];
  let selector = '';
  if (GENERIC_MENTIONS.includes(chatType)) {
    selector = 'user[group]';
  } else {
    selector = `user[userId="u:${guid}]"`;
  }
  return selector;
};

const getChannelMentionSelector = (contact: Contact) => {
  const { jid } = contact || {};
  const guid = jid && jid.split('@')[0];
  return `channel[channelId="g:${guid}"]`;
};

export const getTokenizedMentions = (
  contact: Contact,
  mentionType: string,
  froalaMarkers: string
): string[] => {
  let html = '';
  if (USER === mentionType) {
    html = _getBuddyMentionHtml(contact);
  } else {
    html = _getChannelMentionHtml(contact);
  }
  const htmlWithMarkers = `${html}&nbsp;${froalaMarkers}`;
  return [htmlWithMarkers];
};

const _getBuddyMentionHtml = (contact: Contact) => {
  const html = getBuddyMentionHtml(contact);
  const div = document.createElement('div');
  div.innerHTML = html;
  attachNecessaryPropsToMention(div.firstElementChild, contact);
  return div.innerHTML;
};

const _getChannelMentionHtml = (contact: Contact) => {
  const html = getChannelMentionHtml(contact);
  const div = document.createElement('div');
  div.innerHTML = html;
  attachNecessaryPropsToMention(div.firstElementChild, contact);
  return div.innerHTML;
};

const attachNecessaryPropsToMention = (mention: Element, contact: Contact) => {
  if (!mention || !contact) return;
  const { chatName, chatType, jid } = contact || {};
  mention.setAttribute('data-chatName', chatName);
  mention.setAttribute('data-jid', jid);
  mention.setAttribute('data-value', mention.textContent);
  if (chatType) {
    mention.setAttribute('data-chatType', chatType);
  }
};
