import React from 'react';
import cx from 'classnames';
import styles from './Loader.css';

export default function Loader({
  size = '1em',
  absolute = true,
  loading = false,
  overlay = false,
  opaque = false,
}) {
  if (loading) {
    const spinner = (
      <div
        className={cx([
          styles.loader,
          {
            [styles.Absolute]: absolute,
          },
        ])}
        style={{ height: size, width: size }}
      />
    );

    if (overlay) {
      return (
        <div className={cx([styles.Overlay, { [styles.Opaque]: opaque }])}>
          {spinner}
        </div>
      );
    }

    return spinner;
  }
  return null;
}
