import React, { PureComponent, Fragment } from 'react';
import memoizeOne from 'memoize-one';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import EditorPopoverStyleContext from '@/Contexts/EditorPopoverStyle';
import withCurrentSession from '@/connectHOCs/Sessions/currentSession';
import withCurrentPeer from '@/subscribeHOCs/currentPeer';
import IntlWrapper from '@/Wrappers/IntlWrapper';
import withOwner from '@/subscribeHOCs/owner';
import parse from '@/utils/flockML';
import bridge from '@/utils/bridge';
import ContactProxy from '@/Proxies/ContactProxy';
import { getContactForId } from '@/Models/Contact';
import Loader from '@/components/common/Icons/Loader';
import { shortCodeToNative } from '@/lib/emoji';
import * as editorFocusPubSub from '@/lib/pubsub/editor-focus';
import {
  decorateFlockml,
  optimizeMessageObject,
} from '@/utils/message/flockml';
import { getGenericMentionsUpdateFunctions } from '../utils';
import {
  addBuddyMentions,
  addChannelMentions,
  attachMentions,
} from '../FroalaUtils/MentionsUtils';
import { replaceBrbyNewLine } from '../FlockmlUtils';
import BaseEditor from '../BaseEditor';
import {
  convertToProperFlockml,
  logFormattingToMedusa,
  getFormatting,
  getFormatedContent,
} from '../FroalaUtils/FroalaEditorUtils';
import { CurrentSession, Owner, Peer, Noop } from '../Types';
import css from './EditMessageComposer.css';
import translations from './I18N';

type Props = {
  currentSession: CurrentSession;
  owner: Owner;
  currentPeer: Peer;
  message: Message;
  onCancelEditMessage: Noop;
  mentionedChannels: Object[];
  mentionedBuddies: Object[];
} & typeof EditMessageComposer.defaultProps;

type State = {
  htmlContent: string;
  textContent: string;
  selectionStart: number;
  selectionEnd: number;
  keepTextAreaFocused: boolean;
  messageObjectUpdates: Function[];
  showError: boolean;
  saving: boolean;
};
const ALLOWED_DECORATORS = ['CHANNEL_MENTION', 'BUDDY_MENTION', 'EMOJI'];

const getMentionedChannels = memoizeOne(function (message) {
  const flockml = parse(message.flockml);

  const channelElements: any = flockml.getElementsByTagName('channel');
  const mentionedChannels = [];
  channelElements.forEach((element) => {
    const channelJID = `${
      element.getAttribute('channelid').split(':')[1]
    }@groups.go.to`;
    mentionedChannels.push(channelJID);
  });

  return mentionedChannels;
});

const getMentionedBuddies = memoizeOne(function (message) {
  if (message && message.messageElements && message.messageElements.mentions) {
    const mentionedBuddies = message.messageElements.mentions.map(
      (mentionItem) => {
        const { type, jid } = mentionItem;
        let id = jid;
        if (type === 'all' || type === 'online') {
          id = `SEARCH_UTILITY:::${type}:::${type}`;
        }
        return id;
      }
    );
    return mentionedBuddies;
  }
  return [];
});

const FAILED_MSG_TIMEOUT_IN_SECONDS: number = 2;

class EditMessageComposer extends PureComponent<Props, State> {
  static defaultProps = {
    currentSession: undefined,
    owner: undefined,
    currentPeer: undefined,
    message: undefined,
    onCancelEditMessage: () => {},
    mentionedChannels: [],
    mentionedBuddies: [],
  };

  state: State = {
    htmlContent: '',
    textContent: '',
    selectionStart: 0,
    selectionEnd: 0,
    keepTextAreaFocused: true,
    messageObjectUpdates: [],
    showError: false,
    saving: false,
  };

  editorRef = React.createRef<HTMLDivElement>();

  ContactProxySub: any;

  failedMessageTimer: ReturnType<typeof setTimeout>;

  componentDidMount() {
    const { currentPeer, message } = this.props;
    this.computeStateFromProps();
    const mentionedChannelsIds = getMentionedChannels(message);
    const mentionedBuddiesIds = getMentionedBuddies(message);
    this.ContactProxySub = ContactProxy.subscribe(currentPeer.ownerGuid, [
      ...mentionedChannelsIds,
      ...mentionedBuddiesIds,
    ]);
  }

  componentDidUpdate(prevProps) {
    const { message: prevMessage } = prevProps;
    const { message, currentPeer } = this.props;

    if (message !== prevMessage) {
      this.computeStateFromProps();
      const mentionedChannelsIds = getMentionedChannels(message);
      const mentionedBuddiesIds = getMentionedBuddies(message);
      this.ContactProxySub.update(currentPeer.ownerGuid, [
        ...mentionedChannelsIds,
        ...mentionedBuddiesIds,
      ]);
    }
  }

  componentWillUnmount() {
    // Restore focus back to conversation editor
    editorFocusPubSub.focus();

    this.ContactProxySub.unsubscribe();
    if (this.failedMessageTimer) {
      window.clearTimeout(this.failedMessageTimer);
    }
  }

  sendMessage() {
    const { messageObjectUpdates, htmlContent } = this.state;
    let { textContent } = this.state;

    if (this.isMessageBlank()) return;

    const { message, currentPeer: peer, owner } = this.props;
    const { ownerGuid } = owner || {};

    // Replaces emoji shortcodes with native characters before
    // being sent.
    textContent = shortCodeToNative(textContent);
    const _msgObj = {
      notification: textContent.trim(),
      text: textContent.trim(),
      flockml: htmlContent,
      sentByPeer: false,
      peer,
      messageUid: message.sid,
      messageCid: message.id,
      type: 'EDIT_MESSAGE',
    };
    const genericMentions =
      peer?.type === 'group'
        ? getGenericMentionsUpdateFunctions(ownerGuid)
        : [];
    const _messageObjectUpdates = [
      attachMentions,
      ...messageObjectUpdates,
      ...genericMentions,
    ];
    const messageObject = _messageObjectUpdates.reduce(function (
      messageObj,
      updateFn
    ) {
      const msgObj = updateFn(messageObj, { peer });
      return msgObj;
    },
    _msgObj);

    // Replaces emoji shortcodes with native characters in FlockML,
    // if present.
    if (messageObject.flockml) {
      messageObject.flockml = convertToProperFlockml(
        decorateFlockml(messageObject.flockml),
        true
      );
    }
    const formattingObj = getFormatting(messageObject.flockml);
    optimizeMessageObject(_isEmpty(formattingObj), messageObject, true);
    bridge.tell('ConversationHandler', 'createEditMessage', [messageObject]);
    if (!_isEmpty(formattingObj)) {
      logFormattingToMedusa(formattingObj, true);
    }

    this.failedMessageTimer = setTimeout(() => {
      this.setState({
        saving: false,
        showError: true,
      });
    }, FAILED_MSG_TIMEOUT_IN_SECONDS * 1000);
  }

  isMessageBlank = () => {
    const { textContent } = this.state;
    return textContent.trim() === '';
  };

  onTextAreaEnter = (event: React.KeyboardEvent | React.MouseEvent) => {
    const { metaKey, ctrlKey, shiftKey, altKey } = event;

    if (this.isMessageBlank()) return false;

    if (!metaKey && !ctrlKey && !shiftKey && !altKey) {
      this.sendMessage();
      this.setState({
        saving: true,
      });
      return false;
    }
    return true;
  };

  onEscape = (event: React.KeyboardEvent) => {
    // Escape has conflict with close conversation.
    // It's probably because of react's synthetic events and browser events.
    event.stopPropagation();
    this.closeEditComposer();
  };

  onBlur = () => {
    this.setFocus(false);
  };

  onFocus = () => {
    this.setFocus(true);
  };

  setFocus = (value) => {
    const { keepTextAreaFocused } = this.state;

    if (keepTextAreaFocused === value) {
      return;
    }

    this.setState({
      keepTextAreaFocused: value,
    });
  };

  closeEditComposer() {
    const { onCancelEditMessage } = this.props;

    onCancelEditMessage();
  }

  computeStateFromProps() {
    const { message, mentionedChannels, mentionedBuddies } = this.props;
    let textContent = message.text ? message.text : '';

    if (message.flockml) {
      textContent = parse(replaceBrbyNewLine(message.flockml)).textContent;
    }

    message.flockml = addChannelMentions(message.flockml, mentionedChannels);
    message.flockml = addBuddyMentions(message.flockml, mentionedBuddies);

    // Existing emoji natives will be converted to shortcodes for editing
    // try {
    //   textContent = nativeToShortCode(textContent);
    // } catch (error) {
    //   console.warn(
    //     'emoji native to shortcode conversion failed, using text as is:',
    //     error
    //   );
    // }

    let htmlContent = message.flockml || '';
    // htmlContent = nativeToShortCode(htmlContent);
    htmlContent = getFormatedContent(htmlContent, '');

    this.setState({
      htmlContent,
      textContent,
      selectionStart: textContent.length,
      selectionEnd: textContent.length,
    });
  }

  onChange = (newState: State) => {
    this.setState(newState);
  };

  popoverStyleFn = (trigger, popover) => {
    const popoverHeight = popover.getBoundingClientRect().height;
    const bodyHeight = document.body.clientHeight;

    const elRects = this.editorRef.current.getBoundingClientRect();
    // If bottom has space, position bottom
    if (bodyHeight - elRects.bottom > popoverHeight) {
      return {
        top: `${elRects.top + elRects.height}px`,
        left: `${elRects.left}px`,
      };
    }
    // If no space in top and bottom, position top as 0
    if (elRects.top < popoverHeight) {
      // stick it on the top
      return {
        top: 0,
        left: `${elRects.left}px`,
      };
    }
    // by default show on top.
    return {
      bottom: `${bodyHeight - elRects.bottom + elRects.height}px`,
      left: `${elRects.left}px`,
    };
  };

  editorContextValue = {
    getPositionStyle: this.popoverStyleFn,
    popoverTriggerClass: css.popoverTriggerClass,
  };

  render() {
    const { owner, currentPeer: peer, onCancelEditMessage } = this.props;

    const {
      htmlContent,
      textContent,
      selectionStart,
      selectionEnd,
      messageObjectUpdates,
      showError,
      saving,
      keepTextAreaFocused,
    } = this.state;

    return (
      <div className={css.Wrapper}>
        <div ref={this.editorRef}>
          <EditorPopoverStyleContext.Provider value={this.editorContextValue}>
            <BaseEditor
              owner={owner}
              peer={peer}
              htmlContent={htmlContent}
              textContent={textContent}
              selectionStart={selectionStart}
              selectionEnd={selectionEnd}
              messageObjectUpdates={messageObjectUpdates}
              keepTextAreaFocused={keepTextAreaFocused}
              onChange={this.onChange}
              onEnter={this.onTextAreaEnter}
              onEscape={this.onEscape}
              onBlur={this.onBlur}
              onFocus={this.onFocus}
              isEditMessage
              allowedDecorators={ALLOWED_DECORATORS}
              textAreaClassName={css.TextArea}
            />
          </EditorPopoverStyleContext.Provider>
        </div>
        <div className={css.ActionButtons}>
          <div
            className={`${css.PushRight} ${showError ? css.errorVisible : ''}`}
          >
            {saving ? (
              <button type='button' className={`${css.Button} ${css.Saving}`}>
                <Loader
                  color='green'
                  width='1em'
                  height='1em'
                  style={{ paddingRight: '5px' }}
                />{' '}
                <FormattedMessage id='SAVING' />
              </button>
            ) : (
              <Fragment>
                <button
                  type='button'
                  className={`${css.Button} ${css.Cancel}`}
                  onClick={onCancelEditMessage}
                >
                  <FormattedMessage id='CANCEL' />
                </button>
                <button
                  type='button'
                  className={`${css.Button} ${css.Save}`}
                  onClick={this.onTextAreaEnter}
                  disabled={this.isMessageBlank()}
                >
                  <FormattedMessage id='generic_save' />
                </button>
              </Fragment>
            )}
          </div>
          {showError ? (
            <span className={css.ErrorText}>
              <FormattedMessage id='message_not_edited_error' />
            </span>
          ) : null}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const { currentPeer, message } = props;
  const mentionedChannelsIds = getMentionedChannels(message);
  const mentionedBuddiesIds = getMentionedBuddies(message);

  const mentionedChannels = mentionedChannelsIds.map(function (id) {
    return getContactForId(id, currentPeer.ownerGuid);
  });
  const mentionedBuddies = mentionedBuddiesIds.map(function (id) {
    return getContactForId(id, currentPeer.ownerGuid);
  });

  return {
    mentionedChannels,
    mentionedBuddies,
  };
}

export default IntlWrapper(
  withCurrentSession(
    withCurrentPeer(withOwner(connect(mapStateToProps)(EditMessageComposer)))
  ),
  translations
);
