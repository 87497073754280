import React, { PureComponent } from 'react';
import Tippy from '@tippyjs/react';
import Popover from '@/components/common/Popover';
import getTranslatedString from '@/utils/getTranslatedString';
import commonStyles from '@/components/common/common.css';
import PreferencesPopover from './PreferencesPopover';
import ActionButtonCss from '../ActionButtons.css';
import css from './NotificationPreferences.css';
import i18n from './I18N';

const NOTIFY_ICON_CLASS = {
  UNMUTE: 'Unmute',
  MUTE: 'Mute',
  PARTIAL_MUTE: 'Partial_mute',
};

const TOOLTIP_TEXT = {
  UNMUTE: getTranslatedString(
    'chatTab_tooltip_groupConversation_all_messages',
    i18n
  ),
  MUTE: getTranslatedString(
    'chatTab_tooltip_groupConversation_direct_mention',
    i18n
  ),
  PARTIAL_MUTE: getTranslatedString(
    'chatTab_tooltip_groupConversation_mentions',
    i18n
  ),
};

class NotificationPreferences extends PureComponent {
  static defaultProps = {
    peer: undefined,
  };

  state = {
    showPreferencesPopover: false,
  };

  onClick = () => {
    this.setState(({ showPreferencesPopover }) => ({
      showPreferencesPopover: !showPreferencesPopover,
    }));
  };

  onBlur = () => {
    this.setState({
      showPreferencesPopover: false,
    });
  };

  getComputedProps() {
    const { peer } = this.props;

    const state = {
      shouldShow: false,
      icon: NOTIFY_ICON_CLASS.UNMUTE,
      tooltipText: TOOLTIP_TEXT.UNMUTE,
    };

    if (peer.type !== 'group') {
      return state;
    }

    state.shouldShow = true;

    if (peer.isMuted) {
      state.icon = NOTIFY_ICON_CLASS.MUTE;
      state.tooltipText = TOOLTIP_TEXT.MUTE;

      if (peer.notifyOn === 'MENTIONS') {
        state.icon = NOTIFY_ICON_CLASS.PARTIAL_MUTE;
        state.tooltipText = TOOLTIP_TEXT.PARTIAL_MUTE;
      }
    }

    return state;
  }

  render() {
    const { shouldShow, icon, tooltipText } = this.getComputedProps();

    if (!shouldShow) {
      return null;
    }

    const { peer } = this.props;
    const { showPreferencesPopover } = this.state;

    return (
      <Popover
        open={showPreferencesPopover}
        onClick={this.onClick}
        onBlur={this.onBlur}
        popOverContent={<PreferencesPopover peer={peer} />}
        position='bottom-left'
        triggerClass={`${ActionButtonCss.Item} ${css[icon]}`}
        heightDifference={20}
        widthDifference={-30}
        showArrow
        arrowPosition='top'
        arrowStyle={{ left: `calc(100% - 30px )` }}
      >
        <Tippy
          maxWidth={200}
          content={tooltipText}
          arrow
          className={commonStyles.info_tooltip}
        >
          {/* TODO: Figure out a way without this div */}
          <div
            className={css.dummyEl}
            data-pendo-hook='chat-notification-preferences'
          />
        </Tippy>
      </Popover>
    );
  }
}

export default NotificationPreferences;
