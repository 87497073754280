import { createSelector } from 'reselect';
import bridge from '@/utils/bridge';
import store from '@/store';
import { Actions } from '@/actions/restricted';
import { getSessionId } from '@/utils/SessionUtils';
import { SORTABLE_STORE } from '@/utils/storeNames';
import { SIDEBAR_CATEGORY } from '@/utils/customApps';
import storeSubscribe from '@/subscribe';
import { callProviderByGuidFactory } from '@/selectors';
import { maybe } from '@/utils';
import { getCounter } from './utilities';

const SESSION_ORDER_KEY = SIDEBAR_CATEGORY;

const allcurrentSessionSelector = (state) =>
  state.restricted._session.session_proxy;
const getSessionWithId = createSelector(
  allcurrentSessionSelector,
  (state, id) => id,
  (allSessions, sessionID) => {
    return allSessions[sessionID] || undefined;
  }
);

function getOwnerGuid(sessionId) {
  const match = sessionId.match(/session_(.*)@go.to/);
  return match && match[1];
}

function setAllAppsInStore(ownerGuid, appList) {
  store.dispatch(
    Actions.app_list_proxy({
      appList,
      ownerGuid,
    })
  );
}

async function getFilteredAllApps(appList, sessionId) {
  const [twilioAppId, appearAppId] = await Promise.all([
    bridge.ask('HydraServer', 'getStartupOption', ['twilioAppId']),
    bridge.ask('HydraServer', 'getStartupOption', ['appearAppId']),
  ]);
  const session = getSessionWithId(store.getState(), sessionId);
  if (!session || !Array.isArray(appList)) {
    return appList;
  }

  const callProvider = maybe(
    session,
    'sessionInfo',
    'teamInfo',
    'call',
    'provider'
  );
  if (callProvider === 'twilio') {
    return appList.filter((app) => app.id !== appearAppId);
  }
  return appList.filter((app) => app.id !== twilioAppId);
}

async function getAllApps(ownerGuid) {
  const sessionId = getSessionId(ownerGuid);
  const appList = await bridge.ask('DikeController', 'getAllApps', [
    getSessionId(ownerGuid),
  ]);
  const filteredAppList = await getFilteredAllApps(appList, sessionId);
  setAllAppsInStore(ownerGuid, filteredAppList);
  return filteredAppList;
}

function setBadgesInStore(ownerGuid, badges) {
  store.dispatch(
    Actions.app_list_badges({
      badges,
      ownerGuid,
    })
  );
}

async function getAllBadges(ownerGuid) {
  const badges = await bridge.ask('DikeController', 'getAllBadges', [
    getSessionId(ownerGuid),
  ]);
  setBadgesInStore(ownerGuid, badges);
}

function getAppsOrder(sessionId) {
  return bridge
    .ask('Storage', 'get', [
      SORTABLE_STORE,
      `${SESSION_ORDER_KEY}_${sessionId}`,
    ])
    .then((response) => {
      return response && response.split('|');
    });
}

class DikeProxy {
  counter = getCounter();

  subscribedIds = {};

  subscribedGuids = {};

  // Store subscriptions per ownerGuid
  storeSubscriptions = {};

  constructor() {
    bridge.subscribe('hydra/dike/apps/refreshed', this.onAppsRefreshed);
    bridge.subscribe('hydra/dike/badges/refreshed', this.onBadgesRefreshed);
  }

  onBadgesRefreshed = (sessionId) => {
    const ownerGuid = getOwnerGuid(sessionId);
    if (this.subscribedIds[ownerGuid] && this.subscribedIds[ownerGuid].length) {
      getAllBadges(ownerGuid);
    }
  };

  onAppsRefreshed = (sessionId) => {
    const ownerGuid = getOwnerGuid(sessionId);
    if (this.subscribedIds[ownerGuid] && this.subscribedIds[ownerGuid].length) {
      getAllApps(ownerGuid);
    }
  };

  getAppsOrder = getAppsOrder;

  saveAppsOrder(appsOrder, ownerGuid) {
    store.dispatch(Actions.apps_order({ appsOrder, ownerGuid }));
  }

  _subscribe(ownerGuid, setId) {
    if (this.subscribedGuids[setId] === ownerGuid) {
      return;
    }
    this._unsubscribe(setId);
    if (!this.subscribedIds[ownerGuid]) {
      this.subscribedIds[ownerGuid] = [];
    }
    this.subscribedGuids[setId] = ownerGuid;
    if (this.subscribedIds[ownerGuid].length === 0) {
      getAllApps(ownerGuid);
      getAllBadges(ownerGuid);
      // Updates appList if callProvider changes.
      this.storeSubscriptions[ownerGuid] = storeSubscribe(
        callProviderByGuidFactory(ownerGuid),
        () => {
          getAllApps(ownerGuid);
        }
      );
    }
    this.subscribedIds[ownerGuid].push(setId);
  }

  subscribe(ownerGuid) {
    const setId = this.counter.next().value;
    this._subscribe(ownerGuid, setId);
    return {
      unsubscribe: this._unsubscribe.bind(this, setId),
      update: (updatedOwnerGuid) => this._subscribe(updatedOwnerGuid, setId),
    };
  }

  _unsubscribe(setId) {
    if (!setId) {
      return;
    }
    const ownerGuid = this.subscribedGuids[setId];
    const subscriptions = this.subscribedIds[ownerGuid];
    if (subscriptions) {
      if (subscriptions.length > 0) {
        const idx = subscriptions.indexOf(setId);
        subscriptions.splice(idx, 1);
      }
      if (subscriptions.length < 1) {
        setAllAppsInStore(ownerGuid, undefined);
        // Unsubscriber store subscription
        if (this.storeSubscriptions[ownerGuid]) {
          this.storeSubscriptions[ownerGuid]();
        }
      }
    }
    this.subscribedGuids[setId] = null;
  }
}

export default new DikeProxy();
