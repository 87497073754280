import bridge from '@/utils/bridge';
import { useState, useEffect } from 'react';

let cachedValue: any = [];

export default function useCountryCodes() {
  const [value, setValue] = useState(cachedValue);

  useEffect(() => {
    let cancelled = false;

    if (cachedValue.length === 0) {
      bridge.ask('Shell', 'getCountryCodeListItems').then((result) => {
        if (cancelled) {
          return;
        }

        cachedValue = result;
        setValue(result);
      });
    }

    return () => {
      cancelled = true;
    };
  }, []);

  return value;
}
