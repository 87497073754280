import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import currentSessionTeamInfoSelector from '@/selectors/currentSessionTeamInfo';
import { getMemberCount, maybe } from '@/utils';
import cssStyles from './ContactItem.css';

class GroupContact extends PureComponent {
  static propTypes = {
    contact: PropTypes.object,
    showJoinButton: PropTypes.bool,
    showDescription: PropTypes.bool,
    showCreatedBy: PropTypes.bool,
  };

  static defaultProps = {
    contact: {},
    showJoinButton: false,
    showDescription: false,
    showCreatedBy: false,
  };

  getContactMembers() {
    const {
      contact: { memberCount, creatorName },
      showCreatedBy,
      maskChannelmemberCountAfter,
    } = this.props;
    if (memberCount === undefined) {
      return null;
    }
    return (
      <div className={cssStyles.contactDesc}>
        <FormattedMessage
          id='channel_member_count'
          values={{
            count: getMemberCount(memberCount, maskChannelmemberCountAfter),
          }}
        />
        {showCreatedBy && creatorName.length > 0 ? ' • ' : null}
        {showCreatedBy && creatorName.length > 0 ? (
          <FormattedMessage id='created_by' values={{ name: creatorName }} />
        ) : null}
      </div>
    );
  }

  getDescription() {
    const {
      contact: { description, groupType, isJoined },
      showDescription,
    } = this.props;
    if (!description || groupType !== 'open' || isJoined || !showDescription) {
      return null;
    }
    return (
      <div className={cssStyles.groupDescription} title={description}>
        {description}
      </div>
    );
  }

  getJoinButton() {
    const {
      contact: { isGroup, groupType, isJoined },
      showJoinButton,
    } = this.props;
    if (!isGroup || groupType !== 'open' || isJoined || !showJoinButton) {
      return null;
    }

    return <div className={cssStyles.joinButton} />;
  }

  render() {
    const {
      contact: { name, nameAutoComplete, imageUrl },
    } = this.props;
    return (
      <div>
        <div className={cssStyles.flex}>
          <div
            className={`${cssStyles.relative} ${cssStyles.thumbnaildimensions}`}
          >
            <img className={cssStyles.thumbnail} src={imageUrl} alt={name} />
          </div>
          <div className={cssStyles.contactDetails}>
            <div className={cssStyles.contactName}>
              {name}
              <span className={cssStyles.contactNameAutoComplete}>
                {nameAutoComplete || ''}
              </span>
            </div>
            {this.getContactMembers()}
          </div>
          {this.getJoinButton()}
        </div>
        {this.getDescription()}
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  currentSessionTeamInfoSelector,
  (teamInfo) => {
    return {
      maskChannelmemberCountAfter: maybe(
        teamInfo,
        'channelPreferences',
        'maskChannelMemberCountAfter'
      ),
    };
  }
);

export default connect(mapStateToProps)(GroupContact);
