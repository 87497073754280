import { connect } from 'react-redux';
import { isContactIdSpecial, getContactForId } from '@/Models/Contact';
import { getMemoizedArray } from '@/utils';

function mapStateToProps(memoizedArray, idsProp, guidProp, state, props) {
  const contactIds = props[idsProp];
  const ownerGuid = props[guidProp];
  const contacts = [];
  if (
    !Array.isArray(contactIds) ||
    !ownerGuid ||
    !state.restricted.contact.contact_proxy ||
    !state.restricted.contact.contact_proxy[ownerGuid]
  ) {
    return {
      contacts,
    };
  }
  const allContacts = state.restricted.contact.contact_proxy[ownerGuid];
  for (let i = 0; i < contactIds.length; i += 1) {
    const contactId = contactIds[i];
    if (isContactIdSpecial(contactId)) {
      contacts.push(getContactForId(contactId, ownerGuid));
    } else if (allContacts[contactId]) {
      contacts.push(allContacts[contactId]);
    }
  }
  return {
    contacts: memoizedArray(contacts),
  };
}

/**
 *
 * @param {Component, to which contacts will be passed as a prop} component
 * @param {props key, which has contact ids as an array} idsProp
 * @param {props key for ownerGuid} guidProp
 */
export default function (
  component,
  idsProp = 'contactIds',
  guidProp = 'ownerGuid'
) {
  const memoizedArray = getMemoizedArray([]);
  return connect(mapStateToProps.bind(null, memoizedArray, idsProp, guidProp))(
    component
  );
}
