import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import classNames from 'classnames';
import { getUnreadCount } from '@/utils/ConversationUtils';
import { addsToCount } from '@/utils/ReceiptUtils';
import noop from '@/utils/noop';
import {
  allMessagesByGuidSelector,
  allUnreadMessagesByGuidSelector,
} from '@/selectors';
import cssStyles from './ScrollButton.css';

function ScrollButton({
  shouldScrollToUnread,
  scrollToUnreadMessage,
  scrollToBottom,
  onInteract,
  unreadCount,
  hasMention,
}) {
  const onClick = useCallback(() => {
    if (shouldScrollToUnread) {
      scrollToUnreadMessage();
    } else {
      scrollToBottom();
    }
    onInteract();
  }, [shouldScrollToUnread, scrollToUnreadMessage, scrollToBottom, onInteract]);
  return (
    <div
      className={classNames(cssStyles.unreadAffordance, {
        [cssStyles.scrollsToBottom]: !shouldScrollToUnread,
      })}
      data-pendo-hook='conversation-scroll-button'
      onClick={onClick}
      onKeyDown={noop}
      tabIndex={0}
      role='button'
    >
      {unreadCount > 0 ? (
        <span className={cssStyles.unreadAffordanceCountWrapper}>
          <span
            className={classNames(cssStyles.unreadAffordanceCount, {
              [cssStyles.mentionsMe]: hasMention,
            })}
          >
            {getUnreadCount(unreadCount)}
          </span>
        </span>
      ) : null}
      <div className={cssStyles.unreadAffordanceButton}>
        <div className={cssStyles.unreadAffordanceButtonArrow} />
      </div>
    </div>
  );
}

const peerJidSelector = (_, props) => {
  const { currentPeerId, ownerGuid } = props;
  return { currentPeerId, ownerGuid };
};

const unreadPropsSelector = createSelector(
  allUnreadMessagesByGuidSelector,
  allMessagesByGuidSelector,
  peerJidSelector,
  (
    getAllUnreadMessagesByGuid,
    getAllMessagesByGuid,
    { currentPeerId, ownerGuid }
  ) => {
    const allMessages = getAllMessagesByGuid(ownerGuid);
    const unreadMessages = getAllUnreadMessagesByGuid(ownerGuid);

    const unreadMsgsForPeer = unreadMessages[currentPeerId];
    let unreadCount = 0;
    let hasMention = false;
    unreadMsgsForPeer?.forEach((mcId) => {
      const message = allMessages[mcId];
      if (message) {
        if (addsToCount(message)) {
          unreadCount += 1;
          hasMention =
            hasMention ||
            message.messageElements?.mentions?.some(
              ({ mentionsMe }) => mentionsMe
            );
        }
      }
    });
    return {
      unreadCount,
      hasMention,
    };
  }
);

export default connect(unreadPropsSelector)(ScrollButton);
