import React, { Component } from 'react';
import css from './MemberCount.css';

class MemberCount extends Component {
  static defaultProps = {
    peer: undefined,
  };

  render() {
    const { peer, showContactCard, memberCount } = this.props;
    if (!peer) {
      return null;
    }
    const { type } = peer;
    if (type !== 'group') {
      return null;
    }

    return (
      <div
        className={css.Wrapper}
        onClick={showContactCard}
        onKeyDown={() => {}}
        role='button'
        tabIndex={-1}
      >
        <span className={css.ContactIcon} />
        <span className={css.Count}>{memberCount}</span>
      </div>
    );
  }
}

export default MemberCount;
