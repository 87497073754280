import React from 'react';
import FilePreview from './FilePreview';
import GenericFileThumbnail from './GenericFileThumbnail';

export default function (props) {
  const { download } = props;
  console.debug('download: ', download);
  return download.preview ? (
    <FilePreview {...props} />
  ) : (
    <GenericFileThumbnail {...props} />
  );
}
