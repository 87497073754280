import ptBr from './pt-br';
import enGB from './en-GB';
import enUS from './en-US';
import es from './es';
import esMX from './es-MX';

export default {
  'pt-br': ptBr,
  es,
  'en-GB': enGB,
  'en-US': enUS,
  'es-MX': esMX,
};
