import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { openUrl } from '@/utils/SessionUtils';
import bridge from '@/utils/bridge';
import platform from '@/utils/platform';
import aboutFlockStyles from './AboutFlockModalComponent.css';
import IntlWrapper from '../../../Wrappers/IntlWrapper';
import translations from './I18n';

function applyDownloadedUpdate() {
  bridge.publish('shell/applyDownloadedUpdate', []);
}

function checkForUpdates() {
  bridge.publish('shell/check-for-updates', []);
}

const UpdateStatusMessages = {
  updateAvailable: () => <span>Downloading update...</span>,
  updateDownloading: ({ progressPercentage }) => {
    return (
      <div>
        <span>Downloading update...</span>
        {progressPercentage && (
          <div className={aboutFlockStyles.update_progress_bar}>
            <span
              className={aboutFlockStyles.update_progress_status}
              style={{ width: `${progressPercentage}%` }}
            />
          </div>
        )}
        <div className={aboutFlockStyles.update_process_note}>
          Closing this window will continue the update process in the background
        </div>
      </div>
    );
  },
  updateDownloaded: () => (
    <div className={aboutFlockStyles.download_complete}>
      Download complete.{' '}
      <span
        role='button'
        onKeyDown={() => {}}
        tabIndex={0}
        onClick={applyDownloadedUpdate}
      >
        Restart Flock
      </span>
    </div>
  ),
  updateChecking: () => (
    <div className={aboutFlockStyles.checking_update}>Checking for update</div>
  ),
  updateError: () => (
    <div className={aboutFlockStyles.update_error}>
      Update Error
      <span
        role='button'
        onKeyDown={() => {}}
        tabIndex={0}
        onClick={checkForUpdates}
      >
        Retry now
      </span>
    </div>
  ),
  noUpdate: () => <span>You are up-to-date!</span>,
};

class AboutFlockModalComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMacAppStore: true,
      componentName: '',
    };

    checkForUpdates();
    this.checkPlatform();
  }

  renderUpdate = () => {
    const { componentName } = this.state;
    return UpdateStatusMessages[componentName](this.state);
  };

  _attachListeners = () => {
    bridge.subscribe('shell/checking-for-update', () => {
      this.setState({
        componentName: 'updateChecking',
      });
    });

    bridge.subscribe('shell/update-available', () => {
      this.setState({
        componentName: 'updateAvailable',
      });
    });

    bridge.subscribe('shell/update-not-available', () => {
      this.setState({
        componentName: 'noUpdate',
      });
    });

    bridge.subscribe('shell/update-download-progress', (progressPercentage) => {
      this.setState({
        progressPercentage,
        componentName: 'updateDownloading',
      });
    });

    bridge.subscribe('shell/update-downloaded', () => {
      this.setState({
        componentName: 'updateDownloaded',
      });
    });

    bridge.subscribe('shell/update-error', () => {
      this.setState({
        componentName: 'updateError',
      });
    });
  };

  openWebsite = () => {
    openUrl('https://www.flock.com/?noredirect=true');
  };

  openReleaseNotes = () => {
    const { releaseNotesUrl } = this.state;
    openUrl(releaseNotesUrl);
  };

  checkPlatform = () => {
    platform.getDeviceHost().then((platformInfo) => {
      platform.getAll().then((options) => {
        this.setState({
          releaseNotesUrl: options[`whats_new_url_${platformInfo}`],
        });
      });
    });

    platform.isMacAppStore().then((response) => {
      this.setState(
        {
          isMacAppStore: response,
        },
        () => {
          platform.getAll().then((res) => {
            const { isMacAppStore } = this.state;
            const isDesktopAppClient =
              res.uaInfoParams['device-type'] === 'desktop' &&
              ['Mac_OS_X', 'Windows'].includes(res.uaInfoParams.os);
            if (isDesktopAppClient && !isMacAppStore) {
              this._attachListeners();
            }
          });
        }
      );
    });
  };

  render() {
    const { version } = this.props;
    const { isMacAppStore, componentName } = this.state;
    const currentYear = new Date().getFullYear();

    return (
      <div className={aboutFlockStyles.about_flock_modal_wrapper}>
        <div className={aboutFlockStyles.about_flock_modal_content}>
          <div className={aboutFlockStyles.flock_logo}>
            <div className={aboutFlockStyles.flock_logo__image} />
            <div className={aboutFlockStyles.flock_logo__text} />
          </div>

          <div className={aboutFlockStyles.flock_version}>
            Version {version}
          </div>

          {componentName && (
            <div className={aboutFlockStyles.update_status_text}>
              <div>{this.renderUpdate()}</div>
            </div>
          )}

          {!isMacAppStore && (
            <div
              className={aboutFlockStyles.release_notes}
              onClick={this.openReleaseNotes}
              role='link'
              tabIndex='-1'
              onKeyDown={this.openReleaseNotes}
            >
              <FormattedMessage id='read_release_notes' />
            </div>
          )}
        </div>
        <div className={aboutFlockStyles.footer}>
          <span className={aboutFlockStyles.copyright}>
            <FormattedMessage id='flock_copyright' /> &copy; {currentYear}
          </span>
          <span
            className={aboutFlockStyles.website_link}
            onClick={this.openWebsite}
            role='link'
            tabIndex='-1'
            onKeyDown={this.openWebsite}
          >
            www.flock.com
          </span>
        </div>
      </div>
    );
  }
}

export default IntlWrapper(AboutFlockModalComponent, translations);
