const defaultState = {};

export default (ActionTypes) => {
  return (state = defaultState, action) => {
    try {
      switch (action.type) {
        case ActionTypes.UPDATE_MESSAGE_ANCHOR: {
          const { ownerGuid, jid, anchor } = action.payload;
          return {
            ...state,
            [ownerGuid]: {
              ...state[ownerGuid],
              [jid]: anchor,
            },
          };
        }
        default:
          return state;
      }
    } catch (error) {
      console.error(
        `error in message anchor state reducer : ${error.message}`,
        error
      );
    }
    return state;
  };
};
