import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { DropdownList } from '@/components/common';
import noop from '@/utils/noop';
import { BUCKETS, MODES } from '../constants';
import styles from '../ActiveChats.css';

class ViewModes extends PureComponent {
  static defaultProps = {
    markAllRead: noop,
  };

  static propTypes = {
    /* The style/design mode of the current bucket */
    mode: PropTypes.oneOf(Object.values(MODES)).isRequired,
    /* The type of the bucket, can be pinned, muted or open */
    type: PropTypes.oneOf(Object.values(BUCKETS)).isRequired,
    /* The callback to be executed on selection of a mode */
    onModeChange: PropTypes.func.isRequired,
    markAllRead: PropTypes.func,
  };

  state = {};

  constructor(props) {
    super(props);
    const { intl, type, markAllRead } = props;
    this.options = [
      {
        style: MODES.COMPACT,
        text: intl.formatMessage({ id: 'display_density_compact' }),
      },
      {
        style: MODES.COSY,
        text: intl.formatMessage({ id: 'display_density_thin' }),
      },
      {
        style: MODES.COMFORTABLE,
        text: intl.formatMessage({ id: 'display_density_expanded' }),
      },
      {
        style: MODES.CLASSIC,
        text: intl.formatMessage({ id: 'display_density_classic' }),
      },
    ];

    if (markAllRead !== noop) {
      this.options.push({
        text: intl.formatMessage({ id: 'read_options_mark_as_read' }),
        className: styles.markAllRead,
        onClick: () => markAllRead(type),
      });
    }
    this.modeRef = React.createRef();
  }

  isSelected = (option) => {
    const { mode } = this.props;
    return mode === option.style;
  };

  getDropdownProps = () => {
    const { intl } = this.props;
    return {
      options: this.options,
      dropdownClass: styles.modes,
      onClick: this.selectMode,
      label: `${intl.formatMessage({
        id: 'select_drop_down_title_display_density',
      })}:`,
      labelClass: styles.label,
      selectedClass: styles.selectedMode,
      isSelected: this.isSelected,
    };
  };

  selectMode = (e, option) => {
    e.stopPropagation();
    const { onModeChange, type } = this.props;
    onModeChange(type, option.style);
    return true;
  };

  render() {
    return (
      <DropdownList {...this.getDropdownProps()}>
        <div ref={this.modeRef} className={styles.overflowIcon} />
      </DropdownList>
    );
  }
}

export default injectIntl(ViewModes);
