import React from 'react';
import ReactDOM from 'react-dom';
import debug from 'debug';
import '@/Proxies/CurrentSessionProxy';
import '@/styles/index.css';
// eslint-disable-next-line import/no-unresolved
import 'tippy.js/dist/tippy.css';
import '@talk-to/flock-components/dist/flock-components.css';
import store from '@/store';
import initStoreSubscribers from '@/store/SubscriberHandler';
import Root from '@/containers/Root';
import './wydr';

const mountPoint = document.getElementById('root');
const env = process.env.NODE_ENV;

initStoreSubscribers();
if (env === 'production') {
  debug.disable();
} else {
  debug.enable('odara:*');
}

const render = () => {
  ReactDOM.render(<Root store={store} />, mountPoint);
};

render();
