import { createSelector } from 'reselect';
import { getContactForId } from '@/Models/Contact';
import ownerGuidSelector from './ownerGuid';
import allContactsSelector from './allContacts';

export default createSelector(
  ownerGuidSelector,
  allContactsSelector,
  (ownerGuid, allContacts) =>
    getContactForId(`${ownerGuid}@go.to`, ownerGuid, allContacts)
);
