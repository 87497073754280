/* eslint-disable import/prefer-default-export */
import { logMedusaGenericEvent } from '@/utils/logAnalytics';

const logMedusaEventWithConnectivity = (
  eventName: string,
  currentSession: Session,
  params: Object = {}
) => {
  const connectivity = currentSession?.state === 'ACTIVE';
  logMedusaGenericEvent(eventName, {
    ...params,
    connectivity,
  });
};

export { logMedusaEventWithConnectivity };
