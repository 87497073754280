import React, { useCallback } from 'react';
import prettyFileSize from '@/utils/prettyFileSize';
import noop from '@/utils/noop';
import { downloadAction } from '@/components/Conversation/Message/AttachmentAction/LocalAttachmentActions';
import cssStyles from './FilePreview.css';

/**
 * Renders a thumbnail, clicking on which calls the `downloadAction` from './AttachmentAction/LocalAttachmentActions.js`
 * @param {object} props - props to the `GenericFileThumbnail` are the currentSessionId and download item available in the attachment.downloads array.
 */
export default React.memo(function GenericFileThumbnail({
  currentSessionId,
  download,
}) {
  const { filename, size } = download;
  const onThumbnailClick = useCallback(() => {
    const attachment = { downloads: [download] };
    downloadAction(attachment, currentSessionId, 'preview');
  }, [download, currentSessionId]);
  return (
    <div
      onKeyDown={noop}
      role='button'
      tabIndex='-1'
      className={cssStyles.fileThumbnail}
      onClick={onThumbnailClick}
    >
      <div className={cssStyles.fileIcon} />
      <div className={cssStyles.fileDetailsWrap}>
        <div className={cssStyles.fileName}>{filename}</div>
        <div className={cssStyles.fileSize}>{prettyFileSize(size)}</div>
      </div>
    </div>
  );
});
