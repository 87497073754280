import React from 'react';

const downArrow = ({ style = {} }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='14'
      height='14'
      viewBox='0 0 14 14'
      style={style}
    >
      <g fill='none' fillRule='evenodd'>
        <g fill='#0ABE51' fillRule='nonzero'>
          <g>
            <g>
              <g>
                <path
                  d='M12.25 7.134c0-.38-.303-.69-.677-.69H4.147L6.9 3.835c.187-.166.27-.422.217-.668-.053-.246-.234-.443-.472-.513-.237-.071-.494-.004-.669.175l-4.01 3.801c-.137.13-.215.313-.215.504s.078.373.215.504l4.01 3.803c.274.26.702.244.957-.034.256-.278.24-.714-.033-.974l-2.752-2.61h7.426c.374 0 .677-.308.677-.688z'
                  transform='translate(-914 -307) translate(384 300) translate(403) matrix(0 -1 -1 0 141 21)'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default downArrow;
