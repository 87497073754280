import { createSelector } from 'reselect';
import { addsToCount } from '@/utils/ReceiptUtils';
import allUnreadMessagesSelector from './allUnreadMessages';
import ownerGuidSelector from './ownerGuid';
import currentPeerSelector from './currentPeer';
import currentAllMessages from './allCurrentOwnerMessages';

const defaultUnreadMessages = {};
const emptyArray = [];

export const allUnreadMessagesByGuidSelector = createSelector(
  allUnreadMessagesSelector,
  (allUnreadMessages) => (ownerGuid) =>
    allUnreadMessages[ownerGuid] || defaultUnreadMessages
);

export const unreadMessagesByJidSelector = createSelector(
  allUnreadMessagesByGuidSelector,
  (allUnreadMessages) => (ownerGuid, jid) => {
    const unreadMessages = allUnreadMessages(ownerGuid)?.[jid];
    return unreadMessages || emptyArray;
  }
);

export const unreadMessageCacheIdsForCurrentPeer = createSelector(
  ownerGuidSelector,
  currentPeerSelector,
  unreadMessagesByJidSelector,
  (ownerGuid, currentPeer, getUnreadMessagesByJid) => {
    if (!ownerGuid || !currentPeer) {
      return emptyArray;
    }
    const unreadMessages = getUnreadMessagesByJid(ownerGuid, currentPeer.jid);
    return unreadMessages;
  }
);

export const countedUnreadMessagesForCurrentPeer = createSelector(
  currentAllMessages,
  unreadMessageCacheIdsForCurrentPeer,
  (allMessages, unreadMessageCacheIds) => {
    return unreadMessageCacheIds
      .map((mcid) => allMessages[mcid])
      .filter((msg) => !!msg && addsToCount(msg));
  }
);

export const mentionedUnreadMessagesForCurrentPeer = createSelector(
  countedUnreadMessagesForCurrentPeer,
  (messages) => {
    return messages.filter((message) =>
      message.messageElements?.mentions?.some(({ mentionsMe }) => mentionsMe)
    );
  }
);

export default createSelector(
  ownerGuidSelector,
  allUnreadMessagesByGuidSelector,
  (ownerGuid, getUnreadMessagesByGuid) =>
    getUnreadMessagesByGuid(ownerGuid) || {}
);
