/* eslint-disable import/prefer-default-export, prefer-destructuring, no-prototype-builtins */
import flockmlParser from '@/utils/flockML';
import { trimOutUnnecessarySpaces } from '@/utils/message/flockml';
import {
  getFormatedContent,
  removeUnnecessaryTags,
  sanitizeLinks,
} from '../../FroalaUtils/FroalaEditorUtils';
import {
  replaceTag,
  removeUnnecessaryBr,
  isHtmlEmpty,
} from '../../FroalaUtils/Utils';
import {
  unsupportedTagReplacements,
  htmlAllowedEmptyTags,
} from '../../constants';

export const CustomClipboard = (froalaEditor) => {
  const FE =
    froalaEditor && froalaEditor.hasOwnProperty('default')
      ? froalaEditor.default
      : froalaEditor;

  FE.MODULES.CustomClipboard = (editor) => {
    function _init() {
      // You can access any option from documentation or your custom options.
      // Call any method from documentation.
      // editor.methodName(params);

      // You can listen to any event from documentation.
      editor.events.on(
        'paste.before',
        (event) => {
          const htmlContent = event.clipboardData.getData('text/html');
          if (isHtmlEmpty(htmlContent)) {
            event.preventDefault();
            const formattedContent = _postFormatPastedContent(
              '',
              event.clipboardData.getData('text/plain')
            );
            editor.undo.saveStep();
            editor.html.insert(formattedContent, true);
            editor.undo.saveStep();
            return false;
          }
          return true;
        },
        true
      );

      editor.events.on('paste.beforeCleanup', (html) => {
        if (html) {
          return _preFormatPastedContent(html);
        }
        return undefined;
      });

      editor.events.on('paste.afterCleanup', (html) => {
        // editor.opts.enter = editor.FROALA.ENTER_BR;
        if (html) {
          // return _postFormatPastedContent(html, '');
          editor.html.insert(_postFormatPastedContent(html, ''), true);
        }
        return '';
      });

      // editor.events.on('paste.after', () => {
      //   editor.opts.enter = editor.FROALA.ENTER_P;
      // })
    }

    const _preFormatPastedContent = (html: string) => {
      let _html = replaceTag(html, 'div', 'p');
      _html = replaceUnsupportedTags(_html);
      return _html;
    };

    const _postFormatPastedContent = (
      htmlContent: string,
      textContent: string
    ) => {
      let _html = getFormatedContent(htmlContent, textContent, true);
      _html = removeUnnecessaryTags(_html);
      _html = convertToProperHtml(_html);
      _html = replaceTag(_html, 'p', 'br');
      _html = removeUnnecessaryBr(_html);
      _html = trimOutUnnecessarySpaces(_html);
      _html = _addEmojiClassToImg(_html);
      if (editor.core.isEmpty()) {
        _html = `<p>${_html}</p>`;
      }
      return _html;
    };

    const convertToProperHtml = (html: string): string => {
      const viewDocument = flockmlParser(html);
      _convertAttributesToElement(viewDocument as HTMLElement);
      _cleanEmptyTags(viewDocument as HTMLElement);
      sanitizeLinks(viewDocument);
      return viewDocument.innerHTML;
    };

    const replaceUnsupportedTags = (html: string) => {
      let _html: string = html;
      unsupportedTagReplacements.forEach((replacementObj) => {
        _html = replaceTag(
          _html,
          replacementObj.tagName,
          replacementObj.replacement
        );
      });
      return _html;
    };

    const _cleanEmptyTags = (node: HTMLElement) => {
      const _htmlAllowedEmptyTags = [
        ...(editor.opts.htmlAllowedEmptyTags || []),
        ...htmlAllowedEmptyTags,
      ];
      if (!node) return false;
      let i = 0;
      while (node && i < node.children.length) {
        const isChildRemoved = _cleanEmptyTags(node.children[i] as HTMLElement);
        if (!isChildRemoved) {
          i += 1;
        }
      }
      if (
        !node.childNodes.length &&
        !_htmlAllowedEmptyTags.includes(node.tagName.toLowerCase())
      ) {
        node.remove();
        return true;
      }
      return false;
    };

    const shouldCreateBold = (node: HTMLElement) => {
      const fontWeight = node.style.fontWeight;
      if (fontWeight && (Number(fontWeight) > 500 || fontWeight === 'bold')) {
        return 'strong';
      }
      return '';
    };

    const shouldCreateItalics = (node: HTMLElement) => {
      const fontStyle = node.style.fontStyle;
      if (fontStyle && fontStyle === 'italic') {
        return 'i';
      }
      return '';
    };

    const shouldCreateUnderline = (node: HTMLElement) => {
      const textDecorators = node.style.textDecorationLine;
      if (textDecorators && textDecorators.includes('underline')) {
        return 'u';
      }
      return '';
    };

    const shouldCreateStrikethrough = (node: HTMLElement) => {
      const textDecorators = node.style.textDecorationLine;
      if (textDecorators && textDecorators.includes('line-through')) {
        return 's';
      }
      return '';
    };
    const converters = [
      shouldCreateBold,
      shouldCreateItalics,
      shouldCreateUnderline,
      shouldCreateStrikethrough,
    ];
    const _convertAttributesToElement = (node: HTMLElement): void => {
      if (!node) return;
      let leafNode = null;
      let rootNode = null;
      const childrenOfCurrentNode = node.children;
      converters.forEach((converterFn) => {
        const tagName = converterFn(node);
        if (tagName) {
          const elementFromAttribute = document.createElement(tagName);
          if (!leafNode) {
            leafNode = elementFromAttribute;
            rootNode = elementFromAttribute;
          } else {
            leafNode = leafNode.appendChild(elementFromAttribute);
          }
        }
      });
      _clearAttributes(node, ['href', 'target']);
      if (rootNode) {
        _assignNewParentToChildrens(rootNode, leafNode, node);
      }
      for (let i = 0; i < childrenOfCurrentNode.length; i += 1) {
        _convertAttributesToElement(childrenOfCurrentNode[i] as HTMLElement);
      }
    };

    const _assignNewParentToChildrens = (
      newParentRoot: HTMLElement,
      newParentLeaf: HTMLElement,
      oldParent: HTMLElement
    ) => {
      while (oldParent.childNodes.length > 0) {
        newParentLeaf.appendChild(oldParent.childNodes[0]);
      }
      oldParent.appendChild(newParentRoot);
    };

    const _clearAttributes = (node: HTMLElement, excludeAttributes = []) => {
      const attributes = node.attributes;
      for (let i = 0; i < attributes.length; i += 1) {
        const attributeName = attributes[i].nodeName;
        if (
          node.nodeName !== 'IMG' &&
          !excludeAttributes.includes(attributeName)
        ) {
          node.removeAttribute(attributeName);
        }
      }
    };

    return {
      _init,
    };
  };
};

const _addEmojiClassToImg = (html: string) => {
  const viewDocument = flockmlParser(html);
  const imgs = Array.from(viewDocument.querySelectorAll('img[data-emoji-id]'));
  imgs.forEach((img) => img.classList.add('emoji'));
  return viewDocument.innerHTML;
};
