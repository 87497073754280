import { createSelector } from 'reselect';
import memoizeOne from 'memoize-one';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import { PINNED, OPEN, MUTED } from '@/utils/constants';
import { isGroup as checkGroup, getMemoizedObject } from '@/utils';
import { addsToCount } from '@/utils/ReceiptUtils';
import { openConversationsByGuidSelector } from './openConversations';
import { pinsOrderByGuidSelector } from './pinnedChats';
import { contactsByGuidSelector } from './allContacts';
import { allUnreadMessagesByGuidSelector } from './unreadMessages';
import { allMessagesByGuidSelector } from './allCurrentOwnerMessages';
import ownerGuidSelector from './ownerGuid';

const defaultBucket = [];
const defaultUnreadMessages = [];
const NOTIFICATION_TYPES = {
  DIRECT_MENTION: 'DIRECT_MENTION',
  MENTIONS: 'MENTIONS',
  ALL_MESSAGES: 'ALL_MESSAGES',
};

/**
 * Memoized functiion to get the pinned chats order.
 */
const getPinnedChats = memoizeOne(function (pinned, pinsOrder) {
  /* Sort the pinned chats with order */
  const pins = new Map();
  pinned.forEach((chat) => pins.set(chat.jid, chat));
  const orderedPins = pinsOrder
    .map((jid) => {
      const pin = pins.get(jid);
      pins.delete(jid);
      return pin;
    })
    .filter((p) => p);
  if (pins.size > 0) {
    pins.forEach((p) => orderedPins.unshift(p));
  }

  return orderedPins;
});

/**
 * @typedef {Object} Contact - The contact object
 * @property {boolean} isGroup - Whether contact is a group or not
 * @property {boolean} isBuddy - Whether contact is a buddy
 * @property {boolean} isJoined - Whether contact has joined if `isGroup` is `true`
 * @property {boolean} isDeleted - Sets `true` for deleted contact
 * @property {boolean} pinned
 */

/**
 * Filter function to filter out deleted contacts.
 * There are three major scenarios:
 * 1. It's a one-on-one chat and it's not deleted. `!isDeleted && isBuddy`
 * 2. If it's a group, check if it's not deleted and the current user has joined the group.
 *  `!isDeleted && isGroup && isJoined`
 * 3. If it's a pinned chat and it's a one-on-one peer contact, then show up in Active Chats
 *  irrespective of whether it's a deleted contact or not.
 * @param {Contact} contact - The contact object
 */
const filterDeletedContacts = ({
  isGroup,
  isBuddy,
  isJoined,
  isDeleted,
  pinned,
}) =>
  (!isDeleted && isBuddy) ||
  /* Workaround */ (!isDeleted && isGroup && isJoined) ||
  (isBuddy && pinned);

const MENTION_TYPES = {
  ALL: 'all',
  ONLINE: 'online',
};
function getBuckets(
  openConversations,
  pinsOrder,
  allContacts,
  allMessages,
  unreadMsgIds
) {
  const activeChats = map(openConversations, (conversation) => {
    const { jid } = conversation;
    let hasMention = false;
    const contact = allContacts[jid] || {};
    let unreadMessages = defaultUnreadMessages;
    const { notifyOn } = contact;
    const unreadMsgIdsForPeer = unreadMsgIds[jid];
    if (allMessages) {
      if (unreadMsgIdsForPeer && unreadMsgIdsForPeer.length > 0) {
        unreadMessages = unreadMsgIdsForPeer
          .map((msgId) => allMessages[msgId])
          .filter((message) => message && addsToCount(message));
        hasMention = unreadMessages.some(({ messageElements }) => {
          if (checkGroup(jid)) {
            if (notifyOn === NOTIFICATION_TYPES.DIRECT_MENTION) {
              return messageElements?.mentions?.some(
                ({ type, mentionsMe }) =>
                  mentionsMe &&
                  (type !== MENTION_TYPES.ALL || type !== MENTION_TYPES.ONLINE)
              );
            }
            return messageElements?.mentions?.some(
              ({ mentionsMe }) => mentionsMe
            );
          }
          return false;
        });
      }
    }
    return {
      ...conversation,
      ...contact,
      hasMention,
      unreadCount: unreadMessages?.length || 0,
    };
  }).filter(filterDeletedContacts);

  const conversationBuckets = groupBy(
    activeChats,
    ({ pinned, isMuted, hasMention }) => {
      if (pinned) {
        return PINNED;
      }
      if (isMuted && !hasMention) {
        return MUTED;
      }
      return OPEN;
    }
  );
  const {
    pinned = defaultBucket,
    open = defaultBucket,
    muted = defaultBucket,
  } = conversationBuckets;
  conversationBuckets.open = open.sort(
    (contact1, contact2) => contact2.lastMessageTime - contact1.lastMessageTime
  );
  conversationBuckets.muted = muted.sort(
    (contact1, contact2) => contact2.lastMessageTime - contact1.lastMessageTime
  );
  if (pinned?.length > 0) {
    conversationBuckets.pinned = getPinnedChats(pinned, pinsOrder);
  } else {
    conversationBuckets.pinned = defaultBucket;
  }
  return conversationBuckets;
}

const memoizedObject = getMemoizedObject();

const openContactsByGuidSelector = createSelector(
  openConversationsByGuidSelector,
  contactsByGuidSelector,
  (getOpenConversationsByGuid, getContactsByGuid) => (ownerGuid) => {
    const openConversations = getOpenConversationsByGuid(ownerGuid);
    const allContacts = getContactsByGuid(ownerGuid);
    const contacts = {};
    Object.keys(openConversations).forEach((jid) => {
      const c = allContacts[jid];
      if (c) {
        contacts[jid] = c;
      }
    });

    return memoizedObject(contacts);
  }
);

const memoizedObject2 = getMemoizedObject();

const unreadMessagesByGuidSelector = createSelector(
  openConversationsByGuidSelector,
  allUnreadMessagesByGuidSelector,
  allMessagesByGuidSelector,
  (
    getOpenConversationsByGuid,
    getAllUnreadMessagesByGuid,
    getAllMessagesByGuid
  ) => (ownerGuid) => {
    const allMessages = getAllMessagesByGuid(ownerGuid);
    const openConversations = getOpenConversationsByGuid(ownerGuid);
    const unreadMessages = getAllUnreadMessagesByGuid(ownerGuid);
    const messages = {};

    Object.keys(openConversations).forEach((jid) => {
      const unreadMsgsForPeer = unreadMessages[jid];
      if (!unreadMsgsForPeer) return;
      unreadMsgsForPeer.forEach((mcId) => {
        if (allMessages[mcId]) {
          messages[mcId] = allMessages[mcId];
        }
      });
    });

    return memoizedObject2(messages);
  }
);

const getMemoizedBucket = memoizeOne(getBuckets);

export const conversationBucketsByGuidSelector = createSelector(
  openConversationsByGuidSelector,
  pinsOrderByGuidSelector,
  openContactsByGuidSelector,
  unreadMessagesByGuidSelector,
  allUnreadMessagesByGuidSelector,
  (
    getOpenConversationsByGuid,
    getPinsOrderByGuid,
    getOpenContactsByGuid,
    getUnreadMessagesByGuid,
    getAllUnreadMessagesByGuid
  ) => (ownerGuid) =>
    getMemoizedBucket(
      getOpenConversationsByGuid(ownerGuid),
      getPinsOrderByGuid(ownerGuid),
      getOpenContactsByGuid(ownerGuid),
      getUnreadMessagesByGuid(ownerGuid),
      getAllUnreadMessagesByGuid(ownerGuid)
    )
);

export default createSelector(
  ownerGuidSelector,
  conversationBucketsByGuidSelector,
  (ownerGuid, getConversationBucketsByGuid) =>
    getConversationBucketsByGuid(ownerGuid)
);
