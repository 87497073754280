import { useState, useEffect, useCallback } from 'react';
import bridge from '@/utils/bridge';

function useSoundPreferences() {
  const [selectedSound, setSelectedSound] = useState(null);
  const [sounds, setSounds] = useState({});

  useEffect(() => {
    bridge
      .ask('NotificationController', 'getAllSounds')
      .then((allSounds) => setSounds(allSounds));
  }, []);

  useEffect(() => {
    bridge
      .ask('NotificationController', 'getSoundPreferenceId')
      .then((userSelectedId) => {
        setSelectedSound(userSelectedId);
      });
  }, []);

  const changeSelectedSound = useCallback(
    (id: string | number) =>
      bridge
        .ask('NotificationController', 'setSoundPreference', [id])
        .then(() => {
          setSelectedSound(id);
        }),
    []
  );

  const previewSound = useCallback(
    (id: string) => bridge.tell('HydraServer', 'playAudio', [sounds[id].url]),
    [sounds]
  );

  return { sounds, selectedSound, changeSelectedSound, previewSound };
}

export default useSoundPreferences;
