import { createSelector } from 'reselect';

const defaultMessageAnchor = {};

export default createSelector(
  (state) => state.restricted.messageAnchor,
  (_, { ownerGuid, currentPeerId, currentPeer }) => ({
    ownerGuid,
    currentPeerId,
    currentPeer,
  }),
  (messageAnchorStore, { ownerGuid, currentPeerId, currentPeer }) =>
    messageAnchorStore?.[ownerGuid]?.[currentPeerId || currentPeer?.jid] ||
    defaultMessageAnchor
);
