class BridgeUtil {
  getBridge() {
    return new Promise((resolve) => {
      if (this.initialized) {
        resolve(window.bridge);
        return;
      }
      window.addEventListener(
        'bridgeInitialized',
        () => {
          this.initialized = true;
          resolve(window.bridge);
        },
        false
      );
    });
  }

  async ask(...params) {
    const bridge = await this.getBridge();
    return bridge.ask(...params);
  }

  async tell(...params) {
    const bridge = await this.getBridge();
    return bridge.tell(...params);
  }

  async publish(...params) {
    const bridge = await this.getBridge();
    return bridge.publish(...params);
  }

  async subscribe(...params) {
    const bridge = await this.getBridge();
    return bridge.subscribe(...params);
  }

  async unsubscribe(...params) {
    const bridge = await this.getBridge();
    return bridge.unsubscribe(...params);
  }

  async register(...params) {
    const bridge = await this.getBridge();
    return bridge.register(...params);
  }

  async unsubscribeAll(subscriptionPromises) {
    const bridge = await this.getBridge();
    return subscriptionPromises.forEach((promise) =>
      promise.then((sub) => bridge.unsubscribe(sub))
    );
  }
}

export default new BridgeUtil();
