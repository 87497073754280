import React from 'react';
import { Provider } from 'react-redux';
import Modal from '@/components/Modal/Modal';
import SearchApp from '@/components/SearchApp';
import Preferences from '@/components/appMenu/Preferences';
import Shell from '../shell';

const Root = ({ store }) => (
  <Provider store={store}>
    <Shell />
    <Modal />
    <SearchApp />
    <Preferences />
  </Provider>
);

export default Root;
