import bridge from '@/utils/bridge';
import { SIDEBAR_CATEGORY } from '@/utils/customApps';
import { SORTABLE_STORE } from '@/utils/storeNames';
import Subscriber from './subscriber';

/** Each subscriber needs to have state selector and a callback
 * that takes in the new state and does whatever you need to do.
 */

const SESSION_ORDER_KEY = SIDEBAR_CATEGORY;

/** Returns an object with selector function for appList(`selectState`)
 * and what to do when appList changes (`notify` Function)
 */
class AppSubscriber extends Subscriber {
  /**
   * The state selector function
   * @param {Object} state from redux store
   * @returns the selected state
   */
  selectState = ({ restricted }) => {
    if (restricted && restricted._session) {
      const { currentOwnerGuid, currentSessionId } = restricted._session;
      return {
        appList: restricted.dike[currentOwnerGuid] || {},
        currentSessionId,
      };
    }
    return null;
  };

  /**
   * The `notify` function
   * @param {Object} selectedState - Output of the `selectState` function above.
   * @returns Nothing. Can return a promise if it can be handled.
   */
  notify = ({ appList, currentSessionId }) => {
    if (appList && currentSessionId) {
      /* Important to compare type and truthfulness of the selected state */
      if (appList.appsOrder) {
        const orderedAppIds = appList.appsOrder;
        bridge.tell('Storage', 'set', [
          SORTABLE_STORE,
          `${SESSION_ORDER_KEY}_${currentSessionId}`,
          orderedAppIds.join('|'),
        ]);
      }
    }
  };
}

export default new AppSubscriber('apps');
