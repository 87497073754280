import bridge from '@/utils/bridge';
import store from '@/store';
import { Actions } from '@/actions/restricted';
import { getQueuedDispatch } from './utilities';

const dispatch = getQueuedDispatch(
  store.dispatch,
  'Team Privileges proxy',
  50,
  20
);

class TeamPrivilegesProxy {
  constructor() {
    bridge.subscribe('odara/teamPrivileges', this.handleUpdate);
    this.getPrivilegesFromHydra();
  }

  getPrivilegesFromHydra = () => {
    bridge
      .ask('TeamPrivilegesSynchronizer', 'getAllTeamsPrivileges')
      .then((allConfigs) => {
        Object.keys(allConfigs).forEach((key) => {
          this.handleUpdate(key, allConfigs[key]);
        });
      });
  };

  handleUpdate = (guid, config) => {
    dispatch(
      Actions.update_team_privileges({
        ownerGuid: guid,
        config,
      })
    );
  };
}

export default new TeamPrivilegesProxy();
