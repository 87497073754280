/* eslint-disable radix */
import React, { Component, Fragment } from 'react';
import Tippy from '@tippyjs/react';
import bridge from '@/utils/bridge';
import { PRESENCE } from '@/utils/constants';
import css from './LastSeen.css';

const toolTipTexts = {
  [PRESENCE.ONLINE]: 'Active on Flock during the last 60 minutes',
  [PRESENCE.AWAY]: 'Not active on Flock during the last 60 minutes',
  [PRESENCE.UNREACHABLE]: 'Seems to be unreachable at the moment',
  [PRESENCE.OFFLINE]: "Hasn't joined the team yet",
};

/*
  Displays status of user for 1-1 conversation. 
*/

function subscribeToLastSeen(peer) {
  console.log('Subscribing to last seen', peer.jid, peer.ownerGuid);
  bridge.tell('LastSeenAt', 'subscribeLastSeen', [peer.jid, peer.ownerGuid]);
}
function unsubscribeLastSeen() {
  console.log('Un- Subscribing to last seen');
  bridge.tell('LastSeenAt', 'unsubscribeLastSeen');
}

const SEC_IN_DAY = 24 * 3600;
const SEC_IN_2_DAYS = 2 * 24 * 3600;
// const HALF_SECOND = 30 * 1000;
function getTimeAgo(lastSeenAt) {
  const timeInSeconds = (Date.now() - lastSeenAt) / 1000;

  if (timeInSeconds < 60) return 'Online';
  if (timeInSeconds >= 60 && timeInSeconds < 3600) {
    const min = parseInt(timeInSeconds / 60);
    if (min === 1) return `${min} min`;
    return `${min} min`;
  }
  if (timeInSeconds >= 3600 && timeInSeconds < SEC_IN_DAY) {
    const hours = parseInt(timeInSeconds / 3600);
    if (hours === 1) return `${hours} hr`;
    return `${hours} hr`;
  }
  if (timeInSeconds >= SEC_IN_DAY && timeInSeconds < SEC_IN_2_DAYS) {
    const days = parseInt(timeInSeconds / SEC_IN_DAY);
    if (days === 1) return `${days} day`;
    return `${days} day`;
  }
  // need to convert the string by selected language
  const date = new Date(Date.now() - timeInSeconds * 1000)
    .toDateString()
    .split(' ');
  const returnFormat = `${date[2]} ${date[1]}`;

  return returnFormat;
}

const initialState = {
  time: undefined,
  device: undefined,
};

class LastSeen extends Component {
  static defaultProps = {
    peer: undefined,
  };

  state = initialState;

  subscriptions = [];

  constructor(props) {
    super();
    this.subscriptions.push(
      bridge.subscribe('lastSeen/update', this.updateLastSeen)
    );
    const { peer } = props;
    if (peer && !peer.isBot && !peer.isGroup) {
      subscribeToLastSeen(peer);
    }
  }

  componentDidUpdate(prevProps) {
    const { peer: { jid: prevJid } = {} } = prevProps;
    const { peer = {} } = this.props;
    const { jid } = peer;

    if (prevJid !== jid && !peer.isBot && !peer.isGroup) {
      this.setState(initialState);
      unsubscribeLastSeen();
      subscribeToLastSeen(peer);
    }
  }

  componentWillUnmount() {
    unsubscribeLastSeen();
    bridge.unsubscribeAll(this.subscriptions);
  }

  updateLastSeen = (jid, lastSeen) => {
    console.log('CONV/HEADER/LASTSEEN', jid, lastSeen);
    const { lastSeenTime: time, device } = lastSeen;
    const {
      peer: { jid: peerJid },
    } = this.props;
    if (jid !== peerJid) {
      return;
    }
    this.setState({
      time,
      device,
    });
  };

  render() {
    const { time, device } = this.state;
    const { peer } = this.props;
    if (!peer || peer.type === 'group' || peer.isBot) {
      return null;
    }

    const toolTip = toolTipTexts[peer.presence];

    return (
      <Tippy content={toolTip} maxWidth={200} arrow disabled={!toolTip}>
        <Fragment>
          {device !== undefined ? (
            <span className={`${css[device]} ${css.device}`} />
          ) : null}
          {time && time > 0 ? (
            <span className={css.lastSeenTime}>{getTimeAgo(time)}</span>
          ) : null}
        </Fragment>
      </Tippy>
    );
  }
}

export default LastSeen;
