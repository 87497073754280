import bridge from '@/utils/bridge';

/**
 *
 * @param {Object} peer Marks this peer as read
 */
export default function markLatestMessageAsRead(peer) {
  if (peer && peer.lastMessageTime) {
    console.log('reading latest message', peer.jid);
    return bridge.tell('MessageCache', 'markMessagesRead', [peer]);
  }
  return Promise.resolve('You did not send the correct peer object.');
}
