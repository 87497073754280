// Actions are payloads of informations that are used to modify the
// application's Redux state. They may also act as signals and information
// payloads to sagas when asynchronous operations need to occur. This app uses
// [Flux Standard Actions](https://github.com/acdlite/flux-standard-action) to
// make sure actions have a uniform schema.
//
// http://redux.js.org/docs/basics/Actions.html
import { createAction } from 'redux-actions';
import snakeCase from 'lodash/snakeCase';
import { upperSnakeCase } from '../utils';

// Create load action type variants with get, loading and loading prefixed
function load(resourceName) {
  return [
    `get ${resourceName}`,
    `loading ${resourceName}`,
    `loaded ${resourceName}`,
  ];
}

// Create multiple load action types (get, loading and loading)
function loadAll(...names) {
  return [].concat(...names.map(load));
}

// Create load action type variants with do, doing and done prefixed
function doit(resourceName) {
  return [
    `do ${resourceName}`,
    `doing ${resourceName}`,
    `done ${resourceName}`,
  ];
}

// Create multiple load action types (do, doing and done)
function doAll(...names) {
  return [].concat(...names.map(doit));
}

// Uper snake case names
function normalize(...names) {
  return names.map(upperSnakeCase);
}

// The action type names for this app's Redux/Flux actions. Used to generate
// `ActionTypes` and `Actions` used throughout the app.
// actionName -> ActionType ('action name' -> 'ACTION_NAME')
// actionName -> ActionCreator ('action name' -> 'actionName')
const actionNames = normalize(
  // Data load resource actions
  ...loadAll('sessions'),
  ...doAll(),
  'set_current_theme',
  'session_proxy',
  'init_chats',
  'init_pins',
  'add_pinned_chats',
  'remove_pinned_chats',
  'start_chat',
  'remove_chat',
  'open_channel_feed',
  'pins_order_changed',
  'add_latest_message_id',
  'add_latest_message_ids',
  'remove_latest_message_ids',
  'contact_proxy',
  'update_all_messages',
  'update_unread_messages',
  'delete_unread_message',
  'unread_messages_reset_peer',
  'unread_messages_reset_team',
  'add_to_all_sessions',
  'remove_from_all_sessions',
  'clear_all_sessions',
  'change_session',
  'all_buddies_meta_proxy',
  'all_groups_meta_proxy',
  'recent_contacts_meta_proxy',
  'update_conversation_associates',
  'selected_contacts_in_search',
  'outerleft_layout_changed',
  'app_list_proxy',
  'app_list_badges',
  'apps_order',
  'update_chat_state',
  'remove_chat_state',
  'update_disconnected_once',
  'add_new_editor_notification',
  'remove_oldest_editor_notification',
  'remove_all_editor_notifications',
  'update_editor_state',
  'update_receipt_state',
  'mark_unread_messages_as_read',
  'update_team_privileges',
  'update_message_anchor'
);

// The app's redux action types. Each key is the same as the string action type.
// For each action name, the type is generated by upper snake-casing the phrase.
// assert.equal(ActionTypes.EAT_CAKE, 'EAT_CAKE');
export const ActionTypes = actionNames.reduce((actionTypes, actionName) => {
  const newActionTypes = actionTypes;
  newActionTypes[actionName] = actionName;
  return newActionTypes;
}, {});

// Action creators for the app (functions that return Redux/Flux actions).
// let action = Actions.wingardiumLeviosa('ferret');
// assert.deepEqual(action, { type: 'WINGARDIUM_LEVIOSA', payload: 'ferret' });
export const Actions = actionNames.reduce((actions, actionName) => {
  const newActions = actions;
  const actionCreatorName = snakeCase(actionName);
  newActions[actionCreatorName] = createAction(
    actionName,
    (x) => x,
    (x, y) => y
  );
  return newActions;
}, {});
