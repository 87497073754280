import React, { PureComponent } from 'react';
import isEqual from 'lodash/isEqual';
import { combineEvents } from '@/utils';
import noop from '@/utils/noop';
import AttachmentPicker from '../AttachmentPicker';
import EmojiButton from '../EmojiButton';
import FilePicker from '../FilePicker';
import EditorSwitcher from '../EditorSwitcher/EditorSwitcher';
import MessageDecorator from '../MessageDecorator';
import { getEditorData } from '../FroalaUtils/FroalaEditorUtils';
import { getHtmlContentWithMarkers } from '../FroalaUtils/SelectionUtils';
import { getTokenizedMentions } from '../FroalaUtils/MentionsUtils';
import { getTokenizedEmojis } from '../FroalaUtils/EmojiUtils';
import { sanitizeText } from '../FlockmlUtils';
import FroalaEditor from '../FroalaEditor';
import {
  Noop,
  NoopEventHandler,
  NoopKeyboardEventHandler,
  FroalaEditorType,
  Peer,
  CurrentSession,
  Owner,
  InsertTextOptions,
} from '../Types';
import { EDITOR_TYPES, MENTION_TYPES, EMOJI } from '../constants';
import css from './BaseEditor.css';

const { CLASSIC, BALLOON, TEMPORARY_BALLOON } = EDITOR_TYPES;

type ContentPropertiesType = {
  htmlContent?: string;
  textContent?: string;
  selectionStart?: number;
  selectionEnd?: number;
  keepTextAreaFocused?: boolean;
};
type ParentStateType = {
  messageObjectUpdates?: Function[];
  contactParamUdpates?: Function[];
} & ContentPropertiesType;

type Props = {
  htmlContent: string;
  textContent: string;
  selectionStart: number;
  selectionEnd: number;
  keepTextAreaFocused: boolean;
  messageObjectUpdates: Function[];
  contactParamUdpates: Function[];
  owner: Owner;
  peer: Peer;
  currentSession: CurrentSession;
  placeholder: string;
  textAreaClassName: string;
  showAttachmentPicker: boolean;
  isEditMessage: boolean;
  allowedDecorators: string[];

  onInput: (
    textContent: string,
    selectionStart: number,
    selectionEnd: number
  ) => void;
  onChange: (parentStateType: ParentStateType) => void;
  onEnter: NoopKeyboardEventHandler;
  onUpArrow?: NoopKeyboardEventHandler;
  onEscape?: NoopKeyboardEventHandler;
  onPaste: NoopEventHandler;
  onFocus: Noop;
  onBlur: Noop;
  setFocus: Noop;
  sendSticker: (sticker: any) => void;
  registerEditorUtility: (name: string, util: Function) => Function;
} & typeof BaseEditor.defaultProps;

type State = {
  editorType: string | null;
  showEditorSwitcher: boolean;
  decoratorProps: Object;
};

const defaultDecoratorProps = {};

class BaseEditor extends PureComponent<Props, State> {
  static defaultProps = {
    htmlContent: '',
    textContent: '',
    selectionStart: 0,
    selectionEnd: 0,
    keepTextAreaFocused: true,
    messageObjectUpdates: [] as Function[],
    contactParamUdpates: [] as Function[],
    owner: undefined as Owner,
    peer: undefined as Peer,
    currentSession: undefined as CurrentSession,
    placeholder: '',
    textAreaClassName: '',
    showAttachmentPicker: false,
    isEditMessage: false,
    allowedDecorators: [] as string[],

    onInput: noop as (
      textContent: string,
      selectionStart: number,
      selectionEnd: number
    ) => void,
    onChange: noop as (parentStateType: ParentStateType) => void,
    onEnter: noop as NoopKeyboardEventHandler,
    onPaste: noop as NoopEventHandler,
    onFocus: noop as Noop,
    onBlur: noop as Noop,
    setFocus: noop as Noop,
    sendSticker: noop as (sticker: any) => void,
    registerEditorUtility: (name: string, util: Function) => () => {},
  };

  state = {
    editorType: null,
    showEditorSwitcher: true,
    decoratorProps: defaultDecoratorProps,
  };

  eventListeners: Object = {};

  editor: FroalaEditorType = null;

  toolbarRef = React.createRef<HTMLDivElement>();

  // this ref is needed to dispatch cmd+k keyboard event to golbal listener. because FroalaEditor's default handler will stop propagation of cmd+k event because it reserved in FroalaEditor for link command.
  baseEditorRef = React.createRef<HTMLDivElement>();

  filePickerShortcut: Function = noop; // this variable stores function to be executed on Cmd+U shortcut which is to open file picker.

  MIN_WIDTH: number; // this is the minimum width needed for classic editor. After that classic editor will be converted to balloon.

  baseEditorResizeObserver: any; // to maintain only one instance of resize observer

  unregisterUtilities: Array<Function> = []; // to unregister utilites already registered

  /* toolbar ref is dom node where toolbar of classic editor will be inserted. If it is balloon editor
  then editor itself will be inserted at toolbar node. */
  getToolbarRef = () => {
    return this.toolbarRef.current;
  };

  toggleEditor = () => {
    const { editorType } = this.state;
    const newEditorType = editorType === CLASSIC ? BALLOON : CLASSIC;
    this.setEditorType(newEditorType);
  };

  setMinWidth = () => {
    const { isEditMessage } = this.props;
    this.MIN_WIDTH = isEditMessage ? 370 : 360;
  };

  componentDidMount() {
    const { isEditMessage } = this.props;
    const editorTypeInLocalStorage = localStorage.getItem('editorType');
    let editorType = BALLOON;
    if (
      isEditMessage ||
      !editorTypeInLocalStorage ||
      editorTypeInLocalStorage === CLASSIC ||
      editorTypeInLocalStorage === TEMPORARY_BALLOON
    ) {
      editorType = CLASSIC;
    }
    this.setMinWidth();
    this.setResponsiveEditorType(editorType);
  }

  setBaseEditorResizeObserver = () => {
    this.baseEditorResizeObserver = new ResizeObserver(
      this.resizeObserverCallback
    );
    this.baseEditorResizeObserver.observe(this.baseEditorRef.current);
  };

  resizeObserverCallback = () => {
    const { editorType } = this.state;
    this.setResponsiveEditorType(editorType);
  };

  setResponsiveEditorType = (editorType: string) => {
    /* eslint-disable prefer-destructuring, no-lonely-if */
    if (!this.baseEditorRef.current || !editorType) return;
    const clientWidth = this.baseEditorRef.current.clientWidth;

    let newEditorType = editorType;
    if (clientWidth <= this.MIN_WIDTH) {
      if (editorType !== BALLOON) {
        newEditorType = TEMPORARY_BALLOON;
      } else {
        this.setState({ showEditorSwitcher: false });
      }
    } else {
      if (editorType === TEMPORARY_BALLOON) {
        newEditorType = CLASSIC;
      } else if (editorType === BALLOON) {
        this.setState({ showEditorSwitcher: true });
      }
    }
    this.setEditorType(newEditorType);
    /* eslint-enable prefer-destructuring, no-lonely-if */
  };

  setEditorType = (newEditorType: string) => {
    const { editorType } = this.state;
    const { isEditMessage } = this.props;
    if (editorType !== newEditorType) {
      const showEditorSwitcher = !(newEditorType === TEMPORARY_BALLOON);
      this.setState({
        editorType: newEditorType,
        showEditorSwitcher,
      });
      if (!isEditMessage) {
        localStorage.setItem('editorType', newEditorType);
      }
    }
  };

  onChange = ({
    updateMessageObjFn,
    contactParamUdpateFn,
    ...contentProperties
  }: {
    updateMessageObjFn?: Function | undefined;
    contactParamUdpateFn?: Function | undefined;
  } & ContentPropertiesType) => {
    const { messageObjectUpdates, contactParamUdpates, onChange } = this.props;
    const newState = { ...contentProperties } as ParentStateType;

    let _messageObjectUpdates = updateMessageObjFn
      ? [...messageObjectUpdates, updateMessageObjFn]
      : messageObjectUpdates;

    let _contactParamUdpates = contactParamUdpateFn
      ? [...contactParamUdpates, contactParamUdpateFn]
      : contactParamUdpates;

    const shouldReset =
      contentProperties.textContent &&
      contentProperties.textContent.length === 0;
    _messageObjectUpdates = shouldReset ? [] : _messageObjectUpdates;

    _contactParamUdpates = shouldReset ? [] : _contactParamUdpates;

    if (updateMessageObjFn || shouldReset)
      newState.messageObjectUpdates = _messageObjectUpdates;
    if (contactParamUdpateFn || shouldReset)
      newState.contactParamUdpates = _contactParamUdpates;

    onChange(newState);
  };

  setDecoratorProps = (newDecoratorProps = defaultDecoratorProps) => {
    const { decoratorProps } = this.state;
    if (!isEqual(decoratorProps, newDecoratorProps)) {
      this.setState({
        decoratorProps: newDecoratorProps,
      });
    }
  };

  updateDecoratorProps = (decoratorProps: Object) => {
    this.setDecoratorProps(decoratorProps);
    return null;
  };

  setFilePickerShortcutFunction = (fn: Function) => {
    this.filePickerShortcut = fn;
  };

  getCombinedListener = (eventName: string) => {
    return (e) => {
      const { decoratorProps } = this.state;
      const decoratorFunction = decoratorProps[eventName];
      /* eslint-disable-next-line react/destructuring-assignment */
      const textAreaFunction = this.props[eventName];
      const combinedFunction = combineEvents([
        decoratorFunction,
        textAreaFunction,
      ]);
      return combinedFunction(e);
    };
  };

  getEventListener = (eventName: string) => {
    if (!this.eventListeners[eventName]) {
      this.eventListeners[eventName] = this.getCombinedListener(eventName);
    }
    return this.eventListeners[eventName];
  };

  insertEmoji = (emoji) => {
    const { textContent, selectionStart, selectionEnd } = this.props;
    let nativeEmojiWithPadding = ` ${emoji.native} `;
    const startingText = textContent.slice(0, selectionStart);

    if (
      startingText.length === 0 ||
      /\s/.test(startingText[startingText.length - 1])
    ) {
      nativeEmojiWithPadding = nativeEmojiWithPadding.trimLeft();
    }
    this.insertText(
      nativeEmojiWithPadding,
      selectionStart,
      selectionEnd,
      undefined,
      undefined,
      { insertDirectly: true, insertType: EMOJI }
    );
  };

  onCopy = (e) => {
    // Need stop propagation because for edit Message composer copy is handled by messageList which copies only plain text
    e.stopPropagation();
  };

  // ----------------FroalaEditor Functionalities ----------------------------------------------

  initializeEditor = () => {
    this.setupModelEvents();
    this.setupViewEvents();
    this.setupCommands();
    // this.onEditorChange();
    this.registerUtilities();
    this.setBaseEditorResizeObserver();
  };

  setEditor = (editor) => {
    this.editor = editor;
    this.initializeEditor();
  };

  setupModelEvents = () => {
    this.editor.events.on('contentChanged', () => {
      this.onEditorChange();
    });
    this.editor.events.on('customContentChanged', () => {
      this.onEditorChange();
    });
  };

  setupViewEvents = () => {
    this.editor.events.on(
      'paste.before',
      (event) => {
        const { onPaste } = this.props;
        onPaste(event);
      },
      true
    );
  };

  setupCommands = () => {
    // link and search contact shortcut
    this.editor.registerShortcut(
      this.editor.FROALA.KEYCODE.K,
      false,
      false,
      () => {
        if (this.editor && this.editor.selection.isCollapsed()) {
          return false; // don't open inertLink popup if there is no selection in editor
        }
        return true;
      }
    );

    // filePicker shortcut
    this.editor.registerShortcut(
      this.editor.FROALA.KEYCODE.O,
      false,
      false,
      this.openFilePicker
    );
  };

  openFilePicker = (event: KeyboardEvent) => {
    this.filePickerShortcut?.();
    event.preventDefault();
  };

  modifyCurrentSelection = (start: number) => {
    const { selectionStart } = this.props;
    let difference = selectionStart - start;
    while (difference > 0) {
      this.editor.cursor.backspace();
      difference -= 1;
    }
  };

  insertText = (
    textToBeAdded: string,
    start: number,
    end: number,
    updateMessageObjFn?: Function,
    contactParamUdpateFn?: Function,
    options: InsertTextOptions = {}
  ) => {
    const { insertDirectly, insertType, contact } = options;
    if (updateMessageObjFn || contactParamUdpateFn) {
      this.onChange({ updateMessageObjFn, contactParamUdpateFn });
    }
    if (!this.editor.core.hasFocus()) {
      // if editor is not in focus then it will enter text at the end that is why we need to focus editor first
      this.editor.events.disableBlur();
      this.editor.events.focus();
      this.editor.events.enableBlur();
      this.editor.selection.restore();
    }
    if (!insertDirectly) {
      this.modifyCurrentSelection(start);
    }
    this.editor.commands.clearFormatting();
    if (insertType === EMOJI) {
      this.insertEmojiAsImage(textToBeAdded);
    } else if (MENTION_TYPES.includes(insertType)) {
      this.insertMention(contact, insertType);
    } else {
      this.insertPlainText(textToBeAdded);
    }
    this.onEditorChange();
  };

  insertPlainText = (textToBeAdded: string) => {
    this.editor.html.insert(sanitizeText(textToBeAdded), false);
  };

  insertEmojiAsImage = (text: string): void => {
    const htmlArray = getTokenizedEmojis(text);
    htmlArray.forEach((html: string) => {
      this.editor.html.insert(html, true);
    });
    this.editor.selection.save();
    setTimeout(this.editor.selection.restore);
  };

  insertMention = (contact: Contact, mentionType: string) => {
    const htmlArray = getTokenizedMentions(
      contact,
      mentionType,
      this.editor.FROALA.MARKERS
    );
    htmlArray.forEach((html: string) => {
      this.editor.html.insert(html, true);
    });
  };

  onEditorChange = () => {
    const {
      onInput,
      htmlContent: prevHtmlContent,
      textContent: prevTextContent,
      selectionStart: prevSelectionStart,
      selectionEnd: prevSelectionEnd,
    } = this.props;

    const {
      htmlContent,
      textContent,
      selectionStart,
      selectionEnd,
    } = getEditorData(this.editor);

    if (
      htmlContent !== prevHtmlContent ||
      textContent !== prevTextContent ||
      selectionStart !== prevSelectionStart ||
      selectionEnd !== prevSelectionEnd
    ) {
      this.onChange({
        textContent,
        selectionStart,
        selectionEnd,
        htmlContent,
      });
      onInput(textContent, selectionStart, selectionEnd);
    }
  };

  _getHtmlContentWithMarkers = () => {
    return getHtmlContentWithMarkers(this.editor);
  };

  registerUtilities = () => {
    const { registerEditorUtility } = this.props;
    // register this utility to parent and whenever parent wants to store html content to redux store it should store it with selection marker so that it can be restored easily.
    const unregisterUtility = registerEditorUtility(
      'getHtmlContentWithMarkers',
      this._getHtmlContentWithMarkers
    );
    this.unregisterUtilities.push(unregisterUtility);
  };

  cleanup = () => {
    this.unregisterUtilities.forEach((unregister: Function) => {
      unregister();
    });
    this.unregisterUtilities = [];
  };

  onDestroy = () => {
    this.editor = null;
    this.cleanup();
    if (this.baseEditorResizeObserver) {
      this.baseEditorResizeObserver?.disconnect?.();
    }
    this.baseEditorResizeObserver = null;
  };
  // --------------------------------------------------------------------------------------

  render() {
    const {
      htmlContent,
      textContent,
      selectionStart,
      selectionEnd,
      keepTextAreaFocused,
      owner,
      peer,
      currentSession,
      placeholder,
      textAreaClassName,
      showAttachmentPicker,
      isEditMessage,
      allowedDecorators,
      onFocus,
      onBlur,
      setFocus,
      sendSticker,
    } = this.props;

    const { editorType, showEditorSwitcher } = this.state;
    const toolbarId = `toolbar${isEditMessage ? '-edit' : ''}`; // this is needed to give different ids to both edit message composer and conversation editor
    const balloonEditorClass =
      editorType !== CLASSIC ? css.balloonContainer : '';
    const extraPaddingClass =
      editorType !== CLASSIC ? css.containerPadding : '';
    const rightContainerInBalloonEditor =
      editorType !== CLASSIC ? css.rightContainerInBalloonEditor : '';
    const editMessageToolbarClass = isEditMessage ? css.editMessageToolbar : '';
    const editMessageContainerClass = isEditMessage
      ? css.editMessageContainer
      : '';

    return (
      <div
        className={css.messageAreaWrapper}
        ref={this.baseEditorRef}
        onCopy={this.onCopy}
      >
        <MessageDecorator
          textContent={textContent}
          selectionStart={selectionStart}
          selectionEnd={selectionEnd}
          isEditorFocused={keepTextAreaFocused}
          owner={owner}
          peer={peer}
          allowedDecorators={allowedDecorators}
          insertText={this.insertText}
        >
          {this.updateDecoratorProps}
        </MessageDecorator>
        <FroalaEditor
          className={`${css.TextArea} ${textAreaClassName}`}
          isEditMessage={isEditMessage}
          editorType={editorType}
          htmlContent={htmlContent}
          textContent={textContent}
          selectionStart={selectionStart}
          selectionEnd={selectionEnd}
          keepFocus={keepTextAreaFocused}
          placeholder={placeholder}
          onInit={this.setEditor}
          onEnter={this.getEventListener('onEnter')}
          onUpArrow={this.getEventListener('onUpArrow')}
          onEscape={this.getEventListener('onEscape')}
          onDownArrow={this.getEventListener('onDownArrow')}
          onSpace={this.getEventListener('onSpace')}
          onTab={this.getEventListener('onTab')}
          onDestroy={this.onDestroy}
          onFocus={onFocus}
          onBlur={onBlur}
          getToolbarRef={this.getToolbarRef}
        />
        <span
          className={`${css.container} ${balloonEditorClass} ${editMessageContainerClass}`}
        >
          {!isEditMessage && showAttachmentPicker ? (
            <span className={`${css.leftContainer} ${extraPaddingClass}`}>
              <AttachmentPicker peer={peer} onClose={setFocus} />
              <div className={css.verticalLine} />
            </span>
          ) : null}
          <div
            ref={this.toolbarRef}
            className={`${css.toolbar} ${editMessageToolbarClass}`}
            id={toolbarId}
          />
          {!isEditMessage ? (
            <span
              className={`${css.rightContainer} ${extraPaddingClass} ${rightContainerInBalloonEditor}`}
            >
              {showEditorSwitcher ? (
                <EditorSwitcher
                  toggleEditor={this.toggleEditor}
                  editorType={editorType}
                />
              ) : null}
              <EmojiButton
                onEmoji={this.insertEmoji}
                onSticker={sendSticker}
                onClose={onFocus}
              />
              <FilePicker
                peer={peer}
                currentSession={currentSession}
                setFilePickerShortcutFunction={
                  this.setFilePickerShortcutFunction
                }
              />
            </span>
          ) : null}
        </span>
      </div>
    );
  }
}

export default BaseEditor;
