import { createSelector } from 'reselect';
import { batch } from 'react-redux';
import difference from 'lodash/difference';
import bridge from '@/utils/bridge';
import store from '@/store';
import { Actions } from '@/actions/restricted';
import { markLatestMessageAsRead } from '@/utils/message';
import { getCounter } from './utilities';
import MessageProxy from './MessageProxy';
import ReceiptProxy from './ReceiptProxy';

class UnreadMessagesProxy {
  counter = getCounter();

  messageProxySubscriptions = {};

  selectors = {};

  subscribedIds = {};

  constructor() {
    bridge.subscribe('messagecache-newmessagesbatch', this.onNewMessagesbatch);
    bridge.subscribe('/pw/messagecache/messageupdate', this.onNewMessage);
    bridge.subscribe('/pw/messagecache/deletedMessage', this.onDeleteMessage);
    bridge.subscribe('/pw/messagecache/resetMessages', this.onResetMessage);
    store.subscribe(() => {
      const {
        restricted: {
          message: { unread_messages: unreadMessages },
        },
      } = store.getState();
      const guids = Object.keys(unreadMessages);

      difference(Object.keys(this.selectors), guids).forEach((ownerGuid) => {
        // cleanup cached selectors
        this.selectors[ownerGuid] = null;
      });

      guids.forEach((ownerGuid) => {
        if (this.messageProxySubscriptions[ownerGuid]) {
          if (!this.selectors[ownerGuid]) {
            this.selectors[ownerGuid] = createSelector(
              [(c) => c],
              (unreadMessagesByPeer) =>
                Object.keys(unreadMessagesByPeer).reduce(
                  (allMessages, peerJid) => {
                    return allMessages.concat(unreadMessagesByPeer[peerJid]);
                  },
                  []
                )
            );
          }
          this.messageProxySubscriptions[ownerGuid].update(
            ownerGuid,
            this.selectors[ownerGuid](unreadMessages[ownerGuid] || {})
          );
        }
      });
    });
  }

  isMessageUnreadByMe = (msg) => {
    return ReceiptProxy._isMessageUnreadByMe(msg);
  };

  onNewMessage = (msg) => {
    const { ownerGuid } = msg;
    this.onNewMessages([msg], ownerGuid);
  };

  onNewMessages = (msgs, ownerGuid) => {
    if (
      this.subscribedIds[ownerGuid] &&
      this.subscribedIds[ownerGuid].length > 0
    ) {
      const unreadMsgs = msgs.filter(this.isMessageUnreadByMe);
      // console.log('performance fix unread msgs', unreadMsgs);
      if (unreadMsgs && unreadMsgs.length) {
        // console.log('performance fix new unread message', msgs);
        store.dispatch(
          Actions.update_unread_messages({
            messages: unreadMsgs,
            ownerGuid,
          })
        );
      }
    }
  };

  onNewMessagesbatch = (messages) => {
    const msgContainer = {};
    batch(() => {
      if (messages && messages.length) {
        messages.forEach((msg) => {
          if (!msgContainer[msg.ownerGuid]) {
            msgContainer[msg.ownerGuid] = [];
          }
          msgContainer[msg.ownerGuid].push(msg);
        });
        // console.log('performance fix message obj', msgContainer);
        Object.keys(msgContainer).forEach((ownerGuid) => {
          this.onNewMessages(msgContainer[ownerGuid], ownerGuid);
        });
      }
    });
  };

  readAllMessagesFromPeer = (peer) => {
    markLatestMessageAsRead(peer);
  };

  onDeleteMessage = (msg) => {
    const { message_cache_id: mCId, ownerGuid, peer } = msg;
    store.dispatch(
      Actions.delete_unread_message({
        mCId,
        ownerGuid,
        peerJid: peer.jid,
      })
    );
  };

  onResetMessage = (needRefresh, ownerGuid, peerJid) => {
    if (!needRefresh) {
      store.dispatch(
        Actions.unread_messages_reset_peer({
          ownerGuid,
          peerJid,
        })
      );
    }
  };

  _getUnreadMessages(ownerGuid) {
    return bridge
      .ask('MessageCache', 'getAllUnreadMessageByOwnerGuid', [ownerGuid])
      .then((messages) => {
        messages = messages.filter(this.isMessageUnreadByMe);
        if (
          messages &&
          messages.length > 0 &&
          this.subscribedIds[ownerGuid] &&
          this.subscribedIds[ownerGuid].length > 0
        ) {
          // not subsequently unsubscribed
          store.dispatch(
            Actions.update_unread_messages({
              messages,
              ownerGuid,
            })
          );
        }
        return messages;
      });
  }

  subscribe(ownerGuid) {
    const id = this.counter.next().value;

    if (!this.subscribedIds[ownerGuid]) {
      this.subscribedIds[ownerGuid] = [];
    }
    this.subscribedIds[ownerGuid].push(id);
    if (this.subscribedIds[ownerGuid].length === 1) {
      this._getUnreadMessages(ownerGuid);
      this.messageProxySubscriptions[ownerGuid] = MessageProxy.subscribe(
        ownerGuid,
        []
      );
    }
    return { unsubscribe: this.unsubscribe.bind(this, ownerGuid, id) };
  }

  unsubscribe(ownerGuid, id) {
    if (!ownerGuid || !id) {
      return;
    }
    const subscriptions = this.subscribedIds[ownerGuid];
    if (subscriptions && subscriptions.length > 0) {
      const idx = subscriptions.indexOf(id);
      subscriptions.splice(idx, 1);
    }
    if (subscriptions && subscriptions.length < 1) {
      store.dispatch(Actions.unread_messages_reset_team({ ownerGuid }));
      if (this.messageProxySubscriptions[ownerGuid]) {
        this.messageProxySubscriptions[ownerGuid].unsubscribe();
        this.messageProxySubscriptions[ownerGuid] = null;
      }
    }
  }
}

export default new UnreadMessagesProxy();
