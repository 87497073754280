const defaultState = {};

export default (ActionTypes) => {
  return (state = defaultState, action) => {
    try {
      switch (action.type) {
        case ActionTypes.UPDATE_RECEIPT_STATE: {
          const { ownerJid, peerJid, receiptObj } = action.payload;
          const ownerState = state[ownerJid] || {};

          return {
            ...state,
            [ownerJid]: {
              ...ownerState,
              [peerJid]: receiptObj,
            },
          };
        }
        default:
          return state;
      }
    } catch (error) {
      console.error(`error in receipt state reducer : ${error.message}`, error);
    }
    return state;
  };
};
