import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import { SIDEBAR_CATEGORY, getCustomApps } from '@/utils/customApps';
import { openAppStore } from '@/utils/SessionUtils';
import ErrorBoundary from '@/components/widgets/ErrorBoundary';
import AppElement from './AppElement';
import cssStyles from './AppList.css';
import badgeStyles from './Badge.css';

const SCROLL_UP = 'SCROLL_UP';
const SCROLL_DOWN = 'SCROLL_DOWN';
const SCROLL_STEP = 10;
const APP_ICON_HEIGHT = 56;
const appListRef = React.createRef();

class AppList extends PureComponent {
  static SortableItem = sortableElement(({ children }) => children);

  static SortableContainer = sortableContainer(({ children }) => {
    return (
      <div
        onScroll={this.showOrHideIcon}
        className={classNames(cssStyles.apps, 'no-scrollbar')}
        ref={appListRef}
      >
        {children}
      </div>
    );
  });

  static defaultProps = {
    selectedAppId: '',
  };

  static propTypes = {
    appList: PropTypes.arrayOf(PropTypes.shape).isRequired,
    openApp: PropTypes.func.isRequired,
    selectedAppId: PropTypes.string,
    sessionId: PropTypes.string.isRequired,
  };

  state = {
    showGoToTop: false,
    showGoToBottom: false,
    showTopBadge: false,
    showBottomBadge: false,
  };

  showOrHideIcon = debounce(
    () => {
      const { current } = appListRef;
      if (current.scrollTop > 0) {
        this.setState({
          showGoToTop: true,
        });
      } else {
        this.setState({
          showGoToTop: false,
          showTopBadge: false,
        });
      }
      const { scrollHeight, scrollTop, clientHeight } = current;
      if (
        clientHeight === 280 ||
        scrollHeight - scrollTop === clientHeight ||
        clientHeight === scrollHeight
      ) {
        this.setState({
          showGoToBottom: false,
          showBottomBadge: false,
        });
      } else {
        this.setState({
          showGoToBottom: true,
        });
      }
      const badgeElems = current.querySelectorAll('.badge-element');
      if (badgeElems.length) {
        const {
          0: firstBadge,
          [badgeElems.length - 1]: lastBadge,
        } = badgeElems;
        this.setState({
          showTopBadge: firstBadge.offsetTop - scrollTop < 14,
          showBottomBadge: lastBadge.offsetTop - scrollTop > clientHeight,
        });
      }
    },
    400,
    { leading: true }
  );

  scrollTop = debounce(
    () => {
      const { current } = appListRef;
      current.scrollTo({
        top: 0,
        behaviour: 'smooth',
      });
      this.setState(
        {
          showGoToBottom: false,
          showGoToTop: false,
        },
        () => this.showOrHideIcon.cancel()
      );
    },
    300,
    { leading: true }
  );

  async componentDidMount() {
    const { GET_MORE_APP } = await getCustomApps();
    this.setState({
      browseAppsInfo: GET_MORE_APP,
    });
  }

  componentWillUnmount() {
    this.scrollTop.cancel();
    this.showOrHideIcon.cancel();
  }

  scroll = (direction) => {
    const $appList = appListRef.current;
    const { scrollTop } = $appList;
    let top;
    if (direction === SCROLL_DOWN) {
      top = scrollTop + APP_ICON_HEIGHT * SCROLL_STEP;
    } else {
      top = scrollTop - APP_ICON_HEIGHT * SCROLL_STEP;
    }
    $appList.scrollTo({
      top,
      behaviour: 'smooth',
    });
    this.showOrHideIcon();
  };

  scrollUp = () => {
    this.scroll(SCROLL_UP);
  };

  scrollDown = () => {
    this.scroll(SCROLL_DOWN);
  };

  openApp = (app) => {
    const { openApp } = this.props;
    openApp(app, '', true);
  };

  onFocus = () => {
    this.showOrHideIcon();
  };

  onBrowseAppClick = () => {
    const { sessionId } = this.props;
    openAppStore(sessionId, 'launcherbar', '');
  };

  render() {
    const { appList, onSortEnd, selectedAppId } = this.props;
    const {
      showGoToTop,
      showGoToBottom,
      showTopBadge,
      showBottomBadge,
      browseAppsInfo,
    } = this.state;
    const appWithBadgeIndex = appList.findIndex(({ hasBadge }) => hasBadge);
    const showBadge = appWithBadgeIndex > 4;
    if (!appList || appList.length === 0) return null;
    return (
      <div
        className={cssStyles.appList}
        onMouseLeave={this.scrollTop}
        onMouseOver={this.showOrHideIcon}
        onFocus={this.onFocus}
        id='globalButtonApps'
      >
        <AppList.SortableContainer
          helperClass={classNames('sortableHelper', cssStyles.sorting)}
          lockAxis='y'
          onSortEnd={onSortEnd}
          distance={10}
        >
          <div
            role='button'
            tabIndex={0}
            onKeyDown={() => {}}
            className={classNames(cssStyles.top, {
              [cssStyles.hide]: !showGoToTop,
            })}
            onClick={this.scrollUp}
          >
            <div
              className={classNames(cssStyles.overflowIcon, {
                [badgeStyles.badge]: showBadge && showTopBadge,
              })}
            />
          </div>
          {appList.map((app, index) => {
            return (
              <AppList.SortableItem key={app.id} index={index}>
                <ErrorBoundary>
                  <AppElement
                    onClick={this.openApp}
                    hasBadge={app.hasBadge}
                    key={app.id}
                    index={index}
                    category={SIDEBAR_CATEGORY}
                    appInfo={app}
                    isSelected={app.id === selectedAppId}
                  />
                </ErrorBoundary>
              </AppList.SortableItem>
            );
          })}
          <div
            onKeyDown={() => {}}
            role='button'
            tabIndex={0}
            className={classNames(cssStyles.bottom, {
              [badgeStyles.badge]: showBadge && showBottomBadge,
              [cssStyles.hide]: !showGoToBottom,
            })}
            onClick={this.scrollDown}
          >
            <div className={cssStyles.overflowIcon} />
          </div>
        </AppList.SortableContainer>
        <div
          className={classNames(cssStyles.moreIcon, {
            [badgeStyles.badge]: showBadge,
          })}
        />
        <div className={cssStyles.browseAppsIcon}>
          {browseAppsInfo && (
            <AppElement
              appInfo={browseAppsInfo}
              category={SIDEBAR_CATEGORY}
              onClick={this.onBrowseAppClick}
            />
          )}
        </div>
      </div>
    );
  }
}

export default AppList;
