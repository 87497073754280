import React, { Component } from 'react';
import { Command } from '@/components/ConversationEditor/Types';
import css from './CommandList.css';

type Props = {
  onSelect: (command: Command) => void;
  activeIndex: number;
  commands: Command[];
} & typeof CommandList.defaultProps;

class CommandList extends Component<Props> {
  static defaultProps = {
    onSelect: (command: Command) => {},
    activeIndex: 0,
  };

  ListWrapper = React.createRef<HTMLDivElement>();

  componentDidUpdate(prevProps: Props) {
    const { activeIndex } = this.props;
    const { activeIndex: prevActiveIndex } = prevProps;

    if (activeIndex !== prevActiveIndex) {
      this.adjustScroll(activeIndex);
    }
  }

  onCommandSelect = (command: Command) => {
    const { onSelect } = this.props;

    onSelect(command);
  };

  // This is code is also being used at contactList component
  // TODO: may be abtract it?
  adjustScroll(index) {
    const wrapperEl = this.ListWrapper.current;
    const activeElement = wrapperEl.querySelectorAll('li')[index];
    if (!activeElement) {
      return;
    }
    const {
      top: wrapperTop,
      bottom: wrapperBottom,
    } = wrapperEl.getBoundingClientRect();
    const {
      top: activeElementTop,
      bottom: activeElementBottom,
    } = activeElement.getBoundingClientRect();

    if (activeElementTop < wrapperTop) {
      wrapperEl.scrollTop -= wrapperTop - activeElementTop;
    }
    if (activeElementBottom > wrapperBottom) {
      wrapperEl.scrollTop += activeElementBottom - wrapperBottom;
    }
  }

  // NOTE: This workaround is needed to let decorator visibility be controlled
  // by editor focus state. (See https://github.com/talk-to/odara/pull/345)
  onMouseDown = (event: React.MouseEvent) => event.preventDefault();

  renderCommandItems() {
    const { commands, activeIndex } = this.props;

    return commands.map((command, index) => {
      const { name, hint, params: { hint: paramHint = '' } = {} } = command;

      return (
        <li
          key={name + hint}
          role='menuitem'
          tabIndex={-1}
          className={`${css.ListItem} ${
            index === activeIndex ? css.active : ''
          }`}
          // selected={index === activeIndex}
          onClick={() => this.onCommandSelect(command)}
          onMouseDown={this.onMouseDown}
          onKeyPress={() => {}}
        >
          <span className={css.Name}>{name}</span>
          <span className={css.ParamHint}>{paramHint}</span>
          <span className={css.CommandHint}>{hint}</span>
        </li>
      );
    });
  }

  render() {
    return (
      <div className={css.Wrapper}>
        <div className={css.Title}>/Commands</div>
        <div className={css.ListWrapper} ref={this.ListWrapper}>
          {this.renderCommandItems()}
        </div>
      </div>
    );
  }
}

export default CommandList;
