import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import IntlWrapper from '@/Wrappers/IntlWrapper';
import { CHAT_STATE } from '@/utils/constants';
import iconSrc from '@/assets/icon-edit.svg';

import css from './TypingIndicator.css';
import translations from './I18N';

const getFirstName = (name) => name.split(/\s+/)[0];

const getMessageText = (users) => {
  const names = users.map((user) => getFirstName(user.name));

  if (names.length === 1) {
    return (
      <FormattedMessage id='chat_state_typing' values={{ peer: names[0] }} />
    );
  }

  if (names.length <= 3) {
    return `${names.slice(0, -1).join(', ')} and ${
      names[names.length - 1]
    } are typing...`;
  }

  if (names.length > 3) {
    const joinedNames = names.slice(0, 3).join(', ');
    const remainingCount = names.length - 3;
    const plural = remainingCount > 1 ? 's' : '';
    return (
      <FormattedMessage
        id='chat_state_group_conversation_others'
        values={{
          peer: joinedNames,
          count: remainingCount,
          plural,
        }}
      />
    );
  }

  return null;
};

class TypingIndicator extends React.PureComponent {
  static propTypes = {
    participants: PropTypes.array,
    status: PropTypes.string.isRequired,
  };

  static defaultProps = {
    participants: [],
  };

  render() {
    const { participants, status } = this.props;

    const textContent = getMessageText(participants);

    if (status === CHAT_STATE.COMPOSING && participants.length) {
      return (
        <span className={css.TypingIndicator}>
          <img className={css.TypingIndicatorIcon} src={iconSrc} alt='' />
          <span className={css.TypingIndicatorContent}>{textContent}</span>
        </span>
      );
    }

    return null;
  }
}

export default IntlWrapper(TypingIndicator, translations);
