import { createSelector } from 'reselect';
import ownerGuidSelector from './ownerGuid';

const defaultConversations = {};

const allConversationsSelector = (state) =>
  state.restricted.message.all_conversations;

export const openConversationsByGuidSelector = createSelector(
  allConversationsSelector,
  (allConversations) => (ownerGuid) =>
    (ownerGuid && allConversations[ownerGuid]) || defaultConversations
);

export default createSelector(
  ownerGuidSelector,
  openConversationsByGuidSelector,
  (ownerGuid, getOpenConversationsByGuid) =>
    getOpenConversationsByGuid(ownerGuid)
);
