import React from 'react';
import currentSessionSelector from '@/selectors/session';
import store from '@/store';
import TroubleShootChecks from './TroubleShootChecks';
import TroubleShootResult from './TroubleShootResult';
import { logMedusaEventWithConnectivity } from './utils';
import { ResultObj } from './Types';
import cssStyles from './ConnectionTroubleShooterModal.css';

type Props = {
  source?: string;
};

type State = {
  isTroubleShootingCompleted: boolean;
  result: ResultObj;
};

const defaultResultObj = {
  tests: [],
  allTestPassed: false,
};
class ConnectionLoggerModal extends React.Component<Props, State> {
  state: State = {
    isTroubleShootingCompleted: false,
    result: defaultResultObj,
  };

  componentDidMount() {
    const { source } = this.props;
    if (source === 'APP_MENU') {
      logMedusaEventWithConnectivity(
        'connection_troubleshooter_opened_from_app_menu',
        currentSessionSelector(store.getState())
      );
    }
  }

  onCompletion = (result: ResultObj) => {
    this.setState({
      isTroubleShootingCompleted: true,
      result,
    });
  };

  checkAgain = () => {
    this.setState({
      isTroubleShootingCompleted: false,
      result: defaultResultObj,
    });
  };

  render() {
    const { isTroubleShootingCompleted, result } = this.state;
    return (
      <div className={cssStyles.container}>
        {!isTroubleShootingCompleted ? (
          <TroubleShootChecks onCompletion={this.onCompletion} />
        ) : (
          <TroubleShootResult result={result} checkAgain={this.checkAgain} />
        )}
      </div>
    );
  }
}

export default ConnectionLoggerModal;
