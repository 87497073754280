import bridge from '../bridge';
import MultiFileUpload from './MultiFileUpload';
import { MultiFileUploadProgressType, FileUploadType } from './types';

const FILE_UPLOAD_STATE: Record<string, MultiFileUploadProgressType> = {};
const ABORT_FUNCS: Record<string, () => void> = {};

/**
 * @param  {MultiFileUploadProgressType} progress
 */
function updateUploadState(progress: MultiFileUploadProgressType) {
  if (!progress) {
    return;
  }
  const { peerJid } = progress;

  if (!peerJid) {
    return;
  }
  FILE_UPLOAD_STATE[peerJid] = progress;
}

bridge.subscribe('MultiFileUpload/Progress', updateUploadState);

/**
 * @param  {string} peerJid
 * @returns MultiFileUploadProgressType
 */
function getUploadProgress(
  peerJid: string
): MultiFileUploadProgressType | undefined {
  return FILE_UPLOAD_STATE[peerJid];
}

/**
 * @param  {any} files
 * @param  {string} peerJid
 * @param  {any} currentSession

 * @return {promise}
 */
function startUpload(
  files: any,
  peerJid: string,
  currentSession: any
): Promise<FileUploadType[] | undefined> {
  const progress = getUploadProgress(peerJid);
  if (progress && progress.status === 'UPLOADING') {
    throw new Error('Upload is already in progress for this peer');
  }

  const { promise, abort } = MultiFileUpload.new(
    files,
    peerJid,
    currentSession
  );
  ABORT_FUNCS[peerJid] = abort;
  return promise;
}

function abortUpload(peerJid: string) {
  if (!peerJid) {
    return;
  }
  const fn = ABORT_FUNCS[peerJid];
  if (!fn) {
    return;
  }
  fn();
}

export { getUploadProgress, startUpload, abortUpload };
