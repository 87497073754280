import categories from "emoji-data/data/categories.json";
import isNumber from "lodash/isNumber";
import sortBy from "lodash/sortBy";
import uniq from "lodash/uniq";
import { runInAction, transaction, observable, toJS } from "mobx";

function getEmojiList(converter) {
  return categories.map((category) => {
    return {
      ...category,
      emojis: converter.listBy(category.id),
    };
  });
}

export function createStore() {
  const local = observable({
    hasRenderedList: false,
    convertor: null,
    emojis: [],
    stickers: [],
    recents: [],
    skin: 1,
    vendor: "",
    width: 0,
    height: 0,
    emojiWidth: 26,
    emojiHeight: 26,
    stickerWidth: 50,
    stickerHeight: 50,
    detailHeight: 58,
    detailEmojiWidth: 30,
    detailEmojiHeight: 30,
    detailStickerWidth: 50,
    detailStickerHeight: 50,
    headerHeight: 32,
    innerListWidth: 0,
    itemWidth: 42,
    itemHeight: 42,
    itemStickerWidth: 72,
    itemStickerHeight: 72,
    searchHeight: 48,
    tabsWidth: 50,
    tabItemHeight: 42,
    tabIndicatorHeight: 24,
    disableDetail: false,
    disableSearch: false,
    disableTabs: false,
    theme: { name: "", colors: {} },
    searchTerm: "",
    visibleStartIndex: null,
    tabsVisibleStartIndex: null,
    tabsVisibleStopIndex: null,
    hoverItemId: null,
    hoverItemType: null,
    selectedItemId: null,
    selectedItemType: null,
    searchResultLimit: 18,
    skinToneColors: {
      1: "#ffc93a",
      2: "#fadcbc",
      3: "#e0bb95",
      4: "#bf8f68",
      5: "#9b643d",
      6: "#594539",
    },
    lastReset: null,
    categoryId: null,
    onEmoji: () => {},
    onSticker: () => {},

    get activeCategory() {
      return this.categoryId;
    },

    get colors() {
      if (this.theme && this.theme.colors) {
        return {
          primaryBg: this.theme.colors["dialog-primary-bg"],
          primaryText: this.theme.colors["primary-text"],
          secondaryBg: this.theme.colors["dialog-secondary-bg"],
          secondaryText: this.theme.colors["secondary-text"],
          border: this.theme.colors["border"],
          accent: this.theme.colors["brand"],
        };
      }
      return {};
    },

    get contentWidth() {
      if (this.width > 0) {
        if (this.disableTabs) {
          return this.width;
        }
        return this.width - this.tabsWidth;
      }
      return 0;
    },

    get contentHeight() {
      if (this.height > 0) {
        let height = this.height;
        if (!this.disableSearch) {
          height -= this.searchHeight;
        }
        if (!this.disableDetail) {
          height -= this.detailHeight;
        }
        return height;
      }
      return 0;
    },

    get contentPadding() {
      if (this.rowItemCount > 0) {
        return Math.floor(
          (this.innerListWidth - this.itemWidth * this.rowItemCount) / 2
        );
      }
      return 0;
    },

    get contentStickerPadding() {
      if (this.rowStickerItemCount > 0) {
        return Math.floor(
          (this.innerListWidth -
            this.itemStickerWidth * this.rowStickerItemCount) /
            2
        );
      }
      return 0;
    },

    get estimatedItemSize() {
      let headerCount = 0;
      let emojiCount = 0;
      let stickerCount = 0;
      for (const item of this.list) {
        if (item.type === "header") {
          headerCount += 1;
        } else {
          for (const i of item.items) {
            if (i.type === "emoji") {
              emojiCount += 1;
            } else if (i.type === "sticker") {
              stickerCount += 1;
            }
          }
        }
      }
      const a =
        headerCount * this.headerHeight +
        emojiCount * this.itemHeight +
        stickerCount * this.itemStickerHeight;
      const b = headerCount + emojiCount + stickerCount;
      if (a > 0 && b > 0) {
        return Math.floor(a / b);
      }
      return 0;
    },

    get estimatedSearchItemSize() {
      return Math.floor((this.itemHeight + this.itemStickerHeight) / 2);
    },

    get floaterText() {
      let text = "";
      if (this.isSearching) {
        text = "Search results";
      } else if (this.list.length && isNumber(this.visibleStartIndex)) {
        const obj = this.list[this.visibleStartIndex];
        if (obj.type === "header") {
          text = obj.name;
        } else {
          const item = obj.items[0];
          if (item.type === "emoji") {
            const cid = item.categoryId;
            const cat = categories.find((c) => c.id === cid);
            text = cat.name;
          } else if (item.type === "sticker") {
            text = item.categoryName || "STICKER CATEGORY";
          }
        }
      }
      return text;
    },

    get hoverItem() {
      let item;
      if (this.hoverItemType === "emoji") {
        item = this.convertor.getEmojiById(this.hoverItemId);
      } else if (this.hoverItemType === "sticker") {
        for (const c of this.stickers) {
          if (item) break;
          for (const s of c.stickers) {
            if (s.id === this.hoverItemId) {
              item = s;
              break;
            }
          }
        }
      }
      return item;
    },

    get isSearching() {
      return !!this.searchTerm;
    },

    get list() {
      const list = [];
      if (this.vendor && this.rowItemCount > 0) {
        // recents
        if (this.recents.length) {
          list.push({
            type: "header",
            name: "Frequently used",
            id: "recent",
            order: -1,
          });

          let index = 0;
          while (index < this.recents.length) {
            list.push({
              id: `recent-${index}`,
              items: this.recents
                .slice(index, index + this.rowItemCount)
                .map((e) => ({ ...e, type: "emoji" })),
            });
            index += this.rowItemCount;
          }
        }

        // emojis
        for (const category of this.emojis) {
          const { emojis, ...categoryData } = category;
          list.push({ ...categoryData, type: "header" });
          let index = 0;
          while (index < emojis.length) {
            list.push({
              id: `${categoryData.id}-${index}`,
              items: emojis
                .slice(index, index + this.rowItemCount)
                .map((e) => ({ ...e, type: "emoji" })),
            });
            index += this.rowItemCount;
          }
        }

        // stickers
        for (const category of this.stickers) {
          const { stickers, ...categoryData } = category;
          list.push({ ...categoryData, type: "header" });
          let index = 0;
          while (index < stickers.length) {
            list.push({
              id: `${categoryData.id}-${index}`,
              items: stickers
                .slice(index, index + this.rowStickerItemCount)
                .map((s) => ({ ...s, type: "sticker" })),
            });
            index += this.rowStickerItemCount;
          }
        }
      }
      return list;
    },

    get padding() {
      if (this.rowItemCount > 0) {
        const a = (this.contentWidth - this.itemWidth * this.rowItemCount) / 2;
        const b = (this.itemWidth - this.emojiWidth) / 2;
        return Math.floor(a + b);
      }
      return 0;
    },

    get paddingSticker() {
      if (this.rowStickerItemCount > 0) {
        const a =
          (this.contentWidth -
            this.itemStickerWidth * this.rowStickerItemCount) /
          2;
        const b = (this.itemStickerWidth - this.stickerWidth) / 2;
        return Math.floor(a + b);
      }
      return 0;
    },

    get rowItemCount() {
      if (this.innerListWidth > 0) {
        return Math.floor(this.innerListWidth / this.itemWidth);
      }
      return 0;
    },

    get rowStickerItemCount() {
      if (this.innerListWidth > 0) {
        return Math.floor(this.innerListWidth / this.itemStickerWidth);
      }
      return 0;
    },

    get searchResults() {
      const list = [];
      if (this.searchTerm && this.vendor && this.rowItemCount > 0) {
        const emojis = this.convertor.search(this.searchTerm, {
          limit: this.searchResultLimit,
          recents: this.recents || [],
        });
        list.push({
          id: "search-label",
          name: "Search results",
          type: "header",
        });
        let index = 0;
        while (index < emojis.length) {
          list.push({
            id: `search-${index}`,
            items: emojis
              .slice(index, index + this.rowItemCount)
              .map((e) => ({ ...e, type: "emoji" })),
          });
          index += this.rowItemCount;
        }
        index = 0;
        while (index < this.searchStickerResult.length) {
          list.push({
            id: `search-s-${index}`,
            items: this.searchStickerResult
              .slice(index, index + this.rowStickerItemCount)
              .map((s) => ({ ...s, type: "sticker" })),
          });
          index += this.rowStickerItemCount;
        }
      }
      return list;
    },

    get searchStickerResult() {
      let list = [];
      if (this.isSearching) {
        const termRegex = /([\w\d_\-+]+)/g;
        let termRes;
        let term;
        while ((termRes = termRegex.exec(this.searchTerm))) {
          const tempTerm = termRes[0].toLowerCase();
          if (!term) {
            term = tempTerm;
          }
        }

        const searchCompare = (a, b) => {
          a = a.toLowerCase();
          b = b.toLowerCase();
          let value = "";
          let separator;

          if (b.indexOf("_") > -1) {
            separator = "_";
          } else if (b.indexOf("-") > -1) {
            separator = "-";
          }

          // Term is at the beginning of b
          if (b.indexOf(a) === 0) {
            value += "0";
          } else {
            value += "1";
          }

          // Term is at the beginning of any word of b
          if (separator) {
            if (b.split(separator).some((s) => s.indexOf(a) === 0)) {
              value += "0";
            } else {
              value += "1";
            }
          } else {
            // Single word, repeats the previous condition
            value += value;
          }

          value += b.split(separator).length;
          value += b;
          return value;
        };

        const stickers = toJS(this.stickers)
          .map((i) => i.stickers)
          .flat();

        const possibleNameMatches = stickers.filter(
          (i) => i.name.toLowerCase().indexOf(term) > -1
        );

        const possibleCategoryMatches = stickers.filter(
          (i) => i.categoryName.toLowerCase().indexOf(term) > -1
        );

        const nameMatches = sortBy(possibleNameMatches, (i) =>
          searchCompare(term, i.name)
        );

        const categoryMatches = sortBy(possibleCategoryMatches, (i) =>
          searchCompare(term, i.categoryName)
        );

        const matches = uniq(nameMatches.concat(categoryMatches));

        list = matches.slice(0, this.searchResultLimit);
      }
      return list;
    },

    get tabIndicators() {
      let top = false;
      let bottom = false;
      if (
        this.tabList.length &&
        isNumber(this.tabsVisibleStartIndex) &&
        isNumber(this.tabsVisibleStopIndex)
      ) {
        if (this.tabsVisibleStartIndex > 0) {
          top = true;
        }
        if (this.tabsVisibleStopIndex < this.tabList.length - 1) {
          bottom = true;
        }
      }
      return { top, bottom };
    },

    get tabList() {
      if (this.list.length) {
        return this.list.filter((i) => i.type === "header");
      }
      return [];
    },

    reset() {
      this.searchTerm = "";
      this.hoverItemId = null;
      this.hoverItemType = null;
      this.selectedItemId = null;
      this.selectedItemType = null;
      this.categoryId = null;
      this.lastReset = Date.now();
    },

    setDimensions(width, height) {
      this.width = width - 2;
      this.height = height - 2;
    },

    setHover(id, type) {
      this.hoverItemId = id;
      this.hoverItemType = type;
    },

    setInnerListWidth(width) {
      this.innerListWidth = width;
    },

    setItemsRendered({ visibleStartIndex }) {
      this.hasRenderedList = true;
      this.visibleStartIndex = visibleStartIndex;
    },

    setOptions(props) {
      const {
        convertor,
        disableDetail,
        disableSearch,
        disableTabs,
        onEmoji,
        onSticker,
        onSkin,
        recents,
        skin,
        stickers,
        theme,
      } = props;
      if (typeof disableDetail === "boolean") {
        this.disableDetail = disableDetail;
      }
      if (typeof disableSearch === "boolean") {
        this.disableSearch = disableSearch;
      }
      if (typeof disableTabs === "boolean") {
        this.disableTabs = disableTabs;
      }
      this.convertor = convertor;

      if (this.emojis.length === 0) {
        this.emojis = getEmojiList(this.convertor);
      }

      this.recents = recents;
      this.skin = skin;
      this.stickers = stickers;
      this.vendor = this.convertor.props.vendor;
      this.theme = theme;
      this.onEmoji = onEmoji;
      this.onSticker = onSticker;
      this.onSkin = onSkin;
    },

    setRecents(recents) {
      this.recents = recents;
    },

    setSearchTerm(term) {
      let str = "";
      const wasSearching = this.isSearching;
      if (term) {
        str = term.trim();
      }
      this.searchTerm = str;
      if (wasSearching !== this.isSearching) {
        this.hoverItemId = null;
        this.hoverItemType = null;
        this.selectedItemId = null;
        this.selectedItemType = null;
      }
    },

    setSelection(id, type) {
      this.selectedItemId = id;
      this.selectedItemType = type;
      if (type === "emoji") {
        const emoji = this.convertor.getEmojiById(id);
        this.onEmoji(emoji);
      } else if (type === "sticker") {
        let sticker;
        for (const i of this.list) {
          if (sticker) {
            break;
          }
          if (i.items && i.items.length) {
            for (const s of i.items) {
              if (s.type === "sticker" && s.id === id) {
                sticker = s;
                break;
              }
            }
          }
        }
        this.onSticker(sticker);
      }
    },

    setSkin(skin) {
      this.skin = parseInt(skin);
    },

    setLocalSkin(skin) {
      this.skin = parseInt(skin);
      this.onSkin(this.skin);
    },

    setTabsItemsRendered({ visibleStartIndex, visibleStopIndex }) {
      this.tabsVisibleStartIndex = visibleStartIndex;
      this.tabsVisibleStopIndex = visibleStopIndex;
    },

    setVendor(vendor) {
      this.convertor.setVendor(vendor);
      this.vendor = this.convertor.props.vendor;
    },

    setTheme(theme) {
      this.theme = theme;
    },

    setCategoryId(categoryId) {
      const categoryIndex = this.list.findIndex(
        (i) => i.type === "header" && i.id === categoryId
      );

      if (categoryIndex > -1) {
        this.categoryId = categoryId;
      }
    },
  });

  // NOTE: Not sure as to if this is really needed or whether a simple .bind
  // would do.
  // Ripped from: https://github.com/mobxjs/mobx-react-lite/blob/a38cc5e21dc1b7f9beac33ec030acd4a1e44b7b4/src/useLocalStore.ts
  runInAction(() => {
    Object.keys(local).forEach((key) => {
      const value = local[key];
      if (typeof value === "function") {
        local[key] = wrapInTransaction(value, local);
      }
    });
  });

  return local;
}

function wrapInTransaction(fn, context) {
  return (...args) => {
    return transaction(() => fn.apply(context, args));
  };
}
