import getTranslatedString from '@/utils/getTranslatedString';
import { toggleChannelMute } from '../../../../utils';
import { getSingleParamFragments } from '../../../../TokenUtils';
import I18N from './I18N';

const command = {
  name: getTranslatedString('command_mute_name', I18N),
  hint: getTranslatedString('command_mute_hint', I18N),
  params: {
    hint: getTranslatedString('command_mute_param_hint', I18N),
    type: ['group'],
    check(peer) {
      return peer.type !== 'group';
    },
    getParamContacts(contact) {
      return contact;
    },
    getParamsInfo(textContent, selectionStart, selectionEnd) {
      return getSingleParamFragments(textContent, selectionStart, selectionEnd);
    },
  },
  shouldIgnore() {
    return false;
  },
  action(textContent, peer, owner, sessionId, conversation, contactParams) {
    const contact = contactParams.length > 0 ? contactParams[0] : undefined;
    // TODO: May be we don't need paramfragments
    // const paramFragments = getSingleParamFragments(
    //   textContent,
    //   textContent.length,
    //   textContent.length
    // );
    // const { paramPrefix } = paramFragments;

    if (contact /* && paramPrefix.trim().slice(1) */) {
      return toggleChannelMute(true, contact, sessionId);
    }

    if (!contact /* && paramPrefix.trim() === '' */) {
      return toggleChannelMute(true, peer, sessionId);
    }
    return false;
  },
};

export default command;
