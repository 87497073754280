export default {
  command_mute_name: '/mute',
  command_mute_hint: 'mute a channel',
  command_mute_param_hint: '@channelname',

  command_add_name: '/add',
  command_add_hint: 'add people to this conversation',
  command_add_param_hint: '@name',

  command_clone_name: '/clone',
  command_clone_hint: 'clone the current channel',

  command_leaveChannel_name: '/leave',
  command_leaveChannel_hint: 'leave channel',

  command_setStatus_name: '/status',
  command_setStatus_hint: 'set your status',
  command_setStatus_param_hint: 'status message',

  command_unmute_name: '/unmute',
  command_unmute_hint: 'unmute a channel',
  command_unmute_param_hint: '@channelname',

  command_videor_name: '/videor',
  command_videor_hint: 'start a video conference with recording ON',

  command_shortcuts_name: '/shortcuts',
  command_shortcuts_hint: 'see all keyboard shortcuts',
};
