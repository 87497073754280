import { getNewMessageClientId } from '@/utils/ConversationUtils';
import {
  FileUploadType,
  MultiFileUploadProgressType,
  FileUploadStatusType,
  File,
} from './types';
import { uploadFile } from './util';
import {
  handleFileUploadError,
  checkAndHandleFileRestrictions,
  ERROR_TYPES,
} from './Restrictions';
import bridge from '../bridge';
import noop from '../noop';

/**
    Progress is being published via bridge.
    May be it can be shared more explicitly.
 */

export default class MultiFileUpload {
  files: File[] = [];

  peerJid?: string = undefined;

  sessionId?: string = undefined;

  ownerGuid?: string = undefined;

  uploadStatus: FileUploadType[] = [];

  progress: MultiFileUploadProgressType = {
    status: 'UPLOADING',
    filesCount: 0,
    uploadedCount: 0,
    failedCount: 0,
    totalSize: 0,
    uploadedSize: 0,
    peerJid: this.peerJid,
  };

  xhrs: any[] = [];

  uploadResolveFn!: (result: FileUploadType[]) => void;

  uploadRejectFn!: (result: FileUploadType[]) => void;

  constructor(files: any, peerJid: string, session: any) {
    const { id: sessionId, owner } = session;
    const { ownerGuid } = owner;
    this.files = files;
    this.peerJid = peerJid;
    this.sessionId = sessionId;
    this.ownerGuid = ownerGuid;
  }

  static new(files: any, peerJid: string, session: any) {
    const instance = new MultiFileUpload(files, peerJid, session);
    const { owner } = session;

    if (checkAndHandleFileRestrictions(files, owner, session)) {
      return {
        promise: Promise.reject(),
        abort: noop,
      };
    }

    instance.initProgress();
    instance.startUpload();
    bridge.publish('MultiFileUpload/Progress', [instance.progress]);

    return {
      promise: new Promise<FileUploadType[]>((resolve, reject) => {
        instance.uploadResolveFn = resolve;
        instance.uploadRejectFn = reject;
      }),
      abort: instance.abortUpload.bind(instance),
    };
  }

  onUploadComplete() {
    const { status, filesCount: totalCount, failedCount } = this.progress;
    if (status !== 'UPLOADING') {
      if (
        status === 'SUCCESS' ||
        status === 'PARTIAL_ERROR' ||
        status === 'CANCELLED'
      ) {
        this.uploadResolveFn(this.uploadStatus);
        if (status === 'PARTIAL_ERROR') {
          handleFileUploadError({
            errorType: ERROR_TYPES.PARTIAL_UPLOAD_ERROR,
            props: {
              failedCount,
              totalCount,
            },
          });
        }
      } else {
        this.uploadRejectFn(this.uploadStatus);
        if (status === 'ERROR') {
          handleFileUploadError({
            errorType: ERROR_TYPES.NETWORK_ERROR,
            props: {},
          });
        }
      }
    }
  }

  initProgress() {
    let totalSize = 0;
    this.files.forEach((file) => {
      totalSize += file.size;
    });
    this.progress = {
      status: 'UPLOADING',
      filesCount: this.files.length,
      uploadedCount: 0,
      failedCount: 0,
      totalSize,
      uploadedSize: 0,
      peerJid: this.peerJid,
    };
  }

  updateProgress(
    index: number,
    status: FileUploadStatusType,
    uploaded: number,
    total: number,
    responseText = ''
  ) {
    const statusObj = this.uploadStatus[index];

    switch (status) {
      case 'UPLOADING':
        this.progress.uploadedSize += uploaded - statusObj.uploaded;
        break;
      case 'SUCCESS':
        this.progress.uploadedCount += 1;
        break;
      case 'CANCELLED':
      case 'ERROR':
        this.progress.uploadedSize -= statusObj.uploaded;
        this.progress.failedCount += 1;
        break;
      default:
    }
    const { uploadedCount, failedCount, filesCount } = this.progress;

    if (uploadedCount === filesCount) {
      this.progress.status = 'SUCCESS';
    } else if (failedCount === filesCount) {
      this.progress.status = 'ERROR';
    } else if (failedCount > 0 && uploadedCount + failedCount === filesCount) {
      this.progress.status = 'PARTIAL_ERROR';
    }

    if (status === 'CANCELLED') {
      this.progress.status = 'CANCELLED';
    }

    statusObj.progress = uploaded / total;
    statusObj.uploaded = uploaded;
    if (responseText) {
      statusObj.response = JSON.parse(responseText);
    }
    statusObj.status = status;

    if (this.progress.status !== 'UPLOADING') {
      this.onUploadComplete();
    }

    bridge.publish('MultiFileUpload/Progress', [this.progress]);
  }

  addStatusObj(index: number, file: any, msgCid: string) {
    const statusObj: FileUploadType = {
      file,
      progress: 0,
      uploaded: 0,
      total: file.size,
      status: 'UPLOADING',
      msgCid,
    };
    this.uploadStatus[index] = statusObj;
  }

  startUpload() {
    this.files.forEach((file, index) => {
      const callbacks = {
        onprogress: ({
          uploaded,
          total,
        }: {
          uploaded: number;
          total: number;
        }) => this.updateProgress(index, 'UPLOADING', uploaded, total),
        onload: (responseText: string) =>
          this.updateProgress(
            index,
            'SUCCESS',
            file.size,
            file.size,
            responseText
          ),
        onerror: () => this.updateProgress(index, 'ERROR', 0, file.size),
        onabort: () => this.updateProgress(index, 'CANCELLED', 0, file.size),
      };
      getNewMessageClientId().then((msgCid) => {
        this.addStatusObj(index, file, msgCid);
        uploadFile(
          file,
          this.sessionId,
          this.ownerGuid,
          this.peerJid,
          msgCid,
          callbacks
        ).then((xhr) => this.xhrs.push(xhr));
      });
    });
  }

  abortUpload() {
    this.xhrs.forEach((xhr) => xhr.abort());
  }
}
