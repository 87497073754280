export default {
  sender_name_you: 'You',
  message_sentByMe_header: 'Me',
  guest: 'guest',
  phrase_invited: '{actor} added {user}',
  phrase_removed: '{actor} removed {user}',
  phrase_joined: '{user} joined',
  phrase_left: '{user} left',
  public_note:
    'This channel is public. Anyone in the team can see and join this channel',
  private_note:
    'This channel is private. Only invited members can read and post messages here',
  team_hub_note:
    'This is a default channel. All team members will be added to this channel',
  restricted_note:
    'This channel is public for specific user groups. Anyone in the following groups can see and join this channel : {user_groups}',
  notify_team_hub_announcement:
    'All team members will be added to this channel by default',
  notify_public_channel_created:
    '{actor} created a public channel. Anyone in the team can see and join this channel',
  notify_private_channel_created:
    '{actor} created a private channel. Only invited members can read and post messages here',
  notify_restricted_channel_created:
    '{actor} created a public channel for the following user groups : {user_groups}',
  notify_channel_purpose_set:
    '{actor} set the channel purpose to "{channel_purpose}"',
  notify_channel_avatar_change: '{actor} changed the channel avatar',
  notify_add_channel_admin: '{actor} made {user} a channel admin',
  notify_auto_add_channel_admin: '{user} is now a channel admin',
  notify_auto_add_channel_admin_self: 'You are now a channel admin',
  notify_remove_channel_admin:
    '{actor} revoked channel admin rights from {user}',
  notify_channel_name_change:
    '{actor} changed the channel name to "{channel_name}"',
  notify_channel_type_restricted:
    '{actor} updated this channel’s privacy criteria. All users from the following user groups can see and join this channel from now on : {user_groups}. Any user outside of these user groups needs to be added by channel admin(s)',
  notify_channel_type_public:
    '{actor} updated this channel’s privacy criteria. Anyone in the team can see and join this channel',
  notify_channel_type_private:
    '{actor} changed the channel type to private. Only invited members can read and post messages here',
  notify_channel_purpose_remove: '{actor} cleared the channel purpose',
  notify_channel_purpose_change:
    '{actor} changed the channel purpose to "{channel_purpose}"',
  notify_channel_grant_admin:
    '{actor} changed the channel setting to "admins can add/remove members"',
  notify_channel_grant_everyone:
    '{actor} changed the channel setting to "everyone can add/remove members"',
};
