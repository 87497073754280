import React from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../store";

export const Emoji = observer(function Emoji({ data, width, height }) {
  const store = useStore();
  const emoji = store.convertor.getEmojiWithSkin(data, store.skin);
  const emojiStyle = store.convertor.getCSSForEmoji(emoji);

  return (
    <span className="emoji-root">
      <span className="emoji" style={emojiStyle} />

      <style jsx>{`
        .emoji-root {
          display: flex;
          justify-content: center;
          align-items: center;
          pointer-events: none;
        }
        .emoji {
          width: 100%;
          height: 100%;
        }
      `}</style>
      <style jsx>{`
        .emoji-root {
          width: ${width}px;
          height: ${height}px;
        }
      `}</style>
    </span>
  );
});
