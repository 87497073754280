import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import IntlWrapper from '@/Wrappers/IntlWrapper';
import cssStyles from '../ContactItem.css';
import translations from './I18N';

class SearchUtilContact extends PureComponent {
  static propTypes = {
    contact: PropTypes.object,
  };

  static defaultProps = {
    contact: undefined,
  };

  getSubText() {
    const {
      contact: { chatName },
    } = this.props;

    // TODO: i18n for mention help text
    switch (chatName) {
      case 'all':
        return <FormattedMessage id='utility_search_subtitle_all' />;
      case 'online':
        return (
          <FormattedMessage
            id='utility_search_subtitle_online'
            values={{ minute: 5 }}
          />
        );
      default:
        return null;
    }
  }

  getContactSubText() {
    const {
      contact: { type },
    } = this.props;
    let text;
    if (type === 'utility_search') {
      text = this.getSubText();
    }

    if (!text) {
      return null;
    }

    return <div className={cssStyles.contactDesc}>{text}</div>;
  }

  render() {
    const { contact } = this.props;

    if (!contact) {
      return null;
    }

    const { name } = contact;

    return (
      <div className={cssStyles.flex}>
        <div className={`${cssStyles.contactDetails} ${cssStyles['p-0']}`}>
          <div className={cssStyles.contactName}>{name}</div>
          {this.getContactSubText()}
        </div>
      </div>
    );
  }
}

export default IntlWrapper(SearchUtilContact, translations);
