import * as React from 'react';
import cx from 'classnames';
import iconPaths, { IconName } from './icon-paths';

import css from './Icon.css';

const defaultProps = {
  size: '1em',
  color: 'currentColor',
  as: 'icon',
};

type Props = Omit<React.SVGProps<SVGSVGElement>, 'onClick'> & {
  as?: 'icon' | 'button';
  name: IconName;
  size?: string;
  color?: string;
  onClick?: () => void;
};

export default function Icon(props: Props) {
  const { name, size, color, as, className, ...rest } = {
    ...defaultProps,
    ...props,
  };

  if (!(name in iconPaths)) {
    throw new Error(`Icon name "${name}" doesn't exist.`);
  }

  const { viewBox = iconPaths[name].viewBox } = props;

  const { path } = iconPaths[name];

  const svg = (
    <svg
      className={cx(css.Icon, { [className]: as === 'icon' })}
      width={size}
      viewBox={viewBox}
      fill={color}
      stroke={color}
      strokeWidth='0'
      {...rest}
    >
      {path}
    </svg>
  );

  if (as === 'button') {
    return (
      <button
        className={cx(css.Button, className)}
        onClick={() => rest?.onClick()}
        type='button'
      >
        {svg}
      </button>
    );
  }

  return svg;
}
