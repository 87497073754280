import React from 'react';
import SlashCommandManager from './SlashCommandManager';

const SlashCommandManagerHOC = function (ComponentToWrap) {
  return function (props) {
    return (
      <SlashCommandManager>
        {(commandManager) => (
          <ComponentToWrap {...props} commandManager={commandManager} />
        )}
      </SlashCommandManager>
    );
  };
};

export default SlashCommandManagerHOC;
