import React, { useState, useCallback } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import classNames from 'classnames';
import { Icon } from '@talk-to/flock-components';

import Modal from '@/components/ExtendableModal';
import { actions } from '@/actions/preferences';
import noop from '@/utils/noop';
import { logMedusaGenericEvent } from '@/utils/logAnalytics';

import Profile from './Profile';
import Notifications from './Notifications';
import Themes from './Themes';
import styles from './Preferences.css';

const PROFILE = 'profile';
const NOTIFICATIONS = 'notifications';
const THEME = 'themes';

const MenuItemIcon = (props) => {
  return (
    <span className={styles['icon-container']}>
      <Icon {...props} />
    </span>
  );
};

const preferenceOptions = [
  {
    icon: <MenuItemIcon size='22px' name='profile' />,
    label: 'Profile',
    id: PROFILE,
  },
  {
    icon: <MenuItemIcon size='18px' name='bell' />,
    label: 'Notifications',
    id: NOTIFICATIONS,
  },
  { icon: <MenuItemIcon size='22px' name='eye' />, label: 'Themes', id: THEME },
];

const tabLabelById: Record<string, string> = preferenceOptions.reduce(
  (acc, option) => {
    acc[option.id] = option.label;
    return acc;
  },
  {}
);

const ComponentMap = {
  [PROFILE]: Profile,
  [NOTIFICATIONS]: Notifications,
  [THEME]: Themes,
};
interface StoreState {
  preferences: {
    open: boolean;
  };
}

function mapStateToProps({ preferences: { open } }: StoreState) {
  return {
    open,
  };
}

const connector = connect(mapStateToProps, actions);

type PropsFromRedux = ConnectedProps<typeof connector>;

function Preferences({ open, closePreferences }: PropsFromRedux) {
  const [preferenceOption, setPreferenceOption] = useState(
    preferenceOptions[0].id
  );

  const Component = ComponentMap[preferenceOption];

  const handleTabSelect = useCallback((id: string) => {
    setPreferenceOption(id);
    logMedusaGenericEvent(
      `preferences_${tabLabelById[id].toLowerCase()}_tab_clicked`
    );
  }, []);

  const handleCloseModal = useCallback(() => {
    setPreferenceOption(preferenceOptions[0].id);
    closePreferences();
  }, [setPreferenceOption, closePreferences]);

  return (
    <Modal
      onOverlayClick={handleCloseModal}
      isVisible={open}
      height='600px'
      width='850px'
      onEscape={handleCloseModal}
    >
      <div className={styles.preferences}>
        <header className={styles.heading}>
          <div>Preferences</div>
          <Icon
            className={styles.close}
            as='button'
            name='close'
            onClick={handleCloseModal}
            size='1.1em'
          />
        </header>
        <div className={styles.section}>
          <div className={styles.tabs}>
            <ul className={styles.menu}>
              {preferenceOptions.map(({ icon, label, id }) => (
                <li
                  key={id}
                  className={classNames(styles.menuItems, {
                    [styles.selected]: id === preferenceOption,
                  })}
                >
                  <div
                    onClick={() => handleTabSelect(id)}
                    role='button'
                    onKeyDown={noop}
                    tabIndex={0}
                  >
                    {icon}
                    <span className={styles.label}>{label}</span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className={styles.panel}>
            <Component />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default connector(Preferences);
