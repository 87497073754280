/* eslint-disable react/sort-comp */
import React, { Fragment, PureComponent } from 'react';
import memoize from 'memoize-one';
import difference from 'lodash/difference';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import bridge from '@/utils/bridge';
import { arrayMove, maybe } from '@/utils';
import {
  subscribe as subscribeKeyboard,
  SEARCH_MESSAGES,
  unsubscribe as unsubscribeKeyboard,
} from '@/utils/KeyboardShortcuts';
import { doAppAction } from '@/utils/ShellUtils';
import appListHOC from '@/subscribeHOCs/appList';
import CurrentPeerHOC from '@/subscribeHOCs/currentPeer';
import offlineImage from '@/assets/no-internet-state.svg';

import customApps, {
  DIRECTORY,
  SIDEBAR_CATEGORY,
  getCustomApps,
} from '@/utils/customApps';
import Loader from '@/components/common/Loader';
import RosterContainer from '@/containers/RosterContainer';
import ErrorBoundary from '@/components/widgets/ErrorBoundary';
import store from '@/store';
import { actions as searchModalActions } from '@/actions/search';
import noop from '@/utils/noop';
import platform from '@/utils/platform';
import AppList from '../appList/AppList';
import cssStyles from './Sidebar.css';
import Offline from '../common/Offline';

const iframeRef = React.createRef();
const CONNECTED = 'connected';
const flockEventParams = {
  name: 'client.pressButton',
  button: SIDEBAR_CATEGORY,
};
const INITIAL_STATE = {
  url: 'about:blank',
  name: '',
  id: '',
  loading: false,
  CustomApp: () => null,
};
const eventsToIgnore = ['resizeAttachmentView'];

function getSortVal(app) {
  return maybe(app, 'properties', 'priorities', SIDEBAR_CATEGORY) || 0;
}

function openSearchModal(app) {
  store.dispatch(searchModalActions.openSearchApp(app));
}

class Sidebar extends PureComponent {
  static appData = null;

  static claimToken = null;

  state = {
    ...INITIAL_STATE,
    open: false,
    connected: true,
    DIRECTORY_APP: null,
  };

  localSubscriptions = [];

  $sidebarContainer = document.getElementById('sidebar');

  static defaultProps = {
    currentSessionId: null,
  };

  static propTypes = {
    onCurrentSessionChange: PropTypes.func.isRequired,
    getSidebarState: PropTypes.func.isRequired,
    setSidebarState: PropTypes.func.isRequired,
    saveAppsOrder: PropTypes.func.isRequired,
    appList: PropTypes.array.isRequired,
    badges: PropTypes.array.isRequired,
    appsOrder: PropTypes.array.isRequired,
    currentSessionId: PropTypes.string,
  };

  onConnectionStateChange = debounce((_, connected) => {
    console.log('onConnectionStateChange Clicked');
    this.setState({
      connected: connected === CONNECTED,
    });
  }, 200);

  async componentDidMount() {
    const { onCurrentSessionChange, currentSessionId } = this.props;
    window.addEventListener('message', this.onPostMessage);
    this.fetchCustomApps();
    this.localSubscriptions.push(
      bridge.subscribe('/odara-sidebar-openApp', this.hydraOpenHandler),
      bridge.subscribe('/odara-sidebar-toggle', this.closeApp),
      bridge.subscribe('/odara-recent-contacts-clicked', this.openDirectory),
      bridge.subscribe(
        '/connectionState/changed',
        this.onConnectionStateChange
      ),
      bridge.subscribe('/themeManager/currentTheme/change', this.restoreApp)
    );
    /* // TODO: Keyboard shortcut for sidebar
        this.unubscribeDirectory = subscribe(
          DIRECTORY_SHORTCUT_ID,
          this.openDirectory
      ); */

    const { searchAppId, appLaunchRestoreDenyList } = await platform.getAll();
    this.startupConfig = {
      searchAppId,
      appLaunchRestoreDenyList,
    };
    subscribeKeyboard(SEARCH_MESSAGES, this.openSearch);
    onCurrentSessionChange(currentSessionId);
  }

  componentDidUpdate({ currentSessionId: prevSessionId }) {
    const { currentSessionId, onCurrentSessionChange } = this.props;
    if (currentSessionId !== prevSessionId) {
      onCurrentSessionChange(currentSessionId);
      this.restoreApp(currentSessionId);
    }
  }

  componentWillUnmount() {
    bridge.unsubscribeAll(this.localSubscriptions);
    this.localSubscriptions = null;
    unsubscribeKeyboard(SEARCH_MESSAGES, this.openSearch);
    // TODO: Keyboard shortcut for sidebar
    /* this.unubscribeDirectory(); */
    window.removeEventListener('message', this.onPostMessage);
  }

  openSearch = () => {
    const { appList } = this.props;
    const searchApp = appList.find(
      ({ id }) => id === this.startupConfig.searchAppId
    );
    this.tellShellToOpen(searchApp);
  };

  onPostMessage = (event) => {
    if (
      event &&
      event.data &&
      event.data.claimToken === Sidebar.claimToken &&
      Sidebar.appData &&
      event.source !== window &&
      eventsToIgnore.indexOf(event.data.name) === -1
    ) {
      if (event.data.request) {
        bridge
          .ask('Shell', 'handleAppRequest', [event.data, Sidebar.appData])
          .then((responseObj) => event.source.postMessage(responseObj, '*'));
      } else if (event.data.name === 'close') {
        this.closeApp();
      } else {
        bridge.tell('Shell', 'handleAppRequest', [event.data, Sidebar.appData]);
      }
    }
  };

  setSidebarState(name, id, url, connected = true) {
    this.$sidebarContainer.classList.add('open');
    const hideLoading = (cb = noop) => {
      this.setState(
        {
          loading: false,
        },
        cb
      );
    };
    this.setState(
      {
        open: true,
        url,
        name,
        id,
        loading: true,
        showOffline: !connected,
      },
      () => {
        const sid = setTimeout(hideLoading, 10000);
        if (iframeRef.current) {
          /* Clear loading state in case onload event is delayed. */
          iframeRef.current.onload = () => {
            hideLoading(() => {
              clearTimeout(sid);
              iframeRef.current.onload = null;
            });
          };
        }
      }
    );
  }

  /** Memoizes the ordered appList and returns it.
   * @param {Array} appList - list of apps for this team from props
   * @param {Array} badges from props
   * @param {Array} appsOrder - order of appIds from IndexedDB
   */
  getOrderedAppList = memoize((appList, directoryApp, badges, appsOrder) => {
    const apps = new Map();
    if (directoryApp) {
      apps.set(directoryApp.id, directoryApp);
    }
    /* Add badges */
    appList.forEach((application) => {
      const app = { ...application };
      apps.set(app.id, app);
      app.hasBadge = badges.indexOf(app.id) > -1;
    });
    /* Order the apps */
    const unorderedAppIds = difference([...apps.keys()], appsOrder);
    if (appsOrder.length === 0) {
      const toBeOrdered = unorderedAppIds.map((appId) => apps.get(appId));
      toBeOrdered.sort((app1, app2) => getSortVal(app2) - getSortVal(app1));
      return toBeOrdered.filter((app) => app);
    }
    return appsOrder
      .concat(unorderedAppIds)
      .map((appId) => apps.get(appId))
      .filter((id) => id);
  });

  tellShellToOpen = (app) => {
    const { currentSessionId, currentPeer: peer } = this.props;

    if (app.id === this.startupConfig.searchAppId) {
      openSearchModal(app);
    } else if (customApps.indexOf(app.id) > -1) {
      this.openApp(app);
    } else {
      doAppAction(
        app.appLauncherButton.action,
        app,
        peer, // Add jid and ownerGuid object
        currentSessionId,
        flockEventParams,
        'sidebar'
      );
    }
  };

  /**
   * Opens and app in the Sidebar.
   * @param {Object} app The app object
   * @param {String} openUrl Open this app URL instead of default app URL
   */
  openApp = async (app, openUrl) => {
    const { open, connected } = this.state;
    const { currentSessionId, setSidebarState, currentPeer: peer } = this.props;
    await this.closeApp(open);

    Sidebar.appData = {
      app,
      peer,
      flockEventParams,
      sessionId: currentSessionId,
    };
    const { id, name } = app;
    this.setState({
      loading: true,
    });
    /* If this is a custom app, open it differently */
    if (customApps.indexOf(app.id) > -1) {
      this.openCustomAppInSidebar(name, id);
      setSidebarState(currentSessionId, false, {
        containerId: DIRECTORY,
        app: {
          name,
          id,
        },
      });
    } else if (openUrl) {
      this.setSidebarState(name, id, openUrl, connected);
      setSidebarState(currentSessionId, false, {
        containerId: 'app',
        app: {
          name,
          id,
        },
      });
    }
  };

  closeApp = (keepOpen = false) => {
    const { setSidebarState, currentSessionId } = this.props;
    Sidebar.appData = null;
    return new Promise((resolve) => {
      this.setState(
        {
          ...INITIAL_STATE,
          open: keepOpen,
        },
        () => {
          if (!keepOpen) {
            this.$sidebarContainer.classList.remove('open');
          }
          setSidebarState(currentSessionId, true);
          resolve(true);
        }
      );
    });
  };

  openDirectory = () => {
    const { DIRECTORY_APP } = this.state;
    if (DIRECTORY_APP) {
      this.openApp(DIRECTORY_APP);
    }
  };

  hydraOpenHandler = ({ app, claimToken }, icon, url) => {
    Sidebar.claimToken = claimToken;
    this.openApp(app, url);
  };

  saveAppsOrder = ({ oldIndex, newIndex }) => {
    const {
      appList,
      badges,
      appsOrder,
      currentSessionId,
      saveAppsOrder,
    } = this.props;

    const { DIRECTORY_APP } = this.state;

    const orderedAppIds = this.getOrderedAppList(
      appList,
      DIRECTORY_APP,
      badges,
      appsOrder
    ).map((app) => app.id);

    saveAppsOrder(
      currentSessionId,
      arrayMove(orderedAppIds, oldIndex, newIndex)
    );
  };

  /**
   * Restores the app that was previously open for a particular session.
   * @param {String} currentSessionId - The current session ID. Needed so that we can read data from IndexedDb.
   */
  restoreApp = (sessionId) => {
    const { getSidebarState, currentSessionId } = this.props;

    if (typeof sessionId !== 'string' && currentSessionId) {
      sessionId = currentSessionId;
    }

    this.setState(INITIAL_STATE);

    getSidebarState(sessionId).then((appObj) => {
      if (appObj) {
        const { containerId, app } = appObj;
        const { appList } = this.props;
        if (app && containerId === 'app') {
          const { id: appId } = app;
          const appToRestore = appList.find(({ id }) => id === appId);
          const appCanBeRestored = !this.startupConfig.appLaunchRestoreDenyList.includes(
            appToRestore.id
          );

          if (appToRestore && appCanBeRestored) {
            this.tellShellToOpen(appToRestore);
          }
        } else if (containerId === DIRECTORY) {
          this.openDirectory();
        } else {
          this.closeApp();
        }
      } else {
        this.closeApp();
      }
    });
  };

  fetchCustomApps() {
    getCustomApps().then(({ DIRECTORY_APP }) =>
      this.setState({
        DIRECTORY_APP,
      })
    );
  }

  openCustomAppInSidebar(name, id) {
    if (id === DIRECTORY) {
      this.setState({ CustomApp: RosterContainer });
    }
    /* Add more conditions for more custom apps in the future. */
    this.setSidebarState(name, id, INITIAL_STATE.url);
  }

  render() {
    const { appList, currentSessionId, badges, appsOrder } = this.props;

    const {
      url,
      name,
      open,
      id,
      loading,
      showOffline,
      DIRECTORY_APP,
      CustomApp,
    } = this.state;

    const orderedAppList = this.getOrderedAppList(
      appList,
      DIRECTORY_APP,
      badges,
      appsOrder
    );
    if (currentSessionId) {
      return (
        <aside className={cssStyles.aside}>
          <img
            style={{
              position: 'absolute',
              right: '999999999px',
            }}
            src={offlineImage}
            alt='No Internet'
          />
          {open ? (
            <div className={cssStyles.sidebar}>
              <div className={cssStyles.appName}>
                {name}
                <i
                  role='button'
                  onKeyDown={() => {}}
                  tabIndex={0}
                  name='close'
                  onClick={() => this.closeApp()}
                  className={cssStyles.close}
                />
              </div>
              {url !== INITIAL_STATE.url ? (
                <div className={cssStyles.appContainer}>
                  <Offline show={showOffline} onRetryClick={this.restoreApp} />
                  {showOffline ? null : (
                    <Fragment>
                      <Loader size='1.5em' loading={loading} />
                      <iframe
                        ref={iframeRef}
                        title={name}
                        src={url}
                        frameBorder='0'
                        sandbox='allow-same-origin allow-scripts allow-forms'
                      />
                    </Fragment>
                  )}
                </div>
              ) : (
                <div className={cssStyles.appContainer}>
                  <CustomApp />
                </div>
              )}
            </div>
          ) : null}
          <ErrorBoundary>
            <AppList
              sessionId={currentSessionId}
              selectedAppId={id}
              openApp={this.tellShellToOpen}
              appList={orderedAppList}
              onSortEnd={this.saveAppsOrder}
            />
          </ErrorBoundary>
        </aside>
      );
    }
    return null;
  }
}

export default appListHOC(CurrentPeerHOC(Sidebar), SIDEBAR_CATEGORY);
