import React, { Component } from 'react';
import commonStyles from '@/components/common/common.css';
import noop from '@/utils/noop';
import Tippy from '@tippyjs/react';
import audioIcon from '@/assets/audio.svg';

const audioStyle = {
  WebkitMask: `url(${audioIcon}) 0px 0px / cover no-repeat`,
};

export default class AVConferenceAppsComponent extends Component {
  render() {
    const {
      className,
      tooltip,
      style,
      appId,
      isVideoRecordingEnabled,
      mode,
      callOptionClick,
      toggleMenu,
    } = this.props;

    return (
      <Tippy
        maxWidth={200}
        content={mode === 'video' ? tooltip : 'Start an audio conference'}
        arrow
        className={commonStyles.info_tooltip}
      >
        <span
          className={className}
          style={mode === 'video' ? style : audioStyle}
          onKeyPress={noop}
          tabIndex={-1}
          role='button'
          data-chat-header-app-id={appId}
          onClick={(e) => {
            if (!isVideoRecordingEnabled) {
              callOptionClick(
                {
                  enableRecording: false,
                  startWithoutVideo: mode === 'audio',
                },
                mode
              );
            } else {
              toggleMenu(e, mode);
            }
          }}
        />
      </Tippy>
    );
  }
}
