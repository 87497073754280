export default {
  join_group_description: 'Descubrir canales p\u00fablicos y unirse a ellos',
  discover_group_disc:
    'A continuaci\u00f3n se muestra la lista de canales p\u00fablicos en tu equipo.',
  start_new_channel_link_text: 'Inicia un nuevo canal',
  no_results_found: 'No se han encontrado resultados',
  no_open_groups: 'Parece que no hay canales p\u00fablicos a los que unirse',
  no_open_groups_description:
    'Los canales p\u00fablicos permiten a su equipo descubrir y unirse a conversaciones con facilidad. P. ej. {example}',
  no_open_groups_description_example:
    'equipo de marketing, ideas e inspiraci\u00f3n',
  create_a_channel: 'Inicia un nuevo canal',
  public_channel_guest_restriction_heading: 'Discover and Join Public Channels',
  public_channel_guest_restriction_message:
    '\u00a1Vaya! Los invitados en Flock no pueden ver o crear canales p\u00fablicos en este equipo.',
  okay: 'Okay',
};
