import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import {
  ownerGuidSelector,
  currentConversationSelector,
  allContactsSelector,
} from '@/selectors';

const conversationAssociates = (state) =>
  state.restricted.contact.conversation_associates;

const currentConversationAssociatesSelector = createSelector(
  ownerGuidSelector,
  currentConversationSelector,
  conversationAssociates,
  (ownerGuid, currentConversationId, associates) => {
    if (
      !associates ||
      !associates[ownerGuid] ||
      !associates[ownerGuid][currentConversationId]
    ) {
      return [];
    }

    return associates[ownerGuid][currentConversationId];
  }
);

const conversationAssociateObjectsSelector = createSelector(
  currentConversationAssociatesSelector,
  allContactsSelector,
  (associates, allContacts) =>
    associates
      .map((associate) => {
        const { jid, affiliation } = associate;
        const contact = allContacts[jid];
        if (contact) {
          return {
            ...contact,
            affiliation,
          };
        }
        return null;
      })
      .filter((a) => !!a)
);

function mapStateToProps(state) {
  const associates = conversationAssociateObjectsSelector(state);
  return {
    associates,
  };
}

export default function (component) {
  return connect(mapStateToProps)(component);
}
