/* eslint-disable import/prefer-default-export, prefer-destructuring, no-prototype-builtins */
import { FroalaEditorType } from '../Types';

/* Getting selection start and selectionEnd is little tricky.
   First we get the selection range. After that we create one range which will start from start and end at selection range's start container and offset.
   Now we can get text from start to selectionStart using Range.toString(). Similarly we can get text in selection from selection range's toString method.
   But the problem is requesting text from range doesn't include BR. So that way we miss out every new line character from text.
   So to overcome this problem we get all BRs from editor and find the number of BRs which are in missing.
   Since BRs are all in dom order if BR node at index i is in range the node at index i - 1 will also be in the range because we are creating range from start.
   So we can use binary search to find last BR node which is present in this range. Then we include this number to appropriate selection properties.  
*/

export const getSelection = (editor: FroalaEditorType) => {
  const { rangeTillStart, rangeTillEnd } = createStartAndEndRanges(editor);
  const {
    elementsTillSelectionStart,
    differenceBetweenStartAndEnd,
  } = getCountOfSpecialElements(editor, rangeTillStart, rangeTillEnd);
  const selectionStart: number =
    (rangeTillStart.toString()?.length || 0) + elementsTillSelectionStart;
  const selectionEnd: number =
    selectionStart +
    (editor.selection.text()?.length || 0) +
    differenceBetweenStartAndEnd;
  rangeCleanup([rangeTillStart, rangeTillEnd]);
  return {
    selectionStart,
    selectionEnd,
  };
};

const createStartAndEndRanges = (editor: FroalaEditorType) => {
  const rangeTillStart: Range = editor.doc.createRange();
  const rangeTillEnd: Range = editor.doc.createRange();
  const selectionRange: Range = editor.selection.ranges(0);
  rangeTillStart.setStart(editor.el, 0);
  rangeTillStart.setEnd(
    selectionRange.startContainer,
    selectionRange.startOffset
  );
  rangeTillEnd.setStart(editor.el, 0);
  rangeTillEnd.setEnd(selectionRange.endContainer, selectionRange.endOffset);
  return {
    rangeTillStart,
    rangeTillEnd,
  };
};

const checkBrsInRange = (range: Range, allBrs: HTMLCollection): number => {
  const start: number = 0;
  const end: number = allBrs.length - 1;
  const index: number = binarySearch(
    start,
    end,
    allBrs,
    (BRElement: HTMLBRElement) => {
      // The Range.comparePoint() method returns -1, 0, or 1 depending on whether the referenceNode is before, the same as, or after the Range.
      return range.comparePoint(BRElement, 0);
    }
  );
  return index + 1 || 0;
};

const binarySearch = (
  start: number,
  end: number,
  array: HTMLCollection,
  comparator: Function
): number => {
  let answer: number = -1;
  while (start <= end) {
    // Find the middle index
    const mid: number = Math.floor((start + end) / 2);
    const comparatorValue: number = comparator(array[mid]);
    // in right subarray
    if (comparatorValue <= 0) {
      answer = mid;
      start = mid + 1;
    }
    // then find in left subarray
    else {
      end = mid - 1;
    }
  }
  return answer;
};

const rangeCleanup = (rangeArray: Array<Range>) => {
  rangeArray.forEach((range: Range) => range.detach());
};

export const saveSelection = (editor: FroalaEditorType) => {
  try {
    if (!editor) return;
    // try to use selection range if it is in editor. otherwise use stored selection range. For safari selection range gets changed before blur event so it is needed for safari.
    let selectionRange: Range | undefined = editor.selection.ranges(0);
    if (!editor.selection.inEditor()) {
      selectionRange = editor.selectionRange;
    }
    editor.markers.remove(); // remove old markers
    if (selectionRange) {
      editor.markers.place(selectionRange, true, 0);
      editor.markers.place(selectionRange, false, 0);
    } else {
      editor.selection.save();
    }
  } catch {
    // fail silently
  }
};

export const getHtmlContentWithMarkers = (editor: FroalaEditorType): string => {
  try {
    if (!editor) return '';
    saveSelection(editor);
    const htmlContentWithMarker = editor.html.get(true);
    editor.selection.restore();
    return htmlContentWithMarker;
  } catch {
    return '';
  }
};

const getCountOfSpecialElements = (editor, rangeTillStart, rangeTillEnd) => {
  const { BRsTillSelectionStart, BRsTillSelectionEnd } = getCountOfBrElements(
    editor,
    rangeTillStart,
    rangeTillEnd
  );
  const {
    ImgsTillSelectionStart,
    ImgsTillSelectionEnd,
  } = getCountOfImgElements(editor, rangeTillStart, rangeTillEnd);

  const elementsTillSelectionStart =
    BRsTillSelectionStart + ImgsTillSelectionStart;
  const elementsTillSelectionEnd = BRsTillSelectionEnd + ImgsTillSelectionEnd;

  const differenceBetweenStartAndEnd =
    elementsTillSelectionEnd - elementsTillSelectionStart;
  return { elementsTillSelectionStart, differenceBetweenStartAndEnd };
};

const getCountOfBrElements = (editor, rangeTillStart, rangeTillEnd) => {
  const allBrs: HTMLCollection = editor.el.getElementsByTagName('BR');
  const BRsTillSelectionEnd: number = checkBrsInRange(rangeTillEnd, allBrs);
  const BRsTillSelectionStart: number = checkBrsInRange(rangeTillStart, allBrs);
  return { BRsTillSelectionStart, BRsTillSelectionEnd };
};

const getCountOfImgElements = (editor, rangeTillStart, rangeTillEnd) => {
  let ImgsTillSelectionStart = 0;
  let ImgsTillSelectionEnd = 0;
  const allImgs = editor.el.getElementsByTagName('IMG');
  for (let i = 0; i < allImgs.length; i += 1) {
    const ImgElement = allImgs[i];
    const lengthOfEmoji = ImgElement.getAttribute('data-emoji-native')?.length;
    if (rangeTillStart.comparePoint(ImgElement, 0) <= 0) {
      ImgsTillSelectionStart += lengthOfEmoji;
      ImgsTillSelectionEnd += lengthOfEmoji;
    } else if (rangeTillEnd.comparePoint(ImgElement, 0) <= 0) {
      ImgsTillSelectionEnd += lengthOfEmoji;
    } else {
      break;
    }
  }
  return { ImgsTillSelectionStart, ImgsTillSelectionEnd };
};
