import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import scrollWrapper from '@/Wrappers/ScrollWrapper';
import { setCurrentSession } from '@/utils/SessionUtils';
import { arrayMove } from '@/utils';
import sessionsSubHOC from '@/subscribeHOCs/allSessions';
import SortableList from '@/components/widgets/sortable/SortableList';
import TeamButtonContainer from '@/containers/TeamButtonContainer';
import bridge from '@/utils/bridge';
import platform from '@/utils/platform';
import { subscribe, unsubscribe, SWITCH_TEAM } from '@/utils/KeyboardShortcuts';
import cssStyles from './TeamSwitcher.css';

const ONE_SECOND = 1000;
const SESSION_ORDER_KEY = 'teamSwitcher';
const STORE_NAME = 'sortable_store';

const isMac = navigator.platform && navigator.platform.indexOf('Mac') !== -1;

function getKey(showShortcuts, index) {
  if (showShortcuts && index < 9) {
    return `${isMac ? String.fromCharCode(0x2318) : 'Ctrl+'}${index + 1}`;
  }
  return null;
}

const saveTeamsOrder = debounce((orderedSessionIds) => {
  bridge.ask('Storage', 'set', [
    STORE_NAME,
    SESSION_ORDER_KEY,
    orderedSessionIds.join('|'),
  ]);
}, ONE_SECOND);

function getScrollState(element) {
  const teamButtons = element.querySelectorAll('[status]');
  const wrapperRect = element.getBoundingClientRect();

  // using diffHeight as 1px to overcome precision issues
  // with getBoundingClientRect and scrollHeight values
  const diffHeight = 1;
  let scrollState = {
    hasTopBadge: false,
    hasBottomBadge: false,
    showTop: false,
    showBottom: false,
  };

  for (let i = 0; i < teamButtons.length; i += 1) {
    const button = teamButtons[i];
    const status = button.getAttribute('status');
    if (status !== '') {
      const buttonRect = button.getBoundingClientRect();
      const hasTopBadge = buttonRect.top < wrapperRect.top + 25 - 10;
      const hasBottomBadge = buttonRect.top > wrapperRect.bottom - 25 - 10;

      if (hasTopBadge) {
        scrollState = {
          ...scrollState,
          hasTopBadge,
          topBadgeClass: button.getAttribute('status'),
        };
      }
      if (hasBottomBadge) {
        scrollState = {
          ...scrollState,
          hasBottomBadge,
          bottomBadgeClass: button.getAttribute('status'),
        };
      }
    }
  }

  const { height } = wrapperRect;
  scrollState = {
    ...scrollState,
    showTop: element.scrollTop > diffHeight,
    showBottom:
      element.scrollHeight - (element.scrollTop + height) > diffHeight,
  };

  return scrollState;
}

/**
 * @class TeamSwitcher
 * @classdesc List of session buttons
 *
 * @extends {Component}
 *
 * @param {object} props
 * @param {object} props.sessions List of all sessions
 * @param {string} [props.currentSessionId] Current active session ID
 * @param {boolean} [props.isHorizontal] Show horizontal list
 * @param {object} [props.theme] Global and container specific theme settings
 * @param {function} [props.onTeamSwitch] Callback that handles buttons clicks
 *
 * @param {object} state
 * @param {boolean} state.isSorting If user is sorting the list
 */
class TeamSwitcher extends PureComponent {
  constructor(props) {
    super(props);

    this.onSortStart = this.onSortStart.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);

    this.state = {
      isSorting: false,
      expandedState: false,
      showShortcuts: false,
      orderedSessionIds: [],
    };
  }

  componentDidMount() {
    platform.isDesktopClient().then((isDesktopClient) => {
      this.setState({ showShortcuts: isDesktopClient });
    });
    bridge
      .ask('Storage', 'get', [STORE_NAME, SESSION_ORDER_KEY])
      .then((response) => {
        if (response && response.split) {
          this.setState({
            orderedSessionIds: response.split('|'),
          });
        }
      });
    subscribe(SWITCH_TEAM, this.onKeyboardSwitchTeam, 'onKeyDown');
  }

  componentDidUpdate(prevProps) {
    const { sessions } = this.props;
    if (
      Object.keys(prevProps.sessions).length !== Object.keys(sessions).length
    ) {
      const { onUpdate } = this.props;
      if (typeof onUpdate === 'function') {
        onUpdate();
      }
    }
  }

  componentWillUnmount() {
    unsubscribe(SWITCH_TEAM, this.onKeyboardSwitchTeam, 'onKeyDown');
  }

  onKeyboardSwitchTeam = (event) => {
    const { showShortcuts } = this.state;

    if (!showShortcuts) {
      return;
    }

    const { keyCode } = event;
    if (keyCode >= 49 && keyCode <= 57) {
      this.onShortcutClick(keyCode - 48);
    }
    if (keyCode >= 97 && keyCode <= 105) {
      // windows numpad keys
      this.onShortcutClick(keyCode - 96);
    }
  };

  /** Memoizes the order of sessions from props based on order from state
   * @param sessions - List of sessions from props
   * @param orderedSessionIds - Ordered list of sessionIds
   */
  getOrderedSessions = memoize((sessions, orderedSessionIds) => {
    const orderedSessions = [];

    /* Push all sessions in the order */
    orderedSessionIds.forEach((sessionId) => {
      const session = sessions[sessionId];
      if (session) {
        orderedSessions.push(session);
      }
    });

    /* Push sessions that were not in the ordered list of sessionIds */
    Object.values(sessions).forEach((session) => {
      if (!orderedSessionIds.includes(session.id)) {
        orderedSessions.push(session);
      }
    });
    return orderedSessions;
  });

  onSortStart() {
    this.setState({
      isSorting: true,
    });
  }

  onSortEnd({ oldIndex, newIndex }) {
    this.setState(
      (prevState) => {
        const { sessions } = this.props;
        const prevSessionOrder = this.getOrderedSessions(
          sessions,
          prevState.orderedSessionIds
        ).map((session) => session.id);
        const orderedSessionIds = arrayMove(
          prevSessionOrder,
          oldIndex,
          newIndex
        );
        return {
          isSorting: false,
          orderedSessionIds,
        };
      },
      () => {
        const { orderedSessionIds } = this.state;
        saveTeamsOrder(orderedSessionIds);
      }
    );
  }

  onShortcutClick(val) {
    const { onTeamSwitch, sessions } = this.props;
    const { orderedSessionIds } = this.state;
    const orderedSessions = this.getOrderedSessions(
      sessions,
      orderedSessionIds
    );
    if (orderedSessions[val - 1]) {
      onTeamSwitch(orderedSessions[val - 1].id);
    }
  }

  render() {
    const { isSorting, expandedState, orderedSessionIds } = this.state;
    const {
      sessions,
      isHorizontal,
      currentSessionId,
      onTeamSwitch,
    } = this.props;

    const { showShortcuts } = this.state;

    const orderedSessions = this.getOrderedSessions(
      sessions,
      orderedSessionIds
    );

    const teamSwitcherClassName = classNames(cssStyles.teamSwitcher, {
      [cssStyles.isHorizontal]: isHorizontal,
    });

    const teamsListClasses = classNames(cssStyles.sortableList, {
      [cssStyles.expanded]: expandedState,
    });

    return (
      <div className={teamSwitcherClassName}>
        <SortableList
          axis={isHorizontal ? 'xy' : 'y'}
          className={teamsListClasses}
          lockAxis={isHorizontal ? '' : 'y'}
          onSortStart={this.onSortStart}
          onSortEnd={this.onSortEnd}
          // pressDelay={0}
          // distance={10}
          helperClass='buttonDragging'
        >
          {orderedSessions.map((session, index) => (
            <TeamButtonContainer
              index={index}
              key={session.id}
              onClick={onTeamSwitch}
              isActive={currentSessionId === session.id}
              ownerGuid={session.owner.ownerGuid}
              disableTooltip={isSorting}
              disableContextMenu={isHorizontal}
              keyboardShortcut={getKey(showShortcuts, index)}
              {...session}
            />
          ))}
        </SortableList>
      </div>
    );
  }
}

TeamSwitcher.propTypes = {
  sessions: PropTypes.object.isRequired,
  currentSessionId: PropTypes.string,
  isHorizontal: PropTypes.bool,
  isMenuTeamSwitcher: PropTypes.bool,
  onTeamSwitch: PropTypes.func,
};

TeamSwitcher.defaultProps = {
  currentSessionId: '',
  isHorizontal: false,
  isMenuTeamSwitcher: false,
  onTeamSwitch: setCurrentSession,
};

export default scrollWrapper(sessionsSubHOC(TeamSwitcher), getScrollState);
