import difference from 'lodash/difference';
import events from './events';
import * as MODIFIERS from './keyDefinitions';

const availableModifiers = Object.values(MODIFIERS);

/* Only one of the event modifiers should be pressed and rest should not be pressed */
function checkModifiers(eventModifiers, browserEvent) {
  const excludedModifiers = difference(availableModifiers, eventModifiers);
  return (
    excludedModifiers.every((modifier) => !browserEvent[modifier]) &&
    ((eventModifiers.length > 0 &&
      eventModifiers.every((modifier) => browserEvent[modifier])) ||
      eventModifiers.length === 0)
  );
}

class KeyBoardEvents {
  subscriptions = {
    onKeyDown: [],
    onKeyUp: [],
  };

  eventTypes = Object.keys(this.subscriptions);

  constructor() {
    // React currently uses event delegation from the document level down to the
    // individual elements for its synthetic event system—bypassing the DOM
    // event system. This enables consistency across browsers, which is great.
    // This also allows us to attach events like 'keyup' to non-focusable
    // elements such as a div because React propagates these events up the VDOM.
    //
    // In this case, we need to listen for keyboard shortcuts which are ideally
    // a top-level event hook. Great, so we attach it to our root component and
    // problem solved, right? Not so fast. Thing is, even though React is
    // listening at the document level, if a KeyboardEvent happens above the
    // root element, i.e, when focused element is document.body itself, then our
    // app root handlers won't get called. So, to play nice with React's
    // SyntheticEvent architecture and also to handle cases where focus is on
    // document.body, we need to attach these document listeners.
    document.addEventListener('keyup', this.filterBodyEvents(this.onKeyUp));
    document.addEventListener('keydown', this.filterBodyEvents(this.onKeyDown));
  }

  /**
   * Subscribes to a Keyboard event
   * @param {String} eventId - The `id` of the action you need a shortcut for. Mentioned in `src/utils/eventIds.js`
   * @param {Function} callback - The callback function to be executed on shortcut
   * @param {String} eventType(optional) - When the callback needs to be executed - has to be
   * one of the two - `onKeyDown`, `onKeyUp`.
   * @returns {Function} The unsubscription callback to this subscription.
   * Needs to be called when this callback is no longer needed.
   */
  subscribe = (eventId, callback, eventType = 'onKeyDown') => {
    if (!events[eventId]) {
      const errorMsg = `Event ${eventId} is not available. Are you sure you've added it to src/utils/eventIds.js &
      src/utils/eventsDefinition`;
      console.warn(errorMsg);
      return new Error(errorMsg);
    }

    if (this.eventTypes.indexOf(eventType) < 0) {
      const errorMsg = `Events of type ${eventType} are not being handled here.`;
      console.warn(errorMsg);
      return new Error(errorMsg);
    }

    this.subscriptions[eventType].push({
      event: events[eventId],
      callback,
    });
    return () => {
      this.unsubscribe(eventId, callback, eventType);
    };
  };

  /**
   * Removes the Keyboard event callback. Should be done in `componentWillUnmount` or when you're sure that the
   * keyboard event handler is no longer needed.
   * @param {string} eventId - The `id` of the action you need a shortcut for. Mentioned in `src/utils/eventIds.js`
   * @param {function} callback - The callback reference to be unsubscribed
   * @param {string} eventType - Has to be of one of the three - `onKeyDown`, `onKeyUp`, `onKeyPress`
   */
  unsubscribe = (eventId, callback, eventType = 'onKeyDown') => {
    if (!events[eventId]) {
      console.warn(`Event ${eventId} is not available.`);
      return;
    }

    if (this.eventTypes.indexOf(eventType) < 0) {
      console.warn(`Events of type ${eventType} are not being handled here.`);
      return;
    }

    this.subscriptions[eventType] = this.subscriptions[eventType].filter(
      (sub) => {
        if (sub.event === events[eventId] && sub.callback === callback) {
          return false;
        }
        return true;
      }
    );
  };

  isValidEvent = (browserEvent, event) => {
    const {
      _props: { modifiers, allowedKeyCodes },
    } = event;

    if (allowedKeyCodes && allowedKeyCodes.indexOf(browserEvent.keyCode) < 0) {
      return false;
    }

    if (Array.isArray(modifiers)) {
      // Modifiers is now an array of arrays to allow multiple modifiers.
      // Only one of the modifier array is pressed and none of the other modifiers are pressed.
      return modifiers.some((m) => checkModifiers(m, browserEvent));
    }
    return true;
  };

  /**
   * publishEvent can either receive a SyntheticEvent, courtesy of React's
   * delegation-based event system or a native event if the event happened
   * outside the app root.
   */
  publishEvent(browserEvent, subscriptions) {
    let isEventPublished = false;
    for (let i = 0; i < subscriptions.length; i += 1) {
      const subscription = subscriptions[i];
      if (this.isValidEvent(browserEvent, subscription.event)) {
        // Either case, we need to stop the default action which could be a
        // browser feature such as focusing the address bar and let our custom
        // action take place.
        browserEvent.preventDefault();
        isEventPublished = true;
        subscription.callback(browserEvent);
      }
    }
    if (!isEventPublished && browserEvent?.type === 'keydown') {
      this.publishCustomEvent(browserEvent);
    }
  }

  publishCustomEvent(browserEvent) {
    const customEvent = new CustomEvent('documentKeydown', {
      detail: { nativeEvent: browserEvent },
    });
    document.dispatchEvent(customEvent);
  }

  // We only want our document listeners to get called if our shortcut related
  // events don't happen within the app root and within React's SyntheticEvent
  // architecture. So, we check to see if the focus has been lost from the app
  // root tree to document.body. Then, we want to invoke the appropriate
  // listener and trigger the associated action
  filterBodyEvents = (callback) => {
    return (event) => {
      if (document.activeElement === document.body) {
        callback(event);
      }
    };
  };

  onKeyDown = (event) => {
    this.publishEvent(event, this.subscriptions.onKeyDown);
  };

  onKeyUp = (event) => {
    this.publishEvent(event, this.subscriptions.onKeyUp);
  };
}

const keyboardEvents = new KeyBoardEvents();
export const { subscribe, unsubscribe, onKeyUp, onKeyDown } = keyboardEvents;
