import React, { Fragment } from 'react';
import { converter as emojiConverter } from '@/lib/emoji';
import css from './EmojiDecorator.css';

type Props = {
  text: string;
  enableJumboEmoji?: boolean;
};

function EmojiDecorator({ text, enableJumboEmoji }: Props) {
  if (!text) {
    return null;
  }
  let tokenArray = [];
  try {
    tokenArray = emojiConverter.getTokenizedEmojis(text);
  } catch (error) {
    console.error('error occurred in emoji decorator ', error);
    return <span>{text}</span>;
  }
  const onlyOneEmoji = tokenArray.length === 1 && !tokenArray[0].isText;
  const emojiClass =
    enableJumboEmoji && onlyOneEmoji ? css.jumboEmoji : css.regularEmoji;

  return (
    <React.Fragment>
      {tokenArray.map((node, i) => {
        if (node.isText) {
          // eslint-disable-next-line react/no-array-index-key
          return <Fragment key={i}>{node.value}</Fragment>;
        }
        return (
          <span
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            style={node.value.style}
            className={emojiClass}
          />
        );
      })}
    </React.Fragment>
  );
}

export default EmojiDecorator;
