import React from 'react';

export type IconName = 'smiley' | 'close' | 'muted';

type IconPaths = {
  [key in IconName]: {
    path: React.ReactElement<SVGPathElement>;
    viewBox?: string;
  };
};

const iconPaths: IconPaths = {
  smiley: {
    path: (
      <path
        fill='currentColor'
        d='M20.523 20.523C21.774 19.276 22.4 17.768 22.4 16c0-1.773-.626-3.283-1.877-4.53C19.276 10.223 17.768 9.6 16 9.6c-1.773 0-3.283.623-4.53 1.87-.555.555-.986 1.162-1.294 1.82-.123.263-.227.533-.313.811C9.688 14.697 9.6 15.33 9.6 16c0 1.768.623 3.276 1.87 4.523.365.366.752.68 1.16.938.993.626 2.116.939 3.37.939 1.25 0 2.37-.313 3.356-.939.413-.259.801-.572 1.167-.938zM16 10.353c1.559 0 2.887.552 3.985 1.655 1.103 1.098 1.654 2.429 1.654 3.992 0 1.559-.551 2.887-1.654 3.985-1.098 1.103-2.426 1.654-3.985 1.654-1.563 0-2.894-.551-3.992-1.654-1.103-1.098-1.654-2.426-1.654-3.985 0-.701.11-1.355.33-1.963.107-.29.237-.57.39-.838.25-.424.562-.82.934-1.191 1.098-1.103 2.429-1.654 3.992-1.654zm-1.849 9.31c.612.374 1.228.554 1.849.54.621.014 1.235-.166 1.842-.54 1.057-.707 1.635-1.63 1.735-2.767.01-.194-.254-.284-.427-.185 0 0-.803 1.158-3.15 1.158-1.934 0-3.15-1.158-3.15-1.158-.265-.13-.432-.01-.427.185.11 1.147.685 2.07 1.728 2.766zm.683-4.31l.256.37c.142.175.213.085.213-.27.005-.489-.085-.892-.27-1.21-.213-.331-.446-.495-.697-.49h-.007c-.256-.005-.49.159-.704.49-.185.318-.275.721-.27 1.21 0 .355.07.445.213.27l.256-.37c.128-.213.296-.308.505-.285h.007c.204-.023.37.072.498.285zm1.863.1c0 .355.069.445.206.27l.256-.37c.133-.213.301-.308.505-.285h.007c.204-.023.372.072.505.285l.249.37c.142.175.213.085.213-.27.005-.489-.085-.892-.27-1.21-.209-.331-.44-.495-.697-.49h-.007c-.256-.005-.488.159-.697.49-.185.318-.275.721-.27 1.21z'
      />
    ),
    viewBox: '0 0 32 32',
  },
  close: {
    path: (
      <path
        fill='currentColor'
        d='M13.85 15.97l-3.536-3.535-3.535 3.536a1.5 1.5 0 01-2.122-2.121l3.536-3.536-3.536-3.536a1.5 1.5 0 112.121-2.121l3.536 3.536 3.536-3.536a1.5 1.5 0 112.12 2.122l-3.535 3.535 3.536 3.535a1.5 1.5 0 11-2.122 2.122z'
        fillRule='evenodd'
      />
    ),
    viewBox: '3 3 15 15',
  },
  muted: {
    path: (
      <path
        d='M10.044 57a1.44 1.44 0 001.435-1.436H8.608c0 .79.646 1.436 1.436 1.436zm3.723-2.154H4.3v-.718l1.436-1.436v-3.59c0-.695.117-1.355.34-1.946l-1.489-1.49 1.016-1.015L16.77 55.82l-1.015 1.015-1.99-1.989zm-5.834-9.885c.314-.173.66-.307 1.034-.396v-.488c0-.596.48-1.077 1.077-1.077.595 0 1.077.481 1.077 1.077v.488c2.06.488 3.23 2.333 3.23 4.538v2.277l-6.418-6.42z'
        fill='currentColor'
        fillRule='nonzero'
      />
    ),
    viewBox: '2 42 16 16',
  },
};

export default iconPaths;
