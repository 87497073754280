import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import noop from '@/utils/noop';
import { toggleSidebarState } from '@/utils/ShellUtils';
import IntlWrapper from '@/Wrappers/IntlWrapper';
import currentSessionOwnerGuidHOC from '@/connectHOCs/Sessions/currentSession/ownerGuid';
import translations from '@/components/roster/I18n';
import currentSessionHOC from '@/connectHOCs/Sessions/currentSession';
import cssStyles from '@/components/roster/Contacts.css';
import AllContactsContainer from './AllContactsContainer';
import AllChannelsContainer from './AllChannelsContainer';
import RecentContactsContainer from './RecentContactsContainer';

class RosterContainer extends React.Component {
  static propTypes = {
    currentSession: PropTypes.object,
    ownerGuid: PropTypes.string,
  };

  static defaultProps = {
    currentSession: null,
    ownerGuid: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentTab: 'recentContacts',
    };
    this.onAppContainerClose = this.onAppContainerClose.bind(this);
  }

  onAppContainerClose = () => {
    toggleSidebarState();
  };

  openRecentContacts = () => {
    this.setState({ currentTab: 'recentContacts' });
  };

  openAllChannels = () => {
    this.setState({ currentTab: 'allChannels' });
  };

  openAllBuddies = () => {
    this.setState({ currentTab: 'allBuddies' });
  };

  render() {
    const { currentSession, ownerGuid } = this.props;
    if (!ownerGuid) {
      return <div> Loading </div>;
    }
    let contacts;
    const { currentTab } = this.state;
    if (currentTab === 'allBuddies') {
      contacts = <AllContactsContainer guid={ownerGuid} />;
    } else if (currentTab === 'allChannels') {
      contacts = (
        <AllChannelsContainer
          guid={ownerGuid}
          currentSession={currentSession}
        />
      );
    } else if (currentTab === 'recentContacts') {
      contacts = (
        <RecentContactsContainer
          guid={ownerGuid}
          currentSession={currentSession}
        />
      );
    }
    return (
      <div>
        <div className={cssStyles.roster_tabs}>
          <div
            role='button'
            tabIndex={0}
            onKeyDown={noop}
            className={classNames(
              cssStyles.roster_tab,
              currentTab === 'recentContacts'
                ? cssStyles.roster_tab_selected
                : ''
            )}
            onClick={this.openRecentContacts}
          >
            <span
              className={classNames(
                cssStyles.roster_tab_icon,
                cssStyles.roster_tab_icon_recent
              )}
            />
          </div>
          <div
            role='button'
            tabIndex={0}
            onKeyDown={noop}
            className={classNames(
              cssStyles.roster_tab,
              currentTab === 'allChannels' ? cssStyles.roster_tab_selected : ''
            )}
            onClick={this.openAllChannels}
          >
            <span
              className={classNames(
                cssStyles.roster_tab_icon,
                cssStyles.roster_tab_icon_groups
              )}
            />
          </div>
          <div
            role='button'
            tabIndex={0}
            onKeyDown={noop}
            className={classNames(
              cssStyles.roster_tab,
              currentTab === 'allBuddies' ? cssStyles.roster_tab_selected : ''
            )}
            onClick={this.openAllBuddies}
          >
            <span
              className={classNames(
                cssStyles.roster_tab_icon,
                cssStyles.roster_tab_icon_contacts
              )}
            />
          </div>
        </div>
        <div className={cssStyles.roster_container}>{contacts}</div>
      </div>
    );
  }
}

export default currentSessionOwnerGuidHOC(
  currentSessionHOC(IntlWrapper(RosterContainer, translations))
);
