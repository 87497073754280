import React, { useEffect } from "react";
import { StoreProvider } from "./store";
import { App as EmojiPickerRoot } from "./components/app";
// TODO: Fix typo: Convertor should be Converter
import { Convertor as Converter } from "../convertor";
import pkg from "../../package.json";
import { stickers, themes } from "./defaults";

export const defaultProps = {
  disableDetail: false,
  disableSearch: false,
  disableTabs: false,
  onEmoji: () => {},
  onSticker: () => {},
  onSkin: () => {},
  skin: 1,
  recents: [],
  stickers,
  theme: themes["light"],
  converter: new Converter(),
  register: () => {},
};

export default function EmojiPicker(props = defaultProps) {
  const actualProps = { ...defaultProps, ...props };
  const { converter, stickers, storeId } = actualProps;

  if (typeof props.theme === "string") {
    actualProps.theme = themes[props.theme];
  }

  useEffect(() => {
    if (converter.version !== pkg.version) {
      console.warn(
        "Converter version doesn't match the version of the installed package. This could cause issues."
      );
    }
  }, [converter]);

  return (
    <StoreProvider id={storeId}>
      <EmojiPickerRoot
        {...actualProps}
        // TODO: Fix typo: Convertor should be Converter
        convertor={converter}
        stickers={stickers}
      />
    </StoreProvider>
  );
}
