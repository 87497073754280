import * as React from 'react';
import { cxBind } from '@/utils/cx';
import bridge from '@/utils/bridge';

import EditableCard from '../../shared/EditableCard/EditableCard';
import useCustomFieldsToShow, { Field } from './useCustomFieldsToShow';

import styles from './CustomFieldsCard.css';

const cx = cxBind(styles);

function openCustomFieldsWebifiedModal() {
  bridge.tell('Shell', 'openCustomFields');
}

type Props = {
  fields: Array<Field>;
};

export default function CustomFieldsCard(props: Props) {
  const { fields } = props;
  const fieldsToShow = useCustomFieldsToShow(fields);

  return (
    <EditableCard
      editing={false}
      onEditIconClick={openCustomFieldsWebifiedModal}
    >
      <table className={cx('table')}>
        <tbody>
          {fieldsToShow.map((field) => (
            <tr key={field.fieldId}>
              <th className={cx('field-label')}>
                <div className={cx('cell-overflow-wrapper')}>{field.name}</div>
              </th>
              <td className={cx('field-value')}>
                <div className={cx('cell-overflow-wrapper')}>
                  {field.values?.[0] ?? field.defaultValues?.[0] ?? 'Not set'}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </EditableCard>
  );
}
