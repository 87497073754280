import { connect } from 'react-redux';
import teamPrivilegesSelector from '@/selectors/teamPrivileges';

function mapStateToProps(state) {
  return {
    teamPrivileges: teamPrivilegesSelector(state),
  };
}

export default function (component) {
  return connect(mapStateToProps)(component);
}
