import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Icon } from '@talk-to/flock-components';
import { logMedusaGenericEvent } from '@/utils/logAnalytics';

import { openAppStore } from '@/utils/SessionUtils';

import cssStyles from './MenuDropdown.css';

class ManageApps extends Component {
  constructor(props) {
    super(props);
    this.manageApps = this.manageApps.bind(this);
  }

  manageApps() {
    const { sessionId } = this.props;
    openAppStore(sessionId, 'profilemodal', 'manage');
    logMedusaGenericEvent('settings_manage_your_apps_clicked');
  }

  render() {
    return (
      <div
        role='button'
        tabIndex='-1'
        onKeyDown={() => {}}
        className={classNames(cssStyles.dropdown_list_item)}
        onClick={this.manageApps}
        data-pendo-hook='menu-manage-apps'
      >
        <div
          className={classNames(
            cssStyles.menu_item_info,
            cssStyles['menu-item-external']
          )}
        >
          <FormattedMessage id='manage_apps' />
          <Icon
            className={cssStyles['external-icon']}
            name='external'
            size='1em'
          />
        </div>
      </div>
    );
  }
}

ManageApps.propTypes = {
  sessionId: PropTypes.string.isRequired,
};

export default ManageApps;
