import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { openUpgradeUrl } from '@/utils/SessionUtils';
import platform from '@/utils/platform';
import { currentSessionSelector } from '@/selectors';
import SpecialUpgradeHook from './SpecialUpgradeHook';

const PAID = 'PAID';
const PAID_OFFLINE = 'PAID_OFFLINE';
const ELITE = 'ELITE';
const FREE = 'FREE';
const TRIAL = 'TRIAL';

class UpgradeToProHook extends Component {
  static propTypes = {
    session: PropTypes.object,
    source: PropTypes.string.isRequired,
  };

  static defaultProps = {
    session: null,
  };

  constructor(props) {
    super(props);
    const { source } = props;
    if (!source) {
      console.error('Please provide UpgradeToProHook source via props.');
    }
    this.state = {
      isMacAppStore: true,
    };
  }

  componentDidMount() {
    platform.isMacAppStore().then((response) => {
      this.setState({
        isMacAppStore: response,
      });
    });
  }

  getStateFromProps() {
    const { session } = this.props;
    const { isMacAppStore } = this.state;
    let state = { isMacAppStore };
    if (!session || !session.owner) {
      return state;
    }

    state = { ...state, ...this.getStateFromSession(session) };

    const { teamPlan } = session.sessionInfo;
    if (!teamPlan) {
      return state;
    }
    const {
      type,
      isEligibleForFreeProTrial,
      daysLeftForExpiry: daysLeft,
      attrs: { experiment_type: experimentType } = {},
    } = teamPlan;

    const isHardPaywallTrial = type === 'HARD_PAYWALL_EXPERIMENT';
    const isSoftPaywallTrial =
      type === 'TRIAL' && experimentType === 'spe_trial';
    state = {
      ...state,
      teamType: type,
      eligibleForProTrial: isEligibleForFreeProTrial,
      nodeReady: true,
      isSpecial: isHardPaywallTrial || isSoftPaywallTrial,
      isHardPaywallTrial,
      isSoftPaywallTrial,
      daysLeft,
    };

    return state;
  }

  getStateFromSession = (session) => {
    if (!session) {
      return {};
    }

    const { isAdmin, teamId } = session.sessionInfo.teamInfo;
    const exclusions =
      session.sessionInfo.teamInfo?.teamPreferences?.excludePaywall?.value ??
      [];
    return { isAdmin, teamId, exclusions };
  };

  setUpgradePayload = () => {
    const {
      session: { id },
      source,
    } = this.props;
    const { teamType, eligibleForProTrial } = this.getStateFromProps();
    if (teamType === PAID) return false;
    const payload = {
      source,
      trial: eligibleForProTrial,
    };

    openUpgradeUrl(id, payload);
    return true;
  };

  getStateAndHelpers() {
    const {
      teamType,
      nodeReady,
      eligibleForProTrial,
      isMacAppStore,
      isSpecial,
      daysLeft,
      isAdmin,
      teamId,
      isHardPaywallTrial,
      isSoftPaywallTrial,
      exclusions,
    } = this.getStateFromProps();

    const { source } = this.props;
    const isPaidTeam = [PAID, ELITE, PAID_OFFLINE].includes(teamType);

    return {
      onClick: this.setUpgradePayload,
      shouldRender: nodeReady,
      source,
      isFreeTeam: teamType === FREE,
      isPaidTeam,
      isTrialTeam: teamType === TRIAL,
      isMacAppStore,
      showTrialProHook: !isPaidTeam && eligibleForProTrial,
      showUpgradeProHook: !isPaidTeam && !eligibleForProTrial,
      isSpecial,
      daysLeft,
      isAdmin,
      teamId,
      isHardPaywallTrial,
      isSoftPaywallTrial,
      exclusions,
    };
  }

  render() {
    const { children, source, session } = this.props;
    if (session) {
      const {
        id: sessionId,
        sessionInfo: { teamPlan },
      } = session;
      const { isSpecial, isAdmin } = this.getStateFromProps();
      // Hook only above team name and nowhere else.
      if (isSpecial && source === 'above_team_name') {
        return (
          <SpecialUpgradeHook
            isAdmin={isAdmin}
            currentSessionId={sessionId}
            teamPlan={teamPlan}
          />
        );
      }

      // Don't show anything incase of special teams

      return children(this.getStateAndHelpers());
    }
    return null;
  }
}

export default connect((state) => ({
  session: currentSessionSelector(state),
}))(UpgradeToProHook);
