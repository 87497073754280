import platform from '@/utils/platform';
import {
  startUpload,
  sendFileMessage,
  selectFiles,
} from '@/utils/MultiFileUpload';

const filePicker = {
  isLocal: true,
  name: 'Attach',
  inClientIcon:
    // eslint-disable-next-line prefer-template
    platform.getRootPath() + 'client_base/images/icon-attachment.svg',
  action(peer, currentSession) {
    selectFiles(function (files) {
      const fileUploadPromise = startUpload(files, peer.jid, currentSession);
      fileUploadPromise.then((uploadStatus) => {
        sendFileMessage(uploadStatus, peer);
      });
    });
  },
};

export default [filePicker];
