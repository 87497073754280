import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import IntlWrapper from '@/Wrappers/IntlWrapper';
import bridge from '@/utils/bridge';
import { Button, Image } from '@/components/common';
import paywall from '@/assets/paywall.svg';
import PaywallButton from '@/components/common/PaywallButton';
import { logMedusaGenericEvent } from '@/utils/logAnalytics';

import styles from './ChannelPaywall.css';
import translations from './I18N';

const gaSource = {
  open: 'CREATE_PUBLIC_CHANNEL',
  close: 'CREATE_PRIVATE_CHANNEL',
};

const dialogStrings = {
  privateTitle: 'upgrade_pro_private',
  publicTitle: 'upgrade_pro_public',
  privateDescription: 'private_channel_pro_feature',
  privateDescription1: 'upgrade_pro_or_create_public',
  privateDescription2: 'upgrade_pro_or_create_private',
  publicDescription: 'public_channel_limit_starter_plan',
};

const buttonStrings = {
  createPublic: 'Create Public Channel',
  upgrade: 'Upgrade',
};

let feature = 'public_channel';
let source = 'client_create_channel_public';

function ChannelPaywall({
  isAdmin,
  teamPlan: { isEligibleForFreeProTrial } = {},
  sessionId,
  name,
  invitees,
  purpose,
  type,
  avatar,
  allowed,
  hideChannelPaywall,
}) {
  useEffect(() => {
    logMedusaGenericEvent('paywall_shown', {
      source,
      feature,
    });
  }, []);

  function createPublicChannel() {
    hideChannelPaywall();
    bridge.tell(`Session_${sessionId}`, 'createGroupOnServer', [
      name,
      invitees,
      purpose,
      'open',
      avatar,
    ]);
  }

  if (type === 'close') {
    feature = 'private_channel';
    source = 'client_add_others_to_conversation_private';
    return (
      <div className={styles.ChannelPaywall}>
        <Image src={paywall} alt='' />
        <h2>
          <FormattedMessage id={dialogStrings.privateTitle} />
        </h2>
        <div className={styles.description}>
          <div>
            <FormattedMessage id={dialogStrings.privateDescription} />
          </div>
          <div>
            {allowed.open ? (
              <FormattedMessage id={dialogStrings.privateDescription1} />
            ) : (
              <FormattedMessage id={dialogStrings.privateDescription2} />
            )}
          </div>
        </div>
        {allowed.open ? (
          <Button
            className={styles.CreatePublicBtn}
            kind='secondary'
            onClick={createPublicChannel}
          >
            {buttonStrings.createPublic}
          </Button>
        ) : null}
        <PaywallButton
          isAdmin={isAdmin}
          isEligibleForFreeProTrial={isEligibleForFreeProTrial}
          sessionId={sessionId}
          gaSource={gaSource[type]}
          feature={feature}
          source={source}
          close={hideChannelPaywall}
        />
      </div>
    );
  }
  return (
    <div className={styles.ChannelPaywall}>
      <Image src={paywall} alt='' />
      <h2>
        <FormattedMessage id={dialogStrings.publicTitle} />
      </h2>
      <div className={styles.description}>
        <div>
          <FormattedMessage
            id={dialogStrings.publicDescription}
            values={{ publicLimit: allowed.openMax }}
          />
        </div>
      </div>
      <PaywallButton
        isAdmin={isAdmin}
        isEligibleForFreeProTrial={isEligibleForFreeProTrial}
        sessionId={sessionId}
        gaSource={gaSource[type]}
        feature={feature}
        source={source}
      />
    </div>
  );
}

export default IntlWrapper(ChannelPaywall, translations);
