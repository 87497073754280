import React, { useEffect, useState } from 'react';
import platform from '@/utils/platform';
import bridge from '@/utils/bridge';
import chromeAddressBarIcon from '@/assets/chrome-address-bar.svg';
import cssStyles from './NotificationWarning.css';

const { isChrome } = platform;

const warningMessages = {
  chrome:
    'Notifications on this browser are currently blocked. Please enable them.',
  default:
    'Notifications from web.flock.com are blocked in your browser. Please change your browser permission settings to allow notifications from Flock.',
};

const step1 = 'Step 1: Click on the ‘Lock’ icon in the browser address bar ';
const step2 = 'Step 2: Change the Notifications setting to ‘Allow’';

const GRANTED = 'granted';
const DEFAULT = 'default';

const getWarningMessage = () => {
  if (isChrome) {
    return warningMessages.chrome;
  }
  return warningMessages.default;
};

export default function NotificationWarning() {
  const [permission, setPermission] = useState(null);
  const chromeBrowserClass = isChrome ? cssStyles.chromeBrowser : '';
  const warningMessage = getWarningMessage();

  useEffect(() => {
    bridge
      .ask('NotificationController', 'getNotificationPermission')
      .then((newPermission) => setPermission(newPermission));
  }, []);

  useEffect(() => {
    if (permission === DEFAULT) {
      bridge
        .ask('NotificationController', 'askNotificationPermission')
        .then((newPermission) => setPermission(newPermission));
    }
  }, [permission]);

  if (!permission || permission === GRANTED) {
    return null;
  }

  return (
    <section className={`${cssStyles.container} ${chromeBrowserClass}`}>
      <div className={cssStyles.warning}>{warningMessage}</div>
      {isChrome ? (
        <>
          <div className={cssStyles.step}>
            <span className={cssStyles.text}>{step1}</span>
            <img
              src={chromeAddressBarIcon}
              alt='chrome address bar'
              className={cssStyles.icon}
            />
          </div>
          <div className={cssStyles.step}>
            <span className={cssStyles.text}>{step2}</span>
          </div>
        </>
      ) : null}
    </section>
  );
}
