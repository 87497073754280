import { createSelector } from 'reselect';
import ownerGuidSelector from './ownerGuid';

const teamPrivilegeSelectorUsingGuid = createSelector(
  (state) => state.restricted.teamPrivileges,
  (teamPrivileges) => (ownerGuid) => teamPrivileges[ownerGuid]
);

export default createSelector(
  ownerGuidSelector,
  teamPrivilegeSelectorUsingGuid,
  (ownerGuid, selectPrivileges) => selectPrivileges(ownerGuid)
);
