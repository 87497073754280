import React, { PureComponent } from 'react';
import debounce from 'lodash/debounce';
import { FormattedMessage } from 'react-intl';
import Tippy from '@tippyjs/react';
import currentSessionHOC from '@/connectHOCs/Sessions/currentSession';
import Popover from '@/components/common/Popover';
import commonCss from '@/components/common/common.css';
import appsListSubscriber from '@/subscribeHOCs/appList';
import { doAppAction } from '@/utils/ShellUtils';
import { maybe } from '@/utils';
import noop from '@/utils/noop';
import IntlWrapper from '@/Wrappers/IntlWrapper';
import { Noop, Peer, CurrentSession } from '../Types';
import InClientApps from './InClientApps';
import css from './AttachmentPicker.css';
import translations from './i18n';

const rootCategory = 'attachmentPicker';
const orgStandardRootCategory = 'attachmentPicker';
const gaSource = 'attachment_picker';

type Props = {
  peer?: Peer;
  currentSession: CurrentSession;
  appList: Object[];
  onClose?: Noop;
} & typeof AttachmentPicker.defaultProps;

type State = {
  showAppsList: boolean;
};

class AttachmentPicker extends PureComponent<Props, State> {
  static defaultProps = {
    peer: undefined,
    currentSession: undefined,
    appList: [],
    onClose: noop,
  };

  state = {
    showAppsList: false,
  };

  showAppsList = debounce(() => {
    const { showAppsList } = this.state;
    if (showAppsList) {
      return;
    }
    this.setState({
      showAppsList: true,
    });
  }, 100);

  hideAppsList = debounce(() => {
    const { showAppsList } = this.state;
    const { onClose } = this.props;

    if (!showAppsList) {
      return;
    }
    this.setState({
      showAppsList: false,
    });

    onClose();
  }, 100);

  getPriority = (app) => {
    return maybe(app, 'properties', 'priorities', rootCategory) || 0;
  };

  comparePriorities = (app1, app2) => {
    const app1Priority = this.getPriority(app1);
    const app2Priority = this.getPriority(app2);
    return app1Priority - app2Priority;
  };

  getAppsList() {
    const { appList } = this.props;
    appList.sort(this.comparePriorities);
    return [...appList, ...InClientApps];
  }

  onAppClick = (app) => {
    const { peer, currentSession } = this.props;
    this.hideAppsList();
    if (app.isLocal) {
      app.action(peer, currentSession);
    } else {
      doAppAction(
        app[rootCategory].action,
        app,
        peer,
        currentSession.id,
        {
          name: 'client.pressButton',
          button: orgStandardRootCategory,
        },
        gaSource
      );
    }
  };

  onMouseEnter = () => {
    this.showAppsList();
  };

  onMouseLeave = () => {
    this.hideAppsList();
  };

  onPopoverMouseEnter = () => {
    this.showAppsList();
  };

  onPopoverMouseLeave = () => {
    this.hideAppsList();
  };

  renderAppsList() {
    const appList = this.getAppsList();

    return (
      <div className={css.Wrapper}>
        {appList.map((app) => (
          <div
            key={`${app.name}`}
            className={css.Item}
            onClick={() => this.onAppClick(app)}
            onKeyDown={() => {}}
            role='button'
            tabIndex={-1}
            data-attachment-picker-item={app.name}
          >
            <img
              className={css.ItemIcon}
              src={app.inClientIcon}
              alt='alternate'
            />
            <span>{app.name}</span>
          </div>
        ))}
      </div>
    );
  }

  render() {
    const { showAppsList } = this.state;

    return (
      <div className={css.PopoverWrapper}>
        <Popover
          open={showAppsList}
          onClick={this.showAppsList}
          onClickOutside={this.hideAppsList}
          onEscape={this.hideAppsList}
          widthDifference={-30}
          heightDifference={10}
          position='top-right'
          popOverContent={this.renderAppsList()}
          showArrow
          arrowPosition='bottom'
          arrowStyle={{
            left: '10px',
          }}
        >
          <Tippy
            className={commonCss.info_tooltip}
            content={<FormattedMessage id='attachment_picker_button_tooltip' />}
            arrow
            placement='left'
          >
            <div
              className={css.plus_icon}
              data-pendo-hook='attachment-picker'
            />
          </Tippy>
        </Popover>
      </div>
    );
  }
}

export default currentSessionHOC(
  appsListSubscriber(IntlWrapper(AttachmentPicker, translations), rootCategory)
);
