import React, { Component } from 'react';
import { connect } from 'react-redux';
import ContactProxy from '@/Proxies/ContactProxy';
import {
  currentSessionIdSelector,
  ownerGuidSelector,
  currentConversationSelector,
  allContactsSelector,
} from '@/selectors';

const defaultState = {};

const mapStateToProps = (state) => {
  const currentSessionId = currentSessionIdSelector(state);
  const ownerGuid = ownerGuidSelector(state);
  const currentPeerId = currentConversationSelector(state);
  const allContacts = allContactsSelector(state);
  if (currentPeerId && allContacts && allContacts[currentPeerId]) {
    return {
      ownerGuid,
      currentSessionId,
      currentPeerId,
      currentPeer: allContacts[currentPeerId],
    };
  }
  return defaultState;
};

export default function (ContainedComponent) {
  class CurrentPeerHOC extends Component {
    componentDidMount() {
      const { ownerGuid, currentPeerId } = this.props;
      this.contactProxySubscription = ContactProxy.subscribe(ownerGuid, [
        currentPeerId,
      ]);
    }

    componentDidUpdate({
      ownerGuid: prevOwnerGuid,
      currentPeerId: prevPeerId,
    }) {
      const { ownerGuid, currentPeerId } = this.props;
      if (ownerGuid !== prevOwnerGuid || currentPeerId !== prevPeerId) {
        this.contactProxySubscription.update(ownerGuid, [currentPeerId]);
      }
    }

    componentWillUnmount() {
      this.contactProxySubscription.unsubscribe();
      this.contactProxySubscription = null;
    }

    render() {
      return <ContainedComponent {...this.props} />;
    }
  }

  return connect(mapStateToProps)(CurrentPeerHOC);
}
