export default {
  chatTab_text_groupConversation_inviteToGroup: 'Invitar al canal',
  chatTab_tooltip_groupConversation_leaveGroup: 'Abandonar este canal',
  chatTab_tooltip_groupConversation_all_messages:
    'All new messages will notify you',
  chatTab_tooltip_groupConversation_mentions:
    'Channel is muted. Only mentions will notify you',
  chatTab_tooltip_groupConversation_direct_mention:
    'Channel is muted. Only direct mentions will notify you',
  channel_notifications_preferences__heading:
    'Channel notifications preferences',
  channel_notifications_preferences_heading__notify_me_for: 'Notify me for',
  channel_notifications_preferences_heading__all_messages: 'all messages',
  channel_notifications_preferences_heading__mentions_only: 'mentions only',
  channel_notifications_preferences_heading__direct_mention:
    'direct mentions only',
  channel_notifications_preferences_heading__notifications_for:
    "You'll get notifications for",
  channel_notifications_preferences_heading__mentions_desc:
    '@all, @online, @{user_name}',
  channel_notifications_preferences_heading__direct_mention_desc:
    '@{user_name} and nothing else',
};
