/**
 * This file contains appIds of custom applications (like Roster/Director)
 */
import browseAppsIcon from '@/assets/icon-browse-apps.svg';
import directoryIcon from '@/assets/icon-directory.svg';
import supportedLanguages from '@/utils/supportedLanguages';
import keys from '@/I18N';
import bridge from '@/utils/bridge';
import { events } from '@/utils/KeyboardShortcuts';

export const DIRECTORY = 'recent';
export const GET_MORE_APP = 'GET_MORE_APP';
export const SIDEBAR_CATEGORY = 'appLauncherButton';

export async function getCustomApps() {
  const preferredLanguage = await bridge.ask(
    'HydraServer',
    'getPreferredLanguageFromStore'
  );
  const globalMessages = keys[supportedLanguages[preferredLanguage].code];
  return {
    DIRECTORY_APP: {
      id: DIRECTORY,
      icon: directoryIcon,
      name: globalMessages.roster_title,
      properties: {
        priorities: {
          [SIDEBAR_CATEGORY]: 9500,
        },
      },
      [SIDEBAR_CATEGORY]: {
        label: globalMessages.directory_button_label,
        description: `Team ${globalMessages.directory_button_label} (${events.directory.shortcutString})`,
      },
    },
    GET_MORE_APP: {
      id: GET_MORE_APP,
      icon: browseAppsIcon,
      name: globalMessages.app_store_button_label,
      description: globalMessages.flock_app_store,
      [SIDEBAR_CATEGORY]: {
        label: globalMessages.app_store_button_label,
        description: globalMessages.flock_app_store,
      },
    },
  };
}

export default [DIRECTORY, GET_MORE_APP];
