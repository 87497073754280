import React, { PureComponent } from 'react';
import Popover from '@/components/common/Popover';
import EditorPopoverStyleContext from '@/Contexts/EditorPopoverStyle';
import ContactSearch from '../../ContactSearch';
import CommandList from './CommandList';
import { SlashCommandManagerHOC } from './SlashCommandManager';
import { getContactName } from '../../utils';
import { Owner, Peer, InsertTextFun, Command } from '../../Types';
import css from './SlashCommandHelper.css';

type Props = {
  textContent: string;
  selectionStart: number;
  selectionEnd: number;
  owner: Owner;
  peer: Peer;
  commandManager: any;
  insertText: InsertTextFun;
  children: any;
} & typeof SlashCommandHelper.defaultProps;

type State = {
  activeCommandIndex: number;
};

class SlashCommandHelper extends PureComponent<Props, State> {
  static defaultProps = {
    textContent: '',
    selectionStart: undefined,
    selectionEnd: undefined,
    owner: undefined,
    peer: undefined,
    commandManager: {},
    children: null,
  };

  state = {
    activeCommandIndex: 0,
  };

  componentDidUpdate() {
    this.updateActiveCommandIndex();
  }

  onUpArrow = (event: React.KeyboardEvent) => {
    const commands = this.getCommandList();
    let { activeCommandIndex } = this.state;

    activeCommandIndex -= 1;
    if (activeCommandIndex < 0) {
      activeCommandIndex = commands.length - 1;
    }

    event.preventDefault();

    this.setState({
      activeCommandIndex,
    });
  };

  onDownArrow = (event: React.KeyboardEvent) => {
    const commands = this.getCommandList();
    let { activeCommandIndex } = this.state;

    activeCommandIndex += 1;
    if (activeCommandIndex > commands.length - 1) {
      activeCommandIndex = 0;
    }

    event.preventDefault();

    this.setState({
      activeCommandIndex,
    });
  };

  onEnter = (event: React.KeyboardEvent) => {
    const commands = this.getCommandList();
    const { activeCommandIndex } = this.state;
    /**
     * Command should be selected only when there is no command selected.
     * i.e textContent should be completely part of command name
     * commandName.indexOf(textContent) > 0
     */
    const { textContent } = this.props;
    const activeCommand = commands[activeCommandIndex];
    if (activeCommand && activeCommand.name.indexOf(textContent) === 0) {
      this.onCommandSelect(commands[activeCommandIndex]);
      event.preventDefault();
      return false;
    }
    return true;
  };

  onSpace = (event: React.KeyboardEvent) => {
    this.onEnter(event);
  };

  onTab = (event: React.KeyboardEvent) => {
    this.onEnter(event);
  };

  getCommandList = () => {
    const {
      textContent,
      commandManager: { getMatchingCommands },
      peer: { type },
    } = this.props;
    return getMatchingCommands(textContent.trim(), type === 'group');
  };

  // Check in param is used to determine if we want to add param selector fragment into text
  // If it returns false, add or not. Very bad naming.
  onCommandSelect = (command: Command) => {
    const { peer, textContent, insertText } = this.props;

    let textToBeAdded = command.name;
    const endPosition = textContent.length;
    let paramString = ' ';

    const { params = {} } = command;
    if (
      params.type &&
      (params.type.includes('buddy') ||
        params.type.includes('email') ||
        params.type.includes('group')) &&
      params.check(peer)
    ) {
      paramString += '@';
    }

    textToBeAdded += paramString;

    insertText(textToBeAdded, 0, endPosition);
  };

  onSearchContactSelect = (contact: Contact) => {
    const { owner, insertText, textContent } = this.props;
    const { paramFragments = {} } = this.getSelectedCommandParams();

    const { textBeforeParam, textAfterSuffix } = paramFragments;

    const contactName = getContactName(contact, owner);

    const textToBeAdded = ` @${contactName} `;
    const startPosition = textBeforeParam.length;
    const endPosition =
      startPosition +
      textContent.length -
      textBeforeParam.length -
      textAfterSuffix.length;
    // const caretPosition = `${textBeforeParam} @${contactName} `.length;
    // const caretPosition = textBeforeParam.length + 2 + contactName.length + 1;
    insertText(
      textToBeAdded,
      startPosition,
      endPosition,
      undefined,
      this.getContactParamUpdateFn(contact)
    );
  };

  getContactParamUpdateFn = (contact: Contact) => {
    const { owner } = this.props;
    const contactName = getContactName(contact, owner);
    return function (contacts, textContent: string) {
      // Get all the contact param occurrences
      const matches =
        textContent.match(new RegExp(`@${contactName}`, 'g')) || [];
      // Get all the contact objects in contacts
      const contactsAddedCount = contacts.filter((c) => c === contact).length;
      // If contact is already in the contacts, do not add again.
      if (matches.length > contactsAddedCount) {
        contacts.push(contact);
        return contacts;
      }

      return contacts;
    };
  };

  getSelectedCommandParams() {
    const {
      commandManager: { getCommandForText },
      textContent,
      selectionStart,
      selectionEnd,
      peer: { type },
    } = this.props;

    const command = getCommandForText(textContent, type === 'group');
    if (!command) {
      return null;
    }

    const { params: commandParams = {}, name } = command;
    const { getParamsInfo = () => undefined } = commandParams;

    const paramFragments = getParamsInfo(
      textContent,
      selectionStart,
      selectionEnd
    );

    return { paramFragments, commandParams, name };
  }

  updateActiveCommandIndex = () => {
    const commands = this.getCommandList();
    const { activeCommandIndex } = this.state;
    if (activeCommandIndex > commands.length) {
      this.setState({
        activeCommandIndex: 0,
      });
    }
  };

  shouldShowContactSearch() {
    const {
      commandManager: { getCommandForText },
      textContent,
      peer: { type },
    } = this.props;

    const command = getCommandForText(textContent, type === 'group');
    const commandParamObj = this.getSelectedCommandParams();
    if (!commandParamObj) {
      return false;
    }
    const { paramFragments, commandParams } = commandParamObj;

    if (!command || !paramFragments || !commandParams) {
      return false;
    }

    return (
      paramFragments.paramPrefix &&
      (commandParams.type.includes('buddy') ||
        commandParams.type.includes('email') ||
        commandParams.type.includes('group'))
    );
  }

  renderContactSearch() {
    const {
      paramFragments,
      commandParams: { type, contactTypesFilter, excludeDeactivated } = {},
      name,
    } = this.getSelectedCommandParams();

    if (!paramFragments) {
      return null;
    }
    const { children, peer } = this.props;
    const searchTerm = paramFragments.paramPrefix.slice(1);

    const excludedJids = peer.isBuddy ? [peer.jid] : [];
    const searchParams: any = {
      sortMatches: true,
      excludedJids,
      start: 0,
      count: 6,
      microFormatNeeded: true,
    };

    if (type) {
      searchParams.filters = {
        type,
      };
    }

    if (excludeDeactivated) {
      searchParams.filters.isDeactivated = false;
    }

    if (contactTypesFilter) {
      searchParams.contactTypesFilter = contactTypesFilter;
    }

    const includeTempContact = type ? type.includes('email') : false;

    return (
      // <div>
      <ContactSearch
        className={css.List}
        searchValue={searchTerm}
        onContactSelect={this.onSearchContactSelect}
        searchParams={searchParams}
        selectOnSpace
        selectOnTab
        headerString={name}
        includeTempContact={includeTempContact}
      >
        {(props) => children(props)}
      </ContactSearch>
      // </div>
    );
  }

  renderCommandList() {
    const { children } = this.props;
    const { activeCommandIndex } = this.state;
    const commands = this.getCommandList();
    if (commands.length === 0) {
      return children();
    }
    return (
      <EditorPopoverStyleContext.Consumer>
        {({ getPositionStyle, popoverTriggerClass }) => (
          <Popover
            open
            popOverContent={
              <CommandList
                onSelect={this.onCommandSelect}
                commands={commands}
                activeIndex={activeCommandIndex}
              />
            }
            focusOnRender={false}
            getPositionStyle={getPositionStyle}
            triggerClass={popoverTriggerClass}
          >
            {children({
              onDownArrow: this.onDownArrow,
              onUpArrow: this.onUpArrow,
              onEnter: this.onEnter,
              onSpace: this.onSpace,
              onTab: this.onTab,
            })}
          </Popover>
        )}
      </EditorPopoverStyleContext.Consumer>
    );
  }

  render() {
    const showSearch = this.shouldShowContactSearch();

    return showSearch ? this.renderContactSearch() : this.renderCommandList();
  }
}

export default SlashCommandManagerHOC(SlashCommandHelper);
