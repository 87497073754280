export default {
  editor_messageDropdown_menu_text_copy: 'Copiar',
  editor_messageDropdown_menu_text_edit: 'Editar',
  editor_messageDropdown_menu_text_retry: 'Reintentar',
  editor_messageDropdown_popup_text_deleteMessage:
    'Se eliminar\u00e1 este mensaje tanto para ti como para el destinatario, y se eliminar\u00e1 permanentemente de los servidores.',
  editor_messageDropdown_popup_title_deleteMessage: '\u00bfEliminar mensaje?',
  editor_messageDropdown_popup_buttonText_deleteMessage_Yes:
    'S\u00ed, eliminar',
  editor_messageDropdown_popup_buttonText_delete: 'Eliminar',
  editor_deletion_failed: 'Acci\u00f3n no autorizada',
  editor_not_connected_to_internet_error:
    'Parece que te has desconectado. Esto se debe normalmente a una conexi\u00f3n a internet mala o nula.',
};
