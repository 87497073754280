import bridge from '@/utils/bridge';
import store from '@/store';
import { Actions } from '@/actions/restricted';

let subscriptions = [];
let bridgeSubscriptions = [];
let disconnectionSubscription = [];
let counter = 0;

function getAllSessions() {
  return bridge.ask('TeamSessionRegistry', 'getAllSessions');
}

function onSessionAdded(session) {
  const { id } = session;
  if (!id) {
    return;
  }
  store.dispatch(Actions.add_to_all_sessions([id]));
}

function onSessionDeleted(session) {
  const { id } = session;
  if (!id) {
    return;
  }
  store.dispatch(Actions.remove_from_all_sessions([id]));
}

function sessionDisconnectedOnce() {
  store.dispatch(Actions.update_disconnected_once(true));
  bridge.unsubscribeAll(disconnectionSubscription);
  disconnectionSubscription = [];
}

function unsubscribe(id) {
  if (subscriptions.length > 0) {
    subscriptions = subscriptions.filter((i) => i !== id);
    if (subscriptions.length === 0) {
      bridge.unsubscribeAll(bridgeSubscriptions);
      bridgeSubscriptions = [];
      store.dispatch(Actions.clear_all_sessions());
    }
  }
}

function subscribe() {
  if (subscriptions.length === 0) {
    disconnectionSubscription.push(
      bridge.subscribe('/hydra/session/disconnected', sessionDisconnectedOnce)
    );
    bridgeSubscriptions.push(
      bridge.subscribe('/hydra/session/removed', onSessionDeleted),
      bridge.subscribe('/hydra/session/added', onSessionAdded)
    );
    getAllSessions().then((sessions) => {
      if (subscriptions.length === 0) return;
      const sessionIds = sessions.map((s) => s.id);
      store.dispatch(Actions.add_to_all_sessions(sessionIds));
    });
  }
  counter += 1;
  const id = counter;
  subscriptions.push(id);
  return {
    unsubscribe: () => unsubscribe(id),
  };
}

export default {
  subscribe,
};
