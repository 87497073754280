import { useSelector } from 'react-redux';

type Theme = 'dark' | 'light';

type State = {
  app: {
    theme: Theme;
  };
};

export default function useTheme(): Theme {
  const theme = useSelector((state: State) => state.app.theme);

  return theme;
}
