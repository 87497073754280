import React, { Component } from 'react';
import ErrorBoundary from '@/components/widgets/ErrorBoundary';
import Portal from '@/components/widgets/Portal';
import ConversationHandler from '@/components/Conversation/ConversationHandler';
import { getBlobFromDataUrl } from '@/utils/file';
import { uploadAndSendFileMessage } from '@/utils/MultiFileUpload';
import {
  TeamSwitcherContainer,
  AppMenuContainer,
  SidebarContainer,
  ActiveChatsContainer,
} from '@/containers';
import HardPaywallModal from '@/components/HardPaywallModal';
import bridge from '@/utils/bridge';
import Search from '@/components/Search';
import SupportHooks from '@/components/SupportHooks';
import { Actions as RestrictedActions } from '@/actions/restricted';
import store from '@/store';
import pendo from '@/trackers';
import ChatsProxy from '@/Proxies/ChatsProxy';
import { onKeyUp, onKeyDown } from '@/utils/KeyboardShortcuts';
import cssStyles from './Shell.css';
import ModalSubscriptions from './ModalSubscriptions';

const portalConfiguration = [
  {
    name: 'outer-left',
    getMountPoint: function getMountPoint() {
      return document.getElementById('team-switcher');
    },
    container: TeamSwitcherContainer,
  },
  {
    name: 'app-menu',
    getMountPoint: function getMountPoint() {
      return document.getElementById('app-menu');
    },
    container: AppMenuContainer,
    theme: {},
  },
  {
    name: 'search',
    getMountPoint: function getMountPoint() {
      return document.getElementById('search');
    },
    container: Search,
    theme: {},
  },
  {
    name: 'sidebar',
    getMountPoint: function getMountPoint() {
      return document.getElementById('sidebar');
    },
    container: SidebarContainer,
    theme: {},
  },
  {
    name: 'active-chats',
    getMountPoint: function getMountPoint() {
      return document.getElementById('active-chats');
    },
    container: ActiveChatsContainer,
    theme: {},
  },
  {
    name: 'conversations',
    getMountPoint: function getMountPoint() {
      return document.getElementById('chats-area');
    },
    container: ConversationHandler,
    theme: {},
  },
  {
    name: 'support-hooks',
    getMountPoint: function getMountPoint() {
      return document.getElementById('support-hooks');
    },
    container: SupportHooks,
    theme: {},
  },
];

class Shell extends Component {
  state = {
    isAppInitialized: false,
  };

  localSubscriptions = [];

  constructor(props) {
    super(props);
    pendo.init();
    window.odara_shell = this;
    this.localSubscriptions.push(
      bridge.subscribe(
        '/uploader-file-available',
        async (peer, session, dataUrl, filename) => {
          const file = await getBlobFromDataUrl(dataUrl, filename);
          uploadAndSendFileMessage([file], peer, session);
        }
      ),
      bridge.subscribe('/shell/loading_completed', () => {
        const { isAppInitialized } = this.state;
        if (!isAppInitialized) {
          this.setState({
            isAppInitialized: true,
          });
        }
      }),
      bridge.ask('Shell', 'isLoadingComplete').then((isAppInitialized) => {
        if (isAppInitialized) {
          this.setState({
            isAppInitialized: true,
          });
        }
      }),
      bridge.subscribe(
        '/shell/layout/box/outerLeft/change',
        ({ outerLeft: { collapsed } }) => {
          // temporary arrangement as outerLeft component has to be moved to adara very soon.
          store.dispatch(RestrictedActions.outerleft_layout_changed(collapsed));
        }
      )
    );
  }

  componentWillUnmount() {
    bridge.unsubscribeAll(this.localSubscriptions);
    ModalSubscriptions.unsubscribe();
    // Cleanup
    this.unsubscribeProxies();
  }

  unsubscribeProxies = () => {
    ChatsProxy.unsubscribe();
  };

  render() {
    const { isAppInitialized } = this.state;

    if (!isAppInitialized) {
      return null;
    }
    /*
      Key event handlers are to handle global events.
      Moving it here as attaching events to element in traditional way
      was conflicting with the react's synthetic events.
    */
    return (
      <div
        className={cssStyles.layout}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        role='presentation'
      >
        {portalConfiguration.map((block) => {
          return (
            <Portal key={block.name} mountPoint={block.getMountPoint()}>
              <ErrorBoundary>
                <block.container />
              </ErrorBoundary>
            </Portal>
          );
        })}
        <HardPaywallModal />
      </div>
    );
  }
}

export default Shell;
