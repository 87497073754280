import React, { useState, useCallback } from 'react';
import Popover from '@/components/common/Popover';
import iconMenu from '@/assets/icon-menu.svg';
import { addQueryParamtoHTTPUrls } from '@/utils';
import cssStyles from './AttachmentActions.css';

const MoreAttachmentActions = (props) => {
  const { actionItems } = props;
  const [open, setOpen] = useState(false);

  const openPopover = useCallback(() => {
    setOpen(true);
  }, []);

  const onClickOutside = useCallback(() => {
    setOpen(false);
  }, []);

  const handleAppClick = useCallback((action) => {
    setOpen(false);
    action();
  }, []);

  const getPopoverContent = useCallback(() => {
    return (
      <div>
        {actionItems.map((item) => {
          const iconStyle = item.icon
            ? { WebkitMask: item.icon, WebkitMaskSize: 'cover' }
            : null;
          return (
            <div
              key={item.id}
              className={cssStyles.overflowItemContainer}
              onClick={() => handleAppClick(item.action)}
              onKeyDown={() => {}}
              tabIndex={-1}
              role='button'
            >
              <div
                className={`${cssStyles.icon} ${item.class}`}
                style={iconStyle}
              />
              <div className={`${cssStyles.label} ${cssStyles.overflowLabel}`}>
                {item.label}
              </div>
            </div>
          );
        })}
      </div>
    );
  }, [actionItems, handleAppClick]);

  const iconUrl = `url(${addQueryParamtoHTTPUrls(iconMenu)}) no-repeat`;
  const moreIconStyle = { WebkitMask: iconUrl };

  return (
    <Popover
      position='bottom-right'
      open={open}
      heightDifference={-15}
      widthDifference={-5}
      onClickOutside={onClickOutside}
      popOverContent={getPopoverContent()}
      triggerClass={cssStyles.triggerClass}
    >
      <div
        className={`${cssStyles.limitedItemContainer} ${cssStyles.moreIcon} `}
        style={moreIconStyle}
        onClick={openPopover}
        onKeyDown={() => {}}
        tabIndex={-1}
        role='button'
      />
    </Popover>
  );
};

export default MoreAttachmentActions;
