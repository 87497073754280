import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getContact } from '@/selectors';
import ContactProxy from '@/Proxies/ContactProxy';

const mapStateToProps = (state, { ownerGuid, jid }) => {
  return {
    contact: getContact(state, { ownerGuid, jid }),
  };
};

export default function (ContainedComponent) {
  class ContactHOC extends Component {
    static propTypes = {
      ownerGuid: PropTypes.string.isRequired,
      jid: PropTypes.string.isRequired,
    };

    componentDidMount() {
      const { ownerGuid, jid } = this.props;
      this.contactProxySubscription = ContactProxy.subscribe(ownerGuid, [jid]);
    }

    componentDidUpdate() {
      const { ownerGuid, jid } = this.props;
      this.contactProxySubscription.update(ownerGuid, [jid]);
    }

    componentWillUnmount() {
      this.contactProxySubscription.unsubscribe();
      this.contactProxySubscription = null;
    }

    render() {
      return <ContainedComponent {...this.props} />;
    }
  }

  return connect(mapStateToProps, null)(ContactHOC);
}
