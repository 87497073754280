import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import memoize from 'memoize-one';
import {
  ownerGuidSelector,
  currentConversationSelector,
  unreadMessageSelector,
  openConversationsSelector,
  currentSessionSelector,
  conversationBucketsSelector,
  unreadChannelCountSelector,
} from '@/selectors';
import { PINNED, MUTED, OPEN } from '@/utils/constants';
import { Actions as AppActions } from '@/actions/app';
import ActiveChats from '@/components/ActiveChats';

const temInfoSelector = createSelector(
  currentSessionSelector,
  ({
    sessionInfo: {
      teamInfo: { teamUrl, memberCount },
    },
  }) => ({ teamUrl, memberCount })
);

const collpasedModeSelector = (state) =>
  state.restricted.shell.layout.outerLeft.collapsed;

const buckets = [PINNED, MUTED, OPEN];

const getBucketInfo = memoize((conversationBuckets) => {
  const bucketInfo = {};
  buckets.forEach((type) => {
    const bucket = conversationBuckets[type];
    bucketInfo[type] = {
      count: bucket.filter(({ unreadCount }) => unreadCount > 0).length,
      hasMention: bucket.some(({ hasMention }) => hasMention),
    };
  });
  return bucketInfo;
});

const mapDispatchToProps = (dispatch) => ({
  openRecent() {
    dispatch(
      AppActions.set_contact_search_config({
        open: true,
        listType: 'recent',
      })
    );
  },
  openNewChat() {
    dispatch(
      AppActions.set_contact_search_config({
        open: true,
        listType: 'frequent',
      })
    );
  },
});

const getOpenConversationJids = memoize((openConversations) =>
  Object.keys(openConversations)
);

const activeChatsSelector = createSelector(
  ownerGuidSelector,
  openConversationsSelector,
  currentSessionSelector,
  unreadMessageSelector,
  currentConversationSelector,
  temInfoSelector,
  collpasedModeSelector,
  conversationBucketsSelector,
  unreadChannelCountSelector,
  (
    ownerGuid,
    openConversations,
    currentSession,
    unreadMsgIds,
    currentConversation,
    teamInfo,
    collapsed,
    conversationBuckets,
    unreadChannelCount
  ) => {
    const bucketInfo = getBucketInfo(conversationBuckets);
    return {
      ownerGuid,
      openJids: getOpenConversationJids(openConversations),
      conversationBuckets,
      bucketInfo,
      unreadMsgIds,
      currentOwner: currentSession.owner,
      currentConversation,
      ...teamInfo,
      collapsed,
      unreadChannelCount,
    };
  }
);

export default connect(activeChatsSelector, mapDispatchToProps)(ActiveChats);
