import React, { Component } from 'react';
import { KEYS } from '@/utils/constants';
import noop from '@/utils/noop';
import css from './EditableText.css';

/**
    A textarea which appears as an editable text rendered.
    An action will be taken on the provided event, default will be onEnter.
        Action function needs to return a promise, a loader will be rendered before calling the action.
        On success of the promise, a tick will be rendered, which gets disappeared after a given time.

    onEscape and onBlur, edited content will be replaced with value in prop. 
    Prop value will not be copied to state while in editing state. This makes sure seamless editing for the user, 
    even if the actual value is changed.
 */

class EditableText extends Component {
  static defaultProps = {
    // InitialValue of the textArea before editing
    value: '',
    // additional classNames for the textArea
    className: '',
    // action function when actionEvent occurs
    actionFunction: () => {},
    // Can be onFocusOut, can be extended to more events.
    actionEvent: 'onEnter',
    // timeLength for which tick should be visible after success
    tickAnimationTime: 200,
    // maxLength allowed for the textArea
    maxLength: 100,
    // placeholder for textarea
    placeholder: '',
    // Should editing be allowed
    allowEdit: false,
  };

  state = {
    value: '',
    saving: false,
    saved: false,
    editing: false,
  };

  constructor(props) {
    super(props);

    const { value } = props;
    this.state.value = value;
    this.textArea = React.createRef();
  }

  componentDidMount() {
    this.updateHeight();
  }

  componentDidUpdate(prevProps, prevState) {
    const { value: prevValue } = prevProps;
    const { value } = this.props;
    const { editing } = this.state;

    /**
      Do not update value from prop if in editing state.
    */
    if (prevValue !== value && !editing) {
      this.setState({
        value,
      });
    }
    const { value: stateValue } = this.state;
    const { value: prevStateValue } = prevState;
    if (stateValue !== prevStateValue) {
      this.updateHeight();
    }
  }

  onChange = (e) => {
    const { value } = e.target;
    this.setState({
      value,
      editing: true,
    });
  };

  onKeyDown = (e) => {
    const { keyCode } = e;
    const { actionEvent } = this.props;
    if (keyCode === KEYS.ENTER && actionEvent === 'onEnter') {
      e.preventDefault();
      this.doAction();
    }
    if (keyCode === KEYS.ESCAPE) {
      this.undoChanges();
    }
  };

  onBlur = () => {
    this.undoChanges();
  };

  undoChanges() {
    const { value } = this.props;
    this.setState({
      value,
      editing: false,
    });
  }

  doAction() {
    const { actionFunction, tickAnimationTime } = this.props;
    const { value } = this.state;
    this.setState({
      saving: true,
      editing: false,
    });
    actionFunction(value).then(() => {
      this.setState({
        saved: true,
        saving: false,
      });
      setTimeout(() => {
        this.setState({
          saved: false,
        });
      }, tickAnimationTime);
    });
  }

  /**
      Updates height of textArea based on the content
  */
  updateHeight() {
    const el = this.textArea.current;
    if (!el) {
      return;
    }

    el.style.height = `auto`;
    const { scrollHeight } = el;
    if (scrollHeight) {
      el.style.height = `${scrollHeight}px`;
    }
  }

  render() {
    const { value, saving, saved } = this.state;
    const { className, maxLength, placeholder, allowEdit } = this.props;

    return (
      <textarea
        ref={this.textArea}
        value={value}
        className={`${css.Input} ${className} ${saving ? css.saving : ''} ${
          saved ? css.saved : ''
        }`}
        placeholder={placeholder}
        maxLength={maxLength}
        spellCheck='false'
        rows={1}
        disabled={!allowEdit}
        onKeyDown={allowEdit ? this.onKeyDown : noop}
        onChange={allowEdit ? this.onChange : noop}
        onBlur={allowEdit ? this.onBlur : noop}
      />
    );
  }
}

export default EditableText;
