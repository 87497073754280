import { emojiStore } from '@/lib/storage';

const defaultState = {
  recents: [],
  skinTone: 1,
};

emojiStore.init(defaultState);

export default (ActionTypes) => {
  return (state = defaultState, action) => {
    if (action.type === ActionTypes.SET_RECENT_EMOJIS) {
      return {
        ...state,
        recents: action.payload,
      };
    }

    if (action.type === ActionTypes.SET_EMOJI_SKIN_TONE) {
      return {
        ...state,
        skinTone: action.payload,
      };
    }

    return state;
  };
};
