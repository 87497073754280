import React from 'react';
import { FormattedMessage } from 'react-intl';
import Tippy from '@tippyjs/react';
import IntlWrapper from '@/Wrappers/IntlWrapper';
import Icon from '@/components/Icon';
import commonStyles from '@/components/common/common.css';
import translations from './I18N';
import css from './DndIndicator.css';

const MINUTE_IN_MS = 60 * 1000;

function getDateOffsetFromNow(offsetInMinutes) {
  return new Date(Date.now() + (offsetInMinutes || 0) * MINUTE_IN_MS);
}

const DndIndicator = React.memo(function DndIndicator({
  fullName,
  mutedDurationInMinutes,
}) {
  const message =
    mutedDurationInMinutes && mutedDurationInMinutes > 0 ? (
      <FormattedMessage
        id='conversation_dnd_message_with_time'
        values={{
          name: fullName,
          time: getDateOffsetFromNow(mutedDurationInMinutes),
        }}
      />
    ) : (
      <FormattedMessage
        id='conversation_dnd_message'
        values={{ name: fullName }}
      />
    );

  return (
    <Tippy
      className={commonStyles.info_tooltip}
      content={
        <FormattedMessage
          id='conversation_dnd_message_tooltip_content'
          values={{ name: fullName }}
        />
      }
      arrow
      placement='top'
    >
      <div className={css.DndIndicator}>
        <Icon className={css.DndIcon} name='muted' />
        <span>{message}</span>
      </div>
    </Tippy>
  );
});

export default IntlWrapper(DndIndicator, translations);
