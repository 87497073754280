import React, { useState, useEffect } from 'react';
import FlockML from '@/components/Conversation/Message/FlockML';
import TextMessageDecorator from './TextMessageDecorator';
import cssStyles from './Message.css';

export default function MessageText({
  message: { flockml, text, peer, ownerGuid, appId, id, sid, messageElements },
  currentSessionId,
}) {
  const { mentions } = messageElements || {};

  const [textFragments, setTextFragments] = useState([]);

  useEffect(
    function () {
      if (!flockml) {
        const textArray = TextMessageDecorator(text, mentions, ownerGuid);
        setTextFragments(textArray);
      }
    },
    [text, flockml, mentions, ownerGuid]
  );

  if (flockml) {
    return (
      <FlockML
        flockml={flockml}
        messageElements={messageElements}
        currentSessionId={currentSessionId}
        peerType={peer.type}
        ownerGuid={ownerGuid}
        peer={peer}
        appId={appId}
        msgId={id}
        msgSid={sid}
      />
    );
  }
  if (text) {
    return <div className={cssStyles.messageText}>{textFragments}</div>;
  }
  return null;
}
