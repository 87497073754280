import * as React from 'react';
import classNames from 'classnames/bind';
import {
  Button,
  Icon,
  Select,
  GetControlLabel,
} from '@talk-to/flock-components';
import bridge from '@/utils/bridge';
import withCurrentSession from '@/connectHOCs/Sessions/currentSession';
import useTheme from '@/hooks/useTheme';
import { logMedusaGenericEvent } from '@/utils/logAnalytics';

import PanelSection from '../shared/PanelSection';
import styles from './Profile.css';
import ProfileCard from './ProfileCard';
import CustomFieldsCard from './CustomFieldsCard';

import sharedStyles from '../shared/styles.css';
import useLanguageSettings from './useLanguageSettings';

const cx = classNames.bind(styles);

function openManageAccountAdminPage(sessionId: string) {
  bridge.ask('HydraServer', 'openManageAccountAdminPanel', [sessionId]);
  logMedusaGenericEvent('preferences_profile_manage_account_clicked');
}

function Profile(props) {
  const { languages, currentLanguage, changeLanguage } = useLanguageSettings();
  const currentTheme = useTheme();

  const languageOptions = React.useMemo(
    () =>
      Object.entries(languages).map(([_, value]) => ({
        label: `${value.localName} (${value.englishName})`,
        value: value.englishName,
        localName: value.localName,
      })),
    [languages]
  );

  const currentLanguageOption = React.useMemo(() => {
    return (
      Object.entries(languageOptions).find(
        ([_, item]) => item.value === currentLanguage
      )?.[1] ?? null
    );
  }, [languageOptions, currentLanguage]);

  const {
    currentSession: {
      owner,
      sessionInfo: {
        teamInfo: { fields: customFields = [] },
        name: { firstName = '', lastName = '' } = {},
      },
      id: currentSessionId,
    },
  } = props;

  const getControlLabel: GetControlLabel = React.useCallback((option) => {
    return option[0].localName;
  }, []);

  return (
    <div className={sharedStyles.avPreferences}>
      <PanelSection
        noPadding
        title='Personal details'
        contentClassName={cx('panel-content')}
      >
        <ProfileCard
          firstName={firstName}
          lastName={lastName}
          profile={owner}
          guid={owner?.ownerGuid ?? null}
          sessionId={currentSessionId}
        />
      </PanelSection>
      {customFields.length > 0 && (
        <PanelSection
          noPadding
          title='Custom fields'
          contentClassName={cx('panel-content')}
        >
          <CustomFieldsCard fields={customFields} />
        </PanelSection>
      )}
      <PanelSection noPadding>
        <div className={styles['manage-account-container']}>
          <div className={styles['owner-email-container']}>
            <p className={styles['owner-email']}>{owner?.email}</p>
            <p className={styles['owner-email-subtext']}>
              Change account password or manage your teams
            </p>
          </div>
          <div className={styles['manage-account-cta-container']}>
            <Button
              variant='outline-primary'
              onClick={() => openManageAccountAdminPage(currentSessionId)}
              rightIcon={<Icon name='external' />}
            >
              Manage account
            </Button>
          </div>
        </div>
      </PanelSection>
      <PanelSection
        noPadding
        title='Display language'
        contentClassName={cx('panel-content')}
      >
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div onMouseDown={(e) => e.stopPropagation()}>
          <Select
            isMulti={false}
            onChange={(item: any) =>
              changeLanguage(item.value).then(() =>
                logMedusaGenericEvent('preferences_profile_language_changed', {
                  language: item.value,
                })
              )
            }
            className={cx('language-select')}
            options={languageOptions}
            value={currentLanguageOption}
            isSearchable={false}
            menuPortalTarget={document.body}
            menuPlacement='auto'
            minMenuWidth='350px'
            getControlLabel={getControlLabel}
            appTheme={currentTheme}
            // FIXME: um... maybe start cleaning up z-indexes?
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 90000 }) }}
          />
        </div>
      </PanelSection>
    </div>
  );
}

export default withCurrentSession(Profile);
