import bridge from '@/utils/bridge';
import store from '@/store';
import { Actions } from '@/actions/restricted';

const DEFAULT_TIMEOUT = 5000;

class MessageEditorNotificationProxy {
  // Keep tracks of count of subscriptions to app notifications.
  _numSubscribers = {};

  // Keeps track of the actual subscription handle returned by bridge.subscribe
  // for each peer.
  // TODO: This at present is only ever used for active chat. Need not keep an object
  // to track.
  _newNotificationSubscriptions = {};

  // Keep track of subscriptions for external notifications.
  // TODO: This at present is only ever used for active chat. Need not keep an object
  // to track.
  _externalNotificationSubscriptions = {};

  /**
   * Subscribes for chat state updates.
   */
  async subscribe(peer) {
    await this._addSubscription(peer);

    return {
      unsubscribe: this.unsubscribe.bind(this, peer),
    };
  }

  /**
   * Keeps track of subscriptions for a given session and a conversation.
   * Updates `_numSubscribers` and `_chatStateSubscriptions` with the current
   * count and the handles returned from `bridge.subscribe()`.
   *
   * We only subscribe once for given parameters and update the store while they
   * are in use.
   */
  async _addSubscription(peer) {
    const key = this._getKey(peer);

    if (this._numSubscribers[key]) {
      this._numSubscribers[key] += 1;
      return;
    }

    this._numSubscribers[key] = 0;

    const newNotificationSubscription = await bridge.subscribe(
      '/hydra/in_app_notification_store/newNotification',
      this._handleNewNotification.bind(this, peer)
    );
    const externalNotificationSubscription = await bridge.subscribe(
      '/hydra/inAppNotification/newExternalNotification',
      this._handleExternalNotification.bind(this, peer)
    );

    this._newNotificationSubscriptions[key] = newNotificationSubscription;
    this._externalNotificationSubscriptions[
      key
    ] = externalNotificationSubscription;
    this._numSubscribers[key] += 1;
  }

  /**
   * Gets a key to keep track of subscriptions.
   *
   * Format for a key is `<session_id>/<peer_jid>`.
   */
  _getKey(peer) {
    return `${peer.ownerGuid}/${peer.jid}`;
  }

  _handleNewNotification = (peer, notification) => {
    if (peer.jid === notification.jid) {
      store.dispatch(
        Actions.add_new_editor_notification({
          jid: peer.jid,
          ownerGuid: peer.ownerGuid,
          content: notification.content,
        })
      );
    }
  };

  /**
   * Notifications that are not chat-related will be inserted into the current
   * chat's notification queue.
   *
   * TODO: Maybe this needs to live separately but that could complicate the displaying
   * logic a bit.
   */
  _handleExternalNotification = (peer, ...[text, status, time]) => {
    store.dispatch(
      Actions.add_new_editor_notification({
        jid: peer.jid,
        ownerGuid: peer.ownerGuid,
        content: {
          text,
          status,
          time: time ?? DEFAULT_TIMEOUT,
          type: 'external',
        },
      })
    );
  };

  /**
   * Decrements the count and removes the handler if nothing is
   * using the subscription (i.e, count === 0). Also cleans up
   * the keys from the store.
   *
   */
  unsubscribe(peer) {
    const key = this._getKey(peer);

    if (!this._numSubscribers[key]) {
      return;
    }

    if (this._numSubscribers[key] > 0) {
      this._numSubscribers[key] -= 1;
    }

    if (this._numSubscribers[key] <= 0) {
      bridge.unsubscribe(this._newNotificationSubscriptions[key]);
      bridge.unsubscribe(this._externalNotificationSubscriptions[key]);

      store.dispatch(
        Actions.remove_all_editor_notifications({
          jid: peer.jid,
          ownerGuid: peer.ownerGuid,
        })
      );

      delete this._numSubscribers[key];
      delete this._newNotificationSubscriptions[key];
      delete this._externalNotificationSubscriptions[key];
    }
  }
}

export default new MessageEditorNotificationProxy();
