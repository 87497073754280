import bridge from './bridge';

let __cachedPromise;
const platform = {
  getAll() {
    if (__cachedPromise) {
      return __cachedPromise;
    }
    __cachedPromise = bridge.ask('HydraServer', 'getStartupOptions', []);
    return __cachedPromise;
  },
  isDesktopClient() {
    return platform.getAll().then((options) => {
      return !['browser', 'crx'].includes(options.uaInfoParams['device-type']);
    });
  },
  getDeviceHost() {
    return platform.getAll().then((options) => {
      console.debug('=========', options.currentPlatform);
      return options.currentPlatform;
    });
  },
  isMacAppStore() {
    return platform.getAll().then((options) => {
      return !!options.isMacAppStore;
    });
  },
  getRootPath() {
    if (window.location.href.indexOf('client_base') === -1) {
      return './';
    }
    return '../../../';
  },

  isSafari: (() => {
    return navigator.userAgent.toLowerCase().indexOf('safari/') > -1;
  })(),

  isChrome: (() => {
    return (
      !!window.chrome &&
      navigator.userAgent.toLowerCase().indexOf('chrome/') > -1
    );
  })(),
};

export default platform;
