const defaultAffiliationChangeVisibilityObj = {
  enabledAfterMemberCount: 0,
  hidden: [],
};

const getMemberListWithoutLeaveMessages = (members) => {
  return members.filter((member) => {
    return member.newAffiliation !== 'none';
  });
};

const getMemberListWithoutJoinMessages = (members) => {
  return members.filter((member) => {
    return member.oldAffiliation !== 'none';
  });
};

const isChannelLeaveMessage = (members) => {
  return members.some((member) => {
    return member.newAffiliation === 'none';
  });
};
const isChannelJoinMessage = (members) => {
  return members.some((member) => {
    return member.oldAffiliation === 'none';
  });
};
const isTeamLeaveMessage = (members, actor, jid) => {
  return isChannelLeaveMessage(members) && actor === jid;
};

const getAffiliationChangeVisibilityObj = (channelPreferences) => {
  const affiliationChangeVisibilityObj = {
    ...defaultAffiliationChangeVisibilityObj,
    ...(channelPreferences.affiliationChangeVisibility || {}),
  };
  return affiliationChangeVisibilityObj;
};

const isInfoMessageVisible = (
  message,
  channelPreferences,
  peer,
  ignoreConfigAndProfile
) => {
  if (
    !message ||
    !peer ||
    !channelPreferences ||
    !message.isGroupUpdateMessage ||
    !peer.isGroup ||
    !message.members
  )
    return message.isVisibleMessage;
  if (!ignoreConfigAndProfile && (message.config || message.profile)) {
    return message.isVisibleMessage;
  }
  const affiliationChangeVisibilityObj = getAffiliationChangeVisibilityObj(
    channelPreferences
  );
  const hiddenAffiliationCategories = affiliationChangeVisibilityObj.hidden;

  if (
    peer.memberCount <=
      affiliationChangeVisibilityObj.enabledAfterMemberCount ||
    !hiddenAffiliationCategories.length
  ) {
    return true;
  }
  if (hiddenAffiliationCategories.includes('ANY')) {
    return false;
  }
  if (
    hiddenAffiliationCategories.includes('JOIN') &&
    isChannelJoinMessage(message.members)
  ) {
    return false;
  }
  if (
    hiddenAffiliationCategories.includes('LEAVE') &&
    isChannelLeaveMessage(message.members)
  ) {
    return false;
  }
  if (
    hiddenAffiliationCategories.includes('LEAVE_TEAM') &&
    isTeamLeaveMessage(message.members, message.actor, peer.jid)
  ) {
    return false;
  }
  return true;
};

const getVisibleMembersList = (msg, channelPreferences, peer) => {
  if (!isInfoMessageVisible(msg, channelPreferences, peer, true)) {
    const {
      hidden: hiddenAffiliationCategories,
    } = getAffiliationChangeVisibilityObj(channelPreferences);
    let visibleMembersList = [...msg.members];
    if (hiddenAffiliationCategories.includes('ANY')) {
      return [];
    }
    if (
      hiddenAffiliationCategories.includes('JOIN') &&
      isChannelJoinMessage(visibleMembersList)
    ) {
      visibleMembersList = getMemberListWithoutJoinMessages(visibleMembersList);
    }
    if (
      hiddenAffiliationCategories.includes('LEAVE') &&
      isChannelLeaveMessage(visibleMembersList)
    ) {
      visibleMembersList = getMemberListWithoutLeaveMessages(
        visibleMembersList
      );
      return visibleMembersList;
    }
    if (
      hiddenAffiliationCategories.includes('LEAVE_TEAM') &&
      isTeamLeaveMessage(visibleMembersList, msg.actor, peer.jid)
    ) {
      visibleMembersList = getMemberListWithoutLeaveMessages(
        visibleMembersList
      );
      return visibleMembersList;
    }
    return visibleMembersList;
  }
  return false;
};

export { isInfoMessageVisible, getVisibleMembersList };
