import difference from 'lodash/difference';

const defaultState = {};

function initPeerObject(peerJid, updatedPeers, teamMessages) {
  if (!updatedPeers[peerJid]) {
    updatedPeers[peerJid] = [
      ...((teamMessages && teamMessages[peerJid]) || []),
    ];
  }
  return updatedPeers[peerJid];
}

export default (ActionTypes) => {
  return (state = defaultState, action) => {
    switch (action.type) {
      case ActionTypes.UPDATE_UNREAD_MESSAGES: {
        const { messages, ownerGuid } = action.payload;
        const { [ownerGuid]: teamMessages } = state;
        const updatedPeers = {};
        messages.forEach((msg) => {
          const {
            peer: { jid: peerJid },
            isVisibleMessage,
            message_cache_id: mCId,
          } = msg;
          const isVisible = !msg.sentByOwner && isVisibleMessage;
          const idx =
            teamMessages && teamMessages[peerJid]
              ? teamMessages[peerJid].indexOf(mCId)
              : -1;
          if (isVisible) {
            if (idx === -1) {
              initPeerObject(peerJid, updatedPeers, teamMessages);
              updatedPeers[peerJid].push(mCId);
            }
          } else if (idx > -1) {
            const peerMsgs = initPeerObject(
              peerJid,
              updatedPeers,
              teamMessages
            );
            const updateIdx = peerMsgs.indexOf(mCId);
            if (updateIdx > -1) {
              updatedPeers[peerJid] = [
                ...peerMsgs.slice(0, updateIdx),
                ...peerMsgs.slice(updateIdx + 1),
              ];
            }
          }
        });

        if (Object.keys(updatedPeers).length) {
          const updatedTeamMessages = {
            ...teamMessages,
            ...updatedPeers,
          };
          Object.keys(updatedPeers).forEach((peerJid) => {
            if (updatedPeers[peerJid].length === 0) {
              delete updatedTeamMessages[peerJid];
            }
          });
          return {
            ...state,
            [ownerGuid]: updatedTeamMessages,
          };
        }
        return state;
      }

      case ActionTypes.MARK_UNREAD_MESSAGES_AS_READ: {
        const { ownerGuid, peerJid, readMessagesCacheIds } = action.payload;
        if (state[ownerGuid] && state[ownerGuid][peerJid]) {
          const peerMsgs = state[ownerGuid][peerJid];
          const updatedPeerMsgs = difference(peerMsgs, readMessagesCacheIds);
          const isUpdated = updatedPeerMsgs.length < peerMsgs.length;
          if (isUpdated) {
            const teamMessages = {
              ...state[ownerGuid],
              [peerJid]: updatedPeerMsgs,
            };
            if (updatedPeerMsgs.length === 0) {
              delete teamMessages[peerJid];
            }
            return {
              ...state,
              [ownerGuid]: teamMessages,
            };
          }
        }
        return state;
      }

      case ActionTypes.DELETE_UNREAD_MESSAGE: {
        const { mCId, peerJid, ownerGuid } = action.payload;
        if (state[ownerGuid] && state[ownerGuid][peerJid]) {
          const peerMsgs = state[ownerGuid][peerJid];
          const idx = peerMsgs.indexOf(mCId);
          if (idx > -1) {
            let teamMessages;
            if (peerMsgs.length > 1) {
              teamMessages = {
                ...state[ownerGuid],
                [peerJid]: [
                  ...peerMsgs.slice(0, idx),
                  ...peerMsgs.slice(idx + 1),
                ],
              };
            } else {
              const { [peerJid]: peerMessages, ...otherPeerMessages } = state[
                ownerGuid
              ];
              teamMessages = otherPeerMessages;
            }

            return {
              ...state,
              [ownerGuid]: teamMessages,
            };
          }
        }
        return state;
      }

      case ActionTypes.UNREAD_MESSAGES_RESET_PEER: {
        const { peerJid, ownerGuid } = action.payload;
        if (state[ownerGuid] && state[ownerGuid][peerJid]) {
          const { [peerJid]: peerMessages, ...otherPeerMessages } = state[
            ownerGuid
          ];
          return {
            ...state,
            [ownerGuid]: otherPeerMessages,
          };
        }
        return state;
      }

      case ActionTypes.UNREAD_MESSAGES_RESET_TEAM: {
        const { ownerGuid } = action.payload;
        if (state[ownerGuid]) {
          const { [ownerGuid]: teamMessages, ...otherTeamMessages } = state;
          return {
            ...otherTeamMessages,
          };
        }
        return state;
      }

      default:
        return state;
    }
  };
};
