/**
 * This is a Subscriber Interface.
 * @param {String} name Identifier for the subscriber.
 * Can be any string that a developer can point to which subscriber.
 * Ideally, it should be the name of the file to make it easier to locate.
 * @returns a class with `selectState` function and `notify` function
 */
export default class Subscriber {
  constructor(name) {
    this.name = name;
    if (!name) {
      console.error('No Name was provided for a subscriber.');
    }
  }

  /**
   * The state selector function
   * @param {Object} state from redux store
   * @returns the selected state
   */
  selectState = (state) => {
    console.error(
      `Implementation of selectState is mandatory for ${this.name} subscriber.`
    );
    return state.some.deep.value;
  };

  /**
   * The `notify` function
   * @param {Object} selectedState - Output of the `selectState` function above.
   * @returns Nothing. Can return a promise if it can be handled.
   */
  notify = (selectedState) => {
    console.error(
      `Implementation of notify is mandatory for ${this.name} subscriber`
    );
    return selectedState;
  };
}
