import {
  emoticons as emoticonMap,
  natives as nativeMap,
  shortcodes as shortcodeMap
} from "emoji-data/data/maps.json";
import {
  emoticons as emoticonRegex,
  emojis as emojiRegex
} from "emoji-data/data/regex.json";
import Pica from "pica";
import * as util from "../../util";
import { version } from "../../../package.json";

export class ConvertorFactory {
  version = version;
  vendors = ["apple", "google"];

  _listByCategory = {};

  constructor(props) {
    this.props = props;

    if (!this.vendors.includes(this.props.vendor)) {
      this.props.vendor = util.getEnvVendor();
    }

    this._preloadSpritesheets();
  }

  _applyEmojiOnly(target) {
    if (target && target.nodeType && target.nodeType === Node.ELEMENT_NODE) {
      const emojis = target.getElementsByClassName(this.props.emojiClass);
      const filterOutEmojis = node => {
        if (node.parentNode.classList.contains(this.props.emojiClass)) {
          return NodeFilter.FILTER_REJECT;
        }
        return NodeFilter.FILTER_ACCEPT;
      };

      // Only proceed if only one emoji found
      if (emojis.length === 1) {
        // Using a TreeWalker we can get an iterator with filtered nodes
        // We only get text nodes with NodeFilter.SHOW_TEXT
        // We remove emoji text nodes with filterOutEmojis function
        const treeWalker = document.createTreeWalker(
          target,
          NodeFilter.SHOW_TEXT,
          filterOutEmojis,
          false
        );
        treeWalker.firstChild();
        let hasText = false;

        do {
          let currentNode = treeWalker.currentNode;
          if (
            currentNode.nodeValue &&
            currentNode.nodeValue.trim().length > 0
          ) {
            hasText = true;
            break;
          }
        } while (treeWalker.nextSibling() !== null);

        if (!hasText) {
          const onlyEmoji = emojis[0];
          onlyEmoji.classList.add(this.props.emojiOnlyClass);
          return onlyEmoji;
        }
      }
    }

    return target;
  }

  _convertEmoticonsToShortcodes(str) {
    let nextOffset = 0;
    const withParenthesis = [];
    const emoticonRx = new RegExp(
      emoticonRegex.source,
      emoticonRegex.modifiers
    );
    let strReplaced = str.replace(emoticonRx, (m, $1, emoticon, offset) => {
      const prevOffset = nextOffset;
      nextOffset = offset + m.length;

      const hasOpenParenthesis = emoticon.indexOf("(") !== -1;
      const hasCloseParenthesis = emoticon.indexOf(")") !== -1;

      // Track parenthesis-having emoticons for fixing later
      if (
        (hasOpenParenthesis || hasCloseParenthesis) &&
        withParenthesis.indexOf(emoticon) == -1
      ) {
        withParenthesis.push(emoticon);
      }

      // Look for preceding open paren for emoticons that contain a close parenthesis
      // This prevents matching "8)" inside "(around 7 - 8)"
      if (hasCloseParenthesis && !hasOpenParenthesis) {
        const piece = str.substring(prevOffset, offset);
        if (piece.indexOf("(") !== -1 && piece.indexOf(")") === -1) {
          return m;
        }
      }

      // See if we're in a numbered list
      // This prevents matching "8)" inside "7) foo\n8) bar"
      if (m === "\n8)") {
        const beforeMatch = str.substring(0, offset);
        if (/\n?(6\)|7\))/.test(beforeMatch)) {
          return m;
        }
      }

      const emoji = this.getEmojiById(emoticonMap[emoticon]);
      if (emoji && emoji.shortcodes[0]) {
        return $1 + emoji.shortcodes[0];
      }

      return m;
    });

    // Come back and fix emoticons we ignored because they were inside parenthesis
    if (withParenthesis.length) {
      const escapedEmoticons = withParenthesis.map(util.escapeRegex);
      const parenthesisRx = new RegExp(
        `(\\(.+)(${escapedEmoticons.join("|")})(.+\\))`,
        "g"
      );

      strReplaced = strReplaced.replace(
        parenthesisRx,
        (m, $1, emoticon, $2) => {
          const emoji = this.getEmojiById(emoticonMap[emoticon]);
          if (emoji && emoji.shortcodes[0]) {
            return $1 + emoji.shortcodes[0] + $2;
          }
          return m;
        }
      );
    }

    return strReplaced;
  }

  _convertNativesToShortcodes(str) {
    const rx = new RegExp(emojiRegex.source, emojiRegex.modifiers);
    let tmpNode = "";
    let hasEmoji = false;
    let prevIndex = 0;
    let match;
    while ((match = rx.exec(str)) !== null) {
      hasEmoji = true;
      const emojiStr = match[0];
      const emojiIndex = match.index;
      const emojiLength = emojiStr.length;

      // Include text between previous emoji and this one,
      // if first iteration start from begining (prevIndex starts at 0)
      const textStart = str.substring(prevIndex, emojiIndex);
      if (textStart.length > 0) {
        tmpNode += textStart;
      }

      const frag = document.createElement("span");
      frag.innerHTML = this._convertToCSSImage(emojiStr);

      const emoji = this.getEmojiById(nativeMap[emojiStr]);
      let shortcode;
      if (emoji) {
        let currEmoji = emoji;
        shortcode = currEmoji.shortcodes[0];
        let skinTone = 0;
        if (shortcode && emoji.skinId) {
          currEmoji = emoji.skins.find((e, i) => {
            skinTone = i + 2;
            return e.id === emoji.skinId;
          });
          shortcode += `:skin-tone-${skinTone}:`;
        }
      }
      if (!shortcode) {
        tmpNode += emojiStr;
      } else {
        tmpNode += shortcode;
      }

      prevIndex = emojiIndex + emojiLength;
    }

    if (hasEmoji) {
      // Include text after last emoji
      const textEnd = str.substring(prevIndex);
      if (textEnd.length > 0) {
        tmpNode += textEnd;
      }
      return tmpNode;
    }

    return str;
  }

  _convertShortcodesToNatives(str, presetSkinTone = 1) {
    // don't convert emojis inside urls
    const urlPos = [];
    const hasUrl = util.getUrlRegex().test(str);
    const rx = () => new RegExp(":[a-zA-Z0-9-_+]+:(:skin-tone-[2-6]:)?", "g");

    if (hasUrl) {
      const urlRx = util.getUrlRegex();
      let urlMatch = null;
      while ((urlMatch = urlRx.exec(str)) !== null) {
        const urlMatchString = urlMatch[0];
        const urlMatchIndex = urlMatch.index;
        let emojiMatch;
        const _rx = rx();
        while ((emojiMatch = _rx.exec(urlMatchString)) !== null) {
          urlPos.push([
            emojiMatch[0],
            urlMatchIndex + emojiMatch.index,
            emojiMatch[0].length
          ]);
        }
      }
    }

    return str.replace(rx(), (m, p1, offset) => {
      const pos = [m, offset, m.length];
      let inUrl = urlPos.some(
        url => pos[0] === url[0] && pos[1] === url[1] && pos[2] === url[2]
      );

      if (inUrl) {
        return m;
      }

      let shortcode = m.toLowerCase();
      let skinTone = 0;

      if (shortcode.indexOf(":skin-tone-") > -1) {
        const squareIndex = shortcode.indexOf("::") + 1;
        shortcode = shortcode.substring(0, squareIndex);
        skinTone = parseInt(m.substr(-2, 1));
      }

      const emoji = this.getEmojiById(shortcodeMap[shortcode]);
      if (emoji) {
        if (presetSkinTone >= 2 && emoji.hasSkins) {
          return emoji.skins[presetSkinTone - 2].native;
        } else if (skinTone >= 2 && emoji.hasSkins) {
          return emoji.skins[skinTone - 2].native;
        }
        return emoji.native;
      }

      return m;
    });
  }

  _convertToCSSImage(strUnicode) {
    const { vendor } = this.props;
    const emoji = this.getEmojiById(nativeMap[strUnicode]);
    let currEmoji = emoji;
    let shortcode = currEmoji.shortcodes[0];
    let skinTone = 0;
    if (emoji.skinId) {
      currEmoji = emoji.skins.find((e, i) => {
        skinTone = i + 2;
        return e.id === emoji.skinId;
      });
      shortcode += `:skin-tone-${skinTone}:`;
    }

    if (!currEmoji) {
      return strUnicode;
    }

    const style = this.getCSSForEmoji(currEmoji, true);

    return `<span style="${style}" class="${
      this.props.emojiClass
    }" data-emoji-id="${
      currEmoji.id
    }" data-emoji-shortcode="${shortcode}" data-emoji-vendor="${vendor}"></span>`;
  }

  _getCSSImageAttributes(strUnicode) {
    // const { vendor } = this.props;
    const emoji = this.getEmojiById(nativeMap[strUnicode]);
    let currEmoji = emoji;
    if (emoji && emoji.skinId) {
      currEmoji = emoji.skins.find((e) => {
        return e.id === emoji.skinId;
      });
    }

    if (!currEmoji) {
      throw new Error('Emoji data not found for ' + strUnicode);
    }

    const style = this.getCSSForEmoji(currEmoji);
    return { style };

  }

  _convertToNatives(str, skinTone) {
    let res = str;
    res = this._convertEmoticonsToShortcodes(res);
    res = this._convertShortcodesToNatives(res, skinTone);
    return res;
  }

  _convertToShortcodes(str) {
    let res = str;
    res = this._convertEmoticonsToShortcodes(res);
    res = this._convertNativesToShortcodes(res);
    return res;
  }

  _decorateElementNode(node, options = {}) {
    if (!this.hasEmoji(node)) {
      const { ignoreNodeTypes = [] } = options;
      for (let i = 0, l = node.childNodes.length; i < l; ++i) {
        // We will be removing each child node from the passed node
        // looking for text or element nodes that could contain strings.
        const child = node.removeChild(node.firstChild);

        if (child.nodeType && child.nodeType === Node.TEXT_NODE) {
          // TextNode - Append result from _decorateTextNode
          node.appendChild(this._decorateTextNode(child));
        } else if (
          child.nodeType &&
          child.nodeType === Node.ELEMENT_NODE &&
          !ignoreNodeTypes.includes(child.nodeName) &&
          !this.hasEmoji(child)
        ) {
          // ElementNode - Append result from _decorateElementNode
          node.appendChild(this._decorateElementNode(child, options));
        } else {
          // Default - Append child
          node.appendChild(child);
        }
      }
    }
    return node;
  }

  _decorateString(str) {
    // convert shortcodes/emoticons to unicode
    const input = this._convertToNatives(str);

    const rx = new RegExp(emojiRegex.source, emojiRegex.modifiers);
    const tmpNode = document.createElement("span");
    let hasEmoji = false;
    let prevIndex = 0;
    let match;
    while ((match = rx.exec(input)) !== null) {
      hasEmoji = true;
      const emojiStr = match[0];
      const emojiIndex = match.index;
      const emojiLength = emojiStr.length;

      // Include text between previous emoji and this one,
      // if first iteration start from begining (prevIndex starts at 0)
      const textStart = input.substring(prevIndex, emojiIndex);
      if (textStart.length > 0) {
        tmpNode.appendChild(document.createTextNode(textStart));
      }

      const frag = document.createElement("span");
      frag.innerHTML = this._convertToCSSImage(emojiStr);
      if (frag.firstChild) {
        tmpNode.appendChild(frag.firstChild);
      }

      prevIndex = emojiIndex + emojiLength;
    }

    if (hasEmoji) {
      // Include text after last emoji
      const textEnd = input.substring(prevIndex);
      if (textEnd.length > 0) {
        tmpNode.appendChild(document.createTextNode(textEnd));
      }
      return tmpNode;
    }

    return str;
  }

  _getTokenizedEmojisFromString(str) {
    // convert shortcodes/emoticons to unicode
    const input = this._convertToNatives(str);

    const rx = new RegExp(emojiRegex.source, emojiRegex.modifiers);
    const tokenArray = [];
    let hasEmoji = false;
    let prevIndex = 0;
    let match;
    while ((match = rx.exec(input)) !== null) {
      hasEmoji = true;
      const emojiStr = match[0];
      const emojiIndex = match.index;
      const emojiLength = emojiStr.length;

      // Include text between previous emoji and this one,
      // if first iteration start from begining (prevIndex starts at 0)
      const textStart = input.substring(prevIndex, emojiIndex);
      if (textStart.length > 0) {
        tokenArray.push({
          isText: true,
          length: textStart.length,
          value: textStart 
        });
      }

      try {
        const emojiAttributes = this._getCSSImageAttributes(emojiStr);

        tokenArray.push({
          isText: false,
          length: emojiLength,
          value: emojiAttributes,
        });
      } catch {
        tokenArray.push({
          isText: true,
          length: emojiLength,
          value: emojiStr,
        });
      }
      
      prevIndex = emojiIndex + emojiLength;
    }

    if (hasEmoji) {
      // Include text after last emoji
      const textEnd = input.substring(prevIndex);
      if (textEnd.length > 0) {
        tokenArray.push({
          isText: true,
          length: textEnd.length,
          value: textEnd, 
        });
      }
      return tokenArray;
    }

    return [{
      isText: true,
      length: str.length,
      value: str,
    }];
  }

  _decorateTextNode(node) {
    const str = node.data && node.data.trim();
    const res = this._decorateString(str);
    if (this.hasEmoji(res)) {
      return res;
    }
    return node;
  }

  _removeEmojis(target) {
    const decorated = this.decorate(target);
    if (this.hasEmoji(decorated)) {
      if (decorated.classList.contains(this.props.emojiClass)) {
        decorated.style = "";
        decorated.classList.remove(
          this.props.emojiClass,
          this.props.emojiOnlyClass
        );
        decorated.removeAttribute("data-emoji-id");
        decorated.removeAttribute("data-emoji-vendor");
        return decorated;
      }

      const emojis = decorated.getElementsByClassName(this.props.emojiClass);
      while (emojis.length > 0) {
        emojis[0].parentNode.removeChild(emojis[0]);
      }
    }
    if (typeof target === "string" && decorated.nodeType) {
      if (decorated.nodeType === Node.ELEMENT_NODE) {
        return decorated.textContent;
      } else if (decorated.nodeType === Node.TEXT_NODE) {
        return decorated.data;
      }
    }

    return decorated;
  }

  _searchCompare(a, b) {
    let value = "";
    let separator;

    if (b.indexOf("_") > -1) {
      separator = "_";
    } else if (b.indexOf("-") > -1) {
      separator = "-";
    }

    // Term is at the beginning of b
    if (b.toLowerCase().indexOf(a) === 0) {
      value += "0";
    } else {
      value += "1";
    }

    // Term is at the beginning of any word of b
    if (separator) {
      if (
        b
          .toLowerCase()
          .split(separator)
          .some(s => s.indexOf(a) === 0)
      ) {
        value += "0";
      } else {
        value += "1";
      }
    } else {
      // Single word, repeats the previous condition
      value += value;
    }

    value += b.split(separator).length;
    value += b;
    return value;
  }

  _targetHasClass(target, className) {
    let hasEmoji = false;
    if (target && target.nodeType && target.nodeType === Node.ELEMENT_NODE) {
      const emojis = target.getElementsByClassName(className);
      hasEmoji = target.classList.contains(className) || emojis.length > 0;
    }
    return hasEmoji;
  }

  async _preloadSpritesheets() {
    const {
      appleImagesBaseUrl,
      appleSpritesheetUrl,
      googleImagesBaseUrl,
      googleSpritesheetUrl
    } = this.props;
    let imagesBaseUrl =
      googleImagesBaseUrl || "./node_modules/emoji-data/images/google/";
    let spritesheet =
      googleSpritesheetUrl || "./node_modules/emoji-data/images/google.png";

    if (this.props.vendor === "apple") {
      imagesBaseUrl =
        appleImagesBaseUrl || "./node_modules/emoji-data/images/apple/";
      spritesheet =
        appleSpritesheetUrl || "./node_modules/emoji-data/images/apple.png";
    }

    this.imagesBaseUrl = imagesBaseUrl;
    this.spritesheetUrl = spritesheet;

    try {
      await util.preloadImage(this.spritesheetUrl);
    } catch(err) {
      console.error('Error while preloading spritesheet', this.spritesheetUrl, err);
    }
  }

  async _resizeStickerToCanvas(src, size) {
    if (!(src && size)) return;
    const pica = Pica({ features: ["all"] });
    const picaOptions = {
      alpha: true,
      unsharpAmount: 80,
      unsharpRadius: 0.6,
      unsharpThreshold: 2
    };
    const canvas = document.createElement("canvas");
    canvas.width = size;
    canvas.height = size;

    return new Promise(resolve => {
      const img = new Image();
      img.onload = async () => {
        try {
          const resCanvas = await pica.resize(img, canvas, picaOptions);
          resolve(resCanvas.toDataURL("image/png"));
        } catch (e) {
          console.error(e);
          resolve();
        }
      };
      img.setAttribute("crossorigin", "anonymous");
      img.src = `${src}?r=${Date.now()}`;
    });
  }
}
