import { logMedusaGenericEvent } from '@/utils/logAnalytics';
import { sanitizeChannelName } from '@/utils/ContactUtils';
import { escapedForRegex } from '@/utils';
import { GENERIC_MENTIONS } from './constants';
import { MessageObj, Mentions, Peer } from './Types';

const xmlEntities = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&apos;',
};

const xmlEntityRegex = new RegExp(Object.keys(xmlEntities).join('|'), 'g');

function encodeHtmlEntities(str: string): string {
  return str.replace(xmlEntityRegex, function (match) {
    return xmlEntities[match];
  });
}

function wrapInFlockmlTags(text: string): string {
  return `<flockml>${encodeHtmlEntities(text)}</flockml>`;
}

function replaceNewLineByBr(text: string): string {
  // eslint-disable-next-line no-control-regex
  return text.replace(new RegExp('\r?\n', 'g'), '<br />');
}

function replaceBrbyNewLine(flockml) {
  const _flockml = flockml;
  return _flockml.replace(/<br\s*\/?>/gm, '\n');
}

function wrapInChannelTags(channelName: string, channelGuid: string): string {
  const encodedChannelName = `#${encodeHtmlEntities(
    sanitizeChannelName(channelName)
  )}`;
  return `<channel channelId="g:${channelGuid}">${encodedChannelName}</channel>`;
}

function wrapInUserElement(
  userName: string,
  userJid: string,
  group: string | null
): string {
  const encodedUserName = encodeHtmlEntities(userName);
  if (group) {
    return `<user group="${group}">${encodedUserName}</user>`;
  }

  if (userJid) {
    const guid = userJid && userJid.split('@')[0];
    return `<user userId="u:${guid}">${encodedUserName}</user>`;
  }

  return encodedUserName;
}

function stringReplace(
  str: string,
  find: string,
  replace: string,
  notGlobal: boolean
): string {
  let regex: RegExp;

  if (notGlobal) {
    regex = new RegExp(escapedForRegex(find));
  } else {
    regex = new RegExp(escapedForRegex(find), 'g');
  }

  const val = str.replace(regex, replace);
  return val;
}

function createFlockml(textContent) {
  const _textContent = textContent;
  return replaceNewLineByBr(wrapInFlockmlTags(_textContent));
}

function addMentionsToMessageObject(
  messageObj: MessageObj,
  mentions: Mentions
): MessageObj {
  if (!mentions.length) {
    return messageObj;
  }

  if (!messageObj.messageElements) {
    messageObj.messageElements = {};
  }

  messageObj.messageElements.mentions = mentions;
  return messageObj;
}

function addChannelMentionsInFlockml(flockml: string, channel, peer: Peer) {
  let _flockml = flockml;

  if (!channel) {
    return _flockml;
  }

  const name = channel.chatName;
  const channelGuid = channel.jid.split('@')[0];
  const textToReplace = `#${sanitizeChannelName(name)}`;
  const channelWithTag = wrapInChannelTags(name, channelGuid);
  _flockml = stringReplace(_flockml, textToReplace, channelWithTag, true);

  logMedusaGenericEvent('channel_link_created', {
    channelGuid,
    chat_type: peer.type,
  });

  return _flockml;
}

function getChannelMentionHtml(channel) {
  if (!channel) {
    return '';
  }
  const name = channel.chatName;
  const channelGuid = channel.jid.split('@')[0];
  const channelWithTag = wrapInChannelTags(name, channelGuid);

  return channelWithTag;
}

/**
 *
 * @param {*} flockml
 * @param {*} Contact
 *
 * Only for flockml type
 */
function addBuddyMentionsInFlockml(
  flockml: string,
  contact: BuddyContact
): string {
  // TODO: Replace is not working, need to figure out why.
  // FIXME: mentions are not being wrapped in the returned textContent.
  const _flockml = flockml;
  const { chatName } = contact || {};
  const mentionToken = getBuddyMentionToken(chatName);
  const mentionHtml = getBuddyMentionHtml(contact as Contact);

  if (!mentionHtml) {
    return _flockml;
  }

  return _flockml.replace(mentionToken, mentionHtml);
}

/**
 *
 * @param {*} contact
 *
 * Only for flockml type
 */
function getBuddyMentionHtml(contact: Contact): string {
  const { chatName, chatType, jid } = contact;
  let group = null;
  let _jid = null;
  const mentionToken = getBuddyMentionToken(chatName);
  if (GENERIC_MENTIONS.indexOf(chatType) > -1) {
    group = chatType;
  } else {
    _jid = jid ? jid.split('@')[0] : null;
  }

  if (!_jid && !group) {
    return '';
  }
  const userWithTag = wrapInUserElement(mentionToken, _jid, group);
  return userWithTag;
}

function getBuddyMentionToken(chatName: string): string {
  const mentionToken = `@${chatName
    .trim()
    .replace(/ /g, String.fromCharCode(0x2005))}`;
  return mentionToken;
}

function sanitizeText(text: string): string {
  if (!text) return text;
  return encodeHtmlEntities(text);
}

export {
  wrapInFlockmlTags,
  replaceNewLineByBr,
  replaceBrbyNewLine,
  wrapInChannelTags,
  wrapInUserElement,
  escapedForRegex,
  stringReplace,
  createFlockml,
  addBuddyMentionsInFlockml,
  addChannelMentionsInFlockml,
  getChannelMentionHtml,
  getBuddyMentionHtml,
  addMentionsToMessageObject,
  getBuddyMentionToken,
  sanitizeText,
};
