import * as React from 'react';
import cx from 'classnames';

import noop from '@/utils/noop';
import css from './NotificationIndicatorItem.css';

const { useState, useEffect, useRef, useCallback } = React;

export default function NotificationIndicatorItem({
  children,
  open = false,
  status = 'success',
  visibleDuration = 0,
  onClose = noop,
}) {
  const [visible, setVisible] = useState(open);
  const onCloseHandlerRef = useRef(onClose);
  const timeoutIdRef = useRef(null);

  useEffect(() => {
    if (visibleDuration) {
      timeoutIdRef.current = setTimeout(() => {
        setVisible(false);
        onCloseHandlerRef.current('timeout');
      }, visibleDuration);
    }

    return () => {
      clearTimeout(timeoutIdRef.current);
    };
  }, [visibleDuration, children]);

  useEffect(() => {
    onCloseHandlerRef.current = onClose;
  }, [onClose]);

  useEffect(() => {
    setVisible(open);
  }, [open]);

  const onCloseBtnClick = useCallback(() => {
    setVisible(false);
    clearTimeout(timeoutIdRef.current);
    onCloseHandlerRef.current('button');
  }, []);

  const classNames = cx([css.NotificationIndicatorItem], {
    [css.Success]: status === 'success',
    [css.Danger]: status === 'danger',
    [css.Warning]: status === 'warn',
  });

  return (
    visible && (
      <div className={classNames}>
        {children}
        <button
          type='button'
          onClick={onCloseBtnClick}
          className={css.CloseIconButton}
        >
          <svg
            width='15'
            height='15'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M13.85 15.97l-3.536-3.535-3.535 3.536a1.5 1.5 0 01-2.122-2.121l3.536-3.536-3.536-3.536a1.5 1.5 0 112.121-2.121l3.536 3.536 3.536-3.536a1.5 1.5 0 112.12 2.122l-3.535 3.535 3.536 3.535a1.5 1.5 0 11-2.122 2.122z'
              fill='rgba(0,0,0,0.3)'
            />
          </svg>
        </button>
      </div>
    )
  );
}
