import isEqual from 'lodash/isEqual';
import store from '@/store';
import * as subscribers from './subscribers';

/**
 * @param subscriber The implementation of the `Subscriber` interface.
 * The `handler` method of the instance of this class will be used by the `store.subscribe` invocation.
 */
class SubscriberHandler {
  currentValue = null;

  constructor(subscriber) {
    this.subscriber = subscriber;
  }

  /** This method acts as a closure.
   * It preserves the context of `currentValue` for future comparision during redux store changes.
   */
  handler = () => {
    const { subscriber } = this;
    const previousValue = this.currentValue;
    this.currentValue = subscriber.selectState(store.getState());
    const { currentValue } = this;
    /** previousValue can be null */
    if (
      currentValue &&
      !isEqual(previousValue, currentValue) &&
      typeof subscriber.notify === 'function'
    ) {
      subscriber.notify(currentValue, previousValue);
    }
  };
}

export default function initStoreSubscribers() {
  Object.keys(subscribers).forEach((id) => {
    // eslint-disable-next-line import/namespace
    store.subscribe(new SubscriberHandler(subscribers[id]).handler);
  });
}
