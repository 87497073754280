import React, { Component } from 'react';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    console.error('Error boundary error ', error);
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.error('Error boundary caught error: ', error, info);
  }

  onReload = () => {
    this.setState({
      hasError: false,
    });
  };

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          An error occurred.{' '}
          <button type='button' onClick={this.onReload}>
            Reload
          </button>
        </div>
      );
    }

    return children;
  }
}
