export default {
  GET_THE_APP: 'Obtenha o Aplicativo',
  MAKE_FLOCK_BETTER_APPS: 'Deixe o Flock ainda melhor com aplicativos',
  groupPurpose_blank_value: 'Defina o prop\u00f3sito do seu canal aqui',
  messageArea_chatTab_text_historyButton:
    'Este é o início da sua conversa com {name}',
  me_tab_user_status:
    'Salve mensagens r\u00e1pidas, arquivos ou links para ler mais tarde. Acompanhe afazeres pessoais ou crie notas, sem se preocupar!',
  me_tab_your_space: 'Seu espa\u00e7o pessoal',
  visible_to_me_text: 'S\u00f3 voc\u00ea pode ver isto',
  me_text: 'Eu',
  history_fetch_error: 'Algo deu errado',
  retry: 'Tentar novamente',
  pay_wall_user:
    'H\u00e1 mais mensagens aqui, mas elas excedem o limite do seu plano gratuito. Solicite ao administrador o upgrade da sua equipe para relevar todas as mensagens antigas',
  pay_wall_admin:
    'H\u00e1 mais mensagens aqui, mas elas excedem o limite do seu plano gratuito. Fa\u00e7a upgrade da sua equipe para relevar todas as mensagens antigas',
};
