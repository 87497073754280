import React from 'react';
import classNames from 'classnames';
import cssStyles from './ToggleButton.css';

function ToggleButton(props) {
  const { isActive } = props;
  return (
    <div className={cssStyles.toggle_button}>
      <div
        className={classNames(cssStyles.toggle_button_background, {
          [cssStyles.toggle_button_background_active]: isActive,
        })}
      />
      <div
        className={classNames(cssStyles.toggle_button_handle, {
          [cssStyles.toggle_button_handle_active]: isActive,
        })}
      />
    </div>
  );
}

export default ToggleButton;
