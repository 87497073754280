import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useContext,
} from 'react';
import cn from 'classnames';
import noop from '@/utils/noop';
import InfoMessageProps from '@/Contexts/InfoMessageProps';
import { getVisibleMembersList } from '@/utils/message/infoMessageUtils';
import GroupUpdateMessage from './GroupUpdateMessage';
import cssStyles from './InfoMessage.css';

const InfoMessage = React.memo(function ({
  message,
  lastInGroup,
  isCollapsed,
}) {
  return (
    <div
      className={cn(cssStyles.infoMessageRoot, 'chat-message', {
        [cssStyles.lastInGroup]: lastInGroup,
      })}
      data-msgroot-id={message.message_cache_id}
      data-msgroot-sid={message.sid}
    >
      <GroupUpdateMessage msg={message} translateOwner onlyLast={isCollapsed} />
    </div>
  );
});

const InfoMessageGroup = React.memo(function ({
  msgs,
  firstInDate,
  observerCallback,
  onMessageIntersection,
}) {
  const [isCollapsed, setCollapsed] = useState(true);
  const { channelPreferences, peer } = useContext(InfoMessageProps) || {};
  const toggleCollapsedState = useCallback(() => {
    setCollapsed(!isCollapsed);
  }, [isCollapsed]);

  const processedMsgs = msgs.map((msg) => {
    const newMemberList = getVisibleMembersList(msg, channelPreferences, peer);
    if (newMemberList) {
      return { ...msg, members: newMemberList };
    }
    return msg;
  });
  const lastMessage = processedMsgs.slice(-1)[0]; // lastMessage is always rendered
  const msgsToRender = isCollapsed ? [lastMessage] : processedMsgs;
  const lineCount = processedMsgs.reduce(
    (count, msg) => count + (msg.members?.length || 1),
    0
  );

  const ref = useRef();
  useEffect(() => {
    const el = ref.current;
    if (!el) {
      return () => {};
    }
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => onMessageIntersection(entry));
      },
      {
        root: null,
      }
    );
    observer.observe(el);

    return () => observer.unobserve(el);
  }, [onMessageIntersection, lastMessage, msgs.length]);

  return (
    <div
      ref={ref}
      className={cn(cssStyles.infoMessageGroup, {
        [cssStyles.showTopDivider]: !firstInDate,
      })}
      data-infogroup-id={lastMessage.message_cache_id}
    >
      {msgsToRender.map((msg, index) => (
        <InfoMessage
          message={msg}
          lastInGroup={index === msgsToRender.length - 1}
          isCollapsed={isCollapsed}
          key={msg.id}
          observerCallback={observerCallback}
        />
      ))}
      {lineCount > 1 ? (
        <span
          className={cssStyles.link}
          onClick={toggleCollapsedState}
          onKeyDown={noop}
          tabIndex={-1}
          role='button'
        >
          {isCollapsed ? 'Show All' : 'Hide All'}
        </span>
      ) : null}
    </div>
  );
});

export default InfoMessageGroup;
