import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tippy from '@tippyjs/react/headless';

import Image from '@/components/common/Image';
import noop from '@/utils/noop';
import { getLoaderWithFallbackUrl } from '@/components/common/utils';
import defaultContactImage from '@/assets/icon-contact.svg';
import cssStyles from './Contacts.css';

export default class UserContext extends Component {
  state = {
    on: false,
  };

  static propTypes = {
    userInfo: PropTypes.object.isRequired,
    changeConversation: PropTypes.func.isRequired,
  };

  sendMessage = () => {
    const { changeConversation, userInfo } = this.props;
    this.hide();
    changeConversation(userInfo);
  };

  toggle = (e) => {
    e.stopPropagation();
    this.setState(({ on }) => ({ on: !on }));
  };

  hide = () => {
    this.setState({ on: false });
  };

  stopPropagation = (e) => e.stopPropagation();

  render() {
    const {
      userInfo: { name, mobile, email, imageUrl },
    } = this.props;
    const { on } = this.state;
    return (
      <Tippy
        visible={on}
        onClickOutside={this.hide}
        arrow={false}
        interactive
        interactiveBorder={0}
        placement='bottom-end'
        appendTo={document.body}
        offset={[-10, 10]}
        render={(attrs) => (
          <div
            className={cssStyles.context_dropdown}
            role='menuitem'
            onKeyDown={noop}
            tabIndex='-1'
            onClick={this.stopPropagation}
            {...attrs}
          >
            <Image
              src={imageUrl}
              alt={`${name}'s thumbnail`}
              fallbackUrl={defaultContactImage}
              Loader={getLoaderWithFallbackUrl(defaultContactImage)}
            />
            <div className={cssStyles.channelName}>{name}</div>
            {mobile ? (
              <div className={cssStyles.memberCount}>{mobile}</div>
            ) : null}
            {email ? (
              <div className={cssStyles.memberCount}>{email}</div>
            ) : null}
            <div
              role='button'
              tabIndex={0}
              onKeyDown={noop}
              onClick={this.sendMessage}
              className={classNames(cssStyles.button)}
            >
              Send Message
            </div>
          </div>
        )}
      >
        <div
          onClick={this.toggle}
          role='button'
          onKeyDown={noop}
          tabIndex='-1'
          className={cssStyles.context_menu_target}
        />
      </Tippy>
    );
  }
}
