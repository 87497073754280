import { emojiStore } from '@/lib/storage';
import converter from './converter';

export function shortCodeToNative(text) {
  return converter.sanitize(text, {
    skinTone: emojiStore.get('skinTone'),
  });
}

export function nativeToShortCode(text) {
  return converter.sanitize(text, {
    shortcodes: true,
  });
}
