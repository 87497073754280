import React, { Component } from 'react';
import cx from 'classnames';
import EmojiDecorator from '@/components/common/EmojiDecorator';
import presenceStyles from '@/components/common/common.css';
import css from './Name.css';

class Name extends Component {
  static defaultProps = {
    peer: undefined,
  };

  renderGroupName() {
    const { peer } = this.props;
    const { name, groupType } = peer;
    return (
      <span className={css.Wrapper}>
        <span className={css.ProfileName}>
          <EmojiDecorator text={name} />
        </span>
        <span className={`${css.GroupTypeIcon} ${css[groupType]}`} />
      </span>
    );
  }

  renderBuddyName() {
    const { peer } = this.props;
    const { name, guest, presence, isBot, isDeactivated } = peer;

    return (
      <span className={cx(css.Wrapper, { [css.Deactivated]: isDeactivated })}>
        {!isBot ? (
          <div
            className={`${presenceStyles.presence_base} ${
              presenceStyles[`presence--${presence}`]
            } ${css.presence}`}
          />
        ) : null}
        <span className={css.ProfileName}>
          <EmojiDecorator text={name} />
        </span>
        {guest ? <span className={css.Guest}>(guest)</span> : null}
      </span>
    );
  }

  render() {
    const { peer } = this.props;
    if (!peer) {
      return null;
    }
    console.log('HEADER peer', peer);
    const { type } = peer;
    if (type === 'buddy') {
      return this.renderBuddyName();
    }
    return this.renderGroupName();
  }
}

export default Name;
