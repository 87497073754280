import React from "react";
import { Observer } from "mobx-react-lite";
import { useStore } from "../../store";

export function Item({ children, id, type }) {
  return (
    <Observer>
      {() => {
        const store = useStore();

        const width =
          type === "emoji" ? store.itemWidth : store.itemStickerWidth;
        const height =
          type === "emoji" ? store.itemHeight : store.itemStickerHeight;
        let background = "transparent";
        if (store.selectedItemId === id) {
          background = store.colors.accent;
        } else if (store.hoverItemId === id) {
          background = store.colors.secondaryBg;
        }

        return (
          <div
            className="item-root"
            data-picker-id={id}
            data-picker-type={type}
            data-picker-item
          >
            {children}

            <style jsx>{`
              .item-root {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                cursor: pointer;
                outline: none;
              }
            `}</style>
            <style jsx>{`
              .item-root {
                width: ${width}px;
                min-width: ${width}px;
                height: ${height}px;
                min-height: ${height}px;
                border: 2px solid ${store.colors.primaryBg};
                background-color: ${background};
                transition: background-color 0.1s cubic-bezier(0, 0, 0.3, 1);
              }
            `}</style>
          </div>
        );
      }}
    </Observer>
  );
}
