export default {
  text_inviteUserByEmail:
    'Idea: Introduce una direcci\u00f3n de correo electr\u00f3nico para invitar a usuarios.',
  error_ExternalContactsDisbaled:
    'Tu organizaci\u00f3n tiene desactivados los contactos externos. Solo se pueden a\u00f1adir usuarios @',
  default_value: 'Buscar contactos y canales',
  talk_to: 'Talk.to',
  startChatWithEmail: 'Introduce un email para empezar el chat',
  ext_contacts_disabled:
    'Est\u00e1n desactivados los contactos externos para tu organizaci\u00f3n. Solo puedes a\u00f1adir usuarios @',
  tip_email:
    'Consejo: introduce una cuenta de correo electr\u00f3nico para invitar a usuarios.',
  tab_add_people: 'Pulsa {button} para a\u00f1adir gente',
  select_to_share: 'Selecciona Contactos o Canales para compartir',
  open_groups_separator: 'Canales p\u00fablicos',
  dept_search_separator: 'Puesto departamento de trabajo',
  other_contacts_separator: 'Estos contactos no son de tu equipo',
  invited_but_not_joined: 'Invitado pero no se ha unido',
  start_new_chat: 'Iniciar un chat',
  join_button: 'Unirse',
  recommended_contacts_separator: 'No est\u00e1 en este canal',
  imported_contacts_separator: 'Invitar a tu equipo',
  invite_add_button: 'Invitar y a\u00f1adir',
  add_button: 'A\u00f1adir',
  invite_mode: 'Invitar',
  invited_mode: 'Invitaci\u00f3n enviada',
  frequent: 'frecuente',
  recent: 'reciente',
  tab: 'tab',
};
