import React, { Component } from 'react';
import classNames from 'classnames';
import withTeamInfo from '@/connectHOCs/Sessions/currentSession/teamInfo';
import withCurrentOwner from '@/connectHOCs/Sessions/currentSession/owner';
import { openInviteContactScreen } from '@/utils/ShellUtils';
import noop from '@/utils/noop';
import cssStyles from './Contacts.css';

class EmptyContactsState extends Component {
  onInviteClick = () => {
    openInviteContactScreen('empty_sidebar_invite');
  };

  render() {
    const { owner, teamInfo } = this.props;

    const { isDMBlocked } = teamInfo;
    const { isAdmin: isOwnerAdmin } = owner;

    return (
      <div className={cssStyles.empty_roster_container}>
        <div
          className={classNames(
            cssStyles.empty_roster_image,
            cssStyles.empty_contacts
          )}
        />
        <div>
          No contacts yet.
          {isDMBlocked && !isOwnerAdmin ? null : (
            <div
              role='button'
              onKeyDown={noop}
              tabIndex={0}
              className={cssStyles.invite_text}
              onClick={this.onInviteClick}
            >
              Invite contacts
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withCurrentOwner(withTeamInfo(EmptyContactsState));
