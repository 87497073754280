import { createSelector } from 'reselect';
import ownerGuidSelector from './ownerGuid';

const defaultPinsOrder = [];

function allPinnedChatsOrderSelector(state) {
  return state.restricted.message.pinsOrder;
}

/**
 * Selector function for pin chats order
 * @param {object} state - State of the redux store
 * @returns {Function} Gives the Function which returns the pinsOrder when provided with ownerGuid as parameter.
 */
export const pinsOrderByGuidSelector = createSelector(
  allPinnedChatsOrderSelector,
  (allPinsOrder) => (ownerGuid) => allPinsOrder[ownerGuid] || defaultPinsOrder
);

export default createSelector(
  ownerGuidSelector,
  /* The default selector returns pins for the current ownerGuid */
  pinsOrderByGuidSelector,
  (ownerGuid, getPinsOrderByGuid) => getPinsOrderByGuid(ownerGuid)
);
