import React from 'react';
import Image from '@/components/common/Image';
import defaultGroupImage from '@/assets/icon-group.svg';
import { getLoaderWithFallbackUrl } from '@/components/common/utils';
import GroupContext from './GroupContext';
import cssStyles from './Contacts.css';

function Group(props) {
  const { contact, changeConversation } = props;
  return (
    <div className={cssStyles.contact_container}>
      <div
        role='button'
        onKeyDown={() => {}}
        tabIndex={0}
        onClick={() => changeConversation(contact)}
        className={cssStyles.contact_info}
      >
        <div className={cssStyles.contact}>
          <Image
            alt={contact.name}
            className={cssStyles.roster_image}
            src={contact.imageUrl}
            fallbackUrl={defaultGroupImage}
            Loader={getLoaderWithFallbackUrl(defaultGroupImage)}
          />
          <span className={cssStyles.roster_label}>{contact.name}</span>
        </div>
        <div className={cssStyles.actions}>
          {contact.isMuted && <div className={cssStyles.mute_icon} />}
          <GroupContext channelInfo={contact} />
        </div>
      </div>
    </div>
  );
}
export default Group;
