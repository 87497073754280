export default {
  contact_support: 'Entrar em contato com o Suporte',
  keyboard_shortcuts: 'Atalhos de Teclado',
  audio_alerts: 'Alertas de \u00c1udio',
  popup_notifications: 'Notifica\u00e7\u00f5es pop-up',
  video_ringer: 'Video call ringer',
  quit_flock: 'Sair do Flock',
  report_bug: 'Reportar erro',
  set_status: 'Definir um status...',
  manage_apps: 'Gerenciar aplicativos',
  notification_preferences: 'Prefer\u00eancias de notifica\u00e7\u00e3o',
  add_team: 'Adicionar nova equipe',
  language_settings: 'Configura\u00e7\u00f5es de idioma',
  language_text:
    'O idioma selecionado \u00e9 usado para todas as comunica\u00e7\u00f5es',
  language_change_note:
    'Observa\u00e7\u00e3o: A altera\u00e7\u00e3o do idioma se refletir\u00e1 em todos os seus clientes da Web e desktop.',
  invite: '+ Convidar',
  invite_description: 'Convide membros para a sua equipe',
  browse_apps: 'Procurar aplicativos',
  manage_team: 'Gerencie sua equipe',
  stop_notifications: 'Parar de receber notifica\u00e7\u00f5es',
};
