import bridge from '@/utils/bridge';
import store from '@/store';
import { Actions } from '@/actions/restricted';
import SessionProxy from './SessionProxy';

function getCurrentSession() {
  return bridge.ask('TeamSessionRegistry', 'getCurrentSession');
}

function getCurrentState() {
  const state = store.getState();
  return state.restricted._session.currentSessionId;
}

class CurrentSessionProxy {
  sessionProxySubscription = SessionProxy.subscribe([]);

  constructor() {
    bridge.subscribe(
      '/hydra/session/currentSessionUpdated',
      this.setCurrentSession
    );

    getCurrentSession().then(this.setCurrentSession);
  }

  getCurrentSession = () => {
    return getCurrentSession();
  };

  setCurrentSession = (session) => {
    if (!session) {
      return;
    }
    const currentSessionId = getCurrentState();
    if (currentSessionId === session.id) {
      return;
    }
    this.sessionProxySubscription.update([session.id]);
    store.dispatch(
      Actions.change_session({
        sessionId: session.id,
        ownerGuid: session.owner.ownerGuid,
      })
    );
  };
}

export default new CurrentSessionProxy();
