import { createSelector } from 'reselect';
import { isGroup } from '@/utils';
import allCurrentMessagesSelector from './allCurrentOwnerMessages';
import unreadMessageSelector from './unreadMessages';
import ownerGuidSelector from './ownerGuid';
import allContactsSelector from './allContacts';

const groupWiseUnreadMessageSelector = createSelector(
  unreadMessageSelector,
  allCurrentMessagesSelector,
  (unreadMessages, allMessages) => {
    const unreadGroupJids = Object.keys(unreadMessages).filter(isGroup);
    return unreadGroupJids
      .map((jid) => {
        const visibleMessages = unreadMessages[jid]
          .map((messageId) => allMessages[messageId])
          .filter(
            ({ isInfoMessage, isVisibleMessage } = {}) =>
              !isInfoMessage && isVisibleMessage
          );
        const messageList = visibleMessages.slice(-3).reverse();

        return {
          unreadCount: visibleMessages.length,
          messageList,
          jid,
        };
      })
      .filter(({ unreadCount }) => unreadCount > 0);
  }
);

export const unreadChannelCountSelector = createSelector(
  groupWiseUnreadMessageSelector,
  (unreadMessagesGroupWise) => unreadMessagesGroupWise.length
);

const allChannelFeedSelector = createSelector(
  groupWiseUnreadMessageSelector,
  ownerGuidSelector,
  allContactsSelector,
  (unreadMessagesGroupWise, ownerGuid, contacts) => {
    const sortedGrouspWithMessages = unreadMessagesGroupWise.sort(
      ({ jid: jid1 }, { jid: jid2 }) => {
        if (contacts && contacts[jid1] && contacts[jid2]) {
          return (
            contacts[jid2].lastMessageTime - contacts[jid1].lastMessageTime
          );
        }
        return -1;
      }
    );
    return {
      ownerGuid,
      sortedGrouspWithMessages,
    };
  }
);

export default allChannelFeedSelector;
