import { combineReducers } from 'redux';
import messageReducer from './message';
import unreadMessageReducer from './unreadMessages';
import allConversationsReducer from './allConversations';
import pinsOrderReducer from './pinsOrder';

export default (ActionTypes) => {
  return combineReducers({
    pinsOrder: pinsOrderReducer(ActionTypes),
    all_messages: messageReducer(ActionTypes),
    unread_messages: unreadMessageReducer(ActionTypes),
    all_conversations: allConversationsReducer(ActionTypes),
  });
};
