import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { publishGenericEvent } from '@/utils/SessionUtils';
import currentSessionHOC from '@/connectHOCs/Sessions/currentSession';
import Button from '@/components/common/Button';
import { hideModal } from '@/components/Modal/Modal';
import { acceptGDPR, getFlockTermsAndPrivacyUrls } from '@/utils/ShellUtils';
import gdprStyles from './GDPRModalComponent.css';
import IntlWrapper from '../../../Wrappers/IntlWrapper';
import translations from './I18n';

class GDPRModalComponent extends Component {
  constructor() {
    super();

    this.state = {
      termsUrl: '',
      privacyUrl: '',
      showLoadingSpinner: true,
    };
  }

  onAcceptGDPR = () => {
    const { currentSession } = this.props;

    const {
      teamInfo: { guid },
      metaToken: token,
    } = currentSession.sessionInfo;

    acceptGDPR(token, guid).then((response) => {
      if (!response.isError) {
        hideModal();
      }
    });
  };

  componentDidMount = () => {
    const payloadForMedusa = {
      source: 'within_client',
      userType: 'team_member',
    };
    publishGenericEvent('gdpr_agreement_shown', payloadForMedusa);

    getFlockTermsAndPrivacyUrls().then((responses) => {
      this.setState({
        termsUrl: responses[0],
        privacyUrl: responses[1],
        showLoadingSpinner: false,
      });
    });
  };

  render() {
    const { termsUrl, privacyUrl, showLoadingSpinner } = this.state;

    return (
      <div className={gdprStyles.wrapper}>
        <div className={gdprStyles.title}>
          <FormattedMessage id='review_the_user_terms' />
        </div>
        {showLoadingSpinner && (
          <div className={gdprStyles.iframe_loading_spinner} />
        )}
        <iframe src={termsUrl} frameBorder='0' title='Flock Terms' />

        <div className={gdprStyles.footer}>
          <div className={gdprStyles.footer_note}>
            <FormattedMessage
              id='footer_agreement_note'
              values={{
                agree: (
                  <strong>
                    <FormattedMessage id='i_agree' />
                  </strong>
                ),
                terms: (
                  <a href={termsUrl} rel='noopener noreferrer' target='_blank'>
                    <FormattedMessage id='terms_of_use' />
                  </a>
                ),
                privacyPolicy: (
                  <a
                    href={privacyUrl}
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    <FormattedMessage id='privacy_policy' />
                  </a>
                ),
              }}
            />
          </div>
          <div className={gdprStyles.accept_gdpr}>
            <Button onClick={this.onAcceptGDPR}>
              {' '}
              <FormattedMessage id='i_agree' />{' '}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default IntlWrapper(currentSessionHOC(GDPRModalComponent), translations);
