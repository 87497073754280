import { createSelector } from 'reselect';
import currentSessionSelector, { sessionByGuidSelector } from './session';

export const callProviderByGuidFactory = (guid) => {
  return createSelector(sessionByGuidSelector, (sessionByGuid) => {
    const session = sessionByGuid(guid);
    return session?.sessionInfo?.teamInfo?.call?.provider;
  });
};

export default createSelector(
  currentSessionSelector,
  (session) => session?.sessionInfo?.teamInfo?.call?.provider
);
