import { createSelector } from 'reselect';
import ownerGuidSelector from './ownerGuid';
import allMessagesSelector from './allMessages';

const defaultMessages = {};

export const allMessagesByGuidSelector = createSelector(
  allMessagesSelector,
  (allMessages) => (ownerGuid) => allMessages[ownerGuid] || defaultMessages
);

export default createSelector(
  ownerGuidSelector,
  allMessagesByGuidSelector,
  (ownerGuid, allMessagesByGuid) => allMessagesByGuid(ownerGuid)
);
