import React from 'react';
import { hyperLinksInText } from '@/utils/HyperLinkUrlEnvelope';
import EmojiDecorator from '@/components/common/EmojiDecorator';
import { GroupMention, UserMention, LinkElement } from './FlockMLUtils';

/**
* The decorator works by replacing certain pattern of text with relavant components. 
* and returns a array of texts and components. 
*
* We convert use linkedList of texts and components to make modifying easier than array.

* TextNode is run through a textNodeRunner, which runs replacement fns passed to it. Each replacement
* fn is run through all the nodes available, and fn decides whether to split text node into multiple nodes or not. 
* 

* Replacement functions:
* a function, which accepts text node as a params, and returns details on how to split that text node. 
*/

const textNodeRunner = function (rootNode, replacementFns) {
  replacementFns = replacementFns.filter((fn) => !!fn);
  replacementFns.forEach((replaceNode) => {
    let node = rootNode;

    while (node) {
      const { value, next } = node;
      const { start, length, replacedNodeValue, shouldReplace } = replaceNode(
        value
      );

      if (!shouldReplace) {
        node = next;
        // eslint-disable-next-line no-continue
        continue;
      }

      const startValue = value.slice(0, start);
      const endValue = value.slice(start + length, value.length);

      const endNode = {
        value: endValue,
        next: node.next,
      };

      const replacedNode = {
        value: replacedNodeValue,
        next: endNode,
      };

      node.value = startValue;
      node.next = replacedNode;

      node = endNode;
    }
  });

  return rootNode;
};

const getMentionReplacementFn = function (mention, ownerGuid) {
  return function (nodeValue) {
    const returnValue = {
      shouldReplace: false,
    };

    if (typeof nodeValue !== 'string') {
      return returnValue;
    }

    const idx = nodeValue.indexOf(mention.name);
    if (idx < 0) {
      return returnValue;
    }

    const replacedNodeValue =
      mention.type === 'all' || mention.type === 'online' ? (
        <GroupMention
          isMeMention={mention.mentionsMe}
          textContent={mention.displayName}
        />
      ) : (
        <UserMention
          textContent={mention.displayName}
          jid={mention.jid}
          ownerGuid={ownerGuid}
        />
      );

    return {
      shouldReplace: true,
      start: idx,
      length: mention.name.length,
      replacedNodeValue,
    };
  };
};

const getHyperlinkReplacementFn = function (hyperlink) {
  return function (nodeValue) {
    const returnValue = {
      shouldReplace: false,
    };
    if (typeof nodeValue !== 'string') {
      return returnValue;
    }

    const idx = nodeValue.indexOf(hyperlink);
    if (idx < 0) {
      return returnValue;
    }

    const replacedNodeValue = (
      <LinkElement href={hyperlink}>{hyperlink}</LinkElement>
    );

    return {
      shouldReplace: true,
      start: idx,
      length: hyperlink.length,
      replacedNodeValue,
    };
  };
};

const getItalicsReplacementFn = function () {
  // eslint-disable-next-line no-useless-escape
  const italicsRegex = /(^|\s)\_([^ \n\r].+?[^ \n\r]|[^ \n\r]+?)\_((?=[\s\n])|$)/;

  return function (nodeValue) {
    const returnValue = {
      shouldReplace: false,
    };
    if (typeof nodeValue !== 'string') {
      return returnValue;
    }

    const result = nodeValue.match(italicsRegex);
    if (!result) {
      return returnValue;
    }

    // italicsValue is the value between _s
    const [, , italicsValue] = result;
    const index = nodeValue.indexOf(`_${italicsValue}_`);

    const replacedNodeValue = <em>{italicsValue}</em>;

    return {
      shouldReplace: true,
      start: index,
      length: italicsValue.length + 2,
      replacedNodeValue,
    };
  };
};

const getBoldReplacementFn = function () {
  const boldRegex = /(^|\s)\*([^ \n\r].+?[^ \n\r]|[^ \n\r]+?)\*((?=[\s\n])|$)/;

  return function (nodeValue) {
    const returnValue = {
      shouldReplace: false,
    };
    if (typeof nodeValue !== 'string') {
      return returnValue;
    }

    const result = nodeValue.match(boldRegex);
    if (!result) {
      return returnValue;
    }

    // boldValue is the value between *s
    const [, , boldValue] = result;
    const index = nodeValue.indexOf(`*${boldValue}*`);

    const replacedNodeValue = <strong>{boldValue}</strong>;

    return {
      shouldReplace: true,
      start: index,
      length: boldValue.length + 2,
      replacedNodeValue,
    };
  };
};

function decorateWithEmoji(decoratedTextWithOutEmoji) {
  const enableJumboEmoji = decoratedTextWithOutEmoji.length <= 1;

  const decoratedText = decoratedTextWithOutEmoji.map((element) => {
    if (typeof element === 'string') {
      return (
        <EmojiDecorator text={element} enableJumboEmoji={enableJumboEmoji} />
      );
    }
    return element;
  });
  return decoratedText;
}

export default function (text, mentions, ownerGuid, ignoreReplacements = []) {
  const replacementFns = !mentions
    ? []
    : mentions.map((mention) => getMentionReplacementFn(mention, ownerGuid));

  const hyperLinks =
    (!ignoreReplacements.includes('hyperlink') && hyperLinksInText(text)) || [];
  hyperLinks.sort((link1, link2) => link2.length - link1.length); // sort links descending based on length to correctly render
  const hyperlinkRelacementFns = hyperLinks.map(getHyperlinkReplacementFn);
  const boldReplacementFn = !ignoreReplacements.includes('bold')
    ? getBoldReplacementFn()
    : undefined;
  const italicsReplacementFn = !ignoreReplacements.includes('italics')
    ? getItalicsReplacementFn()
    : undefined;

  const modifiedNode = textNodeRunner({ value: text, next: null }, [
    ...replacementFns,
    ...hyperlinkRelacementFns,
    boldReplacementFn,
    italicsReplacementFn,
  ]);

  const textArray = [];
  let node = modifiedNode;
  while (node) {
    textArray.push(node.value);
    node = node.next;
  }
  return !ignoreReplacements.includes('emoji')
    ? decorateWithEmoji(textArray)
    : textArray;
}
