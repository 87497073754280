import React from 'react';
import { ErrorMessage } from 'formik';
import cn from 'classnames';
import CustomErrorMessage from '../ErrorMessage';
import styles from './TextInput.css';

/**
 * The Formik TextInput component.
 * @param {Object} props
 */
export default function TextInput(props) {
  const {
    field: { name, ...fieldProps },
    form,
    fullWidth = true,
    label,
    onInputChange,
    ...otherProps
  } = props;
  return (
    <>
      {label ? <label htmlFor={name}>{label}</label> : null}
      <input
        name={name}
        className={cn(styles.textInput, {
          [styles.fullWidth]: fullWidth,
        })}
        {...fieldProps}
        {...otherProps}
        type='text'
      />
      <ErrorMessage component={CustomErrorMessage} name={name} />
    </>
  );
}
