import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { KEYS } from '@/utils/constants';
import cssStyles from './FrequentRecentSwitcher.css';

class FrequentRecentSwitcher extends PureComponent {
  static propTypes = {
    selected: PropTypes.string.isRequired,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    onChange: () => {},
  };

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  onChange(type) {
    const { onChange } = this.props;
    onChange(type);
  }

  onKeyDown = (event) => {
    const { keyCode } = event;

    switch (keyCode) {
      case KEYS.LEFT_ARROW:
        this.onChange('frequent');
        break;
      case KEYS.RIGHT_ARROW:
        this.onChange('recent');
        break;
      default:
    }
  };

  render() {
    const { selected } = this.props;

    return (
      <div className={cssStyles.SwitcherWrapper}>
        <div
          className={`${cssStyles.Switcher} ${cssStyles.left} ${
            selected === 'frequent' ? cssStyles.active : ''
          }`}
          onClick={() => this.onChange('frequent')}
          onKeyDown={() => {}}
          role='switch'
          tabIndex='-1'
          aria-checked={selected === 'frequent'}
        >
          <div className='conv-search__add-contact2' />
          <span>
            <FormattedMessage id='frequent' />
          </span>
        </div>
        <div
          className={`${cssStyles.Switcher} ${cssStyles.right} ${
            selected === 'recent' ? cssStyles.active : ''
          }`}
          onClick={() => this.onChange('recent')}
          onKeyDown={() => {}}
          role='switch'
          tabIndex='-1'
          aria-checked={selected === 'recent'}
        >
          <div className='conv-search__globe' />
          <span>
            <FormattedMessage id='recent' />
          </span>
        </div>
      </div>
    );
  }
}

export default FrequentRecentSwitcher;
