import { useState, useEffect } from 'react';

import { getContact } from '@/utils/ContactUtils';

export type Field = {
  name: string;
  valueType: string;
  userValues: string[];
  value: string;
  values: string[];
  defaultValues: any[];
  fieldId: string;
  teamValues: {
    parents: Array<{ fieldId: string; value: string }>;
    values: Array<{ value: string; children: string[] }>;
  };
};

function getVisibleFields(fields: Field[]): Field[] {
  const hash: Record<string, Field> = {};
  const topLevelParents: Set<string> = new Set();
  let rankCounter = 1;

  fields.forEach(function (field) {
    hash[field.fieldId] = field;
    if (field.teamValues.parents.length === 0) {
      topLevelParents.add(field.fieldId);
    }
  });

  const visibleFields = new Set(topLevelParents);
  const fieldRanks = {};

  function traverse(fieldIds: Set<string> | Array<string>) {
    fieldIds.forEach((fieldId) => {
      const field = hash[fieldId];
      rankCounter += 1;
      fieldRanks[field.fieldId] = rankCounter;

      const selectedValueObj =
        field.userValues.length > 0 &&
        field.teamValues.values.filter((valueObj) => {
          return valueObj.value === field.userValues[0];
        })[0];

      if (selectedValueObj && selectedValueObj.children.length > 0) {
        selectedValueObj.children.forEach((val) => visibleFields.add(val));
        traverse(selectedValueObj.children);
      }
    });
  }

  traverse(topLevelParents);

  return Array.from(visibleFields)
    .map(function (fieldId) {
      return hash[fieldId];
    })
    .sort(function (f1, f2) {
      return fieldRanks[f1.fieldId] - fieldRanks[f2.fieldId];
    });
}

export default function useCustomFieldsToShow(fields: Field[]) {
  const [fieldsToShow, setFieldsToShow] = useState<Field[]>([]);

  useEffect(() => {
    let cancelled = false;

    async function populateContactFields() {
      const promises = getVisibleFields(fields).map(async (field) => {
        if (field.valueType === 'contactPicker' && field.values.length > 0) {
          const guid = field.values[0];

          // Allow arbitrary string values in place of GUIDs
          // If GUID is one of the strings in field.defaultValues, show as is
          if (field?.defaultValues?.length > 0) {
            if (field.defaultValues.indexOf(guid) !== -1) {
              return field;
            }
          }

          return getContact(`${field.values[0]}@go.to`)
            .then(([contact]) => {
              const name = contact?.name ?? '';

              return {
                ...field,
                values: [name],
              };
            })
            .catch(() => {
              return field;
            });
        }

        return field;
      });

      Promise.all(promises)
        .then((values) => {
          if (!cancelled) {
            setFieldsToShow(values);
          }
        })
        .catch((error) => {
          console.error('Error generating vieweable custom fields: ', error);
        });
    }

    populateContactFields();

    return () => {
      cancelled = true;
    };
  }, [fields]);

  return fieldsToShow;
}
