import React from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../store";

export const DetailPlaceholder = observer(function DetailPlaceholder() {
  const store = useStore();
  const onClick = React.useCallback(e => {
    if (e.target.hasAttribute("data-detail-skin-item")) {
      const skin = e.target.getAttribute("data-detail-skin-tone");
      if (skin) {
        store.setLocalSkin(skin);
      }
    }
  });

  return (
    <div
      className="detail-placeholder-root"
      onClick={onClick}
    >
      {Object.keys(store.skinToneColors).map(i => (
        <div
          key={i}
          className="skins"
          data-detail-skin-tone={i}
          data-detail-skin-item
          style={{
            borderColor: store.skin === parseInt(i) ? store.colors.accent : ""
          }}
        >
          <div
            className="dot"
            style={{ backgroundColor: store.skinToneColors[i] }}
          />
        </div>
      ))}

      <style jsx>{`
        .detail-placeholder-root {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
        }
        .skins {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          border: 2px solid transparent;
          cursor: pointer;
        }
        .dot {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          pointer-events: none;
        }
      `}</style>
    </div>
  );
});
