export default {
  join_group_description: 'Encontrar y unirse a canales p\u00fablicos',
  discover_group_disc:
    'A continuaci\u00f3n se muestra una lista de los canales p\u00fablicos permiten de su equipo.',
  start_new_channel_link_text: 'Comience un nuevo canal',
  no_results_found: 'No se encontraron resultados',
  no_open_groups: 'Parece que no hay Canales p\u00fablicos para unirse',
  no_open_groups_description:
    'Los Canales p\u00fablicos permiten que su equipo descubra y se una a conversaciones con facilidad. Por ejemplo, {example}',
  no_open_groups_description_example:
    'Equipo de mercadotecnia, ideas e inspiraci\u00f3n',
  create_a_channel: 'Comience un nuevo canal',
  public_channel_guest_restriction_heading:
    'Encontrar y unirse a canales p\u00fablicos',
  public_channel_guest_restriction_message:
    '\u00a1Ups! Un invitado en Flock no puede ver o crear canales p\u00fablicos en este equipo.',
  okay: 'Okay',
};
