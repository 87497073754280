import React from 'react';
import memoizeOne from 'memoize-one';
import currentPeerHOC from '@/subscribeHOCs/currentPeer';
import currentSessionHOC from '@/connectHOCs/Sessions/currentSession';
import withTeamPrivileges from '@/connectHOCs/withTeamPrivileges';
import appsListSubscriber from '@/subscribeHOCs/appList';
import { doAppAction } from '@/utils/ShellUtils';
import msgToPlatformMessage from '@/utils/platformApp/platformApp';
import { maybe } from '@/utils';
import bridge from '@/utils/bridge';
import MoreOptions from '../MoreOptions';
import Item from '../Item';
import cssStyles from './Container.css';
import LocalMenuItems from '../LocalMenuItems';

const rootCategory = 'messageAction';
// const orgStandardRootCategory = 'chatTabButton';
// const gaSource = 'chat_tab_button';
const DISPLAY_LIMIT = 3;

const InlineMenuItems = React.memo(({ menuItems, onAppClick }) => {
  return menuItems.slice(0, DISPLAY_LIMIT).map((item) => {
    return <Item key={item.id} menuItem={item} onAppClick={onAppClick} />;
  });
});

class Container extends React.PureComponent {
  static defaultProps = {
    peer: undefined,
    currentSession: undefined,
    appList: [],
  };

  constructor(props) {
    super(props);
    this.containerRef = React.createRef(null);
  }

  getVisibleMenuItems = memoizeOne(
    (appList, message, currentSession, currentPeer, teamPrivileges) => {
      const menuItems = this.getVisibleMenuItemsList(
        appList,
        message,
        currentSession,
        currentPeer,
        teamPrivileges
      );
      return {
        inlineMenuItems: menuItems.slice(0, DISPLAY_LIMIT),
        moreMenuItems: menuItems.slice(DISPLAY_LIMIT),
      };
    }
  );

  getVisibleMenuItemsList = (
    appList,
    message,
    currentSession,
    currentPeer,
    teamPrivileges
  ) => {
    const dikeAppList = this.getDikeAppList(appList);
    return [...LocalMenuItems, ...dikeAppList]
      .filter((item) =>
        item.validator(message, currentSession, currentPeer, teamPrivileges)
      )
      .sort(
        (item1, item2) =>
          this.getMessageAction(item1) - this.getMessageAction(item2)
      )
      .reverse();
  };

  getMessageAction = (item) => {
    if (!maybe(item, 'properties', 'priorities', 'messageAction')) {
      return 0;
    }
    const {
      properties: { priorities: { messageAction } = {} },
    } = item;
    return messageAction;
  };

  getDikeAppList = (appList) => {
    return appList.map((app) => {
      return {
        id: app.id,
        labelName: app.name,
        labelDesc: app[rootCategory].description,
        iconClass: 'icon',
        icon: app.inClientIcon,
        action: (msg) => {
          this.onDikeAppClick(app, msg);
        },
        validator: (msg) => {
          return msg.sid;
        },
        properties: app.properties,
      };
    });
  };

  onDikeAppClick = (app, msg) => {
    // const { currentSession, peer } = this.props;
    const sessionId = `session_${msg.ownerGuid}@go.to`;
    const flockEventParam = {
      name: `client.${rootCategory}`,
      messageUids: [msg.sid],
      messages: [msgToPlatformMessage(msg)],
    };
    const actionObj = app[rootCategory].action;
    if (actionObj.type === 'sendToAppService') {
      delete flockEventParam.messages;
    }
    if (app) {
      bridge.publish(`/newMenuItem/opened/${msg.peer.jid}`);
      doAppAction(
        actionObj,
        app,
        msg.peer,
        sessionId,
        flockEventParam,
        rootCategory
      );
    } else {
      console.error('No app found for action ');
    }
  };

  onAppClick = (menuItem) => {
    const { message, currentSession, closeBothPopover } = this.props;
    menuItem.action(message, currentSession);
    closeBothPopover();
  };

  render() {
    const {
      appList,
      setSubMenuOpen,
      closeBothPopover,
      message,
      currentSession,
      currentPeer,
      teamPrivileges,
    } = this.props;
    const { inlineMenuItems, moreMenuItems } = this.getVisibleMenuItems(
      appList,
      message,
      currentSession,
      currentPeer,
      teamPrivileges
    );
    if (!inlineMenuItems.length) {
      return null;
    }
    return (
      <div
        className={cssStyles.container}
        id='messageMenuContainer'
        ref={this.containerRef}
      >
        <InlineMenuItems
          menuItems={inlineMenuItems}
          onAppClick={this.onAppClick}
        />
        {moreMenuItems.length ? (
          <MoreOptions
            MenuItems={moreMenuItems}
            setSubMenuOpen={setSubMenuOpen}
            onAppClick={this.onAppClick}
            closeBothPopover={closeBothPopover}
            containerRef={this.containerRef}
          />
        ) : null}
      </div>
    );
  }
}

export default currentPeerHOC(
  currentSessionHOC(
    withTeamPrivileges(appsListSubscriber(Container, rootCategory))
  )
);
