import store from '@/store';
import { getContact as getContactFromStore } from '@/selectors/allContacts';

/**
 * Gets you a contact/conversation info from Redux Store.
 * Gets a dummy object if contact is not available in redux.
 * @param {string} jid The contact's JID, has to be of the form {userGuid}@go.to
 * @param {string} ownerGuid The ownerGuid of the user's session in concern
 */
export default function getConversation(jid, ownerGuid) {
  const storeState = store.getState();
  return getContactFromStore(storeState, { jid, ownerGuid });
}
