import {
  showRestrictedActionForLite,
  showFileSizeLimitExceededError,
  showFileCountLimitExceededError,
  showFileTransferFailError,
  showFileSharingDisabledError,
  showNetworkError,
  showPartialFileUploadError,
} from '../GenericModalUtils';

/**
 * Functions to check for file upload restrictions
 *
 * 1. Invalid type restriction
 * 2. File Sharing restriction, permissions
 *    1. not licensed
 *    2. session inactive
 *    3. file sharing disabled
 * 3. File count exceeded
 * 4. File size exceeded
 *
 *
 * NOTES:
 * 1. Error handling in hydra is wierd. Not sure if it's intended.
 *   For file drop and file select, error is handled differently in few cases
 *    1. session_inactive error.
 *        File drop: we show generic error modal
 *        File Select: we show network error
 *    We will tentavily show network error, because this module will not differentiate between
 *    file drop and file select.
 *
 */

const MAX_FILES_COUNT = 10;
const MAX_UPLOAD_SIZE = 100;

export const ERROR_TYPES = {
  INVALID_TYPE: 'INVALID_TYPE',
  FILE_COUNT_EXCEEDED: 'FILE_COUNT_EXCEEDED',
  FILE_SIZE_EXCEEDED: 'FILE_SIZE_EXCEEDED',
  SESSION_NOT_LICENSED: 'SESSION_NOT_LICENSED',
  SESSION_INACTIVE: 'SESSION_INACTIVE',
  SESSION_FILE_SHARING_DISABLED: 'SESSION_FILE_SHARING_DISABLED',
  NETWORK_ERROR: 'NETWORK_ERROR',
  PARTIAL_UPLOAD_ERROR: 'PARTIAL_UPLOAD_ERROR',
};

function checkInvalidType(files: any): boolean {
  if (files.length === 0) {
    return false;
  }
  for (let i = 0; i < files.length; i += 1) {
    const file = files[i];
    if (file.webkitGetAsEntry) {
      const entry = file.webkitGetAsEntry();
      if (entry.isDirectory) {
        return true;
      }
    }
  }

  return false;
}

function checkFileCountExceeded(files: any): boolean {
  if (files.length > MAX_FILES_COUNT) {
    return true;
  }
  return false;
}

function checkFileSizeExceeded(
  files: any,
  sizeLimit: number = MAX_UPLOAD_SIZE
): boolean {
  for (let i = 0; i < files.length; i += 1) {
    const file = files[i];
    if (file.size > 1024 * 1024 * sizeLimit) {
      return true;
    }
  }
  return false;
}

function checkIfLicensed(session: any): boolean | undefined {
  if (!session) {
    return undefined;
  }
  return session.owner.isLicensed;
}

function checkIfSessionActive(session: any): boolean | undefined {
  if (!session) {
    return undefined;
  }
  return session.state === 'ACTIVE';
}

function checkIfFileSharingIsEnabled(session: any): boolean | undefined {
  if (!session) {
    return undefined;
  }
  return session.sessionInfo.teamInfo.isFileSharingEnabled;
}

function getFileSizeLimit(owner: any): number {
  if (owner.fileUploadLimit) {
    return owner.fileUploadLimit;
  }
  return MAX_UPLOAD_SIZE;
}

function checkForRestrictions(
  files: any,
  owner: any,
  session: any
): string | false {
  if (!checkIfSessionActive(session)) {
    return ERROR_TYPES.SESSION_INACTIVE;
  }
  if (!checkIfLicensed(session)) {
    return ERROR_TYPES.SESSION_NOT_LICENSED;
  }
  if (!checkIfFileSharingIsEnabled(session)) {
    return ERROR_TYPES.SESSION_FILE_SHARING_DISABLED;
  }
  if (checkFileCountExceeded(files)) {
    return ERROR_TYPES.FILE_COUNT_EXCEEDED;
  }
  if (checkInvalidType(files)) {
    return ERROR_TYPES.INVALID_TYPE;
  }
  const sizeLimit = getFileSizeLimit(owner);
  if (checkFileSizeExceeded(files, sizeLimit)) {
    return ERROR_TYPES.FILE_SIZE_EXCEEDED;
  }

  return false;
}

function handleFileUploadError(errorObj: { errorType: string; props: any }) {
  const { errorType, props } = errorObj;
  switch (errorType) {
    case ERROR_TYPES.FILE_SIZE_EXCEEDED: {
      const { owner } = props;
      if (!owner) {
        throw new Error(
          `Error in handling error :O. File exceeded error should have owner object to be handled`
        );
      }
      const sizeLimit = getFileSizeLimit(owner);
      showFileSizeLimitExceededError(sizeLimit);
      break;
    }
    case ERROR_TYPES.FILE_COUNT_EXCEEDED:
      showFileCountLimitExceededError(MAX_FILES_COUNT);
      break;
    case ERROR_TYPES.INVALID_TYPE:
      showFileTransferFailError();
      break;
    case ERROR_TYPES.SESSION_FILE_SHARING_DISABLED:
      showFileSharingDisabledError();
      break;
    case ERROR_TYPES.SESSION_NOT_LICENSED: {
      const { sessionId } = props;
      if (!sessionId) {
        throw new Error(
          `Error in handling error :O. Unlicensed error should have sessionId to be handled`
        );
      }
      showRestrictedActionForLite(sessionId, 'SHARE_FILE');
      break;
    }
    case ERROR_TYPES.SESSION_INACTIVE:
    case ERROR_TYPES.NETWORK_ERROR:
      showNetworkError();
      break;
    case ERROR_TYPES.PARTIAL_UPLOAD_ERROR: {
      const { failedCount, totalCount } = props;
      if (Number.isNaN(failedCount) || Number.isNaN(totalCount)) {
        throw new Error(
          `Error in handling error :O. Failed and total count should passed along with the error`
        );
      }
      showPartialFileUploadError(failedCount, totalCount);
      break;
    }
    default:
  }
}

function checkAndHandleFileRestrictions(
  files: any,
  owner: any,
  session: any
): boolean {
  const error = checkForRestrictions(files, owner, session);
  if (error) {
    let props = {};
    switch (error) {
      case ERROR_TYPES.FILE_SIZE_EXCEEDED:
        props = { owner };
        break;
      case ERROR_TYPES.SESSION_NOT_LICENSED:
        props = { sessionId: session.id };
        break;
      default:
    }
    handleFileUploadError({ errorType: error, props });
    return true;
  }
  return false;
}

export {
  checkForRestrictions,
  handleFileUploadError,
  checkAndHandleFileRestrictions,
};
