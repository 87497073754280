const defaultState = {};
export default ({ APP_LIST_BADGES, APP_LIST_PROXY, APPS_ORDER }) => {
  return (state = defaultState, { type, payload }) => {
    try {
      switch (type) {
        case APP_LIST_BADGES: {
          const { ownerGuid, badges } = payload;

          return {
            ...state,
            [ownerGuid]: {
              ...state[ownerGuid],
              badges,
            },
          };
        }
        case APP_LIST_PROXY: {
          const { ownerGuid, appList } = payload;

          if (!ownerGuid) {
            return state;
          }
          return {
            ...state,
            [ownerGuid]: {
              ...state[ownerGuid],
              appList,
            },
          };
        }
        case APPS_ORDER: {
          const { ownerGuid, appsOrder } = payload;

          return {
            ...state,
            [ownerGuid]: {
              ...state[ownerGuid],
              appsOrder,
            },
          };
        }
        default:
          return state;
      }
    } catch (error) {
      console.error(
        `Error in contact reducer: ${error.message || error.code || error}`,
        error
      );
      return state;
    }
  };
};
