import * as React from 'react';
import {
  Avatar,
  GetControlLabel,
  Input,
  InputTypes,
  Select,
} from '@talk-to/flock-components';
import useTheme from '@/hooks/useTheme';
import { cxBind } from '@/utils/cx';
import type { ProfileForm } from './ProfileCard';
import useCountryCodes from './useCountryCodes';

import styles from './ProfileCard.css';
import getImageFromFilePicker, {
  AvatarFileInputCancelledError,
} from './getImageFromFilePicker';

const cx = cxBind(styles);

const defaultCountryCode = '+91';

type Props = {
  imageUrl: string;
  profile: any;
  name: string;
  firstName: string;
  lastName: string;
  onChange: (data: ProfileForm) => void;
  onValidation: (valid: boolean) => void;
};

export default function EditView(props: Props) {
  const [form, setForm] = React.useState<ProfileForm>({
    meta: {
      initialized: false,
    },
    firstName: '',
    lastName: '',
    tel: '',
    countryCode: '',
    imageFile: null,
  });
  const [validationResult, setValidationResult] = React.useState({
    firstName: true,
    lastName: true,
    tel: true,
  });
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState<string | null>(
    null
  );
  const countryCodesMap = useCountryCodes();
  const currentTheme = useTheme();

  const countryCodeOptions = React.useMemo(
    () =>
      Object.entries(countryCodesMap).map(([code, label]) => ({
        label,
        value: code,
      })),
    [countryCodesMap]
  );

  const currentCountryCodeOption = React.useMemo(
    () => countryCodeOptions.find((item) => item.value === form.countryCode),
    [form.countryCode, countryCodeOptions]
  );

  const {
    name,
    imageUrl,
    profile,
    firstName,
    lastName,
    onChange,
    onValidation,
  } = props;

  React.useEffect(() => {
    onValidation(Object.values(validationResult).every((value) => value));
  }, [onValidation, validationResult]);

  React.useEffect(() => {
    if (!form.meta.initialized) {
      setForm({
        meta: {
          initialized: true,
        },
        firstName,
        lastName,
        countryCode: profile.mobile?.countryCode ?? defaultCountryCode,
        tel: profile.mobile?.mobile ?? '',
        imageFile: null,
      });
    }
  }, [profile, firstName, lastName, form]);

  React.useEffect(() => {
    onChange(form);
  }, [form, onChange]);

  const handleAvatarClick = React.useCallback(() => {
    async function run() {
      try {
        const { previewUrl, imageFile } = await getImageFromFilePicker();

        setImagePreviewUrl(previewUrl);
        setForm((prev) => ({
          ...prev,
          imageFile,
        }));
      } catch (error) {
        if (!(error instanceof AvatarFileInputCancelledError)) {
          console.error(error);
        }
      }
    }

    run();
  }, []);

  const getControlLabel: GetControlLabel = React.useCallback((option) => {
    return option[0].value;
  }, []);

  const handleValidation: InputTypes['OnValidation'] = React.useCallback(
    (nameAttr, result) => {
      setValidationResult((prev) => ({
        ...prev,
        [nameAttr]: result.valid,
      }));
    },
    []
  );

  if (!profile) {
    return null;
  }

  return (
    <div className={cx('profile-wrapper')}>
      <Avatar
        className={cx('avatar')}
        showEdit
        fullName={name}
        size='large'
        src={imagePreviewUrl ?? imageUrl}
        onClick={handleAvatarClick}
      />
      <div className={cx('profile-details')}>
        <div className={cx('name-field-group')}>
          <Input
            name='firstName'
            onChange={(event) => {
              const { value } = event.currentTarget;

              setForm((prev) => ({
                ...prev,
                firstName: value,
              }));
            }}
            className={cx('input', 'first-name-field')}
            placeholder='First Name'
            validators={{ isRequired: 'Enter a valid First Name' }}
            onValidation={handleValidation}
            validateOnChange
            value={form.firstName}
          />
          <Input
            name='lastName'
            className={cx('input')}
            onChange={(event) => {
              const { value } = event.currentTarget;

              setForm((prev) => ({
                ...prev,
                lastName: value,
              }));
            }}
            placeholder='Last Name'
            validators={{ isRequired: 'Enter a valid Last Name' }}
            onValidation={handleValidation}
            validateOnChange
            value={form.lastName}
          />
        </div>
        <div className={cx('tel-group')}>
          <Select
            className={cx('country-code-field')}
            options={countryCodeOptions}
            onChange={(option: any) => {
              setForm((prev) => ({
                ...prev,
                countryCode: option.value,
              }));
            }}
            value={currentCountryCodeOption}
            menuPortalTarget={document.body}
            menuPlacement='auto'
            minMenuWidth='250px'
            getControlLabel={getControlLabel}
            onValidation={handleValidation}
            appTheme={currentTheme}
            // FIXME: um... maybe start cleaning up z-indexes?
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 90000 }) }}
          />
          <Input
            name='tel'
            className={cx('input', 'tel-field')}
            onChange={(event) => {
              const { value } = event.currentTarget;

              setForm((prev) => ({
                ...prev,
                tel: value,
              }));
            }}
            placeholder='Type a number'
            value={form.tel}
            validators={{ isPhone: 'Please enter a valid phone number' }}
            onValidation={handleValidation}
            validateOnChange
          />
        </div>
      </div>
    </div>
  );
}
