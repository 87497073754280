/* global _ */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import {
  openInviteContactScreen,
  openCreateChannelScreen,
  getOpenGroupCount,
  openJoinPublicChannel,
} from '@/utils/ShellUtils';
import Group from '@/components/roster/Group';
import Buddy from '@/components/roster/Buddy';
import cssStyles from '@/components/roster/Contacts.css';
import EmptyContactsState from '@/components/roster/EmptyContactsState';
import RecentContactsProxy from '@/Proxies/RecentContactsProxy';
import RecentContacts from '@/connectHOCs/Contacts/RecentContacts';
import ContactList from '@/connectHOCs/Contacts/ContactList';
import withTeamInfo from '@/connectHOCs/Sessions/currentSession/teamInfo';
import withCurrentOwner from '@/connectHOCs/Sessions/currentSession/owner';
import noop from '@/utils/noop';
import ListContainer from './ListContainer';

const LIMIT = 20;
const VERY_LARGE_NUMBER = 99999999999999;
const SORT_KEY_SEPARATOR = '\u0000';

class RecentContactsContainer extends React.Component {
  recentContactsProxySubscription = undefined;

  state = {
    channelCount: null,
  };

  constructor(props) {
    super(props);

    if (props.guid) {
      this.recentContactsProxySubscription = RecentContactsProxy.subscribe(
        props.guid
      );
    }
  }

  async componentDidMount() {
    const channelCount = await getOpenGroupCount();
    this.setState({
      channelCount,
    });
  }

  componentDidUpdate(prevProps) {
    const { guid } = this.props;
    const { guid: prevGuid } = prevProps;
    if (guid !== prevGuid) {
      if (this.recentContactsProxySubscription) {
        this.recentContactsProxySubscription.unsubscribe();
      }
      this.recentContactsProxySubscription = RecentContactsProxy.subscribe(
        guid
      );
    }
  }

  componentWillUnmount() {
    if (this.recentContactsProxySubscription) {
      this.recentContactsProxySubscription.unsubscribe();
    }
  }

  onInviteClick = () => {
    openInviteContactScreen('sidebar_dm_invite');
  };

  onCreateChannelClick = () => {
    const gaSourceName = 'sidebar_recents_new';
    const { currentSession } = this.props;
    openCreateChannelScreen(currentSession, gaSourceName);
  };

  onJoinChannelClick = () => {
    const gaSourceName = 'sidebar_recents_join_channels';
    openJoinPublicChannel(gaSourceName);
  };

  render() {
    // let {recentContacts} = this.props;
    const { contacts: recentContacts, teamInfo, owner } = this.props;
    const { channelCount } = this.state;

    let groups = recentContacts.filter(
      (contact) => contact.isGroup && contact.isJoined
    );

    const { isDMBlocked } = teamInfo;
    const { isAdmin: isOwnerAdmin } = owner;

    const joinChannelsCount =
      channelCount !== null && channelCount < 100 ? channelCount : '99+';

    groups = _.sortBy(groups, (contact) => {
      const lastMessageTime = contact.lastMessageTime
        ? VERY_LARGE_NUMBER - contact.lastMessageTime
        : VERY_LARGE_NUMBER;
      return `_a_${SORT_KEY_SEPARATOR}${lastMessageTime}${SORT_KEY_SEPARATOR}${contact.name.toLowerCase()}`;
    });

    let buddies = recentContacts.filter((contact) => {
      if (isDMBlocked && !isOwnerAdmin && !contact.isAdmin) {
        return false;
      }

      return (
        contact.canChatWith &&
        (contact.subscription === 'both' || contact.subscription === 'to')
      );
    });

    buddies = _.sortBy(buddies, (contact) => {
      const lastMessageTime = contact.lastMessageTime
        ? VERY_LARGE_NUMBER - contact.lastMessageTime
        : VERY_LARGE_NUMBER;
      return `_b_${SORT_KEY_SEPARATOR}${lastMessageTime}${SORT_KEY_SEPARATOR}${contact.name.toLowerCase()}`;
    });

    return (
      <div>
        <div>
          <span
            className={classNames(
              cssStyles.roster_section_heading,
              cssStyles.recent_channels_heading
            )}
          >
            <FormattedMessage id='recent_groups' />
          </span>
          {isDMBlocked && !isOwnerAdmin ? null : (
            <div
              role='menuitem'
              onKeyDown={noop}
              tabIndex={0}
              className={classNames(
                cssStyles.recents_list_links,
                cssStyles.recents_list_create_channel_link
              )}
              onClick={this.onCreateChannelClick}
            >
              <FormattedMessage id='create_group' />
            </div>
          )}
          <div
            role='menuitem'
            onKeyDown={noop}
            tabIndex={0}
            className={classNames(cssStyles.recents_list_links)}
            onClick={this.onJoinChannelClick}
          >
            <span className={cssStyles.recents_list_join_channel_link} />
            <FormattedMessage id='join_group_button' />
            &nbsp;
            {channelCount !== null && <span>({joinChannelsCount})</span>}
          </div>
          <ListContainer
            items={groups}
            ContactComponent={Group}
            defaultLimit={LIMIT}
          />
        </div>
        <div>
          <span className={cssStyles.roster_section_heading}>
            <FormattedMessage id='recent_contacts' />
          </span>
          {isDMBlocked && !isOwnerAdmin
            ? null
            : buddies.length > 0 && (
                <div
                  role='menuitem'
                  onKeyDown={noop}
                  tabIndex={0}
                  className={classNames(
                    cssStyles.recents_list_links,
                    cssStyles.recents_list_invite_link
                  )}
                  onClick={this.onInviteClick}
                >
                  <FormattedMessage id='invite_contact' />
                </div>
              )}
          <ListContainer
            items={buddies}
            ContactComponent={Buddy}
            defaultLimit={LIMIT}
          />
          {buddies.length === 0 && <EmptyContactsState />}
        </div>
      </div>
    );
  }
}

RecentContactsContainer.propTypes = {
  currentSession: PropTypes.object.isRequired,
  guid: PropTypes.string.isRequired,
  teamInfo: PropTypes.object.isRequired,
  owner: PropTypes.object.isRequired,
};
export default withTeamInfo(
  withCurrentOwner(
    RecentContacts(
      ContactList(RecentContactsContainer, 'recentContactIds', 'guid'),
      'guid'
    )
  )
);
