import classNames from 'classnames/bind';

/**
 * cxBind is simply a wrapper for the `.bind` method of `classnames/bind`
 * default export which makes it easy to write class names from CSS modules
 *
 * @param styles Styles object obtained by importing the CSS file
 */
// eslint-disable-next-line import/prefer-default-export
export function cxBind(styles): ReturnType<typeof classNames.bind> {
  return classNames.bind(styles);
}
