import React, { PureComponent } from 'react';
import currentSessionOwnerGuidHOC from '@/connectHOCs/Sessions/currentSession/ownerGuid';
import { PromisifyBridgeSubscribes } from '@/utils';
import getBucketedList, {
  addBucket,
  getBucketFromBucketedList,
} from '@/utils/BucketedList';
import {
  getBucketedSearchContacts,
  getBucketedChannelMembers,
} from '@/utils/ContactUtils';
import { getConversationIdentity } from '@/utils/ConversationUtils';
import getTranslatedString from '@/utils/getTranslatedString';
import i18n from '@/utils/GenericModalUtils/I18N';
import { openWidgetPopupInShell } from '@/utils/SessionUtils';
import ContactSearch from '../../ContactSearch';
import { addBuddyMentionInText } from '../../utils';
import { USER } from '../../constants';
import { Peer, Owner, Command, InsertTextFun } from '../../Types';
import css from './BuddyMentionCommandHelper.css';

const GENERIC_MENTIONS = ['all', 'online'];

type Props = {
  textContent: string;
  peer: Peer;
  owner: Owner;
  ownerGuid: string;
  command: Command;
  insertText: InsertTextFun;
  children: any;
} & typeof BuddyMentionCommandHelper.defaultProps;

type State = {
  contacts: Contact[];
  bucketIndices: any;
};

class BuddyMentionCommandHelper extends PureComponent<Props, State> {
  static defaultProps = {
    textContent: '',
    peer: undefined as Peer,
    owner: undefined as Owner,
    ownerGuid: undefined as string,
    command: undefined as Command,
    insertText: (() => {}) as InsertTextFun,
    children: null,
  };

  state = {
    contacts: [],
    bucketIndices: [],
    // recommendedContacts: [],
  };

  constructor(props: Props) {
    super(props);
    this.updateSearchResults(props);
  }

  componentDidUpdate(prevProps) {
    const { command: { fragments: prevFragments = {} } = {} } = prevProps;

    const { command: { fragments = {} } = {} } = this.props;

    if (fragments.paramPrefix !== prevFragments.paramPrefix) {
      this.updateSearchResults();
    }
  }

  // TODO: while doing @all for very large group, show a confirmation dialog.
  onContactSelect = async (contact) => {
    const { jid } = contact;
    const { state } = this;
    const { contacts, bucketIndices } = state;
    const recommendationBucket = getBucketFromBucketedList(
      { contacts, bucketIndices },
      'recommendation'
    );
    const { contacts: recommendedContacts } = recommendationBucket || {
      contacts: [],
    };
    if (recommendedContacts.indexOf(jid) > -1) {
      let isInvited = false;
      try {
        isInvited = await this.inviteRecommendedContact(contact);
      } catch (error) {
        //
      }

      if (!isInvited) {
        return;
      }
    }
    const { command, insertText, textContent } = this.props;

    const { fragments } = command;

    const { textToBeAdded, startPosition, endPosition } = addBuddyMentionInText(
      contact,
      fragments,
      textContent
    );
    insertText(
      textToBeAdded,
      startPosition,
      endPosition,
      undefined,
      undefined,
      { insertType: USER, contact }
    );
  };

  // eslint-disable-next-line react/sort-comp
  inviteRecommendedContact(contact) {
    const { peer } = this.props;
    const conversationIdentity = getConversationIdentity(peer);
    const { jid, name } = contact;

    openWidgetPopupInShell('widgets/ConfirmDialog', {
      okText: getTranslatedString('text_addNewMemberToGroup', i18n),
      cancelText: getTranslatedString('cancel', i18n),
      confirmSubText: getTranslatedString('add_user_to_channel', i18n, {
        name,
      }),
      confirmText: getTranslatedString('title_add_contact', i18n),
      containerClass: 'confirm-dialog',
      tell: [`GroupInfoManager`, 'addMembers', [peer, [jid]]],
      publish: `${conversationIdentity}/confirmAddMember`,
      publishArgs: [true],
      publishCancel: `${conversationIdentity}/cancelAddMember`,
      dimensions: {
        height: 'auto',
        width: 'auto',
      },
    });

    return PromisifyBridgeSubscribes(
      `${conversationIdentity}/confirmAddMember`,
      `${conversationIdentity}/cancelAddMember`
    );
  }

  async updateSearchResults(props?) {
    const { command: { fragments = {} } = {}, owner, peer } =
      props || this.props;

    const { jid: ownerJid = '' } = owner;
    const { jid: peerJid = '', type: peerType, hasAddMemberPrivilege } = peer;
    let canMentionRecommendedContacts = false;
    const genericMentionsBucket = this.getGenericMentionContactsBucket();

    const searchParams: any = {
      start: 0,
      count: 6,
      excludedJids: [ownerJid, peerJid],
      filters: {
        isDeactivated: false,
      },
      includeImported: false,
    };

    if (peerType === 'group') {
      searchParams.groupJid = peerJid;

      if (hasAddMemberPrivilege) canMentionRecommendedContacts = true;
    }
    const searchValue = fragments.paramPrefix.slice(1);

    const searchResult = await getBucketedChannelMembers(
      peer,
      searchValue,
      searchParams
    );

    let contactsBucketedList = getBucketedList(searchResult, []);
    contactsBucketedList = addBucket(
      contactsBucketedList,
      genericMentionsBucket,
      0
    );

    const { contacts, bucketIndices } = contactsBucketedList;
    if (contacts.length < 6 && canMentionRecommendedContacts) {
      const recommendationBucket = await this.getRecommendedContacts(
        props || this.props,
        6 - contacts.length,
        contacts
      );
      contactsBucketedList = addBucket(
        contactsBucketedList,
        recommendationBucket,
        bucketIndices.length
      );
    }

    const { contacts: _c, bucketIndices: _b } = contactsBucketedList;
    this.setState({
      contacts: _c,
      bucketIndices: _b,
    });
  }

  async getRecommendedContacts(props, count, excludedJids) {
    const { command: { fragments = {} } = {}, ownerGuid } = props || this.props;

    const searchParams = {
      start: 0,
      count,
      excludedJids,
      contactTypesFilter: ['contact'],
      filters: {
        type: ['buddy', 'email'],
        isDeactivated: false,
      },
    };

    const searchValue = fragments.paramPrefix.slice(1);

    const buckets = await getBucketedSearchContacts(
      ownerGuid,
      searchValue,
      searchParams
    );
    const filteredBucket = buckets.filter((b) => b.id === 'matches')[0];
    filteredBucket.id = 'recommendation';
    this.setState({});
    return filteredBucket;
  }

  getGenericMentionContactsBucket() {
    const { command: { fragments } = {} } = this.props;

    const mentionsBucket = {
      id: 'utility',
      contacts: [],
    };

    if (!fragments) {
      return mentionsBucket;
    }
    const searchValue = fragments.paramPrefix.slice(1);

    const genericMentions = GENERIC_MENTIONS.filter(
      (m) => m.indexOf(searchValue) === 0
    );

    const genericMentionsContacts = genericMentions.map(
      (m) => `SEARCH_UTILITY:::${m}:::${m}`
    );

    mentionsBucket.contacts = genericMentionsContacts;

    return mentionsBucket;
  }

  render() {
    const { children, peer: { type: peerType } = {} } = this.props;
    const { contacts, bucketIndices } = this.state;

    if (peerType !== 'group') {
      return children();
    }
    return (
      // <div>
      <ContactSearch
        className={css.List}
        contacts={contacts}
        bucketIndices={bucketIndices}
        onContactSelect={this.onContactSelect}
        selectOnSpace={false}
        selectOnTab
        headerString='@Mention'
      >
        {children}
      </ContactSearch>
      // </div>
    );
  }
}

export default currentSessionOwnerGuidHOC(BuddyMentionCommandHelper);
