import React from 'react';
import cn from 'classnames';
import PaywallButton from '@/components/common/PaywallButton';
import getTranslatedString from '@/utils/getTranslatedString';
import noop from '@/utils/noop';
import i18n from '../nls';
import cssStyles from './MessageList.css';

type Props = {
  currentSession: Session;
};

export default function Paywall(props: Props) {
  const {
    currentSession: {
      sessionInfo: {
        teamPlan: { isEligibleForFreeProTrial } = {
          isEligibleForFreeProTrial: false,
        },
        isAdmin,
      },
      id: sessionId,
    },
  } = props;
  const msgKey = isAdmin ? 'pay_wall_admin' : 'pay_wall_user';
  return (
    <>
      <div className={cssStyles.history_error_warning_container}>
        <div
          className={cn(
            cssStyles.history_error_warning_msg,
            cssStyles.paywallMessage
          )}
        >
          <span>{getTranslatedString(msgKey, i18n)}</span>
        </div>
      </div>
      <PaywallButton
        isAdmin={isAdmin}
        isEligibleForFreeProTrial={isEligibleForFreeProTrial}
        sessionId={sessionId}
        feature='search'
        source='chat_tab_scroll'
        gaSource='VIEW_HISTORY_UPGRADE'
        className={cssStyles.messagePaywallButton}
        close={noop}
      />
    </>
  );
}
