const createFileUploadButton = function (multiple) {
  const but = document.createElement('input');
  but.type = 'file';
  but.multiple = multiple;
  but.style.display = 'none';

  document.body.appendChild(but);
  return but;
};

/**
  Create a input element, trigger a click and when files are selected, delete the input.

  Incase input emits no change event, we woudn't know when to delete it. 
  And if selectFile is called again, instead of creating new input element
  reuse the old one.
 */
let input = null;
export default function (onFileSelect, multiple = true) {
  if (!input) {
    input = createFileUploadButton(multiple);
  }
  input.onchange = function (event) {
    input.parentNode.removeChild(input);
    input = null;
    const { files } = event.target;
    onFileSelect(files);
  };
  input.click();
}
