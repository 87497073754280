import React, { forwardRef } from 'react';
import cn from 'classnames';
import { MESSAGE_READING_STATE } from '@/utils/constants';
import cssStyles from './ReadingState.css';

const { UNREAD, READING, READ } = MESSAGE_READING_STATE;

export default React.memo(
  forwardRef(function ({ readingState, className }, ref) {
    if (!readingState) {
      return null;
    }

    return (
      <span
        ref={ref}
        className={cn(className, {
          [cssStyles.unreadMessageDot]: readingState === UNREAD,
          [cssStyles.readingMessageDot]: readingState === READING,
          [cssStyles.readMessageDot]: readingState === READ,
        })}
      />
    );
  })
);
