/* eslint-disable prefer-destructuring */
import { logMedusaGenericEvent } from '@/utils/logAnalytics';
import flockmlParser from '@/utils/flockML';
import {
  decorateTextNodes,
  removeStylesFromNodes,
  logNodeNamesAndStyles,
  trimOutUnnecessarySpaces,
} from '@/utils/message/flockml';
import { hyperLinkUrlsInMarkup } from '@/utils/HyperLinkUrlEnvelope';
import { getHtmlWithEmojiAsImage } from './EmojiUtils';
import { getSelection } from './SelectionUtils';
import {
  replaceTag,
  textToHtmlConverter,
  replaceSpecialEntities,
  clearInvisibleSpaces,
} from './Utils';
import { FroalaEditorType } from '../Types';

const BOLD_REGEX: RegExp = /(\s|^)\*([^\s*:][^*]*[^\s*:]|[^\s*:])\*(\s|$)/g;
const ITALIC_REGEX: RegExp = /(\s|^)_([^\s_:][^_]*[^\s_:]|[^\s_:])_(\s|$)/g;
const STRIKETHROUGH_REGEX: RegExp = /(\s|^)~~([^\s~:][^~]*[^\s~:]|[^\s~:])~~(\s|$)/g;
const DEFAULT_IGNORE_TAGS = ['A', 'USER', 'CHANNEL'];
const BOLD_TAG = 'strong';
const ITALIC_TAG = 'i';
const STRIKETHROUGH_TAG = 'del';

const replacementsForMarkdown = [
  { regex: BOLD_REGEX, tag: BOLD_TAG },
  { regex: ITALIC_REGEX, tag: ITALIC_TAG },
  { regex: STRIKETHROUGH_REGEX, tag: STRIKETHROUGH_TAG },
];

const replacementsForFroala = [{ tagToBeReplaced: 'del', properTag: 's' }];

const tagToFormatMap = {
  [BOLD_TAG]: 'bold',
  [ITALIC_TAG]: 'italics',
  [STRIKETHROUGH_TAG]: 'strikethrough',
};

export const getEditorData = (editor: FroalaEditorType) => {
  const htmlContent = editor.html.get(true);
  const textContent = plainTextConverter(htmlContent);
  const { selectionStart, selectionEnd } = getSelection(editor);
  return {
    htmlContent,
    textContent,
    selectionStart,
    selectionEnd,
  };
};

const _getMarkersWithoutInvisibleSpaces = (editor) => {
  const startMarker = editor.FROALA.START_MARKER.replace(
    editor.FROALA.INVISIBLE_SPACE,
    ''
  );
  const endMarker = editor.FROALA.END_MARKER.replace(
    editor.FROALA.INVISIBLE_SPACE,
    ''
  );
  return {
    startMarker,
    endMarker,
  };
};

export const isHtmlContainsMarker = (html: string, editor): boolean => {
  const { startMarker, endMarker } = _getMarkersWithoutInvisibleSpaces(editor);
  const cleanHtml = clearInvisibleSpaces(html);
  return cleanHtml.includes(startMarker) || cleanHtml.includes(endMarker);
};

export const plainTextConverter = (html: string): string => {
  const span = document.createElement('span');
  let _html = html;
  _html = _html.replace(/&nbsp;/g, ' ');
  _html = _html.replace(/<br[^>]*\/?>/gm, '\n');
  _html = clearInvisibleSpaces(_html);
  span.innerHTML = _html;
  const emojis = span.querySelectorAll('.emoji');
  for (let i = 0; i < emojis.length; i += 1) {
    emojis[i].textContent = emojis[i].getAttribute('data-emoji-native');
  }
  return span.textContent;
};

export const getFormatedContent = (
  htmlContent: string,
  textContent: string = '',
  isPastedContent?: boolean
): string => {
  let _content: string = textContent || '';
  if (htmlContent) {
    _content = htmlContent;
  } else {
    _content = textToHtmlConverter(_content);
  }
  return _decorateHtml(_content, isPastedContent);
};

const _decorateHtml = (html: string, isPastedContent?: boolean): string => {
  const decorators = [hyperLinkUrlsInMarkup];
  if (isPastedContent) {
    decorators.push(convertMarkdownToHtml);
  }
  const _content = html;
  let decoratedHtml: string = decorators.reduce(
    (content, fn) => decorateTextNodes(content, fn, DEFAULT_IGNORE_TAGS),
    _content
  );
  decoratedHtml = getHtmlWithEmojiAsImage(decoratedHtml);
  return convertToProperFroalaHtml(decoratedHtml);
};

const convertToProperFroalaHtml = (html: string) => {
  let properFroalaHtml = html;
  replacementsForFroala.forEach(({ tagToBeReplaced, properTag }) => {
    properFroalaHtml = replaceTag(properFroalaHtml, tagToBeReplaced, properTag);
  });
  return properFroalaHtml;
};

export const removeStyles = (
  html: string,
  styles: Array<string> = []
): string => {
  return removeStylesFromNodes(html, styles, ['USER', 'CHANNEL']);
};

export const convertMarkdownToHtml = (html: string, logObj?): string => {
  let _html: string = html;
  replacementsForMarkdown.forEach((replacement) => {
    const { regex, tag } = replacement;
    const prevHtml = _html;
    _html = _html.replace(regex, `$1<${tag}>$2</${tag}>$3`);
    if (logObj && prevHtml !== _html) {
      logObj.isMarkdownUsed = true;
      logObj[tagToFormatMap[tag]] = true;
    }
  });
  return _html;
};

const _replaceMarkdown = (html: string, isEditMessage?: boolean): string => {
  const logObj = {
    is_edited: !!isEditMessage,
  };
  const markdownConverter = (_html: string) =>
    convertMarkdownToHtml(_html, logObj);
  const replacedHtml = decorateTextNodes(
    html,
    markdownConverter,
    DEFAULT_IGNORE_TAGS
  );
  _logMardownUsageToMedusa(logObj);
  return replacedHtml;
};

const _logMardownUsageToMedusa = (logObj) => {
  if (logObj && logObj.isMarkdownUsed) {
    logMedusaGenericEvent('markdown_usage', logObj);
  }
};

export const convertToProperFlockml = (
  flockml: string,
  isEditMessage?: boolean
): string => {
  let _flockml: string = flockml;
  const viewDocument = flockmlParser(_flockml);
  _removeMarkersFromFlockml(viewDocument);
  sanitizeLinks(viewDocument);
  _blockTagsToBr(viewDocument);
  _flockml = viewDocument.innerHTML;
  _flockml = _replaceMarkdown(_flockml, isEditMessage);
  _flockml = _flockml.replace(/&nbsp;/g, ' ');
  _flockml = trimOutUnnecessarySpaces(_flockml);
  _flockml = _flockml.replace(/<\/?br>/g, '<br/>');
  _flockml = replaceTag(_flockml, 's', 'del');
  _flockml = _ensureWhiteSpaceAfterLastChannelElement(_flockml);
  // Dom parser converts all nodes attributes to lowercase so we need to set it to original
  _flockml = _flockml.replace(/(<user[\s]+[^>]*)userid/g, '$1 userId');
  _flockml = _flockml.replace(/(<channel[\s]+[^>]*)channelid/g, '$1 channelId');
  return `<flockml>${_flockml}</flockml>`;
};

export const removeUnnecessaryTags = (html: string): string => {
  const viewDocument = flockmlParser(html);
  const styleNodes = viewDocument.querySelectorAll('style');
  styleNodes.forEach((node) => node.remove());
  return viewDocument.innerHTML;
};

export const logEventToMedusa = (eventObj: any) => {
  // console.log('event log to medusa', eventObj);
  logMedusaGenericEvent(eventObj.category, {
    action: eventObj.action,
    label: eventObj.label,
  });
};

export const getFormatting = (flockml: string) => {
  const { nodes, color } = logNodeNamesAndStyles(flockml);
  const _formatObj = {
    bold: nodes.STRONG || nodes.B,
    italic: nodes.I || nodes.EM,
    underline: nodes.U,
    strikethrough: nodes.DEL || nodes.S,
    link: nodes.A,
    color,
    mention_used: nodes.USER,
    hashtag_used: nodes.CHANNEL,
  };
  const formatObj = {};
  Object.keys(_formatObj).forEach((key) => {
    if (_formatObj[key]) {
      formatObj[key] = true;
    }
  });
  return formatObj;
};

export const logFormattingToMedusa = (
  formattingObj: any,
  isEdited?: boolean
) => {
  // console.log('formatingggg', { formattingObj });
  logMedusaGenericEvent('formatted_message_sent', {
    ...formattingObj,
    is_edited: !!isEdited,
  });
};

const _ensureWhiteSpaceAfterLastChannelElement = (html: string): string => {
  const textContent = plainTextConverter(html);
  const regex: RegExp = /#[^\s\n\r]+$/g;
  const match = textContent.match(regex);
  if (match && match.length) {
    const document = flockmlParser(html);
    const channelNodes = document.querySelectorAll('channel');
    const lastChannelNode =
      channelNodes.length > 0 ? channelNodes[channelNodes.length - 1] : null;
    if (lastChannelNode && lastChannelNode.textContent === match[0]) {
      _insertTextNodeAfter(lastChannelNode, ' ');
      return document.innerHTML;
    }
  }
  return html;
};

const _insertTextNodeAfter = (node: Node, text: string) => {
  const textNode = document.createTextNode(text);
  node.parentNode.insertBefore(textNode, node.nextSibling);
};

const _removeMarkersFromFlockml = (viewDocument: Element): void => {
  const markers = viewDocument.querySelectorAll('.fr-marker');
  for (let i = 0; i < markers.length; i += 1) {
    markers[i].remove();
  }
};

export const sanitizeLinks = (viewDocument: Element): void => {
  const links = viewDocument.getElementsByTagName('a');
  for (let i = 0; i < links.length; i += 1) {
    const href = links[i].attributes.getNamedItem('href').value;
    const generalPrefix = /^\/\//g;
    let newHref = href.replace(generalPrefix, 'https://');
    newHref = _applyAutoPrefix(newHref);
    links[i].attributes.getNamedItem('href').value = replaceSpecialEntities(
      newHref
    );
    links[i].target = '_blank';
  }
};

const _applyAutoPrefix = (href: string): string => {
  const autoPrefixes: RegExp = /^((http|https|ftp|ftps|mailto|tel|sms|notes|data):)/i;
  if (!autoPrefixes.test(href)) {
    return `https://${href}`;
  }
  return href;
};

const _blockTagsToBr = (viewDocument: Element): void => {
  const blockElements = viewDocument.querySelectorAll('p, div');
  try {
    for (let i = 0; i < blockElements.length; i += 1) {
      const blockElement = blockElements[i];
      blockElement.outerHTML =
        blockElement.innerHTML + (blockElement.nextSibling ? '<br>' : '');
    }
  } catch (e) {
    console.error(e);
  }
};
