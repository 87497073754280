import type { FileUploadType } from './types';
import { sendMessage } from '../ConversationUtils';
import { fetchImageDimensionsFromFile } from '../image';

const createPreviewObject = function (response: any, file: any) {
  const { thumbUrl } = response;
  if (!thumbUrl) {
    return undefined;
  }
  if ((file.type || '').indexOf('gif') !== -1) {
    return undefined;
  }
  // eslint-disable-next-line camelcase
  const { thumbUrl_height, thumbUrl_width, thumbId } = response;
  return {
    source: thumbUrl,
    height: thumbUrl_height,
    width: thumbUrl_width,
    mime_type: response['thumbUrl_mime-type'] || 'image',
    id: thumbId,
  };
};

const createFileLinkMessage = async function (
  file: any,
  id: string,
  url: string,
  preview: string,
  peer: any,
  previewObj: any,
  msgCid: string
) {
  if (!url.trim()) return undefined;

  const message: any = {
    text: '',
    sentByPeer: false,
    peer,
    id: msgCid,
  };

  if (!file) {
    return message;
  }

  const { type: mimeType, name: filename, size } = file;
  let { width, height } = file;
  let notification = `sent a file ${filename}`;
  const elements: any = {
    bundle: {
      filename,
      source: url,
      type: 'file',
      size,
      id,
      preview,
      mime_type: mimeType,
    },
  };
  if (mimeType.indexOf('image') === 0) {
    if (previewObj) {
      elements.bundle.previews = [previewObj];
    }
    notification = `sent an image ${filename}`;
    if (!width || !height) {
      try {
        const dimensions = await fetchImageDimensionsFromFile(file);
        width = dimensions?.width || width;
        height = dimensions?.height || height;
      } catch (e) {
        console.error(e);
      }
    }
    elements.bundle = {
      ...elements.bundle,
      type: 'photo',
      width,
      height,
    };
  }

  message.notification = notification;
  message.messageElements = elements;

  return message;
};

const sendFileMessage = function (
  filesUploadStatus: FileUploadType[],
  peer: any
) {
  const uploadedFilesStatus = filesUploadStatus.filter(
    (uploadStatus) => uploadStatus.status === 'SUCCESS'
  );

  uploadedFilesStatus.forEach((fileStatus) => {
    const { response, file, msgCid } = fileStatus;
    if (!response) {
      return;
    }
    const { longId, longUrl, preview } = response;
    const previewObj = createPreviewObject(response, file);
    createFileLinkMessage(
      file,
      longId,
      longUrl,
      preview,
      peer,
      previewObj,
      msgCid
    ).then((message) => {
      sendMessage(message);
    });
  });
};

export default sendFileMessage;
