import { connect } from 'react-redux';
import { ownerObjSelector } from '@/selectors';

function mapStateToProps(state) {
  const owner = ownerObjSelector(state);
  return { owner };
}

export default function (component) {
  return connect(mapStateToProps)(component);
}
