import React from 'react';
import classNames from 'classnames';
import styles from './Button.css';

type Props = {
  fullWidth?: Boolean;
  size?: 'normal' | 'small' | 'large';
  link?: Boolean;
  shadow?: Boolean;

  /** Whether to exchange the background and color of the button. */
  invert?: Boolean;

  kind?: 'primary' | 'secondary' | 'tertiary' | 'link';
} & Omit<React.ComponentProps<'button'>, 'size'>;

export default React.memo(function Button({
  onClick,
  fullWidth = false,
  size = 'normal',
  children,
  link,
  shadow = false,
  kind = 'primary',
  invert = false,
  disabled = false,
  className = '',
  ...rest
}: Props) {
  return (
    <button
      type='button'
      className={classNames(
        className,
        styles.button,
        styles[size],
        ...kind.split(' ').map((k) => styles[k]),
        {
          [styles.link]: link,
          [styles.invert]: invert,
          [styles.shadow]: shadow,
          [styles.disabled]: disabled,
          [styles.fullWidth]: fullWidth,
        }
      )}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
});
