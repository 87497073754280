import { createSelector } from 'reselect';
import pinsOrderSelector from './pinnedChats';
import currentConversationSelector from './currentConversation';

const isCurrentConversationPinnedSelector = createSelector(
  currentConversationSelector,
  pinsOrderSelector,
  (current, pinnedChats) => pinnedChats.indexOf(current) > -1
);

export default isCurrentConversationPinnedSelector;
