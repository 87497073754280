import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Actions } from '@/actions/restricted';

const defaultSelectedContacts = [];

const selectedContactsSelectorFactory = (guidProp) => {
  return createSelector(
    (state, props) => {
      const allSelectedContacts =
        state.restricted.contact.selected_contacts_in_search || {};
      return allSelectedContacts[props[guidProp]] || defaultSelectedContacts;
    },
    (selectedContacts) => ({ selectedContacts })
  );
};

function mapDispatchToProps(guidProp, dispatch, ownProps) {
  const ownerGuid = ownProps[guidProp];

  return {
    setSelectedContacts: (contacts) => {
      dispatch(
        Actions.selected_contacts_in_search({
          [ownerGuid]: contacts,
        })
      );
    },
  };
}
export default function (component, guidProp = 'guid') {
  return connect(
    selectedContactsSelectorFactory(guidProp),
    mapDispatchToProps.bind(null, guidProp)
  )(component);
}
