import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from '@/actions/app';
import { emojiStore } from '../storage';

export default function withEmojiPrefs(Component) {
  function WithEmojiPrefs(props) {
    const dispatch = useDispatch();
    const skinTone = useSelector((state) => state.app.emoji.skinTone);

    const changeSkinTone = useCallback(
      (newSkinTone) => {
        dispatch(Actions.set_emoji_skin_tone(newSkinTone));
        emojiStore.set('skinTone', newSkinTone);
      },
      [dispatch]
    );

    return (
      <Component
        skinTone={skinTone}
        changeSkinTone={changeSkinTone}
        {...props}
      />
    );
  }

  return WithEmojiPrefs;
}
