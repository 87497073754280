import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import OwnerGuidContext from '@/Contexts/OwnerGuidProvider';
import IntlWrapper from '@/Wrappers/IntlWrapper';
import ContactBucket from './ContactBucket';
import translations from './I18N';
import cssStyles from './ContactList.css';

class ContactList extends PureComponent {
  wrapper = React.createRef();

  static propTypes = {
    contactList: PropTypes.array,
    activeContactIndex: PropTypes.number,
    onItemClick: PropTypes.func,
    bucketIndices: PropTypes.array,
    customFieldsMap: PropTypes.object,
    joinButtonBuckets: PropTypes.array,
    addButtonBuckets: PropTypes.array,
    showCustomFields: PropTypes.bool,
    showDescription: PropTypes.bool,
    showGroupCreatedBy: PropTypes.bool,
    headerString: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    contactList: [],
    activeContactIndex: -1,
    onItemClick: () => {},
    bucketIndices: [],
    customFieldsMap: {},
    joinButtonBuckets: [],
    addButtonBuckets: [],
    showDescription: false,
    showCustomFields: false,
    showGroupCreatedBy: false,
    headerString: '',
    className: '',
  };

  componentDidUpdate(prevProps) {
    const { activeContactIndex } = this.props;
    const { activeContactIndex: prevActiveContactIndex } = prevProps;

    if (activeContactIndex !== prevActiveContactIndex) {
      this.adjustScroll(activeContactIndex);
    }
  }

  adjustScroll(index) {
    const wrapperEl = this.wrapper.current;
    const activeElement = wrapperEl.querySelectorAll('li')[index];
    if (!activeElement) {
      return;
    }
    const {
      top: wrapperTop,
      bottom: wrapperBottom,
    } = wrapperEl.getBoundingClientRect();
    const {
      top: activeElementTop,
      bottom: activeElementBottom,
    } = activeElement.getBoundingClientRect();

    if (activeElementTop < wrapperTop) {
      wrapperEl.scrollTop -= wrapperTop - activeElementTop;
    }
    if (activeElementBottom > wrapperBottom) {
      wrapperEl.scrollTop += activeElementBottom - wrapperBottom;
    }
  }

  render() {
    const {
      onItemClick,
      bucketIndices,
      contactList,
      activeContactIndex,
      customFieldsMap,
      addButtonBuckets,
      joinButtonBuckets,
      showDescription,
      showCustomFields,
      showGroupCreatedBy,
      headerString,
      className,
      showGroupTypeHeader,
    } = this.props;

    return (
      <div ref={this.wrapper} className={`${cssStyles.wrapper} ${className}`}>
        {headerString.length && contactList.length > 0 ? (
          <div className={cssStyles.header}>{headerString}</div>
        ) : null}
        {bucketIndices.map((b) => {
          const contacts = contactList.slice(b.start, b.length + b.start);
          const showHeader =
            showGroupTypeHeader &&
            b.id !== 'matches' &&
            b.id !== 'temp' &&
            b.id !== 'topRecent' &&
            b.id !== 'utility' &&
            contacts.length > 0;
          return (
            <OwnerGuidContext.Consumer key={b.id}>
              {(ownerGuid) => (
                <ContactBucket
                  contactIds={contacts}
                  // contacts={contacts}
                  activeIndex={activeContactIndex - b.start}
                  bucketId={b.id}
                  onItemClick={onItemClick}
                  customFieldsMap={customFieldsMap}
                  showHeader={showHeader}
                  ownerGuid={ownerGuid}
                  showJoinButton={joinButtonBuckets.indexOf(b.id) > -1}
                  showAddButton={addButtonBuckets.indexOf(b.id) > -1}
                  showCustomFields={showCustomFields}
                  showDescription={showDescription}
                  showGroupCreatedBy={showGroupCreatedBy}
                />
              )}
            </OwnerGuidContext.Consumer>
          );
        })}
      </div>
    );
  }
}

export default IntlWrapper(ContactList, translations);
