import _get from 'lodash/get';
import _set from 'lodash/set';
import _merge from 'lodash/merge';

const DEFAULT_PREFIX = 'flock-';

function isLocalStorageSupported() {
  const randomKey = '__FLOCK_LS_TEST_KEY__';

  try {
    localStorage.getItem(randomKey);
    localStorage.setItem(randomKey, '');
  } catch (error) {
    return false;
  }

  return true;
}

const localStorageStub = {
  getItem() {},
  setItem() {},
  removeItem() {},
};

function getLocalStorage() {
  return isLocalStorageSupported() ? window.localStorage : localStorageStub;
}

class LocalStorageStore {
  constructor(key, { prefix = DEFAULT_PREFIX } = {}) {
    this.prefix = prefix;
    this.key = this.getKey(key);
    this._localStorage = isLocalStorageSupported()
      ? window.localStorage
      : getLocalStorage();
  }

  init(defaultValue = {}) {
    if (defaultValue == null || typeof defaultValue !== 'object') {
      throw new Error('defaultValue must be an object');
    }

    const storeRecord = this.getRootItem() || {};

    this._localStorage.setItem(
      this.key,
      JSON.stringify(_merge(defaultValue, storeRecord))
    );
  }

  get(path) {
    if (!path) {
      throw new Error('A valid path must be provided');
    }

    return _get(this.getRootItem(), path);
  }

  set(path, value) {
    if (!path) {
      throw new Error('A valid path must be provided');
    }

    const rootItem = this.getRootItem() || {};
    _set(rootItem, path, value);
    this._localStorage.setItem(this.key, JSON.stringify(rootItem));
  }

  clear() {
    this._localStorage.removeItem(this.key);
  }

  has(path) {
    if (!path) {
      throw new Error('A valid path must be provided');
    }

    return this.get(path) !== undefined;
  }

  getRootItem() {
    return JSON.parse(this._localStorage.getItem(this.key));
  }

  getKey(unprefixedKey) {
    return `${this.prefix}${unprefixedKey}`;
  }
}

export default LocalStorageStore;
