export default {
  messageArea_chatTab_text_historyButton:
    'This is the beginning of your chat with {name}',
  groupPurpose_blank_value: "Set your channel's purpose here",
  GET_THE_APP: 'Get the App',
  MAKE_FLOCK_BETTER_APPS: 'Make Flock even better with apps',
  me_tab_user_status:
    'Save quick messages, files or links to read later. Track personal to-dos or create notes, without a worry!',
  me_tab_your_space: 'Your personal space',
  flock_support: 'Flock Support',
  flock_support_description:
    'Leave a message to directly chat with our support agents',
  group_chat_header_add_todo: 'Add a to-do',
  group_chat_header_set_reminder: 'Set a reminder',
  group_chat_header_connect_app: 'Connect an app',
  visible_to_me_text: 'Only you can see this',
  me_text: 'Me',
  history_fetch_error: 'Something went wrong while loading messages.',
  retry: 'Retry',
};
