import * as React from 'react';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import IntlWrapper from '@/Wrappers/IntlWrapper';
import translations from './i18n';
import css from './index.css';

const DeactivatedBadge = React.memo(function DeactivatedBadge({
  className = '',
  variant = '',
  ...styleProps
}) {
  const classNames = [css.Deactivated, className];

  if (variant === 'list-item') {
    classNames.push(css.ListItemVariant);
  }

  return (
    <span className={cx(classNames)} style={styleProps}>
      <FormattedMessage id='core.labels.deactivated' />
    </span>
  );
});

export default IntlWrapper(DeactivatedBadge, translations);
