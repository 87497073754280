/* eslint-disable import/prefer-default-export, prefer-destructuring */

const _isUpdatePopup = (button, editor) => {
  if (
    button.innerText.toLowerCase() ===
    editor.language.translate('Update').toLowerCase()
  ) {
    return true;
  }
  return false;
};

const turnOffAutoCompleteforInputs = (popup) => {
  const inputs = popup.find('input');
  for (let i = 0; i < inputs.length; i += 1) {
    inputs[i].setAttribute('autocomplete', 'off');
  }
};

const repositionPopup = (popup, editor) => {
  const offsetTop: number = popup.offset().top;
  const heightPopup: number = popup.outerHeight();
  const clientHeight: number = editor.$sc.get(0).clientHeight;
  // if there is no space at below then reposition it at above
  if (editor.opts.toolbarInline && offsetTop + heightPopup > clientHeight) {
    popup.css('top', offsetTop - heightPopup);
  }
};

export const setupUIForLink = (editor) => {
  const popup = editor.popups.get('link.insert');
  repositionPopup(popup, editor);
  setTimeout(() => {
    popup.removeClass('fr-hidden'); // need to remove hidden class after it has been added by froala.
  }, 0);
  const buttonContainer = popup.find('.fr-action-buttons')[0];
  let insertButton = null;

  if (buttonContainer.children.length > 1) {
    insertButton = buttonContainer.lastChild;
  } else {
    const newCancelButtonHtml = `<button class="fr-command" role="button" data-cmd="linkCancel" href="#" tabIndex='-1' type="button">${editor.language.translate(
      'Cancel'
    )}</button>`;
    const newCancelButton = new DOMParser()
      .parseFromString(newCancelButtonHtml, 'text/html')
      .getElementsByTagName('button')[0];
    newCancelButton.outerHTML = newCancelButtonHtml;
    newCancelButton.addEventListener('click', () => {
      editor.popups.hide('link.insert');
    });
    insertButton = buttonContainer.firstChild;
    buttonContainer.insertBefore(newCancelButton, insertButton);
  }

  const updatePopup = _isUpdatePopup(insertButton, editor);
  if (!updatePopup) {
    insertButton.innerText = 'Apply';
  }
  turnOffAutoCompleteforInputs(popup);
};
