import { maybe } from '@/utils';
import { getSignedDownloadUrl } from '@/utils/ShellUtils';
import { downloadFromUrl } from '@/utils/SessionUtils';
import {
  showRestrictedActionForLite,
  showCannotDownloadError,
} from '@/utils/GenericModalUtils';
import bridge from '@/utils/bridge';
import spriteActionsSvg from '@/assets/sprite-actions.svg';
import getTranslatedString from '@/utils/getTranslatedString';
import ModalI18n from '@/utils/GenericModalUtils/I18N';
import i18n from './I18N';

const openForwardDialog = (title, msg, attachment) => {
  bridge.publish('odara-forward-message-modal', [
    { modalTitle: title, message: msg, attachment },
  ]);
};

export function downloadAction(
  attachment,
  currentSessionId,
  intent = 'download'
) {
  // analytics.log("chat_tab", "attachment_button", "download");
  const { downloads } = attachment;
  downloads.forEach((file) => {
    getSignedDownloadUrl(file, currentSessionId, intent).then((src) => {
      if (src instanceof Error) {
        showCannotDownloadError(
          'Whoops!',
          getTranslatedString(
            'fileSharing_error_not_connected_to_internet',
            ModalI18n
          ),
          getTranslatedString('buttonText_ok', ModalI18n)
        );
      } else {
        downloadFromUrl(src, file.filename);
      }
    });
  });
}

const attachmentActions = [
  {
    label: getTranslatedString('forward', i18n),
    id: 'Default_Forward_Button',
    isLocal: true,
    divClass: 'forward_button attachment_button',
    icon: `url(${spriteActionsSvg}) -388px -385px no-repeat`,
    validator: (attachment) => {
      if (!maybe(attachment, 'forward')) {
        return false;
      }
      const { forward } = attachment;
      return forward;
    },
    action: (attachment, currentSessionId, msg, owner) => {
      let title = 'forward_';
      const appId = attachment.appId || msg.appId;
      const sessionId = currentSessionId;
      const { isLicensed } = owner;

      if (appId && !attachment.url) {
        bridge
          .ask('DikeController', 'getAppById', [sessionId, appId])
          .then(function (app) {
            if (!app) return;
            if (app) {
              title += 'message';
              openForwardDialog(
                getTranslatedString(title, i18n),
                msg,
                attachment
              );
            }
          });
      } else {
        if (attachment.url) {
          title += 'link';
        } else if (attachment.views && attachment.views.image) {
          if (!isLicensed) {
            showRestrictedActionForLite(sessionId, 'SHARE_FILE');
            return;
          }
          title += 'image';
        } else if (attachment.downloads && attachment.downloads.length > 0) {
          if (!isLicensed) {
            showRestrictedActionForLite(sessionId, 'SHARE_FILE');
            return;
          }
          title += 'file';
        } else {
          title += 'message';
        }
        openForwardDialog(getTranslatedString(title, i18n), msg, attachment);
        // analytics.log("chat_tab", "attachment_button", "forward");
      }
    },
  },
  {
    label: getTranslatedString('download', i18n),
    id: 'Default_Download_Button',
    isLocal: true,
    divClass: 'download_button attachment_button',
    icon: `url(${spriteActionsSvg}) -358px -358px no-repeat`,
    action: (attachment, sessionId /* msg, owner */) =>
      downloadAction(attachment, sessionId, 'download'),
    validator: (attachment) => {
      if (!attachment) {
        return false;
      }
      const { downloads } = attachment;
      if (!downloads) {
        return false;
      }
      return maybe(downloads, 0, 'src');
    },
  },
];

export default attachmentActions;
