export default {
  delete_channel_confirm_button: 'S\u00ed, abandonar y borrar',
  leave_confirm_button: 'S\u00ed, abandonar',
  channel_delete_confirm_message:
    'Este canal ser\u00e1 borrado ya que usted es el \u00faltimo miembro. \u00bfEst\u00e1 seguro?',
  channel_leave_warning_message:
    'Tendr\u00e1 que ser invitado para unirse de nuevo a este canal. \u00bfEst\u00e1 seguro?',
  channel_leave_confirm_message:
    '\u00bfEst\u00e1 seguro de que quiere abandonar este canal?',
  leave_channel_title: '\u00bfAbandonar el canal?',
};
