import { openWidgetPopupInShell } from '@/utils/SessionUtils';
import { mimeExtMap } from '@/utils/image';

const _nls = {
  fileSharing_chatTab_modal_title_uploadImage: 'Upload Image',
};

function showDialogBox(clipboardImageObj) {
  const fileReader = new FileReader();
  fileReader.onload = (e) => {
    const {
      target: { result: imgSrc },
    } = e;
    const tempImg = new window.Image();
    tempImg.crossOrigin = 'Anonymous';
    tempImg.src = imgSrc;
    tempImg.onload = () => {
      clipboardImageObj.dataUrl = imgSrc;
      clipboardImageObj.file.width = tempImg.width;
      clipboardImageObj.file.height = tempImg.height;
      openWidgetPopupInShell('widgets/clipboard_image_upload/ClipboardDialog', {
        containerClass: 'clipboard-image-upload-dialog',
        clipboardImageObj,
        title: _nls.fileSharing_chatTab_modal_title_uploadImage,
        dimensions: {
          width: 'auto',
          height: 'auto',
        },
      });
    };
    tempImg.onerror = (error) => {
      console.error('error while loading image: ', error, tempImg.error);
    };
  };
  fileReader.readAsDataURL(clipboardImageObj.file);
}

export default function uploadImageFromPasteEvent(event, peer, session) {
  const {
    clipboardData: { items },
  } = event;
  for (let i = 0; i < items.length; i += 1) {
    if (items[i].type.indexOf('image') === 0) {
      showDialogBox({
        file: items[i].getAsFile(),
        ext: mimeExtMap[items[i].type] || '',
        peer,
        session,
      });
      break;
    }
  }
}
