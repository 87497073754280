import { getConversationType } from '@/utils';

/* The structure of the object will look like:
  [ownerGuiid]: {
    [jid]: {
      jid,
      pinned,
      type
    }
  } 
*/

const defaultState = {};

const defaultConversations = {};

function handlePins(state, action, pinned) {
  const { ownerGuid, conversations } = action.payload;
  const existingConversations = state[ownerGuid] || defaultConversations;

  const allConversations = conversations.reduce(
    (allConvos, jid) => {
      allConvos[jid] = {
        jid,
        type: getConversationType(jid),
        pinned,
      };
      return allConvos;
    },
    { ...existingConversations }
  );

  return {
    ...state,
    [ownerGuid]: allConversations,
  };
}

export default (ActionTypes) => {
  return (state = defaultState, action) => {
    switch (action.type) {
      case ActionTypes.INIT_CHATS: {
        const { ownerGuid, conversations } = action.payload;
        const existingConversations = state[ownerGuid] || defaultConversations;
        if (!conversations) {
          return {
            ...state,
            startSyncing: true,
          };
        }

        const allConversations = conversations.reduce(
          (allConvos, conversation) => {
            allConvos[conversation.jid] = conversation;
            return allConvos;
          },
          { ...existingConversations }
        );

        return {
          ...state,
          startSyncing: true,
          [ownerGuid]: allConversations,
        };
      }

      case ActionTypes.INIT_PINS: {
        const { ownerGuid, conversations: pinnedJids } = action.payload;
        const existingConversations = state[ownerGuid] || defaultConversations;

        // Remove chats which were pinned chats because we want to initialize from server.
        if (existingConversations !== defaultConversations) {
          Object.keys(existingConversations).forEach((jid) => {
            // We are spreading the existing conversations below, so no need to clone it twice.
            existingConversations[jid].pinned = false;
          });
        }

        return {
          ...state,
          [ownerGuid]: {
            ...existingConversations,
            ...pinnedJids.reduce((pinnedContacts, jid) => {
              pinnedContacts[jid] = {
                jid,
                ownerGuid,
                pinned: true,
                type: getConversationType(jid),
              };
              return pinnedContacts;
            }, {}),
          },
        };
      }

      case ActionTypes.ADD_PINNED_CHATS: {
        return handlePins(state, action, true);
      }

      case ActionTypes.REMOVE_PINNED_CHATS: {
        return handlePins(state, action, false);
      }

      case ActionTypes.START_CHAT: {
        const { ownerGuid, jid, pinned } = action.payload;
        const existingConversations = state[ownerGuid] || defaultConversations;
        const currentConversation = existingConversations[jid];

        if (currentConversation || pinned) {
          return state;
        }
        const newState = {
          ...state,
          [ownerGuid]: {
            ...existingConversations,
            [jid]: {
              jid,
              pinned: false,
              type: getConversationType(jid),
            },
          },
        };
        return newState;
      }

      case ActionTypes.REMOVE_CHAT: {
        const { ownerGuid, jid } = action.payload;
        const existingConversations = state[ownerGuid] || defaultConversations;
        const conversations = {
          ...existingConversations,
        };
        delete conversations[jid];

        return {
          ...state,
          [ownerGuid]: {
            ...conversations,
          },
        };
      }
      default:
        return state;
    }
  };
};
