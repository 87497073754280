export default {
  fileSharing_chatTab_modal_message_fileCountExceedMessage:
    'Ops! Voc\u00ea n\u00e3o pode enviar mais de 10 arquivos de uma vez.',
  fileSharing_chatTab_modal_title_fileLimitExceeded:
    'Limite de Arquivo Excedido',
  fileSharing_chatTab_modal_message_fileLimitExceed:
    'Ops! O tamanho do arquivo n\u00e3o pode exceder {fileUploadLimit} MB',
  fileSharing_chatTab_modal_buttonText_cancelFileUpload:
    'Cancelar Envio de Arquivo',
  fileSharing_chatTab_modal_buttonText_completeFileUpload:
    'Concluir Envio de Arquivo',
  fileSharing_chatTab_modal_title_fileCountExceeded: 'Muitos Arquivos',
  fileSharing_chatTab_modal_message_cancelFileUpload:
    'Tem certeza de que deseja cancelar o envio?',
  fileSharing_chatTab_modal_title_abortTitle: 'Abortar Envio de Arquivo',
  fileSharing_chatTab_hover_toolTip: 'Compartilhar um arquivo',
  buttonText_ok: 'OK',
  fileSharing_chatTab_modal_message_invalidFileType:
    'N\u00e3o foi poss\u00edvel processar o envio de arquivos. Verifique o seguinte: ',
  fileSharing_error_check_folders_cannot_be_uploaded:
    'N\u00e3o \u00e9 poss\u00edvel enviar pastas n\u00e3o compactadas.',
  fileSharing_error_check_connected_to_internet:
    'Assegure que o Flock esteja conectado \u00e0 internet.',
  fileSharing_chatTab_modal_failureMessage_someFilesFailed:
    '{numFiles} arquivo(s) n\u00e3o foram enviados devido a um problema. Verifique sua conex\u00e3o com a Internet e tente novamente.',
  fileSharing_chatTab_modal_fileShareFailed_title:
    'Falha ao Transferir Arquivo',
  fileSharing_chatTab_modal_failureMessage_allFilesFailed:
    'Sus arquivo(s) n\u00e3o foram enviados devido a um problema. Verifique sua conex\u00e3o com a Internet e tente novamente.',
  fileSharing_chatTab_modal_message_fileShareDisabledByAdmin:
    'O compartilhamento de arquivos foi proibido. Entre em contato com o seu administrador do Flock, se necess\u00e1rio.',
  fileSharing_chatTab_modal_progressMessage_percentageUploaded:
    '$percentUploaded$% de $totalFileSize$',
  fileSharing_chatTab_modal_filename: 'Nome do Arquivo',
  fileSharing_chatTab_modal_title_fileShareDisabledByAdmin:
    'Compartilhamento de arquivo restrito',
  fileSharing_chatTab_modal_title_uploadImage: 'Enviar Imagem',
  fileSharing_chatTab_notification_sentFile: 'enviou um arquivo $filename$',
  fileSharing_chatTab_notification_sentImage: 'enviou uma imagem $filename$',
  fileSharing_chatTab_uploadingText_fileUploading: 'Enviando',
  fileSharing_chatTab_chatTab_multipleFileUploadProgressString:
    '{countDone} de {totalCount} arquivos',
  fileSharing_chatTab_chatTab_files: 'arquivos',

  chatTab_dropdown_groupMemberList_loadingMemberText: 'Buscando Membros....',
  chatTab_dropdown_groupMemberList_MultipleMembers: '{member_count} Membros',
  chatTab_inputBox_placeholderText_inviteNewMemberToGroup:
    'Convidar para o canal',
  chatTab_dropdown_groupMemberList_removeMember: 'Remover',
  chatTab_dropdown_groupMemberList_SingleMember: '{member_count} Membro',
  text_addNewMemberToGroup: 'Adicionar',
  remove_member_ok_text: 'Sim, remover',
  remove_member_cancel_text: 'N\u00e3o',
  remove_member_confirm_dialog_text:
    'Tem certeza de que deseja remover {member_name} deste canal?',
  group_name_placeholder: 'Definir nome do canal',
  group_purpose_placeholder: 'Definir finalidade do canal',
  title_remove_contact: 'Remover contato?',

  ATTENTION: 'Aten\u00e7\u00e3o!',
  OOPS: 'Opa!',
  PERMISSION_DETAILS_MESSAGE:
    'Um Convidado no Flock n\u00e3o pode realizar esta a\u00e7\u00e3o!',
  INVITE_PERMISSION_MESSAGE:
    'Voc\u00ea n\u00e3o tem permiss\u00e3o para adicionar convidados nesta equipe. Entre em contato com o administrador da equipe.',
  PERMISSION_RESTRICTED: 'Permiss\u00e3o Restrita',
  LIMITED_PERMISSIONS:
    'Um Convidado no Flock s\u00f3 pode acessar conversas e aplicativos selecionados.',
  PUBLIC_CHANNELS_MESSAGE:
    'Opa! Um Convidado no Flock n\u00e3o pode visualizar ou criar canais p\u00fablicos nesta equipe.',
  ADD_GUEST_TITLE: 'Adicionar um convidado a este canal?',
  ADD_GUEST_DESCRIPTION:
    'Eles ter\u00e3o acesso a todas as mensagens e arquivos compartilhados no canal.',

  cancel: 'Cancelar',

  chatTab_popup_slashCommand_text_leaveGroupHint: 'sair do canal',
  chatTab_popup_slashCommand_text_leaveGroupCommand: 'sair',
  chatTab_popup_slashCommand_text_cloneGroupHint: 'clonar o canal atual',
  chatTab_popup_text_error_cloneGroupDialog:
    'Desculpe, canais com mais de {count} membros n\u00e3o podem ser clonados no momento.',
  chatTab_popup_slashCommand_text_cloneGroupCommand: 'clonar',
  add_popup: 'Adicionar',
  add_user_to_channel: 'Voc\u00ea deseja mesmo adicionar {name} ao canal?',
  title_add_contact: 'Adicionar contato?',

  editor_messageDropdown_menu_text_copy: 'Copiar',
  editor_messageDropdown_menu_text_edit: 'Editar',
  editor_messageDropdown_menu_text_retry: 'Tentar novamente',
  editor_messageDropdown_popup_text_deleteMessage:
    'Esta mensagem ser\u00e1 exclu\u00edda para voc\u00ea e para o destinat\u00e1rio e ser\u00e1 exclu\u00edda permanentemente dos nossos servidores.',
  editor_messageDropdown_popup_title_deleteMessage: 'Excluir mensagem?',
  editor_messageDropdown_popup_buttonText_deleteMessage_Yes: 'Sim, excluir',
  editor_messageDropdown_popup_buttonText_delete: 'Excluir',
  editor_deletion_failed: 'A\u00e7\u00e3o n\u00e3o permitida',
  editor_not_connected_to_internet_error:
    'Parece que voc\u00ea foi desconectado. Isto geralmente acontece devido a uma conex\u00e3o de internet ruim ou falta de conex\u00e3o com a internet.',
};
