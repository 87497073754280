import React from 'react';
import {
  getGroupUpdateMessageParts,
  getLocalString,
} from '@/utils/message/channelUpdate';
import contactHOC from '@/subscribeHOCs/contact';

const Contact = contactHOC(function ({ contact = {} }) {
  const { chatName = 'Unknown member', guest } = contact;
  return `${chatName}${guest ? ` (${getLocalString('guest')})` : ''}`;
});

function getContact({ jid, ownerGuid }) {
  return <Contact jid={jid} ownerGuid={ownerGuid} />;
}

export default function GroupUpdateMessage({
  msg,
  translateOwner,
  onlyLast,
  CustomTag = 'div',
}) {
  return getGroupUpdateMessageParts(
    msg,
    translateOwner,
    onlyLast,
    getContact
  ).map((infoMsg, index, arr) => {
    const key = index === arr.length - 1 ? 'last' : index;
    return <CustomTag key={key}>{infoMsg}</CustomTag>;
  });
}
