import bridge from '@/utils/bridge';
import store from '@/store';
import { Actions } from '@/actions/restricted';
import {
  getDelta,
  getSubscriptionUpdates,
  getCounter,
  getQueuedDispatch,
} from './utilities';

const dispatch = getQueuedDispatch(store.dispatch, 'Contact proxy', 50, 20);

// TODO: Move this to appropriate file

export function getContacts(ids, ownerGuid) {
  const sessionBridgeId = `Session_session_${ownerGuid}@go.to`;
  return bridge.ask(sessionBridgeId, 'getContacts', [ids, true]);
}

async function updateState(ownerGuid, addedIds, removedIds, didChange) {
  if (didChange) {
    const addedContacts = await getContacts(addedIds, ownerGuid);
    dispatch(
      Actions.contact_proxy({
        addedContacts: addedContacts.filter((c) => !!c),
        removedIds,
        ownerGuid,
      })
    );
  }
}

class ContactProxy {
  // contactIds which are to be subscribed from hydra.
  subscribedIds = {};

  // contactIds per contactProxy subscription
  subSets = {};

  // ownerGuids for each subscription
  subscribedGuids = {};

  counter = getCounter();

  constructor() {
    bridge.subscribe('/contact/update/buddy', this.onContactUpdate);
    bridge.subscribe('/contact/update/group', this.onContactUpdate);
    bridge.subscribe('/contact/update/owner', this.onContactUpdate);
    bridge.subscribe('/contact/new/buddy', this.onContactUpdate);
    bridge.subscribe('/contact/new/group', this.onContactUpdate);
  }

  getSubSets(ownerGuid) {
    if (!this.subSets[ownerGuid]) {
      this.subSets[ownerGuid] = {};
    }

    return this.subSets[ownerGuid];
  }

  setSubSets(ownerGuid, setId, value) {
    const subSet = this.getSubSets(ownerGuid);
    subSet[setId] = value;
  }

  getSubscribedIds(ownerGuid) {
    if (!this.subscribedIds[ownerGuid]) {
      this.subscribedIds[ownerGuid] = {};
    }
    return this.subscribedIds[ownerGuid];
  }

  setSubscribedIds(ownerGuid, value) {
    this.subscribedIds[ownerGuid] = value;
  }

  subscribe(ownerGuid, Ids = []) {
    const setId = this._subscribe(ownerGuid, Ids);
    return {
      unsubscribe: this.unsubscribe.bind(this, setId),
      update: (_ownerGuid, _Ids) => this._subscribe(_ownerGuid, _Ids, setId),
    };
  }

  _subscribe(ownerGuid, Ids, setId = `${this.counter.next().value}`) {
    const validIds = Ids.filter(
      (id) => id && (id.endsWith('@go.to') || id.endsWith('@groups.go.to'))
    );
    if (
      this.subscribedGuids[setId] &&
      this.subscribedGuids[setId] !== ownerGuid
    ) {
      this.unsubscribe(setId);
    }
    this.subscribedGuids[setId] = ownerGuid;
    if (ownerGuid) {
      const subscribedIds = this.getSubscribedIds(ownerGuid);
      const subSets = this.getSubSets(ownerGuid);

      const delta = getDelta(subSets[setId] || [], validIds || []);
      const {
        addedIds,
        removedIds,
        subscribedIds: _subscribedIds,
        didChange,
      } = getSubscriptionUpdates(subscribedIds, delta);

      this.setSubSets(ownerGuid, setId, [...validIds]);
      this.setSubscribedIds(ownerGuid, _subscribedIds);

      updateState(ownerGuid, addedIds, removedIds, didChange);
    }
    return setId;
  }

  unsubscribe(setId) {
    if (!setId) {
      return false;
    }

    const ownerGuid = this.subscribedGuids[setId];
    if (!ownerGuid) {
      return false;
    }

    const subscribedIds = this.getSubscribedIds(ownerGuid);
    const subSets = this.getSubSets(ownerGuid);

    const delta = getDelta(subSets[setId], []);
    const {
      addedIds,
      removedIds,
      subscribedIds: _subscribedIds,
      didChange,
    } = getSubscriptionUpdates(subscribedIds, delta);

    this.setSubSets(ownerGuid, setId, []);
    this.setSubscribedIds(ownerGuid, _subscribedIds);

    updateState(ownerGuid, addedIds, removedIds, didChange);
    return true;
  }

  onContactUpdate = (contact) => {
    const { ownerGuid, jid } = contact;
    if (!ownerGuid || !jid) {
      return;
    }
    const subscridedIds = this.getSubscribedIds(ownerGuid);
    if (!subscridedIds[jid]) {
      return;
    }
    dispatch(Actions.contact_proxy({ ownerGuid, addedContacts: [contact] }));
  };
}

export default new ContactProxy();
