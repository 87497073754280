/* eslint-disable import/prefer-default-export, no-use-before-define */
// import { replaceTextByMarkUp as replaceWikiTextToMarkUp } from '@/utils/WikiDecorator';
import flockmlParser from '@/utils/flockML';
import { hyperLinkUrlsInMarkup } from '@/utils/HyperLinkUrlEnvelope';
import { shortCodeToNative } from '@/lib/emoji';
import { MAX_NOTIFICATION_LENGTH } from '@/utils/constants';

const decoratorTypes = {
  TEXT_DECORATOR: 'text decorator',
  REMOVE_STYLES: 'remove styles',
  LOG_FORMATING: 'log formating',
};

const ignoreNodesForTrimming = ['img'];

const _eraseValue = (Obj, prop, shouldDelete) => {
  if (shouldDelete) {
    delete Obj[prop];
    return;
  }
  Obj[prop] = '';
};

export const optimizeMessageObject = (
  sendPlainText,
  messageObj,
  isEditedMessage
) => {
  if (sendPlainText) {
    _eraseValue(messageObj, 'flockml', !isEditedMessage);
    _eraseValue(messageObj, 'notification', !isEditedMessage);
  } else {
    _eraseValue(messageObj, 'text', !isEditedMessage);
    const notification = messageObj.notification || '';
    messageObj.notification =
      notification.length > MAX_NOTIFICATION_LENGTH
        ? `${notification.substr(0, MAX_NOTIFICATION_LENGTH)}...`
        : notification;
  }
};

export const getHyperLinkDecorator = () => {
  return (html) => {
    return decorateTextNodes(html, hyperLinkUrlsInMarkup, [
      'A',
      'USER',
      'CHANNEL',
    ]);
  };
};

export const decorateFlockml = (flockml) => {
  const _flockml = flockml;
  const fns = [
    getHyperLinkDecorator(),
    emojiImageToNative,
    // replaceWikiTextToMarkUp,
    shortCodeToNative,
  ];
  const decoratedFlockml = fns.reduce(
    (_decoratedFlockml, f) => f(_decoratedFlockml),
    _flockml
  );
  return decoratedFlockml;
};

export const decorateNodes = (html, decoratorObj, excludeNodes) => {
  const viewDocument = typeof html === 'string' ? flockmlParser(html) : html;
  _traverse(viewDocument, decoratorObj, excludeNodes);
  return viewDocument.innerHTML;
};

export const decorateTextNodes = (html, textDecoratorFn, excludeNodes) => {
  return decorateNodes(
    html,
    { type: decoratorTypes.TEXT_DECORATOR, decoratorFn: textDecoratorFn },
    excludeNodes
  );
};

export const removeStylesFromNodes = (html, styles = [], excludeNodes = []) => {
  return decorateNodes(
    html,
    { type: decoratorTypes.REMOVE_STYLES, styles },
    excludeNodes
  );
};

export const logNodeNamesAndStyles = (html) => {
  const decoratorObj = {
    type: decoratorTypes.LOG_FORMATING,
    nodes: {},
    color: '',
  };
  decorateNodes(html, decoratorObj, []);
  return {
    nodes: decoratorObj.nodes,
    color: decoratorObj.color,
  };
};

const _traverseChildren = (element, decoratorObj, excludeNodes = []) => {
  [...element.childNodes].forEach((child) =>
    _traverse(child, decoratorObj, excludeNodes)
  );
};

const _traverse = (element, decoratorObj, excludeNodes = []) => {
  switch (element.nodeName) {
    case '#text': {
      if (decoratorObj.type === decoratorTypes.TEXT_DECORATOR) {
        const decoratedHtml = decoratorObj.decoratorFn(
          _textToHtmlConverter(element.textContent)
        );
        const span = document.createElement('span');
        span.innerHTML = decoratedHtml;
        while (span.firstChild) {
          const node = span.firstChild;
          element.parentNode.insertBefore(node, element);
        }
        element.remove();
      }
      return;
    }

    default: {
      if (decoratorObj.type === decoratorTypes.LOG_FORMATING) {
        decoratorObj.nodes[element.nodeName] = element.nodeName;
        decoratorObj.color = element.style.color
          ? element.style.color
          : decoratorObj.color;
      }
      if (!excludeNodes.includes(element.nodeName)) {
        if (decoratorObj.type === decoratorTypes.REMOVE_STYLES) {
          decoratorObj.styles.forEach((styleType) => {
            element.style[styleType] = null;
          });
        }
        _traverseChildren(element, decoratorObj, excludeNodes);
      }
    }
  }
};

const _textToHtmlConverter = (text) => {
  const span = document.createElement('span');
  span.textContent = text;
  return span.innerHTML.replace(/&nbsp;/g, ' ');
};

export const trimOutUnnecessarySpaces = (html) => {
  const viewDocument = typeof html === 'string' ? flockmlParser(html) : html;
  _trimLeftSpaces(viewDocument);
  _trimRightSpaces(viewDocument);
  return viewDocument.innerHTML;
};

const _trimLeftSpaces = (node) => {
  if (!node) return true;
  while (node.firstChild) {
    if (!_shouldDeleteAndContinue(node.firstChild, 'left')) {
      break;
    }
  }
  if (ignoreNodesForTrimming.includes(node?.tagName?.toLowerCase()))
    return false;
  return !node.firstChild;
};

const _trimRightSpaces = (node) => {
  if (!node) return true;
  while (node.lastChild) {
    if (!_shouldDeleteAndContinue(node.lastChild, 'right')) {
      break;
    }
  }
  if (ignoreNodesForTrimming.includes(node?.tagName?.toLowerCase()))
    return false;
  return !node.lastChild;
};

const _shouldDeleteAndContinue = (node, direction) => {
  if (node.nodeName === 'BR') {
    node.remove();
    return true;
  }
  if (node.nodeName === '#text') {
    if (!node.textContent.trim()) {
      node.remove();
      return true;
    }
    return false;
  }
  const shouldDelete =
    direction === 'left' ? _trimLeftSpaces(node) : _trimRightSpaces(node);
  if (shouldDelete) {
    node.remove();
  }
  return shouldDelete;
};

const emojiImageToNative = (flockml) => {
  const viewDocument = flockmlParser(flockml);
  const emojiImages = Array.from(viewDocument.getElementsByClassName('emoji'));
  emojiImages.forEach((emoji) => {
    emoji.outerHTML = emoji.getAttribute('data-emoji-native');
  });
  return viewDocument.innerHTML;
};
