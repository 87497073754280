export default {
  text_inviteUserByEmail:
    'Consejo: Ingrese una ID de email para invitar usuarios.',
  error_ExternalContactsDisbaled:
    'Los contactos externos est\u00e1n deshabilitados por su organizaci\u00f3n. Solo puede agregar usuarios @',
  default_value: 'Buscar contactos y canales',
  talk_to: 'Talk.to',
  startChatWithEmail: 'Ingrese una id de email para empezar el chat',
  ext_contacts_disabled:
    'Los contactos externos est\u00e1n deshabilitados por su organizaci\u00f3n. Solo puede agregar usuarios @',
  tip_email: 'Consejo: Ingrese una ID de email para invitar usuarios.',
  // tab_add_people: 'Pulse {button} para agregar',
  select_to_share: 'Seleccione Contactos o Canales para compartir',
  open_groups_separator: 'Canales p\u00fablicos',
  dept_search_separator: 'Puesto Departamento',
  other_contacts_separator: 'Contactos en su equipo',
  invited_but_not_joined: 'Invitado pero no se ha unido',
  // start_new_chat: 'Empezar un chat',
  join_button: 'Unirse',
  recommended_contacts_separator: 'No en este canal ',
  imported_contacts_separator: 'Invite a su equipo',
  invite_add_button: 'Invite y agregue',
  add_button: 'Agregar',
  invite_mode: 'Invitar',
  invited_mode: 'Invitaci\u00f3n enviada',
  // frequent: 'frecuente',
  // recent: 'reciente',
  // tab: 'tab',
};
