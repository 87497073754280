/* eslint-disable no-return-assign */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getUnreadCount } from '@/utils/ConversationUtils';
import Tippy from '@tippyjs/react';
import { Image } from '@/components/common';
import SortableItem from '@/components/widgets/sortable/SortableItem';
import commonStyles from '@/components/common/common.css';
import memoizeOne from 'memoize-one';
import cssStyles from './TeamButton.css';

function getFirstLetter(name) {
  return name.charAt(0).toUpperCase();
}

const FallbackLogo = React.memo(function TeamLetter({ name }) {
  return <div className={cssStyles.letter}>{getFirstLetter(name)}</div>;
});

const KeyboardShortcut = React.memo(function KeyboardShortcut({
  keyboardShortcut,
}) {
  if (keyboardShortcut) {
    return <div className={cssStyles.keyboardShortcut}>{keyboardShortcut}</div>;
  }

  return '';
});

const loaderPropsFactory = () => memoizeOne((name) => ({ name }));

const UnreadBadge = React.memo(function getUnreadBadge({
  unreadConversationCount,
  hasMention,
  mutedUnreadChatCount,
}) {
  if (unreadConversationCount === 0 && mutedUnreadChatCount === 0) {
    return null;
  }
  const badgeCounterClassName = classNames(cssStyles.teamButtonBadge, {
    [cssStyles.badgeWithCounter]: unreadConversationCount > 0,
    [cssStyles.badgeWithMentions]: hasMention,
    [cssStyles.badgeMuted]:
      unreadConversationCount === 0 && mutedUnreadChatCount > 0,
  });

  return (
    <div className={classNames(badgeCounterClassName)}>
      {unreadConversationCount > 0 &&
        getUnreadCount(unreadConversationCount, 99)}
    </div>
  );
});

/**
 * @class TeamButton
 * @classdesc Button for a sessionf
 *
 * @extends {Component}
 *
 * @param {object} props
 * @param {number} props.index Item index in the list
 * @param {string} props.id Session ID
 * @param {string} props.sessionInfo All session related data, eg. teamName etc.
 * @param {boolean} [props.isActive] If this session is the current active session
 * @param {number} [props.unreadConversationCount] Number of chats with unread messages for this session
 * @param {boolean} [props.hasMention] If this session has chats with unread mentioned messages
 * @param {string} [props.keyboardShortcut] This button's keyboard shortcut
 * @param {boolean} [props.disableTooltip] Disable this button's tooltip
 * @param {function} [props.onClick] Callback that handles this button's click
 */
class TeamButton extends Component {
  teamButton = React.createRef();

  static defaultProps = {
    disableContextMenu: false,
    isMenuTeamSwitcher: false,
  };

  state = {
    showLeaveTeam: false,
    contextMenuLeft: '0px',
    contextMenuTop: '0px',
    // Show the Team Character initially?
    showFirstCharacter: false,
  };

  onButtonMouseDown = (event) => {
    if (event.button !== 2) {
      const { onClick, id } = this.props;
      onClick(id, 'team_button');
    }
    event.stopPropagation();
    event.preventDefault();
    this.focusTeamButton();
  };

  onBlur = () => {
    this.setState(() => {
      return {
        showLeaveTeam: false,
      };
    });
  };

  onLeaveTeam = (event) => {
    const { onLeaveTeamClick, id } = this.props;
    onLeaveTeamClick(id, 'team_button');
    this.onBlur();
    event.stopPropagation();
    event.preventDefault();
  };

  onContextMenuClick = (event) => {
    const teamButton = this.teamButton.current;
    if (!teamButton) {
      return;
    }
    this.focusTeamButton();
    const teamButtonRect = teamButton.getBoundingClientRect();

    const topBannerHeight =
      document.querySelector('#promptsRoot').innerHTML === '' ? 0 : 90;

    this.setState(() => {
      return {
        showLeaveTeam: true,
        contextMenuLeft: teamButtonRect.left + teamButtonRect.width,
        contextMenuTop:
          (teamButtonRect.top + teamButtonRect.bottom - topBannerHeight - 35) /
          2,
      };
    });
    event.stopPropagation();
    event.preventDefault();
  };

  getScrollBadgeClass() {
    const {
      mutedUnreadChatCount,
      hasMention,
      unreadConversationCount,
    } = this.props;

    if (hasMention) {
      return 'mentioned';
    }

    if (unreadConversationCount) {
      return 'unread';
    }

    if (mutedUnreadChatCount > 0) {
      return 'mutedUnread';
    }

    return '';
  }

  focusTeamButton() {
    const teamButton = this.teamButton.current;
    if (!teamButton) {
      return;
    }

    teamButton.focus();
  }

  onAvatarLoad = (isLoaded) => {
    this.setState({
      showFirstCharacter: !isLoaded,
    });
  };

  // If the image is hidden, show the first character
  changeLogoVisibility = (imageHidden) => {
    this.setState({
      showFirstCharacter: imageHidden,
    });
  };

  /** Returns a memoized object */
  getLoaderProps = loaderPropsFactory();

  render() {
    const {
      sessionInfo: {
        teamInfo: { teamName: name, teamAvatar: avatar },
      },
      index,
      isActive,
      disableTooltip,
      disableContextMenu,
      isMenuTeamSwitcher,
      unreadConversationCount,
      keyboardShortcut,
      hasMention,
      mutedUnreadChatCount,
    } = this.props;

    // Define button's classNames
    const teamButtonClassName = classNames(cssStyles.teamButton, {
      [cssStyles.isActive]: isActive,
      [cssStyles.app_menu_switcher]: isMenuTeamSwitcher,
    });

    // Tooltip options

    const {
      showLeaveTeam,
      contextMenuLeft: left,
      contextMenuTop: top,
      showFirstCharacter,
    } = this.state;
    const contextMenuStyle = {
      left: `${left - 5}px`,
      top: `${top}px`,
    };

    const contextMenuClassName = classNames(
      cssStyles.contextMenu,
      'leaveTeamContextMenu'
    );

    // Returns the dom to be rendered
    return (
      <SortableItem index={index}>
        <>
          <Tippy
            disabled={disableTooltip}
            content={name}
            placement='right'
            className={commonStyles.info_tooltip}
          >
            <div
              role='button'
              onKeyDown={() => {}}
              tabIndex={0}
              onMouseDown={this.onButtonMouseDown}
              status={this.getScrollBadgeClass()}
              onContextMenu={this.onContextMenuClick}
              onBlur={this.onBlur}
              ref={this.teamButton}
            >
              <div className={teamButtonClassName}>
                <div className={cssStyles.button}>
                  <UnreadBadge
                    unreadConversationCount={unreadConversationCount}
                    hasMention={hasMention}
                    mutedUnreadChatCount={mutedUnreadChatCount}
                  />
                  {avatar ? (
                    <Image
                      onLoad={this.onAvatarLoad}
                      src={avatar}
                      className={cssStyles.avatar}
                      Loader={FallbackLogo}
                      loaderProps={this.getLoaderProps(name)}
                    />
                  ) : (
                    <FallbackLogo name={name} />
                  )}
                  {showFirstCharacter ? <FallbackLogo name={name} /> : null}
                </div>
                <div className={cssStyles.activeTeam} />
                <KeyboardShortcut keyboardShortcut={keyboardShortcut} />
              </div>
            </div>
          </Tippy>
          {showLeaveTeam && !disableContextMenu ? (
            <div
              role='button'
              onKeyDown={() => {}}
              tabIndex={0}
              style={contextMenuStyle}
              className={contextMenuClassName}
              onMouseDown={this.onLeaveTeam}
            >
              Leave Team
            </div>
          ) : null}
        </>
      </SortableItem>
    );
  }
}

TeamButton.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  sessionInfo: PropTypes.object.isRequired,
  isActive: PropTypes.bool,
  unreadConversationCount: PropTypes.number,
  hasMention: PropTypes.bool,
  keyboardShortcut: PropTypes.string,
  disableTooltip: PropTypes.bool,
  disableContextMenu: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onLeaveTeamClick: PropTypes.func.isRequired,
  isMenuTeamSwitcher: PropTypes.bool,
};

TeamButton.defaultProps = {
  unreadConversationCount: 0,
  hasMention: false,
  keyboardShortcut: '',
  isActive: false,
  disableTooltip: false,
};

export default TeamButton;
