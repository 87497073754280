import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import currentSessionHOC from '@/connectHOCs/Sessions/currentSession';
import Button from '@/components/common/Button';
import bridge from '@/utils/bridge';
import layoutOuterLeftCollapsedHOC from '@/connectHOCs/layoutOuterLeftCollapsed';
import IntlWrapper from '@/Wrappers/IntlWrapper';
import { hideModal } from '@/components/Modal/Modal';
import { openAdminPanelManageSessions } from '@/utils/ShellUtils';
import modalStyles from '@/components/Modal/Modal.css';
import styles from './ResourceLimitExceededModal.css';
import translations from './I18n';

type Props = {
  currentSession: Session;
};

type State = {
  processing: boolean;
};

type Session = {
  state: string;
  hasResourceLimitExceeded: boolean;
};

class ResourceLimitExceededModal extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      processing: false,
    };
  }

  componentDidMount() {
    this.hasResourceLimitBeenExceeded();
  }

  componentDidUpdate() {
    this.hasResourceLimitBeenExceeded();
  }

  hasResourceLimitBeenExceeded = () => {
    const { currentSession } = this.props;
    const { hasResourceLimitExceeded } = currentSession;
    const sessionState = currentSession.state;

    if (sessionState !== 'ACTIVE' && !!hasResourceLimitExceeded) {
      console.error('User has exceeded resource limit');
    } else {
      hideModal();
    }
  };

  setProcessingOff = () => {
    this.setState({
      processing: false,
    });
  };

  tryAgain() {
    this.setState(
      {
        processing: true,
      },
      () => {
        bridge.publish('retry-connection', ['resource_limit_exceeded']);
        setTimeout(() => {
          this.setProcessingOff();
        }, 5000);
      }
    );
  }

  render() {
    const { processing } = this.state;

    return (
      <div className={styles.resource_limit_exceeded_wrapper}>
        <div
          className={classNames(modalStyles.modal_title, styles.modal_title)}
        >
          <FormattedMessage id='heading' />
        </div>
        <div className={styles.modal_content}>
          <FormattedMessage id='subheading' />
        </div>

        <div className={styles.button_container}>
          {/* @ts-ignore */}
          <Button
            onClick={() => {
              this.tryAgain();
            }}
            className={classNames(
              {
                [styles.saving]: processing,
              },
              styles.try_again
            )}
            kind='secondary'
            disabled={processing}
          >
            <FormattedMessage id='try_again' />
          </Button>
          {/* @ts-ignore */}
          <Button onClick={openAdminPanelManageSessions}>
            <FormattedMessage id='manage_sessions' />
          </Button>
        </div>
      </div>
    );
  }
}

export default IntlWrapper(
  currentSessionHOC(layoutOuterLeftCollapsedHOC(ResourceLimitExceededModal)),
  translations
);
