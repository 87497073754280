import isFinite from 'lodash/isFinite';

function _round(value, places) {
  return +(+value).toFixed(places);
}

export default function prettyFileSize(bytes) {
  const _bytes = bytes * 1;
  if (!isFinite(_bytes)) {
    return '';
  }
  if (_bytes < 1024) {
    return `${_bytes} B`;
  }
  if (_bytes < 1024 * 1024) {
    return `${_round(_bytes / 1024, 2)} KB`;
  }
  return `${_round(_bytes / (1024 * 1024), 2)} MB`;
}
