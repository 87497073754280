import React, { Fragment } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import EmojiDecorator from '@/components/common/EmojiDecorator';
import TextMessageDecorator from '@/components/Conversation/Message/TextMessageDecorator';
import Image from '@/components/common/Image';
import defaultContactImage from '@/assets/icon-contact.svg';
import { getLoaderWithFallbackUrl } from '@/components/common/utils';
import cssStyles from './Avatar.css';
import badgeStyles from '../teamSwitcher/teamButton/TeamButton.css';

const Avatar = ({
  unreadConversationCount,
  hasMention,
  collapsed,
  currentSession: { owner },
}) => {
  const nameClassName = classNames(cssStyles.contact_avatar_name);
  const guestClassName = classNames(cssStyles.contact_avatar_guest);
  const statusClassName = classNames(cssStyles.contact_avatar_status);
  const badgeClassnames = classNames(
    badgeStyles.badgeWithCounter,
    badgeStyles.collapsed_contact_unread_badge,
    {
      [badgeStyles.badgeWithMentions]: hasMention,
    }
  );
  const MESSAGE_COUNT_LIMIT = 25;

  if (!owner) {
    return null;
  }
  return (
    <div className={classNames({ [cssStyles.app_menu_avatar]: !collapsed })}>
      <div className={cssStyles.contact_avatar}>
        <div className={cssStyles.contact_avatar_view}>
          <Image
            className={cssStyles.contact_avatar_picture}
            src={owner.imageUrl}
            alt=''
            Loader={getLoaderWithFallbackUrl(defaultContactImage)}
            fallbackUrl={defaultContactImage}
          />
          {collapsed && unreadConversationCount > 0 && (
            <span className={badgeClassnames}>
              {unreadConversationCount <= MESSAGE_COUNT_LIMIT
                ? unreadConversationCount
                : `${MESSAGE_COUNT_LIMIT}+`}
            </span>
          )}
        </div>
        {!collapsed && (
          <div className={cssStyles.contact_avatar_info}>
            <div className={cssStyles.contact_avatar_profile}>
              <div className={nameClassName}>
                <EmojiDecorator text={owner.name} />
              </div>
              <div className={guestClassName}>
                {owner.guest && (
                  <Fragment>
                    (<FormattedMessage id='guest' />)
                  </Fragment>
                )}
              </div>
            </div>
            <div className={statusClassName}>
              {owner.userStatus ? (
                TextMessageDecorator(owner.userStatus, undefined, undefined, [
                  'bold',
                  'italics',
                ])
              ) : (
                <FormattedMessage id='set_status' />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// class Avatar extends Component {
//   render() {
//     const {
//       unreadConversationCount,
//       hasMention,
//       collapsed,
//       currentSession: { owner },
//     } = this.props;
//     const nameClassName = classNames(cssStyles.contact_avatar_name);
//     const guestClassName = classNames(cssStyles.contact_avatar_guest);
//     const statusClassName = classNames(cssStyles.contact_avatar_status);
//     const badgeClassnames = classNames(
//       badgeStyles.badgeWithCounter,
//       badgeStyles.collapsed_contact_unread_badge,
//       {
//         [badgeStyles.badgeWithMentions]: hasMention,
//       }
//     );
//     const MESSAGE_COUNT_LIMIT = 25;
//
//     return (
//       <div className={classNames({ [cssStyles.app_menu_avatar]: !collapsed })}>
//         <div className={cssStyles.contact_avatar}>
//           <div className={cssStyles.contact_avatar_view}>
//             <img
//               className={cssStyles.contact_avatar_picture}
//               src={owner.imageUrl}
//               alt=''
//             />
//             {collapsed && unreadConversationCount > 0 && (
//               <span className={badgeClassnames}>
//                 {unreadConversationCount <= MESSAGE_COUNT_LIMIT
//                   ? unreadConversationCount
//                   : `${MESSAGE_COUNT_LIMIT}+`}
//               </span>
//             )}
//           </div>
//           {!collapsed && (
//             <div className={cssStyles.contact_avatar_info}>
//               <div className={cssStyles.contact_avatar_profile}>
//                 <div className={nameClassName}>{owner.name}</div>
//                 <div className={guestClassName}>
//                   {owner.guest && (
//                     <Fragment>
//                       (<FormattedMessage id='guest' />)
//                     </Fragment>
//                   )}
//                 </div>
//               </div>
//               <div className={statusClassName}>
//                 {owner.userStatus || <FormattedMessage id='set_status' />}
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//     );
//   }
// }

// Avatar.propTypes = {
//   currentSession: PropTypes.object.isRequired,
// };

export default Avatar;
