import React from "react";
import { observer } from "mobx-react-lite";
import IconClose from "../../assets/icon-close.svg";
import { useStore } from "../../store";
import { reaction } from "mobx";

export const SearchInput = observer(function SearchInput() {
  const store = useStore();
  const [hasFocus, setHasFocus] = React.useState(false);

  const inputRef = React.useRef();

  const onChange = React.useCallback(
    (e) => store.setSearchTerm(e.target.value),
    []
  );

  const onClear = React.useCallback(() => {
    store.setSearchTerm("");
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const onFocus = React.useCallback(() => setHasFocus(true));
  const onBlur = React.useCallback(() => setHasFocus(false));

  React.useEffect(() => {
    const dispose = reaction(
      () => store.lastReset,
      () => {
        inputRef.current?.focus();
      },
      {
        name: "setFocusOnStoreReset",
      }
    );

    return () => {
      dispose();
    };
  }, []);

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef.current]);

  if (store.disableSearch) {
    return null;
  }
  return (
    <div className="search-input-root">
      <input
        ref={inputRef}
        className="input"
        type="text"
        value={store.searchTerm}
        placeholder="Search emoji or stickers"
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {store.searchTerm && (
        <div className="clear" onClick={onClear}>
          <IconClose className="eup-clear-icon" />
        </div>
      )}

      <style jsx>{`
        .search-input-root {
          position: relative;
          display: flex;
        }
        .input {
          display: flex;
          width: 100%;
          padding: 0 30px 0 10px;
          border: 1px solid;
          border-radius: 4px;
          font-size: 14px;
          outline: none;
        }
        .clear {
          position: absolute;
          top: 10px;
          bottom: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30px;
          cursor: pointer;
        }
      `}</style>
      <style jsx>{`
        .search-input-root {
          width: ${store.contentWidth}px;
          height: ${store.searchHeight}px;
          padding: 10px ${store.padding}px 6px;
        }
        .input {
          border-color: ${hasFocus ? store.colors.accent : store.colors.border};
          color: ${store.colors.primaryText};
          background-color: ${store.colors.primaryBg};
          transition: border-color 0.1s cubic-bezier(0, 0, 0.3, 1);
        }
        .input::-webkit-input-placeholder {
          color: ${store.colors.secondaryText};
        }
        .clear {
          right: ${store.padding}px;
        }
      `}</style>

      <style jsx global>{`
        .eup-clear-icon {
          fill: ${store.colors.secondaryText};
          transform: scale(0.9);
        }
      `}</style>
    </div>
  );
});
