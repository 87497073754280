import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import modalStyles from '@/components/Modal/Modal.css';
import IntlWrapper from '@/Wrappers/IntlWrapper';
import platform from '@/utils/platform';
import cssStyles from './KeyboardShortcutsModalComponent.css';
import translations from './I18n';

class KeyboardShortcutsModalComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newChatKey: 'N',
      ctrlCmdKey: 'Ctrl',
    };
  }

  componentDidMount() {
    this.platformAgnosticStringForKey();
    this.getNewChatKey();
  }

  platformAgnosticStringForKey = () => {
    const isMac =
      navigator.platform && navigator.platform.indexOf('Mac') !== -1;
    if (isMac) {
      this.setState({
        ctrlCmdKey: String.fromCharCode(0x2318),
      });
    }
  };

  getNewChatKey = () => {
    platform.getAll().then((platformProps) => {
      if (
        ['BROWSER', 'CHROME-EXTENSION'].includes(platformProps.currentPlatform)
      ) {
        this.setState({
          newChatKey: 'K',
        });
      }
    });
  };

  render() {
    const { newChatKey, ctrlCmdKey } = this.state;

    return (
      <div className={cssStyles.keyboard_shortcuts_modal}>
        <div className={modalStyles.modal_title}>
          <FormattedMessage id='keyboard_shortcuts' />
        </div>
        <div className={modalStyles.modal_subtitle} />

        <div className={cssStyles.shortcut_row}>
          <div className={cssStyles.shortcut_key}>
            {ctrlCmdKey}+{newChatKey}
          </div>
          <div className={cssStyles.shortcut_description}>
            <FormattedMessage id='new_chat_shortcut_helper' />
          </div>
        </div>

        <div className={cssStyles.shortcut_row}>
          <div className={cssStyles.shortcut_key}>{ctrlCmdKey}+F</div>
          <div className={cssStyles.shortcut_description}>
            <FormattedMessage id='search_conversations' />
          </div>
        </div>

        <div className={cssStyles.shortcut_row}>
          <div className={cssStyles.shortcut_key}>Alt+&#x2191;</div>
          <div className={cssStyles.shortcut_description}>
            <FormattedMessage id='cycle_through_chats_up' />
          </div>
        </div>

        <div className={cssStyles.shortcut_row}>
          <div className={cssStyles.shortcut_key}>Alt+&#x2193;</div>
          <div className={cssStyles.shortcut_description}>
            <FormattedMessage id='cycle_through_chats_down' />
          </div>
        </div>

        <div className={cssStyles.shortcut_row}>
          <div className={cssStyles.shortcut_key}>{ctrlCmdKey}+D</div>
          <div className={cssStyles.shortcut_description}>
            <FormattedMessage id='open_recent_contacts' />
          </div>
        </div>

        <div className={cssStyles.shortcut_row}>
          <div className={cssStyles.shortcut_key}>{ctrlCmdKey}+.</div>
          <div className={cssStyles.shortcut_description}>
            <FormattedMessage id='toggle_sidebar' />
          </div>
        </div>

        <div className={cssStyles.shortcut_row}>
          <div className={cssStyles.shortcut_key}>Esc</div>
          <div className={cssStyles.shortcut_description}>
            <FormattedMessage id='close_current_chat_tab' />
          </div>
        </div>

        <div className={cssStyles.shortcut_row}>
          <div className={cssStyles.shortcut_key}>Shift+Esc</div>
          <div className={cssStyles.shortcut_description}>
            <FormattedMessage id='reopen_last_tab' />
          </div>
        </div>

        <div className={cssStyles.shortcut_row}>
          <div className={cssStyles.shortcut_key}>{ctrlCmdKey}+E</div>
          <div className={cssStyles.shortcut_description}>
            <FormattedMessage id='move_focus_to_editor' />
          </div>
        </div>

        <div
          className={classNames(cssStyles.shortcut_row, cssStyles.no_border)}
        >
          <div className={cssStyles.shortcut_key}>{ctrlCmdKey}+/</div>
          <div className={cssStyles.shortcut_description}>
            <FormattedMessage id='show_this_list' />
          </div>
        </div>
      </div>
    );
  }
}

export default IntlWrapper(KeyboardShortcutsModalComponent, translations);
