import React from 'react';
import { compose } from 'redux';
import { Image } from '@/components/common';
import ContactHOC from '@/subscribeHOCs/contact';
import EmojiDecorator from '@/components/common/EmojiDecorator';
import { getSessionId } from '@/utils/SessionUtils';
import { formatDate } from '@/utils/datetime';
import styles from './SingleMessage.css';
import { MessageText } from '../Message';

function SingleMessage({ message, ownerGuid, contact }) {
  const { text, notification, sender, sentAsApp, sentTime } = message;
  const displayName = sentAsApp?.name || (contact || sender)?.chatName;
  const imgSrc = sentAsApp?.profileImage || (contact || sender)?.imageUrl;
  const currentSessionId = getSessionId(ownerGuid);
  return (
    <div className={styles.feedBlock}>
      <div className={styles.displayPic}>
        <Image src={imgSrc} />
      </div>
      <div className={styles.chatDescription}>
        <div className={styles.channelFeedDetails}>
          <div className={styles.channelFeedSenderName}>
            <EmojiDecorator text={displayName} />
          </div>
          <div className={styles.channelFeedSenderMessage}>
            {text ? (
              <MessageText
                message={message}
                currentSessionId={currentSessionId}
              />
            ) : (
              notification
            )}
          </div>
        </div>
        <div className={styles.timestamp}>
          {formatDate(new Date(sentTime), 'h:mm a')}
        </div>
      </div>
    </div>
  );
}

export default compose(React.memo, ContactHOC)(SingleMessage);
