import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return {
    outerLeftCollapsed: state.restricted.shell.layout.outerLeft.collapsed,
  };
};

export default function (Component) {
  return connect(mapStateToProps)(Component);
}
