import bridge from '@/utils/bridge';
import store from '@/store';
import { Actions } from '@/actions/restricted';
import { getDelta, getSubscriptionUpdates, getCounter } from './utilities';

// const dispatch = getQueuedDispatch(store.dispatch, 'Session proxy', 10, 20);
const { dispatch } = store;

function getSession(id) {
  return bridge.ask('TeamSessionRegistry', 'getSessionById', [id]);
}

function getSessions(...ids) {
  return Promise.all(ids.map((id) => getSession(id)));
}

async function updateState(addedIds, removedIds, didChange) {
  const addedSessions = await getSessions(...addedIds);

  if (didChange) {
    dispatch(
      Actions.session_proxy({ addedSessions, removedSessionIds: removedIds })
    );
  }
}

class SessionProxy {
  subscribedIds = {};

  subSets = {};

  counter = getCounter();

  constructor() {
    bridge.subscribe('/hydra/session/updated', this.onSessionUpdate);
  }

  getSubSets() {
    return this.subSets;
  }

  setSubSets(setId, value) {
    this.subSets[setId] = value;
  }

  getSubscribedIds() {
    return this.subscribedIds;
  }

  setSubscribedIds(value) {
    this.subscribedIds = value;
  }

  subscribe(Ids) {
    const setId = `${this.counter.next().value}`;
    this._subscribe(setId, Ids);

    return {
      unsubscribe: this.unsubscribe.bind(this, setId),
      update: (_Ids) => this._subscribe(setId, _Ids),
    };
  }

  _subscribe(setId, Ids) {
    const subscribedIds = this.getSubscribedIds();
    const subSets = this.getSubSets();

    const delta = getDelta(subSets[setId] || [], Ids || []);
    const {
      addedIds,
      removedIds,
      subscribedIds: _subscribedIds,
      didChange,
    } = getSubscriptionUpdates(subscribedIds, delta);

    this.setSubSets(setId, [...Ids]);
    this.setSubscribedIds(_subscribedIds);

    updateState(addedIds, removedIds, didChange);

    return true;
  }

  unsubscribe(setId) {
    if (!setId) {
      return false;
    }
    const subscribedIds = this.getSubscribedIds();
    const subSets = this.getSubSets();

    const delta = getDelta(subSets[setId], []);
    const {
      addedIds,
      removedIds,
      subscribedIds: _subscribedIds,
      didChange,
    } = getSubscriptionUpdates(subscribedIds, delta);

    this.setSubSets(setId, false);
    this.setSubscribedIds(_subscribedIds);

    updateState(addedIds, removedIds, didChange);
    return true;
  }

  onSessionUpdate = (session) => {
    const { id } = session;
    if (!id) {
      return;
    }
    const subscridedIds = this.getSubscribedIds();
    if (!subscridedIds[id]) {
      return;
    }
    dispatch(Actions.session_proxy({ addedSessions: [session] }));
  };
}

export default new SessionProxy();
