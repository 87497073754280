/* eslint-disable no-nested-ternary */
/* eslint-disable no-lonely-if */
import React, { PureComponent } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Tippy from '@tippyjs/react';
import { openInviteContactScreen } from '@/utils/ShellUtils';
// import { getTeamInfo } from '@/utils/SessionUtils';
import AvatarContainer from '@/containers/AvatarContainer';
import currentSessionHOC from '@/connectHOCs/Sessions/currentSession';
import IPWhitelistedModalComponent from '@/components/Modal/IPWhitelistedErrorModal/IPWhitelistedModalComponent';
import cssStyles from './AppMenu.css';
import commonStyles from '../common/common.css';
import MenuDropdown from './MenuDropdown';
import IntlWrapper from '../../Wrappers/IntlWrapper';
import translations from './I18N';
import UpgradeToProHook from '../upgradeProHook/UpgradeToPro';

class AppMenu extends PureComponent {
  static defaultProps = {
    currentSession: undefined,
    shellLayout: { outerLeft: false },
  };

  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
    };
    this.onDropdownBlur = this.onDropdownBlur.bind(this);
    this.dropdownWrapper = React.createRef();
  }

  onInviteClick = () => {
    openInviteContactScreen('top_left_invite');
  };

  onDropdownBlur() {
    this.setState({
      dropdownOpen: false,
    });
  }

  toggle() {
    this.setState((prevState) => ({ dropdownOpen: !prevState.dropdownOpen }));
  }

  render() {
    const { dropdownOpen } = this.state;
    const { shellLayout, currentSession, currentSessionId } = this.props;
    const { collapsed } = shellLayout.outerLeft;
    const appMenu = collapsed ? 'app_menu_collapsed' : 'app_menu';
    const appMenuBody = collapsed ? 'app_menu_body_collapsed' : 'app_menu_body';
    const inviteClassName = cssStyles.app_menu_invite;
    if (currentSession) {
      const { teamName } = currentSession.sessionInfo.teamInfo;
      // const showUpgradeHookAboveTeamName =
      //   currentSession.owner.isAdmin &&
      //   !collapsed;
      return (
        <div className={cssStyles[appMenu]} role='menuitem' tabIndex={0}>
          {!collapsed && (
            <UpgradeToProHook source='above_team_name' session={currentSession}>
              {({
                shouldRender,
                onClick,
                isPaidTeam,
                showTrialProHook,
                showUpgradeProHook,
                isAdmin,
                isMacAppStore,
              }) => {
                if (isMacAppStore || !isAdmin || !shouldRender || isPaidTeam) {
                  return null;
                }
                return (
                  <div
                    role='menuitem'
                    onKeyDown={() => {}}
                    tabIndex={0}
                    className={classNames(
                      cssStyles.upgrade_to_pro_hook,
                      cssStyles.team_name_upgrade_hook
                    )}
                    onClick={onClick}
                  >
                    {showTrialProHook && (
                      <Tippy
                        className={commonStyles.info_tooltip}
                        content='Start a free PRO trial for your team'
                        arrow
                        placement='bottom'
                      >
                        <span data-pendo-hook='try-pro-tl'>
                          {' '}
                          Try PRO for free
                        </span>
                      </Tippy>
                    )}

                    {showUpgradeProHook && (
                      <Tippy
                        className={commonStyles.info_tooltip}
                        content='Upgrade your team to the PRO plan'
                        arrow
                        placement='bottom'
                      >
                        <span>Upgrade to Flock PRO</span>
                      </Tippy>
                    )}
                  </div>
                );
              }}
            </UpgradeToProHook>
          )}
          {!collapsed && (
            <div className={cssStyles.app_menu_header}>
              <div className={cssStyles.app_menu_team}>
                <div className={cssStyles.app_menu_team_name}>{teamName}</div>
              </div>
              <div
                role='menuitem'
                onKeyDown={() => {}}
                tabIndex={0}
                className={inviteClassName}
                onClick={() => this.onInviteClick()}
                data-pendo-hook='menu-invite'
              >
                <FormattedMessage id='invite' />
              </div>
            </div>
          )}
          <div
            role='menuitem'
            onKeyDown={() => {}}
            tabIndex={0}
            className={cssStyles[appMenuBody]}
            onClick={() => this.toggle()}
          >
            {dropdownOpen && (
              <MenuDropdown
                currentSessionId={currentSessionId}
                shellLayout={shellLayout}
                onBlur={this.onDropdownBlur}
              />
            )}
            <AvatarContainer shellLayout={shellLayout} />
            {!collapsed && (
              <Tippy
                className={commonStyles.info_tooltip}
                content='Update your settings and more'
                arrow
                placement='bottom'
              >
                <div
                  data-pendo-hook='settings'
                  className={cssStyles.app_menu_icon}
                />
              </Tippy>
            )}
          </div>
          <IPWhitelistedModalComponent />
        </div>
      );
    }
    return null;
  }
}

AppMenu.propTypes = {
  currentSession: PropTypes.object,
  shellLayout: PropTypes.object,
};

export default currentSessionHOC(IntlWrapper(AppMenu, translations));
