/* eslint-disable import/prefer-default-export */

export function getImageBlobFromCanvas(canvas) {
  // .toBlob() is not supported in Chrome <= 49. So, we have to
  // do it this way.
  const parts = canvas
    .toDataURL('image/png')
    .match(/data:([^;]*)(;base64)?,([0-9A-Za-z+/]+)/);

  const binStr = atob(parts[3]);

  const buf = new ArrayBuffer(binStr.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < view.length; i += 1) {
    view[i] = binStr.charCodeAt(i);
  }

  return new Blob([view], {
    type: parts[1],
  });
}
