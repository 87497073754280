function getContacts(buckets) {
  const contacts = buckets.reduce(
    (acc, bucket) => acc.concat(bucket.contacts),
    []
  );
  return contacts;
}

/**
 *
 * @param {[{id:string, contacts:array},...]} buckets
 *
 * @returns {[{ id: string, start: number, length: number}]}
 */

function getIndices(buckets) {
  let current = 0;
  const val = buckets.map((b) => {
    const { length } = b.contacts;
    const start = current;
    current += length;
    return { start, id: b.id, length };
  });
  return val;
}

function removeSelectedContacts(buckets, selectedContacts) {
  if (!selectedContacts.length) {
    return buckets;
  }
  return buckets.map((bucket) => {
    return {
      ...bucket,
      contacts: bucket.contacts.filter(
        (c) =>
          !selectedContacts.find((sc) => sc === c) &&
          c.indexOf('groups.go.to') === -1 &&
          c[0] !== 'B'
      ),
    };
  });
}

/**
 *
 * @param { id: '', contacts: array} buckets
 * @param {array} selectedContacts , contacts which are to be ignored in the list
 *
 * @returns { bucketIndices: [{bucketIndices}], contacts: array}
 */
function getBucketedList(buckets, selectedContacts) {
  const _buckets = removeSelectedContacts(buckets, selectedContacts);
  const val = {
    bucketIndices: getIndices(_buckets),
    contacts: getContacts(_buckets),
  };
  return val;
}

function getBucketsFromBucketedList(bucketedList) {
  const { bucketIndices, contacts } = bucketedList;
  const buckets = bucketIndices.map((indexedBucket) => {
    const { start, length, id } = indexedBucket;
    return {
      id,
      contacts: contacts.slice(start, start + length),
    };
  });

  return buckets;
}

function getBucketFromBucketedList(bucketedList, bucketId) {
  const buckets = getBucketsFromBucketedList(bucketedList);
  return buckets.find((b) => b.id === bucketId);
}

/**
 *
 * @param {object} bucketedList, list on which operation has to be performed
 * @param {object} bucket , bucket which needs to added
 * @param {*} position , position at which bucket has to be pushed, should be index.
 *
 * @returns {object} modifiedBucketedList
 *
 * TODO: this function creates a new bucketedList. Memory can be optimised by modifying inplace.
 */
function addBucket(bucketedList, bucket, position) {
  const buckets = getBucketsFromBucketedList(bucketedList);
  buckets.splice(position, 0, bucket);

  return getBucketedList(buckets, []);
}

export default getBucketedList;

export { addBucket, getBucketFromBucketedList };

/**
 *
 * TODO:
 * Add functions for the following cases:
 *
 * 1. add extra buckets to the existing one.
 * 2. merge buckets.
 */

//  function isValidBucketedList(){

//  }
