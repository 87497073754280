export default {
  contact_support: 'Contactar a soporte',
  keyboard_shortcuts: 'Atajos del teclado',
  audio_alerts: 'Alertas de audio',
  popup_notifications: 'Notificaciones emergentes',
  video_ringer: 'Video call ringer',
  quit_flock: 'Salir de Flock',
  report_bug: 'Reportar un error',
  set_status: 'Establecer un estado...',
  manage_apps: 'Administrar sus aplicaciones',
  notification_preferences: 'Preferencias de notificaciones',
  add_team: 'A\u00f1adir un nuevo equipo',
  language_settings: 'Ajustes de idioma',
  language_text:
    'El idioma seleccionado se usar\u00e1 en todas las comunicaciones',
  language_change_note:
    'Nota: Si cambia el idioma se reflejar\u00e1 en sus clientes de web y de escritorio.',
  invite: '+ Invitar',
  invite_description: 'Invite miembros a su equipo',
  browse_apps: 'Navegar por las aplicaciones',
  manage_team: 'Administrar su equipo',
  stop_notifications: 'Dejar de recibir notificaciones',
};
