// TODO: Move and reuse from lib/avatar
import bridge from '@/utils/bridge';
import { getUserTokenFromSession } from '@/utils/auth';
import { getDeviceInfo } from '@/utils/user-agent';

async function getAvatarUploadUrl(guid: string, sessionId: string) {
  const token = await getUserTokenFromSession(sessionId);
  const fileShareUrl = await bridge.ask('HydraServer', 'getStartupOption', [
    'fileSharingUrl',
  ]);

  return `${fileShareUrl}/profile_image?token=${token}&guid=${guid}`;
}

export default async function updateProfileImage(
  file: Blob,
  sessionId: string,
  guid: string
) {
  const deviceInfo = await getDeviceInfo();
  const formData = new FormData();
  formData.append('file', file);

  const url = await getAvatarUploadUrl(guid, sessionId);

  return fetch(url, {
    method: 'POST',
    headers: {
      'X-UA-Info': deviceInfo,
    },
    body: formData,
  }).catch(async ({ status, body }) => {
    const responseText = await body.text();
    const error = new Error();

    if (status === 410) {
      error.name =
        'You need the latest version of Flock to perform this action';
    } else {
      error.name = 'GENERIC_ERROR';
      error.message = responseText;
    }

    throw error;
  });
}
