export default {
  contact_support: 'Help & Feedback',
  keyboard_shortcuts: 'Keyboard Shortcuts',
  audio_alerts: 'Audio alerts',
  popup_notifications: 'Popup notifications',
  video_ringer: 'Video call ringer',
  quit_flock: 'Quit Flock',
  report_bug: 'Report a bug',
  set_status: 'Set a status...',
  manage_apps: 'Manage your apps',
  notification_preferences: 'Notification Preferences',
  add_team: 'Add new team',
  language_settings: 'Language Settings',
  language_text: 'Selected language is used for all communication',
  language_change_note:
    'Note: Changing language will reflect on your web and desktop clients.',
  invite: '+ Invite',
  invite_description: 'Invite members to your team',
  browse_apps: 'Browse apps',
  manage_team: 'Team Administration',
  stop_notifications: 'Stop receiving notifications',
  user_online_notifications: 'User online notifications',
  refer_someone: 'Like Flock? Spread the word! \u2665',
  edit_profile: 'Edit profile details',
  edit_status: 'Edit status',
  account_preference: 'Profile and Account',
  email_preferences: 'Email Preferences',
  sound_preference: 'Notification Sound',
  upgrade_team_to_pro: 'Upgrade your team to PRO',
  request_admin_to_upgrade: 'Request admin to upgrade your team to PRO',
  dark_theme: 'Dark Theme',
  beta: 'beta',
  guest: 'Guest',
};
