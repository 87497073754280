/* eslint-disable import/prefer-default-export */
import boldSvg from '@/assets/bold.svg';
import italicsSvg from '@/assets/italics.svg';
import underlineSvg from '@/assets/underline.svg';
import strikethroughSvg from '@/assets/strikethrough.svg';
import fontColorSvg from '@/assets/Text_Color_multi.svg';
import linkSvg from '@/assets/link.svg';
import openLinkSvg from '@/assets/link-open.svg';
import editLinkSvg from '@/assets/link-edit.svg';
import removeLinkSvg from '@/assets/unlink.svg';
import deleteIconSvg from '@/assets/icon-color-remove.svg';

const replaceIcons = (FroalaEditor) => {
  FroalaEditor.DefineIcon('bold', {
    SRC: boldSvg,
    template: 'image',
  });
  FroalaEditor.DefineIcon('italic', {
    SRC: italicsSvg,
    template: 'image',
  });
  FroalaEditor.DefineIcon('underline', {
    SRC: underlineSvg,
    template: 'image',
  });
  FroalaEditor.DefineIcon('strikeThrough', {
    SRC: strikethroughSvg,
    template: 'image',
  });
  FroalaEditor.DefineIcon('textColor', {
    SRC: fontColorSvg,
    template: 'image',
  });
  FroalaEditor.DefineIcon('insertLink', {
    SRC: linkSvg,
    template: 'image',
  });
  FroalaEditor.DefineIcon('linkOpen', {
    SRC: openLinkSvg,
    template: 'image',
  });
  FroalaEditor.DefineIcon('linkEdit', {
    SRC: editLinkSvg,
    template: 'image',
  });
  FroalaEditor.DefineIcon('linkRemove', {
    SRC: removeLinkSvg,
    template: 'image',
  });
  FroalaEditor.DefineIcon('remove', {
    SRC: deleteIconSvg,
    template: 'image',
  });
};

export { replaceIcons };
