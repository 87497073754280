import React from 'react';
import { IntlProvider } from 'react-intl';
import LanguageContainer from '@/connectHOCs/Language';
import supportedLanguages from '@/utils/supportedLanguages';
import rootTranslations from '@/I18N';

// TODO: Follow locale code based instead of string based.
const defaultLocale = 'English-US';

function IntlWrapper<P>(
  Component: React.ComponentType<P>,
  localTranslations: object
): React.ComponentType<P> {
  const translations = localTranslations || rootTranslations;
  const defaultLanguage = supportedLanguages[defaultLocale];
  let messages = { ...translations[defaultLanguage.code] };
  return LanguageContainer((props) => {
    const { preferredLanguage, getLanguageFromHydra, ...newProps } = props;
    let currentLanguage = supportedLanguages[preferredLanguage];
    if (!currentLanguage) {
      currentLanguage = defaultLanguage;
    }
    getLanguageFromHydra(preferredLanguage);
    if (defaultLanguage !== currentLanguage) {
      messages = { ...messages, ...translations[currentLanguage.code] };
    }
    /*
        NOTE: react-intl uses browsers locale value and it's locale data for formats and stuff...
        But in our case we don't need extensive formats/functions from react-intl, for our locales.
        So keep locale prop as 'en', which is default and just change messages object when we want
        language to change.

        'key' prop is mandatory here for underlying components to re-render on messages object change.
        Although it's not advisable, there is no solution as of now, and react-intl's new version might
        have a solution for this.

    */
    return (
      <IntlProvider
        key={currentLanguage.code}
        locale='en'
        messages={messages}
        textComponent={React.Fragment}
      >
        <Component {...newProps} />
      </IntlProvider>
    );
  });
}

export default IntlWrapper;
