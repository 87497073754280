import React, { useState, useEffect, useCallback, useRef } from 'react';
import { compose } from 'redux';
import cx from 'classnames';
import { Picker } from 'emoji-util';
import Button from '@/components/common/Button';
import Loader from '@/components/common/Loader';
import Icon from '@/components/Icon';
import withTheme from '@/connectHOCs/Theme';
import withCurrentPeer from '@/subscribeHOCs/currentPeer';
import withCurrentSession from '@/connectHOCs/Sessions/currentSession';
import withStickers from '@/connectHOCs/withStickers';
import { converter, withEmojiPrefs } from '@/lib/emoji';
import {
  getNewAvatarImageFromFile,
  getNewAvatarImageFromEmoji,
  updateChannelAvatar,
  broadcastAvatarUpateMessage,
} from '@/lib/avatar';
import { logMedusaGenericEvent } from '@/utils/logAnalytics';
import commonCss from '@/components/common/common.css';
import ColorSwatch from './ColorSwatch';
import AvatarPreview from './AvatarPreview';
import css from './ChannelAvatarEditor.css';

const IMG_SIZE = 160;

const EmojiPicker = compose(
  withStickers,
  withTheme
)(function EmojiPicker({ currentTheme, ...props }) {
  return <Picker theme={currentTheme} {...props} />;
});

function getEmojiImage(emoji, skin) {
  const emojiWithSkin = converter.getEmojiWithSkin(emoji, skin);
  return converter.imagesBaseUrl + emojiWithSkin.image;
}

function getStickerUrl(sticker) {
  return converter.getStickerImage(sticker);
}

function isValidFile(file) {
  const supportedMimes = ['image/png', 'image/jpeg', 'image/svg+xml'];
  return file.type && supportedMimes.includes(file.type);
}

function ChannelAvatarEditor({
  currentPeer,
  skinTone,
  changeSkinTone,
  currentSessionId,
  onClose,
}) {
  const [previewType, setPreviewType] = useState('server');
  const [backgroundColor, setBackgroundColor] = useState('transparent');
  const [imgSrc, setImgSrc] = useState(null);
  const [imgBlob, setImgBlob] = useState(null);
  const [imgFile, setImgFile] = useState(null);
  const [selectedEmoji, setSelectedEmoji] = useState(null);
  const [selectedSticker, setSelectedSticker] = useState(null);
  const [previewLoading, setPreviewLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const fileInputRef = useRef(null);

  useEffect(() => {
    if (previewType === 'server') {
      setErrorMessage(null);
      setImgSrc(currentPeer.imageUrl);
      setPreviewLoading(false);
    }
  }, [previewType, currentPeer]);

  useEffect(() => {
    let cancelled = false;

    const setEmojiAsAvatar = async () => {
      const emojiImageUrl = getEmojiImage(selectedEmoji, skinTone);
      const {
        url: newAvatarUrl,
        blob: newAvatarBlob,
      } = await getNewAvatarImageFromEmoji(
        emojiImageUrl,
        backgroundColor,
        IMG_SIZE
      );

      if (!cancelled) {
        setImgSrc(newAvatarUrl);
        setImgBlob(newAvatarBlob);
        setPreviewLoading(false);
      }
    };

    if (previewType === 'emoji') {
      setErrorMessage(null);
      setPreviewLoading(true);
      setEmojiAsAvatar();
    }

    return () => {
      cancelled = true;
    };
  }, [selectedEmoji, skinTone, backgroundColor, previewType]);

  useEffect(() => {
    let cancelled = false;

    const setStickerAsAvatar = async () => {
      const stickerUrl = await getStickerUrl(selectedSticker);

      const {
        url: newAvatarUrl,
        blob: newAvatarBlob,
      } = await getNewAvatarImageFromEmoji(
        stickerUrl,
        backgroundColor,
        IMG_SIZE
      );

      if (!cancelled) {
        setImgSrc(newAvatarUrl);
        setImgBlob(newAvatarBlob);
        setPreviewLoading(false);
      }
    };

    if (previewType === 'sticker') {
      setErrorMessage(null);
      setPreviewLoading(true);
      setStickerAsAvatar();
    }

    return () => {
      cancelled = true;
    };
  }, [selectedSticker, backgroundColor, previewType]);

  useEffect(() => {
    let cancelled = false;

    const setFileFromInputAsAvatar = async () => {
      const {
        url: newAvatarUrl,
        blob: newAvatarBlob,
      } = await getNewAvatarImageFromFile(imgFile, IMG_SIZE);

      if (!cancelled) {
        setImgSrc(newAvatarUrl);
        setImgBlob(newAvatarBlob);
        setPreviewLoading(false);
      }
    };

    if (previewType === 'upload') {
      setErrorMessage(null);
      setPreviewLoading(true);
      setFileFromInputAsAvatar();
    }

    return () => {
      cancelled = true;
    };
  }, [imgFile, previewType]);

  const channelName = currentPeer.name;

  const onEmojiSelected = useCallback(async (emoji) => {
    setPreviewType('emoji');
    setSelectedEmoji(emoji);
  }, []);

  const changeBackgroundColor = useCallback((color) => {
    console.log('Color selected:', color);
    setBackgroundColor(color);
  }, []);

  const onStickerSelected = useCallback(async (sticker) => {
    setPreviewType('sticker');
    setSelectedSticker(sticker);
  }, []);

  const onFileInputChange = useCallback((event) => {
    const file = event.currentTarget.files[0];
    if (isValidFile(file)) {
      setPreviewType('upload');
      setImgFile(file);
    } else {
      setErrorMessage('Unsupported file type.');
    }
  }, []);

  const triggerInputFileClick = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, []);

  const onSave = useCallback(async () => {
    if (errorMessage) {
      return;
    }

    if (previewType !== 'server' && !errorMessage) {
      setSaving(true);
      try {
        await updateChannelAvatar(imgBlob, currentPeer, currentSessionId);

        if (currentPeer.type === 'group') {
          await broadcastAvatarUpateMessage();
        }

        logMedusaGenericEvent('channel_avatar_change_completed', {
          avatar_type: ['sticker', 'emoji'].includes(previewType)
            ? 'emoji'
            : 'external',
        });
      } catch (error) {
        console.error('Error occured while uploading new avatar:', error);
        setErrorMessage('Failed to update avatar. Try again.');
        return;
      } finally {
        setSaving(false);
      }
    }

    onClose();
  }, [
    currentPeer,
    currentSessionId,
    errorMessage,
    imgBlob,
    onClose,
    previewType,
  ]);

  return (
    <section className={css.Container}>
      <button
        onClick={onClose}
        className={cx([commonCss.btn__reset, css.CloseButton])}
        type='button'
      >
        <Icon className={css.CloseIcon} name='close' />
      </button>
      <header className={css.Header}>
        <h2 className={css.ModalTitle}>Update Channel Avatar</h2>
        <div className={css.ChannelName}>{channelName}</div>
      </header>
      <div className={css.Content}>
        <div className={css.PreviewWrapper}>
          <div className={css.AvatarPreview}>
            <AvatarPreview loading={previewLoading} src={imgSrc} />
          </div>
          {['emoji', 'sticker'].includes(previewType) && (
            <div className={css.ColorSwatch}>
              <ColorSwatch onColorChange={changeBackgroundColor} />
            </div>
          )}
        </div>
        <div className={css.ControlsWrapper}>
          <div className={css.UploadImageControlsWrapper}>
            <Button
              className={css.UploadButton}
              invert
              onClick={triggerInputFileClick}
            >
              Upload an image
            </Button>
            <input
              type='file'
              ref={fileInputRef}
              className={css.FileInput}
              onChange={onFileInputChange}
            />
            {!!errorMessage && (
              <span className={css.ErrorMessage}>{errorMessage}</span>
            )}
          </div>
          <span>OR</span>
          <div className={css.EmojiPickerHeaderText}>
            Pick an emoji as channel avatar
          </div>
          <div className={css.EmojiPickerWrapper}>
            <EmojiPicker
              onEmoji={onEmojiSelected}
              onSticker={onStickerSelected}
              skin={skinTone}
              onSkin={changeSkinTone}
            />
          </div>
        </div>
      </div>
      <div>
        <Button
          className={css.SaveButton}
          disabled={!!errorMessage || saving}
          onClick={onSave}
          size='large'
          fullWidth
        >
          {saving && <Loader loading absolute={false} />}
          <span>{saving ? 'Saving...' : 'Save'}</span>
        </Button>
      </div>
    </section>
  );
}

export default compose(
  withEmojiPrefs,
  withCurrentPeer,
  withCurrentSession
)(ChannelAvatarEditor);
