import React from 'react';

function HelpIcon({ borderClassName = '' }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='28'
      height='28'
      viewBox='0 0 28 28'
    >
      <g fill='none' fillRule='evenodd'>
        <rect width='28' height='28' rx='4' />
        <path
          className={borderClassName}
          fill='#E1E1E1'
          fillRule='nonzero'
          d='M14 7.28c3.711 0 6.72 3.009 6.72 6.72s-3.009 6.72-6.72 6.72S7.28 17.711 7.28 14 10.289 7.28 14 7.28M14 6c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8c0-2.122-.843-4.157-2.343-5.657S16.122 6 14 6z'
        />
        <path
          className={borderClassName}
          fill='#E1E1E1'
          fillRule='nonzero'
          d='M14.15 16.5c-.352-.003-.67.208-.807.533-.136.325-.063.7.185.95s.623.326.948.192c.326-.134.54-.451.54-.804 0-.48-.386-.87-.866-.872zm2.156-5.033c-.319-1.09-1.364-1.801-2.495-1.697-.987.065-1.826.744-2.096 1.696-.043.197-.076.396-.099.596h1.366c.016-.09.028-.176.048-.257.143-.53.72-.816 1.303-.648.472.128.76.605.653 1.083-.056.26-.169.506-.332.717-.306.396-.708.708-1.17.904-.072.023-.118.094-.11.17v1.323c0 .041 0 .081.01.121h1.342v-.824c-.009-.085.038-.166.116-.201.586-.352 1.05-.875 1.328-1.5.225-.46.274-.988.136-1.483z'
        />
      </g>
    </svg>
  );
}

export default HelpIcon;
