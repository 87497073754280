import { PureComponent } from 'react';
import currentSessionHOC from '@/connectHOCs/Sessions/currentSession';
import withTeamInfo from '@/connectHOCs/Sessions/currentSession/teamInfo';
import withCurrentOwner from '@/subscribeHOCs/owner';
import appsListSubscriber from '@/subscribeHOCs/appList';
import {
  CurrentSession,
  Owner,
  Command,
} from '@/components/ConversationEditor/Types';
import {
  commonCommands as _commonCommands,
  channelCommands as _channelCommands,
  videoRecording,
} from './Commands';
import { getSlashCommandsForDikeApps } from '../../../utils';

/**
 * Maintain and manage slash commands
 */
const category = 'slashCommand';

type Props = {
  currentSession: CurrentSession;
  owner: Owner;
  appList: any;
  teamInfo: any;
  isVideoRecordingEnabled: boolean;
  children: any;
} & typeof SlashCommandManager.defaultProps;

type State = {
  commonCommands: any;
  channelCommands: any;
  dikeCommands: any;
};

class SlashCommandManager extends PureComponent<Props, State> {
  static defaultProps = {
    currentSession: null,
    owner: null,
    appList: [],
    teamInfo: null,
  };

  state = {
    commonCommands: _commonCommands,
    channelCommands: _channelCommands,
    dikeCommands: [],
  };

  componentDidMount() {
    this.setCommandsForSession();
  }

  componentDidUpdate(prevProps: Props) {
    const {
      currentSession: prevCurrentSession,
      appList: prevAppList,
    } = prevProps;
    const { currentSession, appList } = this.props;

    if (currentSession !== prevCurrentSession || appList !== prevAppList) {
      this.setCommandsForSession();
    }
  }

  setCommandsForSession(props?: Props) {
    const { currentSession, appList } = this.props || props;
    getSlashCommandsForDikeApps(appList, currentSession).then(
      (dikeCommands = []) => {
        this.setState({
          dikeCommands,
        });
      }
    );
  }

  getAllCommands = (withChannelCommands?: boolean) => {
    const { commonCommands, channelCommands, dikeCommands } = this.state;
    const {
      isVideoRecordingEnabled,
      teamInfo: { isDMBlocked } = {},
      owner: { isAdmin } = {},
    } = this.props;

    const allCommands: Command[] = [...commonCommands];
    if (withChannelCommands) {
      allCommands.push(...channelCommands);
    }
    allCommands.push(...dikeCommands);
    if (isVideoRecordingEnabled) {
      allCommands.push(videoRecording);
    }
    return allCommands.filter(
      (command) => !command.shouldIgnore(isDMBlocked, isAdmin)
    );
  };

  getMatchingCommands = (textContent, includeChannelCommands = true) => {
    const text = textContent.split(' ')[0];
    const commands = this.getAllCommands(includeChannelCommands);
    return commands.filter(({ name = '' }) => name.indexOf(text) > -1);
  };

  getCommandForText = (textContent, includeChannelCommands = true) => {
    const text = textContent.split(' ')[0];
    const commands = this.getAllCommands(includeChannelCommands);
    return commands.find(({ name = '' }) => name === text);
  };

  render() {
    const { children } = this.props;
    const commands = this.getAllCommands();
    return children({
      commands,
      getCommandForText: this.getCommandForText,
      getMatchingCommands: this.getMatchingCommands,
    });
  }
}

export default withTeamInfo(
  withCurrentOwner(
    currentSessionHOC(appsListSubscriber(SlashCommandManager, category))
  )
);
