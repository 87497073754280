import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import Tippy from '@tippyjs/react';
import AllGroupsProxy from '@/Proxies/AllGroupsProxy';
import allGroups from '@/connectHOCs/Contacts/allGroups';
import ContactList from '@/connectHOCs/Contacts/ContactList';
import withTeamInfo from '@/connectHOCs/Sessions/currentSession/teamInfo';
import withCurrentOwner from '@/connectHOCs/Sessions/currentSession/owner';
import { openCreateChannelScreen } from '@/utils/ShellUtils';
import noop from '@/utils/noop';
import ListContainer from './ListContainer';
import Group from '../components/roster/Group';
import cssStyles from '../components/roster/Contacts.css';
import commonStyles from '../components/common/common.css';

const LIMIT = 20;

class AllChannelsContainer extends React.Component {
  allGroupsProxySubscription = undefined;

  constructor(props) {
    super(props);
    this.state = {
      currentPrefix: '',
    };

    if (props.guid) {
      this.allGroupsProxySubscription = AllGroupsProxy.subscribe(props.guid);
    }
  }

  componentDidUpdate(prevProps) {
    const { guid } = this.props;
    const { guid: prevGuid } = prevProps;
    if (guid !== prevGuid) {
      if (this.allGroupsProxySubscription) {
        this.allGroupsProxySubscription.unsubscribe();
      }
      AllGroupsProxy.subscribe(guid);
    }
  }

  componentWillUnmount() {
    if (this.allGroupsProxySubscription) {
      this.allGroupsProxySubscription.unsubscribe();
    }
  }

  createChannel = (gaSourceName) => {
    const { currentSession } = this.props;
    openCreateChannelScreen(currentSession, gaSourceName);
  };

  onPlusButtonClick = () => {
    this.createChannel('sidebar_channels_plus');
  };

  onEmptyStateCreateChannelClick = () => {
    this.createChannel('sidebar_channels_empty_state');
  };

  render() {
    const { intl, owner, teamInfo } = this.props;
    const { currentPrefix, contactPrefix } = this.state;
    let { contacts: allContacts } = this.props;
    // let {allContacts} = this.props;
    const prefix = currentPrefix;

    const { isDMBlocked } = teamInfo;
    const { isAdmin: isOwnerAdmin } = owner;

    allContacts = allContacts || [];
    // ensure that the contacts are matching the prefix of the search term mentioned
    allContacts = allContacts.filter(
      (contact) =>
        contact.name.toLowerCase().indexOf(prefix.toLowerCase()) === 0
    );
    // might be optimised wrt the original flatlist implementation in hydra(in dojo)
    allContacts.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    return (
      <div>
        <div className={cssStyles.roster_search_container}>
          <input
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            className={classNames(cssStyles.input, cssStyles.roster_search)}
            type='text'
            placeholder={intl.formatMessage({
              id: 'group_tab_search_placeholder',
            })}
            value={contactPrefix}
            onChange={(event) => {
              this.setState({ currentPrefix: event.target.value });
            }}
          />
          {isDMBlocked && !isOwnerAdmin ? null : (
            <Tippy
              className={commonStyles.info_tooltip}
              content='Create channel'
              arrow
              placement='bottom'
            >
              <div
                role='button'
                tabIndex={0}
                onKeyDown={noop}
                className={cssStyles.plus_icon}
                onClick={this.onPlusButtonClick}
              >
                <div />
              </div>
            </Tippy>
          )}
        </div>

        {allContacts.length > 0 && (
          <ListContainer
            items={allContacts}
            ContactComponent={Group}
            defaultLimit={LIMIT}
          />
        )}
        {allContacts.length === 0 && (
          <div className={cssStyles.no_results_found}>
            <FormattedMessage id='no_result_found' />
          </div>
        )}
        {isDMBlocked && !isOwnerAdmin ? null : (
          <div className={cssStyles.empty_roster_container}>
            <div
              className={classNames(
                cssStyles.empty_roster_image,
                cssStyles.empty_groups
              )}
            />
            <p>
              Channels are where all the magic happens in Flock! Create one for
              every project.
            </p>
            <button
              onKeyDown={noop}
              type='button'
              className={classNames(
                commonStyles.btn,
                commonStyles.btn__action,
                commonStyles.btn_block
              )}
              onClick={this.onEmptyStateCreateChannelClick}
            >
              <FormattedMessage id='create_group_button' />
            </button>
          </div>
        )}
      </div>
    );
  }
}

AllChannelsContainer.propTypes = {
  currentSession: PropTypes.object.isRequired,
  guid: PropTypes.string.isRequired,
  teamInfo: PropTypes.object.isRequired,
  owner: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

export default withCurrentOwner(
  withTeamInfo(
    allGroups(
      ContactList(injectIntl(AllChannelsContainer), 'allGroupsIds', 'guid'),
      'guid'
    )
  )
);
