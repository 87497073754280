import React from 'react';
import MessageLoader from '../MessageLoader';
import GroupChatHeader from './GroupChatHeader';
import SupportOrMeChatHeader from './SupportOrMeChatHeader';
import PersonalChatHeader from './PersonalChatHeader';

type Props = {
  canFetch: boolean;
  fetchStatus: fetchStatus;
  fetchMore: (force: boolean) => void;
  paywallHit: boolean;
  currentSession: Session;
  currentPeer: Contact;
};

const SUPPORT_JID = 'Blrrpp1s4ru9s4ss@go.to';

const ChatHeader = React.memo(function ChatHeader({
  canFetch,
  fetchStatus,
  fetchMore,
  paywallHit,
  currentSession,
  currentPeer,
}: Props) {
  if (canFetch || paywallHit) {
    return (
      <MessageLoader
        fetchStatus={fetchStatus}
        fetchMore={fetchMore}
        paywallHit={paywallHit}
        currentSession={currentSession}
      />
    );
  }
  if (currentPeer.type === 'group') {
    return (
      <GroupChatHeader
        currentPeer={currentPeer}
        currentSession={currentSession}
      />
    );
  }
  const isMeChat = currentPeer.jid === 'Botguidformechat@go.to';
  const isSupportChat = currentPeer.jid === SUPPORT_JID;
  if (isMeChat || isSupportChat) {
    return (
      <SupportOrMeChatHeader
        currentPeer={currentPeer}
        support={isSupportChat}
      />
    );
  }

  return <PersonalChatHeader currentPeer={currentPeer} />;
});

export default ChatHeader;
