import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import memoize from 'memoize-one';
import sessionsSubHOC from '@/subscribeHOCs/allSessions';
import SortableList from '@/components/widgets/sortable/SortableList';
import {
  currentSessionIdSelector,
  allSessionsSelector,
  currentSessionSelector,
} from '@/selectors';
import noop from '@/utils/noop';
import TeamButtonContainer from '@/containers/TeamButtonContainer';
import bridge from '@/utils/bridge';
import { SORTABLE_STORE as STORE_NAME } from '@/utils/storeNames';
import cssStyles from './TeamSwitcher.css';

const SESSION_ORDER_KEY = 'teamSwitcher';

/**
 * @class TeamSwitcher
 * @classdesc List of session buttons
 *
 * @extends {Component}
 *
 * @param {object} props
 * @param {object} props.sessions List of sessions
 * @param {string} [props.currentSessionId] Current active session ID
 * @param {boolean} [props.isHorizontal] Show horizontal list
 * @param {object} [props.theme] Global and container specific theme settings
 * @param {function} [props.onTeamSwitch] Callback that handles buttons clicks
 *
 * @param {object} state
 * @param {boolean} state.isSorting If user is sorting the list
 */
class AppMenuTeamSwitcher extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isSorting: false,
      orderedSessionIds: [],
      expandedState: false,
    };
  }

  componentDidMount() {
    bridge
      .ask('Storage', 'get', [STORE_NAME, SESSION_ORDER_KEY])
      .then((response) => {
        if (response && response.split) {
          this.setState({
            orderedSessionIds: response.split('|'),
          });
        }
      });
  }

  getOrderedSessions = memoize((sessions, orderedSessionIds) => {
    const orderedSessions = [];

    /* Push all sessions in the order */
    orderedSessionIds.forEach((sessionId) => {
      const session = sessions[sessionId];
      if (session) {
        orderedSessions.push(session);
      }
    });

    /* Push sessions that were not in the ordered list of sessionIds */
    Object.values(sessions).forEach((session) => {
      if (orderedSessionIds.indexOf(session.id) === -1) {
        orderedSessions.push(session);
      }
    });
    return orderedSessions;
  });

  toggleExpandTeamList() {
    this.setState((prevState) => ({ expandedState: !prevState.expandedState }));
  }

  render() {
    const { isSorting, expandedState, orderedSessionIds } = this.state;
    const {
      sessions,
      isHorizontal,
      currentSessionId,
      currentSession,
      onTeamSwitch,
      isMenuTeamSwitcher,
    } = this.props;

    const orderedSessions = this.getOrderedSessions(
      sessions,
      orderedSessionIds
    );

    const teamSwitcherClassName = classNames(cssStyles.teamSwitcher, {
      [cssStyles.isHorizontal]: isHorizontal,
    });

    const teamsListClasses = classNames(cssStyles.sortableList, {
      [cssStyles.expanded]: expandedState,
    });

    const toggleTeamsListViewClasses = classNames(
      cssStyles.horizontal_team_switcher_toggle,
      {
        [cssStyles.open]: expandedState,
      }
    );

    const otherSessions = orderedSessions.filter(
      (session) => currentSessionId !== session.id
    );

    return (
      <div className={teamSwitcherClassName}>
        <SortableList
          axis={isHorizontal ? 'xy' : 'y'}
          className={classNames(teamsListClasses, cssStyles.currentSessionTeam)}
          shouldCancelStart={() => {
            return true;
          }}
        >
          {[
            <TeamButtonContainer
              index={0}
              key={currentSession.id}
              onClick={onTeamSwitch}
              isActive
              ownerGuid={currentSession.owner.ownerGuid}
              disableTooltip={isSorting}
              disableContextMenu={isHorizontal}
              isMenuTeamSwitcher={isMenuTeamSwitcher}
              {...currentSession}
            />,
          ]}
        </SortableList>

        <SortableList
          axis={isHorizontal ? 'xy' : 'y'}
          className={classNames(teamsListClasses, cssStyles.otherTeams)}
          helperClass='buttonDragging'
          shouldCancelStart={() => {
            return true;
          }}
        >
          {otherSessions.map((session, index) => (
            <TeamButtonContainer
              index={index}
              key={session.id}
              onClick={onTeamSwitch}
              isActive={false}
              ownerGuid={session.owner.ownerGuid}
              disableTooltip={isSorting}
              disableContextMenu={isHorizontal}
              isMenuTeamSwitcher={isMenuTeamSwitcher}
              {...session}
            />
          ))}
        </SortableList>

        {isHorizontal && otherSessions.length > 4 && (
          <div
            role='button'
            onKeyDown={() => {}}
            tabIndex={0}
            className={toggleTeamsListViewClasses}
            onClick={() => this.toggleExpandTeamList()}
          />
        )}
      </div>
    );
  }
}

AppMenuTeamSwitcher.propTypes = {
  sessions: PropTypes.object.isRequired,
  currentSessionId: PropTypes.string,
  theme: PropTypes.object,
  isHorizontal: PropTypes.bool,
  isMenuTeamSwitcher: PropTypes.bool,
  onTeamSwitch: PropTypes.func,
};

AppMenuTeamSwitcher.defaultProps = {
  currentSessionId: '',
  theme: {},
  isHorizontal: false,
  isMenuTeamSwitcher: false,
  onTeamSwitch: noop,
};

const mapStateToProps = createStructuredSelector({
  currentSessionId: currentSessionIdSelector,
  currentSession: currentSessionSelector,
  sessions: allSessionsSelector,
});

export default connect(mapStateToProps)(sessionsSubHOC(AppMenuTeamSwitcher));
