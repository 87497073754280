import bridge from '@/utils/bridge';
import { publishGenericEvent } from '@/utils/SessionUtils';

export function openInviteContactScreen(gaSourceName) {
  bridge.tell('Shell', 'openBuddyAdderDialog', [gaSourceName]);
}

export function openCreateChannelScreen(session, gaSourceName) {
  bridge.tell('Shell', 'openChannelType', [session, gaSourceName]);
}

export function openChannelDiscoveryScreen(gaSourceName) {
  bridge.tell('Shell', 'openChannelDiscovery', [gaSourceName]);
}

export function getOpenGroupCount() {
  // TODO remove this method from ShellUtils.
  // SessionUtils has an equivalent function that accepts sessionId
  return bridge.ask('Shell', 'getOpenGroupCount');
}

export function toggleSidebarState() {
  return bridge.tell('Shell', 'toggleSidebarState');
}

export function showAboutUs() {
  bridge.tell('Shell', 'showAboutUs');
}

export function openAdminPanel() {
  bridge.tell('Shell', 'openAdminPanel');
}

export function openAdminPanelManageSessions() {
  bridge.tell('Shell', 'openAdminPanelManageSessions');
}

export function showReportBugPopup() {
  bridge.tell('Shell', 'showReportBugPopup');
}

export function showKeyboardShortcuts() {
  bridge.tell('Shell', 'showKeyboardShortcuts');
}

export function getCurrentConversation() {
  return bridge.ask('Shell', 'getCurrentConversation');
}

export function showAddNewTeam(accountEmail, teamId) {
  bridge.tell('Shell', 'showAddTeam', [accountEmail, teamId]);
}

export function showReferSomeoneModal() {
  bridge.tell('Shell', 'openReferAdderDialog', ['app_menu_item_invite']);
}

export function showWhatsNewUrl() {
  bridge.tell('Shell', 'showWhatsNewUrl', []);
}

/**
 * @typedef {Object} ImageResolution - The `attachment.views.(original | thumbnail)` object
 * @property {number} height - Height of the image
 * @property {number} width - Width of the image
 * @property {string} id - The ID for this image.
 * @property {string} src - The image origin + path
 */

/**
 * Don't let the name misguide you. This method returns you a signed URL for any attachment image, not just for downloads.
 * @param {ImageResolution} download - The image resolution object.
 * @param {string} sessionId - Current session ID for which image will be shown
 * @param {boolean|string} intent - Whether to initiate a download or not. Downloads file when true or 'download'
 */
export function getSignedDownloadUrl(download, sessionId, intent) {
  return bridge.ask('Shell', 'getSrcFromDownload', [
    download,
    sessionId,
    intent,
  ]);
}

export function exit() {
  bridge.tell('HydraServer', 'exit');
}

export function signout(email) {
  return bridge.ask('TeamSessionRegistry', 'signOutAccount', [email]);
}

export function openJoinPublicChannel(gaSourceName) {
  bridge.publish('odara-group-discovery-modal', [gaSourceName]);
}

export function doTwillioAppAction(
  sessionId,
  peer,
  roomName,
  enableRecording = false,
  startWithoutVideo = false
) {
  let chatGuid = peer.jid.split('@')[0];
  if (peer.isGroup) chatGuid = `g:${chatGuid}`;
  if (peer.isBuddy) chatGuid = `u:${chatGuid}`;

  const roomConfig = { roomName, enableRecording, startWithoutVideo };
  bridge.tell('HydraServer', 'handleTwilioCreateRoom', [
    sessionId,
    chatGuid,
    roomConfig,
  ]);
}

export function doAppAction(action, app, peer, sessionId, context, gaSource) {
  bridge.tell('Shell', 'doAppAction', [
    action,
    app,
    peer,
    sessionId,
    context,
    gaSource,
  ]);
}

export function acceptGDPR(token, guid) {
  return bridge
    .ask('ZeusAPI', 'acceptUserAgreement', [token, guid])
    .then((response) => {
      if (!response.isError) {
        const payloadForMedusa = {
          source: 'within_client',
        };
        publishGenericEvent('team_member_agreed_gdpr', payloadForMedusa);
      } else {
        console.error('There was an error accepting the GDPR Agreement');
      }
      return response;
    });
}

export function getFlockTermsAndPrivacyUrls() {
  const getFlockTerms = bridge.ask('HydraServer', 'getStartupOption', [
    'flockTerms',
  ]);
  const getFlockPrivacyPolicy = bridge.ask('HydraServer', 'getStartupOption', [
    'flockPrivacyPolicy',
  ]);

  return Promise.all([getFlockTerms, getFlockPrivacyPolicy]);
}

export function openSupportLink() {
  bridge.tell('Shell', 'openSupportLink');
}

export function openRequestFeatureLink() {
  bridge.tell('Shell', 'openRequestFeatureLink');
}

export function openSupportRequestPage() {
  bridge.tell('Shell', 'openSupportRequestPage');
}
