import React from 'react';
import classNames from 'classnames';

export default React.memo(
  ({ options, onClick, hide, isSelected, selectedClass, itemClass = '' }) => (
    <ul>
      {options.map((o, i) => (
        <li
          key={`${o.text + i}`}
          role='menuitem'
          className={classNames(itemClass, {
            [selectedClass]: isSelected(o),
            [o.className]: !!o.className,
          })}
          onKeyDown={() => {}}
          onClick={(e) => {
            if (o.onClick) {
              hide();
              o.onClick();
            } else {
              onClick(e, o);
            }
          }}
        >
          {o.text}
        </li>
      ))}
    </ul>
  )
);
