import { PureComponent } from 'react';
import currentSessionHOC from '@/connectHOCs/Sessions/currentSession';
import { getBucketedSearchContacts } from '@/utils/ContactUtils';
import getBucketedList from '@/utils/BucketedList';
import { getCustomFieldsMapFromSession } from '@/utils/SessionUtils';
import { getContactForId } from '@/Models/Contact';

/**
  * Manages current active index
  * Handles selection logic
  * Optionally, gets search result from hydra.

 * Component needs contacts array and bucketIndices to render contactList.
 *
 * User can either pass contacts and bucketIndices, or pass searchParams and searchTerm,
 * from which component will compute contacts and bucketIndices.

 TODO: This needs documentation
 */
class ContactSearchManager extends PureComponent {
  static defaultProps = {
    searchValue: '',
    currentSession: undefined,
    searchParams: {},
    contacts: undefined,
    bucketIndices: undefined,
    selectOnSpace: false,
    selectOnTab: false,
    includeTempContact: false,
  };

  state = {
    contacts: [],
    bucketIndices: [],
    activeContactIndex: 0,
  };

  constructor(props) {
    super(props);
    this.updateSearchResult(props);
  }

  componentDidUpdate(prevProps) {
    const {
      searchValue: prevSearchValue,
      currentSession: prevCurrentSession,
      searchParams: prevSearchParams,
    } = prevProps;
    const { searchValue, currentSession, searchParams } = this.props;
    if (
      prevSearchValue !== searchValue ||
      prevCurrentSession !== currentSession ||
      prevSearchParams !== searchParams
    ) {
      this.updateSearchResult(this.props);
    }
  }

  onDownArrow = (event) => {
    const { activeContactIndex } = this.state;
    const { contacts } = this.getSearchResult();

    let newActiveContactIndex = activeContactIndex + 1;
    if (newActiveContactIndex >= contacts.length) {
      newActiveContactIndex = 0;
    }

    event.preventDefault();

    this.setState({
      activeContactIndex: newActiveContactIndex,
    });
    return false;
  };

  onUpArrow = (event) => {
    const { activeContactIndex } = this.state;
    const { contacts } = this.getSearchResult();

    let newActiveContactIndex = activeContactIndex - 1;
    if (newActiveContactIndex < 0) {
      newActiveContactIndex = contacts.length - 1;
    }

    event.preventDefault();

    this.setState({
      activeContactIndex: newActiveContactIndex,
    });
    return false;
  };

  onEnter = () => {
    const { activeContactIndex } = this.state;
    const { contacts } = this.getSearchResult();
    if (!contacts[activeContactIndex]) {
      return true;
    }
    this.onContactSelect(contacts[activeContactIndex]);
    return false;
  };

  onSpace = () => {
    const { selectOnSpace } = this.props;
    if (!selectOnSpace) {
      return true;
    }
    const { activeContactIndex } = this.state;
    const { contacts } = this.getSearchResult();
    if (!contacts[activeContactIndex]) {
      return true;
    }
    this.onContactSelect(contacts[activeContactIndex]);
    return false;
  };

  onTab = () => {
    const { selectOnTab } = this.props;
    if (!selectOnTab) {
      return true;
    }
    const { activeContactIndex } = this.state;
    const { contacts } = this.getSearchResult();
    if (!contacts[activeContactIndex]) {
      return true;
    }
    this.onContactSelect(contacts[activeContactIndex]);
    return false;
  };

  onContactSelect = (contactJid) => {
    const { onContactSelect, currentSession } = this.props;
    if (currentSession && currentSession.owner) {
      const { ownerGuid } = currentSession.owner;
      onContactSelect(getContactForId(contactJid, ownerGuid));
    }
  };

  getSearchResult() {
    const {
      contacts: propContacts,
      bucketIndices: propBucketIndices,
      includeTempContact,
      searchValue,
    } = this.props;

    let contacts = [];
    let bucketIndices = [];

    if (propContacts && propBucketIndices) {
      contacts = propContacts;
      bucketIndices = propBucketIndices;
    } else {
      const { contacts: _contacts, bucketIndices: _bucketIndices } = this.state;
      contacts = _contacts;
      bucketIndices = _bucketIndices;
    }

    // clone contacts and bucketIndices everytime, so that temp contact change is not added to action prop or state object
    contacts = [...contacts];
    bucketIndices = [...bucketIndices];

    if (includeTempContact && searchValue.length > 0) {
      bucketIndices.push({
        start: contacts.length,
        id: 'temp',
        length: 1,
      });
      contacts.push(`TEMP:::${searchValue}`);
    }
    return { contacts, bucketIndices };
  }

  updateSearchResult(props) {
    const { searchValue, currentSession, searchParams } = props;

    if (!currentSession || searchValue === undefined) {
      return;
    }
    if (currentSession.owner) {
      const { ownerGuid } = currentSession.owner;

      getBucketedSearchContacts(ownerGuid, searchValue, searchParams).then(
        (buckets) => {
          const { contacts, bucketIndices } = getBucketedList(buckets, []);

          this.setState({
            contacts,
            bucketIndices,
          });
        }
      );
    }
  }

  render() {
    console.debug('ContactSearchManager::', this.state);
    const { currentSession, children } = this.props;
    const { activeContactIndex } = this.state;

    const { contacts, bucketIndices } = this.getSearchResult();

    if (!currentSession) {
      return null;
    }
    const customFieldsMap = getCustomFieldsMapFromSession(currentSession);

    return children({
      onUpArrow: this.onUpArrow,
      onDownArrow: this.onDownArrow,
      onEnter: this.onEnter,
      onSpace: this.onSpace,
      onTab: this.onTab,
      onContactSelect: this.onContactSelect,
      customFieldsMap,
      contacts,
      bucketIndices,
      activeContactIndex,
    });
  }
}

export default currentSessionHOC(ContactSearchManager);
