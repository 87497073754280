import { useState, useEffect, useRef, useCallback } from 'react';
import supportedLanguages from '@/utils/supportedLanguages';
import bridge from '@/utils/bridge';

export default function useLanguageSettings() {
  const languages = useRef(supportedLanguages);
  const [currentLanguage, setCurrentLanguage] = useState('');

  useEffect(() => {
    bridge
      .ask('HydraServer', 'getPreferredLanguageFromStore')
      .then((result) => {
        setCurrentLanguage(result);
      });
  }, []);

  const changeLanguage = useCallback((language) => {
    setCurrentLanguage(language);
    return bridge.tell('HydraServer', 'updatePreferredLanguage', [language]);
  }, []);

  return { currentLanguage, changeLanguage, languages: languages.current };
}
