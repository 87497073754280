import React from 'react';
import DownArrow from '@/components/common/Icons/DownArrow';
import cssStyles from './MessageList.css';

const UnreadMarker = function () {
  return (
    <div className={cssStyles.unreadMarker}>
      <div className={cssStyles.unreadMarkerLine} />
      <div className={cssStyles.unreadPill}>
        <span>Unread Messages</span>
        <DownArrow
          style={{
            marginLeft: '2px',
          }}
        />
      </div>
      <div className={cssStyles.unreadMarkerLine} />
    </div>
  );
};

export default UnreadMarker;
