export default {
  fileSharing_chatTab_modal_message_fileCountExceedMessage:
    'Whoops! You can not upload more than 10 files at a time.',
  fileSharing_chatTab_modal_title_fileLimitExceeded: 'File Limit Exceeded',
  fileSharing_chatTab_modal_message_fileLimitExceed:
    'Whoops! Your file size cannot exceed {fileUploadLimit} MB',
  fileSharing_chatTab_modal_buttonText_cancelFileUpload: 'Cancel File Upload',
  fileSharing_chatTab_modal_buttonText_completeFileUpload:
    'Complete File Upload',
  fileSharing_chatTab_modal_title_fileCountExceeded: 'Too Many Files',
  fileSharing_chatTab_modal_message_cancelFileUpload:
    'Are you sure you want to cancel the upload?',
  fileSharing_chatTab_modal_title_abortTitle: 'Abort File Upload',
  fileSharing_chatTab_hover_toolTip: 'Share a file',
  buttonText_ok: 'OK',
  fileSharing_chatTab_modal_message_invalidFileType:
    'The file upload could not be processed. Please check for the following: ',
  fileSharing_chatTab_modal_failureMessage_someFilesFailed:
    '{numFiles} file(s) were not sent because of an issue. Please check your Internet connection and try again.',
  fileSharing_chatTab_modal_fileShareFailed_title: 'File Transfer Failed',
  fileSharing_chatTab_modal_failureMessage_allFilesFailed:
    'Your file(s) were not sent because of an issue. Please check your Internet connection and try again.',
  fileSharing_chatTab_modal_message_fileShareDisabledByAdmin:
    'Contact your team admin to share files in your team',
  fileSharing_chatTab_modal_progressMessage_percentageUploaded:
    '$percentUploaded$% of $totalFileSize$',
  fileSharing_chatTab_modal_filename: 'File Name',
  fileSharing_chatTab_modal_title_fileShareDisabledByAdmin:
    'File sharing restricted',
  fileSharing_chatTab_modal_title_uploadImage: 'Upload Image',
  fileSharing_chatTab_notification_sentFile: 'sent a file $filename$',
  fileSharing_chatTab_notification_sentImage: 'sent an image $filename$',
  fileSharing_chatTab_uploadingText_fileUploading: 'Uploading',
  fileSharing_chatTab_chatTab_multipleFileUploadProgressString:
    '{countDone} of {totalCount} files',
  fileSharing_chatTab_chatTab_files: 'files',

  chatTab_dropdown_groupMemberList_loadingMemberText: 'Fetching Members....',
  chatTab_dropdown_groupMemberList_MultipleMembers: '{member_count} Members',
  chatTab_inputBox_placeholderText_inviteNewMemberToGroup: 'Invite to channel',
  chatTab_dropdown_groupMemberList_removeMember: 'Remove',
  chatTab_dropdown_groupMemberList_SingleMember: '{member_count} Member',
  text_addNewMemberToGroup: 'Add',
  remove_member_ok_text: 'Yes, remove',
  remove_member_cancel_text: 'No',
  remove_member_confirm_dialog_text:
    'Are you sure you want to remove {member_name} from this channel?',
  group_name_placeholder: 'Set channel name',
  group_purpose_placeholder: 'Set channel purpose',

  cancel: 'Cancel',

  chatTab_popup_slashCommand_text_leaveGroupHint: 'leave channel',
  chatTab_popup_slashCommand_text_leaveGroupCommand: 'leave',
  chatTab_popup_slashCommand_text_cloneGroupHint: 'clone the current channel',
  chatTab_popup_text_error_cloneGroupDialog:
    'Sorry, channels larger than {count} members cannot be cloned currently.',
  chatTab_popup_slashCommand_text_cloneGroupCommand: 'clone',
  add_popup: 'Add',
  add_user_to_channel: 'Are you sure you want to add {name} to the channel?',
  title_add_contact: 'Add Contact?',
  mention_separator: 'mention',

  editor_messageDropdown_menu_text_copy: 'Copy',
  editor_messageDropdown_menu_text_edit: 'Edit',
  editor_messageDropdown_menu_text_retry: 'Retry',
  editor_messageDropdown_popup_text_deleteMessage:
    'This message will be deleted for everyone in this conversation.',
  editor_messageDropdown_popup_text_deleteMessage_buddy:
    'This message will be deleted for both {peerName} and you.',
  editor_messageDropdown_popup_title_deleteMessage: 'Delete message?',
  editor_messageDropdown_popup_buttonText_deleteMessage_Yes: 'Yes, delete',
  editor_messageDropdown_popup_buttonText_delete: 'Delete',
  editor_deletion_failed: 'Action disallowed',
  editor_not_connected_to_internet_error:
    'You seem to have gone offline. This is usually due to poor/no internet connectivity.',
  'WARNING-GROUP_CLONE_MESSAGE':
    'Are you sure you want to clone this channel? The new channel will have {member_count} members.',
};
