/* eslint-disable import/prefer-default-export */
/* global _ */

function stringifyCustomFields(customFields, fieldsInfo) {
  if (_.isEmpty(_.compact(customFields)) || _.isEmpty(fieldsInfo)) {
    return '';
  }

  const _customFields = customFields.reduce((accumaltor, field) => {
    accumaltor[field.id] = field;
    return accumaltor;
  }, {});

  return _.chain(customFields)
    .map((customField) => customField.id)
    .filter((fieldId) => {
      const fieldInfo = fieldsInfo[fieldId];
      const fieldValue = _customFields[fieldId];
      return (
        fieldInfo &&
        fieldInfo.disambiguate &&
        fieldValue &&
        fieldValue.values &&
        fieldValue.values.length
      );
    })
    .sortBy((fieldId) => -fieldsInfo[fieldId].priority)
    .map((fieldId) => _customFields[fieldId].values[0] || 'Not set')
    .value()
    .join(' • ');
}

export { stringifyCustomFields };
