import React from 'react';
import Tippy from '@tippyjs/react';
import { FormattedMessage } from 'react-intl';
import IntlWrapper from '@/Wrappers/IntlWrapper';
import { Button } from '@/components/common';
import { openUrl, openAppStore } from '@/utils/SessionUtils';
import bridge from '@/utils/bridge';
import { addQueryParamtoHTTPUrls } from '@/utils';
import translations from './nls';
import cssStyles from './GetAppButton.css';

function GetAppButton(props) {
  const [visible, setVisible] = React.useState(true);

  function handleGetAppButton(appStoreUrl) {
    openUrl(appStoreUrl);
  }

  function openAppStoreUrl() {
    const { sessionId } = props;
    openAppStore(sessionId, 'app_suggestions', '');
  }

  function cancelSuggestion() {
    const {
      appsProperties: { type, storeKey },
    } = props;

    if (type === 'app') {
      bridge.tell('AppSuggestionsController', 'removeAppFromSuggestion', [
        storeKey,
      ]);
    } else if (type === 'domain') {
      bridge.tell('AppSuggestionsController', 'removeDomainFromSuggestion', [
        storeKey,
      ]);
    }
    setVisible(false);
  }

  const { appStoreUrlList, appsProperties: { prop } = {} } = props;
  return (
    <div className={cssStyles.appDiscovery}>
      {visible &&
        (appStoreUrlList.length === 1 ? (
          <>
            <Button
              type='button'
              kind='secondary'
              className={cssStyles.getAppButton}
              onClick={() => handleGetAppButton(appStoreUrlList[0].appStoreUrl)}
            >
              <FormattedMessage id='GET_THE_APP' />
            </Button>
            <FormattedMessage id='MAKE_FLOCK_BETTER_APPS' />
          </>
        ) : (
          // Make Flock even better with apps
          appStoreUrlList.map((app) => (
            <Tippy key={app.id} maxWidth={200} content={app.description}>
              <div
                className={cssStyles.getAppTippy}
                role='button'
                style={{
                  WebkitMask: `url(${addQueryParamtoHTTPUrls(
                    app.inClientIcon
                  )}) 0px 0px / cover no-repeat`,
                }}
                onClick={() => handleGetAppButton(app.appStoreUrl)}
                onKeyPress={() => {}}
                tabIndex={-1}
              />
            </Tippy>
          ))
        ))}
      {visible && (
        <span
          className={cssStyles.cancelSuggestion}
          onClick={cancelSuggestion}
          onKeyDown={() => {}}
          tabIndex={-1}
          role='button'
        />
      )}
      {!visible && (
        <span className={cssStyles.noPromptText}>
          <FormattedMessage
            id='URL_APP_DECORATOR_NO_PROMPT_TEXT'
            values={{
              appOrDomain: prop,
              flockStore: (
                <span
                  className={cssStyles.appStoreUrl}
                  onClick={openAppStoreUrl}
                  onKeyDown={() => {}}
                  role='button'
                  tabIndex={-1}
                >
                  <FormattedMessage id='FLOCK_APP_STORE' />
                </span>
              ),
            }}
          />
        </span>
      )}
    </div>
  );
}

export default IntlWrapper(GetAppButton, translations);
