/* eslint-disable */
/**
 * WARNING: DO NOT MODIFY THIS FILE!
 * This script is copied directly from Pendo. If any changes
 * are being made, ENSURE that you leave a comment.
 *
 * This script adds the pendo global.
 */
(function (p, d, o) {
  let v;
  let w;
  let x;
  o = p[d] = p[d] || {};
  o._q = [];
  v = ['initialize', 'identify', 'updateOptions', 'pageLoad'];
  for (w = 0, x = v.length; w < x; ++w)
    (function (m) {
      o[m] =
        o[m] ||
        function () {
          o._q[m === v[0] ? 'unshift' : 'push'](
            [m].concat([].slice.call(arguments, 0))
          );
        };
    })(v[w]);
})(window, 'pendo');
export default {
  init: () =>
    (function (apiKey, e, n) {
      const today = new Date();
      window.JS_SHA1_NO_NODE_JS = 1;
      window.JS_SHA1_NO_COMMON_JS = 1;
      if (window.define) {
        window.define.amd = null;
      }
      let y;
      let z;
      y = e.createElement(n);
      y.async = !0;
      y.src = `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js?${today.getDate()}${today.getMonth()}`;
      z = e.getElementsByTagName(n)[0];
      z.parentNode.insertBefore(y, z);
    })('4f01790c-f8ae-4161-554c-1b0700ebe262', document, 'script'),
};
