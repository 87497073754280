import React from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../store";

export const Container = observer(function Container({ children }) {
  const store = useStore();

  return (
    <div
      className="eup-container"
      data-vendor={store.vendor}
      data-skin={store.skin}
    >
      <div
        className="container-children"
        style={{ opacity: store.hasRenderedList ? 1 : 0 }}
      >
        {children}
      </div>

      <style jsx global>{`
        .eup-container {
          position: relative;
          border-radius: 4px;
          box-sizing: border-box;
        }
        .eup-container *,
        .eup-container *:before,
        .eup-container *:after {
          box-sizing: inherit;
        }
      `}</style>
      <style jsx global>{`
        .eup-container {
          border: 1px solid ${store.colors.border};
          background-color: ${store.colors.primaryBg};
        }
      `}</style>
      <style jsx>{`
        .container-children {
          display: flex;
          width: 100%;
          height: 100%;
          transition: opacity 0.2s cubic-bezier(0, 0, 0.3, 1);
        }
      `}</style>
    </div>
  );
});
