import * as React from 'react';
import cn from 'classnames';

import styles from './PanelSection.css';

type Props = {
  title?: string;
  contentClassName?: string;
  noPadding?: boolean;
  panelSectionClassName?: string;
};

const PanelSection: React.FC<Props> = (props) => {
  const {
    title,
    children,
    contentClassName,
    noPadding = false,
    panelSectionClassName,
  } = props;

  return (
    <div className={`${styles.section} ${panelSectionClassName}`}>
      {title && <h4 className={styles.heading}>{title}</h4>}
      <div
        className={cn(
          styles.content,
          noPadding && styles['no-padding'],
          contentClassName
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default PanelSection;
