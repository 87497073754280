import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import ThemeHOC from '@/connectHOCs/Theme';
import appWidgets from '@/utils/appWidgets';
import bridge from '@/utils/bridge';
import cssStyles from './Message.css';

function isValidDim(dim) {
  return dim && dim > 0 && `${dim}`.match(/^[0-9]+$/);
}

const DEFAULT_WIDGET_HEIGHT = '20px';
const DEFAULT_WIDGET_WIDTH = '100%';
function getStyle(dynamicHeight, { height, width }) {
  const h = dynamicHeight || height;
  return {
    height: isValidDim(h) ? `${h}px` : DEFAULT_WIDGET_HEIGHT,
    width: isValidDim(width) ? `${width}px` : DEFAULT_WIDGET_WIDTH,
  };
}

function getFlockEvent(
  name,
  attachment,
  currentPeer,
  currentSessionOwner,
  messageId,
  messageSid
) {
  const { id: attachmentId = '' } = attachment;
  return {
    name,
    attachmentId,
    messageId: messageId || '',
    messageUid: messageSid || '',
    locale: 'en-us',
    ...appWidgets._genContextFlockEventProps(currentPeer, currentSessionOwner),
  };
}

function getUrlFromAttachmentObject(attachment) {
  if (attachment && attachment.views && attachment.views.widget) {
    return attachment.views.widget.src;
  }
  return '';
}

function getAppById(sessionId, appId, appList = []) {
  const app = appList.find(({ id }) => id === appId);
  if (app) {
    return Promise.resolve(app);
  }
  return bridge.ask('DikeController', 'getAppDataByAppId', [sessionId, appId]);
}

const useAppId = function (sessionId, appId, appList) {
  const [app, setApp] = useState(null);
  useEffect(() => {
    if (appId) {
      getAppById(sessionId, appId, appList).then((application) =>
        setApp(application)
      );
    }
  }, [appId, appList, sessionId]);
  return app;
};

const useContextParameters = function (
  app,
  claimToken,
  attachment,
  currentPeer,
  currentSessionOwner,
  messageId,
  messageSid
) {
  const appUrl = getUrlFromAttachmentObject(attachment);
  if (!attachment.appId) {
    return appUrl;
  }
  if (app?.eventToken && claimToken) {
    const flockEventParams = getFlockEvent(
      'client.openAttachmentWidget',
      attachment,
      currentPeer,
      currentSessionOwner,
      messageId,
      messageSid
    );
    return appWidgets.addContextParametersToUrl(
      appUrl,
      app?.eventToken,
      currentPeer,
      currentSessionOwner,
      'inline',
      flockEventParams,
      true,
      claimToken,
      false,
      app?.useFragmentParameters
    );
  }
  return null;
};

const InlineAttachment = React.memo(function InlineAttachment({
  claimToken,
  appList,
  attachment,
  currentSessionOwner,
  currentSessionId,
  currentPeer,
  height,
  messageId,
  messageSid,
}) {
  const {
    appId,
    views: { html },
  } = attachment;

  const { inline } = html;
  let srcDoc = inline;
  const app = useAppId(currentSessionId, appId, appList);
  const getStyles = useCallback(getStyle, [height, html]);

  if (app) {
    const parser = new window.DOMParser();
    const inlineDoc = parser.parseFromString(inline, 'text/html');
    const flockEventParams = getFlockEvent(
      'client.openAttachmentWidget',
      attachment,
      currentPeer,
      currentSessionOwner,
      messageId,
      messageSid
    );

    const inlineBodyCollection = inlineDoc.getElementsByTagName('body');
    if (inlineBodyCollection.length === 1) {
      const inlineBody = inlineBodyCollection[0];
      appWidgets.addContextParametersToDataset(
        inlineBody,
        app?.eventToken,
        currentPeer,
        currentSessionOwner,
        flockEventParams,
        claimToken
      );
    }
    srcDoc = inlineDoc.documentElement.outerHTML;
  }
  return (
    <iframe
      sandbox='allow-scripts allow-forms'
      srcDoc={srcDoc}
      className={cssStyles.appViewIFrame}
      title='inline-iframe-chat'
      style={getStyles(height, html)}
    />
  );
});

const IframeAttachment = React.memo(function IframeAttachment({
  attachment,
  currentSessionOwner,
  currentSessionId,
  currentPeer,
  messageId,
  messageSid,
  appList,
  height,
  claimToken,
  keyOfIframe,
}) {
  const {
    views: { widget },
    appId,
  } = attachment;
  const getStyles = useCallback(getStyle, [height, widget]);
  const app = useAppId(currentSessionId, appId, appList);
  const url = useContextParameters(
    app,
    claimToken,
    attachment,
    currentPeer,
    currentSessionOwner,
    messageId,
    messageSid
  );
  if (url) {
    return (
      <iframe
        sandbox='allow-same-origin allow-scripts allow-forms'
        className={cssStyles.appViewIFrame}
        title='widget_iframe'
        src={url}
        style={getStyles(height, widget)}
        key={keyOfIframe}
      />
    );
  }
  return null;
});

class AttachmentWidget extends React.Component {
  state = {
    height: null,
    claimToken: null,
    keyOfIframe: 0,
  };

  componentDidMount() {
    const {
      attachment: { appId },
    } = this.props;
    this.setState({ claimToken: appWidgets.generateClaimToken(appId) });
    window.addEventListener('message', this.changeHeight);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.changeHeight);
  }

  componentDidUpdate(prevProps) {
    const { currentTheme } = this.props;
    const prevTheme = prevProps.currentTheme;
    // For theme change, we need to re-render the iframe.
    if (currentTheme !== prevTheme) {
      this.setState(({ keyOfIframe }) => ({ keyOfIframe: keyOfIframe + 1 }));
    }
  }

  // changeHeight = ({ data: { claimToken, name, payload: { height } = {} } }) => {
  changeHeight = async (e) => {
    const { data } = e;
    const { claimToken: attachmentClaimToken } = this.state;
    const { claimToken, name, payload } = data;
    if (
      name === 'resizeAttachmentView' &&
      claimToken === attachmentClaimToken
    ) {
      const { height } = payload;
      this.setState({ height });
    } else if (claimToken === attachmentClaimToken) {
      const {
        attachment,
        currentPeer,
        currentSessionId,
        currentSessionOwner,
        messageId,
        messageSid,
        appList,
      } = this.props;
      const { appId } = attachment;
      const app = await getAppById(currentSessionId, appId, appList);
      const flockEventParams = getFlockEvent(
        'client.openAttachmentWidget',
        attachment,
        currentPeer,
        currentSessionOwner,
        messageId,
        messageSid
      );
      const appData = {
        app,
        currentPeer,
        flockEventParams,
        sessionId: currentSessionId,
      };
      bridge.tell('Shell', 'handleAppRequest', [data, appData]);
    }
  };

  render() {
    const {
      isInline,
      appList,
      attachment,
      currentSessionOwner,
      currentSessionId,
      currentPeer,
      messageId,
      messageSid,
    } = this.props;
    const { height, claimToken, keyOfIframe } = this.state;
    if (isInline) {
      return (
        <InlineAttachment
          attachment={attachment}
          currentSessionOwner={currentSessionOwner}
          currentSessionId={currentSessionId}
          currentPeer={currentPeer}
          messageId={messageId}
          messageSid={messageSid}
          appList={appList}
          claimToken={claimToken}
          height={height}
          keyOfIframe={keyOfIframe}
        />
      );
    }
    return (
      <IframeAttachment
        attachment={attachment}
        currentSessionId={currentSessionId}
        currentSessionOwner={currentSessionOwner}
        currentPeer={currentPeer}
        messageId={messageId}
        messageSid={messageSid}
        appList={appList}
        claimToken={claimToken}
        height={height}
        keyOfIframe={keyOfIframe}
      />
    );
  }
}

function mapStateToProps(state) {
  const ownerGuid = state.restricted._session.currentOwnerGuid;
  return {
    appList: state.restricted.dike[ownerGuid].appList,
  };
}

export default connect(mapStateToProps)(ThemeHOC(AttachmentWidget));
