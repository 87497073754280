import ptBr from './pt-br';
import enUS from './en-us';
import enGB from './en-gb';
import es from './es';
import esMX from './es-mx';

export default {
  'pt-br': ptBr,
  es,
  'en-GB': enGB,
  'en-US': enUS,
  'es-MX': esMX,
};
