/* global analytics */
/* eslint-disable import/prefer-default-export */
import bridge from '@/utils/bridge';
import { getDataUrlForFile } from '@/utils/file';
import { getImageBlobFromCanvas } from '@/utils/canvas';
import { getImage } from '@/utils/image';
import { getDeviceInfo } from '@/utils/user-agent';
import { getFileShareId } from '@/utils/upload';
import { getUserTokenFromSession } from '@/utils/auth';

async function getAvatarUploadUrl(peer, sessionId) {
  const uuid = getFileShareId(peer.jid);
  const guid = peer.ownerGuid;
  const groupId = peer.jid.split('@')[0];

  const token = await getUserTokenFromSession(sessionId);
  const fileShareUrl = await bridge.ask('HydraServer', 'getStartupOption', [
    'fileSharingUrl',
  ]);

  return `${fileShareUrl}/uploadGroupAvatar?token=${token}&uuid=${uuid}&groupId=${groupId}&guid=${guid}`;
}

export async function updateChannelAvatar(blob, peer, sessionId) {
  const deviceInfo = await getDeviceInfo();

  const formData = new FormData();
  formData.append('file', blob);

  const timeTakenEvent = analytics.startNetworkAction(
    'fileShare',
    'upload_time'
  );

  const url = await getAvatarUploadUrl(peer, sessionId);

  return fetch(url, {
    method: 'POST',
    headers: {
      'X-UA-Info': deviceInfo,
    },
    body: formData,
  })
    .catch(async ({ status, body }) => {
      const responseText = await body.text();
      const error = new Error();
      error.isError = true;

      if (status === 403) {
        error.name = 'NOT_MEMBER';
      } else if (status === 404) {
        error.name = 'GROUP_NONEXISTANT';
      } else {
        error.name = 'GENERIC_ERROR';
        error.response = responseText;
      }

      throw error;
    })
    .finally(() => {
      timeTakenEvent.end();
    });
}

export async function broadcastAvatarUpateMessage() {
  await bridge.publish('/groupinfo/avatar/updated');
}

export async function getNewAvatarImageFromFile(file, imgOutputSize) {
  const src = await getDataUrlForFile(file);

  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      let sw = img.width;
      let sh = img.height;
      let sx = 0;
      const sy = 0;
      let d = imgOutputSize;

      if (sw > sh) {
        sx = (sw - sh) / 2;
        sw = sh;
      } else {
        sh = sw;
      }

      if (imgOutputSize > sw) {
        d = sw;
      }

      const tempCanvas = document.createElement('canvas');
      tempCanvas.width = d;
      tempCanvas.height = d;

      const ctx = tempCanvas.getContext('2d');

      ctx.drawImage(img, sx, sy, sw, sh, 0, 0, d, d);

      resolve({
        url: tempCanvas.toDataURL('image/png'),
        blob: getImageBlobFromCanvas(tempCanvas),
      });
    };

    img.onerror = (error) => {
      reject(error);
    };

    img.src = src;
  });
}

export async function getNewAvatarImageFromEmoji(
  emojiImageUrl,
  backgroundColor,
  imgOutputSize
) {
  const image = await getImage(emojiImageUrl, true);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = imgOutputSize;
  canvas.height = imgOutputSize;

  if (backgroundColor) {
    ctx.fillStyle = backgroundColor;
    ctx.fillRect(0, 0, canvas.width, canvas.height);
  }

  const inputSize = image.width;
  const outputSize = imgOutputSize * 0.75;
  const dx = canvas.width / 2 - outputSize / 2;
  const dy = canvas.height / 2 - outputSize / 2;
  ctx.drawImage(
    image,
    0,
    0,
    inputSize,
    inputSize,
    dx,
    dy,
    outputSize,
    outputSize
  );

  return {
    url: canvas.toDataURL('image/png'),
    blob: getImageBlobFromCanvas(canvas),
  };
}
