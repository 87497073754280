import bridge from '@/utils/bridge';
import getTranslatedString from '@/utils/getTranslatedString';
import { getCommandFragments } from '../../../../TokenUtils';
import I18N from './I18N';

const command = {
  name: getTranslatedString('command_setStatus_name', I18N),
  hint: getTranslatedString('command_setStatus_hint', I18N),
  params: {
    hint: getTranslatedString('command_setStatus_param_hint', I18N),
    getParamsInfo(textContent, selectionStart, selectionEnd) {
      return getCommandFragments(textContent, selectionStart, selectionEnd);
    },
  },
  shouldIgnore() {
    return false;
  },
  action(textContent, peer, owner, sessionId /* conversation */) {
    let status = textContent.split('/status')[1].trim();

    status = status.substring(0, 80); // status to be of max 80 chars
    bridge.tell('UserStatus', 'setUserStatus', [status, sessionId]);

    return true;
  },
};

export default command;
