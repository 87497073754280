import React, { useEffect } from 'react';
import PaywallButton from '@/components/common/PaywallButton';
import { logMedusaGenericEvent } from '@/utils/logAnalytics';
import PaywallContent from '../PaywallContent';

const gaSource = 'VIDEO_CALL';
const feature = 'group_video_call';
const source = 'client_channel_group_video_call';

export default function VideoPaywall({
  teamPlan: { isEligibleForFreeProTrial } = {},
  sessionId,
  isAdmin,
  hideVideoPaywall,
}) {
  /*
   * Log Medusa event whenever this component is shown.
   * When you give useEffect empty props, it will act like componentDidMount
   */
  useEffect(() => {
    logMedusaGenericEvent('paywall_shown', {
      feature,
      source,
    });
  }, []);
  return (
    <PaywallContent
      title='Upgrade to Flock PRO for group video calls'
      description='Your team is currently on the Flock Starter plan which only supports video calls in one-to-one chats.'
    >
      <PaywallButton
        isAdmin={isAdmin}
        isEligibleForFreeProTrial={isEligibleForFreeProTrial}
        sessionId={sessionId}
        gaSource={gaSource}
        feature={feature}
        source={source}
        close={hideVideoPaywall}
      />
    </PaywallContent>
  );
}
