import React from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../store";

export const Header = observer(function Header({ style, text }) {
  const store = useStore();

  return (
    <div className="header-root" style={style}>
      <span className="text">{text}</span>

      <style jsx>{`
        .header-root {
          display: flex;
          align-items: center;
        }
        .text {
          font-size: 11px;
          font-weight: 600;
          text-transform: uppercase;
        }
      `}</style>
      <style jsx>{`
        .header-root {
          padding: 0 ${store.padding}px;
        }
        .text {
          color: ${store.colors.secondaryText};
        }
      `}</style>
    </div>
  );
});
