import { connect } from 'react-redux';

function mapStateToProps(state) {
  const { _session } = state.restricted;
  const currentSessionId = _session.currentSessionId || '';
  return { currentSessionId };
}

export default function (component) {
  return connect(mapStateToProps)(component);
}
