import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { actions } from '@/actions/search';
import appWidgets from '@/utils/appWidgets';
import bridge from '@/utils/bridge';
import withCurrentSession from '@/connectHOCs/Sessions/currentSession';
import withCurrentPeer from '@/subscribeHOCs/currentPeer';
import withTheme from '@/connectHOCs/Theme';
import Loader from '@/components/common/Loader';
import Modal from '@/components/ExtendableModal';

import css from './SearchApp.css';
import Icon from '../Icon';

let devConfig = {} as any;
const isDev = process.env.NODE_ENV === 'development';

const IFRAME_EXPIRY_DURATION = 15 * 1000 * 60;
const FLOCK_EVENT_PARAMS = {
  name: 'client.pressButton',
  button: 'appLauncherButton',
};

interface StoreState {
  search: {
    open: boolean;
    options: any;
  };
}

function mapStateToProps({ search: { open, options } }: StoreState) {
  return {
    open,
    options,
  };
}

// TODO: This is the react-redux documented way. Not a fan of this, probably can
// find a neat way to decouple prop definitions
const connector = connect(mapStateToProps, {
  close: actions.closeSearchApp,
  reset: actions.resetSearchAppOptions,
});

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  currentSession: any;
  currentPeer: any;
  currentTheme: string;
};

const SearchApp = React.memo(function SearchApp({
  open,
  close,
  reset,
  options,
  currentSession,
  currentPeer,
  currentTheme,
}: Props) {
  const [iframeUrl, setIframeUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const claimToken = useRef<string>(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const appRequestOptions = useMemo(
    () => ({
      app: options,
      peer: currentPeer,
      flockEventParams: FLOCK_EVENT_PARAMS,
      sessionId: currentSession.id,
    }),
    [options, currentPeer, currentSession.id]
  );

  const refreshIframe = useCallback(() => {
    if (!options) {
      return;
    }

    const {
      appLauncherButton: {
        action: { url },
      },
      id,
      eventToken,
      useFragmentParameters,
    } = options;

    claimToken.current = appWidgets.generateClaimToken(id);
    let preparedUrl = appWidgets.addContextParametersToUrl(
      !isDev ? url : devConfig.current.url ?? url,
      eventToken,
      currentPeer,
      currentSession.owner,
      'modal',
      FLOCK_EVENT_PARAMS,
      true,
      claimToken.current,
      false,
      useFragmentParameters
    );

    const urlObj = new URL(preparedUrl);
    urlObj.searchParams.append('searchAppLayout', 'modal');
    preparedUrl = urlObj.toString();

    setIframeUrl(preparedUrl);
  }, [currentPeer, currentSession.owner, options]);

  const closeModal = useCallback(() => {
    close();

    (document.activeElement as HTMLButtonElement).blur();
  }, [close]);

  const onPostMessage = useCallback(
    (event) => {
      if (event?.data?.name === 'flock-search/ready') {
        setIsLoading(false);
        return;
      }

      if (
        event &&
        event.data &&
        event.data.claimToken === claimToken.current &&
        event.source !== window &&
        event.data.name !== 'resizeAttachmentView'
      ) {
        if (event.data.request) {
          bridge
            .ask('Shell', 'handleAppRequest', [event.data, appRequestOptions])
            .then((responseObj) => event.source.postMessage(responseObj, '*'));
        } else if (event.data.name === 'close') {
          closeModal();
        } else {
          bridge.tell('Shell', 'handleAppRequest', [
            event.data,
            appRequestOptions,
          ]);
        }
      }
    },
    [appRequestOptions, closeModal]
  );

  useEffect(() => {
    window.addEventListener('message', onPostMessage);

    return () => {
      window.removeEventListener('message', onPostMessage);
    };
  }, [onPostMessage]);

  useEffect(() => {
    let timeoutId;

    // If then modal is kept closed long enough, we want to re-render the iframe
    // and not reuse the existing DOM.
    if (!open) {
      timeoutId = setTimeout(() => {
        setIframeUrl(null);
        reset();
      }, IFRAME_EXPIRY_DURATION);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [open, reset]);

  useEffect(() => {
    if (!open || iframeUrl) {
      return;
    }

    refreshIframe();
  }, [open, iframeUrl, refreshIframe]);

  useEffect(() => {
    setIframeUrl(null);
  }, [currentSession.owner?.jid, currentSession.id, currentTheme]);

  useEffect(() => {
    if (open && iframeUrl) {
      iframeRef.current?.contentWindow.focus();
    }
  }, [open, iframeUrl]);

  useEffect(() => {
    if (!iframeUrl) {
      setIsLoading(true);
    }
  }, [iframeUrl]);

  useEffect(() => {
    if (!open && isLoading) {
      setIframeUrl(null);
    }
  }, [open, isLoading]);

  useEffect(() => {
    if (isDev) {
      // @ts-ignore
      window.dev.searchApp.resetIframe = () => {
        setIframeUrl(null);
      };
    }
  }, []);

  return (
    <Modal
      onOverlayClick={closeModal}
      isVisible={open}
      height='800px'
      noDestroy
    >
      {iframeUrl && (
        <>
          <Icon
            as='button'
            onClick={closeModal}
            className={css.CloseIcon}
            name='close'
          />
          <iframe
            ref={iframeRef}
            className={css.IFrame}
            src={iframeUrl}
            title='Search'
          />
          <Loader loading={isLoading} overlay opaque size='2em' />
        </>
      )}
    </Modal>
  );
});

if (isDev) {
  devConfig = {
    staging: { url: 'http://localhost:8080/search/staging' },
    production: { url: 'http://localhost:8080/search' },
    current: {} as any,
  };

  // @ts-ignore
  window.dev = {
    // @ts-ignore
    ...(window.dev || {}),
    searchApp: {
      enable(env = 'staging') {
        devConfig.current = devConfig[env];
      },
    },
  };

  if (process.env.REACT_APP_SEARCH_APP_DEV) {
    // @ts-ignore
    window.dev.searchApp.enable();
  }
}

export default withTheme(
  withCurrentSession(withCurrentPeer(connector(SearchApp)))
);
