import { createSelector } from 'reselect';
import { getContactForId } from '@/Models/Contact';
import ownerGuidSelector from './ownerGuid';
import currentConversation, {
  currentConversationByGuidSelector,
} from './currentConversation';
import allContactsSelector from './allContacts';

const defaultOpenConversation = '';

export const currentPeerByGuidSelector = createSelector(
  currentConversationByGuidSelector,
  (getCurrentConversationByGuid) => (ownerGuid) =>
    getContactForId(
      getCurrentConversationByGuid(ownerGuid) || defaultOpenConversation,
      ownerGuid
    )
);

export default createSelector(
  ownerGuidSelector,
  currentConversation,
  allContactsSelector,
  (ownerGuid, jid) => getContactForId(jid, ownerGuid)
);
