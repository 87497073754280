/* eslint-disable react/prefer-stateless-function */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ContactList from '@/connectHOCs/Contacts/ContactList';
import SearchTextField from './SearchTextField';
import ContactPills from './ContactPills';
import cssStyles from './SearchInput.css';

class SearchInput extends Component {
  static propTypes = {
    onCloseClick: PropTypes.func,
    onSearchTextChange: PropTypes.func,
    searchText: PropTypes.string,
    contactIds: PropTypes.array,
    ownerGuid: PropTypes.string,
  };

  static defaultProps = {
    onCloseClick: () => {},
    onSearchTextChange: () => {},
    searchText: '',
    contactIds: [],
    ownerGuid: '',
  };

  render() {
    const {
      searchText,
      onSearchTextChange,
      onCloseClick,
      contactIds,
      ownerGuid,
    } = this.props;

    return (
      <div className={cssStyles.wrapper}>
        <div className={cssStyles['search_icon-wrapper']}>
          <div
            className={`search_icon-back ${cssStyles['search_icon-back']} ${cssStyles.pointer}`}
            onClick={onCloseClick}
            onKeyDown={() => {}}
            role='button'
            tabIndex='-1'
          />
        </div>
        <div className={cssStyles.inputWrapper}>
          <ContactPills contactIds={contactIds} ownerGuid={ownerGuid} />
          <SearchTextField
            searchText={searchText}
            onChange={onSearchTextChange}
          />
        </div>
      </div>
    );
  }
}

export default ContactList(SearchInput, 'contactIds', 'ownerGuid');
