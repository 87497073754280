import React, { PureComponent } from 'react';
import { List } from 'react-virtualized';
import currentAssociatesConnectHOC from '@/connectHOCs/Conversations/currentConversation/associates';
import AssociateItem from './AssociateItem';
import css from './AssociateList.css';

function getSortKey(assoc) {
  let prefix = '_';

  prefix += assoc.isDeactivated ? '1' : '0';
  prefix += assoc.affiliation === 'moderator' ? 'a' : 'm';

  return `${prefix}_${assoc.name.toLowerCase()}`;
}

function associatesComparator(a, b) {
  const aKey = getSortKey(a);
  const bKey = getSortKey(b);

  if (aKey > bKey) {
    return 1;
  }
  if (aKey < bKey) {
    return -1;
  }

  return 0;
}

class AssociateList extends PureComponent {
  static default = {
    peer: undefined,
    associates: undefined,
    closePopover: () => {},
  };

  rowRenderer = ({ key, index, style }) => {
    const { associates, peer, closePopover } = this.props;

    return (
      <div style={style} key={key}>
        <AssociateItem
          associate={associates[index]}
          peer={peer}
          closePopover={closePopover}
        />
      </div>
    );
  };

  render() {
    const { associates } = this.props;
    associates.sort(associatesComparator);
    console.log('Rendering associates ', associates);
    return (
      <div className={css.Wrapper}>
        <List
          // Passing associates as props to let List know of any change in sort order.
          // May be there's a better way
          associates={associates}
          width={450}
          height={associates.length > 6 ? 264 : associates.length * 44}
          rowCount={associates.length}
          rowHeight={44}
          rowRenderer={this.rowRenderer}
        />
      </div>
    );
  }
}

export default currentAssociatesConnectHOC(AssociateList);
