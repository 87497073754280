import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Tippy from '@tippyjs/react';
import {
  confirmAndLeaveChannel,
  updateGroupDescription,
  updateGroupName,
} from '@/utils/ConversationUtils';
import { AssociatesProxy } from '@/Proxies';
import { openGroupSettingsModal } from '@/utils/GenericModalUtils';
import { getEnvironment, getResolvedPromise } from '@/utils';
import ChatsProxy from '@/Proxies/ChatsProxy';
import { isCurrentConversationPinnedSelector } from '@/selectors';
import currentSessionHOC from '@/connectHOCs/Sessions/currentSession';
import EditableText from '@/components/common/EditableText';
import getTranslatedString from '@/utils/getTranslatedString';
import noop from '@/utils/noop';
import bridge from '@/utils/bridge';
import AssociateList from './AssociateList';
import DikeApps from './DikeApps';
import Avatar from './Avatar';
import css from './Group.css';
import i18n from './I18N';

const MAX_DESCRIPTION_LENGTH = 140;
const MAX_NAME_LENGTH = 50;

class Group extends PureComponent {
  static defaultProps = {
    peer: undefined,
    currentSession: undefined,
    associates: undefined,
    isPinned: false,
    closePopover: () => {},
  };

  constructor() {
    super();
    this.state = {
      copied: false,
    };
  }

  componentDidMount = () => {
    const { peer } = this.props;
    this._subscribeHandle = AssociatesProxy.subscribe(peer);
  };

  componentDidUpdate = (prevProps) => {
    const { peer: currentPeer } = this.props;
    const { peer: prevPeer } = prevProps;
    if (
      currentPeer.jid !== prevPeer.jid ||
      currentPeer.ownerGuid !== prevPeer.ownerGuid
    ) {
      this._subscribeHandle.unsubscribe();
      this._subscribeHandle = AssociatesProxy.subscribe(currentPeer);
    }
  };

  componentWillUnmount = () => {
    this._subscribeHandle.unsubscribe();
  };

  onLeaveChannelClick = () => {
    const { peer } = this.props;
    confirmAndLeaveChannel(peer);
  };

  onSettingsClick = () => {
    const { peer, currentSession } = this.props;
    openGroupSettingsModal(peer, currentSession);
  };

  onGroupDescriptionUpdate = (description) => {
    const { peer } = this.props;
    updateGroupDescription(peer, description);
    return getResolvedPromise(1000);
  };

  onGroupNameUpdate = (name) => {
    const { peer } = this.props;
    updateGroupName(peer, name);
    return getResolvedPromise(1000);
  };

  onTogglePin = () => {
    const {
      peer: { jid, ownerGuid },
      isPinned,
    } = this.props;
    ChatsProxy.togglePin(ownerGuid, jid, !isPinned);
  };

  copyGroupLinkToClipboard = () => {
    const {
      peer: { jid },
      currentSession: {
        sessionInfo: {
          teamInfo: { teamId },
        },
      },
    } = this.props;

    const env = getEnvironment();

    const intermediatePageDomain =
      env === 'prod'
        ? 'https://web.flock.com/channel/'
        : 'https://web.flock-staging.com/channel/';

    const channelUrl = `${intermediatePageDomain}?chat_jid=${jid}&teamId=${teamId}`;

    bridge.tell('HydraServer', 'copyToClipboard', [channelUrl]).then(() => {
      this.setState({ copied: true });
      setTimeout(() => {
        this.setState({ copied: false });
      }, 2000);
    });
  };

  render() {
    const {
      peer,
      associates,
      closePopover,
      isPinned,
      memberCount,
      currentSession: {
        sessionInfo: {
          teamInfo: { isChannelSharingEnabled },
        },
      },
    } = this.props;
    const {
      chatName,
      description,
      hasConfigChangePrivilege,
      hasLeaveGroupPrivilege,
      hasProfileChangePrivilege,
      groupType,
    } = peer;

    const { copied } = this.state;

    const shouldShowGroupShareLink =
      !!isChannelSharingEnabled && groupType === 'open';

    return (
      <div className={css.Wrapper}>
        <div className={css.Top}>
          <Avatar
            peer={peer}
            className={css.Image}
            onClick={closePopover}
            allowEdit={hasProfileChangePrivilege}
          />
          <div className={css.ProfileDetails}>
            <div className={css.Name}>
              <EditableText
                value={chatName}
                actionFunction={this.onGroupNameUpdate}
                className={css.NameTextArea}
                maxLength={MAX_NAME_LENGTH}
                allowEdit={hasProfileChangePrivilege}
                placeholder={getTranslatedString(
                  'group_name_placeholder',
                  i18n
                )}
              />
            </div>
            <div className={css.Description}>
              <EditableText
                value={description}
                actionFunction={this.onGroupDescriptionUpdate}
                className={css.DescriptionTextArea}
                maxLength={MAX_DESCRIPTION_LENGTH}
                allowEdit={hasProfileChangePrivilege}
                placeholder={getTranslatedString(
                  'group_purpose_placeholder',
                  i18n
                )}
              />
            </div>
          </div>
        </div>
        <div className={css.Middle}>
          <div className={css.MemberCount}>
            <div className={`${css.Icon} ${css.Contact}`} />
            <div>{memberCount}</div>
          </div>
          <div className={css.Right}>
            <DikeApps className={`${css.Icon}`} peer={peer} />
            {shouldShowGroupShareLink && (
              <Tippy
                content={copied ? 'Copied!' : 'Click to copy group link'}
                hideOnClick={false}
                trigger='mouseenter'
                arrow
              >
                <div
                  className={`${css.Icon} ${css.ShareLink}`}
                  onClick={this.copyGroupLinkToClipboard}
                  onKeyPress={noop}
                  tabIndex={-1}
                  role='button'
                />
              </Tippy>
            )}
            <Tippy
              content={isPinned ? 'Unpin this chat' : `Pin this chat`}
              arrow
            >
              <div
                className={`${css.Icon} ${css.Pin} ${
                  isPinned ? css.isPinned : ''
                }`}
                onClick={this.onTogglePin}
                onKeyPress={noop}
                tabIndex={-1}
                role='button'
              />
            </Tippy>
            <div className={css.Separator} />
            {hasConfigChangePrivilege ? (
              <Tippy content='Settings' arrow>
                <div
                  className={`${css.Icon} ${css.Settings}`}
                  onClick={this.onSettingsClick}
                  onKeyPress={noop}
                  tabIndex={-1}
                  role='button'
                />
              </Tippy>
            ) : null}
            {hasLeaveGroupPrivilege ? (
              <Tippy content='Leave this channel' arrow>
                <div
                  className={`${css.Icon} ${css.Leave}`}
                  onClick={this.onLeaveChannelClick}
                  onKeyPress={noop}
                  tabIndex={-1}
                  role='button'
                />
              </Tippy>
            ) : null}
          </div>
        </div>

        <div className={css.Bottom}>
          <AssociateList
            associates={associates}
            peer={peer}
            closePopover={closePopover}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const isPinned = isCurrentConversationPinnedSelector(state);

  return {
    isPinned,
  };
};

export default currentSessionHOC(connect(mapStateToProps)(Group));
