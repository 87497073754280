import React, { useRef, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { ownerGuidSelector, currentSessionSelector } from '@/selectors';
import { Actions } from '@/actions/restricted';
import MessageEditorNotificationProxy from '@/Proxies/MessageEditorNotificationProxy';

import NotificationIndicatorItem from './NotificationIndicatorItem';

import css from './NotificationIndicator.css';

function normalizeStatusType(notification) {
  const mapping = {
    success: 'success',
    error: 'danger',
    warn: 'warn',
  };

  return mapping[notification.status];
}

function NotificationIndicator({
  peer,
  notifications = [],
  removeOldestNotification,
}) {
  const [open, setOpen] = useState(false);
  const [currentNotification, setCurrentNotification] = useState(
    notifications?.[0] ?? null
  );
  const proxyRef = useRef(null);

  useEffect(() => {
    let cancelled = false;

    async function subscribe() {
      const subscription = await MessageEditorNotificationProxy.subscribe(peer);

      if (!cancelled) {
        proxyRef.current = subscription;
      } else {
        subscription.unsubscribe();
      }
    }

    subscribe();

    return () => {
      cancelled = true;

      if (proxyRef.current) {
        proxyRef.current.unsubscribe();
      }
    };
  }, [peer]);

  useEffect(() => {
    if (notifications.length) {
      setCurrentNotification(notifications[0]);
      setOpen(true);
    } else {
      setCurrentNotification(null);
      setOpen(false);
    }
  }, [notifications]);

  const onNotificationClose = useCallback(() => {
    setOpen(false);
    removeOldestNotification();
  }, [removeOldestNotification]);

  if (!currentNotification) {
    return null;
  }

  const status = normalizeStatusType(currentNotification);

  return (
    <div className={css.NotificationIndicator}>
      <NotificationIndicatorItem
        open={open}
        onClose={onNotificationClose}
        status={status}
        visibleDuration={currentNotification.time}
      >
        <span>{currentNotification.text}</span>
      </NotificationIndicatorItem>
    </div>
  );
}

function mapDispatchToProps(dispatch, { peer }) {
  return {
    removeOldestNotification() {
      return dispatch(
        Actions.remove_oldest_editor_notification({
          ownerGuid: peer.ownerGuid,
          jid: peer.jid,
        })
      );
    },
  };
}

function mapStateToProps(state, props) {
  const {
    restricted: { _session: session },
  } = state;
  const { peer } = props;
  const ownerGuid = ownerGuidSelector(state);

  return {
    currentSession: currentSessionSelector(state),
    notifications: session.editor_notifications?.[ownerGuid]?.[peer?.jid] ?? [],
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationIndicator);
