import React, { Component } from 'react';
import classNames from 'classnames';
import modalStyles from '@/components/Modal/Modal.css';
import duplicateInstanceStyles from './DuplicateInstanceModalComponent.css';

class DuplicateInstanceModalComponent extends Component {
  render() {
    return (
      <div className={duplicateInstanceStyles.duplicate_instance_modal}>
        <div
          className={classNames(
            modalStyles.modal_title,
            duplicateInstanceStyles.modal_title
          )}
        >
          Error
        </div>

        <div className={duplicateInstanceStyles.modal_content}>
          Another instance of this client is open. Please close this.
        </div>
      </div>
    );
  }
}

export default DuplicateInstanceModalComponent;
