import { sortBy, uniq } from "lodash";
import * as util from "../util";
import { default as emojiList } from "emoji-data/data/emojis.json";
import { default as emoticonList } from "emoji-data/data/emoticons.json";
import { default as spritesheetList } from "emoji-data/data/spritesheets.json";
import { version as emojiDataVersion } from "emoji-data/package.json";
import { ConvertorFactory } from "./factory/convertor";

const baseUrl = process.env.STATIC_BASE_URL
  ? `${process.env.STATIC_BASE_URL}/${emojiDataVersion}`
  : `https://apps-static.flock.com/files/emoji/${emojiDataVersion}`;

const defaultProps = {
  appleImagesBaseUrl: `${baseUrl}/apple/`,
  googleImagesBaseUrl: `${baseUrl}/google/`,
  appleSpritesheetUrl: `${baseUrl}/apple.png`,
  googleSpritesheetUrl: `${baseUrl}/google.png`,
  emojiClass: "emoji",
  emojiOnlyClass: "emoji-only",
  vendor: "",
};

const REGEX_STARTS_WITH_COLON = /^:/;

const skinTonePrefix = ":skin-tone-";

export class Convertor extends ConvertorFactory {
  constructor(props) {
    super({ ...defaultProps, ...props });
  }

  decorate(target, options = {}) {
    let res = target;
    if (typeof target === "string") {
      res = this._decorateString(target);
    } else if (target.nodeType && target.nodeType === Node.TEXT_NODE) {
      res = this._decorateTextNode(target);
    } else if (target.nodeType && target.nodeType === Node.ELEMENT_NODE) {
      res = this._decorateElementNode(target, options);
    }
    return this._applyEmojiOnly(res);
  }
  /**
   * @param {string} target - target string to be tokenized with emojis
   * @returns {Array} tokenArray
   */

  getTokenizedEmojis(target) {
    return this._getTokenizedEmojisFromString(target);
  }

  getEmojiById(id) {
    let skinId;
    const emoji = emojiList.find((e) => {
      if (e.id === id) {
        return true;
      }
      return e.skins.some((s) => {
        if (s.id === id) {
          skinId = s.id;
          return true;
        }
      });
    });
    if (skinId) {
      return { ...emoji, skinId };
    }
    return emoji;
  }

  getEmojiByNative(native) {
    return emojiList.find((emoji) => {
      if (emoji.native === native) {
        return true;
      }

      return emoji.skins.some((emojiWithSkin) => {
        if (emojiWithSkin.native === native) {
          return true;
        }
      });
    });
  }

  getEmojiByShortcode(shortcode) {
    const skinToneIndex = shortcode.indexOf(skinTonePrefix);
    let shortcodeWithoutSkin =
      skinToneIndex > -1 ? shortcode.slice(0, skinToneIndex) : shortcode;

    const emoji = emojiList.find((e) => {
      if (e.shortcodes.includes(shortcodeWithoutSkin)) {
        return true;
      }
    });

    if (emoji && skinToneIndex > -1) {
      const skinTone = shortcode.slice(
        skinToneIndex + skinTonePrefix.length,
        -1
      );
      const emojiWithSkin = this.getEmojiWithSkin(emoji, skinTone);
      emoji.skinId = emojiWithSkin.id;
    }
    return emoji;
  }

  getEmojiShortcodeWithSkin(emoji, skinTone) {
    var shortcode = emoji.bestMatch || emoji.shortcodes[0];
    if (emoji.hasSkins && skinTone > 1) {
      return `${shortcode}${skinTonePrefix}${skinTone}:`;
    }
    return shortcode;
  }

  getEmojiWithSkin(emoji, skinTone) {
    skinTone = parseInt(skinTone);
    if (emoji.hasSkins && skinTone > 1 && skinTone <= 6) {
      return emoji.skins[skinTone - 2];
    }
    return emoji;
  }

  getCSSForEmoji(emoji, css = false) {
    const e = emoji[this.props.vendor];
    const s = spritesheetList[this.props.vendor];
    const px = (e.x / (s.width - e.width)) * 100;
    const py = (e.y / (s.height - e.height)) * 100;
    const sx = (s.width / e.width) * 100;
    const sy = (s.height / e.height) * 100;
    if (css) {
      return `
        background-image: url(${this.spritesheetUrl});
        background-position: ${px}% ${py}%;
        background-repeat: no-repeat;
        background-size: ${sx}% ${sy}%;
      `;
    }
    return {
      backgroundImage: `url(${this.spritesheetUrl})`,
      backgroundPosition: `${px}% ${py}%`,
      backgroundRepeat: "no-repeat",
      backgroundSize: `${sx}% ${sy}%`,
    };
  }

  hasEmoji(target) {
    return this._targetHasClass(target, this.props.emojiClass);
  }

  hasOnlyEmoji(target) {
    return this._targetHasClass(target, this.props.emojiOnlyClass);
  }

  listBy(categoryId) {
    if (!categoryId) {
      return emojiList;
    }

    if (!this._listByCategory[categoryId]) {
      this._listByCategory[categoryId] = emojiList
        .filter((e) => e.categoryId === categoryId)
        .sort((a, b) => a.order - b.order);
    }

    return this._listByCategory[categoryId];
  }

  sanitize(str, opts = { skinTone: null, remove: false, shortcodes: false }) {
    let nextStr = "";
    if (str) {
      if (opts.remove) {
        nextStr = this._removeEmojis(str);
      } else if (opts.shortcodes) {
        nextStr = this._convertToShortcodes(str);
      } else {
        nextStr = this._convertToNatives(str, opts.skinTone);
      }
    }
    return nextStr;
  }

  search(term, opts = { recents: [], limit: 0, includeEmoticons: true }) {
    if (!term) return [];

    const normalizedRecentsList = opts.recents.map((recentEmoji) =>
      this.getEmojiByNative(recentEmoji.native)
    );

    if (!opts.includeEmoticons) {
      let isEmoticon = false;
      for (let e in emoticonList) {
        if (emoticonList.hasOwnProperty(e) && e === term) {
          isEmoticon = true;
          break;
        }
      }
      if (isEmoticon) return [];
    }

    term = term.toLowerCase().replace(REGEX_STARTS_WITH_COLON, "");

    const recentsPossibleMatches = normalizedRecentsList.filter((emoji) =>
      emoji.shortcodes.some(
        (shortcode) => shortcode.toLowerCase().indexOf(term) > -1
      )
    );

    const shortcodePossibleMatches = emojiList.filter((emoji) =>
      emoji.shortcodes.some(
        (shortcode) => shortcode.toLowerCase().indexOf(term) > -1
      )
    );

    const keywordPossibleMatches = emojiList.filter((emoji) =>
      emoji.keywords.some(
        (shortcode) => shortcode.toLowerCase().indexOf(term) > -1
      )
    );

    const recentsMatches = sortBy(
      recentsPossibleMatches,
      (recentMatch) =>
        sortBy(
          recentMatch.shortcodes.map((shortcode) =>
            this._searchCompare(term, shortcode.replace(/:/g, ""))
          )
        )[0]
    );

    const shortcodeMatches = sortBy(
      shortcodePossibleMatches,
      (emojiMatch) =>
        sortBy(
          emojiMatch.shortcodes.map((shortcode) =>
            this._searchCompare(term, shortcode.replace(/:/g, ""))
          )
        )[0]
    );

    const keywordMatches = sortBy(
      keywordPossibleMatches,
      (emojiMatch) =>
        sortBy(
          emojiMatch.keywords.map((keyword) =>
            this._searchCompare(term, keyword)
          )
        )[0]
    );

    const matches = uniq([
      ...recentsMatches,
      ...shortcodeMatches,
      ...keywordMatches,
    ]);

    return opts.limit ? matches.slice(0, opts.limit) : matches;
  }

  setVendor(vendor) {
    const prevVendor = this.props.vendor;
    if (vendor && this.vendors.includes(vendor)) {
      this.props.vendor = vendor;
    } else {
      this.props.vendor = util.getEnvVendor();
    }

    if (prevVendor !== this.props.vendor) {
      this._preloadSpritesheets();
    }
  }

  strip(target) {
    return this._removeEmojis(target);
  }

  async getStickerImage(sticker, size = 76) {
    const src = sticker.thumbnail || sticker.image;
    if (!src) return;
    return this._resizeStickerToCanvas(src, size);
  }
}
