export default {
  fileSharing_chatTab_modal_message_fileCountExceedMessage:
    '\u00a1Uy! S\u00f3lo se pueden subir hasta diez archivos a la vez.',
  fileSharing_chatTab_modal_title_fileLimitExceeded:
    'Se ha superado el l\u00edmite de archivos',
  fileSharing_chatTab_modal_message_fileLimitExceed:
    '\u00a1Uy! Los archivos no pueden superar los {fileUploadLimit} MB',
  fileSharing_chatTab_modal_buttonText_cancelFileUpload:
    'Cancelar subida de archivos',
  fileSharing_chatTab_modal_buttonText_completeFileUpload: 'Subir archivos',
  fileSharing_chatTab_modal_title_fileCountExceeded: 'Demasiados archivos',
  fileSharing_chatTab_modal_message_cancelFileUpload:
    '\u00bfSeguro que quieres cancelar la subida de archivos?',
  fileSharing_chatTab_modal_title_abortTitle: 'Suspender subida de archivos',
  fileSharing_chatTab_hover_toolTip: 'Compartir un archivo',
  buttonText_ok: 'Aceptar',
  fileSharing_chatTab_modal_message_invalidFileType:
    'No se ha podido realizar la subida de archivos. Comprueba lo siguiente:  ',
  fileSharing_error_check_folders_cannot_be_uploaded:
    'No se pueden subir carpetas sin comprimir.',
  fileSharing_error_check_connected_to_internet:
    'Aseg\u00farate de que Flock est\u00e1 conectado a Internet.',
  fileSharing_chatTab_modal_failureMessage_someFilesFailed:
    'Debido a un problema, no se ha(n) enviado {numFiles} archivo(s). Comprueba la conexi\u00f3n a internet y vuelve a intentarlo.',
  fileSharing_chatTab_modal_fileShareFailed_title:
    'Error de transferencia de archivos',
  fileSharing_chatTab_modal_failureMessage_allFilesFailed:
    'Debido a un problema, el/los archivo(s) no se ha(n) enviado. Comprueba la conexi\u00f3n a internet y vuelve a intentarlo.',
  fileSharing_chatTab_modal_message_fileShareDisabledByAdmin:
    'Se ha desactivado el intercambio de archivos. Si es preciso, contacta con tu administrador de Flock.',
  fileSharing_chatTab_modal_progressMessage_percentageUploaded:
    '{percentUploaded} % de {totalFileSize}',
  fileSharing_chatTab_modal_filename: 'Nombre de archivo',
  fileSharing_chatTab_modal_title_fileShareDisabledByAdmin:
    'Intercambio de archivos restringido',
  fileSharing_chatTab_modal_title_uploadImage: 'Subir imagen',
  fileSharing_chatTab_notification_sentFile:
    'ha enviado un archivo: {filename}',
  fileSharing_chatTab_notification_sentImage:
    'ha enviado una imagen: {filename}',
  fileSharing_chatTab_uploadingText_fileUploading: 'Subiendo',
  fileSharing_chatTab_chatTab_multipleFileUploadProgressString:
    '{countDone} de {totalCount} archivos',
  fileSharing_chatTab_chatTab_files: 'archivos',

  chatTab_dropdown_groupMemberList_loadingMemberText:
    'Recuperando miembros\u2026',
  chatTab_dropdown_groupMemberList_MultipleMembers: '{member_count} miembros',
  chatTab_inputBox_placeholderText_inviteNewMemberToGroup: 'Invitar al canal',
  chatTab_dropdown_groupMemberList_removeMember: 'Eliminar',
  chatTab_dropdown_groupMemberList_SingleMember: '{member_count} miembro',
  text_addNewMemberToGroup: 'A\u00f1adir',
  remove_member_ok_text: 'S\u00ed, eliminar',
  remove_member_cancel_text: 'No',
  remove_member_confirm_dialog_text:
    '\u00bfEst\u00e1s seguro de que quieres eliminar a {member_name} de este canal?',
  group_name_placeholder: 'Establecer nombre del canal',
  group_purpose_placeholder: 'Establecer finalidad del canal',
  title_remove_contact: '\u00bfEliminar contacto?',

  ATTENTION: '\u00a1Atenci\u00f3n!', // RestrictedActionStrings.js in hydra, missing for en-gb.
  OOPS: '\u00a1Vaya!',
  PERMISSION_DETAILS_MESSAGE:
    '\u00a1Los invitados en Flock no pueden efectuar esta acci\u00f3n!',
  INVITE_PERMISSION_MESSAGE:
    'No tienes permiso para a\u00f1adir invitados a este equipo. Contacta con el administrador de tu equipo.',
  PERMISSION_RESTRICTED: 'Permiso restringido',
  LIMITED_PERMISSIONS:
    'Los invitados en Flock solo pueden acceder a las conversaciones y aplicaciones seleccionadas.',
  PUBLIC_CHANNELS_MESSAGE:
    '\u00a1Vaya! Los invitados en Flock no pueden ver o crear canales p\u00fablicos en este equipo.',
  ADD_GUEST_TITLE: '\u00bfA\u00f1adir un invitado a este canal?',
  ADD_GUEST_DESCRIPTION:
    'Tendr\u00e1n acceso a todos los mensajes y archivos compartidos en el canal.',

  cancel: 'Cancelar',

  chatTab_popup_slashCommand_text_leaveGroupHint: 'abandonar canal',
  chatTab_popup_slashCommand_text_leaveGroupCommand: 'abandonar',
  chatTab_popup_slashCommand_text_cloneGroupHint: 'clonar el canal actual',
  chatTab_popup_text_error_cloneGroupDialog:
    'Lo sentimos, pero los canales de m\u00e1s de {count} miembros no se pueden clonar en este momento.',
  chatTab_popup_slashCommand_text_cloneGroupCommand: 'clonar',
  add_popup: 'A\u00f1adir',
  add_user_to_channel:
    '\u00bfSeguro que quieres a\u00f1adir a {name} al canal?',
  title_add_contact: '\u00bfAgregar contacto?',

  editor_messageDropdown_menu_text_copy: 'Copiar',
  editor_messageDropdown_menu_text_edit: 'Editar',
  editor_messageDropdown_menu_text_retry: 'Reintentar',
  editor_messageDropdown_popup_text_deleteMessage:
    'Se eliminar\u00e1 este mensaje tanto para ti como para el destinatario, y se eliminar\u00e1 permanentemente de los servidores.',
  editor_messageDropdown_popup_title_deleteMessage: '\u00bfEliminar mensaje?',
  editor_messageDropdown_popup_buttonText_deleteMessage_Yes:
    'S\u00ed, eliminar',
  editor_messageDropdown_popup_buttonText_delete: 'Eliminar',
  editor_deletion_failed: 'Acci\u00f3n no autorizada',
  editor_not_connected_to_internet_error:
    'Parece que te has desconectado. Esto se debe normalmente a una conexi\u00f3n a internet mala o nula.',
  'WARNING-GROUP_CLONE_MESSAGE':
    '\u00bfSeguro que quieres clonar este canal? El nuevo canal tendr\u00e1 {member_count} miembros.',
};
