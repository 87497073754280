import bridge from '@/utils/bridge';
import { fetchAffiliates } from '@/utils/ConversationUtils';
import store from '@/store';
import { Actions } from '@/actions/restricted';
import ContactProxy from '@/Proxies/ContactProxy';

function updateState(ownerGuid, peerJid, associates) {
  store.dispatch(
    Actions.update_conversation_associates({ ownerGuid, peerJid, associates })
  );
}

function getKeyFromContactInfo(contact) {
  return `${contact.ownerGuid}~${contact.jid}`;
}

class AssociatesProxy {
  // Stores bridge subscriptions for associate updates
  bridgeSubs = {};

  // Holds contact proxy subscriptions for each group
  contactProxySubs = {};

  // Holds count of subscribers for each peer by group
  subscriberCounts = {};

  addSubscriberCount(peerKey) {
    if (!this.subscriberCounts[peerKey]) {
      this.subscriberCounts[peerKey] = 1;
    } else {
      this.subscriberCounts[peerKey] += 1;
    }
  }

  subtractSubscriberCount(peerKey) {
    if (this.subscriberCounts[peerKey]) {
      this.subscriberCounts[peerKey] -= 1;
    }
  }

  subscribeToContactProxy(peerKey, ownerGuid, associates) {
    const jids = associates.map((assoc) => assoc.jid);
    if (this.contactProxySubs[peerKey]) {
      this.contactProxySubs[peerKey].update(ownerGuid, jids);
    } else {
      this.contactProxySubs[peerKey] = ContactProxy.subscribe(ownerGuid, jids);
    }
  }

  unsubscribeToContactProxy(peerKey) {
    if (this.contactProxySubs[peerKey]) {
      this.contactProxySubs[peerKey].unsubscribe();
    }
  }

  subscribe(peer) {
    const { ownerGuid, jid } = peer;
    const peerKey = getKeyFromContactInfo(peer);
    this.addSubscriberCount(peerKey);

    if (this.bridgeSubs[peerKey]) {
      return {
        unsubscribe: this.unsubscribe.bind(this, peer),
      };
    }

    fetchAffiliates(peer);
    this.updateAssociates(peer);
    bridge
      .subscribe(
        `GroupParticipantManager-${ownerGuid}-${jid}`,
        this.onAssociatesUpdate.bind(this, peer)
      )
      .then((sub) => {
        // Make sure there's only one sub per peer.
        if (this.bridgeSubs[peerKey]) {
          bridge.unsubscribe(this.bridgeSubs[peerKey]);
        }
        this.bridgeSubs[peerKey] = sub;
      });
    return { unsubscribe: this.unsubscribe.bind(this, peer) };
  }

  unsubscribe(peer) {
    const peerKey = getKeyFromContactInfo(peer);
    this.subtractSubscriberCount(peerKey);
    if (this.subscriberCounts[peerKey] <= 0 && this.bridgeSubs[peerKey]) {
      bridge.unsubscribe(this.bridgeSubs[peerKey]);
      delete this.bridgeSubs[peerKey];
      updateState(peer.ownerGuid, peer.jid, []);
      this.unsubscribeToContactProxy(peerKey);
    }
  }

  // peer is the bound value from sub, we might get rid of conversation object from associates event.
  onAssociatesUpdate(peer /* membersInfo */) {
    this.updateAssociates(peer);
  }

  updateAssociates(peer) {
    const peerKey = getKeyFromContactInfo(peer);
    const { ownerGuid } = peer;

    bridge
      .ask('GroupParticipantManager', 'getMembersArray', [peer])
      .then((associates) => {
        updateState(ownerGuid, peer.jid, associates);
        this.subscribeToContactProxy(peerKey, ownerGuid, associates);
      });
  }
}

const AP = new AssociatesProxy();
export default {
  subscribe: AP.subscribe.bind(AP),
};
