import React from "react";
import ReactResizeDetector from "react-resize-detector";
import { observer } from "mobx-react-lite";
import { useStore } from "../../store";

export const ResizeDetector = observer(function ResizeDetector() {
  const store = useStore();
  const onResize = React.useCallback(store.setDimensions, []);

  return (
    <ReactResizeDetector
      handleWidth
      handleHeight
      onResize={onResize}
    />
  );
});
