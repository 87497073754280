export default {
  sender_name_you: 'Voc\u00ea',
  message_sentByMe_header: 'Eu',
  phrase_invited: '{actor} adicionou {user}',
  phrase_removed: '{actor} removeu {user}',
  phrase_joined: '{user} entrou',
  phrase_left: '{user} saiu',
  public_note:
    'Este canal \u00e9 p\u00fablico. Qualquer pessoa na equipe pode ver e participar deste canal',
  private_note:
    'Este canal \u00e9 privado. Apenas membros convidados podem ler e publicar mensagens aqui',
  restricted_note:
    'Este canal \u00e9 p\u00fablico para grupos de usu\u00e1rios espec\u00edficos. Qualquer pessoa nos seguintes grupos pode ver e participar deste canal: {user_groups}',
  notify_public_channel_created:
    '{actor} criou um canal p\u00fablico. Qualquer pessoa na equipe pode ver e participar deste canal',
  notify_private_channel_created:
    '{actor} criou um canal privado. Apenas membros convidados podem ler e publicar mensagens aqui',
  notify_restricted_channel_created:
    '{actor} criou um canal p\u00fablico para os seguintes grupos de usu\u00e1rios: {user_groups}',
  notify_channel_purpose_set:
    '{actor} definiu o prop\u00f3sito do canal como "{channel_purpose}"',
  notify_channel_avatar_change: '{actor} alterou o avatar do canal',
  notify_add_channel_admin: '{actor} tornou {user} um administrador do canal',
  notify_auto_add_channel_admin:
    '{user} \u00e9 agora um administrador de canal',
  notify_remove_channel_admin:
    '{actor} revogou os direitos de administrador do canal de {user}',
  notify_channel_name_change:
    '{actor} alterou o nome do canal para "{channel_name}"',
  notify_channel_type_restricted:
    '{actor} atualizou os crit\u00e9rios de privacidade deste canal. Todos os usu\u00e1rios dos seguintes grupos de usu\u00e1rios podem ver e participar deste canal a partir de agora: {user_groups}. Qualquer usu\u00e1rio fora destes grupos de usu\u00e1rios precisa ser adicionado pelo administrador ou administradores do canal',
  notify_channel_type_public:
    '{actor} atualizou os crit\u00e9rios de privacidade deste canal. Qualquer pessoa na equipe pode ver e participar deste canal',
  notify_channel_type_private:
    '{actor} alterou o tipo do canal para privado. Apenas membros convidados podem ler e publicar mensagens aqui',
  notify_channel_purpose_remove: '{actor} removeu o prop\u00f3sito do canal',
  notify_channel_purpose_change:
    '{actor} alterou o prop\u00f3sito do canal para "{channel_purpose}"',
  notify_channel_grant_admin:
    '{actor} alterou a configura\u00e7\u00e3o do canal para "o administrador pode adicionar/remover membros"',
  notify_channel_grant_everyone:
    '{actor} alterou a configura\u00e7\u00e3o do canal para "todos podem adicionar/remover membros"',
};
