import React, { PureComponent } from 'react';
import EditorPopoverStyleContext from '@/Contexts/EditorPopoverStyle';
import Popover from '@/components/common/Popover';
import currentSessionHOC from '@/connectHOCs/Sessions/currentSession';
import ContactList from '@/components/common/ContactList';
import ContactSearchManager from '@/components/common/ContactSearchManager';
import noop from '@/utils/noop';

class ContactSearch extends PureComponent {
  static defaultProps = {
    searchValue: '',
    onContactSelect: () => {},
    includeTempContact: false,
    currentSession: undefined,
    searchParams: {},
    contacts: undefined,
    bucketIndices: undefined,
    selectOnSpace: false,
    selectOnTab: false,
    headerString: '',
    popOverClass: '',
    className: '',
    position: 'top-left',
    focusOnRender: false,
    popoverStyleFn: noop,
    showCustomFields: false,
    showGroupTypeHeader: true,
    popoverOpen: true,
  };

  render() {
    const {
      currentSession,
      className,
      children,
      headerString,
      position,
      focusOnRender,
      popOverClass,
      searchValue,
      searchParams,
      selectOnSpace,
      selectOnTab,
      contacts: _contacts,
      bucketIndices: _bucketIndices,
      onContactSelect: _onContactSelect,
      includeTempContact,
      showCustomFields,
      showGroupTypeHeader,
      popoverOpen,
    } = this.props;

    if (!currentSession) {
      return null;
    }

    return (
      <ContactSearchManager
        searchValue={searchValue}
        searchParams={searchParams}
        contacts={_contacts}
        bucketIndices={_bucketIndices}
        selectOnSpace={selectOnSpace}
        selectOnTab={selectOnTab}
        onContactSelect={_onContactSelect}
        includeTempContact={includeTempContact}
      >
        {(props) => {
          const {
            activeContactIndex,
            bucketIndices,
            contacts,
            customFieldsMap,
            onUpArrow,
            onDownArrow,
            onEnter,
            onSpace,
            onTab,
            onContactSelect,
          } = props;
          console.log(contacts, bucketIndices);
          return (
            <EditorPopoverStyleContext.Consumer>
              {({ getPositionStyle, popoverTriggerClass }) => (
                <Popover
                  open={popoverOpen}
                  popOverClass={popOverClass}
                  popOverContent={
                    <ContactList
                      className={className}
                      contactList={contacts}
                      activeContactIndex={activeContactIndex}
                      onItemClick={onContactSelect}
                      bucketIndices={bucketIndices}
                      customFieldsMap={customFieldsMap}
                      addButtonBuckets={['recommendation']}
                      showDescription={false}
                      showCustomFields={showCustomFields}
                      showGroupCreatedBy
                      headerString={headerString}
                      showGroupTypeHeader={showGroupTypeHeader}
                    />
                  }
                  position={position}
                  focusOnRender={focusOnRender}
                  getPositionStyle={getPositionStyle}
                  triggerClass={popoverTriggerClass}
                >
                  {children &&
                    children({
                      onUpArrow,
                      onDownArrow,
                      onEnter,
                      onSpace,
                      onTab,
                    })}
                </Popover>
              )}
            </EditorPopoverStyleContext.Consumer>
          );
        }}
      </ContactSearchManager>
    );
  }
}

export default currentSessionHOC(ContactSearch);
