import { createStore, compose } from 'redux';
import allReducers from '../reducers';

// import logger from 'redux-logger';
const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    })) ||
  compose;

const store = createStore(allReducers, {}, composeEnhancers());

export default store;
