import React, { useEffect, useCallback } from 'react';
import Tippy from '@tippyjs/react';
import {
  startUpload,
  sendFileMessage,
  selectFiles,
} from '@/utils/MultiFileUpload';
import { events, OPEN_FILE_PICKER } from '@/utils/KeyboardShortcuts';
import svg from '@/assets/attach.svg';
import commonCss from '@/components/common/common.css';
import { Peer, CurrentSession } from '../Types';
import css from './FilePicker.css';

type Props = {
  peer: Peer;
  currentSession: CurrentSession;
  setFilePickerShortcutFunction: Function;
};

function FilePicker({
  peer,
  currentSession,
  setFilePickerShortcutFunction,
}: Props) {
  const clickHandler = useCallback(() => {
    selectFiles(function (files) {
      const fileUploadPromise = startUpload(files, peer.jid, currentSession);
      fileUploadPromise.then((uploadStatus) => {
        sendFileMessage(uploadStatus, peer);
      });
    });
  }, [peer, currentSession]);

  useEffect(() => {
    setFilePickerShortcutFunction(clickHandler);
  }, [setFilePickerShortcutFunction, clickHandler]);

  return (
    <Tippy
      content={`Attach a file (${events[OPEN_FILE_PICKER].shortcutString})`}
      arrow
      placement='top'
      className={commonCss.info_tooltip}
    >
      <span
        onClick={clickHandler}
        role='button'
        className={css.container}
        onKeyDown={() => {}}
        tabIndex={-1}
      >
        <img src={svg} alt='file_picker' />
      </span>
    </Tippy>
  );
}

export default FilePicker;
