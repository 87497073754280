const defaultState = {
  layout: {
    outerLeft: {
      collapsed: false,
    },
  },
};

export default (ActionTypes) => {
  return (state = defaultState, action) => {
    switch (action.type) {
      case ActionTypes.OUTERLEFT_LAYOUT_CHANGED:
        return {
          ...state,
          layout: {
            ...state.layout,
            outerLeft: {
              ...state.layout.outerLeft,
              collapsed: action.payload,
            },
          },
        };

      default:
        return state;
    }
  };
};
