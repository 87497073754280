import { connect } from 'react-redux';
import throttle from 'lodash/throttle';
import bridge from '@/utils/bridge';
import { Actions } from '../actions/app';

function mapStateToProps(state) {
  return {
    preferredLanguage: state.app.preferredLanguage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getLanguageFromHydra: throttle((preferredLanguage) => {
      bridge.ask('HydraServer', 'getPreferredLanguageFromStore').then(
        (language) => {
          if (preferredLanguage !== language) {
            // @ts-ignore
            dispatch(Actions.set_preferred_language(language));
          }
        },
        (e) => {
          console.error(e);
        }
      );
    }, 500),
  };
}

export default function (component) {
  return connect(mapStateToProps, mapDispatchToProps)(component);
}
