export default {
  heading:
    'Access to Flock for {teamName} is not allowed from this network due to security settings.',
  subheading:
    'Please change your network and try again. For any issues or questions, please contact your admin.',
  try_again: 'Try again',
  subtext:
    'If you want to access some other team on Flock, you can {switch_teams}.',
  switch_teams: 'switch teams',
};
