import parse from '@/utils/flockML';
import { maybe } from '@/utils';
import { scale, getPreviewData } from './image';

const getImageUrlFromReplyMessage = (msg) => {
  // if original message is also a reply itself, its thumbnail should not be carried forward.
  if (!msg.replyOf && msg.attachments) {
    if (maybe(msg.attachments[0], 'views', 'image')) {
      return getPreviewData(msg.attachments[0].views.image);
    }
    if (maybe(msg.attachments[0], 'views', 'sticker', 'src')) {
      return msg.attachments[0].views.sticker;
    }
  }
  return null;
};

// TODO : test this function
const flockmlParser = (flockml) => {
  try {
    return parse(flockml).textContent;
  } catch (e) {
    console.error('ReplyMessage: Exception in flockml parse', e);
    return false;
  }
};

const getDescriptionOfReplyMessage = (msg, MAX_COUNT) => {
  let desc = '';
  if (msg.text) {
    desc = msg.text;
  } else if (msg.flockml) {
    desc = flockmlParser(msg.flockml);
  }
  if (!desc) {
    if (msg.attachments && maybe(msg.attachments[0], 'title')) {
      desc = msg.attachments[0].title;
    } else if (msg.attachments && maybe(msg.attachments[0], 'description')) {
      desc = msg.attachments[0].description;
    } else if (
      msg.attachments &&
      maybe(msg.attachments[0], 'views', 'flockml')
    ) {
      desc = flockmlParser(msg.attachments[0].views.flockml);
    }
  }
  if (!desc) {
    if (
      msg.attachments &&
      maybe(msg.attachments[0], 'views', 'image', 'filename')
    ) {
      desc = msg.attachments[0].views.image.filename;
    } else if (msg.notification) {
      desc = msg.notification;
    }
  }
  if (desc.length > MAX_COUNT) {
    desc = `${desc.slice(0, MAX_COUNT)}...`;
  }
  return desc;
};

const getReplyMessageContent = (replyMessage, MAX_COUNT) => {
  if (!replyMessage) {
    return {};
  }
  const attachment = {
    title: `In reply to ${replyMessage.sender.name}`,
    description: getDescriptionOfReplyMessage(replyMessage, MAX_COUNT),
    color: '#0BBE51',
  };
  const imageDetails = getImageUrlFromReplyMessage(replyMessage);
  if (imageDetails) {
    const { width, height } = scale(imageDetails, 50, 50);
    attachment.views = {
      image: {
        original: {
          src: imageDetails.src,
          width,
          height,
          id: imageDetails.id,
        },
      },
    };
  }
  return {
    replyOf: replyMessage.sid,
    attachments: [attachment],
  };
};

export {
  getReplyMessageContent,
  getImageUrlFromReplyMessage,
  getDescriptionOfReplyMessage,
  flockmlParser,
};
