import bridge from '@/utils/bridge';

let subscriptionIds = [];
let appVersion = null;

export function getSessionId(ownerGuid) {
  return `session_${ownerGuid}@go.to`;
}

export function getCurrentTeamPlanLite(sessionId) {
  return bridge.ask('MorosAPI', 'getCurrentPlanLite', [sessionId]);
}

export function getLegacyTeamDetails(sessionId) {
  return bridge.ask('MorosAPI', 'getLegacyTeamDetails', [sessionId]);
}

export function getUserInfo(token, guid) {
  return bridge.ask('ZeusAPI', 'getUserInfo', [token, guid]);
}

export function publishGenericEvent(eventName, payload) {
  return bridge.publish('/log_analytics/medusa/helper/genericEvent', [
    eventName,
    payload,
  ]);
}

export function unsubscribeNotificationPreferences() {
  bridge.unsubscribeAll(subscriptionIds);
  subscriptionIds = [];
}

export function subscribeNotificationPreferences(sessionId, callback) {
  subscriptionIds.push(
    bridge.subscribe(`/notifications/audio/${sessionId}`, (value) =>
      callback('audioEnabled', value)
    ),
    bridge.subscribe(`/notifications/toasters/${sessionId}`, (value) =>
      callback('toastersEnabled', value)
    ),
    bridge.subscribe(
      `/notifications/videoRingerDisabled/${sessionId}`,
      (value) => callback('videoRingerDisabled', value)
    ),
    bridge.subscribe(
      `/hydra/muteEnabled/${sessionId}`,
      (session, status, muteTime) => callback('muteEnabled', status, muteTime)
    ),
    bridge.subscribe(`/notifications/userOnline/${sessionId}`, (value) =>
      callback('userOnlineNotification', value)
    )
  );
}

export function getNotificationPreferences(sessionId) {
  return bridge.ask('NotificationController', 'getNotificationPreferences', [
    sessionId,
  ]);
}

export function setNotificationPreferences(sessionId, type, value) {
  bridge.tell('NotificationController', 'setNotificationPreferences', [
    sessionId,
    type,
    value,
  ]);
}

export function setUserStatus(status, sessionId) {
  return bridge.ask('UserStatus', 'setUserStatus', [status, sessionId]);
}

export function getUserOnlineNotification(sessionId) {
  return bridge.ask(`Session_${sessionId}`, 'getUserOnlineNotificationStatus');
}

export function setUserOnlineNotification(sessionId, value) {
  bridge.tell(`Session_${sessionId}`, 'setUserOnlineNotificationStatus', [
    value,
  ]);
}

export function joinAndOpenChannel(sessionId, channelId, sourceChatType) {
  bridge.tell(`Session_${sessionId}`, 'joinAndOpenChannel', [
    channelId,
    sourceChatType,
  ]);
}

export function getSoundPreference() {
  return bridge.ask('NotificationController', 'getSoundPreference', []);
}

export function openSoundPreference() {
  bridge.tell('Shell', 'openSoundPreference', []);
}

export function openEmailPreferences(sessionId) {
  bridge.tell('HydraServer', 'openEmailPreferences', [sessionId]);
}

export function openUrl(url, closeAfterOpening = false) {
  bridge.tell('HydraServer', 'openUrl', [url, closeAfterOpening]);
}

export function downloadFromUrl(url, filename) {
  bridge.tell('HydraServer', 'downloadFileFromUrl', [url, filename]);
}

/**
 * @param sessionId - SessionID of the current session
 * @param source - The action source e.g. VIDEO_PAYWALL
 */
export function openUpgradeUrl(sessionId, gaSource) {
  bridge.tell('HydraServer', 'openUpgradeUrl', [sessionId, gaSource]);
}

export function notifyAdminForUpgrade(sessionId, context = '') {
  return bridge.ask(`Session_${sessionId}`, 'requestAdminToUpgrade', [context]);
}

export function openAdminPanel(sessionId) {
  bridge.tell('HydraServer', 'openAdminPanel', [sessionId]);
}

export function openAppStore(sessionId, source, deeplink) {
  bridge.tell('HydraServer', 'openAppStore', [sessionId, source, deeplink]);
}

export function openWidgetPopupInShell(widgetType, params) {
  bridge.tell('HydraServer', 'openWidgetPopupInShell', [widgetType, params]);
}

export function openWidgetFullScreenInShell(moduleId, params) {
  bridge.tell('HydraServer', 'openWidgetFullScreenInShell', [moduleId, params]);
}

export function setMuteTime(sessionId, muteStatus, timeInMinutes) {
  bridge.tell(`Session_${sessionId}`, 'setMuteTime', [
    muteStatus,
    timeInMinutes,
  ]);
}

export function setCurrentSession(sessionId, source = 'NOT_SET') {
  bridge.ask('TeamSessionRegistry', 'changeCurrentSession', [
    sessionId,
    source,
  ]);
}

export function getInternectConnectivityStatus() {
  return bridge.ask('Shell', 'getConnectionState');
}

export function getCurrentAppVersion() {
  if (appVersion) {
    return Promise.resolve(appVersion);
  }
  return bridge.ask('HydraServer', 'getCurrentAppVersion').then((version) => {
    appVersion = version;
    return version;
  });
}

export function leaveTeam(sessionId) {
  bridge.publish('shell/leaveTeam', [sessionId]);
}

export function toggleMute(contact) {
  return bridge
    .ask('TeamSessionRegistry', 'getSessionByOwnerGuid', [contact.ownerGuid])
    .then((session) => {
      return bridge.ask(`Session_${session.id}`, 'toggleMute', [contact.jid]);
    });
}

export function getPlanDetails(sessionId) {
  return getCurrentTeamPlanLite(sessionId);
}

export function getOpenGroupCountNotJoined(sessionId) {
  return bridge.ask(`Session_${sessionId}`, 'getOpenGroupCountNotJoined');
}

export function getPublicChannels(sessionId) {
  return bridge.ask(`Session_${sessionId}`, 'getOpenGroups');
}

export function syncPublicChannels(sessionId) {
  bridge.tell(`Session_${sessionId}`, 'fetchOpenGroups');
}

export function inviteContacts(
  emailIds,
  ownerGuids,
  doNotOpenConversation,
  doNotShowDialog
) {
  return bridge.ask('ZeusAPI', 'invite', [
    emailIds,
    ownerGuids,
    doNotOpenConversation,
    doNotShowDialog,
  ]);
}

export function getCustomFieldsMapFromSession(session) {
  const fieldsInfo =
    session &&
    session.sessionInfo &&
    session.sessionInfo.teamInfo &&
    session.sessionInfo.teamInfo.fields;

  if (!fieldsInfo) {
    return {};
  }
  const customFieldsInfo = {};

  fieldsInfo.forEach((customField) => {
    customFieldsInfo[customField.fieldId] = {
      ...customField,
      id: customField.fieldId,
    };
  });

  return customFieldsInfo;
}

export function getOwnerGuidFromSessionId(sessionId) {
  return sessionId.split('_')[1].split('@')[0];
}

export function getLogFileKeyWithBGUpload() {
  return bridge.ask('Shell', 'getLogFileKeyWithBGUpload');
}
