/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import IntlWrapper from '@/Wrappers/IntlWrapper';
import { openCreateChannelScreen } from '@/utils/ShellUtils';
import { openConversation, joinPublicChannel } from '@/utils/ContactUtils';
import GroupDiscoveryPublicChannelsExist from '@/components/Modal/GroupDiscoveryModal/GroupDiscoveryPublicChannelsExist';
import { getPublicChannels, publishGenericEvent } from '@/utils/SessionUtils';
import { hideModal } from '@/components/Modal/Modal';
import GroupDiscoveryNoPublicChannels from '@/components/Modal/GroupDiscoveryModal/GroupDiscoveryNoPublicChannels';
import GroupDiscoveryGuestView from '@/components/Modal/GroupDiscoveryModal/GroupDiscoveryGuestView';
import currentSessionHOC from '@/connectHOCs/Sessions/currentSession';
import { maybe } from '@/utils';
import translations from './I18n';

class GroupDiscoveryModalComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      publicChannelsList: [],
      noPublicChannelsInTeam: false,
      showButtonLoadingSpinner: false,
      currentSelectedChannel: '',
      isGuest: !!props.currentSession.sessionInfo.guest,
    };
    const { isGuest } = this.state;
    if (!isGuest) {
      this.getAllPublicChannels();
    }
  }

  componentDidUpdate(prevProps) {
    const { currentSession } = this.props;
    if (prevProps.currentSession !== currentSession) {
      this.getAllPublicChannels();
    }
  }

  getAllPublicChannels = () => {
    const { currentSession } = this.props;
    getPublicChannels(currentSession.id).then((res) => {
      if (res.length > 0) {
        this.setState({
          publicChannelsList: [...res],
        });
      } else {
        this.setState({
          noPublicChannelsInTeam: true,
        });
      }
    });
  };

  onJoinChannelClick = (channel) => {
    const channelName = channel.chatName;
    const channelJid = channel.jid;
    const hasJoined = channel.isJoined;
    const channelOwnerGuid = channel.ownerGuid;

    if (!hasJoined) {
      this.setState(
        {
          showButtonLoadingSpinner: true,
          currentSelectedChannel: channelJid,
        },
        () => {
          joinPublicChannel(channel, true).then((res) => {
            if (res.responseType === 'success') {
              const { publicChannelsList } = this.state;

              publicChannelsList.find((el) => {
                return el.jid === channelJid;
              }).isJoined = true;

              this.setState({
                showButtonLoadingSpinner: false,
                currentSelectedChannel: '',
                publicChannelsList: [...publicChannelsList],
              });
              const payload = {
                source: '',
                channel_name: channelName,
              };
              publishGenericEvent('channel_joined', payload);
            } else {
              console.error('Unable to join selected channel');
              this.setState({
                showButtonLoadingSpinner: false,
                currentSelectedChannel: '',
              });
            }
          });
        }
      );
    } else {
      this.openJoinedChannel(channelJid, channelOwnerGuid);
      hideModal();
    }
  };

  openJoinedChannel = (channelJid, ownerGuid) => {
    openConversation({ jid: channelJid, ownerGuid });
  };

  onCreateChannelClick = () => {
    const { currentSession } = this.props;
    openCreateChannelScreen(currentSession, '');
    hideModal();
  };

  render() {
    const {
      noPublicChannelsInTeam,
      publicChannelsList,
      showButtonLoadingSpinner,
      currentSelectedChannel,
      isGuest,
    } = this.state;
    const { currentSession } = this.props;
    const maskChannelMemberCountAfter = maybe(
      currentSession,
      'sessionInfo',
      'teamInfo',
      'channelPreferences',
      'maskChannelMemberCountAfter'
    );

    return (
      <div>
        {!isGuest && (
          <div>
            {!noPublicChannelsInTeam && (
              <GroupDiscoveryPublicChannelsExist
                allPublicChannels={publicChannelsList}
                maskChannelMemberCountAfter={maskChannelMemberCountAfter}
                joinChannel={this.onJoinChannelClick}
                showButtonLoadingSpinner={showButtonLoadingSpinner}
                currentSelectedChannel={currentSelectedChannel}
                createChannel={this.onCreateChannelClick}
              />
            )}

            {noPublicChannelsInTeam && (
              <GroupDiscoveryNoPublicChannels
                createChannel={this.onCreateChannelClick}
              />
            )}
          </div>
        )}
        {isGuest && (
          <div>
            <GroupDiscoveryGuestView />
          </div>
        )}
      </div>
    );
  }
}

export default IntlWrapper(
  currentSessionHOC(GroupDiscoveryModalComponent),
  translations
);
