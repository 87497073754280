import "./polyfill";
import "mobx-react-lite/batchingForReactDom";
import { Convertor as Converter } from "./convertor";
import { Picker, LegacyPicker } from "./picker";
import { useStore as useEmojiStore } from "./picker/store";

const EmojiUtil = {
  Convertor: Converter,
  Picker: LegacyPicker,
};

export default EmojiUtil;

export { Picker, Converter, useEmojiStore };
