const weekdays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
const monthsFull = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const monthsShort = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
];

export function getFormattedTime(timeInMinutes) {
  // Get formatted time... after the given duration timeInMinutes
  const date = new Date();
  let hour = date.getHours();
  let min = date.getMinutes() + parseInt(timeInMinutes, 10);
  let phase;
  if (min >= 60) {
    hour = parseInt(hour + min / 60, 10);
    min %= 60;
  }
  if (hour >= 24) {
    hour %= 24;
  }
  phase = 'AM';
  if (hour >= 12) {
    phase = 'PM';
  }
  hour %= 12;
  if (hour === 0) hour = 12;
  if (min < 10) min = `0${min}`;

  return `${hour}:${min} ${phase}`;
}

export function formatDate(/* Date */ date, /* String */ pattern) {
  return pattern.replace(
    /dddd|dd|d|MMMM|MMM|MM|Mmm|yyyy|h|mm|as|a/g,
    (match) => {
      let h;
      let m;
      let dd;
      let mm;
      switch (match) {
        case 'dddd':
          return weekdays[date.getDay()];
        case 'dd':
          dd = date.getDate();
          return dd < 10 ? `0${dd}` : dd;
        case 'd':
          return date.getDate();
        case 'MMMM':
          return monthsFull[date.getMonth()];
        case 'MMM':
          return monthsShort[date.getMonth()];
        case 'MM':
          mm = date.getMonth() + 1;
          return mm < 10 ? `0${mm}` : mm; // getMonth() method returns month from 0 to 11.
        case 'Mmm': {
          const month = monthsShort[date.getMonth()];
          return month.charAt(0) + month.slice(1).toLowerCase();
        }
        case 'yyyy':
          return date.getFullYear();
        case 'h':
          h = date.getHours();
          if (h < 13) {
            return h === 0 ? 12 : h;
          }
          return h - 12;
        case 'mm':
          m = date.getMinutes();
          return m < 10 ? `0${m}` : m;
        case 'as':
          return date.getHours() < 12 ? 'am' : 'pm';
        case 'a':
          return date.getHours() < 12 ? 'AM' : 'PM';
        default:
          return '';
      }
    }
  );
}

export function justDate(t) {
  const d = new Date(t);
  return new Date(d.getFullYear(), d.getMonth(), d.getDate());
}

function dayDiff(dateFrom, dateTo) {
  // returns number of days between the two dates (ignoring time of day)
  // returns 0 if same day
  return Math.floor(
    (justDate(dateTo) - justDate(dateFrom)) / (24 * 60 * 60 * 1000)
  );
}

export function niceString(d) {
  const today = new Date();
  const diff = dayDiff(d, today);
  switch (diff) {
    case 0:
      return 'Today';
    case 1:
      return 'Yesterday';
    default:
      if (today.getFullYear() === d.getFullYear()) {
        return formatDate(d, 'dddd, d MMMM');
      }
      return formatDate(d, 'dddd, d MMMM yyyy');
  }
}

export function editedDateString(d) {
  const diff = dayDiff(d, new Date());
  const time = formatDate(d, 'h:mm a');

  switch (diff) {
    case 0:
      return `${time}, Today`;
    case 1:
      return `${time}, Yesterday`;
    default:
      return `${time}, ${formatDate(d, 'dd Mmm')}`;
  }
}
