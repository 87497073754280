import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import ChannelPaywall from '@/components/ChannelPaywall';
import { currentSessionSelector, currentSessionIdSelector } from '@/selectors';
import { maybe } from '@/utils';

const mapStateToProps = createSelector(
  currentSessionSelector,
  currentSessionIdSelector,
  (currentSession, sessionId) => ({
    sessionId,
    teamPlan: maybe(currentSession, 'sessionInfo', 'teamPlan'),
    isAdmin: maybe(currentSession, 'sessionInfo', 'teamInfo', 'isAdmin'),
  })
);
export default connect(mapStateToProps)(ChannelPaywall);
