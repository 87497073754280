import LanguageProxy from '@/Proxies/LanguageProxy';

export default function getTranslatedString(stringId, i18n, params) {
  const currentLanguage = LanguageProxy.getLanguageCode();
  let msg = i18n[currentLanguage][stringId];
  if (!msg) {
    msg = i18n['en-US'][stringId];
  }
  let finalMsg = '';
  let key = '';
  let addChar = true;
  msg.split('').forEach((m) => {
    if (m === '{') {
      addChar = false;
    }
    if (addChar) {
      finalMsg += m;
    } else if (m !== '{' && m !== '}') {
      key += m;
    }
    if (m === '}') {
      addChar = true;
      finalMsg += params[key];
      key = '';
    }
  });
  return finalMsg;
}
