import React, { PureComponent } from 'react';
import cx from 'classnames';
import Popover from '@/components/common/Popover';
import currentSessionHOC from '@/connectHOCs/Sessions/currentSession';
import { updateAffiliationOfAssociate } from '@/utils/ConversationUtils';
import { ChatsProxy } from '@/Proxies';
import { Deactivated as DeactivatedBadge } from '@/components/common/badges';
import presenceStyles from '@/components/common/common.css';
import css from './AssociateItem.css';

class AssociateItem extends PureComponent {
  static defaultProps = {
    associate: undefined,
    peer: undefined,
    closePopover: () => {},
  };

  state = {
    showOptions: false,
  };

  openConversation = () => {
    const { associate, closePopover } = this.props;
    const { type } = associate;
    if (type === 'owner') {
      return;
    }
    ChatsProxy.openConversation(associate, true);
    closePopover();
  };

  showOptions = (e) => {
    e.stopPropagation();
    this.setState({
      showOptions: true,
    });
  };

  hideOptions = () => {
    this.setState({ showOptions: false });
  };

  makeAdmin = (e) => {
    e.stopPropagation();
    const { peer, associate } = this.props;
    updateAffiliationOfAssociate(peer, associate, 'moderator', false);
    this.hideOptions();
  };

  removeAdmin = (e) => {
    e.stopPropagation();
    const { peer, associate } = this.props;
    updateAffiliationOfAssociate(peer, associate, 'member', false);
    this.hideOptions();
  };

  removeMember = (e) => {
    e.stopPropagation();
    const { peer, associate } = this.props;
    updateAffiliationOfAssociate(peer, associate, 'none', false);
    this.hideOptions();
  };

  canBeMadeAdmin() {
    const { associate, peer } = this.props;
    const { affiliation, guest } = associate;
    const { hasAdminChangePrivilege } = peer;

    return affiliation !== 'moderator' && !guest && hasAdminChangePrivilege;
  }

  canBeRemovedAsAdmin() {
    const { associate, peer } = this.props;
    const { affiliation } = associate;
    const { hasAdminChangePrivilege } = peer;

    return affiliation === 'moderator' && hasAdminChangePrivilege;
  }

  canBeRemovedAsMember() {
    const {
      associate,
      peer,
      currentSession: {
        owner: { guest: isOwnerGuest },
      },
    } = this.props;
    const { affiliation } = associate;
    const { hasAdminChangePrivilege, hasRemoveMemberPrivilege } = peer;

    return (
      !isOwnerGuest &&
      hasRemoveMemberPrivilege &&
      (affiliation !== 'moderator' ||
        (affiliation === 'moderator' && hasAdminChangePrivilege))
    );
  }

  shouldShowOptionsArrow() {
    const {
      associate: { type },
    } = this.props;
    if (type === 'owner') {
      return false;
    }
    return !!(
      this.canBeMadeAdmin() ||
      this.canBeRemovedAsAdmin() ||
      this.canBeRemovedAsMember()
    );
  }

  getOptions() {
    if (!this.shouldShowOptionsArrow()) {
      return null;
    }

    return (
      <ul className={`${css.OptionsPopover} Child-of-Header`}>
        {this.canBeRemovedAsAdmin() ? (
          <li
            className={css.Item}
            onClick={this.removeAdmin}
            onKeyDown={() => {}}
            role='option'
            aria-selected='false'
            tabIndex='-1'
          >
            Remove as admin
          </li>
        ) : null}
        {this.canBeMadeAdmin() ? (
          <li
            className={css.Item}
            onClick={this.makeAdmin}
            onKeyDown={() => {}}
            role='option'
            aria-selected='false'
            tabIndex='-1'
          >
            Make admin
          </li>
        ) : null}
        {this.canBeRemovedAsMember() ? (
          <li
            className={css.Item}
            onClick={this.removeMember}
            onKeyDown={() => {}}
            role='option'
            aria-selected='false'
            tabIndex='-1'
          >
            Remove from channel
          </li>
        ) : null}
      </ul>
    );
  }

  render() {
    const { associate } = this.props;
    if (!associate) {
      return null;
    }
    const { showOptions } = this.state;

    const {
      name,
      imageUrl,
      presence,
      guest,
      affiliation,
      type,
      isDeactivated = false,
    } = associate;
    const isAdmin = affiliation === 'moderator';
    const isSelf = type === 'owner';
    return (
      <div
        className={cx(css.Item, {
          [css.showOptions]: showOptions,
          [css.Deactivated]: isDeactivated,
        })}
        onClick={this.openConversation}
        onKeyDown={() => {}}
        tabIndex={0}
        role='button'
      >
        <div className={css.ImageWrapper}>
          <img src={imageUrl} className={css.Image} alt={name} />
          {!isDeactivated && (
            <div
              className={`${presenceStyles.presence_base} ${
                presenceStyles[`presence--${presence}`]
              } ${css.Presence}`}
            />
          )}
        </div>
        <div className={css.NameContainer}>
          <span className={css.Name}>{name}</span>
          <span>&nbsp;{isSelf ? '(You)' : null}</span>
          {isDeactivated && (
            <DeactivatedBadge variant='list-item' marginLeft='5px' />
          )}
        </div>
        {isAdmin ? <div className={css.AssociateType}> (admin)</div> : null}
        {guest ? <div className={css.AssociateType}> (guest)</div> : null}
        {this.shouldShowOptionsArrow() ? (
          <Popover
            open={showOptions}
            onClick={this.showOptions}
            onClickOutside={this.hideOptions}
            popOverContent={this.getOptions()}
            triggerClass={css.Popover}
            position='bottom-center'
            heightDifference={20}
            widthDifference={-50}
            focusOnRender={false}
          >
            <div className={css.OptionsTriggerWrapper}>
              <span className={css.OptionsTrigger} />
            </div>
          </Popover>
        ) : null}
      </div>
    );
  }
}

export default currentSessionHOC(AssociateItem);
