export const SEARCH = 'start-search';
export const DIRECTORY = 'directory';
export const KEYBOARD_SHORTCUTS = 'keyboard-shortcuts';
export const SEARCH_MESSAGES = 'search-messages';
export const FOCUS_MESSAGE_EDITOR = 'focus-message-editor';
export const SWITCH_TEAM = 'switch-team';
export const REMOVE_CHAT = 'remove-chat';
export const REOPEN_CONVERSATION = 'reopen-conversation';
export const NAVIGATE_CONVERSATION = 'navigate-conversation';
export const OPEN_FILE_PICKER = 'open-file-picker';
export const SECONDARY_NAVIGATION = 'secondary-navigation';
