import addPeople from './addPeople';
import cloneChannel from './cloneChannel';
import leaveChannel from './leaveChannel';
import muteChannel from './muteChannel';
import setStatus from './setStatus';
import unmuteChannel from './unmuteChannel';
import viewShortcuts from './viewShortcuts';

export const commonCommands = [
  addPeople,
  muteChannel,
  unmuteChannel,
  setStatus,
  viewShortcuts,
];

export const channelCommands = [leaveChannel, cloneChannel];

export default [commonCommands, channelCommands];

export { default as videoRecording } from './videoRecording';
