import React from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../store";

const categoryIcons = {
  activity: "https://apps-static.flock.com/emojis/4.1.0/emoji-activity.svg",
  flags: "https://apps-static.flock.com/emojis/4.1.0/emoji-flags.svg",
  "food-drink": "https://apps-static.flock.com/emojis/4.1.0/emoji-food.svg",
  nature: "https://apps-static.flock.com/emojis/4.1.0/emoji-nature.svg",
  objects: "https://apps-static.flock.com/emojis/4.1.0/emoji-objects.svg",
  people: "https://apps-static.flock.com/emojis/4.1.0/emoji-people.svg",
  recent: "https://apps-static.flock.com/emojis/4.1.0/emoji-recent.svg",
  symbols: "https://apps-static.flock.com/emojis/4.1.0/emoji-symbols.svg",
  "travel-places": "https://apps-static.flock.com/emojis/4.1.0/emoji-places.svg"
};

export const TabItem = observer(function TabItem({ index, style }) {
  const store = useStore();
  const data = store.tabList[index];
  const url = data.image || categoryIcons[data.id];

  if (!url) {
    return null;
  }
  return (
    <div
      style={style}
      className="tab-item-root"
      data-picker-tab-item-id={data.id}
      data-picker-tab-item
    >
      <span className="icon" />

      <style jsx>{`
        .tab-item-root {
          display: flex;
          justify-content: center;
          align-items: center;
          width: calc(100% - 1px) !important;
          padding: 12px 0;
          cursor: pointer;
        }
        .icon {
          display: block;
          width: 18px;
          height: 18px;
          pointer-events: none;
          mask: url(${url}) 0 0 / cover no-repeat;
        }
      `}</style>
      <style jsx>{`
        .tab-item-root {
          background-color: ${store.activeCategory === data.id
            ? store.colors.secondaryBg
            : ""};
        }
        .icon {
          background-color: ${store.colors.secondaryText};
        }
      `}</style>
    </div>
  );
});
