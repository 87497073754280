import { confirmAndLeaveChannel } from '@/utils/ConversationUtils';
import getTranslatedString from '@/utils/getTranslatedString';
import I18N from './I18N';

const command = {
  name: getTranslatedString('command_leaveChannel_name', I18N),
  hint: getTranslatedString('command_leaveChannel_hint', I18N),
  shouldIgnore() {
    return false;
  },
  action(textContent, peer /* owner, sessionId, conversation */) {
    if (!peer.hasLeaveGroupPrivilege) {
      return false;
    }
    confirmAndLeaveChannel(peer);
    return true;
  },
};

export default command;
