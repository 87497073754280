export default {
  user_status_available: 'Disponible',
  user_status_busy: 'Ocupado',
  user_status_away_from_desk: 'Lejos del escritorio',
  user_status_working_from_home: 'Trabajando desde casa',
  user_status_on_leave: 'De permiso',
  update: 'Actualizar',
  updated: 'Actualizado',
  error_occurred:
    'Se ha producido un error. Vuelve a intentarlo m\u00e1s tarde.',
  oops: '\u00a1Vaya!',
  okay: 'Aceptar',
};
