import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { doAppAction } from '@/utils/ShellUtils';
import parse from '@/utils/flockML';
import {
  getStyles,
  ChannelLink,
  UserMention,
  GroupMention,
  LinkElement,
} from './FlockMLUtils';
import TextMessageDecorator from './TextMessageDecorator';
import cssStyles from './Message.css';

const _removeFromArray = (array, item) => {
  const index = array.indexOf(item);
  if (index > -1) {
    array.splice(index, 1);
  }
};

export default class FlockML extends PureComponent {
  static propTypes = {
    appId: PropTypes.string,
    // attachmentId: PropTypes.string,
    currentSessionId: PropTypes.string.isRequired,
    peerType: PropTypes.string,
    ownerGuid: PropTypes.string.isRequired,
    flockml: PropTypes.string.isRequired,
    messageElements: PropTypes.object,
  };

  ignoreTextDecorators = [];

  static defaultProps = {
    appId: null,
    peerType: '',
    messageElements: {},
  };

  span(element) {
    return (
      <span style={getStyles(element)}>{this.traverseChildren(element)}</span>
    );
  }

  strong(element) {
    return <strong>{this.traverseChildren(element)}</strong>;
  }

  em(element) {
    return <em>{this.traverseChildren(element)}</em>;
  }

  underline(element) {
    return <u>{this.traverseChildren(element)}</u>;
  }

  deletedText(element) {
    return <del>{this.traverseChildren(element)}</del>;
  }

  userMention(element) {
    const { ownerGuid, messageElements } = this.props;
    const userId =
      element.getAttribute('jid') || element.getAttribute('userId');
    const group = element.getAttribute('group');

    const { mentions = [] } = messageElements;
    if (group) {
      const mentionObj = mentions.find((m) => m.type === group);
      if (mentionObj) {
        return (
          <GroupMention
            textContent={element.textContent}
            isMeMention={mentionObj.mentionsMe}
          />
        );
      }
    } else if (userId) {
      return (
        <UserMention
          textContent={element.textContent}
          jid={`${userId.substr(2)}@go.to`}
          ownerGuid={ownerGuid}
        />
      );
    }

    return <React.Fragment>{this.traverseChildren(element)}</React.Fragment>;
  }

  channelTag(element) {
    const { currentSessionId, peerType, ownerGuid } = this.props;
    const channelId = element.getAttribute('channelId');

    if (channelId) {
      return (
        <ChannelLink
          peerType={peerType}
          currentSessionId={currentSessionId}
          element={element}
          jid={`${channelId.substr(2)}@groups.go.to`}
          ownerGuid={ownerGuid}
        />
      );
    }
    return <React.Fragment>{this.traverseChildren(element)}</React.Fragment>;
  }

  anchor(element) {
    const href = element.getAttribute('href');
    if (href) {
      return this.link(element, href);
    }
    return <React.Fragment>{this.traverseChildren(element)}</React.Fragment>;
  }

  link(element, href) {
    return (
      <LinkElement style={getStyles(element)} href={href}>
        {this.traverseChildren(element)}
      </LinkElement>
    );
  }

  action = (element) => {
    const { msgId, msgSid, appId, peer, currentSessionId } = this.props;
    const allowedAttributes = {
      openWidget: ['url', 'desktopType', 'mobileType', 'height', 'width'],
      openBrowser: ['url', 'sendContext'],
      sendToAppService: [],
      sendEvent: [],
      openScreen: ['screen', 'chat'],
    };
    const flockmlParams = {
      name: 'client.flockmlAction',
      messageId: msgId || '',
      messageUid: msgSid || '',
    };
    const gaSource = 'flockML';
    const id = element.getAttribute('id');
    const type = element.getAttribute('type');
    const context = { actionId: id, ...flockmlParams };
    const params = { id, type };

    if (type && allowedAttributes[type]) {
      const allowedActionAttributes = allowedAttributes[type];
      allowedActionAttributes.forEach((actionAttribute) => {
        const value = element.getAttribute(actionAttribute);
        if (value) {
          params[actionAttribute] = value;
        }
      });
    }

    const mandatoryUrlActionsTypes = ['openWidget', 'openBrowser'];
    const data = {
      params,
      appId,
      peer,
      currentSessionId,
      context,
      gaSource,
      mandatoryUrlActionsTypes,
      type,
    };
    return this.renderActionElement(element, data);
  };

  renderActionElement = (element, data) => {
    const {
      params,
      appId,
      peer,
      currentSessionId,
      context,
      gaSource,
      mandatoryUrlActionsTypes,
      type: actionType,
    } = data;
    if (!mandatoryUrlActionsTypes.includes(actionType) || params.url) {
      const actionData = params;
      // TODO : check if app for appId  exists or not
      return (
        <LinkElement
          onClick={() => {
            doAppAction(
              actionData,
              appId,
              peer,
              currentSessionId,
              context,
              gaSource
            );
          }}
        >
          {this.traverseChildren(element)}
        </LinkElement>
      );
    }

    return <React.Fragment>{this.traverseChildren(element)}</React.Fragment>;
  };

  traverse = (element) => {
    switch (element.nodeName) {
      case '#text':
        return TextMessageDecorator(
          element.textContent,
          undefined,
          undefined,
          this.ignoreTextDecorators
        );
      case 'BR':
        return <br />;
      case 'SPAN':
        return this.span(element);
      case 'B':
      case 'STRONG':
        return this.strong(element);
      case 'I':
      case 'EM':
        return this.em(element);
      case 'U':
        return this.underline(element);
      case 'DEL':
        return this.deletedText(element);
      case 'ACTION':
        return this.action(element);
      case 'USER':
        return this.userMention(element);
      case 'CHANNEL':
        return this.channelTag(element);
      case 'A': {
        this.ignoreTextDecorators.push('hyperlink');
        const returnValue = this.anchor(element);
        _removeFromArray(this.ignoreTextDecorators, 'hyperlink');
        return returnValue;
      }
      default:
        return (
          <React.Fragment>{this.traverseChildren(element)}</React.Fragment>
        );
    }
  };

  traverseChildren = (element) => {
    return [...element.childNodes].map(this.traverse);
  };

  render() {
    const { flockml } = this.props;
    const flockMLObj = parse(flockml);
    this.ignoreTextDecorators = [];
    return (
      <div className={cssStyles.flockMLRoot}>{this.traverse(flockMLObj)}</div>
    );
  }
}
