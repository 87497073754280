export default {
  GET_THE_APP: 'Obtenga la aplicaci\u00f3n',
  MAKE_FLOCK_BETTER_APPS: 'Mejorar Flock con aplicaciones',
  groupPurpose_blank_value:
    'Establezca aqu\u00ed el prop\u00f3sito de su canal',
  messageArea_chatTab_text_historyButton:
    'Este es el comienzo de su conversación con {name}',
  me_tab_user_status:
    'Guarde r\u00e1pidamente mensajes, archivos o v\u00ednculos para leerlos despu\u00e9s. \u00a1Haga el seguimiento de sus pendientes personales o cree notas sin ning\u00fan problema!',
  me_tab_your_space: 'Su espacio personal',
  visible_to_me_text: 'Solo usted puedes ver esto',
  me_text: 'Yo',
  history_fetch_error: 'Algo sali\u00f3 mal',
  retry: 'Volver a intentar',
  pay_wall_user:
    'Aqu\u00ed hay m\u00e1s mensajes, pero exceden el l\u00edmite de su plan gratuito. Solicite a su administrador que actualice a su equipo para desbloquear desbloquear todos los mensajes anteriores',
  pay_wall_admin:
    'Aqu\u00ed hay m\u00e1s mensajes, pero exceden el l\u00edmite de su plan gratuito. Actualice a su equipo para desbloquear todos los mensajes anteriores',
};
