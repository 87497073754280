import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import bridge from '@/utils/bridge';
import { MESSAGE_READING_STATE, MESSAGE_READING_TIME } from '@/utils/constants';
import { getReceiptState, getMessageReadingState } from '@/utils/ReceiptUtils';
import IntlWrapper from '@/Wrappers/IntlWrapper';
import MessageListActions from '@/Contexts/MessageListActions';
import ChatMessage from './ChatMessage';
import translations from './I18N';

const { UNREAD, READING, READ } = MESSAGE_READING_STATE;

function isInfoMessage(message) {
  return message.isInfoMessage || message.muc;
}

function Message(props) {
  const { message, receiptObj, isLastMessage } = props;
  const [readingState, setReadingState] = useState(null);
  const receiptState = getReceiptState(receiptObj, message);
  const [showEditState, setShowEditState] = useState(false);
  const messageListActions = useContext(MessageListActions);

  useMemo(() => {
    if (showEditState) {
      // Anchor message element before it is replaced by edit
      // message composer.
      messageListActions.scrollToMessageBySid(
        message.sid,
        'top',
        false,
        'edit'
      );
    }
  }, [showEditState, messageListActions, message.sid]);

  useEffect(
    function () {
      const sub = [
        bridge.subscribe(`/editMessage`, (msg) => {
          if (msg && msg.id === message.id && !showEditState) {
            setShowEditState(true);
          }
          if (msg && msg.id !== message.id && showEditState) {
            setShowEditState(false);
          }
        }),
      ];
      return () => {
        bridge.unsubscribeAll(sub);
      };
    },
    [message.id, showEditState]
  );

  useEffect(
    function () {
      const sub = [
        bridge.subscribe(
          `/pw/messagecache/messageupdate/${message.ownerGuid}/${message.peer.jid}`,
          (msg, changesArray) => {
            if (
              msg.id === message.id &&
              changesArray.find((change) => change.attr === 'editedOn')
            ) {
              setShowEditState(false);
            }
          }
        ),
      ];
      return () => {
        bridge.unsubscribeAll(sub);
      };
    },
    [message.ownerGuid, message.peer.jid, message.id]
  );

  useEffect(() => {
    const updatedReadingState = getMessageReadingState(receiptObj, message);
    if (updatedReadingState !== readingState && readingState !== READING) {
      if (readingState === UNREAD && updatedReadingState === READ) {
        // only set reading state when state changes read from unread
        setReadingState(READING);
      } else {
        setReadingState(updatedReadingState);
      }
    }
  }, [receiptObj, message, readingState]);

  useEffect(() => {
    if (readingState === READING) {
      setTimeout(() => {
        setReadingState(READ);
      }, MESSAGE_READING_TIME);
    }
  }, [readingState]);

  const setEditStateToFalse = useCallback(() => {
    setShowEditState(false);
  }, []);

  return isInfoMessage(message) ? null : (
    <ChatMessage
      {...props}
      isLastMessage={isLastMessage}
      showEditState={showEditState}
      receiptState={receiptState}
      readingState={readingState}
      setEditStateToFalse={setEditStateToFalse}
    />
  );
}

export default IntlWrapper(Message, translations);
