import ReactDOM from 'react-dom';

/**
 * Documentation: https://reactjs.org/docs/portals.html
 *
 * @param {object} props
 * @param {children} props.children
 * @param {mountPoint} props.mountPoint
 * @returns {React.Component}
 */
const Portal = ({ children, mountPoint }) =>
  ReactDOM.createPortal(children, mountPoint);
export default Portal;
