import React from "react";
import { observer } from "mobx-react-lite";
import { Detail } from "./detail/detail";
import { Container } from "./layout/container";
import { Content } from "./layout/content";
import { ResizeDetector } from "./layout/resizeDetector";
import { Sidebar } from "./layout/sidebar";
import { Floater } from "./list/floater";
import { List } from "./list/list";
import { SearchInput } from "./search/searchInput";
import { SearchResults } from "./search/searchResults";
import { Tabs } from "./tabs/tabs";
import { useStore } from "../store";

export const App = observer(function App(props) {
  const store = useStore();

  React.useEffect(() => store.setOptions(props), [props.stickers, props.skin, props.onEmoji]);
  React.useEffect(
    () =>
      props.register({
        reset: store.reset,
        setRecents: store.setRecents,
        setSkin: store.setSkin,
        setTheme: store.setTheme,
        setVendor: store.setVendor,
      }),
    []
  );

  return (
    <React.Fragment>
      <ResizeDetector />
      <Container>
        <Sidebar>
          <Tabs />
        </Sidebar>
        <Content>
          <SearchInput />
          <Floater />
          <List />
          <SearchResults />
          <Detail />
        </Content>
      </Container>
    </React.Fragment>
  );
});
