import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import { KEYS } from '@/utils/constants';
import {
  selectContact,
  getBucketedSearchContacts,
  getTopRecentContacts,
} from '@/utils/ContactUtils';
import OwnerGuidContext from '@/Contexts/OwnerGuidProvider';
import selectedContactsInSearch from '@/connectHOCs/Contacts/selectedContactsInSearch';
import currentSessionHOC from '@/connectHOCs/Sessions/currentSession';
import { getContactForId, isContactIdSpecial } from '@/Models/Contact';
import { isValidEmail } from '@/utils';
import getBucketedList from '@/utils/BucketedList';
import {
  getCustomFieldsMapFromSession,
  syncPublicChannels,
} from '@/utils/SessionUtils';
import ContactList from '@/components/common/ContactList';
import SearchInput from './SearchInput';
import FrequentRecentSwitcher from './FrequentRecentSwitcher';
import cssStyles from './ContactSearch.css';

class ContactSearch extends PureComponent {
  updateContactList = () => {
    const {
      ContactSearchConfig: { listType },
    } = this.props;
    if (listType === 'frequent') {
      this.updateBucketedSearch();
    } else {
      this.updateTopRecentContacts();
    }
  };

  static propTypes = {
    guid: PropTypes.string,
    currentSession: PropTypes.object,
    selectedContacts: PropTypes.array,
    closeSearch: PropTypes.func,
    ContactSearchConfig: PropTypes.object.isRequired,
  };

  static defaultProps = {
    guid: '',
    currentSession: {},
    selectedContacts: [],
    closeSearch: () => {},
  };

  state = {
    searchText: '',
    activeContactIndex: 0,
    searchResults: [],
  };

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown);
    this.updateContactList();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      guid: prevGuid,
      ContactSearchConfig: { listType: prevListType },
    } = prevProps;

    const {
      currentSession,
      guid,
      ContactSearchConfig: { listType },
    } = this.props;

    const { searchText: prevSearchText } = prevState;
    const { searchText } = this.state;

    if (
      prevGuid !== guid ||
      listType !== prevListType ||
      searchText !== prevSearchText
    ) {
      // also trigger fetch from server as new public channels are not pushed to client
      syncPublicChannels(currentSession.id);
      this.updateContactList();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  onItemClick = (contactId) => {
    const { selectedContacts, guid } = this.props;
    const contact = getContactForId(contactId, guid);

    const isDeactivated = contact.isDeactivated ?? false;

    // We don't want to add the user to the selected list
    // if the user is deactivated. But, if it's just to
    // open the DM, then proceed.
    if (selectedContacts.length > 0 && isDeactivated) {
      return;
    }

    this.onContactSelect(contactId);
  };

  onTab(event) {
    const { activeContactIndex, searchText } = this.state;
    const { currentSession, guid } = this.props;
    const contactsList = this.getContactsList(searchText);
    const contactId = contactsList[activeContactIndex];
    event.preventDefault();

    if (currentSession.owner.guest || !currentSession.owner.isLicensed) {
      this.onContactSelect(contactId);
      return;
    }

    if (!contactId) {
      return;
    }

    const contact = getContactForId(contactId, guid);

    if (contact.isDeactivated ?? false) {
      return;
    }

    switch (contact.type) {
      case 'buddy':
      case 'email': {
        this.addToSelectedContacts(contactId);
        break;
      }
      case 'temp_search': {
        if (!isValidEmail(contact.email)) {
          this.onContactSelect(contactId);
        } else {
          this.addToSelectedContacts(contactId);
        }
        break;
      }
      case 'group':
      default: {
        this.onContactSelect(contactId);
      }
    }

    this.onSearchTextChange('');
  }

  onEnter(event) {
    event.preventDefault();
    const { activeContactIndex, searchText } = this.state;
    const contactsList = this.getContactsList(searchText);
    const contactId = contactsList[activeContactIndex];
    this.onContactSelect(contactId);
  }

  onKeyDown = (e) => {
    const { keyCode } = e;
    const { searchText } = this.state;
    if (searchText) {
      switch (keyCode) {
        case KEYS.DOWN_ARROW:
        case KEYS.UP_ARROW:
          e.preventDefault();
          break;
        case KEYS.LEFT_ARROW:
        case KEYS.RIGHT_ARROW:
        case KEYS.BACKSPACE:
          return;
        default:
      }
    }

    switch (keyCode) {
      case KEYS.DOWN_ARROW:
        this.moveActiveContactIndex('down');
        break;
      case KEYS.UP_ARROW:
        this.moveActiveContactIndex('up');
        break;
      case KEYS.TAB:
        this.onTab(e);
        break;
      case KEYS.BACKSPACE:
        this.removeLastFromSelectedContacts();
        break;
      case KEYS.ENTER:
        this.onEnter(e);
        break;
      default:
    }
  };

  onBlur = (e) => {
    e.persist();
    const { onBlur } = this.props;
    if (onBlur) {
      onBlur(e);
    }
  };

  onContactSelect = (contactId) => {
    const { searchText } = this.state;
    const { selectedContacts, guid, currentSession } = this.props;
    selectContact(
      contactId,
      selectedContacts,
      searchText,
      guid,
      currentSession,
      currentSession.id
    );
    this.closeSearch();
    this.clearSelectedContacts();
  };

  onSearchTextChange = (searchText) => {
    this.setState(
      {
        searchText,
        activeContactIndex: 0,
      },
      () => this.onListChange('frequent')
    );
  };

  onListChange(listType) {
    const { setContactSearchConfig } = this.props;
    setContactSearchConfig({
      listType,
    });
  }

  getBucktedContactsList = memoizeOne((selectedContacts, searchResults) => {
    return getBucketedList(searchResults, selectedContacts);
  });

  getCustomFieldsMap = memoizeOne((currentSession) => {
    return getCustomFieldsMapFromSession(currentSession);
  });

  getBucketIndices = (searchText) => {
    const { selectedContacts } = this.props;

    const { searchResults } = this.state;
    if (searchText.length === 0 && selectedContacts.length > 0) {
      return [];
    }

    const { bucketIndices, contacts } = this.getBucktedContactsList(
      selectedContacts,
      searchResults
    );
    if (searchText.length > 0) {
      const tempBucket = {
        start: contacts.length,
        id: 'temp',
        length: 1,
      };
      return [...bucketIndices, tempBucket];
    }
    return bucketIndices;
  };

  getContactsList = (searchText) => {
    const { selectedContacts } = this.props;

    const { searchResults } = this.state;

    if (searchText.length === 0 && selectedContacts.length > 0) {
      return [];
    }

    const { contacts } = this.getBucktedContactsList(
      selectedContacts,
      searchResults
    );

    if (searchText.length > 0) {
      return [...contacts, `TEMP:::${searchText}`];
    }
    return contacts;
  };

  clearSelectedContacts = () => {
    const { setSelectedContacts } = this.props;
    setSelectedContacts([]);
  };

  closeSearch() {
    const { closeSearch } = this.props;
    closeSearch();
  }

  updateBucketedSearch() {
    const { guid } = this.props;
    const { searchText } = this.state;

    getBucketedSearchContacts(guid, searchText).then((buckets) => {
      if (!buckets) {
        return;
      }
      this.setState({
        searchResults: buckets,
      });
    });
  }

  updateTopRecentContacts() {
    const { guid } = this.props;
    getTopRecentContacts(guid).then((buckets) => {
      if (!buckets) {
        return;
      }
      this.setState({
        searchResults: buckets,
      });
    });
  }

  moveActiveContactIndex(position) {
    let { activeContactIndex } = this.state;
    const { searchText } = this.state;
    const contactList = this.getContactsList(searchText);
    const contactsLength = contactList.length;

    if (position === 'up') {
      activeContactIndex -= 1;
    }
    if (position === 'down') {
      activeContactIndex += 1;
    }

    if (activeContactIndex >= contactsLength) {
      activeContactIndex = 0;
    }

    if (activeContactIndex < 0) {
      activeContactIndex = contactsLength - 1;
    }

    this.setState({
      activeContactIndex,
    });
  }

  canContactBeSelected(contactId) {
    // TODO: verify if the logic is valid
    if (!isContactIdSpecial(contactId)) {
      return true;
    }

    const { selectedContacts } = this.props;

    for (let i = 0; i < selectedContacts.length; i += 1) {
      const id = selectedContacts[i];
      if (id === contactId) {
        return false;
      }
    }

    return true;
  }

  addToSelectedContacts(contact) {
    const { selectedContacts, setSelectedContacts } = this.props;
    if (this.canContactBeSelected(contact)) {
      setSelectedContacts([...selectedContacts, contact]);
    }
  }

  removeLastFromSelectedContacts() {
    const { selectedContacts, setSelectedContacts } = this.props;
    selectedContacts.pop();

    setSelectedContacts([...selectedContacts]);
  }

  render() {
    const { searchText, activeContactIndex } = this.state;

    const {
      guid,
      currentSession,
      selectedContacts,
      ContactSearchConfig: { listType },
    } = this.props;

    const contactsList = this.getContactsList(searchText);
    const bucketIndices = this.getBucketIndices(searchText);
    const customFieldsMap = this.getCustomFieldsMap(currentSession);

    return (
      <div
        className={cssStyles.searchWrapper}
        onBlur={this.onBlur}
        tabIndex='-1'
      >
        <OwnerGuidContext.Provider value={guid}>
          <SearchInput
            searchText={searchText}
            onCloseClick={() => this.closeSearch()}
            onSearchTextChange={this.onSearchTextChange}
            contactIds={selectedContacts}
            ownerGuid={guid}
          />
          {searchText || selectedContacts.length > 0 ? null : (
            <FrequentRecentSwitcher
              selected={listType}
              onChange={(type) => this.onListChange(type)}
            />
          )}
          <ContactList
            contactList={contactsList}
            activeContactIndex={activeContactIndex}
            onItemClick={this.onItemClick}
            bucketIndices={bucketIndices}
            customFieldsMap={customFieldsMap}
            joinButtonBuckets={['openChannel']}
            showDescription
            showCustomFields
          />
        </OwnerGuidContext.Provider>
      </div>
    );
  }
}

export default selectedContactsInSearch(
  currentSessionHOC(ContactSearch),
  'guid'
);
