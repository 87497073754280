import memoize from 'memoize-one';
import { isValidEmail } from '@/utils';
import { getContactForId } from '@/Models/Contact';
import bridge from '@/utils/bridge';
import { logToggleChannelMute } from '@/utils/logAnalytics';
import { doTwillioAppAction } from '@/utils/ShellUtils';
import { sanitizeChannelName } from '@/utils/ContactUtils';
import { getCommandFragments } from './TokenUtils/textFragments';
import {
  addBuddyMentionsInFlockml,
  addChannelMentionsInFlockml,
  createFlockml,
  addMentionsToMessageObject,
  getBuddyMentionToken,
} from './FlockmlUtils';
import { FROALA_KEYCODES, ALLOWED_CHARACTERS_KEYCODES } from './constants';
import {
  Owner,
  CurrentSession,
  MentionObj,
  AppList,
  DecoratorFragments,
} from './Types';

type CommandObject = any;

function encodeSpace(string: string): string {
  return string.replace(' ', '\u2005');
}
function decodeSpace(string: string): string {
  return string.replace('\u2005', ' ');
}

async function getContactsFromText(
  textContent: string,
  owner: Owner
): Promise<Object[]> {
  const regex = /\s@([^\s]*)/g;
  const emails = [];
  const ownerEmailDomain = owner.email.split('@')[1];
  const { ownerGuid } = owner;
  for (;;) {
    const match = regex.exec(textContent);

    if (!match) {
      break;
    }

    let email = match[1];
    if (email.indexOf('@') < 0) {
      email += `@${ownerEmailDomain}`;
    }
    if (isValidEmail(email)) {
      emails.push(email);
    }
  }
  if (!emails.length) {
    return [];
  }

  const contacts = await bridge.ask('ContactStore', 'getContactsByEmails', [
    emails,
    ownerGuid,
  ]);
  return contacts.map((contact) => {
    if (typeof contact === 'string' && emails.indexOf(contact) > -1) {
      return getContactForId(`TEMP:::${contact}`, undefined);
    }
    return contact;
  });
}

function toggleChannelMute(
  muteValue: boolean,
  contact: Contact,
  sessionId: string
): boolean {
  const { type, isMuted, hasMuteGroupPrivilege, isJoined, jid } = contact;

  if (
    type !== 'group' ||
    isMuted === muteValue ||
    !hasMuteGroupPrivilege ||
    !isJoined
  ) {
    return false;
  }

  bridge.tell(`Session_${sessionId}`, 'toggleMute', [jid]);
  logToggleChannelMute(muteValue);
  return true;
}

/**
 *
 * @param {contact_object} contact
 * @param {contact_object} owner
 *
 * Need to figure why we have getContactName for a contact
 */
function getContactName(contact: Contact, owner: Owner): string {
  if (contact.type === 'group') {
    return contact.name.replace(/ /g, String.fromCharCode(0x2005));
  }
  if (contact.type === 'temp_search' || !owner.email) {
    return contact.email;
  }

  const ownerEmailDomain = owner.email.split('@')[1];
  const contactEmailParts = contact.email.split('@');

  if (contactEmailParts[1] === ownerEmailDomain) {
    return contactEmailParts[0];
  }

  return contact.email;
}

function getSlashCommandsForDikeApps(
  appList: AppList,
  session: CurrentSession
) {
  return Promise.all([
    bridge.ask('HydraServer', 'getStartupOption', ['appearAppId']),
    bridge.ask('HydraServer', 'getStartupOption', ['twilioAppId']),
  ]).then((results) => {
    const [appearAppId, twilioAppId] = results;

    const {
      sessionInfo: { teamInfo: { call: { provider = null } = {} } = {} } = {},
    } = session as CurrentSession;
    const slashCommands = [];
    appList = appList.sort(function (a, b) {
      const aPriority =
        a?.properties?.priorities?.slashCommand || Number.NEGATIVE_INFINITY;
      const bPriority =
        b?.properties?.priorities?.slashCommand || Number.NEGATIVE_INFINITY;

      return aPriority - bPriority;
    });

    // Order is reversed, because the lower items are close to focused element.
    appList.reverse().forEach((app) => {
      if (
        (app.id === appearAppId && provider !== 'appear') ||
        (app.id === twilioAppId && provider !== 'twilio') ||
        !app.enabled
      ) {
        return;
      }

      const {
        slashCommand: { description, helpText, command, action },
      } = app;
      const commandObject: CommandObject = {
        name: `/${command}`,
        hint: description,
        params: {
          hint: helpText,
          getParamsInfo(textContent, selectionStart, selectionEnd) {
            return getCommandFragments(
              textContent,
              selectionStart,
              selectionEnd
            );
          },
        },
        shouldIgnore() {
          return false;
        },
      };

      if (app.id === twilioAppId && provider === 'twilio') {
        commandObject.action = function (textContent, peer, owner, sessionId) {
          const roomName = textContent.split(`/${command}`)[1].trim() || '';
          doTwillioAppAction(sessionId, peer, roomName);
          return true;
        };
      } else {
        commandObject.action = function (
          textContent,
          peer,
          owner,
          sessionId /* conversation */
        ) {
          const content = textContent.split(`/${command}`)[1].trim() || '';
          bridge.tell('Shell', 'doSlashCommandAction', [
            action,
            app,
            peer,
            sessionId,
            content,
            command,
          ]);
          return true;
        };
      }

      slashCommands.push(commandObject);
    });

    return slashCommands;
  });
}

/**
 *
 * @param {ContactObject} channelContact
 * @param {fragmentsObject} textFragments , fragmentsObject of the given channel Command
 *
 * @returns { textContent, caretPosition} , text and cursor position to be set in editor.
 */

function addChannelMentionInText(
  channelContact: GroupContact,
  textFragments: DecoratorFragments,
  textContent: string
) {
  const { textBeforeParam, textAfterSuffix } = textFragments;

  const textToBeAdded = `#${sanitizeChannelName(channelContact.chatName)} `;
  const startPosition = textBeforeParam.length;
  const endPosition =
    startPosition +
    textContent.length -
    textBeforeParam.length -
    textAfterSuffix.length;

  return {
    textToBeAdded,
    startPosition,
    endPosition,
  };
}

function addBuddyMentionInText(
  buddyContact: BuddyContact,
  textFragments: DecoratorFragments,
  textContent: string
) {
  const { textBeforeParam, textAfterSuffix } = textFragments;

  const buddyName = buddyContact.chatName
    .trim()
    .replace(/ /g, String.fromCharCode(0x2005));
  // const caretPosition = textBeforeParam.length + buddyName.length + 2;
  const textToBeAdded = `@${buddyName} `;
  const startPosition = textBeforeParam.length;
  const endPosition =
    startPosition +
    textContent.length -
    textBeforeParam.length -
    textAfterSuffix.length;

  return {
    // textContent: `${textBeforeParam}@${buddyName} ${textAfterSuffix}`,
    // caretPosition,
    textToBeAdded,
    startPosition,
    endPosition,
  };
}

const GENERIC_MENTIONS = ['all', 'online'];

function updateMessageWithBuddyMention(contact: BuddyContact) {
  return function update(messageObj /* conversation */) {
    const {
      flockml,
      text,
      messageElements: { mentions = [] } = {},
    } = messageObj;
    const { chatName, chatType, jid } = contact;
    if (isBuddyAlreadyMentioned(mentions, chatType, jid)) {
      return messageObj;
    }

    const mentionToken = getBuddyMentionToken(chatName);
    if (!isMentionTokenValid(flockml, text, mentionToken)) {
      return messageObj;
    }
    if (!flockml) {
      messageObj.flockml = createFlockml(text);
    }

    messageObj.flockml = `${addBuddyMentionsInFlockml(
      messageObj.flockml,
      contact
    )}`;

    const mentionObj = {
      name: mentionToken,
    } as MentionObj;

    if (chatType) {
      mentionObj.type = chatType;
    }
    if (GENERIC_MENTIONS.indexOf(chatType) === -1) {
      mentionObj.userJid = jid;
    }
    mentions.push(mentionObj);
    addMentionsToMessageObject(messageObj, mentions);
    return messageObj;
  };
}

function isBuddyAlreadyMentioned(
  mentions: MentionObj[],
  chatType: string,
  jid: string
): boolean {
  const index: number = mentions.findIndex((mentionObj) => {
    if (mentionObj.type && chatType) {
      return mentionObj.type === chatType;
    }
    if (mentionObj.userJid && jid) {
      return mentionObj.userJid === jid;
    }
    return false;
  });
  return index !== -1;
}

function updateMessageWithChannelMention(contact) {
  return function update(messageObj, conversation) {
    const { peer } = conversation;
    const { text, flockml } = messageObj;
    if (!flockml) {
      messageObj.flockml = createFlockml(text);
    }
    messageObj.flockml = addChannelMentionsInFlockml(
      messageObj.flockml,
      contact,
      peer
    );
    return messageObj;
  };
}

const getGenericMentionsUpdateFunctions = memoize((ownerGuid: string) => {
  if (!ownerGuid) return [];
  const genericMentionsIds = GENERIC_MENTIONS.map((type) => {
    return `SEARCH_UTILITY:::${type}:::${type}`;
  });
  const genericMentionsContacts = genericMentionsIds.map((mentionId) => {
    return getContactForId(mentionId, ownerGuid);
  });
  const genericMentionsUpdateFuns = genericMentionsContacts.map((contact) => {
    return updateMessageWithBuddyMention(contact);
  });
  return genericMentionsUpdateFuns;
});

const isElementContentEditable = (event: KeyboardEvent) => {
  const element = event.target as HTMLElement;
  const inputs = ['input', 'select', 'button', 'textarea'];
  if (inputs.indexOf(element.tagName.toLowerCase()) !== -1) {
    return true;
  }
  if (
    element.isContentEditable ||
    element.getAttribute('contenteditable') === 'true'
  ) {
    return true;
  }
  return false;
};

// This method is taken from froala source code
const isCharacter = (e: KeyboardEvent) => {
  const keyCode = e.keyCode || e.which;
  const { ctrlKey, metaKey, altKey } = e;
  if (ctrlKey || metaKey || altKey) return false;
  if (keyCode >= FROALA_KEYCODES.ZERO && keyCode <= FROALA_KEYCODES.NINE) {
    return true;
  }
  if (
    keyCode >= FROALA_KEYCODES.NUM_ZERO &&
    keyCode <= FROALA_KEYCODES.NUM_MULTIPLY
  ) {
    return true;
  }
  if (keyCode >= FROALA_KEYCODES.A && keyCode <= FROALA_KEYCODES.Z) {
    return true;
  }
  if (ALLOWED_CHARACTERS_KEYCODES.has(keyCode)) {
    return true;
  }

  return false;
};

function isMentionTokenValid(
  flockml: string,
  text: string,
  mentionToken: string
): boolean {
  const tokenIndex = text.indexOf(mentionToken);
  if (tokenIndex === -1) return false;
  if (flockml && flockml.indexOf(mentionToken) === -1) return false;
  if (tokenIndex === 0) return true;
  const previousChar = text[tokenIndex - 1];
  const whitespaceRegex = /\s/g;
  if (whitespaceRegex.test(previousChar)) return true;
  return false;
}

export {
  encodeSpace,
  decodeSpace,
  getContactsFromText,
  toggleChannelMute,
  getContactName,
  getSlashCommandsForDikeApps,
  addChannelMentionInText,
  addBuddyMentionInText,
  updateMessageWithBuddyMention,
  updateMessageWithChannelMention,
  isBuddyAlreadyMentioned,
  getGenericMentionsUpdateFunctions,
  isElementContentEditable,
  isCharacter,
};
