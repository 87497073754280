export default {
  block_tooltip_unpin: 'Desmarcar',
  block_tooltip_pin_chat: 'Fixar esta barra de conversa',
  channels_separator_text: 'CANAIS SILENCIADOS',
  direct_conversation_separator_text: 'ABRIR CONVERSAS',
  pinned_conversation_separator_text: 'CONVERSAS FIXADAS',
  display_density_compact: 'Compacto',
  display_density_thin: 'Aconchegante',
  display_density_expanded: 'Confort\u00e1vel',
  display_density_classic: 'Cl\u00e1ssico',
  read_options_mark_as_read: 'Marcar Tudo Como Lido',
  select_drop_down_title_display_density: 'Densidade de exibi\u00e7\u00e3o',
  select_drop_down_title_read: 'Lido',
  invite_contact_tooltip: 'Convidar',
  browse_channel_tooltip: 'Entrar em um Canal',
  create_channel_tooltip: 'Criar Canais',
  search_recent_tooltip: 'Suas conversas recentes',
  active_bucket_empty_text: 'Nenhuma conversa aberta',
  active_bucket_empty_start_conversation: 'Iniciar Novo',
  active_bucket_empty_open_recent: 'Ver Recentes',
  active_bucket_recent_button: 'Conversas Recentes',
  all_channel_feed_tab_text: 'Feed de Canais',
  contactAdder_inviteHeader_title: 'Convidar membros para a equipe',
  contactAdder_inviteHeader_desc:
    'O Flock funciona melhor quando sua equipe tamb\u00e9m est\u00e1 aqui. Para adicionar membros, insira suas IDs de e-mail.',
  contactAdder_invite_error:
    'Voc\u00ea n\u00e3o tem privil\u00e9gios para convidar membros para esta equipe.',
  invite_buttonText: 'Convidar',
  text_shareUrlWithTeam: 'Ou compartilhe esta URL com a sua equipe',
  text_enterEmailAddress: 'Digite um ID de e-mail',
  enter_valid_email: 'Digite um endere\u00e7o de e-mail v\u00e1lido',
};
