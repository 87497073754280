import React from "react";
import { createStore } from "./createStore";

const _cache = new Map();
const StoreContext = React.createContext(null);

export function useStore() {
  const store = React.useContext(StoreContext);
  if (!store) {
    throw new Error(
      "You need to include <StoreProvider /> higher in the tree and provide a `value` prop to it before calling useStore."
    );
  }
  return store;
}

export function StoreProvider({ children, id = "default" }) {
  let store = null;

  if (_cache.has(id)) {
    store = _cache.get(id);
  } else {
    store = createStore();
    _cache.set(id, store);
  }

  if (
    (process.env.NODE_ENV === "development" ||
      window.__ODARA_EXPOSE_EMOJI_STORE__) &&
    store
  ) {
    console.info("Mobx store is available on window object as _emojiStore");
    window._emojiStore = store;
  }

  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
}
