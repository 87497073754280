const defaultState = {
  open: false,
  listType: 'frequent', // Allowed Values: frequent || recent
  searchText: '', // Not using as of now, but should be useful in future refactors.
};

export default (ActionTypes) => {
  return (state = defaultState, action) => {
    try {
      switch (action.type) {
        case ActionTypes.SET_CONTACT_SEARCH_CONFIG: {
          // TODO: we can add type and value checks with flow, to verify if proper values are being passed.
          const config = action.payload;
          return {
            ...state,
            ...config,
          };
        }
        case ActionTypes.RESET_CONTACT_SEARCH_CONFIG:
          return defaultState;

        default:
          return state;
      }
    } catch (error) {
      console.error(
        `Error in appState reducer: ${error.message || error.code || error}`,
        error
      );
    }
    return state;
  };
};
