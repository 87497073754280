import { doTwillioAppAction } from '@/utils/ShellUtils';
import getTranslatedString from '@/utils/getTranslatedString';
import I18N from './I18N';

export default {
  name: getTranslatedString('command_videor_name', I18N),
  hint: getTranslatedString('command_videor_hint', I18N),
  shouldIgnore() {
    return false;
  },
  action(textContent, peer, owner, sessionId) {
    doTwillioAppAction(sessionId, peer, null, true);
  },
};
