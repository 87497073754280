export default {
  new_chat_shortcut_helper: 'New Chat',
  keyboard_shortcuts: 'Keyboard Shortcuts',
  search_conversations: 'Search Conversations',
  close_current_chat_tab: 'Close current chat tab',
  move_focus_to_editor: 'Move focus to message editor',
  cycle_through_chats_down: 'Cycle through open chat tabs (downward)',
  open_recent_contacts: 'Recent Chats and Directory',
  toggle_sidebar: 'Toggle Right Sidebar',
  reopen_last_tab: 'Reopen last closed chat tab',
  cycle_through_chats_up: 'Cycle through open chat tabs (upward)',
  show_this_list: 'Show this list',
};
