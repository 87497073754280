const defaultState = 'English-US';

export default (ActionTypes) => {
  return (state = defaultState, action) => {
    try {
      switch (action.type) {
        case ActionTypes.SET_PREFERRED_LANGUAGE:
          return action.payload;

        default:
          return state;
      }
    } catch (error) {
      console.error(
        `Error in appState reducer: ${error.message || error.code || error}`,
        error
      );
    }
    return state;
  };
};
