/* eslint-disable jsx-a11y/click-events-have-key-events,
                  jsx-a11y/mouse-events-have-key-events,
                  jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import cx from 'classnames';
import { converter } from '@/lib/emoji';
import noop from '@/utils/noop';
import { Emoji } from '../../Types';
import css from './EmojiPopoverList.css';

const EMOJI_DISPLAY_COUNT = 5;

type Props = {
  className: string;
  searchValue: string;
  recents: any;
  skinTone: any;
  onSelect: (emoji: Emoji) => void;
  registerListeners: (listeners: any) => void;
};

export default function EmojiPopoverList({
  className,
  searchValue = '',
  recents = [],
  skinTone = 1,
  onSelect = noop,
  registerListeners = noop,
}: Props) {
  const [emojis, setEmojis] = useState<Array<Emoji>>([]);
  const [highlightedIndex, setHighlightedIndex] = useState(0);

  const emojiSearchResults = useMemo(
    () =>
      emojis.map((item) =>
        skinTone > 1 && item.hasSkins
          ? {
              ...item.skins[skinTone - 2],
              shortcodes: item.shortcodes,
              parent: item.native,
            }
          : item
      ),
    [emojis, skinTone]
  );

  useEffect(() => {
    const searchResults = converter
      .search(searchValue, { recents, includeEmoticons: false })
      .slice(0, EMOJI_DISPLAY_COUNT);
    setEmojis(searchResults);
    setHighlightedIndex(() => 0);
  }, [searchValue, recents]);

  const selectEmoji = useCallback(() => {
    onSelect(emojiSearchResults[highlightedIndex]);
  }, [emojiSearchResults, onSelect, highlightedIndex]);

  const onDownArrow = useCallback(() => {
    setHighlightedIndex(
      (prevIndex) => (prevIndex + 1) % emojiSearchResults.length
    );
    return false;
  }, [setHighlightedIndex, emojiSearchResults]);

  const onUpArrow = useCallback(() => {
    setHighlightedIndex((prevIndex) =>
      prevIndex - 1 < 0 ? emojiSearchResults.length - 1 : prevIndex - 1
    );
    return false;
  }, [setHighlightedIndex, emojiSearchResults]);

  const onEnter = useCallback(
    (event) => {
      if (emojis.length) {
        event.preventDefault();

        selectEmoji();

        return false;
      }

      return true;
    },
    [emojis, selectEmoji]
  );

  // NOTE: This workaround is needed to let decorator visibility be controlled
  // by editor focus state. (See https://github.com/talk-to/odara/pull/345)
  const onMouseDown = (event) => event.preventDefault();

  // The event listeners `onDownArrow`, `onUpArrow` and `onEnter` are passed up
  // to the parent component and subsequently registered with the corresponding
  // events from the editor textarea.
  useEffect(() => {
    registerListeners({
      onDownArrow,
      onUpArrow,
      onEnter,
    });
  }, [registerListeners, onDownArrow, onUpArrow, onEnter]);

  if (emojiSearchResults.length === 0) {
    return null;
  }

  return (
    <div className={css.Wrapper}>
      <div className={css.Heading}>Emoji</div>
      <ul className={`${className} ${css.List}`}>
        {emojiSearchResults.map((emoji, index) => (
          <li
            role='button'
            onClick={selectEmoji}
            onMouseDown={onMouseDown}
            onMouseOver={() => setHighlightedIndex(index)}
            className={cx(css.ListItem, {
              [css.Highlighted]: index === highlightedIndex,
            })}
            key={emoji.id}
          >
            <div
              className={css.Emoji}
              style={converter.getCSSForEmoji(emoji)}
            />
            <div className={css.ShortCode}>{emoji.shortcodes[0]}</div>
          </li>
        ))}
      </ul>
    </div>
  );
}
