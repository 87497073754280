import React from 'react';
import Tippy from '@tippyjs/react';
import commonCss from '@/components/common/common.css';
import { EDITOR_TYPES } from '../constants';
import { Noop } from '../Types';
import css from './EditorSwitcher.css';

const { CLASSIC, BALLOON } = EDITOR_TYPES;

type Props = {
  editorType: string;
  toggleEditor: Noop;
};

function EditorSwitcher({ editorType, toggleEditor }: Props) {
  const balloonEditorClass =
    editorType === BALLOON ? css.balloonEditorClass : '';

  return (
    <Tippy
      content={editorType === CLASSIC ? 'Hide formatting' : 'Show formatting'}
      arrow
      placement='top'
      className={commonCss.info_tooltip}
    >
      <span className={css.wrapper}>
        <button
          className={`${css.switchButton} ${balloonEditorClass}`}
          onClick={toggleEditor}
          type='button'
        >
          Aa
        </button>
      </span>
    </Tippy>
  );
}

export default EditorSwitcher;
