import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { hideModal } from '@/components/Modal/Modal';
import groupDiscoveryModalStyles from '@/components/Modal/GroupDiscoveryModal/GroupDiscoveryModalComponent.css';
import commonStyles from '@/components/common/common.css';

function GroupDiscoveryGuestView() {
  return (
    <div
      className={classNames(
        groupDiscoveryModalStyles.join_public_channels_modal,
        groupDiscoveryModalStyles.no_public_channels
      )}
    >
      <div className={groupDiscoveryModalStyles.modal_title}>
        <FormattedMessage id='public_channel_guest_restriction_heading' />
      </div>
      <div
        className={
          groupDiscoveryModalStyles.public_channels_guest_restriction_image
        }
      />

      <div className={groupDiscoveryModalStyles.no_public_channels_description}>
        <FormattedMessage id='public_channel_guest_restriction_message' />
      </div>

      <button
        type='button'
        className={classNames(
          commonStyles.btn,
          commonStyles.btn__action,
          commonStyles.btn_block
        )}
        onClick={hideModal}
      >
        <FormattedMessage id='okay' />
      </button>
    </div>
  );
}

export default GroupDiscoveryGuestView;
