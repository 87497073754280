import { converter as emojiConverter, shortCodeToNative } from '@/lib/emoji';
import { replaceTag } from './Utils';
import { MENTION_TYPES } from '../constants';

/* Emoji has only background image so browser will render border box as foreground image so to avoid it we apply transparent image as foreground image.
  https://stackoverflow.com/questions/1073015/how-do-i-remove-the-gray-border-that-surrounds-background-images/36367724 
*/
const transparentImgSrc =
  'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

export const getHtmlWithEmojiAsImage = (html: string): string => {
  if (!html) return html;
  const div = document.createElement('div');
  div.innerHTML = html;
  emojiConverter.decorate(div, {
    ignoreNodeTypes: MENTION_TYPES.map((str) => str.toUpperCase()),
  });
  const emojis = Array.from(div.querySelectorAll('.emoji'));
  emojis.forEach((emoji) => {
    const shortCode = emoji.getAttribute('data-emoji-shortcode');
    emoji.setAttribute('data-emoji-native', shortCodeToNative(shortCode));
    emoji.setAttribute('src', transparentImgSrc);
    emoji.outerHTML = replaceTag(emoji.outerHTML, 'span', 'img');
  });
  return div.innerHTML;
};

export const getTokenizedEmojis = (text: string): string[] => {
  if (!text || !text.length) return [''];
  const node = emojiConverter.decorate(text);
  if (typeof node === 'string') {
    return [text];
  }
  const div = document.createElement('div');
  const shortCode = node.getAttribute('data-emoji-shortcode');
  node.setAttribute('data-emoji-native', shortCodeToNative(shortCode));
  node.setAttribute('src', transparentImgSrc);
  div.appendChild(node);
  const emojiHtml = replaceTag(div.innerHTML, 'span', 'img');
  const htmlArray = [];

  if (text[0] === ' ') htmlArray.push('&nbsp;');
  htmlArray.push(emojiHtml);
  if (text.length > 1 && text[text.length - 1] === ' ')
    htmlArray.push('&nbsp;');
  return htmlArray;
};
