import React from 'react';
import { injectIntl } from 'react-intl';
import ChatsProxy from '@/Proxies/ChatsProxy';
import { getUnreadCount } from '@/utils/ConversationUtils';
import UnreadMessagesProxy from '@/Proxies/UnreadMessagesProxy';
import { Button, Badge } from '@/components/common';
import contactHOC from '@/subscribeHOCs/contact';
import { compose } from 'redux';
import SingleMessage from './SingleMessage';
import styles from './GroupMessage.css';

function GroupMessage({
  contact,
  messageList,
  unreadCount,
  ownerGuid,
  intl,
  jid,
}) {
  const { chatName, imageUrl } = contact;

  const hasMentions = messageList.some(
    ({ messageElements }) =>
      messageElements &&
      messageElements.mentions &&
      messageElements.mentions.some(({ mentionsMe }) => mentionsMe)
  );

  function viewAllMessagesHandler() {
    ChatsProxy.openConversation(
      {
        jid,
        ownerGuid,
      },
      true
    );
  }

  function markAsReadButtonHandler() {
    UnreadMessagesProxy.readAllMessagesFromPeer(contact);
  }

  return (
    <div className={styles.feedDescription}>
      <div className={styles.headerArea}>
        <div className={styles.headerAreaLeft}>
          <img className={styles.avatar} src={imageUrl} alt={chatName} />
          <div className={styles.channelName}>{chatName}</div>
        </div>
        <div className={styles.headerAreaRight}>
          <Badge
            background={hasMentions ? 'var(--c-mention)' : 'var(--c-unread)'}
          >
            {getUnreadCount(unreadCount)}
          </Badge>
          <Button
            className={styles.markAsReadButton}
            type='submit'
            kind='link'
            onClick={markAsReadButtonHandler}
          >
            {intl.formatMessage({ id: 'mark_all_as_read_button' })}
          </Button>
        </div>
      </div>
      <div className={styles.messageContainer}>
        {messageList.map((message) => (
          <SingleMessage
            key={message.sentTime}
            ownerGuid={ownerGuid}
            message={message}
            jid={message.sender?.jid}
          />
        ))}
        <div className={styles.viewAllMessages}>
          <Button
            className={styles.viewAllMessagesButton}
            onClick={viewAllMessagesHandler}
            link
            kind='secondary'
          >
            {intl.formatMessage({ id: 'view_all_message_button' })}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default compose(contactHOC, injectIntl, React.memo)(GroupMessage);
