import { PINNED, MUTED, OPEN } from '@/utils/constants';

export const CLASSIC = 'classic';
export const COSY = 'thin';
export const COMFORTABLE = 'expanded';
export const COMPACT = 'compact';
export const MODES = {
  CLASSIC,
  COSY,
  COMPACT,
  COMFORTABLE,
};

const RECEIVED = 'received';

export const RECEIPT_STATE = {
  RECEIVED,
};

export { PINNED, MUTED, OPEN };

export const BUCKETS = {
  PINNED,
  OPEN,
  MUTED,
};

const VIEW_MODE_SUFFIX = 'active_chat_val';

export const VIEW_MODE_KEYS = {
  [PINNED]: `pinned_${VIEW_MODE_SUFFIX}`,
  [OPEN]: `direct_${VIEW_MODE_SUFFIX}`,
  [MUTED]: `channel_${VIEW_MODE_SUFFIX}`,
};
