import React, { Component } from 'react';
import { notifyAdminForUpgrade } from '@/utils/SessionUtils';
import currentSessionHOC from '@/connectHOCs/Sessions/currentSession';

class NotifyAdminCTA extends Component {
  static defaultProps = {
    currentSession: undefined,
    onAdminNotify: () => {},
    context: '',
  };

  state = {
    text: 'Notify Admin',
  };

  onClick = () => {
    const { currentSession, onAdminNotify, context } = this.props;
    if (!currentSession) {
      return;
    }
    notifyAdminForUpgrade(currentSession.id, context).then(() => {
      this.setState({
        text: 'Admin notified',
      });
      onAdminNotify();
    });
  };

  render() {
    const { text } = this.state;

    return (
      <span
        role='button'
        onClick={this.onClick}
        onKeyPress={() => {}}
        tabIndex={-1}
      >
        {text}
      </span>
    );
  }
}

export default currentSessionHOC(NotifyAdminCTA);
