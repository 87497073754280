import React from 'react';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import prettyFileSize from '@/utils/prettyFileSize';
import { getSignedDownloadUrl } from '@/utils/ShellUtils';
import { Image } from '@/components/common';
import { exponentialBackoff } from '@/utils';
import { downloadAction } from '@/components/Conversation/Message/AttachmentAction/LocalAttachmentActions';
import cssStyles from './FilePreview.css';

const noop = () => {};

const Icon = React.memo(({ extension }) => {
  let extensionClass;
  switch (extension) {
    case 'pdf':
      extensionClass = cssStyles.fileType_pdf;
      break;

    case 'csv':
      extensionClass = cssStyles.fileType_csv;
      break;

    case 'doc':
    case 'docx':
    case 'odt':
    case 'rtf':
      extensionClass = cssStyles.fileType_doc;
      break;

    case 'txt':
      extensionClass = cssStyles.fileType_txt;
      break;

    case 'ppt':
    case 'pptx':
      extensionClass = cssStyles.fileType_ppt;
      break;

    case 'xls':
    case 'xlsx':
    case 'ods':
      extensionClass = cssStyles.fileType_xls;
      break;

    default:
      extensionClass = cssStyles.fileType_attachment;
      break;
  }

  const iconClass = classNames(cssStyles.fileType, extensionClass);
  return <div className={iconClass} />;
});

export default class FilePreview extends React.Component {
  state = {
    previewImgSrc: '',
  };

  fetching = false;

  componentDidMount() {
    this.getFilePreviewUrl();
    this.initRetryFunction();
  }

  initRetryFunction() {
    this.retryTimer = exponentialBackoff(this.getFilePreviewUrl, 15);
  }

  componentDidUpdate(prevProps) {
    const { download } = this.props;
    if (!isEqual(prevProps.download, download)) {
      this.initRetryFunction();
      if (!this.fetching) {
        this.getFilePreviewUrl();
      }
    }
  }

  componentWillUnmount() {
    this.retryTimer = null;
  }

  getFilePreviewUrl = () => {
    const { download, currentSessionId } = this.props;
    this.fetching = true;
    getSignedDownloadUrl(download.preview, currentSessionId).then(
      (previewImgSrc) => {
        // TODO: this promise should be cancelled when component unmounts
        const { download: currentDownloadProp } = this.props;
        if (
          !isEqual(currentDownloadProp, download) ||
          previewImgSrc instanceof Error
        ) {
          if (this.retryTimer) {
            this.retryTimer();
          }
        } else {
          this.setState({ previewImgSrc });
          this.fetching = false;
        }
      }
    );
  };

  onPreviewClick = () => {
    const { download, currentSessionId } = this.props;
    const attachment = { downloads: [download] };
    downloadAction(attachment, currentSessionId, 'preview');
  };

  defaultImageWrapStyle = {};

  render() {
    const { download } = this.props;
    const {
      filename,
      size,
      preview: { width, height },
    } = download;
    const { previewImgSrc } = this.state;

    const extMatch = filename && /\.([^.]+)$/.exec(filename);
    const extension = extMatch && extMatch[1].toLowerCase();

    let computedHeight;
    if (width && height) {
      computedHeight = (400 / width) * height;
    }

    const imageWrapStyles =
      computedHeight && computedHeight < 150
        ? { height: `${computedHeight}px` }
        : this.defaultImageWrapStyle;

    return (
      <div
        role='button'
        onKeyDown={noop}
        tabIndex='-1'
        className={cssStyles.FileSharePreview}
        onClick={this.onPreviewClick}
      >
        <div className={cssStyles.previewHeader}>
          <div className={cssStyles.attachmentIconWrap}>
            <Icon extension={extension} />
          </div>
          <div className={cssStyles.fileDetailsWrap}>
            <div className={cssStyles.fileShareName}>{filename}</div>
            <div className={cssStyles.fileShareAttributes}>
              {prettyFileSize(size)} • {extension}
            </div>
          </div>
        </div>
        <div className={cssStyles.filePreviewImageWrap} style={imageWrapStyles}>
          <Image src={previewImgSrc} alt='' />
        </div>
      </div>
    );
  }
}
