export default {
  recent_contacts: 'Mensajes directos recientes',
  recent_groups: 'Canales recientes',
  create_group: 'Nuevo canal',
  invite_contact: 'Invitar',
  invite_contact_button: 'Invitar',
  group_tab_search_placeholder: 'Buscar canales',
  all_contact_tab_search_placeholder: 'Buscar contactos',
  your_groups: 'Tus canales',
  all_contacts: 'Todos los contactos',
  join_group_button: 'Unirte a canales',
  create_group_button: 'Crear canales',
  no_result_found: 'No hay resultados',
  join_group_description: 'Descubrir y unirse a canales en tu equipo',
  create_group_description: 'Crear un nuevo canal',
  invite_contact_description: 'Invitar miembros a tu equipo',
  invite_via_email: 'Invitar mediante el correo electr\u00f3nico',
};
