export default {
  block_tooltip_unpin: 'Movilizar',
  block_tooltip_pin_chat: 'Inmovilizar esta pesta\u00f1a de chat',
  channels_separator_text: 'CANALES SILENCIADOS',
  direct_conversation_separator_text: 'ABRIR CHATS',
  pinned_conversation_separator_text: 'CHATS FIJADOS',
  display_density_compact: 'Compacto',
  display_density_thin: 'Acogedor',
  display_density_expanded: 'C\u00f3modo',
  display_density_classic: 'Cl\u00e1sico',
  read_options_mark_as_read: 'Marcar todo como le\u00eddo',
  select_drop_down_title_display_density: 'Desplegar densidad',
  select_drop_down_title_read: 'Leer',
  invite_contact_tooltip: 'Invitar',
  browse_channel_tooltip: 'Unirse a canales',
  create_channel_tooltip: 'Crear canales',
  search_recent_tooltip: 'Sus chats recientes',
  active_bucket_empty_text: 'No hay chats abiertos',
  active_bucket_empty_start_conversation: 'Empezar uno nuevo',
  active_bucket_empty_open_recent: 'Ver recientes',
  active_bucket_recent_button: 'Chats recientes',
  all_channel_feed_tab_text: 'Mensajes recientes de todos los canales',
  contactAdder_inviteHeader_title: 'Invitar a los miembros del equipo',
  contactAdder_inviteHeader_desc:
    'Flock funciona mejor cuando tu equipo tambi\u00e9n est\u00e1 aqu\u00ed. Agregue miembros ingresando su ID de email.',
  contactAdder_invite_error:
    'Usted no tiene los privilegios para invitar miembros a este equipo.',
  invite_buttonText: 'Invitar',
  text_shareUrlWithTeam: 'O comparta esta URL con su equipo',
  text_enterEmailAddress: 'Ingrese una ID de email',
  enter_valid_email: 'Ingrese una direcci\u00f3n de email v\u00e1lida',
};
