import React, { PureComponent } from 'react';
import throttle from 'lodash/throttle';
import classNames from 'classnames';
import styles from './ScrollWrapper.css';

function scrollWrapper(Wrapper, getScrollState = () => {}) {
  return class ScrollWrapper extends PureComponent {
    state = {
      showTop: false,
      showBottom: false,
      hasTopBadge: false,
      hasBottomBadge: false,
      topBadgeClass: '',
      bottomBadgeClass: '',
    };

    wrapperRef = React.createRef();

    computeState = throttle(() => {
      const el = this.wrapperRef.current;

      if (!el) {
        return;
      }

      const {
        hasTopBadge,
        hasBottomBadge,
        showTop,
        showBottom,
        topBadgeClass,
        bottomBadgeClass,
      } = getScrollState(el);

      this.setState({
        hasBottomBadge,
        hasTopBadge,
        showTop,
        showBottom,
        topBadgeClass,
        bottomBadgeClass,
      });
    }, 200);

    componentDidMount() {
      this.computeState();
      window.addEventListener('resize', this.computeState);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.computeState);
    }

    onScroll = () => {
      this.computeState();
    };

    onTopClick = () => {
      const el = this.wrapperRef.current;

      if (!el) {
        return;
      }

      el.scrollTop -= el.getBoundingClientRect().height - 50;
    };

    onBottomClick = () => {
      const el = this.wrapperRef.current;

      if (!el) {
        return;
      }

      el.scrollTop += el.getBoundingClientRect().height - 50;
    };

    render() {
      const {
        showTop,
        showBottom,
        hasTopBadge,
        hasBottomBadge,
        topBadgeClass,
        bottomBadgeClass,
      } = this.state;

      return (
        <React.Fragment>
          {showTop ? (
            <div
              role='button'
              onKeyDown={() => {}}
              tabIndex={0}
              className={classNames(styles.buttons, styles.go_to_top)}
              onClick={this.onTopClick}
            >
              <span className={classNames(styles.icon)} />
              {hasTopBadge && (
                <span className={classNames(styles.has_badge, topBadgeClass)} />
              )}
            </div>
          ) : null}
          <div
            className={classNames(styles.wrapper, 'no-scrollbar')}
            ref={this.wrapperRef}
            onScroll={this.onScroll}
          >
            <Wrapper {...this.props} onUpdate={this.computeState} />
          </div>
          {showBottom ? (
            <div
              role='button'
              onKeyDown={() => {}}
              tabIndex={0}
              className={classNames(styles.buttons, styles.go_to_bottom)}
              onClick={this.onBottomClick}
            >
              <span className={classNames(styles.icon)} />
              {hasBottomBadge && (
                <span
                  className={classNames(styles.has_badge, bottomBadgeClass)}
                />
              )}
            </div>
          ) : null}
        </React.Fragment>
      );
    }
  };
}

export default scrollWrapper;
