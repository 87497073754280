import React from 'react';

function RequestAFeatureIcon({ bulbClassName = '' }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='28'
      height='28'
      viewBox='0 0 28 28'
    >
      <g fill='none' fillRule='evenodd'>
        <rect width='28' height='28' rx='4' />
        <path
          className={bulbClassName}
          fill='#E1E1E1'
          fillRule='nonzero'
          d='M16.8 16.192c2.008-1.398 2.876-3.938 2.143-6.273-.733-2.334-2.896-3.923-5.343-3.923S8.99 7.585 8.257 9.92c-.733 2.335.135 4.875 2.143 6.273V18.8h6.4v-2.608z'
        />
        <path fill='#E1E1E1' fillRule='nonzero' d='M11.2 20.4H16V22H11.2z' />
      </g>
    </svg>
  );
}

export default RequestAFeatureIcon;
