import React from 'react';
import { FormattedMessage } from 'react-intl';
import IntlWrapper from '@/Wrappers/IntlWrapper';
import translations from '../../nls';
import cssStyles from '../MessageList.css';

type Props = {
  currentPeer: OwnerContact;
};

function PersonalChatHeader(props: Props) {
  const { currentPeer } = props;
  return (
    <div className={cssStyles.personal_beginning}>
      <FormattedMessage
        id='messageArea_chatTab_text_historyButton'
        values={{ name: currentPeer.name }}
      />
    </div>
  );
}

export default IntlWrapper(PersonalChatHeader, translations);
