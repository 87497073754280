import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import layoutOuterLeftCollapsedHOC from '@/connectHOCs/layoutOuterLeftCollapsed';
import css from './BlockingModal.css';

class BlockingModal extends Component {
  static defaultProps = {
    outerLeftCollapsed: false,
  };

  getModal() {
    const { children, outerLeftCollapsed } = this.props;

    return (
      <div
        className={`${css.Background} ${
          outerLeftCollapsed ? css['Background-collapsed'] : ''
        }`}
      >
        <div className={css.Wrapper}>{children}</div>
      </div>
    );
  }

  render() {
    return ReactDOM.createPortal(this.getModal(), document.body);
  }
}

export default layoutOuterLeftCollapsedHOC(BlockingModal);
