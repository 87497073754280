import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { setMuteTime } from '@/utils/SessionUtils';
import { logMedusaGenericEvent } from '@/utils/logAnalytics';
import ToggleButton from '../common/ToggleButton/ToggleButton';

import cssStyles from './MenuDropdown.css';

const MEDUSA_LOG_DND_UPDATED_KEY = 'DND_duration_changed';
class DoNotDisturb extends PureComponent {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.updateState = this.updateState.bind(this);
    this.setMuteTime = this.setMuteTime.bind(this);
    this.state = {
      currentMuteTime: '',
    };
    this.dndItems = [
      {
        id: 'dnd1hour',
        selected: false,
        title: <FormattedMessage id='for_1_hour' />,
        onClick: (e) => {
          e.preventDefault();
          e.stopPropagation();
          this.setMuteTime(60);
          this.updateState('dnd1hour');
          logMedusaGenericEvent(MEDUSA_LOG_DND_UPDATED_KEY, { duration: '1' });
        },
      },
      {
        id: 'dnd8hours',
        selected: false,
        title: <FormattedMessage id='for_8_hours' />,
        onClick: (e) => {
          e.preventDefault();
          e.stopPropagation();
          this.setMuteTime(8 * 60);
          this.updateState('dnd8hours');
          logMedusaGenericEvent(MEDUSA_LOG_DND_UPDATED_KEY, { duration: '8' });
        },
      },
      {
        id: 'dnd12hours',
        selected: false,
        title: <FormattedMessage id='for_12_hrs' />,
        onClick: (e) => {
          e.preventDefault();
          e.stopPropagation();
          this.setMuteTime(12 * 60);
          this.updateState('dnd12hours');
          logMedusaGenericEvent(MEDUSA_LOG_DND_UPDATED_KEY, { duration: '12' });
        },
      },
      {
        id: 'dndInfinite',
        selected: false,
        title: <FormattedMessage id='until_i_resume' />,
        onClick: (e) => {
          e.preventDefault();
          e.stopPropagation();
          this.setMuteTime(-1);
          this.updateState('dndInfinite');
          logMedusaGenericEvent(MEDUSA_LOG_DND_UPDATED_KEY, {
            duration: 'resume',
          });
        },
      },
    ];
  }

  // componentDidMount() {
  //   const {
  //     notificationPreferences: { muteEnabled, muteTime },
  //   } = this.props;
  //   if (muteEnabled) {
  //     this.setDndMuteTime(muteTime);
  //   }
  // }

  componentDidUpdate(prevProps) {
    const {
      notificationPreferences: { muteEnabled, muteTime },
    } = this.props;
    if (prevProps.notificationPreferences.muteEnabled !== muteEnabled) {
      if (muteEnabled) {
        this.setDndMuteTime(muteTime);
      }
    }
  }

  setDndMuteTime = (muteTime) => {
    let currentMuteTime;

    if (muteTime < 0) {
      currentMuteTime = 'dndInfinite';
    } else if (muteTime <= 60) {
      currentMuteTime = 'dnd1hour';
    } else if (muteTime <= 480) {
      currentMuteTime = 'dnd8hours';
    } else {
      currentMuteTime = 'dnd12hours';
    }
    this.setState({ currentMuteTime });
  };

  setMuteTime(timeInMinutes) {
    const { sessionId } = this.props;
    setMuteTime(sessionId, timeInMinutes !== 0, timeInMinutes);
  }

  updateState(id) {
    this.setState({
      currentMuteTime: id,
    });
  }

  toggle(e) {
    const {
      notificationPreferences: { muteEnabled },
    } = this.props;
    e.preventDefault();
    e.stopPropagation();
    this.setMuteTime(muteEnabled ? 0 : 60);
    this.updateState(muteEnabled ? '' : 'dnd1hour');
    logMedusaGenericEvent('DND_toggled', { state: !muteEnabled });
  }

  renderDndItems() {
    const { currentMuteTime } = this.state;
    return (
      <div className={cssStyles.sub_dropdown_list}>
        {this.dndItems.map((item) => (
          <div
            role='button'
            tabIndex='-1'
            onKeyDown={(event) =>
              ['Enter', ' '].includes(event.key) && item.onClick(event)
            }
            onClick={item.onClick}
            className={cssStyles.sub_dropdown_list_item}
            key={item.id}
          >
            {item.title}
            <div className={cssStyles.check_button}>
              <div
                className={classNames(cssStyles.check_button_handle, {
                  [cssStyles.check_button_handle_active]:
                    item.id === currentMuteTime,
                })}
              />
            </div>
          </div>
        ))}
      </div>
    );
  }

  render() {
    const {
      notificationPreferences: { muteEnabled },
    } = this.props;
    return (
      <div
        role='button'
        tabIndex='-1'
        onKeyDown={(event) =>
          ['Enter', ' '].includes(event.key) && this.toggle(event)
        }
        className={cssStyles.dropdown_list_item}
        onClick={this.toggle}
        data-pendo-hook='menu-dnd'
      >
        <div className={cssStyles.menu_item_info}>
          <div className={cssStyles.menu_item_label}>Do not disturb</div>
          <div className={cssStyles.menu_item_sub_label}>
            <FormattedMessage id='stop_notifications' />
          </div>
        </div>
        <div
          className={classNames(cssStyles.menu_item_icon, {
            [cssStyles.menu_item_icon_active]: muteEnabled,
          })}
        >
          <ToggleButton isActive={muteEnabled} />
        </div>
        {muteEnabled && this.renderDndItems()}
      </div>
    );
  }
}

DoNotDisturb.propTypes = {
  sessionId: PropTypes.string.isRequired,
  notificationPreferences: PropTypes.object.isRequired,
};

export default DoNotDisturb;
