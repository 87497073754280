export default {
  new_chat_shortcut_helper: 'Novo chat',
  keyboard_shortcuts: 'Atalhos de Teclado',
  search_conversations: 'Pesquisar Conversas',
  close_current_chat_tab: 'Fechar a aba de conversa atual',
  move_focus_to_editor: 'Mover o foco para o editor de mensagens',
  cycle_through_chats_down:
    'Percorrer as abas de conversa abertas (para baixo)',
  open_recent_contacts: 'Conversas Recentes e Diret\u00f3rio',
  toggle_sidebar: 'Alternar Barra Lateral Direita',
  reopen_last_tab: 'Reabrir a \u00faltima aba de conversa fechada',
  cycle_through_chats_up: 'Percorrer as abas de conversa abertas (para cima)',
  show_this_list: 'Mostrar esta lista',
};
