import React, { useState, useEffect } from 'react';
import Loader from '@/components/common/Loader';
import { Image } from '@/components/common';
import css from './AvatarPreview.css';

export default function AvatarPreview({ loading = false, src = null }) {
  const [imgLoaded, setImgLoaded] = useState(false);

  useEffect(() => {
    setImgLoaded(false);
  }, [src]);

  return (
    <div className={css.Container}>
      <Loader overlay loading={loading || !imgLoaded} />
      {src && (
        <Image
          className={css.Img}
          src={src}
          alt='Avatar preview'
          onLoad={() => setImgLoaded(true)}
          showAltText={false}
        />
      )}
    </div>
  );
}
