const defaultState = {};

export default (ActionTypes) => {
  return (state = defaultState, action) => {
    try {
      switch (action.type) {
        case ActionTypes.UPDATE_EDITOR_STATE: {
          const { conversationId, editorState } = action.payload;
          const { [conversationId]: prevEditorState } = state;
          return {
            ...state,
            [conversationId]: { ...prevEditorState, ...editorState },
          };
        }
        default:
          return state;
      }
    } catch (error) {
      console.error(`error in editor state reducer : ${error.message}`, error);
    }
    return state;
  };
};
