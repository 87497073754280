import { connect } from 'react-redux';
import { currentSessionTeamInfo } from '@/selectors';

function mapStateToProps(state) {
  const teamInfo = currentSessionTeamInfo(state);
  return { teamInfo };
}

export default function (component) {
  return connect(mapStateToProps)(component);
}
