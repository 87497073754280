import React, { useCallback, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import cx from 'classnames';

import { KEYS } from '@/utils/constants';

import css from './Modal.css';

type Props = {
  className?: string;
  children: React.ReactNode;
  isVisible?: boolean;
  width?: string;
  height?: string;
  onOverlayClick: () => void;
  onEscape?: () => void;

  /**
   * Setting this to `true` will always render `children`. Otherwise, if modal
   * is not shown, `children` is not rendered
   */
  noDestroy?: boolean;
};

type Style = {
  width?: string;
  height?: string;
};

export default function Modal({
  children,
  width,
  height,
  isVisible = true,
  onOverlayClick,
  className,
  onEscape,
  noDestroy = false,
}: Props) {
  const modalBodyRef = useRef<HTMLDivElement>(null);
  const style: Style = {};

  const handleEsc = useCallback(
    (event: KeyboardEvent) => {
      if (isVisible && event.keyCode === KEYS.ESCAPE) {
        event.stopPropagation();
        onEscape?.();
      }
    },
    [isVisible, onEscape]
  );

  useEffect(() => {
    if (isVisible) {
      modalBodyRef.current.focus();
    }
  }, [isVisible]);

  useEffect(() => {
    if (isVisible) {
      document.addEventListener('keydown', handleEsc);
    }

    return () => {
      if (isVisible) {
        document.removeEventListener('keydown', handleEsc);
      }
    };
  }, [isVisible, handleEsc]);

  if (width) {
    style.width = width;
  }

  if (height) {
    style.height = height;
  }

  const handleOverlayClick = (event) => {
    // FIXME: There has to be a better way to do this, or probably some way to
    // share contexts for clicks? What about ESC?
    if (event.target.closest('.f-react-select__menu')) {
      return;
    }

    if (modalBodyRef.current?.contains(event.target)) {
      return;
    }

    onOverlayClick();
  };

  if (!noDestroy && !isVisible) {
    children = null;
  }

  return createPortal(
    <div
      role='presentation'
      className={cx(css.Overlay, {
        [css.Hidden]: !isVisible,
      })}
      onClick={handleOverlayClick}
    >
      <div
        className={cx(css.Modal, className)}
        ref={modalBodyRef}
        aria-modal='true'
        role='dialog'
        style={style}
        tabIndex={-1}
      >
        {children}
      </div>
    </div>,
    document.body
  );
}
